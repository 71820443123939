var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selectData" }, [
    _c("span", { staticClass: "text" }, [_vm._v("选择日期")]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "text" },
      [
        _c("el-date-picker", {
          attrs: {
            type: "daterange",
            format: "yyyy-MM-dd",
            "value-format": "yyyy-MM-dd",
            "range-separator": "",
            "start-placeholder": "",
            "end-placeholder": "",
            clearable: false,
          },
          on: {
            change: function ($event) {
              return _vm.changeDate()
            },
          },
          model: {
            value: _vm.dateRange,
            callback: function ($$v) {
              _vm.dateRange = $$v
            },
            expression: "dateRange",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }