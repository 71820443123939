var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "el-dialong-body" }, [
    _c("div", { staticClass: "el-dialong-div el-dialong-left" }, [
      _c(
        "div",
        { staticClass: "el-left-checkbox" },
        [
          _vm._l(_vm.checkedUnits, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "iconfont cursor",
                on: {
                  click: function ($event) {
                    return _vm.clearAway(index, "unit")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s(item.unit_name) + "\n        "),
                _c("span", [_vm._v("")]),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.checkedGroups, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "iconfont cursor",
                on: {
                  click: function ($event) {
                    return _vm.clearAway(index, "group")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s(item.name) + "\n        "),
                _c("span", [_vm._v("")]),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.checkedUsers, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "iconfont cursor",
                on: {
                  click: function ($event) {
                    return _vm.clearAway(index, "user")
                  },
                },
              },
              [
                _vm._v("\n        " + _vm._s(item.name) + "\n        "),
                _c("span", [_vm._v("")]),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "cursor el-dialong-btn" }, [
        _c("div", { on: { click: _vm.selectConfirm } }, [
          _vm._v(
            "确定(" +
              _vm._s(
                _vm.checkedUnits.length +
                  _vm.checkedGroups.length +
                  _vm.checkedUsers.length
              ) +
              ")"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cancel", on: { click: _vm.cancelSelect } }, [
          _vm._v("取消"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "el-dialong-div el-dialong-right" },
      [
        !_vm.isHint
          ? _c(
              "div",
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentUnitId == 0,
                      expression: "currentUnitId == 0",
                    },
                  ],
                  staticClass: "el-dialong-input",
                  attrs: {
                    placeholder: "请输入内容",
                    "suffix-icon": "el-icon-search",
                  },
                  on: { input: _vm.unitSearchEvent },
                  model: {
                    value: _vm.unitSearchInput,
                    callback: function ($$v) {
                      _vm.unitSearchInput = $$v
                    },
                    expression: "unitSearchInput",
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentUnitId != 0,
                      expression: "currentUnitId != 0",
                    },
                  ],
                  staticClass: "el-dialong-input",
                  attrs: {
                    placeholder: "请输入内容",
                    "suffix-icon": "el-icon-search",
                  },
                  on: { input: _vm.groupUserSearchEvent },
                  model: {
                    value: _vm.groupUserSearchInput,
                    callback: function ($$v) {
                      _vm.groupUserSearchInput = $$v
                    },
                    expression: "groupUserSearchInput",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentUnitId == 0,
                        expression: "currentUnitId == 0",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "cheak-all",
                        model: {
                          value: _vm.checkAllUnit,
                          callback: function ($$v) {
                            _vm.checkAllUnit = $$v
                          },
                          expression: "checkAllUnit",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "el-dialong-group",
                        model: {
                          value: _vm.checkedUnits,
                          callback: function ($$v) {
                            _vm.checkedUnits = $$v
                          },
                          expression: "checkedUnits",
                        },
                      },
                      _vm._l(_vm.unitList, function (unit, index) {
                        return _c("div", { staticClass: "dialog-one-div" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: unit.data.show,
                                  expression: "unit.data.show",
                                },
                              ],
                              staticClass: "selectUnit",
                              attrs: { title: unit.data.unit_name },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "el-dialong-checkbox",
                                  attrs: { label: unit.data },
                                },
                                [_vm._v(_vm._s(unit.data.unit_name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "el-dialong-chdiv iconfont cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.subordinate(index)
                                    },
                                  },
                                },
                                [_vm._v("\n                \n              ")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticStyle: { margin: "15px", "font-size": "17px" } },
              [_vm._v("当前无活跃的指派或群发人员")]
            ),
        _vm._v(" "),
        _vm._l(_vm.unitList, function (unit, index) {
          return _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentUnitId == unit.data.id,
                    expression: "currentUnitId == unit.data.id",
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "iconfont cursor back-btn",
                    on: { click: _vm.backUnitList },
                  },
                  [
                    _vm._v("\n          \n          "),
                    _c("span", [_vm._v(_vm._s(_vm.currentUnitName))]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "cheak-all-user",
                    model: {
                      value: _vm.checkAllGroupAndUser,
                      callback: function ($$v) {
                        _vm.checkAllGroupAndUser = $$v
                      },
                      expression: "checkAllGroupAndUser",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "el-dialong-group",
                    model: {
                      value: _vm.checkedGroups,
                      callback: function ($$v) {
                        _vm.checkedGroups = $$v
                      },
                      expression: "checkedGroups",
                    },
                  },
                  _vm._l(unit.groupList, function (group) {
                    return _c(
                      "div",
                      { staticClass: "dialog-one-div" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: group.show,
                                expression: "group.show",
                              },
                            ],
                            staticClass: "el-dialong-checkbox",
                            attrs: { label: group },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(group.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "el-dialong-group",
                    model: {
                      value: _vm.checkedUsers,
                      callback: function ($$v) {
                        _vm.checkedUsers = $$v
                      },
                      expression: "checkedUsers",
                    },
                  },
                  _vm._l(unit.userList, function (user) {
                    return _c(
                      "div",
                      { staticClass: "dialog-one-div" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: user.show,
                                expression: "user.show",
                              },
                            ],
                            staticClass: "el-dialong-checkbox",
                            attrs: { label: user },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(user.name) +
                                "\n              "
                            ),
                            user.is_admin == 0 && user.is_unit == 1
                              ? _c("i", { staticClass: "iconfont icon-pre" }, [
                                  _vm._v(""),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }