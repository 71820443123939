<template>
  <div class="screen">
    <input
      type="checkbox"
      class="historyInp"
      id="Google"
      value="限制区域"
      @change="limit(value1)"
      v-model="value1"
    />
    <span :style="limitSty" class="flystatus">限制区域</span>
    <input
      type="checkbox"
      class="historyInp"
      id="Runoob"
      value="存续空域"
      @change="subsist(value2)"
      v-model="value2"
    />
    <span :style="subsistSty" class="flystatus">存续空域</span>
    <input
      type="checkbox"
      class="historyInp"
      id="guoqi"
      value="过期空域"
      @change="overdue(value3)"
      v-model="value3"
    />
    <span :style="overdueSty" class="flystatus">过期空域</span>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      value1: false,
      value2: false,
      value3: false,
      limitSty: "",
      subsistSty: "",
      overdueSty: "",
    };
  },
  methods: {
    // 限制区域
    limit(param) {
      if (param == true) {
        this.$emit("limit", event.target.checked);
        this.limitSty = "color:#1890ff";
      } else {
        this.$emit("limit", event.target.checked);
        this.limitSty = "";
      }
    },
    // 存续空域
    subsist(param) {
      if (param == true) {
        this.$emit("subsist", event.target.checked);
        this.subsistSty = "color:#1890ff";
      } else {
        this.$emit("subsist", event.target.checked);
        this.subsistSty = "";
      }
    },
    // 过期空域
    overdue(param) {
      if (param == true) {
        this.$emit("overdue", event.target.checked);
        this.overdueSty = "color:#1890ff";
      } else {
        this.$emit("overdue", event.target.checked);
        this.overdueSty = "";
      }
    },
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(["token", "user"]),
  },
};
</script>

<style lang="less">
.screen {
  width: 409px;
  height: 57px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 30px 2px rgba(184, 183, 183, 0.5);
}
.historyInp {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  font-family: Helvetica;
  font-size: 16px;
}
.flystatus {
  margin-right: 20px;
}
</style>