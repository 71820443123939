var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "taskBox",
              attrs: {
                title: _vm.ruleForm.id ? "编辑设备" : "新增设备",
                "close-on-click-modal": false,
                visible: _vm.dialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                click: _vm.open,
                close: _vm.closeDialog,
              },
            },
            [
              _c("div", { staticClass: "detailBar" }, [
                _c(
                  "div",
                  { staticClass: "formBox" },
                  [
                    _c(
                      "el-form",
                      {
                        key: _vm.ruleForm.id,
                        ref: "ruleForm",
                        staticClass: "formbox",
                        attrs: {
                          rules: _vm.rules,
                          model: _vm.ruleForm,
                          "label-width": "80px",
                        },
                      },
                      [
                        _vm.type != "terminal" &&
                        _vm.type != "monitorModule" &&
                        _vm.type != "countryApp"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: { prop: "name", label: "设备名称" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入设备名称" },
                                  model: {
                                    value: _vm.ruleForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "name", $$v)
                                    },
                                    expression: "ruleForm.name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type != "terminal" && _vm.type !== "countryApp"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: {
                                  prop: "software_code",
                                  label: "设备ID",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入设备ID" },
                                  model: {
                                    value: _vm.ruleForm.software_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "software_code",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.software_code",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "holder_dept", label: "归属部门" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入归属部门" },
                              model: {
                                value: _vm.ruleForm.holder_dept,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "holder_dept", $$v)
                                },
                                expression: "ruleForm.holder_dept",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.type != "terminal" && _vm.type != "monitorModule"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: { prop: "brand_id", label: "设备品牌" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择设备品牌" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.brandChange(
                                          _vm.ruleForm.brand_id
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.brand_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "brand_id", $$v)
                                      },
                                      expression: "ruleForm.brand_id",
                                    },
                                  },
                                  _vm._l(_vm.brandList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type != "terminal" && _vm.type != "monitorModule"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: { prop: "model_id", label: "设备型号" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择设备型号" },
                                    model: {
                                      value: _vm.ruleForm.model_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "model_id", $$v)
                                      },
                                      expression: "ruleForm.model_id",
                                    },
                                  },
                                  _vm._l(_vm.modelList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "holder_name", label: "持有人" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入持有人" },
                              model: {
                                value: _vm.ruleForm.holder_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "holder_name", $$v)
                                },
                                expression: "ruleForm.holder_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "unit_id", label: "联盟单位" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择联盟单位" },
                                model: {
                                  value: _vm.ruleForm.unit_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "unit_id", $$v)
                                  },
                                  expression: "ruleForm.unit_id",
                                },
                              },
                              _vm._l(_vm.unitList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.unit_name,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: {
                              prop: "holder_phone",
                              label: "持有人手机",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入持有人手机" },
                              model: {
                                value: _vm.ruleForm.holder_phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "holder_phone", $$v)
                                },
                                expression: "ruleForm.holder_phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "status", label: "设备状态" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择设备状态" },
                                model: {
                                  value: _vm.ruleForm.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "status", $$v)
                                  },
                                  expression: "ruleForm.status",
                                },
                              },
                              _vm._l(_vm.statusList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.key, value: item.value },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            staticStyle: { height: "130px" },
                            attrs: { label: "登记位置", required: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: { prop: "longitude" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "经度" },
                                  model: {
                                    value: _vm.ruleForm.longitude,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "longitude", $$v)
                                    },
                                    expression: "ruleForm.longitude",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: { prop: "latitude" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "纬度" },
                                  model: {
                                    value: _vm.ruleForm.latitude,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "latitude", $$v)
                                    },
                                    expression: "ruleForm.latitude",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "info",
                                  plain: "",
                                  icon: "el-icon-map-location",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectMapPointDialog.visible = true
                                  },
                                },
                              },
                              [_vm._v("地图")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.type == "monitorModule"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: { label: "关联设备类型" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择关联设备类型",
                                    },
                                    model: {
                                      value:
                                        _vm.ruleForm.associated_device_type_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "associated_device_type_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.associated_device_type_id",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 0, label: "请选择" },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.associatedDeviceTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.value,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "monitorModule"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "userForm",
                                attrs: { label: "关联设备编号" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入关联设备编号" },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .associated_device_software_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "associated_device_software_code",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.associated_device_software_code",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.closeDialog } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("selectMapPoint", {
        attrs: {
          lonlatForm: _vm.ruleForm,
          selectMapPointDialog: _vm.selectMapPointDialog,
          vid: _vm.ruleForm.id,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }