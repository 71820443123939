<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      title="删除"
      v-dialogDrag
    >
      <span class="titleBar"
        >确定要删除“{{ this.fileInfor.source_name }}”吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          class="footBtn"
          :loading="loading"
          type="primary"
          @click="delBtn"
          >确 定</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  inject: ["reload"],
  props: {
    fileInfor: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    openDelEdit() {
      this.dialogVisible = true;
    },
    // 确定删除
    delBtn() {
      this.loading = true;
      this.$apis
        .delfile(
          {
            id: this.fileInfor.id,
          },
          this.token
        )
        .then((data) => {
          this.$emit("upFileList");
          if (data.data.status == 0) {
            this.loading = false;
            this.$message({
              message: data.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: data.data.message,
              type: "error",
            });
          }
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 308px !important;
  height: 200px !important;
  margin-top: 38vh !important;
}
/deep/ .el-dialog__footer {
  margin-top: 10px !important;
  padding-right: 50px;
}
.footBtn {
  width: 96px;
  height: 36px;
  margin-left: 10px;
}
.titleBar {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-top: 10px;
  min-height: 60px;
  max-height: 72px;
  overflow: hidden;
}
/deep/.el-dialog__body {
  padding: 0px 20px;
}
</style>
