<template>
  <div>
    <!-- 新建用户 -->
    <el-dialog
      title="新建用户"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      class="taskBox"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="formBox">
          <el-form
            class="formbox"
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item class="userForm" prop="mobile" label="手机号码">
              <el-input
                placeholder="请输入"
                v-model="ruleForm.mobile"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="name" label="账户名称">
              <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
              <p class="sign">
                账户名称请输入真实姓名，方便以后推送任务时更加容易识别。
              </p>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="footBtn"
          :loading="loading"
          @click="addBtn"
          type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    unitID: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      ruleForm: {
        mobile: "",
        name: "",
      },
      rules: {
        mobile: [
          { required: true, message: "手机号不存在", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { max: 10, message: "最大字符数6", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    openAddUser() {
      this.dialogVisible = true;
    },
    addBtn() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.submitData();
          this.dialogVisible = false;
        } else {
        }
      });
    },
    submitData() {
      this.$apis
        .addunituser(
          {
            unit_id: this.unitID.id,
            mobile: this.ruleForm.mobile,
            name: this.ruleForm.name,
            password: this.ruleForm.mobile,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.loading = false;
            this.ruleForm.mobile = "";
            this.ruleForm.name = "";
            this.$emit("refresh");

            this.$message({
              message: data.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.sign {
  width: 270px;
  font-size: 12px;
  color: #9b9da3;
  margin: 5px 0 0 80px;
}
.formBox {
  margin-top: 50px !important;
  margin-left: 30px;
}
/deep/.el-dialog {
  width: 460px !important;
  height: 380px !important;
  margin-left: 73vh !important;
  margin-top: 20vh !important;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
.el-input {
  width: 277px;
}
/deep/.el-form-item__content {
  line-height: 16px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-dialog__title {
  margin-left: 25px;
  line-height: 50px;
}
/deep/.el-dialog__header {
  height: 50px;
}
/deep/.el-input--medium .el-input__inner {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
</style>