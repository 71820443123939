var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "fmdialog",
          attrs: {
            title: "添加联盟绑定",
            visible: _vm.outerVisible,
            "close-on-click-modal": false,
          },
          on: {
            click: _vm.open,
            "update:visible": function ($event) {
              _vm.outerVisible = $event
            },
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { prop: "mobile", label: "手机号码" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "mobile", $$v)
                      },
                      expression: "ruleForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { prop: "name", label: "用户名称" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-alert", {
                attrs: {
                  title: "手机号登录时，密码与手机号同步！",
                  type: "warning",
                  "show-icon": "",
                  closable: false,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "fmButton",
                  attrs: { type: "primary" },
                  on: { click: _vm.handAddReg },
                },
                [_vm._v("添 加")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "fmButton", on: { click: _vm.handCancel } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }