var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c("iframe", {
        ref: "iframe",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          src: "/result/index.html?config=" + _vm.param,
          frameborder: "0",
        },
      }),
      _vm._v(" "),
      _c("Drawer", { staticClass: "drawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }