<template>
  <div>
    <el-dialog
      title="登记位置"
      :close-on-click-modal="false"
      :visible.sync="selectMapPointDialog.visible"
      class="taskBox"
      width="70%"
      v-dialogDrag
    >
      <div style="width: 100%; height: 500px">
        <el-amap
          defaultCursor="pointer"
          class="amap-box"
          :vid="'map' + vid"
          :events="mapEvent"
          :amap-manager="amapManager"
          :doubleClickZoom="false"
          :keyboardEnable="false"
          :zoom="zoom"
          :center="selectPosition.lon != 0 ? [selectPosition.lon, selectPosition.lat] : defaultCenter"
        >
          <el-amap-marker
            v-if="selectPosition.lon != 0"
            :vid="'marker' + vid"
            :position="[selectPosition.lon, selectPosition.lat]"
          ></el-amap-marker>
					<el-amap-info-window
						v-if="selectPosition.lon != 0 && infoWindowContent != ''"
            :vid="'marker' + vid"
						:offset="[0,-35]"
						:content="infoWindowContent"
            :position="[selectPosition.lon, selectPosition.lat]"
					>
					</el-amap-info-window>
        </el-amap>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setSelectPosition">确 定</el-button>
        <el-button @click="selectMapPointDialog.visible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {  AMapManager } from "vue-amap";
const amapManager = new AMapManager();
import defaultConstants from "@/utils/default-constants";



export default {
	// props: ['selectMapPointDialog','lonlatForm','vid'],
  props:{
    selectMapPointDialog: {
      type: Object,
    },
    lonlatForm:{
      type: Object
    },
    vid: {},
    isRedegisterAddress: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const self = this;
    return {
      defaultCenter: defaultConstants.mapDefaultLocation,
      selectPosition: {
        lat: "",
        lon: "",
      },
      elMap: {},
      amapManager,
			infoWindowContent: '',
      zoom: 12, //地图组件的参数
      mapEvent: {
        init(e, mapStyle) {
          self.elMap = e;
        },
        click(e) {
          const { lng, lat } = e.lnglat;
          self.selectPosition.lon = lng;
          self.selectPosition.lat = lat;

					self.showSelectAddressInfo(lng,lat)
        },
      },
    };
  },
	created() {
	},
  methods: {
		//显示选点地址信息
		showSelectAddressInfo(lon,lat) {
			let geocoder = new AMap.Geocoder({
				radius: 1000,
				extensions: "all",
			});
			let address = "";
			let self = this
			geocoder.getAddress(
				[lon, lat],
				function (status, result) {
					if (status === "complete" && result.info === "OK") {
						if (result && result.regeocode) {
							address = result.regeocode.formattedAddress;
							var info = [];
							info.push('<div style="font-weight:bold;">登记位置</div>');
							info.push(
								'<div style="witdh:100%;height:1px;margin:2px 0;background:#000000"></div>'
							);
							info.push("<div>" + address + "</div>");
							info.push(
								"<div>经度：" + lon + "</div>"
							);
							info.push(
								"<div>纬度：" + lat + "</div>"
							);


							self.infoWindowContent = info.join('')
						}
					} else {
						console.log('无法获取坐标点位置信息',lat,lon)
					}
				}
			);
		},

    //确认选点
    setSelectPosition() {
      this.selectMapPointDialog.visible = false
			this.lonlatForm.longitude = this.selectPosition.lon
      this.lonlatForm.latitude = this.selectPosition.lat
      if (this.isRedegisterAddress){
        this.$emit("updateLocation",this.lonlatForm)
      }
    },
  },
	watch: {
		lonlatForm(newVal,oldVal) {
			this.selectPosition.lon = newVal.longitude
			this.selectPosition.lat = newVal.latitude

			this.showSelectAddressInfo(this.selectPosition.lon,this.selectPosition.lat)
		},
	}
};
</script>

<style>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
  height: 60px;
}
</style>
