var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "upfile",
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "titleBar" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("数据回传")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              this.flag == 1
                ? _c("div", { staticClass: "bodyBar" }, [
                    _c("div", { staticClass: "leftBar" }, [
                      _c("span", { staticClass: "uptit" }, [
                        _vm._v("上传文件"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightBar" }, [
                      _c("div", { staticClass: "uplay" }, [
                        _c("div", { staticClass: "clickplay" }, [
                          _c("img", {
                            staticClass: "upImg",
                            attrs: {
                              src: require("@/assets/img/datareturn／upload／file.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "describe" }, [
                            _vm._v(
                              "点击上传单个/多个文件，或将单个/多个文件拖拽到这里"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            ref: "file",
                            staticClass: "uploadInput",
                            attrs: { type: "file", multiple: "multiple" },
                            on: { change: _vm.aetherupload },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sider" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tips" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("i", { staticClass: "el-icon-warning-outline" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titWord" }, [
                            _vm._v("文件上传提示"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v("1、请勿一次上传多个大型文件"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v(
                              "\n                2、如需上传大型文件，请待上传进度完成以后在进行下一个文件上传\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v("3、请勿重复上传"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v(
                              "\n                4、上传过程中请勿刷新页面，否则任务将重新进行\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "bodyNav" }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.flag == 2
                ? _c("div", { staticClass: "bodyBar" }, [
                    _c("div", { staticClass: "leftBar" }, [
                      _c("span", { staticClass: "uptit" }, [
                        _vm._v("上传文件夹"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightBar" }, [
                      _c("div", { staticClass: "uplay" }, [
                        _c("div", { staticClass: "clickplay" }, [
                          _c("img", {
                            staticClass: "upImg",
                            attrs: {
                              src: require("@/assets/img/datareturn／upload／folder.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "describe" }, [
                            _vm._v(
                              "点击上传单个/多个文件，或将单个/多个文件拖拽到这里"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            ref: "file",
                            staticClass: "uploadInput",
                            attrs: {
                              type: "file",
                              multiple: "multiple",
                              webkitdirectory: "",
                            },
                            on: { change: _vm.aetherupload },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sider" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tips" }, [
                        _c("div", { staticClass: "tit" }, [
                          _c("i", { staticClass: "el-icon-warning-outline" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "titWord" }, [
                            _vm._v("文件上传提示"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v("1.请勿一次上传多个大型文件"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v(
                              "\n                2.如需上传大型文件，请待上传进度完成以后在进行下一个文件上传\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v("3、请勿重复上传"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "tipsWord" }, [
                            _vm._v(
                              "\n                4、上传过程中请勿刷新页面，否则任务将重新进行\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "bodyNav" }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleTwo,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleTwo = $event
            },
          },
        },
        [
          _vm.filesList.length > 0
            ? _c(
                "div",
                { staticClass: "titleBar" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(
                      "正在上传(" +
                        _vm._s(_vm.underway) +
                        "/" +
                        _vm._s(_vm.sum) +
                        ")"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("div", { staticClass: "bodyBar" }, [
                    _c("div", { staticClass: "dataTit" }, [
                      _c("span", { staticClass: "title" }, [_vm._v("文件名")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title" }, [_vm._v("大小")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "title" }, [_vm._v("状态")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mainBar" },
                      _vm._l(_vm.filesList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "fileline" },
                          [
                            _c(
                              "div",
                              { staticClass: "filename" },
                              [
                                _c("img", {
                                  staticClass: "fileImg",
                                  attrs: {
                                    src: require("@/assets/img/datareturn／other.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      width: "200",
                                      trigger: "hover",
                                      content: item.name,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "name",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "filesize" }, [
                              _c("span", { staticClass: "size" }, [
                                _vm._v(_vm._s(item.size)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "filestatus iconfont" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.filesList[index].status)),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "footbtn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "footBtn",
                          attrs: {
                            disabled: _vm.underway !== 0,
                            type: "primary",
                          },
                          on: { click: _vm.suerBtn },
                        },
                        [_vm._v("确 定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "footBtn",
                          attrs: { disabled: _vm.underway !== 0 },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisibleTwo = false
                              _vm.dialogVisible = true
                            },
                          },
                        },
                        [_vm._v("继续回传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }