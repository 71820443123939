// 任务相关接口
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  获取任务列表接口
export const taskList = (data, token, url) => fly.post(
  baseURL + '/task/' + url,
  data, deploy(30000, token)
)

//  获取任务推送类型
export const taskPushType = (data, token) => fly.post(
  baseURL + '/task/pushType',
  data, deploy(30000, token)
)

//  获取项目子任务接口
export const taskListTask = (data, token, url) => fly.post(
  baseURL + '/task/' + url,
  data, deploy(30000, token)
)


//  任务推送联盟接口
export const taskPushToUnit = (data, token) => fly.post(
  baseURL + '/task/pushToUnit',
  data, deploy(60000, token)
)


//  任务推送用户接口
export const taskPushToUser = (data, token) => fly.post(
  baseURL + '/task/pushToUser',
  data, deploy(60000, token)
)

//  传感器类型接口
export const taskGetSensorType = (data, token) => fly.post(
  baseURL + '/task/getSensorType',
  data, deploy(30000, token)
)

//  授权空域接口
export const taskGetAuthAreaStatus = (data, token) => fly.post(
  baseURL + '/task/getAuthAreaStatus',
  data, deploy(30000, token)
)

//  任务性质接口
export const taskGetChargeProperty = (data, token) => fly.post(
  baseURL + '/task/getChargeProperty',
  data, deploy(30000, token)
)


// 新建通用任务
export const taskAddCommon = (data, token) => fly.post(
  baseURL + '/task/addCommon',
  data, deploy(90000, token)
)


// 联盟接单
export const taskUnitConfirm = (data, token) => fly.post(
  baseURL + '/task/unitConfirm',
  data, deploy(30000, token)
)


// 发送指令
export const taskSendCommand = (data, token) => fly.post(
  baseURL + '/task/sendCommand',
  data, deploy(30000, token)
)


// 联盟拒单
export const taskUnitRefuse = (data, token) => fly.post(
  baseURL + '/task/unitRefuse',
  data, deploy(30000, token)
)

// 删除项目
export const taskUnitDelete = (data, token) => fly.post(
  baseURL + '/task/unitDelete',
  data, deploy(30000, token)
)


// 删除项目
export const taskGetTaskLog = (data, token) => fly.post(
  baseURL + '/task/getTaskLog',
  data, deploy(30000, token)
)



// 任务简报列表
export const taskGetTaskBriefList = (data, token) => fly.post(
  baseURL + '/task/getTaskBriefList',
  data, deploy(30000, token)
)


// 任务简报详情
export const taskGetTaskBriefDetail = (data, token) => fly.post(
  baseURL + '/task/getTaskBriefDetail',
  data, deploy(30000, token)
)



// 任务回传原始分类
export const taskGetClassify = (url, token) => fly.post(
  baseURL + '/task/' + url, {}, deploy(30000, token)
)


// 任务数据回传
export const taskPostDataBack = (data, token) => fly.post(
  baseURL + '/task/postDataBack',
  data, deploy(3000000000, token)
)


// 任务数据查看
export const taskPostDataDetail = (data, token) => fly.post(
  baseURL + '/task/postDataDetail',
  data, deploy(30000, token)
)

// 任务评分
export const taskSetTaskScore = (data, token) => fly.post(
  baseURL + '/task/setTaskScore',
  data, deploy(30000, token)
)

// 子任务详情
export const taskGetTaskDetail = (data, token) => fly.post(
  baseURL + '/task/getTaskDetail',
  data, deploy(30000, token)
)


// 发布到门户
export const taskIsPortal = (data, token) => fly.post(
  baseURL + '/task/isPortal',
  data, deploy(30000, token)
)

// 消息未读数
export const noticeUnreadNum = (data, token) => fly.post(
  baseURL + '/task/noticeUnreadNum',
  data, deploy(30000, token)
)

// 消息列表
export const getNoticeList = (data, token) => fly.post(
  baseURL + '/task/getNoticeList',
  data, deploy(30000, token)
)

// 单条消息已读
export const setNoticeRead = (data, token) => fly.post(
  baseURL + '/task/setNoticeRead',
  data, deploy(30000, token)
)

// 所有消息已读
export const readAllNotice = (data, token) => fly.post(
  baseURL + '/task/readAllNotice',
  data, deploy(30000, token)
)

// 删除消息
export const deleteNotice = (data, token) => fly.post(
  baseURL + '/task/deleteNotice',
  data, deploy(30000, token)
)

// 门户任务列表
export const portalListProject = (data, token) => fly.post(
  baseURL + '/portal/listProject',
  data, deploy(30000, token)
)

// 门户任务子列表
export const portalListTask = (data, token) => fly.post(
  baseURL + '/portal/listTask',
  data, deploy(30000, token)
)

// 项目重做
export const taskRedoProject = (data, token) => fly.post(
  baseURL + '/task/redoProject',
  data, deploy(30000, token)
)

// 任务重做
export const taskRedoTask = (data, token) => fly.post(
  baseURL + '/task/redoTask',
  data, deploy(30000, token)
)

// 项目详情
export const getProjectDetail = (data, token) => fly.post(
  baseURL + '/task/getProjectDetail',
  data, deploy(30000, token)
)

// 飞马子任务
export const listFeiMaTask = (data, token) => fly.post(
  baseURL + '/task/listFeiMaTask',
  data, deploy(30000, token)
)

// 通用子任务
export const listCommonTask = (data, token) => fly.post(
  baseURL + '/task/listCommonTask',
  data, deploy(30000, token)
)


// 任务列表
export const getProjectList = (data, token) => fly.post(
  baseURL + '/task/getProjectList',
  data, deploy(30000, token)
)



// 保存传感器类型
export const taskSaveSensorType = (data, token) => fly.post(
  baseURL + '/task/saveSensorType',
  data, deploy(30000, token)
)


// 删除传感器类型
export const taskDelSensorType = (data, token) => fly.post(
  baseURL + '/task/delSensorType',
  data, deploy(30000, token)
)
// 任务规划
//发布任务
export const demandSaveOrder = (data, token) => fly.post(
  baseURL + '/demand/saveOrder',
  data, deploy(30000, token)
)
//任务列表
export const demandListOrder = (data, token) => fly.post(
  baseURL + '/demand/listOrder',
  data, deploy(30000, token)
)

//任务详情
export const demandShowOrder = (data, token) => fly.post(
  baseURL + '/demand/showOrder',
  data, deploy(30000, token)
)

//任务详情
export const demandCompleteOrder = (data, token) => fly.post(
  baseURL + '/demand/completeOrder',
  data, deploy(30000, token)
)
//任务详情
export const demandAuditOrder = (data, token) => fly.post(
  baseURL + '/demand/auditOrder',
  data, deploy(30000, token)
)
//内部派单
export const demandInternalOrder = (data, token) => fly.post(
  baseURL + '/demand/internalOrder',
  data, deploy(30000, token)
)
// demand/deleteOrder
//内部派单
export const demandDeleteOrder = (data, token) => fly.post(
  baseURL + '/demand/deleteOrder',
  data, deploy(30000, token)
)
// demand/cancelOrder
// 中止订单
export const demandCancelOrder = (data, token) => fly.post(
  baseURL + '/demand/cancelOrder',
  data, deploy(30000, token)
)

// 全部已读
export const setOrderIsRead = (data, token) => fly.post(
  baseURL + '/demand/setOrderIsRead',
  data, deploy(30000, token)
)
