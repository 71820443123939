var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TopBar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mapBar" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("Monitor", { ref: "monitor" }),
          _vm._v(" "),
          _c("div", { ref: "middleBar", attrs: { id: "middleBar" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showScroll,
                    expression: "showScroll",
                  },
                ],
                staticClass: "el-backtop",
                staticStyle: { right: "40px", bottom: "40px" },
                attrs: { title: "回到顶部" },
                on: { click: _vm.returnTop },
              },
              [_c("i", { staticClass: "el-icon-caret-top" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "topTask" }, [
              _c("div", { staticClass: "taskinfor" }, [
                _c("div", { staticClass: "topTask-name" }, [
                  _vm._v("应急任务"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "topTask-text" }, [
                  _vm._v("应急任务协同处理查看"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "taskinfor-div" }, [
                  _c("div", { staticClass: "flyNum" }, [
                    _c("div", { staticClass: "leftBar" }, [
                      _c("img", {
                        staticClass: "iconImg",
                        attrs: {
                          src: require("../../../assets/img/homeImg/right.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "countleft" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.homeDataList.report_home_today_pushed) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "describe" }, [
                        _vm._v("今日已推送任务"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightBar" }, [
                      _c("img", {
                        staticClass: "iconImg",
                        attrs: {
                          src: require("../../../assets/img/homeImg/left.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "countleft" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.homeDataList.report_home_today_not_pushed
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "describe" }, [
                        _vm._v("今日未推送任务"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pushCount" }, [
                    _c("div", { staticClass: "topPush" }, [
                      _c("span", { staticClass: "pushtask" }, [
                        _vm._v("历史已推送任务"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "progressBar" },
                        [
                          _c("el-progress", {
                            staticClass: "progress",
                            attrs: {
                              percentage: _vm.historypush,
                              color: "#3FF1FF",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "botPush" }, [
                      _c("span", { staticClass: "pushtask" }, [
                        _vm._v("历史未推送任务"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "progressBar" },
                        [
                          _c("el-progress", {
                            staticClass: "progress",
                            attrs: {
                              percentage: _vm.historyNotpush,
                              color: "#E06551",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "taskmeun" },
                [
                  _vm._l(_vm.HomeProjectArr, function (item, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "taskmeun-list",
                        class: {
                          taskmeunListClick: _vm.HomeProjectArrIndex === index,
                        },
                        on: {
                          click: function ($event) {
                            _vm.HomeProjectArrIndex = index
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "13px",
                              color: "#3c3d41",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.project_name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "12px",
                              "font-size": "12px",
                              color: "#a5a5a5",
                            },
                          },
                          [
                            _vm._v(
                              "\n              创建人:" +
                                _vm._s(item.name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "4px",
                              "font-size": "12px",
                              color: "#a5a5a5",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.created_at) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "taskmeun-btn",
                      on: { click: _vm.seeMoreTasks },
                    },
                    [_vm._v("查看更多任务")]
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "airsearch" }, [
              _c(
                "div",
                { staticClass: "text", on: { click: _vm.toAirspace } },
                [
                  _c("div", [_vm._v("空域查询")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "12px",
                      },
                    },
                    [_vm._v("\n            阳光飞行便捷服务站\n          ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "homeBot" }, [
              _c("div", { staticClass: "introduce" }, [
                _c("div", { staticClass: "introduce-top" }, [
                  _vm._v("数据成果申请"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "introduce-text" }, [
                  _vm._v("高清呈现我们的家园"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "introduce-btn",
                    on: { click: _vm.application },
                  },
                  [_vm._v("去申请数据")]
                ),
              ]),
              _vm._v(" "),
              _vm.HomeApplyDataArr.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "homeBot-list",
                      staticStyle: { top: "-39px" },
                    },
                    [
                      _c("div", { staticClass: "homeBot-list-top" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "padding-left": "30px",
                            },
                          },
                          [
                            _vm._v(
                              "\n              数据申请单号: " +
                                _vm._s(_vm.HomeApplyDataArr[0].data_apply_no) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "padding-right": "34px",
                              color: "#98a6cd",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.HomeApplyDataArr[0].created_at) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "homeBot-list-body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "homeBot-list-li",
                            staticStyle: { "margin-top": "29px" },
                          },
                          [
                            _c("div", [_vm._v("数据申请类型")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "#000000" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.HomeApplyDataArr[0].category) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("数据完整性")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.HomeApplyDataArr[0].integrality === 0
                                    ? "部分"
                                    : "全部"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("可接受的反馈等待期")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.HomeApplyDataArr[0].wait_time) +
                                "天\n              "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.HomeApplyDataArr.length > 1
                ? _c(
                    "div",
                    {
                      staticClass: "homeBot-list",
                      staticStyle: { top: "207px" },
                    },
                    [
                      _c("div", { staticClass: "homeBot-list-top" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "padding-left": "30px",
                            },
                          },
                          [
                            _vm._v(
                              "\n              数据申请单号: " +
                                _vm._s(_vm.HomeApplyDataArr[1].data_apply_no) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "padding-right": "34px",
                              color: "#98a6cd",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.HomeApplyDataArr[1].created_at) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "homeBot-list-body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "homeBot-list-li",
                            staticStyle: { "margin-top": "29px" },
                          },
                          [
                            _c("div", [_vm._v("数据申请类型")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "#000000" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.HomeApplyDataArr[1].category) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("数据完整性")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.HomeApplyDataArr[1].integrality === 0
                                    ? "部分"
                                    : "全部"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("可接受的反馈等待期")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.HomeApplyDataArr[1].wait_time) +
                                "天\n              "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.HomeApplyDataArr.length > 2
                ? _c(
                    "div",
                    {
                      staticClass: "homeBot-list",
                      staticStyle: { top: "452px" },
                    },
                    [
                      _c("div", { staticClass: "homeBot-list-top" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "padding-left": "30px",
                            },
                          },
                          [
                            _vm._v(
                              "\n              数据申请单号: " +
                                _vm._s(_vm.HomeApplyDataArr[2].data_apply_no) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "padding-right": "34px",
                              color: "#98a6cd",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.HomeApplyDataArr[2].created_at) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "homeBot-list-body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "homeBot-list-li",
                            staticStyle: { "margin-top": "29px" },
                          },
                          [
                            _c("div", [_vm._v("数据申请类型")]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "#000000" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.HomeApplyDataArr[2].category) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("数据完整性")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.HomeApplyDataArr[2].integrality === 0
                                    ? "部分"
                                    : "全部"
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "homeBot-list-li" }, [
                          _c("div", [_vm._v("可接受的反馈等待期")]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.HomeApplyDataArr[2].wait_time) +
                                "天\n              "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "homesign" }, [
              _c("div", { staticClass: "navBare" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "midtop" }),
                _vm._v(" "),
                _c("div", { staticClass: "righttop" }, [
                  _c("div", { staticClass: "titstop" }, [_vm._v("相关链接")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "srcBar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "line",
                        on: {
                          click: function ($event) {
                            return _vm.skip(1)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "srcline" }, [
                          _vm._v("  无人机管家"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sing" }, [
                          _vm._v(
                            "-无人机数据获取/处理/显示管理/无人机维护的智能GIS系统。"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "line",
                        on: {
                          click: function ($event) {
                            return _vm.skip(2)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "srcline" }, [
                          _vm._v("  无人机管家（iPad）"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sing" }, [
                          _vm._v(_vm._s(_vm.urlObj.IpadUrl)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "line",
                        on: {
                          click: function ($event) {
                            return _vm.skip(3)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "srcline" }, [
                          _vm._v("  DataManager上传工具"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sing" }, [_vm._v("链接")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "line",
                        on: {
                          click: function ($event) {
                            return _vm.skip(4)
                          },
                        },
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              trigger: "hover",
                              width: "120px",
                              id: "popover",
                            },
                          },
                          [
                            _c("vue-qr", {
                              staticClass: "qr",
                              attrs: {
                                size: 120,
                                margin: 0,
                                "auto-color": true,
                                "dot-scale": 1,
                                text: _vm.htmlUrl,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "srcline",
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDownload("app")
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _vm._v("【飞享终端】手机APP "),
                                _c("span", { staticClass: "sing" }, [
                                  _vm._v("链接"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "titstop" }, [_vm._v("相关网站")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "srcBar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "line",
                        on: {
                          click: function ($event) {
                            return _vm.skip(5)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "srcline" }, [
                          _vm._v("【调度中心端】网站"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sing" }, [
                          _vm._v(_vm._s(_vm.urlObj.webgxUrl)),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "botBar" }, [
            _c("p", { staticClass: "slide" }, [
              _vm._v("了解应急任务、成果查看、数据申请等"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "arrow",
              attrs: { src: _vm.ScrollImge, alt: "" },
              on: { click: _vm.scrollView },
            }),
          ]),
          _vm._v(" "),
          _c("Login", { ref: "login", on: { loginSuccess: _vm.initHomeData } }),
          _vm._v(" "),
          _c("Forget", { ref: "forget" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fa-eachar" }, [
      _c("div", { staticClass: "son-eachar" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "airsearch-div iconfont" }, [
      _c("div", { staticClass: "airsearch-div-list" }, [
        _c("div", { staticClass: "circle" }, [
          _c("span"),
          _vm._v("\n              空域申请查询\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "circle-list" }, [
          _vm._v("\n              任务批件\n              "),
          _c("span", { staticStyle: { color: "#f5474a" } }, [_vm._v("")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "circle-list" }, [
          _vm._v("\n              空域批件\n              "),
          _c("span", { staticStyle: { color: "#f5474a" } }, [_vm._v("")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "circle-list" }, [
          _vm._v("\n              长期计划\n              "),
          _c("span", { staticStyle: { color: "#f5474a" } }, [_vm._v("")]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "airsearch-div-list",
          staticStyle: { transform: "translateX(50%)" },
        },
        [
          _c("div", { staticClass: "circle" }, [
            _c("span", { staticStyle: { "background-color": "#20bbef" } }),
            _vm._v("\n              空域有效期查询\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "circle-list" }, [
            _vm._v("\n              空域加载\n              "),
            _c("span", { staticStyle: { color: "#2bbff0" } }, [_vm._v("")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "circle-list" }, [
            _vm._v("\n              填写信息\n              "),
            _c("span", { staticStyle: { color: "#2bbff0" } }, [_vm._v("")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "circle-list" }, [
            _vm._v("\n              确认空域\n              "),
            _c("span", { staticStyle: { color: "#2bbff0" } }, [_vm._v("")]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lefttop" }, [
      _c("img", {
        staticClass: "portalImg",
        attrs: {
          src: require("../../../assets/img/gatewayImg/banner-logo.png"),
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "portalWord" }, [_vm._v("【公众门户网站】")]),
      _vm._v(" "),
      _c("p", { staticClass: "portalline" }, [_vm._v("智慧田长管理平台")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }