var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerWindow" },
    [
      _c("div", { staticClass: "loginBgc" }, [
        _c("div", { staticClass: "fmWelcome" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "interlinkage interlinkage-place" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right",
                    width: "120px",
                    trigger: "hover",
                  },
                },
                [
                  _c("vue-qr", {
                    staticClass: "qr",
                    attrs: {
                      size: 120,
                      margin: 0,
                      "auto-color": true,
                      "dot-scale": 1,
                      text: _vm.htmlUrl,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "showQr",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          return _vm.toDownload("app")
                        },
                      },
                      slot: "reference",
                    },
                    [_vm._v("\n          下载【飞享终端】APP\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toDownload("DataManager")
                    },
                  },
                },
                [_vm._v("下载【DataManager】工具")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fmLoginNav" }, [
          _c("div", { staticClass: "loginNav" }, [
            _c(
              "div",
              { staticClass: "loginBox" },
              [
                _c("p", { staticClass: "loginTitle" }, [_vm._v("登录账号")]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "demo-ruleForm",
                        attrs: { prop: "mobile" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号" },
                          model: {
                            value: _vm.ruleForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mobile", $$v)
                            },
                            expression: "ruleForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "demo-ruleForm",
                        attrs: { prop: "password" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入密码",
                            type: "password",
                          },
                          model: {
                            value: _vm.ruleForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "password", $$v)
                            },
                            expression: "ruleForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "SignInBar" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.agreeStatement,
                              callback: function ($$v) {
                                _vm.agreeStatement = $$v
                              },
                              expression: "agreeStatement",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { color: "rgb(96, 98, 102)" } },
                              [_vm._v("我已同意并认真阅读")]
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "#5185ff" },
                                attrs: { href: "#" },
                                on: { click: _vm.lookStatement },
                              },
                              [_vm._v("《免责声明》")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "loginBar",
                            attrs: {
                              loading: _vm.loading,
                              type: "primary",
                              disabled: !_vm.agreeStatement,
                            },
                            on: { click: _vm.userLogin },
                          },
                          [_vm._v("登  录")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { staticClass: "demo-ruleForm" }, [
                      _c(
                        "div",
                        { staticClass: "otherLogin" },
                        [
                          _c("div", { staticClass: "leftBoder" }),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              staticClass: "FMword",
                              attrs: { underline: false },
                              on: { click: _vm.otherLogin },
                            },
                            [_vm._v("联盟代码登录")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "rightBoder" }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footerBar" },
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "register",
                        attrs: { underline: false },
                        on: { click: _vm.registerNum },
                      },
                      [_vm._v("注册账号")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dividing" }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "forgetPsw",
                        attrs: { underline: false },
                        on: { click: _vm.forgetPsw },
                      },
                      [_vm._v("忘记密码")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "versions" }, [
              _c("div", [_vm._v("调度中心" + _vm._s(_vm.versions))]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "免责声明",
            visible: _vm.disStatemenetData,
            width: "302px",
            center: "",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disStatemenetData = $event
            },
          },
        },
        [
          _c("disclaimer", { attrs: { show: _vm.disStatemenetData } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.disStatemenetData = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fmWelLogin" }, [
      _c("p", { staticClass: "sayHello" }, [_vm._v("您好,")]),
      _vm._v(" "),
      _c("p", { staticClass: "welLogin" }, [_vm._v("欢迎登录")]),
      _vm._v(" "),
      _c("p", { staticClass: "fmsider" }),
      _vm._v(" "),
      _c("p", { staticClass: "fmTitle" }, [_vm._v("智慧田长管理平台")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }