var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subject" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "bodyNav" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("历史已申请数据")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fmNav" },
              [
                _c(
                  "el-button",
                  {
                    style: _vm.ImportSty,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.Import(0)
                      },
                    },
                  },
                  [_vm._v("全部订单")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    style: _vm.drawSty,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.draw(1)
                      },
                    },
                  },
                  [_vm._v("审核中")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    style: _vm.confirmed,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.confir(2)
                      },
                    },
                  },
                  [_vm._v("待确认")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    style: _vm.returned,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.retur(3)
                      },
                    },
                  },
                  [_vm._v("已退回")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    style: _vm.completed,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.comple(4)
                      },
                    },
                  },
                  [_vm._v("已完成")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    style: _vm.termina,
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.termination(5)
                      },
                    },
                  },
                  [_vm._v("已终止")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "midBar" },
              _vm._l(_vm.dataList, function (item, index) {
                return _c("div", { key: index, staticClass: "lineBar" }, [
                  _c("div", { staticClass: "topNav" }, [
                    _c("div", { staticClass: "titles" }, [
                      _c("span", { staticClass: "titword" }, [
                        _vm._v("数据申请单号: " + _vm._s(item.data_apply_no)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                    _vm._v(" "),
                    item.status == 1
                      ? _c("div", { staticClass: "status1" }, [
                          _vm._v("审核中"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.status == 2
                      ? _c("div", { staticClass: "status2" }, [
                          _vm._v("待确认"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.status == 3
                      ? _c("div", { staticClass: "status3" }, [
                          _vm._v("已退回"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.status == 4
                      ? _c("div", { staticClass: "status4" }, [
                          _vm._v("已完成"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.status == 5
                      ? _c("div", { staticClass: "status3" }, [
                          _vm._v("已终止"),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "botNav" }, [
                    _c(
                      "div",
                      { staticClass: "typeNav" },
                      [
                        _c("span", { staticClass: "application" }, [
                          _vm._v("数据申请类型"),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          JSON.parse(item.category),
                          function (item, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "type" },
                              [_vm._v(_vm._s(item))]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dataNav" }, [
                      _c("span", { staticClass: "dataType" }, [
                        _vm._v("数据完整性"),
                      ]),
                      _vm._v(" "),
                      item.integrality == 0
                        ? _c("span", { staticClass: "types" }, [
                            _vm._v("可接受只覆盖部分区域"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.integrality == 1
                        ? _c("span", { staticClass: "types" }, [
                            _vm._v("只接受只全区域覆盖"),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "waitNav" }, [
                      _c("span", { staticClass: "waitingperiod" }, [
                        _vm._v("可接受的反馈等待期"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "waitday" }, [
                        _vm._v(_vm._s(item.wait_time)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "watchNab" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#0094ff" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.wacthBtn(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.current_page,
                    "page-size": _vm.per_page,
                    layout: "total,  prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "current-change": _vm.currentchange,
                    "next-click": _vm.handleNext,
                    "prev-click": _vm.handlePrev,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }