var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tuban" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "50%" } }, [
      _c(
        "div",
        { staticStyle: { "margin-left": "30px" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.radio1,
                callback: function ($$v) {
                  _vm.radio1 = $$v
                },
                expression: "radio1",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "线索信息" } }),
              _vm._v(" "),
              _c("el-radio-button", {
                attrs: { label: "审核复核", disabled: "" },
              }),
              _vm._v(" "),
              _c("el-radio-button", {
                attrs: { label: "实地照片", disabled: "" },
              }),
              _vm._v(" "),
              _c("el-radio-button", {
                attrs: { label: "无人机照片", disabled: "" },
              }),
              _vm._v(" "),
              _c("el-radio-button", {
                attrs: { label: "现场视频", disabled: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "setBack" }, [_vm._v("基本信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "事件名称", prop: "name" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", $$v)
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "事件编号", prop: "name1" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name1,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name1", $$v)
                        },
                        expression: "ruleForm.name1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "行政区代码", prop: "name2" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name2,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name2", $$v)
                        },
                        expression: "ruleForm.name2",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "行政区名称", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name3,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name3", $$v)
                        },
                        expression: "ruleForm.name3",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "违法地点", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name4,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name4", $$v)
                        },
                        expression: "ruleForm.name4",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "图斑面积（亩）", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name5,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name5", $$v)
                        },
                        expression: "ruleForm.name5",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "举报人", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name6,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name6", $$v)
                        },
                        expression: "ruleForm.name6",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "举报时间", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name7,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name7", $$v)
                        },
                        expression: "ruleForm.name7",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "疑似违法类型", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name8,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name8", $$v)
                        },
                        expression: "ruleForm.name8",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "事件描述", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name9,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name9", $$v)
                        },
                        expression: "ruleForm.name9",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "setBack" }, [_vm._v("外业信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "外业核实结果", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name10,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name10", $$v)
                        },
                        expression: "ruleForm.name10",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "外业违法类型", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name11,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name11", $$v)
                        },
                        expression: "ruleForm.name11",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "外业描述", prop: "region" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name12,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name12", $$v)
                        },
                        expression: "ruleForm.name12",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "center" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.submitForm("ruleForm")
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { height: "100%", width: "50%" } }, [
      _c("div", { staticStyle: { width: "100%", "text-align": "center" } }, [
        _vm._v("图斑范围"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "map", attrs: { id: "map1" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }