var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: true,
            center: true,
            title: "新建航线任务引导",
            visible: _vm.dialogVisible,
            width: "62.5vw",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "feimaCarousel",
              attrs: { loop: false, trigger: "click", height: "45.46vh" },
              on: {
                change: function ($event) {
                  return _vm.changeItem()
                },
              },
            },
            _vm._l(4, function (item) {
              return _c("el-carousel-item", { key: item }, [
                _c("img", {
                  staticClass: "newFm-img",
                  attrs: {
                    src: require("../assets/img/newFm/newFm-" + item + ".png"),
                    alt: "",
                  },
                }),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                bottom: "57px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#838A9D",
              },
            },
            [_vm._v(_vm._s(_vm.textValue))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                bottom: "24px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#007EFE",
              },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.nowItemIndex + 1)),
              _c("span", { staticStyle: { color: "#9EA2B0" } }, [_vm._v("/4")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn",
              staticStyle: { bottom: "57px" },
              on: { click: _vm.toWin },
            },
            [_vm._v("下载无人机管家")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn",
              staticStyle: { bottom: "24px" },
              on: { click: _vm.toIpad },
            },
            [_vm._v("下载无人机管家iPad")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "padding-bottom": "106px" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }