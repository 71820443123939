import store from '@/store'
export function selectNavSide(path,query){

    const backEndPathlist = [
      ['/flying','/taskPush'],
      ['/uav','/teamterminal'],
      ['/alliance','/terminal','/portal','/ranking'],
      ['/achievement','/released','/publish'],
      ['/airspaceQuery','/airspaceApplied','/pendingApprova','/approved','/authorizedAirspace','/airspaceCataloging'],
      ['/taskstat','/userstat','/flystat'],
      ['/information','/setting'],
      ['/taskpage','/actionpage','/shpage'],
      ['/permissionManagement','/onfiguration'],
      ['/airspaceQuery','/airspaceApplied','/pendingApprova','/approved','/authorizedAirspace','/airspaceCataloging'],
    ];
    const backEndTopNavName = [
      ['任务下达','任务下达'],
      ['应急装备'],
      ['用户管理'],
      ['目录查询'],
      ['空域查询'],
      ['统计分析'],
      ['个人中心'],
      ['消息通知'],
      ['权限配置']
    ]
    const backEndSideName = [
      ['新建任务','航线任务','通用任务'],
      ['无人机','队伍终端'],
      ['联盟单位','联盟终端用户','门户网站用户','排行榜'],
      ['数据目录','待发布','已发布'],
      ['空域查询','已申请空域','待审批空域','已审批空域','加载授权空域','授权空域编目'],
      ['任务统计',' 用户统计','飞行统计'],
      ['个人信息','账户设置'],
      ['待办任务','标准动作指令','SHP文件'],
      ['权限管理','配置管理'],
      ["发布任务", "全部任务","待核任务","核发任务","退回任务","草拟任务"]
    ];
    const portalPathlist = [
      ['/getawayTask'],
      ['/result'],
      ['/application'],
      ['/airsearch','/authorization'],
      ['/publicPortalWeather'],
      ['/sentiment'],
      ['/warning'],
      ['/personal','/settings','/Applied','/issued','/Airspace'],
      ['/datamanage','/warningmanage']
    ];
    const portalTopName = [
      ['应急任务'],
      ['目录查询'],
      ['数据申请'],
      ['空域查询'],
      ['气象服务'],
      ['舆情发布'],
      ['预警发布'],
      ['个人信息','账户设置','已申请数据','已发布预警','已申请空域'],
      ['数据申请管理','预警发布管理']
    ]
    const portalSideName = [
      ['航线任务','通用任务'],
      ['目录查询'],
      ['数据申请'],
      ['空域查询','已加载授权空域'],
      ['气象服务'],
      ['舆情发布'],
      ['预警发布'],
      ['个人信息','账户设置','已申请数据','已发布预警','已申请空域'],
      ['数据申请管理','预警发布管理']
    ]
    let topNavName,sideName,pathlist;
    if ( window.runEnv !== 'portal') {
      topNavName = backEndTopNavName;
      sideName = backEndSideName;
      pathlist = backEndPathlist;
    }else {
      topNavName = portalTopName;
      sideName = portalSideName;
      pathlist = portalPathlist;
    }
    console.log("ing...")
    for (let i=0; i<pathlist.length ;i++){
      let index = pathlist[i].indexOf(path);
      ;
      if (index !== -1){
        store.commit('navMessageStorage', i);
        if (!isNaN(Number(query.nav))){

          store.commit('currentUrlStorage', ['首页', topNavName[i][0],sideName[i][Number(query.nav)]]);
        }else {
          store.commit('currentUrlStorage', ['首页', topNavName[i][0],sideName[i][index]]);
        }
        break;
      }
    }
  }
