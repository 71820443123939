var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dia_body" }, [
    _vm.popType === "params"
      ? _c("div", { staticClass: "params" }, [
          _c("div", { staticStyle: { height: "10px" } }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "10px" } }),
          _vm._v(" "),
          _vm._m(1),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.popType === "drone"
      ? _c(
          "div",
          [
            _c("div", { staticStyle: { height: "10px" } }),
            _vm._v(" "),
            _c("div", { staticClass: "sn_id setSnIDPadding" }, [
              !_vm.terminal.isSurveyingmapping
                ? _c(
                    "div",
                    { staticStyle: { width: "73px", "margin-right": "10px" } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.terminal.device_data.device_brands_icon,
                          alt: "",
                          width: "73",
                        },
                      }),
                    ]
                  )
                : _c(
                    "div",
                    { staticStyle: { width: "93px", "margin-right": "10px" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/homeDialog/Surveyingmapping.png"),
                          width: "100%",
                        },
                      }),
                    ]
                  ),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "135px" } }, [
                _c("ul", [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("li", { staticClass: "snIdData" }, [
                    _vm._v(_vm._s(_vm.terminal.droneInfo.software_code)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.monitorType !== "point" && !_vm.terminal.isSurveyingmapping
                ? _c("div", { staticClass: "play" }, [
                    _c("img", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        src: require("@/assets/img/homeDialog/play.png"),
                      },
                      on: { click: _vm.toDetail },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "location setLocationPadding" }, [
              _c("div", [_vm._v("当前位置")]),
              _vm._v(" "),
              _vm.terminal.device_data.last_point.city !== undefined
                ? _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "cityAndarea",
                        attrs: {
                          title:
                            _vm.terminal.device_data.last_point.city +
                            "-" +
                            _vm.terminal.device_data.last_point.area,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminal.device_data.last_point.city +
                              "-" +
                              _vm.terminal.device_data.last_point.area
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "setLocation",
                        on: {
                          click: function ($event) {
                            return _vm.goLocation([
                              _vm.terminal.device_data.last_point.lon,
                              _vm.terminal.device_data.last_point.lat,
                            ])
                          },
                        },
                      },
                      [_vm._v("定位")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.terminal.device_data.last_point.lat !== undefined
              ? _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lat.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("纬度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.last_point.lat
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lng.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("经度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.last_point.lon
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _vm._v("暂无飞行记录"),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "location setLocationPadding" }, [
              _c("div", [_vm._v("上次位置")]),
              _vm._v(" "),
              _vm.terminal.device_data.last_two_point.city !== undefined
                ? _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "cityAndarea",
                        attrs: {
                          title:
                            _vm.terminal.device_data.last_two_point.city +
                            "-" +
                            _vm.terminal.device_data.last_two_point.area,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminal.device_data.last_two_point.city +
                              "-" +
                              _vm.terminal.device_data.last_two_point.area
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "setLocation",
                        on: {
                          click: function ($event) {
                            return _vm.goLocation([
                              _vm.terminal.device_data.last_two_point.lon,
                              _vm.terminal.device_data.last_two_point.lat,
                            ])
                          },
                        },
                      },
                      [_vm._v("定位")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.terminal.device_data.last_two_point.lat !== undefined
              ? _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lat.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("纬度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.last_two_point.lat
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lng.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("经度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.last_two_point.lon
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _vm._v("暂无飞行记录"),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "location setLocationPadding" }, [
              _c("div", [_vm._v("登记位置")]),
              _vm._v(" "),
              _vm.terminal.device_data.register_point.city !== undefined
                ? _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "cityAndarea",
                        attrs: {
                          title:
                            _vm.terminal.device_data.register_point.city +
                            "-" +
                            _vm.terminal.device_data.register_point.area,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminal.device_data.register_point.city +
                              "-" +
                              _vm.terminal.device_data.register_point.area
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "setLocation",
                        on: {
                          click: function ($event) {
                            return _vm.goLocation([
                              _vm.terminal.device_data.register_point.lon,
                              _vm.terminal.device_data.register_point.lat,
                            ])
                          },
                        },
                      },
                      [_vm._v("定位")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.terminal.device_data.register_point.lat !== undefined &&
            Number(_vm.terminal.device_data.register_point.lat) !== 0
              ? _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lat.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("纬度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.register_point.lat
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lng.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("经度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.register_point.lon
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("span", [
                    _vm._v("暂无登记位置"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.terminal.device_data.is_third === 0,
                            expression: "terminal.device_data.is_third === 0",
                          },
                        ],
                        staticClass: "toRegisterStyle",
                        on: { click: _vm.toRegister },
                      },
                      [_vm._v("，去登记")]
                    ),
                  ]),
                ]),
            _vm._v(" "),
            _c("div", { staticStyle: { height: "10px" } }),
            _vm._v(" "),
            _c("deviceData", { attrs: { terminal: _vm.listDeviceData } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.popType === "terminal"
      ? _c(
          "div",
          [
            _c("div", { staticClass: "location setLocationPadding" }, [
              _c("div", [_vm._v("当前位置")]),
              _vm._v(" "),
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "span",
                  {
                    staticClass: "cityAndarea",
                    attrs: { title: _vm.city + "-" + _vm.district },
                  },
                  [_vm._v(_vm._s(_vm.city) + "-" + _vm._s(_vm.district))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "setLocation",
                    on: {
                      click: function ($event) {
                        return _vm.goLocation([
                          _vm.terminal.nowloaction[0],
                          _vm.terminal.nowloaction[1],
                        ])
                      },
                    },
                  },
                  [_vm._v("定位")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lnglat setLocationPadding" }, [
              _c("div", { staticClass: "setImg" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/lat.png"),
                    alt: "",
                    width: "20",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("纬度")]),
                _c("span", { staticClass: "lnglatData" }, [
                  _vm._v(
                    _vm._s(_vm._f("saveLnglat")(_vm.terminal.nowloaction[1]))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "setImg" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/lng.png"),
                    alt: "",
                    width: "20",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("经度")]),
                _c("span", { staticClass: "lnglatData" }, [
                  _vm._v(
                    _vm._s(_vm._f("saveLnglat")(_vm.terminal.nowloaction[0]))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "location setLocationPadding" }, [
              _c("div", [_vm._v("登记位置")]),
              _vm._v(" "),
              _vm.terminal.device_data.register_point.city !== undefined
                ? _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "cityAndarea",
                        attrs: {
                          title:
                            _vm.terminal.device_data.register_point.city +
                            "-" +
                            _vm.terminal.device_data.register_point.area,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminal.device_data.register_point.city +
                              "-" +
                              _vm.terminal.device_data.register_point.area
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "setLocation",
                        on: {
                          click: function ($event) {
                            return _vm.goLocation([
                              _vm.terminal.device_data.register_point.lon,
                              _vm.terminal.device_data.register_point.lat,
                            ])
                          },
                        },
                      },
                      [_vm._v("定位")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.terminal.device_data.register_point.lat !== undefined &&
            Number(_vm.terminal.device_data.register_point.lat) !== 0
              ? _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lat.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("纬度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.register_point.lat
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "setImg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/lng.png"),
                        alt: "",
                        width: "20",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("经度")]),
                    _c("span", { staticClass: "lnglatData" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("saveLnglat")(
                            _vm.terminal.device_data.register_point.lon
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "lnglat setLngLatPadding" }, [
                  _c("span", [
                    _vm._v("暂无登记位置"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.terminal.device_data.is_third === 0,
                            expression: "terminal.device_data.is_third === 0",
                          },
                        ],
                        staticClass: "toRegisterStyle",
                        on: { click: _vm.toRegister },
                      },
                      [_vm._v("，去登记")]
                    ),
                  ]),
                ]),
            _vm._v(" "),
            _c("div", { staticStyle: { height: "10px" } }),
            _vm._v(" "),
            _c("deviceData", { attrs: { terminal: _vm.listDeviceData } }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.singleDialogVisible
      ? _c(
          "span",
          [
            _c("dialog-single-monitor", {
              attrs: {
                terminal: _vm.terminal,
                listDeviceData: _vm.listDeviceData,
                nowShowLayer: _vm.nowShowLayer,
              },
              on: { closeDialogSingleMonitor: _vm.closeDialogSingleMonitor },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "setSnIDPadding device_type",
        staticStyle: { height: "83px" },
      },
      [
        _c("div", { staticStyle: { color: "#fff" } }, [
          _vm._v("广西航测项目—区块1—任务1"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", [
            _c("img", {
              attrs: {
                src: require("@/assets/img/homeDialog/device_type.png"),
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("设备类型")]),
          _vm._v(" "),
          _c("span", [_vm._v("D2000")]),
          _vm._v(" "),
          _c("span", [
            _c("img", {
              staticStyle: { position: "relative", top: "5px" },
              attrs: {
                src: require("@/assets/img/homeDialog/load.png"),
                alt: "",
                width: "23",
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("荷载")]),
          _vm._v(" "),
          _c("span", [_vm._v("D-LiDAR150")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setSnIDPadding plane_data" }, [
      _c("table", [
        _c("tr", [
          _c("td", { staticClass: "params_name" }, [_vm._v("比例尺")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "params_title" }, [_vm._v("GSD")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_data" }, [_vm._v("2.00cm")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_title" }, [_vm._v("海拔高度")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_data" }, [_vm._v("160m")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "params_title" }, [_vm._v("航向重叠")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_data" }, [_vm._v("80%")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_title" }, [_vm._v("飞行高度")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_data" }, [_vm._v("160m")]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "params_title" }, [_vm._v("旁向重叠")]),
          _vm._v(" "),
          _c("td", { staticClass: "params_data" }, [_vm._v("60%")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "snIdTitle" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/homeDialog/snId.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [_vm._v("SN码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }