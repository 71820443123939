<template>
  <div class="uavBody">
    <navTop />
    <div class="bodyBar">
      <div class="titleBar">预警发布管理</div>
      <el-divider></el-divider>
      <div class="top">
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
        >
          <el-form-item
            class="userForm"
            prop="disaster_name"
            label="预警灾害"
            style="width: 300px"
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.disaster_name"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="advise"
            label="灾害建议"
            style="width: 300px"
          >
            <el-select
              v-model="ruleForm.advise"
              @change="getStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
            <!-- <el-input placeholder="请输入" v-model="ruleForm.advise"></el-input> -->
          </el-form-item>
          <el-form-item class="userForm" prop="DateRange" label="创建时间区间">
            <el-date-picker
              v-model="ruleForm.DateRange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDateRange"
            ></el-date-picker>
          </el-form-item>
          <div class="footBtn">
            <div class="fmBtn">
              <el-button class="searchBtn" type="primary" @click="searchBtn"
                >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
              >
              <el-button
                class="reset"
                @click="resetForm('ruleForm')"
                type="primary"
                plain
                >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableBar">
        <el-table
          height="80%"
          :stripe="true"
          :header-cell-style="{ background: '#DDE6EF' }"
          :data="tableData"
        >
          <el-table-column
            align="center"
            prop="id"
            label="发布预警ID"
            width="100"
            class="number"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="area_name"
            label="区域名称"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="disaster_name"
            label="预警灾害"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="advise"
            label="预警建议"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="账户"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="contact"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="created_at"
            label="创建时间"
          ></el-table-column>
          <el-table-column align="center" label="审核">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 0"
                @click="acceptance(scope.row)"
                type="text"
                >通过</el-button
              >
              <el-divider
                v-if="scope.row.status == 0"
                direction="vertical"
              ></el-divider>
              <el-button
                v-if="scope.row.status == 0"
                @click="reject(scope.row)"
                type="text"
                class="blok"
                >驳回</el-button
              >
              <span class="status" v-if="scope.row.status == 1">已通过</span>
              <span class="status" v-if="scope.row.status == 2">已驳回</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="editBtn(scope.row)" type="text"
                >详情</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button @click="delBtn(scope.row.id)" type="text" class="blok"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      ruleForm: {
        disaster_name: "",
        advise: "",
        start_time: "",
        end_time: "",
        DateRange: [],
      },
      options: [
        {
          value: "1",
          label: "及其严重",
        },
        {
          value: "2",
          label: "严重",
        },
        {
          value: "3",
          label: "中度",
        },
        {
          value: "4",
          label: "一般",
        },
        {
          value: "5",
          label: "轻微",
        },
      ],
      tableData: [],
      start_datetime: "",
      end_datetime: "",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.start_datetime = "";
      this.end_datetime = "";
      this.$refs["ruleForm"].resetFields();
    },
    // 详情
    editBtn(param) {
      this.$router.push({ path: "/details", query: { id: param.id } });
    },
    // 表格数据
    pending() {
      this.$apis
        .manageWarning(
          {
            per_page: 8,
            page: this.page,
            disaster_name: this.ruleForm.disaster_name,
            advise: this.ruleForm.advise,
            start_time: this.start_datetime,
            end_time: this.end_datetime,
          },
          this.token
        )
        .then((data) => {
          this.tableData = data.data.data.data;
          this.total = data.data.data.total;
          this.current_page = data.data.data.current_page;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取时间
    getDateRange() {
      this.start_datetime = this.ruleForm.DateRange[0] + " " + "00:00:00";
      this.end_datetime = this.ruleForm.DateRange[1] + " " + "23:59:59";
    },
    // 删除
    delBtn(param) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .deleteWarning(
              {
                id: param,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  showClose: true,
                  message: data.data.message,
                  type: "success",
                });
                this.pending();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    // 获取预警建议
    getStatus(param) {
      this.ruleForm.advise = param;
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.pending();
    },
    // 受理
    acceptance(param) {
      this.datasubmit(1, param.id);
    },
    // 驳回
    reject(param) {
      this.datasubmit(2, param.id);
    },
    // 是否受理
    datasubmit(data, id) {
      this.$confirm("是否继续此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .auditWarning(
              {
                status: data,
                id: id,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  showClose: true,
                  message: data.data.message,
                  type: "success",
                });
                this.pending();
              }
            })
            .catch((err) => {
              console.error(err);
            });
          this.pending();
        })
        .catch(() => {});
    },
    // 分页
    handleNext() {
      this.page++;
      this.pending(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.pending(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.pending(this.page, this.per_page);
    },
  },
  created() {
    this.pending();
  },
  computed: {
    ...mapState(["navMessage", "role", "token", "user", "currentUrl"]),
  },
  mounted() {
    if (this.$route.query.nav == "wman") {
      this.currentUrlStorage(["首页", "后台管理", "预警发布管理"]);
    }
  },
};
</script>

<style lang="less" scoped>
.status {
  color: #c0c4cc;
  font-size: 14px;
}
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
.block {
  float: left;
  margin-top: 15px;
}
.bodyBar {
  width: 100%;
  height: 90%;
  float: left;
  background-color: #fff;
  padding: 23px 20px 0px 20px;
}
.uavBody {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 15px 30px;
}
.titleBar {
  font-size: 16px;
  color: #c7c7c7;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  width: 35%;
  margin-left: 5px;
}
.blok {
  color: #fd5c5e;
}
.tableBar {
  height: 90%;
}
</style>