var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerHome" },
    [
      _c("Monitor", {
        ref: "monitor",
        attrs: {
          requestDataManager: _vm.requestDataManager,
          onlineSortiesData: _vm.onlineSortiesData,
          onlineLayerData: _vm.onlineLayerData,
          allDeviceLayerData: _vm.allDeviceLayerData,
          netWorkIcon: _vm.netWorkIcon,
          allRequsetLoading: _vm.allRequsetLoading,
        },
        on: { setTimerRun: _vm.setTimerRun },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isHiddenAll,
              expression: "!isHiddenAll",
            },
          ],
          staticClass: "bottomWindow",
        },
        [
          _c("bottom-bar", {
            attrs: {
              selected: _vm.isShowDio,
              datas: {
                networkingTotal: _vm.deviceTotal,
                onlineTotal: _vm.onlineTotal,
                flyAreaTotal: _vm.flyAreaTotal,
                taskTotal: _vm.taskTotal,
              },
            },
            on: { showDio: _vm.showDio },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rightWindow" },
        [
          _c("rightBar", {
            ref: "rightBar",
            attrs: { isHiddenAll: _vm.isHiddenAll },
            on: {
              hiddenAll: _vm.hiddenAll,
              showRightDioBtn: _vm.showRightDioBtn,
              chat: _vm.chat,
              switchMap: _vm.switchMap,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showData && !_vm.isHiddenAll && !_vm.clickOutsideLeftWindow
        ? _c("div", { staticClass: "leftWindow" }, [
            _c("div", [
              _c("div", { staticClass: "header" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 1,
                        expression: "isShowDio === 1",
                      },
                    ],
                    staticClass: "flyArea networkingDevices",
                  },
                  [_vm._m(0)]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 2 || _vm.isShowDio === 3,
                        expression: "isShowDio === 2 || isShowDio === 3",
                      },
                    ],
                    staticClass: "networkingDevices",
                  },
                  [
                    _c("div", { staticClass: "networkingDeviceTitle" }, [
                      _c("div", { staticClass: "networkingName" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isShowDio === 2 ? "在线田长数量" : "田长总数量"
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "450px",
                          margin: "10px 30px 10px 45px",
                          overflow: "auto",
                          width: "94%",
                        },
                      },
                      _vm._l(
                        _vm.isShowDio === 2 ? 10 : 50,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              staticStyle: {
                                height: "40px",
                                "line-height": "40px",
                              },
                            },
                            [
                              _c("div", { staticClass: "onlineListUser" }, [
                                _c("div", [
                                  _c("i", {
                                    staticClass: "el-icon-s-custom",
                                    staticStyle: { color: "#fff" },
                                  }),
                                  _vm._v(
                                    "\n                  二级田长" +
                                      _vm._s(index) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.isShowDio === 2 ? "吴某某" : "张三"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                  180000000" +
                                      _vm._s(_vm.isShowDio === 2 ? "1" : "2") +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._m(1, true),
                                _vm._v(" "),
                                _vm._m(2, true),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 4,
                        expression: "isShowDio === 4",
                      },
                    ],
                    staticClass: "flyArea networkingDevices",
                  },
                  [_vm._m(3)]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("div", { staticStyle: { height: "33px" } }),
                  _vm._v(" "),
                  _c("dioContent", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.isSelectBtn === 1 && _vm.showRingChart) ||
                          _vm.isShowDio === 2,
                        expression:
                          "(isSelectBtn === 1 && showRingChart) || isShowDio === 2",
                      },
                    ],
                    attrs: { datas: _vm.dioContentData },
                  }),
                  _vm._v(" "),
                  _vm.isSelectBtn === 1 && _vm.showBarChart
                    ? _c("comBar", {
                        attrs: {
                          options: _vm.comBarData,
                          "element-loading-background": "rgba(0, 0, 0, 0)",
                        },
                        on: { rangeChange: _vm.onRangeChange },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footer" }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowtTasksAndSorties,
              expression: "isShowtTasksAndSorties",
            },
          ],
          staticClass: "tasksAndSorties",
        },
        [
          _c("com-bj-dio", {
            ref: "ComBjDio",
            attrs: {
              isTasks: _vm.isTasks,
              requestDataManager: _vm.requestDataManager,
              onlineSortiesData: _vm.onlineSortiesData,
              deviceData: _vm.categoryDevice,
              isShowtTasksAndSorties: _vm.isShowtTasksAndSorties,
            },
            on: { toMore: _vm.toMultiViews },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMultiViews
        ? _c("MultiMonitors", {
            attrs: {
              show: _vm.showMultiViews,
              onlineSortiesData: _vm.onlineSortiesData,
              onlineLayerData: _vm.onlineLayerData,
            },
            on: {
              close: function ($event) {
                _vm.showMultiViews = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "networkingDeviceTitle" }, [
      _c("div", { staticClass: "networkingName" }, [_vm._v("处置案件数量")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
      _vm._v("\n                  视频\n                "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
      _vm._v("\n                  短信\n                "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "networkingDeviceTitle" }, [
      _c("div", { staticClass: "networkingName" }, [_vm._v("接收案件数量")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }