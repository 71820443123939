var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TopBar"),
      _vm._v(" "),
      _c("SiderBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHasSidebar,
            expression: "isHasSidebar",
          },
        ],
      }),
      _vm._v(" "),
      _c("app-main", {
        class: [
          "app-main-portal",
          { "app-main-no-siderBar": !_vm.isHasSidebar },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }