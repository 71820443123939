// 统计相关接口
import {
  fly,
  baseWeatherURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  获取天气地图数据
export const getMapWeatherData = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/getMapWeatherData',
  data, deploy(30000, token)
)

// 获取省份城市天气
export const getProvinceWeather = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/getProvinceWeather',
  data, deploy(30000, token)
)

// 获取省份城市天气
export const getWeatherIsogram = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/getWeatherIsogram',
  data, deploy(30000, token)
)

// 获取城市逐天天气
export const getCityWeatherByDay = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/day',
  data, deploy(30000, token)
)

// 获取城市实时天气
export const getCityWeatherByNow = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/now',
  data, deploy(30000, token)
)

// 获取城市生活指数
export const getCityIndices = (data, token) => fly.get(
  baseWeatherURL + '/weather/weather/indices',
  data, deploy(30000, token)
)

// 月度天气统计
export const getMonthlyWeatherStat = (data, token) => fly.get(
  baseWeatherURL + '/weather/stat/getMonthlyWeatherStat',
  data, deploy(30000, token)
)

// 年度天气统计
export const getAnnualWeatherStat = (data, token) => fly.get(
  baseWeatherURL + '/weather/stat/getAnnualWeatherStat',
  data, deploy(30000, token)
)