<template>
  <div>
    <el-dialog
      title="添加兴趣标注"
      :visible.sync="dialogVisible"
      class="taskBox"
      @click="turnon"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-divider />
      <div class="detailBar">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
        >
          <!--  -->
          <el-form-item label="名称" prop="tagname">
            <el-input v-model="ruleForm.tagname" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="ruleForm.type" placeholder="请选择">
              <el-option label="类型1" value="shanghai" />
              <el-option label="类型2" value="beijing" />
            </el-select>
          </el-form-item>
          <el-form-item label="高度" prop="tagheight">
            <el-input v-model="ruleForm.tagheight" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="描述" prop="discribe">
            <el-input
              v-model="ruleForm.discribe"
              class="textarea"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10 }"
            />
          </el-form-item>
        </el-form>
      </div>
      <el-divider />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addNewTag">添 加</el-button>
        <el-button type="primary" plain>取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: true,
      rules: {
        tagname: [
          { required: true,message: '名称不能为空',trigger: 'blur' }
        ],
        tagheight: [
          { required: true,message: '类型不能为空',trigger: 'blur' }
        ],
        type: [
          { required: true,message: '类型不能为空',trigger: 'change' }
        ]
      },
      ruleForm: {
        company: '',
        task: '',
        tagname: '',
        tagheight: '',
        discribe: '',
        type: ''
      }
    }
  },
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    turnon () {
      this.dialogVisible=true
    },
    addNewTag () {
      this.$refs['ruleForm'].validate(valid => {
        if(valid) {
          this.submitData()
        } else {
          this.loading=false
        }
      })
    },
    submitData () {

    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  height: 617px;
  width: 432px;
}
/deep/.el-dialog__title {
  color: #000000;
  font-size: 20px;
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-form-item__label {
  font-size: 16px;
  color: #838a9d;
  margin-right: 20px;
}
/deep/.el-form-item {
  margin-left: 20px;
  margin-bottom: 32px;
}

/deep/.el-input {
  width: 240px;
  height: 40px;
}
/deep/.el-dialog__headerbtn {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 36px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.textarea {
  width: 240px;
}
/deep/.el-button--medium {
  padding: 15px 60px;
}
/deep/.el-form-item__error {
  color: #ff4949;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 29%;
}
</style>
