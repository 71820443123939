var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value1,
          expression: "value1",
        },
      ],
      staticClass: "historyInp",
      attrs: { type: "checkbox", id: "Google", value: "限制区域" },
      domProps: {
        checked: Array.isArray(_vm.value1)
          ? _vm._i(_vm.value1, "限制区域") > -1
          : _vm.value1,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.value1,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "限制区域",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value1 = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value1 = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value1 = $$c
            }
          },
          function ($event) {
            return _vm.limit(_vm.value1)
          },
        ],
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "flystatus", style: _vm.limitSty }, [
      _vm._v("限制区域"),
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value2,
          expression: "value2",
        },
      ],
      staticClass: "historyInp",
      attrs: { type: "checkbox", id: "Runoob", value: "存续空域" },
      domProps: {
        checked: Array.isArray(_vm.value2)
          ? _vm._i(_vm.value2, "存续空域") > -1
          : _vm.value2,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.value2,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "存续空域",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value2 = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value2 = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value2 = $$c
            }
          },
          function ($event) {
            return _vm.subsist(_vm.value2)
          },
        ],
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "flystatus", style: _vm.subsistSty }, [
      _vm._v("存续空域"),
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value3,
          expression: "value3",
        },
      ],
      staticClass: "historyInp",
      attrs: { type: "checkbox", id: "guoqi", value: "过期空域" },
      domProps: {
        checked: Array.isArray(_vm.value3)
          ? _vm._i(_vm.value3, "过期空域") > -1
          : _vm.value3,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.value3,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = "过期空域",
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value3 = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value3 = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value3 = $$c
            }
          },
          function ($event) {
            return _vm.overdue(_vm.value3)
          },
        ],
      },
    }),
    _vm._v(" "),
    _c("span", { staticClass: "flystatus", style: _vm.overdueSty }, [
      _vm._v("过期空域"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }