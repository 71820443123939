var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pageBar" }, [
    _c("div", { staticClass: "bodyBar" }, [
      _c("div", { staticClass: "tabelNav" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("详情")]),
            _vm._v(" "),
            _c("el-divider"),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "midlle" }, [
          _c("span", { staticClass: "tit" }, [_vm._v("基本信息")]),
          _vm._v(" "),
          _vm.dataObj
            ? _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "infobar" }, [
                  _c("p", { staticClass: "titleword" }, [_vm._v("订单编号")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.dataObj.data_apply_no)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "infobar" }, [
                  _c("p", { staticClass: "titleword" }, [_vm._v("开放期")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "data" }, [
                    _vm._v(_vm._s(_vm.dataObj.wait_time)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "infobar" }, [
                  _c("p", { staticClass: "titleword" }, [_vm._v("申请类型")]),
                  _vm._v(" "),
                  _vm.dataObj.category !== undefined
                    ? _c(
                        "div",
                        { staticClass: "types" },
                        _vm._l(
                          JSON.parse(_vm.dataObj.category),
                          function (item, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "data" },
                              [_vm._v(_vm._s(item) + " ")]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "infobar" }, [
                  _c("p", { staticClass: "titleword" }, [_vm._v("数据完整性")]),
                  _vm._v(" "),
                  _vm.dataObj.integrality == 0
                    ? _c("p", { staticClass: "data" }, [
                        _vm._v(
                          "\n              可接受只覆盖部分区域\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dataObj.integrality == 1
                    ? _c("p", { staticClass: "data" }, [
                        _vm._v(
                          "\n              只接受只全区域覆盖\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _c("span", { staticClass: "tit" }, [_vm._v("基本信息")]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                attrs: {
                  height: "170",
                  stripe: true,
                  "header-cell-style": { background: "#DDE6EF" },
                  data: _vm.tableData,
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", selectable: _vm.checkSelectable },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: {
                    align: "center",
                    prop: "task_id",
                    label: "任务编号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: {
                    align: "center",
                    prop: "uas_serial_id",
                    label: "飞机序列号",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                scope.row.uas_serial_id == ""
                                  ? "未同步"
                                  : scope.row.uas_serial_id
                              ) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: {
                    align: "center",
                    prop: "plane_type",
                    label: "飞机型号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: {
                    align: "center",
                    prop: "unit_name",
                    label: "所属公司名字",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: { align: "center", prop: "work_area", label: "面积" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: { align: "center", label: "报价" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            on: {
                              change: function ($event) {
                                return _vm.input(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.input,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "input", $$v)
                              },
                              expression: "scope.row.input",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: { align: "center", label: "服务费" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            on: {
                              change: function ($event) {
                                return _vm.charge(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.charge,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "charge", $$v)
                              },
                              expression: "scope.row.charge",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "number",
                  attrs: {
                    align: "center",
                    prop: "total_price",
                    label: "总价",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                Number(scope.row.input) +
                                  Number(scope.row.charge) >
                                  0
                                  ? Number(scope.row.input) +
                                      Number(scope.row.charge)
                                  : 0
                              ) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "midNav" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("审核通过")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formbox",
              attrs: { model: _vm.ruleForm, "label-width": "110px" },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "plane_type_number", label: "飞机序列号种类" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.ruleForm.plane_type_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "plane_type_number", $$v)
                      },
                      expression: "ruleForm.plane_type_number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "work_area", label: "目标覆盖面积" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.ruleForm.work_area,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "work_area", $$v)
                      },
                      expression: "ruleForm.work_area",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "period", label: "供给周期" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "period", $$v)
                      },
                      expression: "ruleForm.period",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "total_price", label: "总价" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.ruleForm.total_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "total_price", $$v)
                      },
                      expression: "ruleForm.total_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "unit_qty_1", label: "持有单位数量" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.ruleForm.unit_qty_1,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "unit_qty_1", $$v)
                      },
                      expression: "ruleForm.unit_qty_1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "unit_qty_2", label: "提供单位数量" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", disabled: "" },
                    model: {
                      value: _vm.ruleForm.unit_qty_2,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "unit_qty_2", $$v)
                      },
                      expression: "ruleForm.unit_qty_2",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "contact", label: "联系方式" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "contact", $$v)
                      },
                      expression: "ruleForm.contact",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "userForm" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "buttonBar",
                      attrs: { type: "primary" },
                      on: { click: _vm.formBtn },
                    },
                    [_vm._v("审核通过")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "botNav" },
        [
          _c("p", { staticClass: "title" }, [_vm._v("审核退回")]),
          _vm._v(" "),
          _c("span", { staticClass: "returns" }, [_vm._v("退回原因")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formNav",
              attrs: { model: _vm.returnForm },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "seruleform", attrs: { prop: "software_code" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radioBar yesorno",
                      on: { change: _vm.getLabelId },
                      model: {
                        value: _vm.returnForm.refuse,
                        callback: function ($$v) {
                          _vm.$set(_vm.returnForm, "refuse", $$v)
                        },
                        expression: "returnForm.refuse",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(
                          "经确认，您当前申请区域未达全面覆盖要求，无法达成您的需求。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(
                          "经确认，您当前申请的数据成果受控，无法达成您的需求。"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "remarks", label: "备注" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.returnForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.returnForm, "remarks", $$v)
                      },
                      expression: "returnForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "footbutton",
              attrs: { type: "danger" },
              on: { click: _vm.returnBtn },
            },
            [_vm._v("审核退回")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }