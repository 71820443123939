<template>
  <div class="postback-body">
    <el-dialog
      title="数据回传"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="shut"
      top="5vh"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div class="uploadInput">
        <input
          type="file"
          multiple="multiple"
          webkitRelativePath
          ref="file"
          @change="aetherupload"
          :disabled="num !== uploadingNum"
        />
        <span>点击上传单个/多个文件，或将单个/多个文件拖拽到这里</span>
      </div>

      <div class="hint">提示:超过1GB以上文件请选择大文件回传</div>
      <div class="statistics">
        <div>
          上传总文件：<span>{{ sum }}</span>
        </div>
        <div class="be-uploading">
          <div>
            正在上传中：<span>{{ num }}</span
            >/{{ uploadingNum }}
          </div>
          <div></div>
        </div>
        <el-button
          type="primary"
          title="超过1GB文件请选择大文件回传"
          @click="largeFileShow = true"
          class="large-btn"
          >大文件回传</el-button
        >
      </div>
      <div class="postback-table">
        <el-table
          :data="tableData"
          :height="tableHeight"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            background: '#DDE6EF',
            color: 'rgba(0, 0, 0, 0.65)',
          }"
        >
          <el-table-column prop="name" label="文件名" width="240">
          </el-table-column>
          <el-table-column prop="size" label="大小"> </el-table-column>
          <el-table-column prop="address" label="状态" width="100">
          </el-table-column>
        </el-table>
      </div>

      <div class="btn">
        <el-button
          type="primary"
          @click="shut"
          :disabled="num !== uploadingNum"
          style="margin-right: 24px"
          >确定</el-button
        >
        <el-button
          :disabled="num !== uploadingNum"
          @click="shut"
          style="margin-right: 36px"
          >关闭</el-button
        >
      </div>
    </el-dialog>
    <largeFile
      :uploadType="'task_data'"
      :taskId="taskObj.id"
      @cancel="largeFileCancel"
      @fullscreenLoading="fullscreenLoading"
      @refresh="refresh"
      :returnType="taskObj.name"
      :uppyIframe="uppyIframe"
      v-if="largeFileShow"
    ></largeFile>
  </div>
</template>

<script>
let files = {};
window.progress = {};
import { mapState, mapMutations } from "vuex";
export default {
  name: "subcharacters",
  data() {
    return {
      dialogVisible: true,
      sum: 0, //   上传总数量
      num: 0, //   进行中的数量
      uploadingNum: 0, //   进行中的总数量
      tableData: [],
      tableHeight: 340, //  table表格的高
      taskObj: {}, //  任务的数据
      largeFileShow: false, //  大文件回傳
      uppyIframe: process.env.VUE_APP_API_HOST + "/map/uppy.html",
    };
  },
  computed: {
    ...mapState(["currentUrl", "token", "role"]),
  },
  created() {
    this.tableHeight = document.body.clientHeight * 0.425;
    this.taskObj = this.utils.$funcFind("postback").data;
    let obj = {
      token: this.token,
      uploadType: "task_data",
      taskId: this.taskObj.id,
      returnType: this.taskObj.name,
    };
    this.uppyIframe =
      process.env.VUE_APP_API_HOST +
      "/map/uppy.html?" +
      encodeURIComponent(JSON.stringify(obj));
    this.getTaskPostDataBack();
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 回传加载全屏loading
     */
    fullscreenLoading(data) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (data === false) {
        loading.close();
      }
    },
    /**
     * 大文件回传关闭页面
     */
    largeFileCancel() {
      this.largeFileShow = false;
    },
    /**
     * 弹框点击关闭触发
     */
    shut() {
      let str = "";
      if (this.num !== this.uploadingNum) {
        str = "回传任务暂未完成,确认关闭?";
      } else {
        str = "确认关闭当前页面?";
      }
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancel();
        })
        .catch(() => {});
    },
    /**
     * 选取上传文件
     */
    aetherupload(e) {
      files = e.target.files;
      this.uploadingNum = files.length;
      this.num = 0;
      for (let key in files) {
        let size = files[key].size;
        if (size > 1024 * 1024 * 1024) {
          if (this.largeFileShow !== true) {
            this.$alert("该文件超过1GB，请使用大文件回传", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.largeFileShow = true;
                this.uploadingNum -= this.uploadingNum;
              },
            });
          }
        } else {
          if (size !== undefined) {
            size = this.sizeTransition(size);
            this.tableData.push({
              name: files[key].name,
              size: size,
              address: "上传中",
            });
            this.sum = this.sum + 1;
            // this.num = this.num - 1;
            this.fileTransfer(files[key], this.tableData.length - 1);
          }
        }
      }
    },
    /**
     * 请求前转换
     */
    fileTransfer(file, index) {
      let obj = {
        action: "upload",
        task_id: this.taskObj.id,
        type: this.taskObj.name,
        size: file.size,
        file: file,
      };
      //   let formData = new FormData();
      //   formData.append("file", file, file.name);
      //   for (let keys in obj) {
      //     formData.append(keys, obj[keys]);
      //   }
      this.taskPostDataBack(obj, index);
    },
    /**
     * 转换文件大小
     */
    sizeTransition(size) {
      if (size > 1024 * 1024 * 1024) {
        size = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      } else if (size > 1024 * 1024) {
        size = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else if (size > 1024) {
        size = (size / 1024).toFixed(2) + "KB";
      } else {
        size = size + "B";
      }
      return size;
    },
    /**
     * 回传文件
     */
    taskPostDataBack(formData, index) {
      let header = {
        token: this.taskObj.token,
      };
      let url = this.taskObj.url + "/task/postDataBack";
      let objkey = "progress" + index; //  需要监听的数据变化的对象key
      this.utils.$funcFileRequest(formData, url, header, 99999999, objkey);
      Object.defineProperty(window.progress, objkey, {
        get: () => {
          return data;
        },
        set: (newValue) => {
          this.tableData[index].address = newValue;
          if (newValue === "已完成" || newValue === "失败") {
            this.num = this.num + 1;
          }
        },
      });
    },
    /**
     * 获取回传文件数据列表
     */
    getTaskPostDataBack() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .taskPostDataBack(
          {
            action: "list",
            task_id: this.taskObj.id,
            type: this.taskObj.name,
          },
          this.token
        )
        .then((data) => {
          loading.close();
          this.tableData = [];
          data.data.data.forEach((e, index) => {
            let obj = e;
            obj.name = e.source_name;
            obj.size = this.sizeTransition(obj.size);
            obj.address = "已完成";
            this.tableData.push(obj);
          });
          this.tableData = this.deWeightFour(this.tableData);
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    deWeightFour(arr) {
      let deWeightFourObj = {};
      arr = arr.reduce((a, b) => {
        deWeightFourObj[b.name]
          ? ""
          : (deWeightFourObj[b.name] = true && a.push(b));
        return a;
      }, []);
      return arr;
    },
    /**
     * 上传完刷新
     */
    refresh() {
      this.getTaskPostDataBack();
    },
    /**
     * 取消按钮，关闭回传弹框
     */
    cancel() {
      //   this.$emit("postbackCancel");
      window.open("about:blank", "_self").close();
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  width: 90%;
  margin: 10px auto 0;
}

.postback-body {
  width: 100vw;
  height: 100vh;
  background-color: rgb(240, 240, 240);
}

.top-segmentation {
  width: 100%;
  height: 1px;
  background-color: #dde2ee;
}
.uploadInput {
  width: 90%;
  height: 130px;
  background: url(../../assets/img/file.png) no-repeat center 25px;
  background-size: 40px 40px;
  border-radius: 1px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  position: relative;
  margin: 0 auto;
  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 28px;
    font-size: 14px;
    color: #838a9d;
    width: 100%;
    text-align: center;
    font-size: 10px;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.statistics {
  position: relative;
  width: 90%;
  margin: 14px auto 14px;
  border-top: 1px dashed rgba(166, 170, 184, 0.6);
  padding-top: 25px;
  color: rgba(0, 0, 0, 0.65);
  span {
    color: rgba(23, 130, 251, 1);
  }
}

.postback-table {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 40px;
}

.btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.large-btn {
  position: absolute;
  top: 40%;
  right: 30px;
}
</style>
