<template>
  <el-dialog
    :title="'已回传' + obj.type + '数据'"
    :visible.sync="dialogVisible"
    v-dialogDrag
    width="34.33%"
    :before-close="shut"
  >
    <div class="top-btn">
      <div style="width: 200px; line-height: 36px">
        上传总文件：<span>{{ tableData.length }}</span>
      </div>
      <div class="btn">
        <el-button
          type="primary"
          plain
          @click="operation('下载')"
          style="margin-right: 24px"
          >下载</el-button
        >
        <el-button
          @click="operation('删除')"
          plain
          type="danger"
          style="margin-right: 36px"
          >删除</el-button
        >
      </div>
    </div>
    <div class="postback-table">
      <!-- <el-table
        :data="tableData"
        height="362"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          background: '#DDE6EF',
          color: 'rgba(0, 0, 0, 0.65)',
        }"
      >
        <el-table-column prop="name" label="文件名" width="240">
        </el-table-column>
        <el-table-column prop="size" label="大小"> </el-table-column>
        <el-table-column prop="address" label="状态" width="100">
        </el-table-column>
      </el-table> -->

      <el-table
        ref="multipleTable"
        :data="tableData"
        @selection-change="handleSelectionChange"
        height="362"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          background: '#DDE6EF',
          color: 'rgba(0, 0, 0, 0.65)',
        }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="source_name" label="文件名" width="240">
          <template slot-scope="scope"><a :href="scope.row.download_url" :download="scope.row.source_name">{{ scope.row.source_name }}</a></template>
        </el-table-column>
        <el-table-column prop="size" label="大小"> </el-table-column>
        <el-table-column prop="address" label="状态" width="100">
        </el-table-column>
      </el-table>
    </div>
    <div class="note">另存为：右键单击文件名，选择“链接另存为”</div>
    <div class="btn">
      <el-button
        :disabled="deleteNum !== 0"
        @click="cancel"
        style="margin-right: 36px"
        >关闭</el-button
      >
    </div>

    <downloadLoad :downloadLength="downloadLength"></downloadLoad>
  </el-dialog>
</template>

<script>
let files = {};
import { mapState, mapMutations } from "vuex";
import download from "downloadjs";
let num = 1;
export default {
  name: "subcharacters",
  data() {
    return {
      dialogVisible: true,
      tableData: [], //  文件列表
      multipleSelection: [], //  已选择的文件
      deleteNum: 0, //  当前删除文件还剩多少个
      downloadLength: 0, //   当前下载了多少个文件
    };
  },
  computed: {
    ...mapState(["currentUrl", "token", "role"]),
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    getList() {
      this.$apis
        .taskPostDataBack(
          {
            action: "list",
            task_id: this.obj.id,
            type: this.obj.type,
          },
          this.token
        )
        .then((data) => {
          this.tableData = [];
          data.data.data.forEach((e, index) => {
            let obj = e;
            obj.sizeNum = e.size;
            obj.size = this.sizeTransition(obj.size);
            obj.address = "已完成";
            this.tableData.push(obj);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 弹框点击关闭触发
     */
    shut() {
      if (this.deleteNum !== 0) {
        this.$confirm("删除任务暂未完成确认关闭?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.cancel();
          })
          .catch(() => {});
      } else {
        this.cancel();
      }
    },
    /**
     * 转换文件大小
     */
    sizeTransition(size) {
      if (size > 1024 * 1024 * 1024) {
        size = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      } else if (size > 1024 * 1024) {
        size = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else if (size > 1024) {
        size = (size / 1024).toFixed(2) + "KB";
      } else {
        size = size + "B";
      }
      return size;
    },
    /**
     * 取消按钮，关闭回传弹框
     */
    cancel() {
      this.$emit("postbackListCancel");
    },
    /**
     * 下载和删除文件
     */
    operation(str) {
      let lengthNum = this.multipleSelection.length;
      this.$confirm(`确认${str}当前所选的${lengthNum}个文件？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (str === "下载") {
            this.downloadLength = this.multipleSelection.length;
          }
          for (let i = 0; i < lengthNum; i++) {
            let fileType = this.multipleSelection[i].source_name.slice(
              this.multipleSelection[i].source_name.lastIndexOf(".") + 1
            );
            let obj = this.multipleSelection[i];
            if (str === "下载") {
              if (obj.sizeNum < 100 * (1024 * 1024)) {
                this.utils
                  .$funcDownloadImgZP(obj.download_url, obj.source_name)
                  .then((data) => {
                    this.downloadLength = this.downloadLength - 1;
                  })
                  .catch((err) => {
                    this.downloadLength = this.downloadLength - 1;
                  });
              } else {
                this.downloadLength = this.downloadLength - 1;
                window.open(this.multipleSelection[i].download_url, "_blank");
              }
            } else if (str === "删除") {
              let numIndex = i;
              this.listDelete(this.multipleSelection[i].id, numIndex);
              this.deleteNum = this.deleteNum + 1;
            }
          }
        })
        .catch(() => {});
    },
    /**
     * download 小文件下载
     */
    pictureDownload(obj) {
      var x = new XMLHttpRequest();
      x.open("GET", obj.download_url, true);
      x.responseType = "blob";
      x.onload = (e) => {
        download(x.response, obj.source_name);
        this.downloadLength = this.downloadLength - 1;
      };
      x.onabort = (err) => {
        console.error(err);
        this.downloadLength = this.downloadLength - 1;
      };
      x.send();
    },
    listDelete(task_list_id, i) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.tableData[i].address === "已删除") {
        return;
      }
      this.$apis
        .taskPostDataBack(
          {
            action: "delete",
            task_id: this.obj.id,
            task_list_id: task_list_id,
          },
          this.token
        )
        .then((data) => {
          this.deleteNum = this.deleteNum - 1;
          if (data.data.status === 0) {
            // this.tableData.splice(i, 1);
            this.utils.$notifySuccess("成功", "删除成功");
            this.getList();
            loading.close();
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
  },
  props: {
    obj: {
      type: Object,
      data: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.top-segmentation {
  width: 100%;
  height: 1px;
  background-color: #dde2ee;
}

.top-btn {
  display: flex;
  padding-left: 5%;
  margin-bottom: 24px;
}

.postback-table {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 20px;
}

.btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.note {
  color: #7c7d80;
  font-size: 10px;
  width: 90%;
  margin-left: 5%;
  display: inline-block;
  margin-bottom: 20px;
}
a:hover {
  color: #1890ff;
}
</style>
