<template>
  <div :class="_module" ref="deviceData">
    <div class="setLineHeight">
      <span class="deviceTitle">联盟单位</span
      ><span
        class="deviceData"
        :title="terminal.unit_name"
        style="width: 190px"
        >{{ terminal.unit_name | filterData }}</span
      >
    </div>

    <div v-if="terminal.holder_dept !== undefined || terminal.holder_dept===''" class="status setLineHeight">

      <div class="setDisplay">
        <span class="deviceTitle">归属部门</span
        ><span
          v-if="terminal.holder_dept !== ''"
          class="deviceData"
          style="width: 190px"
          :title="terminal.holder_dept"
          >{{ terminal.holder_dept }}</span
        >
        <span v-else class="deviceData">无</span>
      </div>
    </div>
    <div class="setLineHeight" v-if="terminal.brand === '测绘装备'">
      <span class="deviceTitle" style="margin-right: 9px">设备品牌</span
      ><span
        class="deviceData"
        style="width: 190px"
        :title="terminal.device_brands_name"
        >{{ terminal.device_brands_name | filterData }}</span
      >
    </div>
    <div class="setLineHeight">
      <span class="deviceTitle userName">{{terminal.msg_data.holder_user_data ? `持有人` : `使用人`}}</span
      ><span class="deviceData" style="width: 85px" :title="terminal.name">{{
        terminal.name | filterData
      }}</span
      ><span class="phone">{{ terminal.mobile | filterMobile }}</span>
    </div>
    <div class="setLineHeight" v-if="terminal.brand === '监控模块'">
      <span class="deviceTitle" style="margin-right: 9px; width: 58px"
        >关联设备</span
      ><span
        class="deviceData"
        style="width: 50px"
        :title="terminal.associated_device_type"
        >{{ terminal.associated_device_type | filterData }}</span
      >
      <span
        class="deviceData"
        style="width: 140px; margin-left: 11px"
        :title="terminal.associated_device_software_code"
        >{{ terminal.associated_device_software_code }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "deviceData",
  props: {
    terminal: {
      type: Object,
    },
    setStyle: {
      type: Boolean,
      default: false,
    },
    blockSetStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  filters: {
    filterData(val) {
      return val !== "" ? val : "无";
    },
    filterMobile(val) {
      return val !== "" ? val : "";
    },
  },
  mounted() {
    if (this.blockSetStyle) {
      let title = document.getElementsByClassName("deviceTitle");
      for (let i = 0; i < title.length; i++) {
        title[i].style.color = "#666666";
        title[i].style.fontSize = "13px";
      }
      let data = document.getElementsByClassName("deviceData");
      for (let i = 0; i < data.length; i++) {
        data[i].style.color = "#333333";
        data[i].style.fontSize = "13px";
      }
    }

    // console.log(title.length);
  },
  computed: {
    _module: {
      get: function () {
        if (this.setStyle) {
          // console.log(1234);
          return "";
        } else {
          if (this.blockSetStyle) {
            return "blockTaskStyle setSnIDPaddingBlock";
          } else {
            return "information setSnIDPadding";
          }
        }
      },
    },
  },
  watch: {
    terminal: {
      handler: "setHeight",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setHeight() {
      if (this.$refs.deviceData) {
        if (this.terminal.brand === "测绘装备") {
          this.$refs.deviceData.style.height = "134px";
        } else if (this.terminal.brand === "监控模块") {
          this.$refs.deviceData.style.height = "145px";
        } else {
          this.$refs.deviceData.style.height = "117px";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setSnIDPadding {
  padding-left: 30px;
  padding-right: 19px;
}
.setSnIDPaddingBlock {
  padding: 10px;
  padding-left: 30px;
  padding-right: 19px;
}
.information {
  background: #000d1c;
  // height: 117px;
  padding-top: 13px;
}
.blockTaskStyle {
  background: #fff !important;
}
.setLineHeight {
  line-height: 27px;
  display: flex;
}
.status {
  display: flex;
  justify-content: space-between;
}
.deviceTitle {
  color: #69789e;
  margin-right: 12px;
}
.userName {
  width: 56px;
  letter-spacing: 4.6px;
}
.deviceData {
  color: #007bf7;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
  display: inline-block;
  // width: 22px;
}
.phone {
  color: #ffba00;
  text-decoration: underline;
  margin-left: 11px;
}
.setDisplay {
  display: flex;
  align-items: center;
}
</style>
