var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "el-menu",
        {
          staticClass: "feimarMenu",
          attrs: {
            "background-color": "#112D6D",
            "text-color": "#fff",
            "active-text-color": "#fff",
            "default-active": "activeIndex",
          },
          on: {
            open: _vm.menuOpen,
            close: _vm.menuClose,
            select: _vm.handleSelect,
          },
        },
        [
          _vm._l(_vm.sidebarConfig, function (side) {
            return [
              side.sideIndex == _vm.navMessage
                ? [
                    _vm._l(side.group, function (group) {
                      return _c(
                        "el-menu-item-group",
                        { key: group },
                        [
                          _vm.verifyAuth(group)
                            ? [
                                group.name
                                  ? _c("template", { slot: "title" }, [
                                      _vm._v(_vm._s(group.name)),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(group.menu, function (menu) {
                                  return [
                                    _vm.verifyAuth(menu)
                                      ? _c(
                                          "el-menu-item",
                                          {
                                            key: menu.name,
                                            class: [
                                              "fmmenuson",
                                              {
                                                active:
                                                  _vm.currentUrl[2] ==
                                                  menu.name,
                                              },
                                            ],
                                            attrs: { index: menu.index },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "iconfont",
                                              staticStyle: {
                                                "margin-right": "18px",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(menu.icon),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "siderTit",
                                                attrs: { slot: "title" },
                                                slot: "title",
                                              },
                                              [_vm._v(_vm._s(menu.name))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(side.menu, function (menu) {
                      return [
                        _c(
                          "el-menu-item",
                          {
                            key: menu.name,
                            class: [
                              "fmmenuson",
                              { active: _vm.currentUrl[2] == menu.name },
                            ],
                            attrs: { index: menu.index },
                            on: {
                              click: function ($event) {
                                menu.callback != null && menu.callback()
                              },
                            },
                          },
                          [
                            menu.template
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(menu.template()),
                                  },
                                })
                              : _c("div", [
                                  menu.icon
                                    ? _c("i", {
                                        staticClass: "iconfont",
                                        staticStyle: { "margin-right": "18px" },
                                        domProps: {
                                          innerHTML: _vm._s(menu.icon),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  menu.img
                                    ? _c("img", {
                                        staticStyle: { "margin-right": "18px" },
                                        attrs: { src: menu.img, width: "36" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "siderTit",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [_vm._v(_vm._s(menu.name))]
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    }),
                    _vm._v(" "),
                    _vm._l(side.submenu, function (submenu) {
                      return [
                        _c(
                          "el-submenu",
                          {
                            key: submenu.name,
                            staticClass: "sonMenu fmmenuson",
                            attrs: { index: submenu.name },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              submenu.icon
                                ? _c("i", {
                                    staticClass: "iconfont",
                                    domProps: {
                                      innerHTML: _vm._s(submenu.icon),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              submenu.img
                                ? _c("img", {
                                    staticStyle: { "margin-right": "18px" },
                                    attrs: { src: submenu.img, width: "36" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", { staticClass: "siderTit" }, [
                                _vm._v(_vm._s(submenu.name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm._l(submenu.menu, function (menu) {
                              return _c(
                                "el-menu-item",
                                {
                                  key: menu.name,
                                  staticClass: "fmmenuson",
                                  class: {
                                    active:
                                      _vm.currentUrl[2] == menu.name ||
                                      _vm.currentUrl[3] == menu.name,
                                  },
                                  attrs: { index: menu.index },
                                },
                                [_vm._v(_vm._s(menu.name))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }