var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "playBack", on: { mouseleave: _vm.leaveBackPlay } },
    [
      _c("div", [
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPlay,
              expression: "!isPlay",
            },
          ],
          attrs: { src: require("@/assets/img/homeDialog/pause.png"), alt: "" },
          on: { click: _vm.setPlayOrPause },
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPlay,
              expression: "isPlay",
            },
          ],
          attrs: {
            src: require("@/assets/img/homeDialog/playVideo.png"),
            alt: "",
          },
          on: { click: _vm.setPlayOrPause },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "beginTime" }, [
        _c("span", [_vm._v(_vm._s(_vm._f("sChangeTime")(_vm.flyTime)))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "progressBar",
          staticClass: "progressBar",
          on: {
            mousedown: _vm.processDown,
            mousemove: _vm.progressMove,
            mouseup: _vm.progressUp,
          },
        },
        [
          _c("div", { ref: "progress", staticClass: "progress" }),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "endTime" }, [
        _c("span", [_vm._v(_vm._s(_vm.endTime))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }