<template>
  <div class="task">
    <div id="map1" class="map"></div>
    <div class="management">
      <div style="margin-">
        <span>我的管理区: 南宁市</span>
      </div>
      <div class="work">
        <el-radio-group v-model="radio1">
          <el-radio-button label="责任范围"></el-radio-button>
          <el-radio-button label="图层目录"></el-radio-button>
        </el-radio-group>
        <div style="height: 458px; overflow: auto; margin-left: 10px">
          <el-collapse accordion v-model="city" @change="selectCity()">
            <el-collapse-item
              v-for="item in allCity"
              :key="item.name"
              :name="item.name"
            >
              <template slot="title">
                {{ item.name }}
              </template>
              <div style="margin-left: 30px">
                <el-collapse accordion v-model="area" @change="selectArea()">
                  <el-collapse-item
                    v-for="item in allArea"
                    :key="item.adcode"
                    :name="item.adcode"
                  >
                    <template slot="title">
                      {{ item.name }}
                    </template>
                    <div style="margin-left: 60px">
                      <el-collapse
                        accordion
                        v-model="town"
                        @change="selectTown()"
                      >
                       <div style="height: 40px;line-height: 40px;border-bottom: 1px solid #f9cfcf;cursor: pointer;"  v-for="item in allTown" :key="item.name" @click="selectTown(item.adcode,item.name)">
                         <div>
                           {{item.name}}
                         </div>
                       </div>
                      </el-collapse>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      
    </div>
    <div class="worker" v-show="drawCnter">
        <div class="header">
          基本信息
        </div>
        <div class="scope">
          <span>责任范围：{{town}}</span>
          
        </div>
        <div class="people">
          <el-table :data="workerData">
            <el-table-column prop="name" label="田长姓名">
               <template slot-scope="scope">
                {{town+scope.row.name}}
          </template>
            </el-table-column>
            <el-table-column prop="role" label="田长角色">
             
            </el-table-column>
            <el-table-column prop="phone" label="联系电话"></el-table-column>

          </el-table>
        </div>
      </div>
  </div>
</template>
<script>
import coordtransform from "@/utils/transform.js";
import transform from "@/utils/transform.js";
import { mapState, mapMutations } from "vuex";
import SwitchMap from "@/utils/switchMap.js";
import DrwaMapMarker from "@/components/backEndHome/drawMapMarker.js";
import RequestData from "@/components/backEndHome/requestDevicesData.js";
const turf = require("@turf/turf");

export default {
  components: {},
  data() {
    return {
      map: "", //地球实例
      switchMapManage: "", //切换地球
      radio1: "责任范围",
      // "柳州市","桂林市","梧州市","北海市","崇左市"
      allCity: [{name:"南宁市"},{name:"柳州市"},{name:"桂林市"},{name:"梧州市"},{name:"北海市"},{name:"崇左市"}],
      allArea: [],
      city: "",
      area: "",
      town: "",
      allTown: [],
      village:"",
      allVillage:[],
      gxPolygon: [],
      drawLine: false,
      workerData:[{
        name:"负责人",
        role: "二级田长",
        phone: "1850000001"
      }],
      drawCnter: false,
    };
  },

  computed: {
    ...mapState(["monitorPlaneData", "token"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(["setMonitorPlaneData"]),
    init() {
      this.$nextTick(() => {
        this.initMap();
      });

      // this.drawDeviceMarker();
    },
    initMap() {
      let map = new AMap.Map("map1", {
        resizeEnable: true,
        mapStyle: "amap://styles/dark",
        zoom: 4,
      });
      this.map = map;
      this.switchMapManage = new SwitchMap(this.map);
      this.switchMapManage.switchDifferentMap(3)
    },
    selectCity(val) {
      if (this.city) {
        this.drawCnter = false;
        this.drawLine = false;
        this.getCity(this.city, "allArea");
      }
    },
    selectArea() {
      console.log(this.area);
      if (this.area) {
        this.drawCnter = false;
        this.drawLine = true;
        this.getCity(this.area, "allTown");
      }
    },
    selectTown(val,name) {
      this.drawLine = false;
      this.town = name;
        this.drawCnter = true
        this.getCity(val, "allVillage");
    },
    getCity(name, allCity) {
      //行政区划查询
      var opts = {
        subdistrict: 1, //返回下一级行政区
        showbiz: false, //最后一级返回街道信息
        extensions: "all",
      };
      let that = this;
      this.map.plugin("AMap.DistrictSearch", () => {
        let district = new AMap.DistrictSearch(opts); //注意：需要使用插件同步下发功能才能这样直接使用

        district.search(name, function (status, result) {
          if (status == "complete") {
            that[allCity] = result.districtList[0].districtList;
            console.log(result);
            if (that.drawLine){
              for (var i = 0, l = that.gxPolygon.length; i < l; i++) {
                  that.gxPolygon[i].setMap(null);
              }
              that.handlePolygon(result.districtList[0].boundaries);
            }
            if (that.drawCnter){
              console.log(result.districtList[0].center);
              that.map.setCenter(result.districtList[0].center)
            }
            // getData(result.districtList[0]);
          }
        });
      });
    },
    handlePolygon(result) {
      let self = this;
        let bounds = result;
        self.gxPolygon = [];
        if (bounds.length) {
          for (let i = 0, l = bounds.length; i < l; i++) {
            //生成行政区划polygon
            self.gxPolygon.push(
              new AMap.Polygon({
                map: self.map, // 指定地图对象
                strokeWeight: 2, // 轮廓线宽度
                path: bounds[i], //轮廓线的节点坐标数组
                fillOpacity: 0.6, //透明度
                fillColor: "#e57777", //填充颜色
                strokeColor: "#cff53b", //线条颜色
              })
            );
          }
          // 地图自适应
          self.map.setFitView()
        }
      },
  },
};
</script>
<style lang="scss" scoped>
.task {
  height: 100%;
  position: relative;
  overflow: hidden;
  .management {
    position: absolute;
    top: 0;
    left: 15px;
    font-size: 21px;
    color: #fff;
    margin-top: 30px;
  }
  .map {
    height: 100%;
  }
  .work {
    margin-top: 30px;
    height: 500px;
    background-color: #fff;
    /deep/.el-radio-button--medium .el-radio-button__inner {
      width: 140px !important;
    }
  }
  .worker {
    position: absolute;
    right: 30px;
    width: 370px;
    height: 400px;
    top: 80px;
    color: #fff;
    background-color: #fff;
    .header {
      background-color: blueviolet;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
    }
    .scope {
      color: #000;
      padding-left: 10px;
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
