<template>
  <!-- 手机号登录 -->
  <div class="centerWindow">
    <div class="loginBgc">
      <div class="fmWelcome">
        <div class="fmWelLogin">
          <p class="sayHello">您好,</p>
          <p class="welLogin">欢迎登录</p>
          <p class="fmsider"></p>
          <p class="fmTitle">智慧田长管理平台</p>
          <!-- <p class="sonTitle">调度中心端</p> -->
        </div>
      </div>
      <div class="fmLoginNav">
        <div class="loginNav">
          <div class="loginBox">
            <p class="loginTitle">修改联盟代码密码</p>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
              <el-form-item prop="unit_id" class="demo-ruleForm">
                <el-input placeholder="请输入联盟代码" v-model="ruleForm.unit_id"></el-input>
              </el-form-item>
              <el-form-item prop="password" class="demo-ruleForm">
                <el-input placeholder="请输入密码" v-model="ruleForm.password"></el-input>
              </el-form-item>
              <el-form-item prop="passNewWord" class="demo-ruleForm">
                <el-input
                  type="password"
                  placeholder="请输入新密码"
                  v-model="ruleForm.passNewWord"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item prop="surePassNewWord" class="demo-ruleForm">
                <el-input
                  type="password"
                  placeholder="请确认新密码"
                  v-model="ruleForm.surePassNewWord"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item class="SignInBar">
                <el-button class="loginBar" type="primary" @click="userLogin">修&nbsp;&nbsp;改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm.surePassNewWord !== "") {
          this.$refs.ruleForm.validateField("surePassNewWord");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.passNewWord) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        unit_id: "", // 联盟代码账号
        password: "", // 旧密码
        passNewWord: "", // 新密码
        surepassNewWord: "", // 确认新密码
      },
      rules: {
        unit_id: [
          { required: true, message: "请输入联盟代码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { max: 10, message: "最大字符数10", trigger: "blur" },
        ],
        passNewWord: [{ validator: validatePass, trigger: "blur" }],
        surePassNewWord: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    registerNum() {
      this.$router.push("/Register");
    },
    userLogin() {
      // validate：验证当前表单元素中所有的规则
      // 这里是修改联盟代码密码页面，现已弃用。
      console.log("此页面已被弃用");
      // this.$refs["ruleForm"].validate((valid) => {
      //   // 如果valid 为 true 说明验证通过
      //   // 如果valid 为 false 说明验证不通过
      //   // if (valid) {
      //   //   // this.submitData();
      //   //   // this.isLoading = true;
      //   // } else {
      //   // }
      // });
    },
    // 联盟代码登录
    otherLogin() {
      // this.$router.push('/otherlogin')
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.centerWindow {
  width: 100%;
  height: 100%;
  .loginBgc {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("/src/assets/img/bgc-login.png") no-repeat center;
    background-size: 100% 100%;
    padding-bottom: 68px;
    .fmWelcome {
      display: block;
      float: left;
      margin: 0 auto;
      padding-top: 292px;
      margin-left: 6%;
      .sayHello {
        width: 120px;
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 52px;
      }
      .welLogin {
        width: 160px;
        height: 52px;
        line-height: 82px;
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
      }
      .fmsider {
        width: 166px;
        height: 4px;
        background: rgba(255, 255, 255, 0.43);
        margin-top: 59px;
      }
      .fmTitle {
        width: 784px;
        height: 74px;
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 74px;
        margin-top: 51px;
      }
      .sonTitle {
        width: 240px;
        height: 64px;
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 64px;
        margin-top: 53px;
      }
    }
  }
  .fmLoginNav {
    padding: 68px 68px 0px 0px;
    .loginNav {
      width: 615px;
      height: calc(100vh - 136px);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      float: right;
      padding: 121px 164px 165px 121px;
      .loginBox {
        width: 100%;
        height: 100%;
        .loginTitle {
          width: 256px;
          height: 42px;
          font-size: 32px;
          color: rgba(59, 66, 83, 1);
          line-height: 2px;
        }
        .demo-ruleForm {
          margin-top: 36px;
          width: 370px;
        }
        .SignInBar {
          margin-top: 72px;
          .loginBar {
            font-size: 20px;
            width: 370px;
            height: 55px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.otherLogin {
  display: block;
  width: 370px;
}
.FMword {
  color: #0094ff;
  float: left;
  text-align: center;
  padding: 0px 20px 0 20px;
  margin-top: -18px;
  cursor: pointer;
}
.register {
  cursor: pointer;
}
.forgetPsw {
  cursor: pointer;
}
.leftBoder {
  width: 120px;
  height: 2px;
  background-color: #8099bb;
  float: left;
}
.rightBoder {
  float: left;
  width: 123px;
  height: 2px;
  background-color: #8099bb;
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}
/deep/ .el-input__inner {
  border-radius: 8px;
  height: 44px;
  border: 2px solid rgba(166, 170, 184, 1);
  background-color: rgba(255, 255, 255, 0.3);
}
/deep/.el-divider__text {
  background-color: transparent;
}
@media screen and (min-width: 1700px) {
}
</style>
