var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: _vm.obj.name,
        visible: _vm.dialogVisible,
        width: "46.875%",
        "before-close": _vm.shut,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "top-segmentation" }),
      _vm._v(" "),
      _c("div", { staticClass: "cen-right" }, [
        _c(
          "div",
          { staticClass: "cen-right-top" },
          [
            _c(
              "el-checkbox",
              {
                on: { change: _vm.allChange },
                model: {
                  value: _vm.checkAll,
                  callback: function ($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll",
                },
              },
              [_vm._v("全部选择")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.checkedCitiesArr.length > 0
          ? _c(
              "div",
              { staticClass: "cen-right-body" },
              _vm._l(_vm.checkedCitiesArr, function (item, index) {
                return _c(
                  "div",
                  { key: item.name, staticClass: "option" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.allListChange(item.name, index)
                          },
                        },
                        model: {
                          value: _vm.checkAllArr[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.checkAllArr, index, $$v)
                          },
                          expression: "checkAllArr[index]",
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "division" }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.permissionsArr[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.permissionsArr, index, $$v)
                          },
                          expression: "permissionsArr[index]",
                        },
                      },
                      _vm._l(
                        _vm.checkedCitiesArr[index].list,
                        function (city, num) {
                          return _c(
                            "el-checkbox",
                            {
                              key: city.code,
                              staticStyle: { margin: "10px  4%" },
                              attrs: { label: city },
                              on: {
                                change: function ($event) {
                                  return _vm.GroupChange(
                                    _vm.checkedCitiesArr[index],
                                    index,
                                    num
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(city.name))]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer subcharacters-ben",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.preserve },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              staticStyle: { color: "#1182fb", border: "1px solid #1182fb" },
              on: { click: _vm.shut },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }