var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.avatar != "" && _vm.avatar != null,
            expression: "avatar != '' && avatar != null",
          },
        ],
        staticClass: "avatar",
      },
      [
        _c(
          "el-image",
          {
            staticClass: "avatar-box",
            style: "width:" + _vm.width + "px;height:" + _vm.height + "px",
            attrs: { "data-img": _vm.avatar, src: _vm.avatar },
          },
          [
            _c(
              "div",
              {
                staticClass: "image-slot",
                attrs: { slot: "error" },
                slot: "error",
              },
              [
                _c("img", {
                  style:
                    "width:" + _vm.width + "px;height:" + _vm.height + "px",
                  attrs: {
                    src: require("@/assets/img/user-default-head-big.png"),
                  },
                }),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.avatar == "" || _vm.avatar == null,
            expression: "avatar == '' || avatar == null",
          },
        ],
        staticClass: "avatar",
      },
      [
        _c("img", {
          style: "width:" + _vm.width + "px;height:" + _vm.height + "px",
          attrs: { src: require("@/assets/img/user-default-head-big.png") },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }