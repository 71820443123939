<template>
  <div class="tuban">
    <div style="height: 100%; width: 50%">
      <div style="width: 100%; text-align: center">图斑范围</div>
      <div id="map1" class="map"></div>
    </div>
    <div style="width: 50%">
      <div style="margin-left: 30px">
        <el-radio-group v-model="radio1">
          <el-radio-button label="线索信息"></el-radio-button>
          <el-radio-button label="审核复核" disabled></el-radio-button>
          <el-radio-button label="实地照片" disabled></el-radio-button>
          <el-radio-button label="无人机照片" disabled></el-radio-button>
          <el-radio-button label="现场视频" disabled></el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <div class="setBack">基本信息</div>
        <div class="body">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="110px"
            class="demo-ruleForm"
            size="mini"
          >
            <el-form-item label="事件名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="事件编号" prop="name1">
              <el-input v-model="ruleForm.name1"></el-input> </el-form-item
            ><el-form-item label="行政区代码" prop="name2">
              <el-input v-model="ruleForm.name2"></el-input>
            </el-form-item>
            <el-form-item label="行政区名称" prop="region">
              <el-input v-model="ruleForm.name3"></el-input> </el-form-item
            ><el-form-item label="违法地点" prop="region">
              <el-input v-model="ruleForm.name4"></el-input> </el-form-item
            ><el-form-item label="图斑面积（亩）" prop="region">
              <el-input v-model="ruleForm.name5"></el-input> </el-form-item
            ><el-form-item label="举报人" prop="region">
              <el-input v-model="ruleForm.name6"></el-input> </el-form-item
            ><el-form-item label="举报时间" prop="region">
              <el-input v-model="ruleForm.name7"></el-input> </el-form-item
            ><el-form-item label="疑似违法类型" prop="region">
              <el-input v-model="ruleForm.name8"></el-input> </el-form-item
            ><el-form-item label="事件描述" prop="region">
              <el-input v-model="ruleForm.name9"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="setBack">外业信息</div>
        <div class="body">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="110px"
            class="demo-ruleForm"
            size="mini"
          >
            <el-form-item label="外业核实结果" prop="region">
              <el-input v-model="ruleForm.name10"></el-input>
            </el-form-item>
            <el-form-item label="外业违法类型" prop="region">
              <el-input v-model="ruleForm.name11"></el-input>
            </el-form-item>
            <el-form-item label="外业描述" prop="region">
              <el-input v-model="ruleForm.name12"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="text-align: center">
          <el-button @click="submitForm('ruleForm')">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SwitchMap from "@/utils/switchMap.js";
export default {
  props:{
    blockItem:{
      type: Object,
      data: {}
    }
  },
  data() {
    return {
      radio1: "线索信息",
      ruleForm: {
        name: "",
        region: "",
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        name5: "",
        name6: "",
        name7: "",
        name8: "",
        name9: "",
        name10: "",
        name11: "",
        name12: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        name1: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        name2: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        region: [
          { required: false, message: "请选择活动区域", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      gxPolygon: [],
      map: ""
    };
  },
  computed: {
    ...mapState(["token"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();

    });
    this.ruleForm.name = this.blockItem.project_name;
    this.ruleForm.name4 = this.blockItem.project_name;
    this.ruleForm.name1 = this.blockItem.id;
    this.ruleForm.name3 = this.blockItem.project_name;
    this.ruleForm.name6 = this.blockItem.name;
    this.ruleForm.name7 = this.blockItem.created_at;

    this.init()
  },
  methods: {
    init(){
      let pramas = { project_id: this.blockItem.id };
      this.$apis.demandShowOrder(pramas, this.token).then((res) => {
        console.log(res.data.data);
        let order = res.data.data.order
        this.ruleForm.name5 = order.survey_area_area;
        let description = JSON.parse(order.description)
        this.ruleForm.name9 = description.description;
        this.getCity(res.data.data.survey_area[0].survey_street_name.split(" ")[1]);
        let points = [];
        res.data.data.survey_area.forEach(item=>{
          let allPoint = item.survey_area_coordinate_points.split(";");
          let arrPoint = []
          allPoint.forEach(item=>{
            if (item)
              arrPoint.push(item.split(","))
          })
          points.push(arrPoint)
        })
        this.handlePolygon(points)
      });
    },
    initMap() {
      let map = new AMap.Map("map1", {
        resizeEnable: true,
        mapStyle: "amap://styles/dark",
        zoom: 4,
      });
      this.map = map;
      this.switchMapManage = new SwitchMap(this.map);
      this.switchMapManage.switchDifferentMap(3);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //绘制
    handlePolygon(result) {
      let self = this;
        let bounds = result;
        self.gxPolygon = [];
        if (bounds.length) {
          for (let i = 0, l = bounds.length; i < l; i++) {
            //生成行政区划polygon
            self.gxPolygon.push(
              new AMap.Polygon({
                map: self.map, // 指定地图对象
                strokeWeight: 2, // 轮廓线宽度
                path: bounds[i], //轮廓线的节点坐标数组
                fillOpacity: 0.6, //透明度
                fillColor: "#e57777", //填充颜色
                strokeColor: "#cff53b", //线条颜色
              })
            );
          }
          // 地图自适应
          self.map.setFitView()
        }
      },
      getCity(name) {
      //行政区划查询
      var opts = {
        subdistrict: 1, //返回下一级行政区
        showbiz: false, //最后一级返回街道信息
        extensions: "all",
      };
      let that = this;
      this.map.plugin("AMap.DistrictSearch", () => {
        let district = new AMap.DistrictSearch(opts); //注意：需要使用插件同步下发功能才能这样直接使用

        district.search(name, function (status, result) {
          if (status == "complete") {
            console.log(result.districtList[0].adcode);
            that.ruleForm.name2 = result.districtList[0].adcode;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tuban {
  height: 750px;
  display: flex;
  .map {
    height: 90%;
    width: 100%;
  }
  /deep/.el-input--mini {
    width: 70% !important;
  }
  .setBack {
    margin-left: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #b1e3e5;
    height: 35px;
    line-height: 35px;
  }
  .body {
    margin-left: 30px;
    width: 100%;
  }
}
</style>
