<template>
  <div class="nav-top">
    <div class="top cursor">
      <span @click="toIndex">{{ currentUrl[0] }}</span>
      /
      <span @click="navigational">{{ currentUrl[1] }}</span>
      /
      <span class="span">{{ currentUrl[2] }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "NavTop",
  data() {
    return {
      nav: "tongyong",
    };
  },
  computed: {
    ...mapState(["currentUrl", "role"]),
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    toIndex() {
      if (this.role === 2) {
        this.$router.push({
          path: "login",
        });
        return;
      }
      this.$router.push({
        path: "home",
      });
    },
    /**
     * 跳转二级导航
     */
    navigational() {
      if (this.role === 2) {
        switch (this.currentUrl[1]) {
          case "应急任务":
            this.$router.push({
              path: "getawayTask?nav=feima",
            });
            break;
          case "目录查询":
            this.$router.push({
              path: "result",
            });
            break;
          case "应急装备":
            this.$router.push({
              path: "deviceManagement?type=uav",
            });
            break;
          case "空域查询":
            this.$router.push({
              path: "airsearch",
            });
            break;
          case "气象服务":
            this.$router.push({
              path: "meteorological",
            });
            break;
          case "舆情发布":
            this.$router.push({
              path: "sentiment",
            });
            break;
          case "预警发布":
            this.$router.push({
              path: "warning",
            });
            break;
          default:
            this.$router.push({
              path: "login",
            });
            break;
        }
        return;
      }
      switch (this.currentUrl[1]) {
        case "任务规划":
          this.$router.push({
            path: "taskCoordination",
          });
          break;
        case "任务下达":
          this.$router.push({
            path: "flying?nav=feima",
          });
          break;
        case "用户管理":
          this.$router.push({
            path: "alliance",
          });
          break;
        case "应急装备":
          this.$router.push({
            path: "deviceManagement?type=uav",
          });
          break;
        case "目录查询":
          this.$router.push({
            path: "uav",
          });
          break;
        case "空域查询":
          this.$router.push({
            path: "uav",
          });
          break;
        case "统计分析":
          this.currentUrlStorage(["首页", "统计分析", "任务统计"]);
          this.$router.push({
            path: "taskstat",
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-top {
  padding: 17px 0 17px 30px;
}
.top {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  .span {
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
