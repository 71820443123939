<template>
  <div class="subject">
    <navTop />
    <div class="bodyNav">
      <div class="top">
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
        >
          <el-form-item
            class="userForm"
            prop="disaster_name"
            label="灾害预警"
            style="width: 300px"
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.disaster_name"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="advise"
            label="灾害建议"
            style="width: 300px"
          >
            <el-input placeholder="请输入" v-model="ruleForm.advise"></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="mobile"
            label="创建时间区间"
            style="width: 455px"
          >
            <el-date-picker
              v-model="DateRange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDateRange"
            ></el-date-picker>
          </el-form-item>
          <div class="footBtn">
            <div class="fmBtn">
              <el-button class="searchBtn" type="primary" @click="searchBtn"
                >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
              >
              <el-button
                class="reset"
                @click="resetForm('ruleForm')"
                type="primary"
                plain
                >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="center-body">
        <el-table
          height="80%"
          :stripe="true"
          :header-cell-style="{ background: '#DDE6EF' }"
          :data="tableData"
          style="width: 98%; margin-left: 1%; margin-top: 1%"
        >
          <el-table-column
            align="center"
            prop="id"
            label="发布预警ID"
            width="300"
            class="number"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="area_name"
            label="区域名称"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="disaster_name"
            label="预警灾害"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="advise"
            label="预警建议"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="contact"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="created_at"
            label="创建时间"
          ></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="editBtn(scope.row)" type="text"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="per_page"
            layout="total,  prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      ruleForm: {
        disaster_name: "",
        advise: "",
        start_time: "",
        end_time: "",
      },
      tableData: [],
      DateRange: [],
      start_datetime: "",
      end_datetime: "",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    getDateRange() {
      this.start_datetime = this.DateRange[0] + " " + "00:00:00";
      this.end_datetime = this.DateRange[1] + " " + "23:59:59";
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.applyData();
    },
    // 申请详情
    editBtn(param) {
      this.$router.push({ path: "/details", query: { id: param.id } });
    },
    // 发布预警信息
    applyData() {
      this.$apis
        .getMyWarningList(
          {
            per_page: 8,
            page: this.page,
            disaster_name: this.ruleForm.disaster_name,
            advise: this.ruleForm.advise,
            start_time: this.start_datetime,
            end_time: this.end_datetime,
          },
          this.token
        )
        .then((data) => {
          this.tableData = data.data.data.data;
          this.total = data.data.data.total;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 分页
    handleNext() {
      this.page++;
      this.applyData(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.applyData(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.applyData(this.page, this.per_page);
    },
  },
  created() {
    this.applyData();
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "issued") {
      this.currentUrlStorage(["首页", "个人中心", "已发布预警"]);
    }
  },
};
</script>

<style lang="less" scoped>
.bodyNav {
  height: calc(100vh - 130px);
  width: 100%;
}
.blok {
  color: #fd5c5e;
}
.el-tag.el-tag--success {
  background-color: #e7faf0;
  border-color: #1cd0bd;
  color: #1cd0bd;
  width: 80px;
}
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  width: 32%;
  margin-left: 10px;
}
.online {
  color: #409eff;
  font-size: 14px;
}
.formbox {
  float: left;
  margin-top: 2%;
  width: 100%;
}
.fmBtn {
  float: right;
  width: 210px;
}
.block {
  padding: 20px 0 0 70px;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 0 15px 2% 15px;
  background-color: #f0f0f0;

  .top {
    width: 100%;
    height: 20%;
    display: flow-root;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .nape {
      margin: 2.664% 2% 0 0;
      display: inline-block;
      width: 22%;
      height: 40px;
      span {
        padding-right: 8.3%;
      }
      .import {
        display: inline-block;
        width: 58.363%;
      }
    }
  }
  .center-body {
    width: 100%;
    height: 77%;
    margin-top: 1%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    position: relative;
    overflow-y: auto;
    /deep/ .el-table .cell {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    /deep/ .el-table .cell:hover {
      text-overflow: inherit;
      overflow: visible;
      white-space: pre-line;
    }
    .pagination {
      position: absolute;
      bottom: 4.973%;
      right: 19.216%;
    }
  }
}
@media only screen and (max-width: 1344px) {
  .subject {
    .center-body {
      height: 67%;
    }
  }
}
</style>