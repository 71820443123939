// 群组
import {
  fly,
  baseURL,
  baseAirURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

// 申请空域
export const apply = (data, token) => fly.post(
  baseURL + '/airspace/apply', data, deploy(30000, token)
)

// 已申请空域
export const applyList = (data, token) => fly.post(
  baseURL + '/airspace/applyList', data, deploy(30000, token)
)

// 待审批空域
export const pending = (data, token) => fly.post(
  baseURL + '/airspace/pending', data, deploy(30000, token)
)

// 待审批空域
export const confirm = (data, token) => fly.post(
  baseURL + '/airspace/confirm', data, deploy(30000, token)
)

// 是否受理
export const audit = (data, token) => fly.post(
  baseURL + '/airspace/audit', data, deploy(30000, token)
)

// 上传批件
export const upload = (data, token) => fly.post(
  baseURL + '/airspace/upload', data, deploy(30000, token)
)

// 空域编目
export const authorizeList = (data, token) => fly.post(
  baseURL + '/airspace/authorizeList', data, deploy(30000, token)
)

// 空域查询、空域审批详情
export const detail = (data, token) => fly.post(
  baseURL + '/airspace/detail', data, deploy(30000, token)
)

// 授权空域详情
export const authorizeDetail = (data, token) => fly.post(
  baseURL + '/airspace/authorizeDetail', data, deploy(30000, token)
)

// 保存授权空域
export const saveAuthorize = (data, token) => fly.post(
  baseURL + '/airspace/saveAuthorize', data, deploy(30000, token)
)

// 空域查询
export const applySearch = (data, token) => fly.post(
  baseURL + '/airspace/applySearch', data, deploy(30000, token)
)

// 禁飞空域
export const noflyzone = (data, token) => fly.post(
  baseAirURL + '/zones', data, deploy(30000, token)
)

// 获取飞行脚印
export const getFlightFootPrints = (data, token) => fly.post(
  baseURL + '/apply/getFlightFootPrints', data, deploy(30000, token)
)

// 飞机类型
export const getPlaneType = (data, token) => fly.post(
  baseURL + '/apply/getPlaneType', data, deploy(30000, token)
)

// 载荷类型
export const getCameraType = (data, token) => fly.post(
  baseURL + '/apply/getCameraType', data, deploy(30000, token)
)

// 数据申请
export const applyData = (data, token) => fly.post(
  baseURL + '/apply/applyData', data, deploy(30000, token)
)

// 已申请数据
export const getMyApplyData = (data, token) => fly.post(
  baseURL + '/apply/getMyApplyData', data, deploy(30000, token)
)

// 已申请数据详情
export const getMyApplyDataDetail = (data, token) => fly.post(
  baseURL + '/apply/getMyApplyDataDetail', data, deploy(30000, token)
)

// 数据申请管理
export const manageApplyData = (data, token) => fly.post(
  baseURL + '/apply/manageApplyData', data, deploy(30000, token)
)

// 数据申请审核及确认
export const updateApplyData = (data, token) => fly.post(
  baseURL + '/apply/updateApplyData', data, deploy(30000, token)
)

// 门户首页最新项目
export const portalGetHomeProject = (data, token) => fly.post(
  baseURL + '/portal/getHomeProject', data, deploy(30000, token)
)

// 门户首页数据申请
export const portalGetHomeApplyData = (data, token) => fly.post(
  baseURL + '/portal/getHomeApplyData', data, deploy(30000, token)
)
