var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user && _vm.user.length == 3
      ? _c(
          "div",
          { staticClass: "avatar-list" },
          [
            _c(
              "div",
              { staticClass: "avatar-part avatar-part-top" },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "avatar-small",
                    attrs: { src: _vm.user[0].photo, fit: "fill" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("img", {
                          style: "width:19px;height:19px",
                          attrs: {
                            src: require("@/assets/img/user-default-head-big.png"),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.user.slice(1), function (item) {
              return _c(
                "div",
                { staticClass: "avatar-part" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "avatar-small",
                      attrs: { src: item.photo, fit: "fill" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("img", {
                            style: "width:19px;height:19px",
                            attrs: {
                              src: require("@/assets/img/user-default-head-big.png"),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm.user && _vm.user.length > 3
      ? _c(
          "div",
          { staticClass: "avatar-list" },
          _vm._l(_vm.user.slice(0, 4), function (item) {
            return _c(
              "div",
              { staticClass: "avatar-part" },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "avatar-small",
                    attrs: { src: item.photo, fit: "fill" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("img", {
                          style: "width:19px;height:19px",
                          attrs: {
                            src: require("@/assets/img/user-default-head-big.png"),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "avatar-list" },
          [
            _c(
              "el-image",
              {
                staticClass: "avatar-box",
                style: "width:" + _vm.width + "px;height:" + _vm.height + "px",
                attrs: { src: _vm.avatar },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [
                    _c("img", {
                      style:
                        "width:" + _vm.width + "px;height:" + _vm.height + "px",
                      attrs: {
                        src: require("@/assets/img/user-default-head-big.png"),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }