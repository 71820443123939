var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            modal: false,
            visible: _vm.dialogVisible,
            width: "35%",
            top: "85px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "titleBar" }, [
            _c(
              "div",
              {
                staticClass: "leftBar",
                class: { active: this.flag == 1 },
                on: {
                  click: function ($event) {
                    return _vm.leftBtn(1)
                  },
                },
              },
              [
                _c("span", { staticClass: "leftword" }, [
                  _vm._v(
                    "待办任务(" + _vm._s(_vm.msgCenter.unread_num.task) + ")"
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "midBar",
                class: { active: this.flag == 2 },
                on: {
                  click: function ($event) {
                    return _vm.midBtn(2)
                  },
                },
              },
              [
                _c("span", { staticClass: "midword" }, [
                  _vm._v(
                    "标准动作指令(" +
                      _vm._s(_vm.msgCenter.unread_num.task_command) +
                      ")"
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "rightBar",
                class: { active: this.flag == 3 },
                on: {
                  click: function ($event) {
                    return _vm.rightBtn(3)
                  },
                },
              },
              [
                _c("span", { staticClass: "rightword" }, [
                  _vm._v(
                    "SHP文件(" +
                      _vm._s(_vm.msgCenter.unread_num.task_file) +
                      ")"
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodyBar" },
            _vm._l(_vm.messageData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "lineBar",
                  on: {
                    click: function ($event) {
                      return _vm.readBtn(item)
                    },
                  },
                },
                [
                  _vm.flag == 1
                    ? _c("div", { staticClass: "task" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.is_read == 0,
                              expression: "item.is_read == 0",
                            },
                          ],
                          staticClass: "sircel",
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "body" }, [
                          _c("div", { staticClass: "imgNav" }, [
                            item.project.type == 1 && !item.task
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／feima.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 1 &&
                            item.task &&
                            item.task.push_type == 1
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／feima／1.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 1 &&
                            item.task &&
                            item.task.push_type == 2
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／feima／2.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 2 && !item.task
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／general.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 2 &&
                            item.task &&
                            item.task.push_type == 1
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／general1.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 2 &&
                            item.task &&
                            item.task.push_type == 2
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/message／task／general2.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 3 && !item.task
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/blocksassigned.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 3 &&
                            item.task &&
                            item.task.push_type == 1
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/block_assigned.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.project.type == 3 &&
                            item.task &&
                            item.task.push_type == 2
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeImg/block_mass.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "taskNav" }, [
                            _c("div", { staticClass: "leftNav" }, [
                              _c("p", { staticClass: "taskname" }, [
                                _vm._v(_vm._s(item.project.project_name)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "setuser" }, [
                                _vm._v(
                                  "创建人：" + _vm._s(item.from_user_data.name)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "rightNav" }, [
                              _c("span", { staticClass: "settime" }, [
                                _vm._v("创建时间：" + _vm._s(item.created_at)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "timeNav" }, [
                            _c("span", { staticClass: "timer" }, [
                              _vm._v(_vm._s(item.created_at)),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.flag == 2
                    ? _c("div", { staticClass: "task" }, [
                        item.is_read == 0
                          ? _c("div", { staticClass: "sircel" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "instruction" }, [
                          _c("div", { staticClass: "instructionImgNav" }, [
                            item.from_user_data.photo_url
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: item.from_user_data.photo_url,
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: require("@/assets/img/user-default-head-small.png"),
                                  },
                                }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "instructionTaskNav" }, [
                            _c("div", { staticClass: "instructionLeftNav" }, [
                              _c("p", { staticClass: "instructionTaskname" }, [
                                _vm._v(_vm._s(item.from_user_data.name)),
                              ]),
                              _vm._v(" "),
                              item.task
                                ? _c(
                                    "p",
                                    { staticClass: "instructionSetuser" },
                                    [_vm._v(_vm._s(item.task.task_command))]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "instructiontimeNav" }, [
                            _c("span", { staticClass: "instructionTimer" }, [
                              _vm._v(_vm._s(item.created_at)),
                            ]),
                            _vm._v(" "),
                            item.task
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "instructionPlay",
                                    attrs: {
                                      title:
                                        item.project.project_name +
                                        "-" +
                                        item.task.block_name +
                                        "-" +
                                        item.task.task_name,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.project.project_name +
                                            "-" +
                                            item.task.block_name +
                                            "-" +
                                            item.task.task_name
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.flag == 3
                    ? _c("div", { staticClass: "task" }, [
                        item.is_read == 0
                          ? _c("div", { staticClass: "sircel" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "body" }, [
                          _c("div", { staticClass: "imgNav" }, [
                            item.from_user_data.photo_url
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: item.from_user_data.photo_url,
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: require("@/assets/img/user-default-head-small.png"),
                                  },
                                }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "taskNav" }, [
                            _c("div", { staticClass: "leftNav" }, [
                              _c("p", { staticClass: "taskname" }, [
                                _vm._v(_vm._s(item.from_user_data.name)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "setuser" }, [
                                _vm._v(_vm._s(item.task.task_file)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "timeNav" }, [
                            _c("span", { staticClass: "timer" }, [
                              _vm._v(_vm._s(item.created_at)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "play" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    item.project.project_name +
                                      "-" +
                                      item.task.block_name +
                                      "-" +
                                      item.task.task_name
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footBar" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.readAll } },
                [_vm._v("全部清空")]
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.watchMore(7)
                    },
                  },
                },
                [_vm._v("查看更多")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }