var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "div",
        { staticClass: "amap-box" },
        [
          _c(
            "el-amap",
            {
              staticClass: "amap-box",
              attrs: {
                plugin: _vm.plugin,
                events: _vm.events,
                zoom: _vm.zoom,
                center: _vm.center,
                vid: "amap-vue",
              },
            },
            [
              _vm._l(_vm.markers, function (marker, index) {
                return _c("el-amap-marker", {
                  key: index,
                  attrs: {
                    position: marker.position,
                    events: marker.events,
                    visible: marker.visible,
                    icon: marker.icon,
                    content: marker.template,
                    draggable: marker.draggable,
                  },
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.polygons, function (polygon, index) {
                return _c("el-amap-polygon", {
                  key: index + "0",
                  ref: "polygon_" + index,
                  refInFor: true,
                  attrs: {
                    bubble: "true",
                    path: polygon.path,
                    "stroke-color": polygon.strokeColor,
                    "fill-color": polygon.fillColor,
                    "fill-opacity": polygon.fillOpacity,
                    draggable: polygon.draggable,
                    events: polygon.events,
                  },
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.limitArea, function (polygon, index) {
                return _vm.limitArea.length > 0
                  ? _c("el-amap-polygon", {
                      key: index + "1",
                      ref: "polygon_" + index,
                      refInFor: true,
                      attrs: {
                        bubble: "true",
                        path: polygon.path,
                        "stroke-color": polygon.strokeColor,
                        "fill-color": polygon.fillColor,
                        "fill-opacity": polygon.fillOpacity,
                        draggable: polygon.draggable,
                        events: polygon.events,
                      },
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.subsistArea, function (polygon, index) {
                return _vm.subsistArea.length > 0
                  ? _c("el-amap-polygon", {
                      key: index + "2",
                      ref: "polygon_" + index,
                      refInFor: true,
                      attrs: {
                        bubble: "true",
                        path: polygon.path,
                        "stroke-color": polygon.strokeColor,
                        "fill-color": polygon.fillColor,
                        "fill-opacity": polygon.fillOpacity,
                        draggable: polygon.draggable,
                        events: polygon.events,
                      },
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.overdueArea, function (polygon, index) {
                return _vm.overdueArea.length > 0
                  ? _c("el-amap-polygon", {
                      key: index + "3",
                      ref: "polygon_" + index,
                      refInFor: true,
                      attrs: {
                        bubble: "true",
                        path: polygon.path,
                        "stroke-color": polygon.strokeColor,
                        "fill-color": polygon.fillColor,
                        "fill-opacity": polygon.fillOpacity,
                        draggable: polygon.draggable,
                        events: polygon.events,
                      },
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("queryBar", {
        ref: "queryBar",
        attrs: {
          title: _vm.title,
          warZone: _vm.warZone,
          partition: _vm.partition,
          limitData: _vm.limitData,
        },
        on: { empty: _vm.empty },
      }),
      _vm._v(" "),
      _vm.ableApply
        ? _c("applyBar", {
            attrs: {
              legal: _vm.legal,
              centerStr: _vm.centerStr,
              latitudeAndLongitudeStr: _vm.latitudeAndLongitudeStr,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("screenAir", {
        on: { limit: _vm.limit, subsist: _vm.subsist, overdue: _vm.overdue },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }