var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "appBar", attrs: { id: "appBar" } }, [
        _c(
          "div",
          { staticClass: "amap-box" },
          [
            _c(
              "el-amap",
              {
                staticClass: "amap-box",
                attrs: {
                  events: _vm.events,
                  zoom: _vm.zoom,
                  center: _vm.center,
                  plugin: _vm.plugin,
                  vid: "amap-vue",
                },
              },
              [
                _vm._l(_vm.markers, function (marker, index) {
                  return _c("el-amap-marker", {
                    key: index,
                    attrs: {
                      position: marker.position,
                      events: marker.events,
                      visible: marker.visible,
                      icon: marker.icon,
                      content: marker.template,
                      draggable: marker.draggable,
                    },
                  })
                }),
                _vm._v(" "),
                _vm._l(_vm.polygons, function (polygon, index) {
                  return polygon != []
                    ? _c("el-amap-polygon", {
                        key: index + "0",
                        ref: "polygon_" + index,
                        refInFor: true,
                        attrs: {
                          id: "amapPolygon",
                          bubble: "true",
                          path: polygon.path,
                          "stroke-color": polygon.strokeColor,
                          "fill-color": polygon.fillColor,
                          "fill-opacity": polygon.fillOpacity,
                          draggable: polygon.draggable,
                          events: polygon.events,
                        },
                      })
                    : _vm._e()
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("Warning", {
        attrs: {
          latitudeAndLongitudeStr: _vm.latitudeAndLongitudeStr,
          centerStr: _vm.centerStr,
          dataURL: _vm.dataURL,
        },
        on: { eliminate: _vm.empty },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }