<template>
  <div class="rightBar">

    <div
      class="imgBtn"
      @click="clickHidden"
      :class="[isHidden ? 'activeHidden' : 'hidden']"
    ></div>
    <div
    v-show="!isHiddenAll"
      class="imgBtn"
      @click="clickLayer"
      :class="[isLayer ? 'activeLayer' : 'layer']"
    ></div>
    <div
    v-show="!isHiddenAll"
      class="imgBtn"
      @click="clickTasks"
      :class="[isTasks ? 'activeTasks' : 'tasks']"
    ></div>
    <div
    v-show="!isHiddenAll"
      class="imgBtn"
      @click="clickSorties"
      :class="[isSorties ? 'activeSorties' : 'sorties']"
    ></div>
    <span v-if="runEnv === 'backend'">
      <div v-show="!isHiddenAll" class="imgBtn returnData" @click="clickReturnData"></div>
      <div v-show="!isHiddenAll" class="imgBtn voice" @click="clickVoice"></div>
    </span>

    <!-- <div>
      <img src="@/assets/img/centerHome/IMG107.png" alt="" width="55"  class="imgBtn voice2" />
    </div> -->
    <div class="switchMap" v-show="isLayer">
          <div class="clickMap" @click="switchMap(1)">
            <img
              v-if="mapIndex === 1"
              src="@/assets/img/homeDialog/selectGaode.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/gaode.png" alt="" />
            <div class="mapType" :class="mapIndex === 1 ? 'isMap' : ''">
              默认地图
            </div>
          </div>
          <div class="clickMap" @click="switchMap(2)">
            <img
              v-if="mapIndex === 2"
              src="@/assets/img/homeDialog/selectTianditu.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/tianditu.png" alt="" />

            <div class="mapType" :class="mapIndex === 2 ? 'isMap' : ''">
              天地图
            </div>
          </div>
          <div class="clickMap" @click="switchMap(3)">
            <img
              v-if="mapIndex === 3"
              src="@/assets/img/homeDialog/selectYingxiang.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/yingxiang.png" alt="" />

            <div class="mapType" :class="mapIndex === 3 ? 'isMap' : ''">
              影像瓦片
            </div>
          </div>
        </div>
  </div>
</template>
<script>
export default {
  props: {
    isHiddenAll: {
      type: Boolean,
    }
  },
  data() {
    return {
      isTemperature: false,
      isHidden: false,
      isLayer: false,
      isTasks: false,
      isSorties: false,
      mapIndex:1, //切换图层
      runEnv: window.runEnv,
    };
  },
  methods: {
    // 点击气候
    clickTemperature() {
      this.isTemperature = !this.isTemperature;
    },
    // 点击隐藏
    clickHidden() {
      this.isHidden = !this.isHidden;
      this.$emit("hiddenAll")
    },
    // 点击图层
    clickLayer() {
      this.isLayer = !this.isLayer;
      this.isTasks = false
      this.isSorties = false
      this.$emit("showRightDioBtn", true,this.isTasks,false)
    },
    // 点击任务
    clickTasks() {
      this.isTasks = !this.isTasks;
      if (this.isTasks) {
        this.isSorties = false
        this.isLayer = false
      }
      this.$emit("showRightDioBtn", true,this.isTasks)
    },
    // 点击架次
    clickSorties() {
      this.isSorties = !this.isSorties;
      if (this.isSorties) {
        this.isTasks = false
        this.isLayer = false
      }
      this.$emit("showRightDioBtn", false,this.isSorties)
    },
    // 点击数据回传
    clickReturnData() {
      window.open(`${process.env.VUE_APP_URL_HOST}/datareturn`);
      // this.isTemperature = !this.isTemperature;
    },
    // 点击音视频通话
    clickVoice() {
      this.$emit("chat")
      // this.isTemperature = !this.isTemperature;
    },
    // // 点击气候
    // clickTemperature() {
    //   this.isTemperature = !this.isTemperature;
    // },
    // 选择地图
    switchMap(index) {
      this.mapIndex = index
      this.$emit("switchMap",index)
    }
  },
};
</script>
<style lang="scss">
.rightBar {
  height: 100%;
  width: 100%;
  position: relative;
  .imgBtn {
    width: 56px;
    height: 56px;
    position: absolute;
    right: 0;
  }
  .temperature {
    top: 3%;
    background: url("~@/assets/img/centerHome/precipitation_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/precipitation_hov.png")
        no-repeat;
      background-size: 100% 100%;

    }
  }
  .activeTemperature {
    top: 3%;
    background: url("~@/assets/img/centerHome/precipitation_pre.png") no-repeat;
    background-size: 100% 100%;
  }

  .hidden {
    top: 19.4%;
    background: url("~@/assets/img/centerHome/eyes_nor.png") no-repeat;
    background-size: 100% 100%;
  }
  .activeHidden {
    top: 19.4%;
    background: url("~@/assets/img/centerHome/eyes_pre.png")
        no-repeat;
      background-size: 100% 100%;
  }
  .layer {
    top: 26.7%;
    background: url("~@/assets/img/centerHome/coating_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/coating_hov.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .activeLayer {
    top: 26.7%;
    background: url("~@/assets/img/centerHome/coating_pre.png")
        no-repeat;
      background-size: 100% 100%;
  }
  .tasks {
    top: 36.8%;
    background: url("~@/assets/img/centerHome/mission_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/mission_hov.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .activeTasks {
    top: 36.8%;
    background: url("~@/assets/img/centerHome/mission_pre.png")
        no-repeat;
      background-size: 100% 100%;
  }
  .sorties {
    top: 44.2%;
    background: url("~@/assets/img/centerHome/sortie_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/sortie_hov.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .activeSorties {
    top: 44.2%;
    background: url("~@/assets/img/centerHome/sortie_pre.png")
        no-repeat;
      background-size: 100% 100%;
  }
  .returnData {
    top: 54.5%;
    background: url("~@/assets/img/centerHome/databackhaul_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/databackhaul_hov.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .voice {
    top: 61.7%;
    background: url("~@/assets/img/centerHome/av_nor.png") no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/av_hov.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .voice2 {
    top: 70%;
  }
  .switchMap {
      width: 228px;
      height: 90px;
      background: #1F3550;
      border-radius: 11px;
      position: absolute;
      right: 58px;
      top: 26.7%;;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #69789E;
      .clickMap {
        cursor: pointer;
      }
      .mapType {
        text-align: center;
        margin-top: 5px;
      }
      .isMap {
        color: #3f67ff;
      }
    }
}
</style>
