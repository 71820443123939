<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <div>
      <!-- 导入顶部通栏 -->
      <TopBar
        @Achieve="Achieve"
        @jurisidStorage="jurisidStorage"
        ref="navBox"
      />
    </div>
    <Drawer ref="child" />
    <Intrdrawer ref="intrdrw" />
    <!-- 导入侧边栏 -->
    <Sidebar
      v-show="isHasSidebar"
      ref="siderChild"
      class="sidebar-container"
      @watchachve="watchachve"
      @intrTags="intrTags"
    />
    <!-- 导入主体部分 -->
    <app-main
      id="feimaapp"
      :class="['app-main-backend', { 'app-main-no-siderBar': !isHasSidebar }]"
      ref="childra"
    />
  </div>
</template>

<script>
import AppMain  from './components/AppMain'
import TopBar  from './components/backend/topBar'
import Sidebar  from './components/backend/sidebar.vue'
import TagsView  from './components/TagsView/index.vue'
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import Drawer from "@/components/messageBox/drawer";
import Intrdrawer from "@/components/messageBox/intrdrawer";

export default {
  name: "Layout",
  components: {
    AppMain,
    TopBar,
    Sidebar,
    TagsView,
    Drawer,
    Intrdrawer,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      runEnv: window.runEnv,
      tagdra: "",
      isHasSidebar: !this.$route.meta.hideSidebar,
    };
  },
  watch: {
    $route() {
      // 判断是否有侧边栏
      if (this.$route.name !== "achievement"){
        this.$refs.child.closeDialog();
      }
      console.log(this.$route.meta);
      this.isHasSidebar = !this.$route.meta.hideSidebar;
    },
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    watchachve() {
      this.$refs.child.changedrawer();
    },
    intrTags() {
      this.$refs.intrdrw.openIntrDrw();
    },
    Achieve() {
      this.$refs.siderChild.watchachve();
    },
    jurisidStorage() {
      // this.$refs.siderChild.showsJur()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  // min-width: 1280px;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.app-main-backend {
  width: calc(100% - 260px);
  height: 1px;
  min-height: calc(100vh - 54px);
}

.app-main-no-siderBar {
  width: 100%;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
/deep/.el-drawer__open .el-drawer.ltr {
  width: 360px !important;
  margin-left: 260px;
  height: calc(100vh - 80px);
  position: absolute;
  margin-top: 80px;
}
.main-container {
  height: calc(100vh - 80px) !important;
}
</style>
