<template>
  <div class="playBack" @mouseleave="leaveBackPlay">
    <div>
      <img
        v-show="!isPlay"
        src="@/assets/img/homeDialog/pause.png"
        alt=""
        @click="setPlayOrPause"
      />
      <img
        v-show="isPlay"
        src="@/assets/img/homeDialog/playVideo.png"
        alt=""
        @click="setPlayOrPause"
      />
    </div>
    <div class="beginTime">
      <span>{{ flyTime | sChangeTime }}</span>
    </div>
    <div
      class="progressBar"
      ref="progressBar"
      @mousedown="processDown"
      @mousemove="progressMove"
      @mouseup="progressUp"
    >
      <div class="progress" ref="progress"></div>
      <div class="line"></div>
    </div>
    <div class="endTime">
      <span>{{ endTime }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    endTime: {
      type: String,
      default: "00:01:00",
    },
    FlightTime: {
      default: "",
    },
    allTimeS: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPlay: true,
      playTimer: null,
      canMove: false,
      onePx: 0,
      initProcessWidth: 5,
      allprocessLength: 0,
      precent: 0,
      flyTime: 0,
    };
  },
  mounted() {
    this.$emit("openBackPlay");
    setTimeout(() => {
      let processLength = this.$refs.progressBar.clientWidth - 5;
      this.allprocessLength = processLength;
      this.onePx = this.allprocessLength / this.allTimeS;
    }, 1000);
    this.setFlyTime();
  },
  watch: {
    allTimeS(newVal, oldVal) {
      this.isPlay = true;
      this.setFlyTime();
    },
  },
  methods: {
    setFlyTime() {
      this.playTimer = setInterval(() => {
        if (this.flyTime < this.allTimeS) {
          this.flyTime++;
          let process = this.$refs.progress;
          let nowWidth = this.initProcessWidth + this.onePx;
          this.initProcessWidth = nowWidth;
          process.style.width = nowWidth + "px";
        } else {
          clearInterval(this.playTimer);
          this.isPlay = false;
        }
      }, 1000);
    },
    setPlayOrPause() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        this.$emit("openBackPlay");
        this.setFlyTime();
      } else {
        this.$emit("pause");
        clearInterval(this.playTimer);
      }
    },
    processDown(e) {
      this.canMove = true;
      this.isPlay = false;
      let progress = this.$refs.progress;
      progress.style.width = e.layerX + "px";
      this.initProcessWidth = e.layerX;
      this.precent = this.initProcessWidth / this.allprocessLength;
      this.isPlay = true;
      clearInterval(this.playTimer);
    },
    progressMove(e) {
      if (this.canMove) {
        let progress = this.$refs.progress;
        progress.style.width = e.layerX + "px";
        this.initProcessWidth = e.layerX;
        this.precent = this.initProcessWidth / this.allprocessLength;
      }
    },
    leaveBackPlay(e) {
      if (this.canMove) {
        this.progressUp();
      }
    },
    progressUp() {
      this.canMove = false;
      this.isPlay = true;
      if (this.precent <= 1) {
        this.flyTime = Math.ceil(this.allTimeS * this.precent);
        this.$emit("moveProgress", this.precent);
        this.setFlyTime();
      } else {
        this.isPlay = false;
      }
    },
    /**
     * 秒转为时间
     */
    sChangeTime(s) {
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    initData() {
      this.initProcessWidth = 5
      this.$refs.progress.style.width = "5px";
      this.flyTime = 0;
      clearInterval(this.playTimer);
      setTimeout(() => {
        let processLength = this.$refs.progressBar.clientWidth - 5;
        this.allprocessLength = processLength;
        this.onePx = this.allprocessLength / this.allTimeS;
      }, 1000);
      this.setFlyTime();
    },
  },
  destroyed() {
    clearInterval(this.playTimer);
  },
  filters: {
    /**
     * 秒转为时间
     */
    sChangeTime(s) {
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
  },
};
</script>
<style lang="scss" scoped>
.playBack {
  width: 534px;
  height: 48px;
  background: rgba(3, 13, 28, 0.8);
  border-radius: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  .progressBar {
    width: 352px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .progress {
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      background: linear-gradient(
        270deg,
        #35d9a7 0%,
        rgba(53, 217, 167, 0) 100%
      );
    }
    .line {
      height: 0.5px;
      width: 100%;
      background-color: #434951;
    }
  }
}
</style>
