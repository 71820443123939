// api接口的导出

// 登录注册用户相关部分
import {
	login,
	register,
	info,
	note,
	uploadData,
	uploadList,
	roleList,
	rolechildList,
	rolechildSave,
	rolechildDel,
	getUserRelatedCount,
	download,
	resetPassword,
	delfile,
	updateUnit,
	userUpdateInfo,
	userVerifyMobile,
	userUpdateMobile,
	userEditPassword,
	getCity,
	userPhoto
} from './api/user.js'

// 群组部分
import {
	groupuserList,
	grouplist,
	groupadd,
	groupdel,
	groupupdate,
	groupuseradd,
	delgroupuser,
	groupinfo,
  getAllUnitAdminUser
} from './api/group.js'

// 联盟部分
import {
	unitList,
	loginByCode,
	unituserList,
	add,
	addManager,
	listManager,
	delManager,
	edit,
	delunit,
	unitLists,
	addunituser,
	delunituser,
	listForUnitBind,
	setsuper,
	setChild,
	resetPsw,
	ranking,
	getPortal,
	userDelPortal,
	exportPortal,
	exportUnit,
	exportUnitUser,
	exportDevice
} from './api/unit.js'

// 权限部分
import {
	permissionsGet,
	permissionsSave
} from './api/jurisdiction.js'

// 成果部分
import {
	addPoi,
	delPoi,
	listResult,
	listPoi,
	publishResult,
	directUpload,
	deleteResult,
	relationWithTask,
	upgrade,
	getInterestType,
	publishPortal,
	dataSaveInterestType,
	dataDelInterestType,
	saveDataResultHeight,
	deviceListStatus,
	deviceAddStatus,
	deviceUpdateStatus,
	deviceDeleteStatus,
} from './api/achievement.js'

// 任务相关
import {
	taskList,
	taskPushType,
	taskListTask,
	taskPushToUnit,
	taskPushToUser,
	taskGetSensorType,
	taskSaveSensorType,
	taskDelSensorType,
	taskGetAuthAreaStatus,
	taskGetChargeProperty,
	taskAddCommon,
	taskUnitConfirm,
	taskSendCommand,
	taskUnitRefuse,
	taskUnitDelete,
	taskGetTaskLog,
	taskGetTaskBriefList,
	taskGetTaskBriefDetail,
	taskGetClassify,
	taskPostDataBack,
	taskPostDataDetail,
	taskSetTaskScore,
	taskIsPortal,
	taskGetTaskDetail,
	noticeUnreadNum,
	getNoticeList,
	setNoticeRead,
	readAllNotice,
	deleteNotice,
	portalListProject,
	portalListTask,
	taskRedoProject,
	taskRedoTask,
	getProjectDetail,
	listFeiMaTask,
	listCommonTask,
	getProjectList,
	demandSaveOrder,
	demandListOrder,
  setOrderIsRead,
	demandShowOrder,
	demandCompleteOrder,
	demandAuditOrder,
	demandInternalOrder,
	demandDeleteOrder,
	demandCancelOrder
} from './api/task.js'

// 设备相关
import {
	ListCategory,
	ListDevice,
	ListBrand,
	ListModel,
	ListStatus,
	AddDevice,
	AddCategory,
	UpdateDevice,
	delteDev,
	deviceAddBrand,
	deviceAddModel,
	deviceDeleteBrand,
	deviceDeleteModel,
	deviceUpdateBrand,
	deviceUpdateModel,
	getFeiMaLocation,
	getFeiMaHistoryFlight,
	getCommonPlane,
	getTerminal,
	getDeviceLocation,
	getDeviceDetailInfo,
	getDeviceMonitorData,
	getLiveUrl
} from './api/equipment.js'

// 聊天相关
import {
	chatListUnit,
	chatListUser,
	chatListGroup,
	chatListGroupUser,
	chatRoomList,
	chatCreateRoom,
	chatRecord,
	chatSendMsg,
	chatAddRoomUser,
	chatUnreadNum,
	chatOpenMedia,
	chatRoomUser
} from './api/chat.js'

// 数据统计相关
import {
	homeData,
	getTaskData,
	portalTaskPushReport,
	portalWarningReport,
	portalFlyData,
	reportGetUserData,
	reportGetFlyData,
  getMissionStatData
} from './api/statistics.js'

// 空域相关
import {
	applyList,
	pending,
	confirm,
	apply,
	audit,
	upload,
	authorizeList,
	detail,
	authorizeDetail,
	saveAuthorize,
	applySearch,
	noflyzone,
	getFlightFootPrints,
	applyData,
	getMyApplyData,
	getMyApplyDataDetail,
	portalGetHomeProject,
	portalGetHomeApplyData,
	manageApplyData,
	updateApplyData,
	getPlaneType,
	getCameraType
} from './api/airspace.js'

// 天气气象接口
import {
	getMapWeatherData,
	getProvinceWeather,
	getWeatherIsogram,
	getCityWeatherByDay,
	getCityWeatherByNow,
	getCityIndices,
	getMonthlyWeatherStat,
	getAnnualWeatherStat,
	weatherGetWarning,
} from './api/weather.js'

// 预警接口
import {
	saveWarning,
	getWarningList,
	getMyWarningList,
	getWarningDetail,
	auditWarning,
	deleteWarning,
	manageWarning
} from './api/warning.js'

// 舆情接口
import {
	sentiment,
	saveDisaster,
	getDisasterList,
	getDisasterDetail
} from './api/disaster.js'

// 设备视图
import {
	sortiesCount,
	sortiesList,
	deviceCategoryBrand,
	deviceViewIndex,
  getDeviceTrack
} from "./api/equipmentView"

// 通用
import {
	listEnum,
	editEnum,
	addEnum,
	delEnum,
} from "./api/common"

export const apis = {
	login,
	register,
	info,
	note,
	groupuserList,
	unitList,
	uploadData,
	uploadList,
	loginByCode,
	unituserList,
	permissionsGet,
	permissionsSave,
	roleList,
	rolechildList,
	rolechildSave,
	rolechildDel,
	addPoi,
	delPoi,
	listPoi,
	taskList,
	listResult,
	publishResult,
	taskPushType,
	taskListTask,
	taskPushToUnit,
	taskPushToUser,
	taskGetSensorType,
	taskSaveSensorType,
	taskDelSensorType,
	taskGetAuthAreaStatus,
	taskGetChargeProperty,
	taskAddCommon,
	taskUnitConfirm,
	taskSendCommand,
	taskUnitRefuse,
	taskUnitDelete,
	taskGetTaskLog,
	taskGetTaskBriefList,
	taskGetTaskBriefDetail,
	taskGetClassify,
	taskPostDataBack,
	taskPostDataDetail,
	taskSetTaskScore,
	taskGetTaskDetail,
	taskIsPortal,
	portalListProject,
	portalListTask,
	taskRedoProject,
	taskRedoTask,
	directUpload,
	add,
	getUserRelatedCount,
	addManager,
	listManager,
	delManager,
	edit,
	delunit,
	unitLists,
	grouplist,
  getAllUnitAdminUser,
	addunituser,
	delunituser,
	groupadd,
	groupdel,
	groupupdate,
	groupuseradd,
	ListCategory,
	ListDevice,
	ListBrand,
	ListModel,
	ListStatus,
	AddDevice,
	AddCategory,
	UpdateDevice,
	delteDev,
	deviceAddBrand,
	deviceAddModel,
	deviceDeleteBrand,
	deviceDeleteModel,
	deviceUpdateBrand,
	deviceUpdateModel,
	listForUnitBind,
	download,
	setsuper,
	setChild,
	deleteResult,
	delgroupuser,
	groupinfo,
	resetPassword,
	resetPsw,
	chatListUnit,
	chatListUser,
	chatListGroup,
	chatListGroupUser,
	chatRoomList,
	chatCreateRoom,
	chatRecord,
	chatSendMsg,
	chatAddRoomUser,
	chatUnreadNum,
	ranking,
	homeData,
	sentiment,
	getProjectList,
	getProjectDetail,
	listFeiMaTask,
	listCommonTask,
	saveDisaster,
	getTaskData,
	chatOpenMedia,
	getTerminal,
	applyList,
	pending,
	confirm,
	apply,
	audit,
	upload,
	authorizeList,
	detail,
	authorizeDetail,
	saveAuthorize,
	chatRoomUser,
	applySearch,
	relationWithTask,
	delfile,
	userUpdateInfo,
	updateUnit,
	userVerifyMobile,
	userUpdateMobile,
	userEditPassword,
	getDisasterList,
	getDisasterDetail,
	saveWarning,
	getWarningList,
	getMyWarningList,
	getWarningDetail,
	auditWarning,
	deleteWarning,
	manageWarning,
	getMapWeatherData,
	getProvinceWeather,
	getWeatherIsogram,
	getCityWeatherByDay,
	getCityWeatherByNow,
	getCityIndices,
	getMonthlyWeatherStat,
	getAnnualWeatherStat,
	upgrade,
	noflyzone,
	getFlightFootPrints,
	noticeUnreadNum,
	getNoticeList,
	setNoticeRead,
	readAllNotice,
	deleteNotice,
	getFeiMaLocation,
	getFeiMaHistoryFlight,
	getCommonPlane,
	getDeviceDetailInfo,
	applyData,
	getMyApplyData,
	portalGetHomeProject,
	portalGetHomeApplyData,
	getMyApplyDataDetail,
	manageApplyData,
	updateApplyData,
	getPortal,
	userDelPortal,
	getInterestType,
	publishPortal,
	dataSaveInterestType,
	dataDelInterestType,
	deviceListStatus,
	deviceAddStatus,
	deviceUpdateStatus,
	deviceDeleteStatus,
	getCity,
	userPhoto,
	getPlaneType,
	getCameraType,
	portalTaskPushReport,
	portalWarningReport,
	portalFlyData,
	reportGetUserData,
	reportGetFlyData,
  getMissionStatData,
	saveDataResultHeight,
	exportPortal,
	exportUnit,
	exportUnitUser,
	exportDevice,
	getDeviceLocation,
	getDeviceMonitorData,
	sortiesCount,
	sortiesList,
	deviceCategoryBrand,
	deviceViewIndex,
  getDeviceTrack,
	getLiveUrl,
	demandSaveOrder,
	demandListOrder,
  setOrderIsRead,
	demandShowOrder,
	demandCompleteOrder,
	demandAuditOrder,
	demandInternalOrder,
	demandDeleteOrder,
	demandCancelOrder,
	listEnum,
	editEnum,
	addEnum,
	delEnum,
}
