import coordtransform from "@/utils/transform.js";
import utils from "@/utils/public"; // 任务公用方法
const turf = require("@turf/turf");
class MapTool {
  constructor(map, mouseTool = "") {
    this.map = map;
    this.mouseTool = mouseTool;
    this.shapeTotal = 0;
    if (mouseTool !== "") {
      this.eventDraw();
    }

    this.shapeType = ""; //绘制类型
    this.polylineData = []; //多边形折线数据
    this.polyline = {}; //折线
    this.drawShapeData = [];
    this.Icon = new AMap.Icon({
      size: new AMap.Size(14, 14),
      image: require("@/assets/img/planTask/dot.png"),
      imageSize: new AMap.Size(14, 14)
    });
    this.measurePointIcon = new AMap.Icon({
      size: new AMap.Size(15, 30),
      image: require("@/assets/img/planTask/measurePointIcon.png"),
      imageSize: new AMap.Size(15, 30)
    });
    this.distanceIcon = new AMap.Icon({
      size: new AMap.Size(14, 14),
      image: require("@/assets/img/planTask/diatanceDot.png"),
      imageSize: new AMap.Size(14, 14)
    });
    this.listMarker = { area: [], distance: [], marker: [] }; //存储绘制的点标记
    this.listText = { area: [], distance: [], marker: [] }; //存储绘制的图形文本标记
    this.listNumber = {}; //编号文本
    this.selectShapeData = {}; //选中的图形
    this.allShapeData = {}; //所有绘制的形状，鼠标工具绘制的
    this.selectIndex = []; //选中的编号集合
    this.isPolyon = false; //是否是多边形
    this.canSelectShape = false;
    this.circleEditor = ""; //圆形编辑测试
    this.markerDrag = ""; //标记点拖动点
    this.markerDragIndex = 0;
    this.editorShape = ""; //要编辑的形状
    this.computeArea = [];
  }
  // 设置绘制的编号文本
  setMapText(position, total, isToolDraw = true) {
    var text = new AMap.Text({
      text: total,
      anchor: "center", // 设置文本标记锚点
      // draggable: true,
      cursor: "pointer",
      style: {
        padding: "7px 0",
        "border-radius": "53%",
        "background-color": "white",
        width: "30px",
        "text-align": "center",
        "font-size": "13px",
        color: "blue"
      },
      position: position
    });
    if (isToolDraw) {
      this.listNumber[this.shapeTotal].push(text);
    }

    text.setMap(this.map);
    return text;
  }
  // 设置面积
  setMapShapeaArea(
    position,
    area,
    backgroundColor = "#7993F5",
    color = "#FFF",
    width = "4.7rem",
    marginTop = "30px"
  ) {
    const text = new AMap.Text({
      text: area,
      anchor: "center", // 设置文本标记锚点
      // draggable: true,
      cursor: "pointer",
      style: {
        "background-color": backgroundColor,
        "margin-top": marginTop,
        width: width,
        "text-align": "center",
        "font-size": "13px",
        color: color
      },
      position: position
    });

    text.setMap(this.map);
    if (
      this.shapeType !== "distance" &&
      this.shapeType !== "area" &&
      this.shapeType !== "marker"
    ) {
      this.listText[this.shapeTotal].push(text);
    } else {
      this.listText[this.shapeType].push(text);
    }
    return text;
  }
  /**
   * 选中绘制的图形
   * @param {*} data
   */
  selectShape(data) {
    let id = data.id;
    let select = data.select;
    this.map.remove(this.listText[id]);
    if (select) {
      this.selectShapeData[id] = data;
      this.selectIndex.push(id);
      this.listMarker[id].forEach(item => {
        item.show();
      });
    } else {
      delete this.selectShapeData[id];
      this.selectIndex.splice(this.selectIndex.indexOf(id), 1);
      this.listMarker[id].forEach(item => {
        item.hide();
      });
    }
    window.getSelectShape();
  }
  // 鼠标工具绘制图形结束
  eventDraw() {
    let that = this;
    AMap.event.addListener(this.mouseTool, "draw", function(e) {
      //添加事件
      e.obj.on("click", function(data) {
        if (that.canSelectShape) {
          data.target.select = !data.target.select;
          that.selectShape(data.target);
        }
      });
      if (that.shapeType !== "area") {
        let radius;
        let lnglat;
        let area;
        let perimeter = 0;
        if (that.shapeType === "circle") {
          radius = e.obj.getRadius() / 1000;
          lnglat = [e.obj.getCenter().lng, e.obj.getCenter().lat];
          area = 3.14 * radius * radius;
          perimeter = (3.14 * 2 * radius).toFixed(1);
        } else {
          area = e.obj.getArea() / 1000000;
        }
        if (area > 0.001) {
          that.isPolyon = false;
          if (that.shapeType !== "polygon") {
            that.shapeTotal = that.shapeTotal + 1;
            that.listMarker[that.shapeTotal] = [];
            that.listText[that.shapeTotal] = [];
            that.listNumber[that.shapeTotal] = [];
          }

          that.allShapeData[that.shapeTotal] = e.obj;
          e.obj["id"] = that.shapeTotal;
          e.obj["shapeType"] = that.shapeType;
          e.obj["select"] = false;
          e.obj["area"] = area;
          if (that.shapeType !== "circle") {
            let rectanglePath = e.obj.getPath();
            let lng = (rectanglePath[0].lng + rectanglePath[1].lng) / 2;
            let lat = (rectanglePath[1].lat + rectanglePath[2].lat) / 2;
            lnglat = [lng, lat];

            if (that.shapeType === "rectangle") {
              that.setMarker(rectanglePath);
              rectanglePath.forEach((item, index) => {
                if (index !== rectanglePath.length - 1) {
                  let p1 = item;
                  let p2 = rectanglePath[index + 1];
                  that.computeDistance(p1, p2);
                } else {
                  let p1 = item;
                  let p2 = rectanglePath[0];
                  that.computeDistance(p1, p2);
                }
              });
            } else {
              // 绘制多边形时的边长,需要判断多边形是否符合规范，即不相交
              let p1 = that.polylineData[that.polylineData.length - 1];
              let p2 = that.polylineData[0];
              that.computeDistance(p1, p2);
              that.polylineData.push(that.polylineData[0]);
              that.setPolyline(that.polylineData);
              // 判断绘制的多边形是否有相交的，有相交提示并删除图形
              let polypath = [];
              // var features = turf.featureCollection([
              //   turf.point( [-97.522259, 35.4691]),
              //   turf.point( [-97.502754, 35.463455]),
              //   turf.point( [-97.508269, 35.463245])
              // ]);

              // var center = turf.center(features)
              let features = [];
              that.polylineData.forEach(item => {
                polypath.push([item.lng, item.lat]);
                features.push(turf.point([item.lng, item.lat]));
              });
              let center = turf.center(turf.featureCollection(features));
              lnglat = center.geometry.coordinates;
              let poly = turf.polygon([polypath]);
              let kinks = turf.kinks(poly);
              if (kinks.features.length > 0) {
                that.shapeTotal = that.shapeTotal - 1;
                that.selectShape(e.obj);
                that.polyline[that.shapeTotal + 1].setMap(null);
                e.obj.setMap(null);
                that.polylineData = [];
                that.polyline[that.shapeTotal + 1] = "";
                utils.$message("多边形不符合规范", "error");
                delete that.allShapeData[that.shapeTotal + 1];
                return;
              }
            }
          } else {
            let bounds = e.obj.getBounds();
            let center = e.obj.getCenter();
            let radius = e.obj.getRadius();
            let circlelrPoint = that.getPointsOnCircle(
              radius,
              [center.R, center.Q],
              4
            );
            that.setMarker(circlelrPoint);

            that.setMapShapeaArea(
              circlelrPoint[0],
              perimeter + "km",
              "#FFFFFF",
              "#DCDCDC",
              "89px",
              "-13px"
            );
          }
          that.setMapText(lnglat, that.shapeTotal);
          that.setMapShapeaArea(lnglat, area.toFixed(2) + "km²");
          that.findLocation(lnglat).then(res => {
            e.obj["location"] = res;
          });
          window.getSelectShape();
          // that.clearPolygon()
          that.polylineData = [];
          // that.clearPlygon()
          // that.polyline = "";
        } else {
          e.obj.setMap(null);
        }
      } else {
        that.computeArea.push(e.obj);
        // e.obj.setMap(null);
      }
    });
  }
  /**
   * 清除多边形点不足三个的
   */
  clearPolygon() {
    // console.log(this.polyline, this.polylineData, this.shapeTotal);
    if (this.polylineData.length <= 2 && this.polylineData.length !== 0) {
      this.listMarker[this.shapeTotal].forEach(item => {
        item.setMap(null);
      });
      this.listText[this.shapeTotal].forEach(item => {
        item.setMap(null);
      });
      this.polyline[this.shapeTotal].setMap(null);
      // delete this.listMarker[this.selectShape]
      // delete this.polyline[this.selectShape]
      this.polyline[this.shapeTotal] = "";
      this.polylineData = [];
      // this.shapeTotal--
    }
  }
  /**
   * 清除测量工具
   */
  clearCompute() {
    this.computeArea.forEach(item => {
      item.setMap(null);
    });
    this.computeArea = [];
  }
  // 是否可以选择图形
  setCanSelectShape(data) {
    this.canSelectShape = data;
  }
  // 绘制多边形
  lnglatInShape(lnglat, number) {
    if (!this.isPolyon) {
      this.isPolyon = true;
      this.shapeTotal = this.shapeTotal + 1;
      this.listMarker[this.shapeTotal] = [];
      this.listText[this.shapeTotal] = [];
      this.listNumber[this.shapeTotal] = [];
    }
    this.setMarker([lnglat], "", -7, -7, number);
  }
  // 设置绘制类型，以便用于计算面积周长
  setShape(type) {
    this.shapeType = type;
  }

  editShape(circle) {
    let that = this;
    this.map.plugin(["AMap.CircleEditor"], function() {
      let circleEditor = new AMap.CircleEditor(that.map, circle);
      circleEditor.open();
    });
  }
  // 计算两点间距离
  computeDistance(p1, p2) {
    const textPos = p1.divideBy(2).add(p2.divideBy(2));
    const distance = (Math.round(p1.distance(p2)) / 1000).toFixed(1) + "km";
    this.setMapShapeaArea(
      textPos,
      distance,
      "#FFFFFF",
      "#DCDCDC",
      "49px",
      "-13px"
    );
  }
  // 设置点标记
  setMarker(data, icon = "", x = -7, y = -7, number = -1) {
    let that = this;
    if (this.shapeType === "polygon") {
      if (this.polylineData.length > 0) {
        let p1 = data[0];
        let p2 = this.polylineData[this.polylineData.length - 1];
        this.computeDistance(p1, p2);
      }

      this.polylineData.push(data[0]);
      this.setPolyline(this.polylineData);
    }
    if (icon === "") {
      icon = this.Icon;
    } else {
      icon = this[icon];
    }
    let marker = "";
    data.forEach((item, index) => {
      marker = new AMap.Marker({
        position: item,
        icon: icon,
        zIndex: 111,
        draggable: true,
        offset: new AMap.Pixel(x, y),
        extData: {
          shapeId: that.shapeTotal, //绘制的几个图形
          index: number === -1 ? index : number, //绘制点的编号，从0开始
          shapeType: that.shapeType //属于哪一种图形
        }
      });
      if (this.shapeType !== "") {
        if (
          this.shapeType !== "distance" &&
          this.shapeType !== "area" &&
          this.shapeType !== "marker"
        ) {
          this.listMarker[this.shapeTotal].push(marker);
        } else {
          this.listMarker[this.shapeType].push(marker);
        }
      }

      marker.on("dragstart", function(e) {
        let data = e.target.getExtData();
        that.markerDragIndex = data.index;
        that.editorShape = that.allShapeData[data.shapeId];
        that.markerDrag = e.target.getPosition();
      });
      marker.on("dragging", function(e) {
        let shapeType = e.target.getExtData().shapeType;
        if (shapeType === "rectangle") {
          that.editRectangle(e);
        } else if (shapeType === "circle") {
          that.editorCircle(e);
        } else {
          that.editorPolygon(e);
        }
      });
      this.map.add(marker);
    });
    return marker;
  }
  /**
   * 编辑圆形
   * @param {} e
   */
  editorCircle(e) {
    let that = this;
    let data = e.target.getExtData();

    let center = that.editorShape.getCenter();
    let radius = that.editorShape.getRadius();
    let draggingLnglat = e.target.getPosition();
    let newRadius = center.distance(draggingLnglat);
    // console.log(typeOf(newRadius),that.editorShape);
    that.editorShape.setRadius(newRadius);
    let newCenter = that.editorShape.getCenter();
    let lnglatData = that.getPointsOnCircle(
      newRadius,
      [newCenter.R, newCenter.Q],
      4
    );
    that.listMarker[data.shapeId].forEach((c, i, arr) => {
      if (i !== data.index) {
        arr[i].setPosition(lnglatData[i]);
      }
    });
    let RadiusKm = newRadius / 1000;
    let perimeter = 2 * 3.14 * RadiusKm;
    let area = 3.14 * RadiusKm * RadiusKm;
    that.listText[data.shapeId][0].setPosition(lnglatData[0]);
    that.listText[data.shapeId][0].setText(perimeter.toFixed(1) + "km");
    that.listText[data.shapeId][1].setText(area.toFixed(1) + "km²");
    that.editorShape.area = area;
  }
  /**
   *编辑矩形
   * @param {} e
   */
  editRectangle(e) {
    let that = this;
    let data = e.target.getExtData();
    that.listMarker[e.target.getExtData().shapeId].forEach((c, i, arr) => {
      let draggingLnglat = e.target.getPosition(); //移动后的经纬度
      let dragIndex = c.getExtData().index;
      let position = c.getPosition();
      // console.log(arr[i]);
      switch (that.markerDragIndex) {
        case 0:
          if (dragIndex === 1) {
            let lnglat = [position.R, draggingLnglat.Q];
            arr[i].setPosition(lnglat);
          }
          if (dragIndex === 3) {
            let lnglat = [draggingLnglat.R, position.Q];
            // console.log(lnglat,dragIndex);
            arr[i].setPosition(lnglat);
          }
          break;
        case 1:
          if (dragIndex === 0) {
            let lnglat = [position.R, draggingLnglat.Q];
            arr[i].setPosition(lnglat);
          }
          if (dragIndex === 2) {
            let lnglat = [draggingLnglat.R, position.Q];
            arr[i].setPosition(lnglat);
          }
          break;
        case 2:
          if (dragIndex === 3) {
            let lnglat = [position.R, draggingLnglat.Q];
            arr[i].setPosition(lnglat);
          }
          if (dragIndex === 1) {
            let lnglat = [draggingLnglat.R, position.Q];
            arr[i].setPosition(lnglat);
          }
          break;
        default:
          if (dragIndex === 2) {
            let lnglat = [position.R, draggingLnglat.Q];
            arr[i].setPosition(lnglat);
          }
          if (dragIndex === 0) {
            let lnglat = [draggingLnglat.R, position.Q];
            arr[i].setPosition(lnglat);
          }
          break;
      }
    });
    let path = [];
    let features = [];
    that.listMarker[data.shapeId].forEach((c, index) => {
      path.push(c.getPosition());
      let lnglat = c.getPosition();
      let nextLnglat = [];
      if (index !== that.listMarker[data.shapeId].length - 1) {
        nextLnglat = that.listMarker[data.shapeId][index + 1].getPosition();
      } else {
        nextLnglat = that.listMarker[data.shapeId][0].getPosition();
      }
      let point1 = turf.point([lnglat.lng, lnglat.lat]);
      let point2 = turf.point([nextLnglat.lng, nextLnglat.lat]);
      let midpoint = turf.midpoint(point1, point2);
      that.listText[data.shapeId][index].setPosition(
        midpoint.geometry.coordinates
      );
      const distance =
        (Math.round(lnglat.distance(nextLnglat)) / 1000).toFixed(1) + "km";
      that.listText[data.shapeId][index].setText(distance);
      features.push(point1);
    });
    that.editorShape.setPath(path);
    let area = that.editorShape.getArea() / 1000000;
    that.listText[data.shapeId][4].setText(area + "km²");
    let centerLnglat = turf.center(turf.featureCollection(features));
    that.listText[data.shapeId][4].setPosition(
      centerLnglat.geometry.coordinates
    );
    that.listNumber[data.shapeId][0].setPosition(
      centerLnglat.geometry.coordinates
    );
    that.editorShape.area = area;
  }
  /**
   * 编辑多边形
   * @param {*} data
   */
  editorPolygon(e) {
    let that = this;
    let data = e.target.getExtData();
    let path = [];
    let polylinePath = [];
    let centerOfMass = [];
    that.listMarker[data.shapeId].forEach((item, i, arr) => {
      let itemLnglat = item.getPosition();
      path.push(itemLnglat);
      polylinePath.push(itemLnglat);
      centerOfMass.push([itemLnglat.lng, itemLnglat.lat]);
    });
    let lnglat = e.target.getPosition();
    let dataLength = that.listMarker[data.shapeId].length;
    let oneLnglat = [];
    let towLnglat = [];
    let textDataLength = that.listText[data.shapeId].length;
    let leftLength = -1;
    let rightLength = -1;
    if (data.index === dataLength) {
      //编辑的是最后一个点
      oneLnglat = that.listMarker[data.shapeId][dataLength - 2].getPosition(); //上一个点
      towLnglat = that.listMarker[data.shapeId][0].getPosition(); //最后一个点
      leftLength = textDataLength - 2;
      rightLength = textDataLength - 3;
    } else {
      if (data.index === 1) {
        //编辑是第一个点
        oneLnglat = that.listMarker[data.shapeId][1].getPosition(); //上一个点
        towLnglat = that.listMarker[data.shapeId][dataLength - 1].getPosition(); //最后一个点
        leftLength = textDataLength - 2;
        rightLength = 0;
      } else {
        oneLnglat = that.listMarker[data.shapeId][data.index - 2].getPosition(); //上一个点
        towLnglat = that.listMarker[data.shapeId][data.index].getPosition(); //最后一个点
        leftLength = data.index - 1;
        rightLength = data.index - 2;
      }
    }
    that.editorShape.setPath(path);
    polylinePath.push(polylinePath[0]);
    centerOfMass.push(centerOfMass[0]);
    that.polyline[data.shapeId].setPath(polylinePath);
    let area = that.editorShape.getArea() / 1000000;
    that.editorShape.area = area;

    let polygon = turf.polygon([centerOfMass]);
    let center = turf.centerOfMass(polygon);
    that.listText[data.shapeId][textDataLength - 1].setText(area + "Km²");
    that.listNumber[data.shapeId][0].setPosition(center.geometry.coordinates);
    that.listText[data.shapeId][textDataLength - 1].setPosition(
      center.geometry.coordinates
    );
    that.editorPolygonText(lnglat, oneLnglat, data, rightLength);
    that.editorPolygonText(lnglat, towLnglat, data, leftLength);

    // console.log(oneLnglat,data,dataLength);
  }
  /**
   * 多边形编辑是修改对应标记点的文本
   * @param {移动点的经纬度}} lnglat
   * @param {相临点的经纬度} otherLnglat
   * @param {点携带的信息} data
   * @param {要编辑的文本下标} index
   */
  editorPolygonText(lnglat, otherLnglat, data, index) {
    let that = this;
    let point1 = turf.point([lnglat.lng, lnglat.lat]);
    let point2 = turf.point([otherLnglat.lng, otherLnglat.lat]);
    let midpoint = turf.midpoint(point1, point2);
    that.listText[data.shapeId][index].setPosition(
      midpoint.geometry.coordinates
    );
    const distance =
      (Math.round(lnglat.distance(otherLnglat)) / 1000).toFixed(1) + "km";
    that.listText[data.shapeId][index].setText(distance);
  }
  setPolyline(data) {
    const that = this;
    if (
      !this.polyline[that.shapeTotal] ||
      this.polyline[that.shapeTotal] === ""
    ) {
      that.polyline[that.shapeTotal] = new AMap.Polyline({
        path: data,
        borderWeight: 3,
        strokeColor: "#3F67FF",
        strokeOpacity: 1,
        strokeWeight: 3,
        // 折线样式还支持 'dashed'
        strokeStyle: "solid",
        zIndex: 50
      });

      this.polyline[that.shapeTotal].setMap(this.map);
    } else {
      this.polyline[that.shapeTotal].setPath(data);
    }
  }
  clearTextAndMarker(name) {
    this.map.remove(this.listText["area"]);
    this.map.remove(this.listMarker["area"]);
    this.map.remove(this.listText["distance"]);
    this.map.remove(this.listMarker["distance"]);
    this.map.remove(this.listText["marker"]);
    this.map.remove(this.listMarker["marker"]);
  }
  /**
   * 删除选中的图形
   */
  delSelectShape() {
    this.selectIndex.forEach(item => {
      this.selectShapeData[item].setMap(null);
      this.map.remove(this.listMarker[item]);
      this.map.remove(this.listNumber[item]);
      this.map.remove(this.listText[item]);
      if (this.polyline[item]) {
        this.polyline[item].setMap(null);
      }

      delete this.selectShapeData[item];
      delete this.allShapeData[item];
    });
    this.selectIndex = [];
  }
  //逆地址查找地址
  findLocation(lnglat) {
    return new Promise((resolve, reject) => {
      let that = this;
      var locationText;
      AMap.plugin("AMap.Geocoder", function() {
        var geocoder = new AMap.Geocoder();
        geocoder.getAddress(lnglat, function(status, result) {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息
            locationText = result.regeocode.addressComponent;
            let location =
              locationText.city +
              " " +
              locationText.district +
              " " +
              locationText.township;
            resolve(location);
          }
        });
      });
    });
  }
  /**
   * 返回绘制的数据
   * @returns
   */
  returnDrawShape() {
    return [this.selectShapeData, this.allShapeData, this.selectIndex];
  }
  commonDrawShape(data, isWgs = false) {
    let path = [];
    let drawPolygon = "";
      let pointData = data.task_coordinate_points.split(";");
      pointData.forEach(item => {
        if (item !== "") {
          let pathLnglat = item.split(",");
          let lnglat = [Number(pathLnglat[0]), Number(pathLnglat[1])];
          if (isWgs) {
            lnglat = coordtransform.gcj02towgs84(pathLnglat[0], pathLnglat[1]);
          }
          path.push(lnglat);
        }
      });

    drawPolygon = this.drawCommonPolygon(path);
    this.map.setFitView();
    return [drawPolygon,drawPolygon];
  }
  drawCommonPolygon(path){
    var polygon = new AMap.Polygon({
      path: path,
      strokeColor: "#065BD5",
      strokeOpacity: 0.73,
      strokeWeight: 3,
      fillColor: "#065BD5",
      fillOpacity: 0.73,
      // strokeStyle还支持 solid
      strokeStyle: "solid"
    });

    this.map.add(polygon);
    return polygon;
  }
  drawFmShape(data,isWgs,number) {
    let path = [];
    let drawPolygon = "";

      let pointData = data.task_coordinate_points.split(";");
      pointData.forEach(item => {
        if (item !== "") {
          let pathLnglat = item.split(",");
          let lnglat = [Number(pathLnglat[1]), Number(pathLnglat[0])];
          if (!isWgs) {
            lnglat = coordtransform.wgs84togcj02(pathLnglat[0], pathLnglat[1]);
          }
            path.push(lnglat);
        }
      });

      drawPolygon = this.drawPolygon(path);
      // if (!data.isKml) {
      //   let center = turf.center(turf.featureCollection(turfPoint));
      //   drawText = this.setMapText(center.geometry.coordinates, number, false);
      // }
    this.map.setFitView();
    return [drawPolygon, drawPolygon];
  }
  /**
   *
   * @param {*} data 航线数据
   * @param {*} isWgs 是否是wgs84坐标
   * @param {*} number 编号
   * @returns
   */
  blockDrawShape(data, isWgs = false, number) {
    let turfPoint = [];
    let path = [];
    let drawPolygon = "";
    let drawText = "";
    if (
      data.survey_block_type === "polygon" ||
      data.survey_block_type === "rectangle"
    ) {
      let pointData = data.survey_area_coordinate_points.split(";");
      pointData.forEach(item => {
        if (item !== "") {
          let pathLnglat = item.split(",");
          let lnglat = [Number(pathLnglat[0]), Number(pathLnglat[1])];
          if (isWgs) {
            lnglat = coordtransform.gcj02towgs84(pathLnglat[0], pathLnglat[1]);
          }
          if (!item.isKml) {
            turfPoint.push(turf.point(lnglat));
            path.push(lnglat);
          }
        }
      });

      drawPolygon = this.drawPolygon(path);
      if (!data.isKml) {
        let center = turf.center(turf.featureCollection(turfPoint));
        drawText = this.setMapText(center.geometry.coordinates, number, false);
      }
    } else {
      let cricleData = data.survey_area_coordinate_points.split(",");
      let lnglat = [cricleData[0], cricleData[1]];
      let radius = cricleData[2];
      this.drawCricle(lnglat, radius);
    }
    this.map.setFitView();
    return [drawPolygon, drawText];
  }
  drawShape(data, isWgs = false) {
    let number = 0;
    data.forEach(item => {
      number++;
      let turfPoint = [];
      let path = [];
      // if (
      //   item.survey_block_type === "polygon" ||
      //   item.survey_block_type === "rectangle"
      // ) {
        let pointData = item.survey_area_coordinate_points.split(";");
        pointData.forEach(item => {
          if (item !== "") {
            let pathLnglat = item.split(",");
            let lnglat = [Number(pathLnglat[0]), Number(pathLnglat[1])];
            if (isWgs) {
              lnglat = coordtransform.gcj02towgs84(
                pathLnglat[0],
                pathLnglat[1]
              );
            }
            // console.log(lnglat);
            if (!item.isKml) {
              turfPoint.push(turf.point(lnglat));
              path.push(lnglat);
            }
          }
        });

        this.drawPolygon(path);
        if (!item.isKml) {
          let center = turf.center(turf.featureCollection(turfPoint));
          this.setMapText(center.geometry.coordinates, number, false);
        }

        // this.setMarker(path);
      // } else {
      //   let cricleData = item.survey_area_coordinate_points.split(",");
      //   let lnglat = [cricleData[0], cricleData[1]];
      //   let radius = cricleData[2];
      //   this.drawCricle(lnglat, radius);
      // }
    });
    this.map.setFitView();
  }
  drawPolygon(path) {
    var polygon = new AMap.Polygon({
      path: path,
      strokeColor: "#3F67FF",
      strokeOpacity: 1,
      strokeWeight: 3,
      fillColor: "#3F67FF",
      fillOpacity: 0.4,
      // strokeStyle还支持 solid
      strokeStyle: "solid"
    });

    this.map.add(polygon);
    return polygon;
  }
  drawCricle(lnglat, radius) {
    var circle = new AMap.Circle({
      center: lnglat,
      radius: radius, //半径
      strokeColor: "#3F67FF",
      strokeOpacity: 1,
      strokeWeight: 3,
      fillColor: "#3F67FF",
      fillOpacity: 0.4,
      strokeStyle: "solid"
    });

    circle.setMap(this.map);
    return circle;
  }
  /**
   * @param {Number} t: 半径
   * @param {Array} e: 中心点经纬度坐标[ lng, lat ]
   * @param {Number} i: 圆上点的个数，默认15个，建议73个
   */
  getPointsOnCircle(t, e, i) {
    for (
      var r = t / 6378137,
        n = [e[0], e[1]],
        o = [this.toRadians(n[1]), this.toRadians(n[0])],
        s = ((i = i || 15), []),
        a = 0;
      a < i;
      a++
    ) {
      var u = (2 * Math.PI * a) / i;
      var h = Math.asin(
        Math.sin(o[0]) * Math.cos(r) +
          Math.cos(o[0]) * Math.sin(r) * Math.cos(u)
      );
      var c =
        o[1] +
        Math.atan2(
          Math.sin(u) * Math.sin(r) * Math.cos(o[0]),
          Math.cos(r) - Math.sin(o[0]) * Math.sin(h)
        );
      s.push([this.toDegrees(c), this.toDegrees(h)]);
    }
    // s.push(s[0])
    return s;
  }
  /**
   * 角度转弧度
   * @param {Number} t 角度值
   * @return {Number} 弧度值
   */
  toRadians(t) {
    return (t * Math.PI) / 180;
  }
  /**
   * 弧度转角度
   * @param {Number} t  弧度值
   * @return {Number} 角度值
   */
  toDegrees(t) {
    return (180 * t) / Math.PI;
  }
  /**
   * 更换坐标系
   */
  switchMapLnglat(isWgs) {
    if (isWgs) {
      for (let key in this.allShapeData) {
        if (this.allShapeData[key].shapeType === "circle") {
          let center = this.allShapeData[key].getCenter();
          let wgs84Center = coordtransform.gcj02towgs84(center.R, center.Q);
          this.allShapeData[key].setCenter(wgs84Center);
        } else {
          let path = this.allShapeData[key].getPath();
          let wgs84Path = [];
          path.forEach(item => {
            wgs84Path.push(coordtransform.gcj02towgs84(item.R, item.Q));
          });
          this.allShapeData[key].setPath(wgs84Path);
        }
      }
      this.switchGcj("gcj02towgs84");
    } else {
      for (let key in this.allShapeData) {
        if (this.allShapeData[key].shapeType === "circle") {
          let center = this.allShapeData[key].getCenter();
          let wgs84Center = coordtransform.wgs84togcj02(center.R, center.Q);
          this.allShapeData[key].setCenter(wgs84Center);
        } else {
          let path = this.allShapeData[key].getPath();
          let wgs84Path = [];
          path.forEach(item => {
            wgs84Path.push(coordtransform.wgs84togcj02(item.R, item.Q));
          });
          this.allShapeData[key].setPath(wgs84Path);
        }
      }
      this.switchGcj();
    }
  }
  switchGcj(gjc = "wgs84togcj02") {
    for (let key in this.listMarker) {
      this.listMarker[key].forEach(item => {
        let lnglat = item.getPosition();
        let wgs84Lnglat = coordtransform[gjc](lnglat.R, lnglat.Q);
        item.setPosition(wgs84Lnglat);
      });
    }
    for (let key in this.listNumber) {
      this.listNumber[key].forEach(item => {
        let lnglat = item.getPosition();
        let wgs84Lnglat = coordtransform[gjc](lnglat.R, lnglat.Q);
        item.setPosition(wgs84Lnglat);
      });
    }
    for (let key in this.listText) {
      this.listText[key].forEach(item => {
        let lnglat = item.getPosition();
        let wgs84Lnglat = coordtransform[gjc](lnglat.R, lnglat.Q);
        item.setPosition(wgs84Lnglat);
      });
    }
    for (let key in this.polyline) {
      // console.log(this.polyline[key].getPosition());
      let path = this.polyline[key].getPath();
      let newPath = [];
      path.forEach(item => {
        let wgs84Lnglat = coordtransform[gjc](item.R, item.Q);
        newPath.push(wgs84Lnglat);
      });
      this.polyline[key].setPath(newPath);
    }
  }
}

export default MapTool;
