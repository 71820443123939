var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rightBar" }, [
    _c("div", {
      staticClass: "imgBtn",
      class: [_vm.isHidden ? "activeHidden" : "hidden"],
      on: { click: _vm.clickHidden },
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isHiddenAll,
          expression: "!isHiddenAll",
        },
      ],
      staticClass: "imgBtn",
      class: [_vm.isLayer ? "activeLayer" : "layer"],
      on: { click: _vm.clickLayer },
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isHiddenAll,
          expression: "!isHiddenAll",
        },
      ],
      staticClass: "imgBtn",
      class: [_vm.isTasks ? "activeTasks" : "tasks"],
      on: { click: _vm.clickTasks },
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isHiddenAll,
          expression: "!isHiddenAll",
        },
      ],
      staticClass: "imgBtn",
      class: [_vm.isSorties ? "activeSorties" : "sorties"],
      on: { click: _vm.clickSorties },
    }),
    _vm._v(" "),
    _vm.runEnv === "backend"
      ? _c("span", [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isHiddenAll,
                expression: "!isHiddenAll",
              },
            ],
            staticClass: "imgBtn returnData",
            on: { click: _vm.clickReturnData },
          }),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isHiddenAll,
                expression: "!isHiddenAll",
              },
            ],
            staticClass: "imgBtn voice",
            on: { click: _vm.clickVoice },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLayer,
            expression: "isLayer",
          },
        ],
        staticClass: "switchMap",
      },
      [
        _c(
          "div",
          {
            staticClass: "clickMap",
            on: {
              click: function ($event) {
                return _vm.switchMap(1)
              },
            },
          },
          [
            _vm.mapIndex === 1
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/selectGaode.png"),
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/gaode.png"),
                    alt: "",
                  },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mapType",
                class: _vm.mapIndex === 1 ? "isMap" : "",
              },
              [_vm._v("\n            默认地图\n          ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "clickMap",
            on: {
              click: function ($event) {
                return _vm.switchMap(2)
              },
            },
          },
          [
            _vm.mapIndex === 2
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/selectTianditu.png"),
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/tianditu.png"),
                    alt: "",
                  },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mapType",
                class: _vm.mapIndex === 2 ? "isMap" : "",
              },
              [_vm._v("\n            天地图\n          ")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "clickMap",
            on: {
              click: function ($event) {
                return _vm.switchMap(3)
              },
            },
          },
          [
            _vm.mapIndex === 3
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/selectYingxiang.png"),
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/yingxiang.png"),
                    alt: "",
                  },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mapType",
                class: _vm.mapIndex === 3 ? "isMap" : "",
              },
              [_vm._v("\n            影像瓦片\n          ")]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }