var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "informationNav" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "inforBody" }, [
        _c(
          "div",
          { staticClass: "leftBar" },
          [
            _c(
              "el-form",
              { ref: "ruleForm", attrs: { "label-width": "100px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { prop: "name", label: "账户名称" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "alliInput",
                      attrs: {
                        placeholder: "请输入",
                        "suffix-icon": "iconfont iconsousuo-copy",
                      },
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", $$v)
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { prop: "desc", label: "个人简介" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "alliInput",
                      attrs: {
                        placeholder: "个人简介",
                        type: "textarea",
                        rows: 8,
                      },
                      model: {
                        value: _vm.ruleForm.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "desc", $$v)
                        },
                        expression: "ruleForm.desc",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "local",
                attrs: { type: "primary" },
                on: { click: _vm.saveBtn },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rightBar" },
          [
            _c(
              "el-form",
              { ref: "ruleForm", attrs: { "label-width": "100px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { label: "用户头像" },
                  },
                  [
                    _c("div", { staticClass: "avatar-uploader" }, [
                      _c("input", {
                        attrs: { type: "file", accept: ".jpg, .jpeg, .png" },
                        on: { change: _vm.avatarUpload },
                      }),
                      _vm._v(" "),
                      _vm.avatarUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.avatarUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }