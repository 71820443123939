<template>
  <!-- 注册账号 -->
  <div class="centerWindow">
    <div class="loginBgc">
      <div class="fmWelcome">
        <div class="fmWelLogin">
          <p class="sayHello">您好,</p>
          <p class="welLogin">欢迎注册</p>
          <p class="fmsider"></p>
          <p class="fmTitle">智慧田长管理平台</p>
          <!-- <p class="sonTitle">调度中心端</p> -->
        </div>
        <div class="interlinkage interlinkage-place">
      <el-popover placement="right" width="120px" trigger="hover">
        <vue-qr
          class="qr"
          :size="120"
          :margin="0"
          :auto-color="true"
          :dot-scale="1"
          :text="htmlUrl"
        />
        <div
          slot="reference"
          @click="toDownload('app')"
          style="margin-bottom: 20px"
          class="showQr"
        >
          下载【飞享终端】APP
        </div>
      </el-popover>
      <div @click="toDownload('DataManager')">下载【DataManager】工具</div>
    </div>
      </div>
      <div class="fmLoginNav">
        <div class="loginNav">
          <div class="loginBox">
            <span class="loginTitle">注册账号</span>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="loginNavbar"
            >
              <el-form-item
                ref="mobile"
                prop="mobile"
                :class="[formMessage.mobile ? 'isNotError' : '']"
              >
                <el-input
                  placeholder="请输入手机号"
                  v-model="ruleForm.mobile"
                  @blur="blurMobile"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="sms_code"
                :class="[formMessage.sms_code ? 'isNotError' : '']"
              >
                <el-input
                  placeholder="请输入验证码"
                  v-model="ruleForm.sms_code"
                  @blur="blurCode"
                >
                  <el-button
                    @click="getRegister"
                    class="getVerification"
                    slot="suffix"
                    type="text"
                    :disabled="disabled"
                    :sms_code="ruleForm.sms_code"
                  >
                    <!-- 获取验证码 -->
                    <span style="line-height: 22px" class="getCodeStyle">{{ btnTitle }}</span>
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item
                prop="name"
                :class="[formMessage.name ? 'isNotError' : '']"
              >
                <el-input
                  placeholder="请输入账户昵称,请使用真实姓名"
                  v-model="ruleForm.name"
                  @blur="blurName"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="unit_id"
                :class="[formMessage.unit_id ? 'isNotError' : '']"
              >
                <el-select
                  v-model="ruleForm.unit_id"
                  placeholder="请选择联盟单位"
                  class="fmSelect"
                  clearable
                  filterable
                  @blur="blurUnit"
                >
                  <el-option
                    v-for="(item, index) in unitList"
                    :key="index"
                    :label="item.unit_name + item.unit_code"
                    :value="item.id"
                    :unit_id="item.unit_id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="password"
                :class="[formMessage.password ? 'isNotError' : '']"
              >
                <el-input
                  type="password"

                  placeholder="请输入密码"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  @blur="blurPassword"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="surePassword"
                :class="[formMessage.surePassword ? 'isNotError' : '']"
              >
                <el-input
                  type="password"
                  placeholder="请确认密码"
                  v-model="ruleForm.surePassword"
                  autocomplete="new-password"
                  @blur="blursurePassword"
                ></el-input>
              </el-form-item>
              <el-form-item class="SignInBar">
                <el-checkbox v-model="agreeStatement"
                  ><span style="color:rgb(96, 98, 102)">我已同意并认真阅读</span><a href="#" @click="lookStatement" style="color:#5185ff">《免责声明》</a></el-checkbox
                >
                <el-button class="loginBar" type="primary" @click="userRegister" :disabled="!agreeStatement"
                  >注&nbsp;&nbsp;册</el-button
                >
              </el-form-item>
            </el-form>
            <div class="footerBar">
              <el-link class="register" @click="registerNum" :underline="false"
                >登录账号</el-link
              >
              <div class="dividing" />
              <el-link class="forgetPsw" :underline="false" @click="forgetPsw"
                >忘记密码</el-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>


    <el-dialog title="免责声明" :visible.sync="disStatemenetData" width="302px" center>
      <disclaimer :show="disStatemenetData" />
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="disStatemenetData = false;agreeStatement = false">取 消</el-button> -->
        <el-button type="primary" @click="disStatemenetData = false;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import disclaimer from "@/components/statemenet/disclaimer"
// let appUrl = "";
// let dataManagerUrl = "";
import { validPhone } from "@/utils/validate.js";
let rule = /^1[0-9][0-9]{9}$/;
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.surePassword !== "") {
          this.$refs.ruleForm.validateField("surePassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      appUrl: "",
      disabled: false, //是否可点击
      btnTitle: "获取验证码",
      dataManagerUrl: "",
      unitList: "",
      unit_id: "",
      ruleForm: {
        mobile: "", //手机号
        password: "", // 密码
        sms_code: "", // 验证码
        name: "", //用户昵称
        unit_id: "", //联盟单位
        surePassword: "", //确认密码
      },
      formMessage: {
        mobile: false,
        password: false,
        sms_code: false,
        name: false,
        unit_id: false,
        surePassword: false,
      },
      rules: {
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确验证码", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入账户昵称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "请输入账户昵称,昵称长度需小于10",
            trigger: "blur",
          },
        ],
        unit_id: [{ required: true, message: "请选择联盟", trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        surePassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      page: 1,
      agreeStatement: false,
      disStatemenetData: false, //免责声明弹窗
      htmlUrl: "",
    };
  },
  mounted() {
    this.upgradeApp("app", false);
  },
  methods: {
    /**
     * 查看免责声明
     */
    lookStatement() {
      this.disStatemenetData = true
    },
    /**
     * 下载
     */
    toDownload(name) {
      switch (name) {
        case "DataManager":
          this.dataManagerUrl === ""
            ? this.upgradeApp("data")
            : window.open(this.dataManagerUrl);
          break;
        case "app":
          this.appUrl === ""
            ? this.upgradeApp("app")
            : window.open(this.appUrl);
          break;
        default:
          break;
      }
    },
    /**
     * 获取app的下载链接
     */
    upgradeApp(category, open = false) {
      this.$apis
        .upgrade(
          {
            category: category,
            version_int: "0.0.1",
          },
          this.token
        )
        .then((data) => {
          category === "app"
            ? (this.appUrl = data.data.data.file_url)
            : (this.dataManagerUrl = data.data.data.file_url);
          if (open) {
            window.open(data.data.data.file_url);
          }
          let ishttps = document.location.protocol

          this.htmlUrl = ishttps+"//"+window.location.host + "/downApp.html"

        })
        .catch((err) => {
          console.error(err);
        });
    },
    blurMobile(val) {
      val.target.value
        ? (this.formMessage.mobile = false)
        : (this.formMessage.mobile = true);
    },
    blurCode(val) {
      val.target.value
        ? (this.formMessage.sms_code = false)
        : (this.formMessage.sms_code = true);
    },
    blurName(val) {
      val.target.value
        ? (this.formMessage.name = false)
        : (this.formMessage.name = true);
    },
    blurUnit(val) {
      val.target.value
        ? (this.formMessage.unit_id = false)
        : (this.formMessage.unit_id = true);
    },
    blurPassword(val) {
      val.target.value
        ? (this.formMessage.password = false)
        : (this.formMessage.password = true);
    },
    blursurePassword(val) {
      val.target.value
        ? (this.formMessage.surePassword = false)
        : (this.formMessage.surePassword = true);
    },
    registerNum() {
      this.$router.push("/");
    },
    // 注册按钮
    userRegister() {
      // validate：验证当前表单元素中所有的规则
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.submitData();
        } else {
        }
      });
    },
    // 数据提交
    submitData() {
      this.$apis
        .register({
          mobile: this.ruleForm.mobile,
          name: this.ruleForm.name,
          password: this.ruleForm.password,
          unit_id: this.ruleForm.unit_id,
          sms_code: this.ruleForm.sms_code,
          register_channel: "手机号注册",
        })
        .then((data) => {
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.$router.push("/");
          } else {
            this.reload();
          }
        })
        .catch((err) => {});
    },
    // 发送验证码
    getRegister() {
      // 开启倒计时
      if (!validPhone(this.ruleForm.mobile)) {
        this.$message.error("手机号不能为空或不合法");
        return;
      } else {
        // 发送验证码请求
        this.$apis
          .note({
            action: "register",
            mobile: this.ruleForm.mobile,
          })
          .then((data) => {
            if (data.data.status == 0) {
              // 倒计时
              this.validateBtn();
              this.$message({
                message: "验证码发送成功",
                type: "success",
              });
            }
          })
          .catch((err) => {});
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    // 获取联盟单位列表
    getUnitList() {
      this.$apis
        .unitList(
          {
            per_page: 9999,
          },
          "",
          "?page=" + this.page
        )
        .then((data) => {
          this.unitList = data.data.data.data;
        })
        .catch((err) => {});
    },
    // 忘记密码
    forgetPsw() {
      this.$router.push("/forgetPsw");
    },
  },
  components: {
    disclaimer
  },
  created() {
    this.getUnitList();
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item.is-error.isNotError .el-input__inner {
  border: 2px solid #a6aab8;
}
/deep/.el-form-item.is-error.isNotError {
  .el-form-item__error {
    display: none !important;
  }
}
/deep/.el-input__inner {
  width: 370px;
}
/deep/.el-form-item {
  margin-top: 30px;
}
/deep/.el-dialog__header{
  padding-bottom: 0;
}
/deep/.el-dialog__footer {
  padding-top: 0;
}
.centerWindow {
  width: 100%;
  height: 100%;

  overflow: auto;
  background: url("../../assets/img/bgc-login.png") no-repeat center;
  background-size: 100% 100%;
  .loginBgc {
    min-width: 1280px;
    min-height: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    .fmWelcome {
      display: block;
      // float: left;
      margin: 0 auto;
      padding-top: 25vh;
      margin-left: 6%;
      .sayHello {
        width: 120px;
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 52px;
      }
      .welLogin {
        width: 160px;
        height: 52px;
        line-height: 82px;
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
      }
      .fmsider {
        width: 166px;
        height: 4px;
        background: rgba(255, 255, 255, 0.43);
        margin-top: 59px;
      }
      .fmTitle {
        width: 850px;
        height: 74px;
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 74px;
        margin-top: 51px;
      }
      .sonTitle {
        width: 250px;
        height: 64px;
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 64px;
        margin-top: 53px;
      }
    }
  }
  .fmLoginNav {
    padding: 68px 68px 0px 0px;
    .loginNav {
      width: 615px;
      min-height: 730px;
      height: calc(100vh - 136px);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      // float: right;
      padding: 50px 164px 50px 127px;
      .loginBox {
        width: 100%;
        height: 100%;
        .loginTitle {
          width: 128px;
          height: 42px;
          font-size: 32px;
          color: rgba(59, 66, 83, 1);
          line-height: 42px;
        }
        .demo-ruleForm {
          margin-top: 37px;
          width: 370px;
          .getVerification {
            color: #0094ff;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .fmSelect {
          width: 370px;
        }
        .SignInBar {
          margin-top: 30px;
          .loginBar {
            font-size: 20px;
            width: 370px;
            height: 55px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 30px;
  .register {
    cursor: pointer;
  }
  .forgetPsw {
    cursor: pointer;
  }
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}

.interlinkage {
  // position: fixed;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  // bottom: 80px;
  width: 182px;
  margin-top: 5%;
}

.interlinkage-place {
  // left: 150px;
}

/deep/ .el-input__inner {
  border-radius: 8px;
  height: 44px;
  border: 2px solid rgba(166, 170, 184, 1);
  background-color: rgba(255, 255, 255, 0.3);
}
/deep/.el-divider__text {
  background-color: transparent;
}
@media only screen and (max-width: 1650px) {
  .interlinkage-place {
    // left: 6%;
  }
  .centerWindow {
    width: 100%;
    height: 100%;
    overflow: auto;
    .loginBgc {
      min-width: 1280px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-size: 100% 100%;
      .fmWelcome {
        display: block;
        // position: absolute;
        // bottom: 18%;
        margin-left: 6%;
        .sayHello {
          width: 120px;
          height: 52px;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 1);
          line-height: 52px;
        }
        .welLogin {
          width: 160px;
          height: 52px;
          line-height: 82px;
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .fmsider {
          width: 166px;
          height: 4px;
          background: rgba(255, 255, 255, 0.43);
          margin-top: 59px;
        }
        .fmTitle {
          width: 100%;
          height: 74px;
          font-size: 36px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 74px;
          margin-top: 51px;
        }
        .sonTitle {
          width: 240px;
          height: 64px;
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 64px;
          margin-top: 53px;
        }
      }
    }
    .fmLoginNav {
      display: block;
      margin-top: 10%;
      // position: absolute;
      // right: 1%;
      .getCodeStyle {
        margin-right: 13px;
      }
      .loginNav {
        width: 480px;
        height: 712px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        // float: right;
        padding: 65px 45px 45px 58px;
        .loginBox {
          width: 100%;
          height: 100%;
          .loginTitle {
            width: 128px;
            height: 42px;
            font-size: 28px;
            color: rgba(59, 66, 83, 1);
            line-height: 2px;
            margin-top: 12px;
          }
          .loginNavbar {
            margin-top: 35px;
          }
          .demo-ruleForm {
            margin-top: -10px;
            width: 370px;
          }
          .SignInBar {
            // margin-top: 80px;
            .loginBar {
              font-size: 20px;
              width: 370px;
              height: 55px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  .footerBar {
    width: 370px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
