<template>
  <div>
    <div class="avatar" v-show="avatar != '' && avatar != null">
      <el-image :style="'width:'+width+'px;height:'+height+'px'" :data-img="avatar" class="avatar-box" :src="avatar">
        <div slot="error" class="image-slot">
          <img
            :style="'width:'+width+'px;height:'+height+'px'"
            src="@/assets/img/user-default-head-big.png"
          />
        </div>
      </el-image>
    </div>
    <div class="avatar" v-show="avatar == '' || avatar == null">
      <img
        :style="'width:'+width+'px;height:'+height+'px'"
        src="@/assets/img/user-default-head-big.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  props: {
    avatar: {
      type: String,
    },
    width: {
      default: 40,
    },
    height: {
      default: 40,
    },
  },
};
</script>

<style lang="less" scoped>
.avatar {
  img {
    border-radius: 50%;
  }
}
</style>