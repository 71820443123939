// 舆情相关接口
import {
	fly,
	baseURL,
	start,
	finishRes,
	finishErr,
	deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
	(request) => {
		start(request)
	}
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
	(response) => {
		finishRes(response)
	},
	(err) => {
		finishErr(err)
	}
)

//  舆情发布
export const sentiment = (data, token) => fly.post(
	baseURL + '/task/getProjectDetail',
	data, deploy(9999999999, token)
)

//  舆情发布表单提交
export const saveDisaster = (data, token) => fly.post(
	baseURL + '/task/saveDisaster',
	data, deploy(9999999999, token)
)


// 舆情列表
export const getDisasterList = (data, token) => fly.post(
	baseURL + '/task/getDisasterList',
	data, deploy(30000, token)
)

// 舆情详情
export const getDisasterDetail = (data, token) => fly.post(
	baseURL + '/task/getDisasterDetail',
	data, deploy(30000, token)
)