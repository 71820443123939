var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            visible: this.registerVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(this, "registerVisible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "registerNav" },
            [
              _c("div", { staticClass: "loginLogo" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/new-logo.png"),
                    alt: "",
                    width: "60px",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "loginTit" }, [
                _c("span", { staticClass: "loginword" }, [
                  _vm._v("智慧田长管理平台"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "getawaytit" }, [
                _c("span", { staticClass: "getaword" }, [
                  _vm._v("【公众门户网站】"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "loginInput",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "regInputBar", attrs: { prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        model: {
                          value: _vm.ruleForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "mobile", $$v)
                          },
                          expression: "ruleForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "regInputBar", attrs: { prop: "sms_code" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入验证码" },
                          model: {
                            value: _vm.ruleForm.sms_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "sms_code", $$v)
                            },
                            expression: "ruleForm.sms_code",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "getVerification",
                              attrs: {
                                slot: "suffix",
                                type: "text",
                                disabled: _vm.disabled,
                                sms_code: _vm.ruleForm.sms_code,
                              },
                              on: { click: _vm.getRegister },
                              slot: "suffix",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.btnTitle) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "regInputBar", attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入密码",
                          type: "password",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.ruleForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "password", $$v)
                          },
                          expression: "ruleForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "regInputBar",
                      attrs: { prop: "surePassword" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请确认密码",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.ruleForm.surePassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "surePassword", $$v)
                          },
                          expression: "ruleForm.surePassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "regBar" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.agreeStatement,
                            callback: function ($$v) {
                              _vm.agreeStatement = $$v
                            },
                            expression: "agreeStatement",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { color: "rgb(96, 98, 102)" } },
                            [_vm._v("我已同意并认真阅读")]
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#5185ff" },
                              attrs: { href: "#" },
                              on: { click: _vm.lookStatement },
                            },
                            [_vm._v("《免责声明》")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "loginBar",
                          attrs: {
                            loading: _vm.loading,
                            type: "primary",
                            disabled: !_vm.agreeStatement,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.userRegister("ruleForm")
                            },
                          },
                        },
                        [_vm._v("注  册")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footerBar" },
                [
                  _c(
                    "el-link",
                    {
                      staticClass: "forgetPsw",
                      attrs: { underline: false },
                      on: { click: _vm.loginBtn },
                    },
                    [_vm._v("登录账号")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "disclaimer" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "免责声明",
                visible: _vm.disStatemenetData,
                width: "300px",
                top: "25vh",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.disStatemenetData = $event
                },
              },
            },
            [
              _c("disclaimer", { attrs: { show: _vm.disStatemenetData } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.disStatemenetData = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }