var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "releaseTask",
    },
    [
      Object.keys(_vm.orderData).length > 0
        ? _c(
            "div",
            { staticClass: "releaseBody" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.changeTest },
                  model: {
                    value: _vm.labelSelect,
                    callback: function ($$v) {
                      _vm.labelSelect = $$v
                    },
                    expression: "labelSelect",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "releasePeople" } }, [
                    _vm._v("发布人"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "taskDescription" } },
                    [_vm._v("任务描述")]
                  ),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "taskTest" } }, [
                    _vm._v("任务面积"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "supplement" } }, [
                    _vm._v("补充说明"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "15px" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "formBody" },
                [
                  _c(
                    "table",
                    { staticClass: "fixedVal", attrs: { id: "releasePeople" } },
                    [
                      _c("tr", [
                        _c("td", [_vm._v("账户")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            attrs: { title: _vm.orderData.created_user_mobile },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.orderData.created_user_mobile) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("账户类型")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            attrs: {
                              title: _vm.orderData.created_user_role_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.orderData.created_user_role_name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("单位")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            attrs: {
                              title: _vm.orderData.created_user_unit_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.orderData.created_user_unit_name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("账户名称")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { attrs: { title: _vm.orderData.created_user_name } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.orderData.created_user_name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("发布方式")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.orderData.created_type) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.detailDescription,
                        rules: _vm.rules,
                        "label-width": "98px",
                        "label-position": "left",
                        size: "small",
                        id: _vm.taskDescription,
                      },
                    },
                    [
                      _vm.isShowAllData
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务类型" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description.task_type
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务名称" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description.task_name
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "授权空域" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderData.description.auth_area
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "传感器" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description.sensor_type
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orderData.description.sensor_type.indexOf(
                                          "LIDAR"
                                        ) === -1,
                                      expression:
                                        "orderData.description.sensor_type.indexOf('LIDAR') === -1",
                                    },
                                  ],
                                  attrs: { label: "影像GSD" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .data_requirements
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orderData.description
                                          .sensor_type !== "LIDAR",
                                      expression:
                                        "orderData.description.sensor_type !== 'LIDAR'",
                                    },
                                  ],
                                  attrs: { label: "航向重叠度" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .longitudinal_overlap
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orderData.description.sensor_type.indexOf(
                                          "LIDAR"
                                        ) !== -1,
                                      expression:
                                        "orderData.description.sensor_type.indexOf('LIDAR') !== -1",
                                    },
                                  ],
                                  attrs: { label: "点密度" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.orderData.description.pts) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orderData.description
                                          .sensor_type === "LIDAR+正射",
                                      expression:
                                        "orderData.description.sensor_type === 'LIDAR+正射'",
                                    },
                                  ],
                                  attrs: { label: "影像GSD" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .data_requirements
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "旁向重叠度" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .lateral_overlap
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.orderData.description
                                          .sensor_type === "LIDAR+正射",
                                      expression:
                                        "orderData.description.sensor_type === 'LIDAR+正射'",
                                    },
                                  ],
                                  attrs: { label: "航向重叠度" },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .longitudinal_overlap
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "成果类型" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "result_type" },
                                    _vm._l(
                                      _vm.orderData.description.result_type,
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item,
                                            staticClass: "result_type_item",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "成果精度" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description
                                          .result_precision
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "截止工期" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description.end_date
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务类型" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderData.description.task_type
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "任务描述" } },
                                [
                                  _c("div", { staticClass: "taskDescribe" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.orderData.description.description
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.pathQuery === "published",
                                      expression: "pathQuery === 'published'",
                                    },
                                  ],
                                  staticStyle: { "margin-left": "99px" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.completeInformation },
                                    },
                                    [_vm._v("去完善")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                staticStyle: { "margin-top": "14px" },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { id: "taskTest" } },
                    [
                      _c("table", { staticClass: "fixedVal" }, [
                        _c("tr", [
                          _c("td", [_vm._v("任务面积")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v("\n              共"),
                            _c("span", { staticClass: "measurementArea" }, [
                              _vm._v(_vm._s(_vm.orderData.survey_area_count)),
                            ]),
                            _vm._v("个区块，累计面积"),
                            _c(
                              "span",
                              {
                                staticClass: "measurementArea",
                                attrs: {
                                  title:
                                    Number(
                                      _vm.orderData.survey_area_area
                                    ).toFixed(1) + "Km²",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    Number(
                                      _vm.orderData.survey_area_area
                                    ).toFixed(2)
                                  )
                                ),
                              ]
                            ),
                            _vm._v("Km²\n            "),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.orderData.survey_area, function (item, index) {
                        return _c("span", { key: index }, [
                          _c("div", { staticClass: "testArea" }, [
                            _c("div", [_vm._v("任务" + _vm._s(index + 1))]),
                            _vm._v(" "),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/homeDialog/taskTestArea.png"),
                                  alt: "",
                                  width: "12px",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "area" }, [
                                _vm._v(
                                  _vm._s(
                                    Number(item.survey_area_area).toFixed(2)
                                  ) + "Km²"
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "location" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/centerHome/onlinetask.png"),
                                alt: "",
                                width: "14px",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(item.survey_street_name)),
                            ]),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("table", { staticClass: "fixedVal" }, [
                      _c("tr", [
                        _c("td", [_vm._v("补充说明")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            attrs: { title: _vm.orderData.additional_remarks },
                          },
                          [_vm._v(_vm._s(_vm.orderData.additional_remarks))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "fixedVal", attrs: { id: "supplement" } },
                      [
                        _c("tr", [
                          _c("td", [_vm._v("附件")]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "files" },
                              _vm._l(_vm.fileList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadFile(
                                          item.url,
                                          item.source_name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.source_name) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }