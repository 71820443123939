var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pageBar" }, [
    _c("div", { staticClass: "bodyBar" }, [
      _vm.applyData
        ? _c("div", { staticClass: "tabelNav" }, [
            _c("p", { staticClass: "numbers" }, [
              _vm._v("数据申请单号: " + _vm._s(_vm.applyData.data_apply_no)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.applyData.created_at)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tabel" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-top": "1%" },
                    attrs: {
                      "max-height": "400",
                      height: "100%",
                      stripe: true,
                      data: _vm.tableData,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "序号",
                        type: "index",
                        index: _vm.indexMethod,
                        width: "40",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "task_id",
                        label: "脚印ID",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "plane_type",
                        label: "飞机型号",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "camera_type",
                        label: "载荷类型",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "work_time",
                        label: "飞行时间",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.work_time) + "分钟"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3199714591
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "province", label: "省" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "city", label: "市" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "high_gsd",
                        label: "GSD",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.high_gsd) + "cm"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4263619074
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "detailed" }, [
              _c(
                "p",
                { staticClass: "deline" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("数据申请类型"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.typeList, function (item, index) {
                    return _c("span", { key: index, staticClass: "type" }, [
                      _vm._v("\n            " + _vm._s(item) + "\n          "),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.applyData.status == 2
        ? _c("div", { staticClass: "midBar" }, [
            _c("p", { staticClass: "titword" }, [
              _vm._v("针对您的需求，经确认:"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "line" }, [
              _vm._v(
                "\n        1.共涉及" +
                  _vm._s(_vm.auditData.unit_qty_1) +
                  "家持有单位，其中" +
                  _vm._s(_vm.auditData.unit_qty_2) +
                  "家可提供\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "line" }, [
              _vm._v(
                "2.实际可提供面积" + _vm._s(_vm.auditData.work_area) + "km²"
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "line" }, [
              _vm._v("3.数据供给周期" + _vm._s(_vm.auditData.period) + "天"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "line" }, [
              _vm._v(
                "4.以上数据合计报价" + _vm._s(_vm.auditData.total_price) + "元"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "concat" }, [
              _vm._v("如需确认，请联系管理员:"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "mobile" }, [_vm._v("13657685783")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "botBar" },
        [
          _vm.applyData.status != 2
            ? _c(
                "el-button",
                {
                  staticClass: "close",
                  attrs: { type: "primary" },
                  on: { click: _vm.closeBtn },
                },
                [_vm._v("关闭")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.applyData.status == 2
            ? _c(
                "el-button",
                {
                  staticClass: "close",
                  attrs: { plain: "" },
                  on: { click: _vm.termination },
                },
                [_vm._v("终止")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.applyData.status == 2
            ? _c(
                "el-button",
                {
                  staticClass: "close",
                  attrs: { type: "primary" },
                  on: { click: _vm.dealBtn },
                },
                [_vm._v("成交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "deline" }, [
      _c("span", { staticClass: "title" }, [_vm._v("数据完整性")]),
      _vm._v(" "),
      _c("span", { staticClass: "type" }, [_vm._v("可接受只覆盖部分区域")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "deline" }, [
      _c("span", { staticClass: "title" }, [_vm._v("成交开放期")]),
      _vm._v(" "),
      _c("span", { staticClass: "type" }, [_vm._v("14天")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }