import { render, staticRenderFns } from "./SHPage.vue?vue&type=template&id=3ca0c5bb&scoped=true&"
import script from "./SHPage.vue?vue&type=script&lang=js&"
export * from "./SHPage.vue?vue&type=script&lang=js&"
import style0 from "./SHPage.vue?vue&type=style&index=0&id=3ca0c5bb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca0c5bb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chenxs/Desktop/gx_web_cxs/gx_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ca0c5bb')) {
      api.createRecord('3ca0c5bb', component.options)
    } else {
      api.reload('3ca0c5bb', component.options)
    }
    module.hot.accept("./SHPage.vue?vue&type=template&id=3ca0c5bb&scoped=true&", function () {
      api.rerender('3ca0c5bb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/messageNotification/SHPage.vue"
export default component.exports