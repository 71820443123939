<template>
  <div class="subject">
    <navTop />
    <div class="bodyNav">
      <div class="top">
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="80px"
        >
          <el-form-item
            class="userForm"
            prop="project_name"
            label="任务名称"
            style="width: 300px"
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.project_name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="project_creator"
            label="创建人"
            style="width: 300px"
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.project_creator"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="DateRange"
            label="消息发送时间"
            style="width: 455px"
            label-width="100px"
          >
            <el-date-picker
              v-model="ruleForm.DateRange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDateRange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="creatData"
            label="创建时间"
            style="width: 455px"
            label-width="80px"
          >
            <el-date-picker
              v-model="ruleForm.creatData"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getcreatData"
            ></el-date-picker>
          </el-form-item>
          <div class="footBtn">
            <div class="fmBtn">
              <el-button class="searchBtn" type="primary" @click="searchBtn"
                >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
              >
              <el-button
                class="reset"
                @click="resetForm('ruleForm')"
                type="primary"
                plain
                >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="center-body">
        <div class="title">全部待办任务</div>
        <div
          class="lineBar"
          v-for="(item, index) in messageData"
          :key="index"
          @click="readNew(item)"
        >
          <div class="task">
            <div class="sircel" v-if="item.is_read == 0"></div>
            <div class="body">
              <div class="imgNav">
                <img
                  v-if="item.project.type == 1 && !item.task"
                  src="../../assets/img/homeImg/message_task_feima.png"
                  alt
                />
                <img
                  v-if="item.project.type == 1 && item.task && item.task.push_type == 1"
                  src="../../assets/img/homeImg/message_task_feima_assigned.png"
                  alt
                />
                <img
                  v-if="item.project.type == 1 && item.task && item.task.push_type == 2"
                  src="../../assets/img/homeImg/message_task_feima_mass.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && !item.task"
                  src="../../assets/img/homeImg/message／task／general.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && item.task && item.task.push_type == 1"
                  src="../../assets/img/homeImg/message／task／general1.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && item.task && item.task.push_type == 2"
                  src="../../assets/img/homeImg/message／task／general2.png"
                  alt
                />
                <img
                  v-if="item.project.type == 3 && !item.task"
                  src="@/assets/img/homeImg/blocksassigned.png"
                  alt
                />
                <img
                  v-if="item.project.type == 3 && item.task && item.task.push_type == 1"
                  src="@/assets/img/homeImg/block_assigned.png"
                  alt
                />
                <img
                  v-if="item.project.type == 3 && item.task && item.task.push_type == 2"
                  src="@/assets/img/homeImg/block_mass.png"
                  alt
                />
              </div>
              <div class="taskNav">
                <div class="leftNav">
                  <p class="taskname">{{ item.project.project_name }}</p>
                  <p class="setuser">创建人：{{ item.from_user_data.name }}</p>
                </div>
                <div class="rightNav">
                  <span class="settime">创建时间：{{ item.created_at }}</span>
                </div>
              </div>
              <div class="operation">
                <el-button
                  type="text"
                  @click.stop="readBtn(item._id.$oid)"
                  :disabled="item.is_read != 0"
                  >标记已读</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-button
                  class="block"
                  type="text"
                  @click.stop="delBtn(item._id.$oid)"
                  >删除</el-button
                >
              </div>
              <div class="timeNav">
                <span class="timer">{{ item.created_at }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="blocks">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="per_page"
            layout="total,  prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 6, //每页多少条
      page: 1,
      ruleForm: {
        project_name: "",
        project_creator: "",
        created_at_from: "",
        created_at_to: "",
        project_created_at_from: "",
        project_created_at_to: "",
        DateRange: [],
        creatData: [],
      },
      messageData: [],
    };
  },
  methods: {
    ...mapMutations(["navMessageStorage", "currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    // 跳转任务详情
    readNew(param) {
      let navName = ""
      let path = "/blockTaskPush"
      switch(param.project.type){
        case 1:
          navName = "航线任务"
          break;
        case 2:
          navName = "通用任务"
          break;
        default:
          navName = "区块任务"
          path = "/blockTaskPush"
          break;
      }
      this.currentUrlStorage([
        "首页",
        "任务下达",
        navName
      ]);
      this.navMessageStorage(0);
      this.$router.push({
        path: path,
        query: {
          id: param.project.id,
          nav: param.project.type,
          name: param.project.project_name,
          mobile: param.project.user.mobile,
          push_flag: param.project.push_flag,
        },
      });
      //减少未读数
      this.readBtn(param._id.$oid);
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.getTaskList();
    },
    // 处理消息发送时间
    getDateRange() {
      this.ruleForm.created_at_from =
        this.ruleForm.DateRange[0] + " " + "00:00:00";
      this.ruleForm.created_at_to =
        this.ruleForm.DateRange[1] + " " + "23:59:59";
    },
    // 处理任务创建时间
    getcreatData() {
      this.ruleForm.project_created_at_from =
        this.ruleForm.DateRange[0] + " " + "00:00:00";
      this.ruleForm.project_created_at_to =
        this.ruleForm.DateRange[1] + " " + "23:59:59";
    },
    // 读单条消息
    readBtn(param) {
      this.$apis
        .setNoticeRead(
          {
            id: param,
          },
          this.token
        )
        .then((data) => {
          this.getTaskList();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 删除
    delBtn(param) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .deleteNotice(
              {
                id: param,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  showClose: true,
                  message: data.data.message,
                  type: "success",
                });
                this.getTaskList();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    // 分页
    handleNext() {
      this.page++;
      this.getTaskList(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.getTaskList(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.getTaskList(this.page, this.per_page);
    },
    // 待办任务列表
    getTaskList() {
      this.$apis
        .getNoticeList(
          {
            type: 1,
            per_page: 6,
            page: this.page,
            project_name: this.ruleForm.project_name,
            project_creator: this.ruleForm.project_creator,
            created_at_from: this.ruleForm.created_at_from,
            created_at_to: this.ruleForm.created_at_to,
            project_created_at_from: this.ruleForm.project_created_at_from,
            project_created_at_to: this.ruleForm.project_created_at_to,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.messageData = data.data.data.data;
            this.total = data.data.data.total;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.getTaskList();
  },
  computed: {
    ...mapState(["user", "token", "msgCenter"]),
  },
  mounted() {
    if (this.$route.query.nav == "taskp") {
      this.currentUrlStorage(["首页", "消息通知", "待办任务"]);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
.blocks {
  padding: 20px 0 0 70px;
}
.block {
  color: #fd5c5e;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  width: 32%;
  margin-left: 10px;
}
.bodyNav {
  height: calc(100vh - 130px);
  width: 100%;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 0 15px 2% 15px;
  background-color: #f0f0f0;
  .top {
    width: 100%;
    height: 20%;
    display: flow-root;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  }
}
.center-body {
  width: 100%;
  height: 76%;
  margin-top: 1%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  position: relative;
  overflow-y: auto;
  padding: 30px;
}
.formbox {
  float: left;
  margin-top: 3%;
  width: 100%;
}
.fmBtn {
  float: right;
  width: 210px;
}
.lineBar {
  height: 78px;
  width: 100%;
  border-top: 1px solid #dde2ef;
  cursor: pointer;
}
.lineBar:hover {
  background-color: #dde2ef;
}
.task {
  width: 100%;
  height: 100%;
}
.sircel {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fd5c5e;
  margin-left: 10px;
  float: left;
  margin-top: 34px;
}
.imgNav {
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  width: 6%;
}
.taskNav {
  height: 100%;
  display: flex;
  align-items: center;
  float: left;
  width: 49%;
}
.timeNav {
  height: 100%;
  display: flex;
  align-items: center;
  float: right;
  width: 10%;
}
.timer {
  font-size: 12px;
  color: #838a9d;
}
.play {
  height: 28px;
  border: 1px solid #a6aab8;
  background-color: #f3f5f7;
  line-height: 28px;
}
.rightNav {
  margin-top: 28px;
  margin-left: 15px;
}
.leftNav {
}
.taskname {
  font-size: 14px;
  color: #3c4353;
}
.setuser {
  font-size: 12px;
  color: #838a9d;
}
.settime {
  font-size: 12px;
  color: #838a9d;
}
.sircel {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fd5c5e;
  margin-left: 10px;
  float: left;
  margin-top: 34px;
}
.body {
  height: 100%;
  float: right;
  width: 98%;
}
.operation {
  float: right;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.title {
  font-size: 16px;
  color: #3c4353;
  margin-bottom: 15px;
}
</style>
