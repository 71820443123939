import FmMonitor from "./FmMonitor.js";
import MapHelper from "./MapHelper.js";
import { gcj02towgs84, towgs84array } from "../Tools/transfrom.js";
import { planeM } from "../Tools/getPlaneImage.js";
import { PathConfig } from "../index.js";

/**
 * FmMapManager 功能描述
 * - 根据目标航点生成目标航线
 * - 根据已飞点生成已飞航线
 * - 根据起始、结束点生成标记点
 * - 根据当前位置更新当前飞机标记点
 *
 * 外部调用api
 * 1. 实时监视器调用
 * - createMonitor
 * - stopMonitor
 * - errMonitor
 * - onPlaneMove
 *
 * 2. 回放调用
 * - createPlayback
 * - setPlaybackPercent
 * - onPlaneMove
 */


class FmMapManager {
  constructor(options) {
    this._mapHelper; // 分平台处理地图数据
    this._fmManagerOptions;  // 航线配置
    this._isWgsNeeded; // 是否需要wgs84信息。设置为false将减少一些数据处理
    this._markersOptions;  // 标记点配置
    this.fmMonitor = {};
    this.airlines = [];
    this.markers = [];
    this.wgs84Markers = [];
    this.airline = null;
    this.flyingAirline = null;
    this.wgs84Airline = null;
    this.wgs84FlyingAirline = null;

    this._mapHelper = new MapHelper(
      options.platform,
      options.map || options.mapId,
      options.airlineOptions
    );

    this._markersOptions = options.markersOptions
    this._isWgsNeeded = options.isWgsNeeded || true;
    this._fmManagerOptions = options;

    if(options.wsMessageFunc) FmMonitor.prototype.getSendMessageFun = options.wsMessageFunc


    this.init();
  }
  init() {
    // console.log("初始化");
  }
  /**
   * 基础初始化函数
   */
  baseInit() {
    this.fmMonitor = new FmMonitor(this._fmManagerOptions);
    this.initAirlines();
    this.initHomeMarkers();
  }
  /*
   * 打开监视器
   * @param {Object} options, flight_data: 接口返回的数据， local_id: 任务id, url: websocket链接
   */
  createMonitor() {
    this.baseInit()
    console.log(this._markersOptions.planeMarker.icon);
    // 监听socket连接错误
    this.fmMonitor.onErrorSocket((err) => {
      this.errorMonitor(err);
    });
  }

  /**
   * 关闭监视器
   */
  stopMonitor() {
    this.fmMonitor.closeSocket();
  }
  /**
   * 监视器连接失败
   */
  errorMonitor(err) {
    console.log(err);
  }
  /**
   * 打开回放
   */
  createPlayback() {
    this.baseInit()
  }
  /**
   * 设置进度
   */
  setPlaybackPercent(percent) {
    this.fmMonitor.setPlaybackProgress(percent)
  }
  /**
   * 初始化航线
   */
  initAirlines() {
    const { baseLinePoints, flyingPoints } = this.fmMonitor
    // 初始化任务航线
    this.airline = this._mapHelper.getAirline(baseLinePoints, 'base' );

    this.airlines = [this.airline];

    // 初始化正在飞的航线
    this.flyingAirline = this._mapHelper.getAirline( flyingPoints, 'flying');

    // wgs84航线初始化
    if (this._isWgsNeeded) {
      const wgs84Path = towgs84array([
        ...baseLinePoints,
      ]);
      const wgs84FlyPath = towgs84array([...flyingPoints]);
      this.wgs84Airline = this._mapHelper.getAirline(
        wgs84Path,
        'base'
      );
      this.wgs84FlyingAirline = this._mapHelper.getAirline(
        wgs84FlyPath,
        'flying'
      );
    }

    // 监听飞机位置改变event
    this.fmMonitor.onPositionUpdated = (newPosition) => {
      this.onPlaneMove(newPosition);
      this.onAirlineUpdated(newPosition);
      this.onMarkerUpdated(newPosition)
    };
    // 清除已有航线，重新绘制
    this.fmMonitor.clearAirLinePath = ()=>{
      // this.flyingAirline.path = []
      // this.wgs84FlyingAirline.path = []
    }
    this.fmMonitor.clearPath = ()=>{
      this.wgs84FlyingAirline.path = []
      this.flyingAirline.path = []
    }
    this.fmMonitor.backPlayMove = newPosition => {
      // this.onPlaneMove(newPosition);
      this.onAirlineUpdated2(newPosition);
    };
  }
  /**
   * 初始化飞机标记点
   */
  initHomeMarkers() {
    const { landingMarker, endPointMarker } = this._markersOptions
    const { homePoint, endPoint, planeType } = this.fmMonitor
    landingMarker.icon = `${PathConfig.landingPointImgUrl}`;
    endPointMarker.icon = `${PathConfig.endPointImgUrl}`;

    // gcj02 Markers
    const marker1 = this._mapHelper.addMarker( homePoint, landingMarker);
    const marker2 = this._mapHelper.addMarker( endPoint, endPointMarker );

    const markerArr = [marker1, marker2];
    this.markers = this.addMarkerToMarkers(markerArr, this.markers);

    // wgs84 Markers
    const wgs84HomePoint = gcj02towgs84(homePoint.longitude, homePoint.latitude, homePoint.altitude)
    const wgsMarker1 = this._mapHelper.addMarker( wgs84HomePoint, landingMarker );
    const wgs84EndPoint = gcj02towgs84(endPoint.longitude, endPoint.latitude, endPoint.altitude)
    const wgsMarker2 = this._mapHelper.addMarker( wgs84EndPoint, endPointMarker);

    const markerArr2 = [wgsMarker1, wgsMarker2];
    this.wgs84Markers = this.addMarkerToMarkers(
      markerArr2,
      this.wgs84Markers
    );
    // 无人机icon
    this._markersOptions.planeMarker.icon = planeM.getIconImageUrl(planeType);
  }
   /**
   * 添加标记点数组到_markers 数组
   * @param {Array} markers
   */
  addMarkerToMarkers(markerArr, markers) {
    console.log(markerArr, markers);
    return [...markers, ...markerArr];
  }
  /**
   * 飞机移动
   */
  onPlaneMove(e) {
    console.log(e);
  }
  onPlaybackMove(e) {

  }
  /**
   * 航线更新
   */
  onAirlineUpdated() {
    const { currentPosition, wgs84CurrentPosition } = this.fmMonitor

    this.flyingAirline = this._mapHelper.updateAirline(
      currentPosition,
      this.flyingAirline
    );
    const airlines = [this.airline, this.flyingAirline];
    this.airlines = [...airlines];

    if (this._isWgsNeeded) {
      this.wgs84FlyingAirline = this._mapHelper.updateAirline(
        wgs84CurrentPosition,
        this.wgs84FlyingAirline
      );
    }
  }
  /**
   * 航线更新
   */
   onAirlineUpdated2(position) {
    // const { currentPosition, wgs84CurrentPosition } = this.fmMonitor
    // console.log(this.fmMonitor);
    const currentPosition = position;
    const wgs84CurrentPosition = gcj02towgs84(position.longitude, position.latitude)
    this.flyingAirline = this._mapHelper.updateAirline(
      currentPosition,
      this.flyingAirline
    );
    const airlines = [this.airline, this.flyingAirline];
    this.airlines = [...airlines];

    if (this._isWgsNeeded) {
      this.wgs84FlyingAirline = this._mapHelper.updateAirline(
        wgs84CurrentPosition,
        this.wgs84FlyingAirline
      );
    }
  }
  /**
   * 标记点更新
   */
  onMarkerUpdated() {
    const { currentPosition, wgs84CurrentPosition } = this.fmMonitor
    // 地图标记更新
    if (this.markers.length === 2) {
      const marker = this._mapHelper.addMarker(
        currentPosition,
        this._markersOptions.planeMarker
      );
      const wgs84Marker = this._mapHelper.addMarker(
        wgs84CurrentPosition,
        this._markersOptions.planeMarker
      );
      this.markers = this.addMarkerToMarkers([marker], this.markers);
      this.wgs84Markers = this.addMarkerToMarkers(
        [wgs84Marker],
        this.wgs84Markers
      );
    }
  }
}

export default FmMapManager;
