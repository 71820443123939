export const planeImgPathMapBase = '/static/img/feimaToDetail/stat/' // 监控页面飞机图片路径

export const planeImgPathCloudBase = '/static/img/feimaToDetail/cloudplay/' // 云播页面飞机图片路径

export const signalImageBaseUrl = '/static/img/feimaToDetail/signal_' // 信号图片base地址

export const landingPointImgUrl = '/static/img/feimaToDetail/landing_point.png' // 起始点标记图片

export const endPointImgUrl = '/static/img/feimaToDetail/end_Point.png'  // 结束点标记图片

let PathConfig = (function() {
  let instance = null

  return function(configs) {
    if(configs) {
      this.planeImgPathMapBase = configs.planeImgPathMapBase // 监控页面飞机图片路径
      this.planeImgPathCloudBase = configs.planeImgPathCloudBase // 云播页面飞机图片路径
      this.signalImageBaseUrl = configs.signalImageBaseUrl // 信号图片base地址
      this.landingPointImgUrl = configs.landingPointImgUrl // 起始点标记图片
      this.endPointImgUrl = configs.endPointImgUrl  // 结束点标记图片
    } else {
      this.planeImgPathMapBase = planeImgPathMapBase
      this.planeImgPathCloudBase = planeImgPathCloudBase
      this.signalImageBaseUrl = signalImageBaseUrl
      this.landingPointImgUrl = landingPointImgUrl
      this.endPointImgUrl = endPointImgUrl
    }
    if(instance){
      return instance
    }
    return instance = this;
  }
})()

PathConfig.prototype.setConfigs = function(configs) {
  if(configs) {
    this.planeImgPathMapBase = configs.planeImgPathMapBase // 监控页面飞机图片路径
    this.planeImgPathCloudBase = configs.planeImgPathCloudBase // 云播页面飞机图片路径
    this.signalImageBaseUrl = configs.signalImageBaseUrl // 信号图片base地址
    this.landingPointImgUrl = configs.landingPointImgUrl // 起始点标记图片
    this.endPointImgUrl = configs.endPointImgUrl  // 结束点标记图片
  } else {
    throw('configs can not be empty')
  }
}

const pathConfigs = new PathConfig()
export default pathConfigs
