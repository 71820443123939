<template>
  <div class="pageBar">
    <div class="bodyBar">
      <div class="tabelNav">
        <div class="top">
          <span class="title">详情</span>
          <el-divider></el-divider>
        </div>
        <div class="midlle">
          <span class="tit">基本信息</span>
          <div class="info" v-if="dataObj">
            <div class="infobar">
              <p class="titleword">订单编号</p>
              <p class="data">{{ dataObj.data_apply_no }}</p>
            </div>
            <div class="infobar">
              <p class="titleword">开放期</p>
              <p class="data">{{ dataObj.wait_time }}</p>
            </div>
            <div class="infobar">
              <p class="titleword">申请类型</p>
              <div class="types" v-if="dataObj.category !== undefined">
                <span
                  class="data"
                  v-for="(item, index) in JSON.parse(dataObj.category)"
                  :key="index"
                  >{{ item }}&nbsp;</span
                >
              </div>
            </div>
            <div class="infobar">
              <p class="titleword">数据完整性</p>
              <p class="data" v-if="dataObj.integrality == 0">
                可接受只覆盖部分区域
              </p>
              <p class="data" v-if="dataObj.integrality == 1">
                只接受只全区域覆盖
              </p>
            </div>
          </div>
        </div>
        <div class="bottom">
          <span class="tit">基本信息</span>
          <el-table
            height="170"
            :stripe="true"
            :header-cell-style="{ background: '#DDE6EF' }"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="task_id"
              label="任务编号"
              class="number"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="uas_serial_id"
              label="飞机序列号"
              class="number"
            >
              <template slot-scope="scope">
                {{
                  scope.row.uas_serial_id == ""
                    ? "未同步"
                    : scope.row.uas_serial_id
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="plane_type"
              label="飞机型号"
              class="number"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="unit_name"
              label="所属公司名字"
              class="number"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="work_area"
              label="面积"
              class="number"
            ></el-table-column>
            <el-table-column align="center" label="报价" class="number">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.input"
                  @change="input(scope.row)"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" label="服务费" class="number">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.charge"
                  @change="charge(scope.row)"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="total_price"
              label="总价"
              class="number"
            >
              <template slot-scope="scope">
                {{
                  Number(scope.row.input) + Number(scope.row.charge) > 0
                    ? Number(scope.row.input) + Number(scope.row.charge)
                    : 0
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="midNav">
        <span class="title">审核通过</span>
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="110px"
        >
          <el-form-item
            class="userForm"
            prop="plane_type_number"
            label="飞机序列号种类"
            style
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.plane_type_number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="work_area"
            label="目标覆盖面积"
            style
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.work_area"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="period" label="供给周期" style>
            <el-input placeholder="请输入" v-model="ruleForm.period"></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="total_price" label="总价" style>
            <el-input
              placeholder="请输入"
              v-model="ruleForm.total_price"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="unit_qty_1"
            label="持有单位数量"
            style
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.unit_qty_1"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="unit_qty_2"
            label="提供单位数量"
            style
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.unit_qty_2"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="contact" label="联系方式" style>
            <el-input
              placeholder="请输入"
              v-model="ruleForm.contact"
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm">
            <el-button class="buttonBar" @click="formBtn" type="primary"
              >审核通过</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="botNav">
        <p class="title">审核退回</p>
        <span class="returns">退回原因</span>
        <el-form class="formNav" ref="ruleForm" :model="returnForm">
          <el-form-item class="seruleform" prop="software_code">
            <el-radio-group
              @change="getLabelId"
              v-model="returnForm.refuse"
              class="radioBar yesorno"
            >
              <el-radio :label="1"
                >经确认，您当前申请区域未达全面覆盖要求，无法达成您的需求。</el-radio
              >
              <el-radio :label="2"
                >经确认，您当前申请的数据成果受控，无法达成您的需求。</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="remarks" label="备注" style>
            <el-input v-model="returnForm.remarks"></el-input>
          </el-form-item>
        </el-form>

        <el-button class="footbutton" @click="returnBtn" type="danger"
          >审核退回</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      id: "",
      dataObj: {},
      tableData: [],
      ruleForm: {
        plane_type_number: "",
        work_area: "",
        period: "",
        total_price: "",
        unit_qty_1: "",
        unit_qty_2: "",
        contact: "",
      },
      status: 2,
      task_list: [],
      returnForm: { refuse: "", remarks: "" },
      total_price: 0,
      offer: "",
      service: "",
    };
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.getdataList();
  },
  methods: {
    checkSelectable(row) {
      return row.input || row.charge == "";
    },
    input(param) {
      this.offer = param.input;
      this.total_price = Number(this.offer) + Number(this.service);
    },
    charge(param) {
      this.service = param.charge;
      this.total_price = Number(this.offer) + Number(this.service);
    },
    //   获取退回原因id
    getLabelId(param) {
      this.returnForm.refuse = param;
    },
    //   选中项
    handleSelectionChange(val) {
      this.ruleForm.work_area = 0;
      this.ruleForm.total_price = 0;
      let workatea = 0;
      let unitList = [];
      let temp = [];
      let all = [];
      let allStr = [];
      let fly = [];
      let flyStr = [];
      for (let i = 0; i < val.length; i++) {
        all.push(val[i].unit_name);
        fly.push(val[i].camera_type);
        for (let k = 0; k < all.length; k++) {
          if (allStr.indexOf(all[k]) == -1) {
            allStr.push(all[k]);
          }
        }
        this.task_list[i] = {
          task_id: val[i].task_id,
          quote: val[i].input,
          service_charge: val[i].charge,
        };
        workatea += Number(val[i].work_area);
        this.ruleForm.work_area = workatea.toFixed(6);
        this.ruleForm.total_price += Number(
          Number(val[i].input) + Number(val[i].charge)
        );
        unitList.push(val[i].unit_name);
        for (let j = 0; j < unitList.length; j++) {
          if (temp.indexOf(unitList[j]) == -1) {
            temp.push(unitList[j]);
          }
        }
        for (let h = 0; h < fly.length; h++) {
          if (flyStr.indexOf(fly[h]) == -1) {
            flyStr.push(fly[h]);
          }
        }
        this.ruleForm.plane_type_number = flyStr.length;
        this.ruleForm.unit_qty_2 = temp.length;
        this.ruleForm.unit_qty_1 = allStr.length;
      }
    },
    getdataList() {
      this.$apis
        .getMyApplyDataDetail(
          {
            id: this.id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.dataObj = data.data.data.apply_data;
            this.tableData = data.data.data.task_data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 审核按钮
    formBtn() {
      this.$confirm("确认信息无误继续此操作吗？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .updateApplyData(
              {
                id: this.id,
                status: this.status,
                plane_type_number: this.ruleForm.plane_type_number,
                work_area: this.ruleForm.work_area,
                period: this.ruleForm.period,
                total_price: this.ruleForm.total_price,
                unit_qty_1: this.ruleForm.unit_qty_1,
                unit_qty_2: this.ruleForm.unit_qty_2,
                contact: this.ruleForm.contact,
                task_list: this.task_list,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                window.history.go(-1);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    // 审核退回
    returnBtn() {
      if (this.returnForm.refuse == "") {
        this.$message({
          message: "请选择退回原因",
          type: "error",
        });
        return false;
      }
      this.$confirm("确认继续此操作吗？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .updateApplyData(
              {
                id: this.id,
                status: 3,
                refuse: this.returnForm.refuse,
                remarks: this.returnForm.remarks,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                window.history.go(-1);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
  },
  watch: {
    $route() {
      this.id = this.$route.query.id;
      this.getdataList();
    },
  },
  computed: {
    ...mapState(["user", "token", "centerDialogVisible"]),
  },
};
</script>

<style lang="less" scoped>
.types {
  display: flex;
  flex-wrap: wrap;
}
.footbutton {
  float: right;
  margin: 50px 68px 0 0;
}
.formNav {
  float: left;
  margin-left: 30px;
}
/deep/.el-radio {
  margin-bottom: 5px;
}
.buttonBar {
  margin-left: 110px;
}
/deep/.el-form-item {
  width: 260px;
}
/deep/.el-input {
  width: 140px;
}
.formbox {
  flex-wrap: wrap;
  display: flex;
  margin-top: 10px;
}
.pageBar {
  width: 100%;
  height: 100%;
}
.topBar {
  float: left;
}
.bodyBar {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #f0f0f0;
  padding: 20px 272px 0 272px;
  float: left;
  .tabelNav {
    width: 100%;
    height: 53%;
    background-color: #fff;
    padding: 20px 0px 0 0px;
    .title {
      margin-left: 40px;
      font-size: 17px;
    }
    .midlle {
      width: 100%;
      padding: 0px 40px 0 40px;
      .tit {
        font-size: 17px;
      }
      .info {
        display: flex;
        margin-top: 20px;
        .infobar {
          width: 200px;
          height: 96px;
          border: 1px solid #a4aab9;
          background-color: #f5f5f5;
          padding: 0 0 0 19px;
          border-radius: 4px;
          margin-right: 40px;
          .titleword {
            font-size: 12px;
            color: #a4aab9;
          }
          .data {
            font-size: 14px;
            color: #1782fb;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 20px 40px 0 40px;
    }
  }
  .midNav {
    width: 100%;
    height: 20%;
    background-color: #fff;
    padding: 20px 40px 0 40px;
    margin-top: 20px;
    .title {
      color: #1782fb;
      font-size: 16px;
    }
  }
  .botNav {
    width: 100%;
    height: 20%;
    background-color: #fff;
    padding: 5px 40px 0 40px;
    margin-top: 20px;
    .title {
      color: #fd5c5e;
      font-size: 16px;
    }
    .returns {
      font-size: 14px;
      float: left;
    }
  }
}
/deep/.el-table::before {
  height: 0;
}
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
</style>
