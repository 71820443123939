<template>
  <div class="centerHome">
    <!-- <el-button @click="addTestData">sx</el-button> -->
    <!-- <el-button>1234</el-button>
    <el-button @click="removeTestData">xs</el-button> -->

    <!-- 地图 -->
    <Monitor
      ref="monitor"
      :requestDataManager="requestDataManager"
      :onlineSortiesData="onlineSortiesData"
      :onlineLayerData="onlineLayerData"
      :allDeviceLayerData="allDeviceLayerData"
      :netWorkIcon="netWorkIcon"
      :allRequsetLoading="allRequsetLoading"
      @setTimerRun="setTimerRun"
    />
    <div class="bottomWindow" v-show="!isHiddenAll">
      <bottom-bar
        :selected="isShowDio"
        @showDio="showDio"
        :datas="{
          networkingTotal: deviceTotal,
          onlineTotal,
          flyAreaTotal,
          taskTotal,
        }"
      />
    </div>
    <div class="rightWindow">
      <rightBar
        :isHiddenAll="isHiddenAll"
        @hiddenAll="hiddenAll"
        @showRightDioBtn="showRightDioBtn"
        @chat="chat"
        @switchMap="switchMap"
        ref="rightBar"
      />
    </div>
    <div
      class="leftWindow"
      v-if="showData && !isHiddenAll && !clickOutsideLeftWindow"
    >
      <!-- <div class="leftWindowData"></div> -->
      <div>
        <div class="header">
          <span v-show="isShowDio === 1" class="flyArea networkingDevices">
             <div class="networkingDeviceTitle">
              <div class="networkingName">处置案件数量</div>
            </div>
          </span>
          <span v-show="isShowDio === 2 || isShowDio === 3" class="networkingDevices">
            <div class="networkingDeviceTitle">
              <div class="networkingName">{{isShowDio === 2 ? '在线田长数量' : '田长总数量'}}</div>
            </div>
            <div style="height:450px;margin:10px 30px 10px 45px;overflow: auto;width:94%">
              <div v-for="(item,index) in isShowDio === 2 ? 10 : 50" style="height:40px;line-height:40px;">
                <div class="onlineListUser">
                  <div>
                    <i class="el-icon-s-custom" style="color:#fff"></i>
                    二级田长{{index}}
                  </div>
                  <div>
                    {{isShowDio === 2 ? '吴某某' : '张三'}}
                  </div>
                  <div>
                    180000000{{isShowDio === 2 ? '1' : '2'}}
                  </div>
                  <div>
                    <i class="iconfont" style="">&#xe60f;</i>
                    视频
                  </div>
                  <div>

                     <i class="iconfont" style="">&#xe610;</i>
                    短信
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span v-show="isShowDio === 4" class="flyArea networkingDevices">
            <div class="networkingDeviceTitle">
              <div class="networkingName">接收案件数量</div>
            </div>
          </span>
        </div>
        <div class="body">
          <div style="height: 33px"></div>
          <dioContent
            v-show="(isSelectBtn === 1 && showRingChart) || isShowDio === 2"
            :datas="dioContentData"
          />
          <comBar
            v-if="isSelectBtn === 1 && showBarChart"
            :options="comBarData"
            @rangeChange="onRangeChange"
            element-loading-background="rgba(0, 0, 0, 0)"
          />
        </div>
        <div class="footer"></div>
      </div>
      <!-- <div v-show="isShowDio === 3" class="onlineDevices">
        <div class="onlineDeviceTitle">在线设备</div>
        <div v-for="(val, index) in onlineDatas" :key="index">
          <div class="onlineDataTitle">
            <div class="onlineDataTitleText">
              <span class="onlineDeviceTotal">{{ val.total }}&nbsp</span
              ><span> {{ val.name }}</span>
            </div>
            <div
              class="onlineDataTitleImg"
              @click="onlineShowMore(index)"
              v-if="val.length > 6"
            >
              <img
                src="@/assets/img/centerHome/caretdown.png"
                :style="{ transform: val.isShowMore ? 'rotate(180deg)' : '' }"
                alt=""
              />
            </div>
          </div>
          <div
            class="onlineTypeDatas"
            :style="{ height: val.isShowMore ? 'auto' : '78px' }"
          >
            <div
              v-for="(dataVal, dataKey, dataIndex) in val.data"
              :key="dataKey"
              class="onlineTypeData"
            >
              <div
                class="onlineDataColor"
                :style="{ background: color[dataIndex] }"
              ></div>
              <div class="name" :title="dataKey">{{ dataKey }}</div>
              <div class="data" :title="dataVal">{{ dataVal }}</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="tasksAndSorties" v-show="isShowtTasksAndSorties">
      <com-bj-dio
        ref="ComBjDio"
        :isTasks="isTasks"
        :requestDataManager="requestDataManager"
        :onlineSortiesData="onlineSortiesData"
        :deviceData="categoryDevice"
        :isShowtTasksAndSorties="isShowtTasksAndSorties"
        @toMore="toMultiViews"
      />
    </div>
    <MultiMonitors
      v-if="showMultiViews"
      :show="showMultiViews"
      :onlineSortiesData="onlineSortiesData"
      :onlineLayerData="onlineLayerData"
      @close="showMultiViews = false"
    ></MultiMonitors>


  </div>
</template>
<script>
import Monitor from "@/components/monitorTest";
import bottomBar from "@/components/centerHome/bottomBar";
import rightBar from "@/components/centerHome/rightBar";
import dioContent from "@/components/centerHome/dioContent";
import comBar from "@/components/centerHome/comBar";
import ComBjDio from "@/components/centerHome/comBjDio";
import MultiMonitors from "@/components/centerHome/multiMonitors";
import { mapState, mapMutations } from "vuex";
import RequestData from "./requestDevicesData.js";
export default {
  components: {
    Monitor,
    bottomBar,
    rightBar,
    dioContent,
    comBar,
    ComBjDio,
    MultiMonitors,
  },
  data() {
    return {
      activeName: '1',
      monitorType: "list", //监控类型
      monitorKey: new Date().toString(),
      showData: false,
      isSelectBtn: 1,
      isShowDio: -1, //要显示那个类型的数据弹窗，飞行面积：1，。。。。
      showRingChart: false,
      showBarChart: false,
      isHiddenAll: false,
      isTasks: true, //是点击任务弹窗
      isShowtTasksAndSorties: false, //架次和任务弹窗显示
      // 左侧数据
      clickOutsideLeftWindow: false,
      comBarData: {}, // 条形图数据
      dioContentData: {}, // 饼状图及列表数据
      flyArea: {
        statsTexts: {
          sup: "",
          title: "总飞行面积",
          data: 0,
          unit: "平方公里",
        },
        data: {},
        time: "",
        diagram: { data: [], date: [] },
      }, // 飞行面积数据
      taskData: {
        statsTexts: null,
        data: {},
        diagram: {},
      }, // 任务数量
      taskTotal: 0,
      isLoadingTask: false,
      connectedDevice: [
        {
          statsTexts: {
            sup: "联网",
            title: "无人机",
            data: 0,
            unit: "架",
          },
          data: {},
        },
        {
          statsTexts: {
            sup: "联网",
            title: "测绘装备",
            data: 0,
            unit: "个",
          },
          data: {},
        },
        {
          statsTexts: {
            sup: "联网",
            title: "终端设备",
            data: 0,
            unit: "个",
          },
          data: {},
        },
      ], // 联网设备数据
      connectedDeviceIndex: 1,
      onlineTotal: 0,
      onlineDatas: [
        {
          name: "无人机",
          total: 0,
          data: {},
        },
        {
          name: "测绘装备",
          total: 0,
          data: {},
        },
        {
          name: "终端设备",
          total: 0,
          data: {},
        },
      ], // 在线设备数据
      color: [
        "#BE20F4",
        "#82FF25",
        "#CD2727",
        "#24C7D3",
        "#FF6500",
        "#007BFF",
        "#F9CB1F",
        "#6618FF",
        "#4B00FF",
        "#BE20F4",
        "#82FF25",
        "#CD2727",
        "#24C7D3",
        "#FF6500",
        "#007BFF",
        "#F9CB1F",
        "#6618FF",
        "#4B00FF",
      ],
      requestDataManager: {},
      onlineSortiesData: {}, //架次在线数据
      deviceData: {}, //设备数据
      onlineTimer: null, //在线设备定时器
      onlineLayerData: {}, //在线设备图层
      showMultiViews: false, // 多屏查看
      allDeviceLayerData: {}, //设备图层
      categoryDevice: [], //设备的分别数量
      newOnlineData: [], //最新的在线数据
      oldOnlineData: "", //最新的在线数据
      netWorkIcon: {},
      allOnlineData: [], //全部在线飞机数据
      allOldOnlineData: [], //旧的全部在线飞机数据
      testDeviceName: "",
      allRequsetLoading: true,
      userList: [{}]
    };
  },
  computed: {
    ...mapState(["user", "token", "chatDialog", "msgCenter"]),
    /**
     * ---- start ----
     * 左侧数据统计视图
     */
    deviceTotal() {
      let total = 0;
      this.connectedDevice.forEach((deviceList) => {
        total += deviceList.statsTexts.data;
      });
      return total;
    },
    flyAreaTotal() {
      return this.flyArea.statsTexts ? this.flyArea.statsTexts.data : 0;
    },
    /**
     * 左侧数据统计视图
     * ---- end ----
     */
  },
  mounted() {
    this.init();
    // this.$refs.monitor.init();
    document.addEventListener("click", this.clickOutside);
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "navMessageStorage"]),
    // 初始化
    init() {
      this.$apis
        .deviceCategoryBrand({ type: "list" }, this.token)
        .then((res) => {
          res.data.data.forEach((item) => {
            this.netWorkIcon[item.brand_name] = item;
          });
        });

      this.navMessageStorage(-1);
      this.currentUrlStorage(["首页", "首页", "首页"]);
      this.initGetAreaAndFlyQty();
      // 左侧统计--面积
      // this.getFlyAreaData();
      // this.getFlyQtyData();
      this.requestDataManager = new RequestData();
      this.requestDataManager.getDeviceCategoryBrand().then((data) => {
        this.getOnlineData().then((data) => {
          this.onlineSortiesData = data;

          setTimeout(() => {
            this.$refs.monitor.drawOnlineDevice();
            this.$refs.ComBjDio.getSortiesHistoryData();
            this.allRequsetLoading = false
          }, 1000);
        });
        this.getOnlineData("", "deviceData")
          .then((data) => {
            this.deviceData = data;


            this.getConnectedDevice();
          })
          .catch((err) => {
            console.log(err);
          });
        this.setTimerRun(true)
      });
    },
    setTimeGetOnlineDevice() {
      // this.onlineTimer = setInterval(() => {
        return new Promise((resolve,reject)=>{
            this.getOnlineData(1, "test").then((data) => {
            // 有新飞机上下线时的更新
            if (JSON.stringify(data) !== JSON.stringify(this.onlineSortiesData)) {
              this.updateOnlineDevice(data);

              this.onlineSortiesData = data;
              // this.$refs.monitor.removeOnlineDevice();
              this.oldOnlineData = JSON.parse(
                JSON.stringify(this.onlineLayerData)
              );
              this.allOldOnlineData = JSON.parse(
                JSON.stringify(this.allOnlineData)
              );
            }
            if (
              JSON.stringify(this.onlineLayerData) !==
              JSON.stringify(this.oldOnlineData)
            ) {
              // this.$refs.monitor.removeOnlineDevice();
              this.changeOnlineLocation();
              this.oldOnlineData = JSON.parse(
                JSON.stringify(this.onlineLayerData)
              );
            }
            resolve(data)
          });
        })

      // }, 3000);
    },
    // 设备改变位置
    changeOnlineLocation() {
      // console.log(this.oldOnlineData);
      this.allOnlineData.forEach((item) => {
        let index = -1;
        if (item._source.brand_name !== "彩虹") {
          index = this.oldOnlineData[item._source.category_name][
            item._source.brand_name
          ].findIndex((data) => {
            return data._source.mission_id === item._source.mission_id;
          });
          if (index !== -1) {
            if (
              JSON.stringify(item._source.last_point) !==
              JSON.stringify(
                this.oldOnlineData[item._source.category_name][
                  item._source.brand_name
                ][index]._source.last_point
              )
            ) {
              this.$refs.monitor.setNewItem(
                this.onlineLayerData[item._source.category_name][
                  item._source.brand_name
                ][index],
                "change"
              );
            }
          }
        } else {
          let key = "广西";
          let oldDeviceData = "";
          let newDeviceData = "";
          if (item._source.register_province.indexOf("广西") !== -1) {
            index = this.oldOnlineData["彩虹无人机"]["广西"].findIndex(
              (data) => {
                return data._source.mission_id === item._source.mission_id;
              }
            );
            oldDeviceData = this.oldOnlineData["彩虹无人机"]["广西"];
            newDeviceData = this.onlineLayerData["彩虹无人机"]["广西"];
          } else {
            key = "其他";
            index = this.oldOnlineData["彩虹无人机"]["其他"][
              item._source.register_province
            ].findIndex((data) => {
              return data._source.mission_id === item._source.mission_id;
            });
            oldDeviceData =
              this.oldOnlineData["彩虹无人机"]["其他"][
                item._source.register_province
              ];
            newDeviceData =
              this.onlineLayerData["彩虹无人机"]["其他"][
                item._source.register_province
              ];
          }
          if (index !== -1) {
            if (
              JSON.stringify(item._source.last_point) !==
              JSON.stringify(oldDeviceData[index]._source.last_point)
            ) {
              this.$refs.monitor.setNewItem(newDeviceData[index], "change");
            }
          }
        }
      });
    },
    /**
     * 设置定时器是否要执行
     */
    setTimerRun(isSet) {
      if (isSet) {
        this.onlineTimer = setInterval(() => {
         this.setTimeGetOnlineDevice();
        },3000)
      } else {
        clearInterval(this.onlineTimer);
      }
    },
    // 设备下线时的处理
    updateOnlineDevice(data) {
      // this.allOnlineData //现在全部的数据
      // this.onlineLayerData //全部解析后的数据
      // this.oldOnlineData //旧的数据
      for (let key in data["彩虹无人机"]) {
        if (
          data["彩虹无人机"][key] !== this.onlineSortiesData["彩虹无人机"][key]
        ) {
          // 更新彩虹无人机
          if (key.indexOf("广西") !== -1) {
            this.$refs.monitor.mapUpdateOnlineDevice(
              "彩虹无人机",
              key,
              this.onlineLayerData["彩虹无人机"][key]
            );
          } else {
            this.$refs.monitor.mapUpdateOnlineDevice(
              "彩虹无人机",
              key,
              this.onlineLayerData["彩虹无人机"]["其他"][key]
            );
          }
        }
      }
      for (let key1 in data["其他"]) {
        for (let key2 in data["其他"][key1].brand) {
          if (
            data["其他"][key1].brand[key2] !==
            this.onlineSortiesData["其他"][key1].brand[key2]
          ) {
            let time = 0;
            if (key2 === "乡村绘") {
              time = 10000;
            } else {
              time = 0;
            }
            setTimeout(() => {
              this.$refs.monitor.mapUpdateOnlineDevice(
                key1,
                key2,
                this.onlineLayerData[key1][key2]
              );
            }, time);
            // console.log("更新其他设备",key1,key2,this.onlineLayerData[key1][key2]);
          }
        }
      }
    },
    // 获取在线架次数据
    getOnlineData(index = 1, name = "onlineSortiesData") {
      return new Promise((resolve, reject) => {
        this.requestDataManager.getDeviceViewIndex(index).then((res) => {
          let data = res.data;
          if (index === 1) {
            this.onlineLayerData = data;
            if (this.oldOnlineData === "") {
              this.oldOnlineData = data;
              this.allOldOnlineData = res.allOnlineData;
            }
          } else {
            // this.allDeviceLayerData = data;
            this.$refs.monitor.drawDeviceMarker(data);
            this.categoryDevice = [
              this.computeTotal(data[0]),
              this.computeTotal(data[1]),
              this.computeTotal(data[2]),
            ];
            data = data[3];
          }
          let listData = {
            彩虹无人机: { 广西: 0 },
            其他: {
              无人机: { brand: {}, count: 0 },
              测绘装备: { brand: {}, count: 0 },
              终端设备: { brand: {}, count: 0 },
            },
          };
          listData["彩虹无人机"]["广西"] = data["彩虹无人机"]["广西"].length;
          for (let key in data["彩虹无人机"]["其他"]) {
            listData["彩虹无人机"][key] =
              data["彩虹无人机"]["其他"][key].length;
          }
          for (let key in data) {
            if (key.indexOf("彩虹") === -1) {
              let total = 0;
              for (let key2 in data[key]) {
                total = total + data[key][key2].length;
                listData["其他"][key]["brand"][key2] = data[key][key2].length;
              }
              listData["其他"][key]["count"] = total;
            }
          }

          this[name] = listData;
          this.getOnlineDataStats();
          // this.selectOnlineTask(1);
          // this.sortiesData = listData;
          this.allOnlineData = res.allOnlineData;

          resolve(listData);
        }).catch(err=>{
          this.allRequsetLoading = false
        });
      });
    },
    // 计算设备数量，上次位置，当前位置
    computeTotal(data) {
      let listData = {
        彩虹无人机: { 广西: 0 },
        其他: {
          无人机: { brand: {}, count: 0 },
          测绘装备: { brand: {}, count: 0 },
          终端设备: { brand: {}, count: 0 },
        },
      };
      listData["彩虹无人机"]["广西"] = data["彩虹无人机"]["广西"].length;
      for (let key in data["彩虹无人机"]["其他"]) {
        listData["彩虹无人机"][key] = data["彩虹无人机"]["其他"][key].length;
      }
      for (let key in data) {
        if (key.indexOf("彩虹") === -1) {
          let total = 0;
          for (let key2 in data[key]) {
            total = total + data[key][key2].length;
            listData["其他"][key]["brand"][key2] = data[key][key2].length;
          }
          listData["其他"][key]["count"] = total;
        }
      }
      return listData;
    },
    // 更新监控数
    updateMonitor() {
      this.terminalOnlineCount = this.$refs.monitor.terminalOnlineCount;
    },
    // 选中按钮
    selectBtn(index) {
      this.isSelectBtn = index;
    },
    // 显示哪一个数据的弹窗
    showDio(index) {
      if (this.isShowDio === index) {
        this.showData = false;
        this.isShowDio = -1;
      } else {
        this.isShowDio = index;
        this.showData = true;
      }
      console.log(index);
      this.showRingChart = false;
      this.showBarChart = false;
      switch (index) {
        case 1:
          this.showRingChart = true;
          this.showBarChart = true;
          this.dioContentData = JSON.parse(JSON.stringify(this.flyArea));
          this.comBarData = {
            unit: "累计",
            diagram: this.flyArea.diagram,
            time: this.flyArea.time,
          };
          break;
        case 2:
          this.showRingChart = true;
          this.dioContentData = JSON.parse(
            JSON.stringify(this.connectedDevice[this.connectedDeviceIndex - 1])
          );
          break;
        case 4:
          this.showRingChart = true;
          this.showBarChart = true;
          this.dioContentData = JSON.parse(JSON.stringify(this.taskData));
          this.comBarData = {
            unit: "个",
            diagram: {},
            time: this.taskData.time,
          };
          break;
      }
    },
    // 隐藏所有
    hiddenAll() {
      this.isHiddenAll = !this.isHiddenAll;
    },
    // 右侧点击了任务或设备控件
    showRightDioBtn(isTasks, isShowtTasksAndSorties, isHideSort = true) {
      this.isTasks = isTasks;
      this.isShowtTasksAndSorties = isShowtTasksAndSorties;
      if (isHideSort) {
        setTimeout(() => {
          this.$refs.ComBjDio.selectOnlineTask();
        }, 500); //这里直接调用会获取到错误的this.isTasks值
      }
    },
    // 音视频通话
    chat() {
      if (this.chatDialog.show) {
        this.$confirm(
          "您当前已打开了一个聊天窗口，是否要关闭当前的聊天窗口？",
          "提示"
        ).then(() => {
          this.chatDialog.window = "home";
          this.chatDialog.project.id = "";
          this.chatDialog.project.name = "";
          this.chatDialog.task.id = "";
          this.chatDialog.key = new Date().getTime();
          this.chatDialog.show = false;
        });
      } else {
        this.chatDialog.window = "home";
        this.chatDialog.project.id = "";
        this.chatDialog.project.name = "";
        this.chatDialog.task.id = "";
        this.chatDialog.key = new Date().getTime();
        this.chatDialog.show = true;
      }
    },
    switchMap(index) {
      //
      this.$refs.monitor.switchMap(index);
    },
    /**
     * ----start----
     * 左侧统计区域逻辑
     */
    initGetAreaAndFlyQty(range) {
      const timeArr = range ? range : this.getInitTime();
      this.taskData = {
        statsTexts: {
          title: "总任务数量",
          data: 0,
          unit: "架",
        },
        time: timeArr,
        data: {},
        diagram: { data: {}, date: [] },
      };
      this.flyArea.time = timeArr;
      this.isLoadingTask = true;
      this.getMissionStatData("all").then((res) => {
        for (let key in res.data) {
          this.taskData.statsTexts.data += res.data[key].sorties_count;
          this.taskData.data[key] = res.data[key].sorties_count;

          this.flyArea.statsTexts.data += res.data[key].area;
          this.flyArea.data[key] = res.data[key].area.toFixed(0);
        }
        this.taskData.data = this.sortObject(this.taskData.data);

        this.taskTotal = this.taskData.statsTexts.data;
        this.flyArea.statsTexts.data = this.flyArea.statsTexts.data.toFixed(0);
        this.taskData.data = this.sortObject(this.taskData.data);
      });
      let endDate = timeArr[1].split("-");
      let lastDate = this.getLastDay(endDate[0], endDate[1]);
      timeArr[1] = endDate[0] + "-" + endDate[1] + "-" + lastDate;
      // 架次统计图表
      this.getMissionStatData("date", {
        calendar_interval: "1M",
        created_at_from: timeArr[0],
        created_at_to: timeArr[1],
      }).then((res) => {
        this.taskData.unit = "架";
        for (let key in res.data) {
          this.taskData.diagram.date.push(key);
          this.taskData.diagram.data[key] = res.data[key].sorties_count;
          this.flyArea.diagram.date.push(key);
          this.flyArea.diagram.data.push(res.data[key].area.toFixed(0));
        }
        this.$set(
          this.taskData.diagram,
          "dateList",
          this.taskData.diagram.date
        );
        this.$set(this.flyArea.diagram, "dateList", this.taskData.diagram.date);
        this.taskData.diagram.date = JSON.stringify(this.taskData.diagram.date);
        this.flyArea.diagram.date = JSON.stringify(this.flyArea.diagram.date);

        this.isLoadingTask = false;
      });
    },
    // 飞行面积
    getFlyAreaData(range) {
      const timeArr = range;
      this.flyArea.data = {};
      this.flyArea.diagram = { data: [], date: [] };
      this.flyArea.time = timeArr;
      this.isLoadingTask = true;
      // 架次统计图表
      this.getMissionStatData("date", {
        calendar_interval: "1M",
        created_at_from: timeArr[0],
        created_at_to: timeArr[1],
      }).then((res) => {
        for (let key in res.data) {
          this.flyArea.diagram.date.push(key);
          this.flyArea.diagram.data.push(res.data[key].area.toFixed(0));
        }
        this.$set(this.flyArea.diagram, "dateList", this.taskData.diagram.date);
        this.flyArea.diagram.date = JSON.stringify(this.flyArea.diagram.date);
        this.isLoadingTask = false;
        this.comBarData = {
          unit: "平方公里",
          diagram: this.flyArea.diagram,
          time: this.flyArea.time,
        };
      });
    },
    // 任务数量统计页
    getFlyQtyData(range) {
      const timeArr = range ? range : this.getInitTime();
      this.taskData = {
        statsTexts: {
          title: "总任务数量",
          data: 0,
          unit: "架",
        },
        time: timeArr,
        data: {},
        diagram: { data: {}, date: [] },
      };
      this.isLoadingTask = true;
      // 架次统计图表
      this.getMissionStatData("date", {
        calendar_interval: "1M",
        created_at_from: timeArr[0],
        created_at_to: timeArr[1],
      }).then((res) => {
        this.taskData.unit = "架";
        for (let key in res.data) {
          this.taskData.diagram.date.push(key);
          this.taskData.diagram.data[key] = res.data[key].sorties_count;
        }
        this.$set(
          this.taskData.diagram,
          "dateList",
          this.taskData.diagram.date
        );
        this.taskData.diagram.date = JSON.stringify(this.taskData.diagram.date);
        this.isLoadingTask = false;
        if (range) {
          this.comBarData = {
            unit: "架",
            diagram: this.taskData.diagram,
            time: this.taskData.time,
          };
        }
      });
    },
    // 根据属性值排序属性
    sortObject(obj) {
      // 获取属性值并按从大到小排序
      let result = Object.values(obj).sort((a, b) => {
        return b - a;
      });
      let new_obj = {};
      for (let i = 0; i < result.length; i++) {
        // 将原对象中的键值对按照属性值的排序顺序写入新对象
        let data = Object.keys(obj).map((item, index) => {
          if (obj[item] === result[i]) {
            new_obj[item] = result[i];
          }
        });
      }
      return new_obj;
    },
    getMissionStatData(requestType, data = {}) {
      return new Promise((resolve, reject) => {
        this.$apis
          .getMissionStatData({ type: requestType, ...data }, this.token)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 架次与面积统计接口封装
    getFlyData(range, data) {
      return new Promise((resolve, reject) => {
        // 日期时间段处理
        const timeArr = range ? range : this.getInitTime();
        this.$apis
          .reportGetFlyData(
            {
              start_date: timeArr[0],
              end_date: timeArr[1],
              ...data,
            },
            this.token
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 在线设备展开
    onlineShowMore(index) {
      this.onlineDatas[index].isShowMore = !this.onlineDatas[index].isShowMore;
    },
    // 获取联网设备数据
    getConnectedDevice() {

      const datas = this.getCountsFromSortieData(this.deviceData);
      let connectedDevice = [
        {
          statsTexts: {
            sup: "联网",
            title: "无人机",
            data: datas[0].total,
            unit: "架",
          },
          data: datas[0].data,
        },
        {
          statsTexts: {
            sup: "联网",
            title: "测绘装备",
            data: datas[1].total,
            unit: "个",
          },
          data: datas[1].data,
        },
        {
          statsTexts: {
            sup: "联网",
            title: "终端设备",
            data: datas[2].total,
            unit: "个",
          },
          data: datas[2].data,
        },
      ];

      this.connectedDevice = connectedDevice;
    },
    /**
     * 数据统计视图的索引
     */
    getCategoryIndex(category) {
      let index = 0;
      switch (category) {
        case "无人机":
          index = 0;
          break;
        case "测绘装备":
          index = 1;
          break;
        case "终端设备":
          index = 2;
          break;
        default:
          break;
      }
      return index;
    },
    /**
     * 对联网设备或在线设备进行统计
     * @params {Object} deviceData: 设备数据
     * 数据类型如下
     * {
     *   "彩虹无人机": {"广西":0},
     *   "其他":{
     *      "无人机":{"brand":{"飞马":0,"大疆":0,"慧视":0,"华测":0,"思洛普":0},"count":0},
     *      "测绘装备":{"brand":{"COMS":0},"count":0},
     *      "终端设备":{"brand":{"飞享终端":0},"count":0}
     *    }
     * }
     * @returns {Array}
     * [
     *  { total: 0, data: {},}, //无人机数据
     *  { total: 0, data: {},}, //测绘装备
     *  { total: 0, data: {},}, //终端设备
     * ]
     */
    getCountsFromSortieData(deviceData) {
      let deviceList = [
        { total: 0, data: {} },
        { total: 0, data: {} },
        { total: 0, data: {} },
      ];
      for (let key in deviceData) {
        if (deviceData[key] == null) {
          continue;
        }
        for (let category in deviceData[key]) {
          const categoryIndex = this.getCategoryIndex(category);
          const categoryData = deviceData[key][category];
          if (key === "彩虹无人机") {
            // 计入彩虹总数
            if (deviceList[0].data["彩虹无人机"] == null)
              deviceList[0].data["彩虹无人机"] = 0;
            deviceList[0].data["彩虹无人机"] += categoryData;
            // 计入无人机总数
            deviceList[0].total += categoryData;
            continue;
          }

          // 品牌列表
          if (
            categoryData === null ||
            JSON.stringify(categoryData) === "{}" ||
            categoryData.brand == null
          ) {
            continue;
          }

          const brandData = categoryData.brand;
          for (let brand in brandData) {
            if (deviceList[categoryIndex].data[brand] == null)
              deviceList[categoryIndex].data[brand] = 0;
            deviceList[categoryIndex].data[brand] += brandData[brand];
          }
          deviceList[categoryIndex].data = this.sortObject(
            deviceList[categoryIndex].data
          );
          // 计入总数
          deviceList[categoryIndex].total += categoryData.count;
        }
      }
      return deviceList;
    },
    // 更改联网设备显示索引
    selectDeviceType(index) {
      this.connectedDeviceIndex = index;
      this.dioContentData = JSON.parse(
        JSON.stringify(this.connectedDevice[index - 1])
      );
    },
    // 获取在线设备
    getOnlineDataStats() {
      const datas = this.getCountsFromSortieData(this.onlineSortiesData);
      this.onlineDatas = [
        {
          name: "无人机",
          total: datas[0].total,
          isShowMore: false,
          data: datas[0].data,
        },
        {
          name: "测绘装备",
          total: datas[1].total,
          isShowMore: false,
          data: datas[1].data,
        },
        {
          name: "终端设备",
          total: datas[2].total,
          isShowMore: false,
          data: datas[2].data,
        },
      ];
      let total = 0;
      this.onlineDatas.forEach((data) => {
        total += data.total;
      });
      this.onlineTotal = total;
    },
    // 获取初始统计时间段
    getInitTime() {
      const now = new Date();
      const nowMonth = now.getMonth() + 1;
      const nowYear = now.getFullYear();

      const startMonthDate = new Date(nowYear, nowMonth - 6, 1);
      const endMonthDate = new Date(nowYear, nowMonth, 0);
      let startMonth = startMonthDate.getMonth() + 1;
      let endMonth = endMonthDate.getMonth() + 1;

      if (startMonth < 10) {
        startMonth = "0" + startMonth;
      }
      if (endMonth < 10) {
        endMonth = "0" + endMonth;
      }
      const formatStart = `${startMonthDate.getFullYear()}-${startMonth}-01`;
      const formatEnd = `${endMonthDate.getFullYear()}-${endMonth}-01`;
      return [formatStart, formatEnd];
    },
    getLastDay(year, month) {
      let new_year = year; //取当前的年份
      let new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate(); //获取当月最后一天日期
    },
    // 统计时间段切换
    onRangeChange(dateArr) {
      let endDate = dateArr[1].split("-");
      let lastDate = this.getLastDay(endDate[0], endDate[1]);
      dateArr[1] = endDate[0] + "-" + endDate[1] + "-" + lastDate;
      switch (this.isShowDio) {
        case 1:
          // 修改飞行面积时间
          this.getFlyAreaData(dateArr);
          break;
        case 4:
          // 修改任务数量
          this.getFlyQtyData(dateArr);
          break;
        default:
          break;
      }
    },
    clickOutside(e) {
      this.clickOutsideLeftWindow = this.clickOutsideWithSelector(
        e,
        ".leftWindow",
        ".bottomWindow"
      );
      if (this.clickOutsideLeftWindow) {
        this.isShowDio = -1;
      }
    },
    // 点击外部关闭左侧弹窗
    clickOutsideWithSelector(e, selector, avoidSelector) {
      const elem = document.querySelector(selector);
      const avoidElement = document.querySelector(avoidSelector);
      if (e.target != null && avoidElement.contains(e.target)) {
        return false;
      }
      if (this.isShowDio !== -1 && this.showData) {
        if (e.target != null && elem.contains(e.target)) {
          return false;
        } else {
          this.showData = false;
          return true;
        }
      }
    },
    /**
     * 左侧统计区域逻辑
     * ----end----
     */
    // 多屏查看
    toMultiViews() {
      this.showMultiViews = !this.showMultiViews;
      this.isShowtTasksAndSorties = false;
      this.$refs.rightBar.clickTasks();
    },
  },
  beforeDestroy() {
    clearInterval(this.onlineTimer);
    this.onlineTimer = null;

    document.removeEventListener("click", this.clickOutside);
  },
};
</script>
<style lang="scss">

.centerHome {
  height: 100%;
  position: relative;
  min-width: 1280px;
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #3f597e;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: transparent;
  }
  * {
    scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  }
  .bottomWindow {
    position: absolute;
    top: 5%;
    left: 30px;
    height: 85%;

    // display: flex;
    // justify-content: center;
  }
  .rightWindow {
    height: calc(100% - 20px);
    position: absolute;
    right: 23px;
    top: 0;
    width: 56px;
  }
  .leftWindow {
    position: absolute;
    top: 13%;
    left: 159px;
    .onlineDevices {
      position: absolute;
      top: 50px;
      left: 45px;
      height: 500px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .onlineDeviceTitle {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .onlineDataTitle {
        width: 306px;
        height: 33px;
        margin-top: 32px;
        margin-bottom: 15px;
        background: url("~@/assets/img/centerHome/title_bj.png") no-repeat
          center;
        background-size: 100% 100%;
        position: relative;
        .onlineDataTitleText {
          position: absolute;
          left: 25px;
          top: -14px;
          font-size: 14px;
          color: #ffffff;
          .onlineDeviceTotal {
            font-family: "DIN";
            font-weight: bold;
            color: #ffd65c;
            font-size: 26px;
          }
        }
        .onlineDataTitleImg {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      }
      .onlineTypeDatas {
        width: 336px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
        height: 80px;
      }
      .onlineTypeData {
        display: flex;
        margin-right: 30px;
        line-height: 26px;
        align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap-reverse;
        .onlineDataColor {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
        .name {
          font-size: 14px;
          color: #7893b9;
          width: 75px;
          margin-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 26px;
        }
        .data {
          font-size: 14px;
          color: #fff;
          width: 32px;
        }
      }
    }
    .leftWindowData {
      position: relative;
      top: 33px;
      left: 47px;
    }
    .header {
      width: 460px;
      height: 145px;
      background: url("~@/assets/img/centerHome/frametop.png") no-repeat center;
      background-size: 100% 100%;

      // border-bottom: 1px solid #69789E;
      .deviceButton {
        width: 96px;
        height: 32px;
        background: rgba(105, 120, 158, 0.3);
        border-radius: 16px;
        border: 1px solid #69789e;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          background: rgba(7, 155, 255, 0.3);
          border: 1px solid #079bff;
        }
      }
      .flyArea {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 45px;
      }
      .networkingDevices {
        position: absolute;
        top: 73px;
        .onlineListUser {
          display: flex;
          justify-content: space-around;
          color: #fff;
        }
      }
      .networkingDeviceTitle {
        width: 326px;
        height: 33px;
        background: url("~@/assets/img/centerHome/title_bj.png") no-repeat
          center;
        background-size: 100% 100%;

        margin-left: 47px;
        position: relative;
        .networkingName {
          position: absolute;
          top: -10px;
          left: 10px;

          font-weight: bold;
          color: #ffffff;
          font-size: 18px;
        }
      }
      .networkingDeviceBtn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 23px 13px 23px 39px;
        color: #fff;
      }
      .button {
        width: 133px;
        height: 44px;
        background: rgba(105, 120, 158, 0.3);
        border: 1px solid #69789e;
        color: #fff;
        text-align: center;
        line-height: 44px;
        font-size: 14px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        &:hover {
          background: rgba(7, 155, 255, 0.3);
          border: 1px solid #079bff;
        }
      }
      .isActive {
        background: rgba(7, 155, 255, 0.3);
        border: 1px solid #079bff;
      }
    }
    .body {
      width: 460px;
      height: 315px;
      background: url("~@/assets/img/centerHome/framem.png") no-repeat center;
      background-size: 100% 100%;
      padding-left: 45px;
      /deep/ .el-loading-mask {
        background-color: rgba(0, 0, 0, 0);
        opacity: 0.5;
      }
    }
    .footer {
      width: 460px;
      height: 162px;
      background: url("~@/assets/img/centerHome/framedown.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .multiViews {
    position: absolute;
    top: 10%;
    left: 25%;
    background: #061220;
    z-index: 15;
  }
  .tasksAndSorties {
    position: absolute;
    top: 10%;
    right: 0;
  }
  @media screen and (max-height: 1000px) {
    .leftWindow {
      transform: scale(0.8);
    }
  }
}
</style>
