var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.earthLoading,
          expression: "earthLoading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _c("iframe", {
        ref: "iframe",
        staticStyle: { width: "100%", height: "calc(100vh - 70px)" },
        attrs: { src: "/result/index.html", frameborder: "0" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }