var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rightBar" }, [
    _c("div", { staticClass: "bodybar" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fmNav" },
        [
          _c(
            "el-button",
            {
              style: _vm.ImportSty,
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.Import((_vm.flag = 0))
                },
              },
            },
            [_vm._v("导入空域")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              style: _vm.drawSty,
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.draw((_vm.flag = 1))
                },
              },
            },
            [_vm._v("绘制空域")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.flag == 0
        ? _c(
            "div",
            { staticClass: "uploadBar" },
            [
              _c("p", { staticClass: "upWord" }, [
                _vm._v("请加载您计划的航飞空域"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.kmlInputEl.click()
                    },
                  },
                },
                [_vm._v("加载空域")]
              ),
              _vm._v(" "),
              _c("input", {
                ref: "kmlInputEl",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: ".kml, .KML" },
                on: { change: _vm.uploadKmlFile },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "careful" }, [_vm._v("*仅支持KML文件")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.flag == 1
        ? _c(
            "div",
            { staticClass: "drawair" },
            [
              _c("img", {
                attrs: { src: require("@/assets/img/绘制示意.png"), alt: "" },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "careful" }, [
                _vm._v(
                  "\n        请在地图中绘制您的计划航飞空域 （注：请至少选择四个点进行绘制）\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.empty },
                },
                [_vm._v("清除重绘")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "divider" }),
      _vm._v(" "),
      _c("div", { staticClass: "results" }, [
        _c("span", { staticClass: "resultsTitle" }, [_vm._v("查询结果:")]),
        _vm._v(" "),
        _c("div", { staticClass: "exhibition" }, [
          _c("span", { staticClass: "guide" }, [
            _vm._v("针对您提交的航飞空域，查询到以下有效信息"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "warzone" },
            [
              _c("span", { staticClass: "titleword" }, [
                _vm._v("任务审批归属战区："),
              ]),
              _vm._v(" "),
              _vm._l(_vm.warZone, function (item, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "flyInformation" },
                  [_vm._v(_vm._s(item))]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "partition" },
            [
              _c("span", { staticClass: "titleword" }, [
                _vm._v("任务审批归属分区："),
              ]),
              _vm._v(" "),
              _vm._l(_vm.partition, function (litem) {
                return _c(
                  "span",
                  { key: litem, staticClass: "flyInformation" },
                  [_vm._v("\n            " + _vm._s(litem) + "\n          ")]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "partition" }, [
            _vm.limitData.length > 0
              ? _c("span", { staticClass: "flyInformation" }, [
                  _vm._v("空域内包含限制区域。"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.limitData.length == 0
              ? _c("span", { staticClass: "flyInformation" }, [
                  _vm._v("空域内不包含限制区域。"),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }