// 统计相关接口
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  获取首页报表数据
export const homeData = (data, token) => fly.get(
  baseURL + '/report/getHomeReportData',
  data, deploy(30000, token)
)


// 统计分析
export const getTaskData = (data, token) => fly.post(
  baseURL + '/report/getTaskData',
  data, deploy(90000, token)
)











// 门户月推送任务统计
export const portalTaskPushReport = (data, token) => fly.post(
  baseURL + '/report/monthPush',
  data, deploy(30000, token)
)

// 门户报表预警发布条目
export const portalWarningReport = (data, token) => fly.post(
  baseURL + '/report/warnings',
  data, deploy(30000, token)
)

// 飞行架次统计
export const portalFlyData = (data, token) => fly.post(
  baseURL + '/report/flyData',
  data, deploy(30000, token)
)

// 用户统计
export const reportGetUserData = (data, token) => fly.post(
  baseURL + '/report/getUserData',
  data, deploy(30000, token)
)

// 飞行统计
export const reportGetFlyData = (data, token) => fly.post(
  baseURL + '/report/getFlyData',
  data, deploy(30000, token)
)

// 任务视图统计
export const getMissionStatData = (data, token) => fly.post(
  baseURL + '/report/getMissionStatData',
  data, deploy(30000, token)
)

