var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "chat" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "room-list" }, [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("el-input", {
                  staticClass: "search-input",
                  attrs: {
                    clearable: "",
                    placeholder: "搜索",
                    "suffix-icon": "el-icon-search",
                  },
                  on: { input: _vm.searchInput },
                  model: {
                    value: _vm.roomParam.keywords,
                    callback: function ($$v) {
                      _vm.$set(_vm.roomParam, "keywords", $$v)
                    },
                    expression: "roomParam.keywords",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.roomLoading
              ? _c("div", { staticClass: "hint" }, [_vm._m(0)])
              : _vm.roomList.length == 0
              ? _c("div", { staticClass: "hint" }, [
                  _c("div", [_vm._v("暂无消息")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { ref: "room", staticClass: "room" },
              _vm._l(_vm.roomList, function (room, index) {
                return _c(
                  "div",
                  {
                    staticClass: "room-box",
                    class: { "current-room": _vm.currentRoomId == room.id },
                    on: {
                      click: function ($event) {
                        return _vm.switchRoom(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "room-left" },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: room.unread_num > 0,
                              expression: "room.unread_num > 0",
                            },
                          ],
                          staticClass: "new-msg-icon",
                        }),
                        _vm._v(" "),
                        room.user_num == 2 && room.create_user_id != _vm.user.id
                          ? _c("chat-avatar", {
                              staticClass: "avatar",
                              attrs: { avatar: room.user[0].photo },
                            })
                          : room.user_num == 2 &&
                            room.create_user_id == _vm.user.id
                          ? _c("chat-avatar", {
                              staticClass: "avatar",
                              attrs: { avatar: room.user[1].photo },
                            })
                          : _c("chat-avatar", {
                              staticClass: "avatar",
                              attrs: { user: room.user },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-center" }, [
                      _c("div", { staticClass: "room-name" }, [
                        room.user_num == 2
                          ? _c("span", [
                              room.create_user_id != _vm.user.id
                                ? _c("span", [
                                    _vm._v(_vm._s(room.user[0].name)),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(room.user[1].name)),
                                  ]),
                            ])
                          : _c("span", [_vm._v(_vm._s(room.room_name))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "last-msg" }, [
                        _vm.currentRoomId != room.id && _vm.chatInput[index]
                          ? _c("span", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("[草稿]"),
                              ]),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.chatInput[index]) +
                                  "\n                "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("chatLastMsg")(room.record.data[0])
                                )
                              ),
                            ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-right" }, [
                      _c("div", { staticClass: "last-time" }, [
                        room.record.data.length != 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  room.record.data[0].created_at.substr(-8, 5)
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(room.created_at.substr(-8, 5))),
                            ]),
                      ]),
                      _vm._v(" "),
                      room.user_num > 2
                        ? _c("div", { staticClass: "room-user-num" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(room.user_num) +
                                "人\n              "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "option-btn" }, [
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.toAdd(2)
                  },
                },
              },
              [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
            ),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "2px",
                height: "40px",
                background: "#d8d8d8",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.toAdd(1)
                  },
                },
              },
              [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          _vm._l(_vm.roomList, function (room, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentRoomId == room.id,
                    expression: "currentRoomId == room.id",
                  },
                ],
                staticClass: "room-chat-box",
              },
              [
                _c(
                  "div",
                  { staticClass: "room-chat-header" },
                  [
                    room.user_num == 2 && room.create_user_id != _vm.user.id
                      ? _c("chat-avatar", {
                          staticClass: "avatar",
                          attrs: { avatar: room.user[0].photo },
                        })
                      : room.user_num == 2 && room.create_user_id == _vm.user.id
                      ? _c("chat-avatar", {
                          staticClass: "avatar",
                          attrs: { avatar: room.user[1].photo },
                        })
                      : _c("chat-avatar", {
                          staticClass: "avatar",
                          attrs: { user: room.user },
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "room-task-name" }, [
                      _vm._v(_vm._s(room.room_task_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "head-option" }, [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont show-option-box",
                          on: {
                            click: function ($event) {
                              _vm.optionBoxShow = true
                            },
                          },
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.optionBoxShow,
                              expression: "optionBoxShow",
                            },
                          ],
                          staticClass: "head-option-box",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "head-option-btn",
                              staticStyle: { "margin-bottom": "7px" },
                              on: {
                                click: function ($event) {
                                  return _vm.showUserList(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "iconfont" }, [
                                _vm._v(""),
                              ]),
                              _vm._v("\n                成员\n              "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "head-option-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.showFileList(index)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "iconfont" }, [
                                _vm._v(""),
                              ]),
                              _vm._v("\n                文件\n              "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: room.media_status == 1,
                        expression: "room.media_status == 1",
                      },
                    ],
                    staticClass: "room-is-media",
                  },
                  [
                    _vm._v("\n          正在进行音视频通讯，\n          "),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#07c160", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.enterMedia(index)
                          },
                        },
                      },
                      [_vm._v("点击进入")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { ref: "record", refInFor: true, staticClass: "chat-record" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingRecord,
                            expression: "loadingRecord",
                          },
                        ],
                        staticClass: "record-loading",
                      },
                      [_c("i", { staticClass: "el-icon-loading" })]
                    ),
                    _vm._v(" "),
                    _vm._l(
                      room.record.data.slice().reverse(),
                      function (msg, msgIndex) {
                        return _c(
                          "div",
                          {
                            ref: "recordBox",
                            refInFor: true,
                            staticClass: "record-box",
                          },
                          [
                            msg.type == 6
                              ? _c(
                                  "div",
                                  { staticClass: "record-box-center" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(msg.content) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : msg.user.user_id != _vm.user.id
                              ? _c("div", { staticClass: "record-box-left" }, [
                                  _c(
                                    "div",
                                    { staticClass: "content-msg" },
                                    [
                                      _c("chat-avatar", {
                                        staticClass: "avatar",
                                        attrs: { avatar: msg.user.photo },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "content-frame" },
                                        [
                                          _c("div", {
                                            staticClass: "frame-triangle",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "frame-rectangle" },
                                            [
                                              msg.type == 1
                                                ? _c("div", [
                                                    _vm._v(_vm._s(msg.content)),
                                                  ])
                                                : msg.type == 2
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-image",
                                                        {
                                                          ref:
                                                            "imageBox-" + index,
                                                          refInFor: true,
                                                          staticClass: "image",
                                                          attrs: {
                                                            lazy: "",
                                                            src: msg.file_path,
                                                            "preview-src-list":
                                                              [msg.file_path],
                                                          },
                                                          on: {
                                                            load: function (
                                                              $event
                                                            ) {
                                                              return _vm.imgLoadEvent(
                                                                index,
                                                                msgIndex
                                                              )
                                                            },
                                                            error: function (
                                                              $event
                                                            ) {
                                                              return _vm.imgLoadEvent(
                                                                index,
                                                                msgIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "image-slot",
                                                              attrs: {
                                                                slot: "placeholder",
                                                              },
                                                              slot: "placeholder",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          加载中\n                          "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "dot",
                                                                },
                                                                [_vm._v("...")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : msg.type == 3
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "video-box",
                                                    },
                                                    [
                                                      _c("video", {
                                                        staticStyle: {
                                                          width: "300px",
                                                        },
                                                        attrs: {
                                                          src: msg.file_path,
                                                          controls: "",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : msg.type == 4
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "location-box",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickMap(
                                                            msg.location
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-amap",
                                                        {
                                                          staticClass:
                                                            "amap-box",
                                                          attrs: {
                                                            vid:
                                                              "map_" +
                                                              index +
                                                              "_" +
                                                              msgIndex,
                                                            defaultCursor:
                                                              "pointer",
                                                            dragEnable: false,
                                                            doubleClickZoom: false,
                                                            keyboardEnable: false,
                                                            scrollWheel: false,
                                                            zoom: _vm.zoom,
                                                            center:
                                                              _vm._f(
                                                                "locationMsg"
                                                              )(msg),
                                                          },
                                                        },
                                                        [
                                                          _c("el-amap-marker", {
                                                            attrs: {
                                                              vid:
                                                                "marker-" +
                                                                msg.msg_id,
                                                              position:
                                                                _vm._f(
                                                                  "locationMsg"
                                                                )(msg),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : msg.type == 5
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "file-box",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            msg.file_path
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "file-box-left",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                JSON.parse(
                                                                  msg.file_msg
                                                                )["name"]
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fileSize"
                                                                  )(
                                                                    JSON.parse(
                                                                      msg.file_msg
                                                                    )["size"]
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-document",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "content-time" }, [
                                    _vm._v(_vm._s(msg.created_at)),
                                  ]),
                                ])
                              : _c("div", { staticClass: "record-box-right" }, [
                                  _c(
                                    "div",
                                    { staticClass: "content-msg" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "content-frame right" },
                                        [
                                          _c("div", {
                                            staticClass: "frame-triangle-right",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: msg.send_loading,
                                                    expression:
                                                      "msg.send_loading",
                                                  },
                                                ],
                                                staticClass: "el-icon-loading",
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: msg.send_err,
                                                    expression: "msg.send_err",
                                                  },
                                                ],
                                                staticClass:
                                                  "el-icon-warning-outline",
                                                staticStyle: { color: "red" },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "frame-rectangle right",
                                            },
                                            [
                                              msg.type == 1
                                                ? _c("div", [
                                                    _vm._v(_vm._s(msg.content)),
                                                  ])
                                                : msg.type == 2
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-image",
                                                        {
                                                          ref:
                                                            "imageBox-" + index,
                                                          refInFor: true,
                                                          staticClass: "image",
                                                          attrs: {
                                                            lazy: "",
                                                            src: msg.file_path,
                                                            "preview-src-list":
                                                              [msg.file_path],
                                                          },
                                                          on: {
                                                            load: function (
                                                              $event
                                                            ) {
                                                              return _vm.imgLoadEvent(
                                                                index,
                                                                msgIndex
                                                              )
                                                            },
                                                            error: function (
                                                              $event
                                                            ) {
                                                              return _vm.imgLoadEvent(
                                                                index,
                                                                msgIndex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "image-slot",
                                                              attrs: {
                                                                slot: "placeholder",
                                                              },
                                                              slot: "placeholder",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          加载中\n                          "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "dot",
                                                                },
                                                                [_vm._v("...")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : msg.type == 3
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "video-box",
                                                    },
                                                    [
                                                      _c("video", {
                                                        staticStyle: {
                                                          width: "300px",
                                                        },
                                                        attrs: {
                                                          src: msg.file_path,
                                                          controls: "",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : msg.type == 4
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "location-box",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickMap(
                                                            msg.location
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-amap",
                                                        {
                                                          staticClass:
                                                            "amap-box",
                                                          attrs: {
                                                            vid:
                                                              "map_" +
                                                              index +
                                                              "_" +
                                                              msgIndex,
                                                            defaultCursor:
                                                              "pointer",
                                                            dragEnable: false,
                                                            doubleClickZoom: false,
                                                            keyboardEnable: false,
                                                            scrollWheel: false,
                                                            zoom: _vm.zoom,
                                                            center:
                                                              _vm._f(
                                                                "locationMsg"
                                                              )(msg),
                                                          },
                                                        },
                                                        [
                                                          _c("el-amap-marker", {
                                                            attrs: {
                                                              vid:
                                                                "marker-" +
                                                                msg.msg_id,
                                                              position:
                                                                _vm._f(
                                                                  "locationMsg"
                                                                )(msg),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : msg.type == 5
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "file-box",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            msg.file_path
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "file-box-left",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                JSON.parse(
                                                                  msg.file_msg
                                                                )["name"]
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fileSize"
                                                                  )(
                                                                    JSON.parse(
                                                                      msg.file_msg
                                                                    )["size"]
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-document",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("chat-avatar", {
                                        staticClass: "avatar",
                                        attrs: { avatar: msg.user.photo },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "content-time right" },
                                    [_vm._v(_vm._s(msg.created_at))]
                                  ),
                                ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "chat-input-box" }, [
                  _c("div", { staticClass: "right-btn" }, [
                    _c("div", { staticClass: "upload" }, [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.fileInput[index].click()
                            },
                          },
                        },
                        [_vm._v("")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "fileInput",
                      refInFor: true,
                      staticStyle: { display: "none" },
                      attrs: { type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.uploadFile($event, index)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "video" }, [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.openMedia(index)
                            },
                          },
                        },
                        [_vm._v("")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.chatInput[index],
                        expression: "chatInput[index]",
                      },
                    ],
                    staticClass: "chat-input",
                    attrs: {
                      resize: "none",
                      placeholder: "请输入消息..",
                      cols: "30",
                      rows: "10",
                    },
                    domProps: { value: _vm.chatInput[index] },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.sendMsg(index)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.chatInput, index, $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.userListShow,
                        expression: "userListShow",
                      },
                    ],
                    staticClass: "user-list",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "add-member-box",
                        on: {
                          click: function ($event) {
                            return _vm.toAddUser(index)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                        _vm._v("\n            添加成员\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "user-list-box" },
                      _vm._l(room.user, function (user) {
                        return _c(
                          "div",
                          { staticClass: "user-box" },
                          [
                            _c("chat-avatar", {
                              staticClass: "avatar",
                              attrs: { avatar: user.photo },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "user-box-name" }, [
                              _vm._v(_vm._s(user.name)),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.fileListShow,
                        expression: "fileListShow",
                      },
                    ],
                    ref: "fileRecord",
                    refInFor: true,
                    staticClass: "file-list",
                  },
                  [
                    _vm._l(
                      _vm.roomList[index].file_record.data,
                      function (dateArr) {
                        return _c("div", { staticClass: "file-time-box" }, [
                          _c("div", { staticClass: "file-time" }, [
                            _vm._v(_vm._s(dateArr.created_at)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "file-column-list" },
                            _vm._l(dateArr.content, function (msg) {
                              return _c("div", { staticClass: "file-box" }, [
                                msg.type == 2
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "file-content",
                                            attrs: {
                                              fit: "cover",
                                              src: msg.file_path,
                                              "preview-src-list": [
                                                msg.file_path,
                                              ],
                                              lazy: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "placeholder" },
                                                slot: "placeholder",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      加载中\n                      "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "dot" },
                                                  [_vm._v("...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : msg.type == 3
                                  ? _c("div", [
                                      _c("video", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "120px",
                                        },
                                        attrs: {
                                          src: msg.file_path,
                                          controls: "",
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                        ])
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loadingFileRecord,
                            expression: "loadingFileRecord",
                          },
                        ],
                        staticClass: "record-loading",
                      },
                      [_c("i", { staticClass: "el-icon-loading" })]
                    ),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "chat-content-map-dialog",
          attrs: {
            modal: false,
            top: "20vh",
            "lock-scroll": false,
            visible: _vm.mapDialog.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.mapDialog, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", height: "500px" } },
            [
              _c(
                "el-amap",
                {
                  attrs: {
                    vid: "map-dialog",
                    zoom: _vm.zoom,
                    center: _vm.mapDialog.position,
                  },
                },
                [
                  _c("el-amap-marker", {
                    attrs: {
                      vid: "marker-dialog",
                      position: _vm.mapDialog.position,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", {
        staticClass: "el-icon-loading",
        staticStyle: { "margin-right": "5px" },
      }),
      _vm._v("加载中\n          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }