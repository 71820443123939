<template>
  <div class="pageBar">
    <div class="bodyBar">
      <div class="tabelNav" v-if="applyData">
        <p class="numbers">数据申请单号: {{ applyData.data_apply_no }}</p>
        <p class="time">{{ applyData.created_at }}</p>
        <div class="tabel">
          <el-table
            max-height="400"
            height="100%"
            :stripe="true"
            :data="tableData"
            style="width: 100%; margin-top: 1%"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              :index="indexMethod"
              width="40"
            />
            <el-table-column align="center" prop="task_id" label="脚印ID" />
            <el-table-column
              align="center"
              prop="plane_type"
              label="飞机型号"
            />
            <el-table-column
              align="center"
              prop="camera_type"
              label="载荷类型"
            />
            <el-table-column align="center" prop="work_time" label="飞行时间">
              <template slot-scope="scope">
                <span>{{ scope.row.work_time }}分钟</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="province" label="省" />
            <el-table-column align="center" prop="city" label="市" />
            <el-table-column align="center" prop="high_gsd" label="GSD">
              <template slot-scope="scope">
                <span>{{ scope.row.high_gsd }}cm</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="detailed">
          <p class="deline">
            <span class="title">数据申请类型</span>
            <span v-for="(item, index) in typeList" :key="index" class="type">
              {{ item }}
            </span>
          </p>
          <p class="deline">
            <span class="title">数据完整性</span>
            <span class="type">可接受只覆盖部分区域</span>
          </p>
          <p class="deline">
            <span class="title">成交开放期</span>
            <span class="type">14天</span>
          </p>
        </div>
      </div>
      <div v-if="applyData.status == 2" class="midBar">
        <p class="titword">针对您的需求，经确认:</p>
        <p class="line">
          1.共涉及{{ auditData.unit_qty_1 }}家持有单位，其中{{
            auditData.unit_qty_2
          }}家可提供
        </p>
        <p class="line">2.实际可提供面积{{ auditData.work_area }}km²</p>
        <p class="line">3.数据供给周期{{ auditData.period }}天</p>
        <p class="line">4.以上数据合计报价{{ auditData.total_price }}元</p>
        <span class="concat">如需确认，请联系管理员:</span>
        <span class="mobile">13657685783</span>
      </div>
      <div class="botBar">
        <el-button
          v-if="applyData.status != 2"
          class="close"
          type="primary"
          @click="closeBtn"
          >关闭</el-button
        >
        <el-button
          v-if="applyData.status == 2"
          class="close"
          @click="termination"
          plain
          >终止</el-button
        >
        <el-button
          v-if="applyData.status == 2"
          class="close"
          type="primary"
          @click="dealBtn"
          >成交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      id: "",
      applyData: {},
      tableData: [],
      typeList: [],
      auditData: [],
      status: 4,
    };
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.getdataList();
  },
  methods: {
    // 自定义序号
    indexMethod(index) {
      return index + 1;
    },
    // 详情数据
    getdataList() {
      this.$apis
        .getMyApplyDataDetail(
          {
            id: this.id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.applyData = data.data.data.apply_data;
            this.tableData = data.data.data.task_data;
            this.auditData = data.data.data.audit_data;
            this.typeList = JSON.parse(data.data.data.apply_data.category);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 成交
    dealBtn() {
      this.$confirm("确认继续此操作吗？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .updateApplyData(
              {
                id: this.id,
                status: this.status,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                window.history.go(-1);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    termination() {
      this.$confirm("确认继续此操作吗？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .updateApplyData(
              {
                id: this.id,
                status: 5,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                window.history.go(-1);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    closeBtn() {
      window.history.go(-1);
    },
  },
  watch: {
    $route() {
      this.id = this.$route.query.id;
      this.getdataList();
    },
  },
  computed: {
    ...mapState(["user", "token", "centerDialogVisible"]),
  },
};
</script>

<style lang="less" scoped>
.deline {
  display: flex;
  justify-content: flex-start;
}
.title {
  font-size: 12px;
  color: #8b8b8b;
  width: 80px;
}
.type {
  font-size: 12px;
  color: #000000;
  margin-right: 56px;
}
.pageBar {
  width: 100%;
  height: 100%;
}
.topBar {
  float: left;
}
.bodyBar {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #f0f0f0;
  padding: 20px 272px 0 272px;
  float: left;
  .tabelNav {
    width: 100%;
    height: calc(100vh - 430px);
    background-color: #fff;
    padding: 26px 40px 0 40px;
    .numbers {
      font-size: 17px;
      margin: 0;
    }
    .time {
      font-size: 15px;
      color: #adadad;
      margin: 3px 0 0 0;
    }
    .tabel {
      height: 47%;
      width: 100%;
    }
    .detailed {
      width: 100%;
      height: 139px;
      background-color: #f5f5f5;
      margin-top: 30px;
      padding: 10px 0 0 32px;
    }
  }
  .tabelNavs {
    width: 100%;
    height: calc(100vh - 426px);
    background-color: #fff;
  }
  .midBar {
    width: 100%;
    height: 200px;
    background-color: #fff;
    margin-top: 20px;
    padding: 10px 0 0 31px;
    .titword {
      font-size: 17px;
      color: #000000;
      font-weight: 400;
    }
    .line {
      font-size: 12px;
      color: #5a87ff;
      margin-left: 28px;
    }
    .concat {
      margin-left: 28px;
      font-size: 13px;
    }
    .mobile {
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #5a87ff;
    }
  }
  .botBar {
    width: 100%;
    height: 100px;
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .close {
      margin-right: 30px;
    }
  }
}
/deep/.el-table::before {
  height: 0;
}
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
</style>
