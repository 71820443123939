<template>
  <div class="informationNav">
    <navTop />
    <div class="inforBody">
      <div class="leftBar">
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item prop="name" class="demo-ruleForm" label="账户名称">
            <el-input
              v-model="ruleForm.name"
              class="alliInput"
              placeholder="请输入"
              suffix-icon="iconfont iconsousuo-copy"
            />
          </el-form-item>
          <el-form-item prop="desc" class="demo-ruleForm" label="个人简介">
            <el-input
              v-model="ruleForm.desc"
              class="alliInput"
              placeholder="个人简介"
              type="textarea"
              :rows="8"
            />
          </el-form-item>
          <!-- <el-form-item prop="province" class="demo-ruleForm" label="所在省市">
            <div id="app"><el-cascader v-model="selectedOptions" size="large" :options="options" @change="handleChange" /></div>
          </el-form-item>-->
        </el-form>
        <el-button class="local" type="primary" @click="saveBtn"
          >保存</el-button
        >
      </div>
      <div class="rightBar">
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item class="demo-ruleForm" label="用户头像">
            <div class="avatar-uploader">
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                @change="avatarUpload"
              />
              <img v-if="avatarUrl" :src="avatarUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data";
import { mapState, mapMutations } from "vuex";

const formData = new FormData();
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        desc: "",
      },
      avatarUrl: "",
      options: provinceAndCityData,
      selectedOptions: [],
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "userStorage"]),
    getUserInfo() {
      this.$apis.info({}, this.token).then((res) => {
        if (res.data.status == 0) {
          this.ruleForm.name = res.data.data.name;
          this.ruleForm.desc = res.data.data.desc;
          this.avatarUrl = res.data.data.photo_url;
        }
      });
    },
    //更改用户信息
    async saveBtn() {
      this.$apis.userUpdateInfo(this.ruleForm, this.token).then((res) => {
        if (res.data.status == 0) {
          this.$message.success("修改成功");
          this.$apis.info({}, this.token).then((data) => {
            this.userStorage(data.data.data);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //上传验证
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      const isLt2M = file.size < 2 * 1024 * 1024;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、png、jpeg 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }
      return true;
    },
    //上传头像
    avatarUpload(e) {
      let file = e.target.files[0];
      if (!this.beforeAvatarUpload(file)) {
        return false;
      }

      let formData = new FormData();
      formData.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis.userPhoto(formData, this.token).then((res) => {
        if (res.data.status == 0) {
          loading.close();
          this.$message.success("修改成功");
          this.$apis.info({}, this.token).then((data) => {
            this.avatarUrl = data.data.data.photo_url;
            this.userStorage(data.data.data);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.nav == "infor") {
      this.currentUrlStorage(["首页", "个人中心", "个人信息"]);
    }
    this.getUserInfo();
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  margin-top: 50px;
  width: 178px;
  height: 178px;
  position: relative;
  border: 1px dashed #d9d9d9;
  border-radius: 89px;
  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 89px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 50%;
}
/deep/.el-input {
  margin-left: 30px;
}
/deep/.el-form-item {
  margin-top: 53px;
}
/deep/.el-textarea {
  margin-left: 30px;
}
.informationNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px;
  .inforBody {
    width: 100%;
    height: calc(100vh - 160px);
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .leftBar {
      width: 40%;
      height: 85%;
      margin-left: 3%;
      float: left;
    }
    .rightBar {
      width: 40%;
      height: 100%;
      margin-left: 15%;
      float: left;
    }
    .local {
      float: left;
      width: 100px;
      height: 40px;
      margin-left: 5%;
      margin-top: 10%;
    }
  }
}
</style>
