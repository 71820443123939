<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      title="删除"
      v-dialogDrag
    >
      <span class="titleBar"
        >确定删除{{ this.currendelParam.unit_name }}({{
          this.currendelParam.unit_code
        }})吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button class="footBtn" type="primary" @click="delBtn"
          >确 定</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  inject: ["reload"],
  props: {
    currendelParam: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    openDelEdit() {
      this.dialogVisible = true;
    },
    unitListFunc() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .unitList(
          {
            page: this.page,
            per_page: this.per_page,
          },
          this.token,
          ""
        )
        .then((data) => {
          this.unitList = data.data.data.data;
          this.total = data.data.data.total;
          this.current_page = data.data.data.current_page;
          this.per_page = data.data.data.per_page;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.error(err);
        });
    },
    // 确定删除
    delBtn() {
      this.$apis
        .delunit(
          {
            unit_id: this.currendelParam.id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.reload();
            this.$message({
              message: data.data.message,
              type: "success",
            });
          }
          this.dialogVisible = false;
         
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 308px !important;
  height: 200px !important;
  margin-top: 38vh !important;
}
/deep/ .el-dialog__footer {
  margin-top: 15px !important;
}

.footBtn {
  width: 96px;
  height: 36px;
  margin-left: 10px;
}
.titleBar {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 20px 20px 0 20px;
  height: 30px;
  // width: 300px;
  // margin: auto;
}
</style>