var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "登记位置",
            "close-on-click-modal": false,
            visible: _vm.selectMapPointDialog.visible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.selectMapPointDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", height: "500px" } },
            [
              _c(
                "el-amap",
                {
                  staticClass: "amap-box",
                  attrs: {
                    defaultCursor: "pointer",
                    vid: "map" + _vm.vid,
                    events: _vm.mapEvent,
                    "amap-manager": _vm.amapManager,
                    doubleClickZoom: false,
                    keyboardEnable: false,
                    zoom: _vm.zoom,
                    center:
                      _vm.selectPosition.lon != 0
                        ? [_vm.selectPosition.lon, _vm.selectPosition.lat]
                        : _vm.defaultCenter,
                  },
                },
                [
                  _vm.selectPosition.lon != 0
                    ? _c("el-amap-marker", {
                        attrs: {
                          vid: "marker" + _vm.vid,
                          position: [
                            _vm.selectPosition.lon,
                            _vm.selectPosition.lat,
                          ],
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectPosition.lon != 0 && _vm.infoWindowContent != ""
                    ? _c("el-amap-info-window", {
                        attrs: {
                          vid: "marker" + _vm.vid,
                          offset: [0, -35],
                          content: _vm.infoWindowContent,
                          position: [
                            _vm.selectPosition.lon,
                            _vm.selectPosition.lat,
                          ],
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setSelectPosition },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectMapPointDialog.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }