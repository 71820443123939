<template>
  <!-- <div class="new-task-pop"> -->
  <el-dialog title="新建任务" :visible="newTaskNums" width="30.8vw" @close="closeNew">
    <div class="new-pop-text">请先选择要新建任务的类型</div>
    <div class="task-bg-img">
      <img :src="taskImgFm" alt="" @click="clickTaskType(0)">
      <img :src="taskImgTy" alt="" @click="clickTaskType(1)">
    </div>
    <div class="new-pop-btn">
      <el-button style="margin-left: 41.65%; width: 23.8%;height: 4.45vh;" type="primary" plain @click="confirmBtn">确定</el-button>
      <el-button style="width: 23.8%;height: 4.45vh;" plain @click="cancelBtn">取消</el-button>
    </div>
  </el-dialog>
  <!-- </div> -->
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
	props: {},
	data() {
		return {
			taskImgFm: require('../../src/assets/img/newFm/clickFm.png'),
			taskImgTy: require('../../src/assets/img/newFm/ty.png'),
			newTaskNums: false,
			pitchIndex: 0 //  当前选中的
		}
	},
	mounted() {
		this.newTaskNums = this.newTaskNum
	},
	computed: {
		...mapState(['user', 'token', 'role', 'currentUrl', 'newTaskNum'])
	},
	methods: {
		...mapMutations(['achivevStorage', 'jurisidStorage', 'newTaskNumStorage']),
		closeNew() {
			this.newTaskNumStorage(false)
		},
		/**
		 * 点击选中的飞马任务还是通用任务
		 * @param {Object} index
		 */
		clickTaskType(index) {
			this.pitchIndex = index
			if (index) {
				this.taskImgTy =
					this.taskImgTy === require('../../src/assets/img/newFm/ty.png')
						? require('../../src/assets/img/newFm/clickTy.png')
						: require('../../src/assets/img/newFm/ty.png')

				this.taskImgFm = require('../../src/assets/img/newFm/fm.png')
				return
			}
			this.taskImgFm =
				this.taskImgFm === require('../../src/assets/img/newFm/fm.png')
					? require('../../src/assets/img/newFm/clickFm.png')
					: require('../../src/assets/img/newFm/fm.png')
			this.taskImgTy = require('../../src/assets/img/newFm/ty.png')
		},
		/**
		 * 点击确定
		 */
		confirmBtn() {
			this.cancelBtn()
			if (this.pitchIndex) {
				this.$router.push({
					path: '/newgeneric'
				})
				return
			}
			this.$emit('newFmPop')
		},
		/**
		 * 点击取消
		 */
		cancelBtn() {
			this.newTaskNums = false
			this.closeNew()
		}
	}
}
</script>

<style lang="less" scoped>
.new-pop-text {
	margin-bottom: 34px;
	padding-left: 3.2vh;
}

.task-bg-img {
	display: flex;
	img {
		display: inline-block;
		width: 35.95%;
		height: 8.98vh;
		margin: 0 auto;
	}
}

.new-pop-btn {
	width: 100%;
	margin-top: 6.26vh;
	border-top: 1px solid #dde2ee;
	padding-top: 2.22vh;
}
</style>
