var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "today-weather" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "info" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "40px", "margin-right": "20px" } },
            [_vm._v(_vm._s(_vm.city))]
          ),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "最后更新于：" +
                _vm._s(_vm._f("timeFormat")(_vm.nowWeather.obsTime))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "weather-info" }, [
          _c("img", {
            attrs: {
              src:
                "/static/img/weathercn/color-512/" +
                _vm.nowWeather.icon +
                ".png",
            },
          }),
          _vm._v(" "),
          _c("span", { staticStyle: { "font-size": "110px" } }, [
            _vm._v(_vm._s(_vm.nowWeather.temp) + "°"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "font-size": "30px", "align-self": "center" } },
            [_vm._v(_vm._s(_vm.nowWeather.text))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "aqi" }, [
          _vm._v("AQI  " + _vm._s(_vm.cityIndices.category)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nowWeather.windScale) + "级"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _vm._v(_vm._s(_vm.nowWeather.windDir)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm._f("uvIndexLevel")(_vm.todayWeather.uvIndex))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [_vm._v("紫外线")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nowWeather.humidity) + "%"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [_vm._v("相对湿度")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nowWeather.pressure) + "hPa"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [_vm._v("大气压")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nowWeather.precip) + "mm"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [_vm._v("降水量")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.nowWeather.vis) + "km"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [_vm._v("能见度")]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "weather-forecast" }, [
      _c("span", { staticClass: "title" }, [_vm._v("7日天气预报")]),
      _vm._v(" "),
      _c("div", { staticClass: "weather-7d-box" }, [
        _c(
          "div",
          { staticClass: "weather-title" },
          _vm._l(_vm.recent7dWeather, function (item, index) {
            return _c("div", { key: item.fxDate, staticClass: "day" }, [
              _vm._v(
                "\n          " +
                  _vm._s(item.fxDate.substr(5)) +
                  "  \n          "
              ),
              _c("span"),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm._f("dateFormat")(item.fxDate, index))),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "weather-icon icon-top" },
          _vm._l(_vm.recent7dWeather, function (item) {
            return _c("div", { key: item.fxDate, staticClass: "icon" }, [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(item.textDay)),
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src:
                    "/static/img/weathercn/color-64/" + item.iconDay + ".png",
                },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "setChart", attrs: { id: "chart" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "weather-icon icon-bottom" },
          _vm._l(_vm.recent7dWeather, function (item) {
            return _c("div", { key: item.fxDate, staticClass: "icon" }, [
              _c("img", {
                attrs: {
                  src:
                    "/static/img/weathercn/color-64/" + item.iconNight + ".png",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(item.textNight)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "wind" }, [
                _vm._v(
                  _vm._s(item.windDirDay) +
                    "  " +
                    _vm._s(item.windScaleDay) +
                    "级"
                ),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "bottom-line" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }