<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>


<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
@import "assets/iconfont/iconfont.css";
.cursor {
  cursor: pointer;
  user-select: none;
}

.over-dot {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
