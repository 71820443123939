<template>
  <div>
    <el-dialog :modal="false" :visible.sync="dialogVisible" width="330px" :show-close="false">
      <div class="bodys">
        <span class="count">共{{ unreadNoticeNum }}条未读消息</span>
        <el-button @click="watchAll" class="seeall" type="text">查看全部</el-button>
      </div>
      <div v-for="(item, index) in noticeList" :key="index">
        <div class="content" v-if="item.type == 4">
          <div class="content-box" v-if="item.data_apply.status == 1">
            <span class="contword">有编号为：</span>
            <span class="contnum" @click="turnData(item)">{{ item.data_apply.data_apply_no }}</span>
            <span class="contword">的数据申请待审核</span>
          </div>
          <div
            class="content-box"
            v-else-if="item.data_apply.status == 2 || item.data_apply.status == 3"
          >
            <span class="contword">您的数据申请：</span>
            <span class="contnum" @click="turnData(item)">{{ item.data_apply.data_apply_no }}</span>
            <span class="contword">审核结果为：</span>
            <span class="contword">{{ item.data_apply.status | dataApplyStatus }}</span>
          </div>
          <div class="content-box" v-else>
            <span class="contword">编号为：</span>
            <span class="contnum" @click="turnData(item)">{{ item.data_apply.data_apply_no }}</span>
            <span class="contword">的数据申请，</span>
            <span class="contword">{{ item.data_apply.status | dataApplyStatus }}</span>
          </div>
        </div>
        <div class="content" v-if="item.type == 5">
          <div class="content-box" v-if="item.warning.status == 0">
            <span class="contword">有新的预警信息待审核：</span>
            <span class="contnum" @click="turnData(item)">{{ item.warning.area_name }}</span>
          </div>
          <div class="content-box" v-else>
            <span class="contword">您的预警信息：</span>
            <span class="contnum" @click="turnData(item)">{{ item.warning.area_name }}</span>
            <span class="contword">审核结果为：</span>
            <span class="contword">{{ item.warning.status | warningStatus }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      noticeList: [],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getNoticeList();
    },
    watchAll() {
      this.$router.push("/Allmessage");
    },
    // 数据跳转
    turnData(param) {
      this.$apis
        .setNoticeRead(
          {
            id: param._id.$oid,
          },
          this.token
        )
        .then((data) => {
          switch (param.type) {
            case 4:
              if (this.msgCenter.unread_num.data_apply > 0) {
                this.msgCenter.unread_num.data_apply--;
              }
              if (param.data_apply.status == 1) {
                this.$router.push({
                  path: "/auditpage",
                  query: { id: param.data_apply.id },
                });
              } else {
                this.$router.push({
                  path: "/datadetails",
                  query: { id: param.data_apply.id },
                });
              }
              break;
            case 5:
              if (this.msgCenter.unread_num.warning > 0) {
                this.msgCenter.unread_num.warning--;
              }
              if (param.warning.status == 0) {
                this.$router.push({
                  path: "/warningmanage",
                  query: { nav: "wman" },
                });
              } else {
                this.$router.push({
                  path: "/details",
                  query: { id: param.warning.id },
                });
              }
              break;
            case 6:
              if (this.msgCenter.unread_num.disaster > 0) {
                this.msgCenter.unread_num.disaster--;
              }
              break;
          }
          this.dialogVisible = false;
        });
    },
    // 获取消息列表
    getNoticeList() {
      this.$apis
        .getNoticeList(
          {
            is_read: 0,
            type: "4,5,6",
            per_page: 5,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.noticeList = data.data.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    unreadNoticeNum() {
      let data = this.msgCenter.unread_num;
      let num = data.data_apply + data.warning + data.disaster;
      return num > 99 ? "..." : num;
    },
    ...mapState(["token", "user", "msgCenter"]),
  },
  filters: {
    dataApplyStatus(val) {
      let text = "";
      switch (val + "") {
        case "1":
          text = "审核中";
          break;
        case "2":
          text = "待确认";
          break;
        case "3":
          text = "已退回";
          break;
        case "4":
          text = "用户已成交";
          break;
        case "5":
          text = "用户已终止";
          break;
      }
      return text;
    },
    warningStatus(val) {
      let text = "";
      switch (val + "") {
        case "0":
          text = "待审核";
          break;
        case "1":
          text = "已通过";
          break;
        case "2":
          text = "已驳回";
          break;
      }
      return text;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  padding: 0px !important;
}
/deep/.el-dialog__body {
  height: 454px !important;
  background-color: #04081f;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.1),
    0px 6px 16px 0px rgba(0, 0, 0, 0.13), 0px 3px 6px -4px rgba(0, 0, 0, 0.13);
  border-radius: 4px !important;
  border: 1px solid rgba(11, 104, 254, 1) !important;
  padding: 20px 20px 30px 20px !important;
}
/deep/.el-dialog {
  margin-top: 70px !important;
  position: absolute !important;
  left: 65vw !important;
  height: 454px !important;
}
/deep/.el-button--medium {
  padding: 0px 0px !important;
}
.bodys {
  display: block;
  .count {
    float: left;
    color: #6a778d;
  }
  .seeall {
    float: right;
  }
}
.dividing {
  height: 1px;
  width: 100%;
  border: 1px dashed #828a9e;
  float: left;
  margin-top: 20px;
}
.content {
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px dashed #828a9e;
  .content-box {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    .contword {
      font-size: 13px;
      color: #ffffff;
      line-height: 25px;
    }
    .contnum {
      font-size: 13px;
      color: #1782fb;
      text-decoration: underline;
      cursor: pointer;
      line-height: 25px;
    }
  }
}
</style>