<template>
  <el-dialog
    :modal="false"
    :visible.sync="showWindow"
    :show-close="true"
    v-dialogDrag
    v-if="showWindow"
    :append-to-body="true"
    custom-class="multiViews"
    @close="closeMultiView"
  >
    <div class="multiViewBody">
      <div class="header">
        <div class="left">
          <div class="locationIcon">
            <img src="@/assets/img/homeDialog/location@2x.png" alt="" />
          </div>
          <span class="onlineCount">{{ allData.length }}</span>
          <span class="onlineText">在线任务</span>
        </div>
        <div class="middle" style="">
          <div class="btns">
            <button
              class="filterBtn"
              :class="{ filterActive: nowDeviceType === 'drone' }"
              @click="showMoreDrone('drone')"
            >
              <span class="btnText" :title="`无人机（`+originalDroneData[`drone`].length+`)`"
                >无人机({{ originalDroneData["drone"].length }})</span
              >
              <img
                class="caretDown"
                :class="{ caretUp: nowDeviceType === 'drone' }"
                src="@/assets/img/centerHome/caretdown.png"
                alt=""
              />
            </button>
            <button
              class="filterBtn filterBtnMargin"
              :class="{ filterActive: nowDeviceType === 'mapping' }"
              @click="showMoreDrone('mapping')"
            >
              <span class="btnText" :title="`测绘装备(`+originalDroneData[`mapping`].length +`)`"
                >测绘装备({{ originalDroneData["mapping"].length }})</span
              >
              <img
                class="caretDown"
                :class="{ caretUp: nowDeviceType === 'mapping' }"
                src="@/assets/img/centerHome/caretdown.png"
                alt=""
              />
            </button>
            <button
              class="filterBtn filterBtnMargin"
              :class="{ filterActive: nowDeviceType === 'terminal' }"
              @click="showMoreDrone('terminal')"
            >
              <span class="btnText" :title="`终端设备(`+ originalDroneData[`terminal`].length+`)`"
                >终端设备({{ originalDroneData["terminal"].length }})</span
              >
              <img
                class="caretDown"
                :class="{ caretUp: nowDeviceType === 'terminal' }"
                src="@/assets/img/centerHome/caretdown.png"
                alt=""
              />
            </button>
          </div>

          <div class="otherDrone" v-show="isShowMoreDrone">
            <div
              v-for="item in droneBrand"
              :key="item"
              class="droneBrand"
              :class="{ activeBrand: flitBrand.indexOf(item) !== -1 }"
              @click="selectBrand(item)"
            >
              {{ item }}
              <span v-show="item !== '全部'"
                >({{ item | fitlNumber(that) }})</span
              >
            </div>
          </div>
        </div>
        <div class="right">
          <div
            class="prev jt"
            :class="{ notClick: currentPage === 1 }"
            @click="prevPage"
          >
            <
          </div>
          <div class="nowPage page">{{ currentPage }}</div>
          <div>/</div>
          <div class="totalPage page">{{ pagesum }}</div>
          <div
            class="next jt"
            :class="{ notClick: currentPage === pagesum }"
            @click="nextPage"
          >
            >
          </div>
        </div>
      </div>

      <div class="contentWrapper">
        <div class="monitorList" v-if="destroyCom">
          <div
            class="monitor"
            v-for="(item, index) in droneData.slice(
              (currentPage - 1) * eachpage,
              currentPage * eachpage
            )"
            :key="index"
          >
            <!-- {{index}} -->
            <span>
              <LiteMonitor
                class="monitorMonitor"
                :deviceData="item"
                :software_code="item._source.software_code"
                :brandName="item._source.brand_name"
                :mission_id="String(item._source.mission_id)"
                :category_name="item._source.category_name"
                :deviceIcon="deviceIcon"
              >
              </LiteMonitor>
            </span>
            <!-- <span v-else>
              <div style="margin:15px">
                <img :src="deviceIcon[item._source.brand_name === '监控模块' ? item._source.associated_device_type : item._source.brand_name]" alt="" style="width:100%">
              </div>
              <div class="deviceInformation">
                <div>{{item._source.unit_name}}</div>
                <div>{{item._source.unit_name}}</div>

              </div>
            </span> -->
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import LiteMonitor from "./LiteMonitor.vue";
import { mapState, mapMutations } from "vuex";
import { apis } from "@/http/api";
import singleMonitor from "@/components/monitorPlane/singleMonitor.vue";
export default {
  components: {
    LiteMonitor,
    singleMonitor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onlineSortiesData: {
      type: Object,
    },
    onlineLayerData: {
      type: Object,
    },
  },
  watch: {
    show: {
      handler: "handleShowChange",
    },
    onlineLayerData: {
      handler: "init",
      deep: true,
    },
  },
  data() {
    return {
      allData: [], //全部数据
      currentPage: 1,
      showWindow: false,
      originalDroneData: { drone: [], mapping: [], terminal: [] }, //在线无人机原始数据
      droneData: [],
      taskTotal: 0,
      pagesum: 0,
      eachpage: 8, //每页数量
      btnDroneData: "无人机", //按钮的文字说明
      deviceBrand: { drone: ["全部"], mapping: ["全部"], terminal: ["全部"] },
      droneBrand: ["全部"],
      flitBrand: ["全部"],
      nowDeviceType: "drone",
      isShowMoreDrone: false, //显示更多的无人机
      destroyCom: true, //用于销毁组件
      listDeviceData: {},
      deviceNumber: {},
      deviceIcon: {},
      moreScreen: false,
      that:this,
      count: 0
    };
  },
  mounted() {
    this.handleShowChange();
    this.getBrand();
    // this.init();
  },
  computed: {
    ...mapState(["token"]),
  },
  filters: {
    fitlNumber(val,that) {
      let count = 0
      that.allData.forEach(item=>{
        if(item._source.brand_name === val){
          count ++
        }
      })
      return count
    },
  },
  methods: {
    init() {
      // console.log(this.onlineLayerData);
      let data = [];
      for (let key in this.onlineLayerData) {
        if (key === "彩虹无人机") {
          for (let key2 in this.onlineLayerData[key]) {
            if (key2 === "广西") {
              data = data.concat(this.onlineLayerData[key]["广西"]);
            } else {
              for (let key3 in this.onlineLayerData[key][key2]) {
                data = data.concat(this.onlineLayerData[key][key2][key3]);
              }
              // data = data.concat(this.onlineLayerData[key][key2]);
            }
          }
        } else {
          for (let key2 in this.onlineLayerData[key]) {
            data = data.concat(this.onlineLayerData[key][key2]);
          }
        }
      }
      // console.log(data);
      if (JSON.stringify(data) !== JSON.stringify(this.allData)) {

        this.allData.forEach((item, itemIndex) => {
          let index = data.findIndex((v) => {
            return v._source.mission_id === item._source.mission_id;
          });
          if (index === -1) {
            switch (item._source.category_name) {
              case "无人机":
                this.spliceDevice("drone", item);
                break;
              case "终端设备":
                // this.originalDroneData["terminal"].push(item);
                this.spliceDevice("terminal", item);

                break;
              case "测绘装备":
                // this.originalDroneData["mapping"].push(item);
                this.spliceDevice("mapping", item);

                break;
              default:
                break;
            }
            this.allData.splice(itemIndex, 1);
          }
        });
        data.forEach((item) => {
          let index = this.allData.findIndex((v) => {
            return v._source.mission_id === item._source.mission_id;
          });
          if (index === -1) {
            this.allData.push(item);
            switch (item._source.category_name) {
              case "无人机":
                this.pushDevice("drone", item);
                break;
              case "终端设备":
                // this.originalDroneData["terminal"].push(item);
                this.pushDevice("terminal", item);

                break;
              case "测绘装备":
                // this.originalDroneData["mapping"].push(item);
                this.pushDevice("mapping", item);

                break;
              default:
                break;
            }
          }
        });
        this.count++
        if (this.count === 1){
          this.setDroneData(this.originalDroneData[this.nowDeviceType]);
        this.flitBrand.forEach((item) => {
          this.selectBrand(item);
        });
        }


        // this.allData = JSON.parse(JSON.stringify(data));
      }
    },
    spliceDevice(name, item) {
      const deviceIndex = this.originalDroneData[name].findIndex((v1) => {
        return v1._source.mission_id === item._source.mission_id;
      });
      this.originalDroneData[name].splice(deviceIndex, 1);
    },
    pushDevice(name, item) {
      this.originalDroneData[name].push(item);
    },
    getBrand() {
      this.$apis.deviceCategoryBrand({}, this.token).then((res) => {
        // this.droneBrand = res.data.data["无人机"];
        // this.droneBrand.unshift("全部");
        // console.log(res,1234);
        this.deviceBrand.mapping = res.data.data["测绘装备"];
        this.deviceBrand.terminal = res.data.data["终端设备"];
        this.deviceBrand.drone = res.data.data["无人机"];

        this.deviceBrand.mapping.unshift("全部");
        this.deviceBrand.terminal.unshift("全部");
        this.deviceBrand.drone.unshift("全部");
      });
      this.$apis
        .deviceCategoryBrand({ type: "list" }, this.token)
        .then((res) => {
          res.data.data.forEach((item) => {
            if (item.brand_name === "监控模块") {
              for (let key in item.associated_icon) {
                this.deviceIcon[key] = item.associated_icon[key].icon;
              }
            } else {
              this.deviceIcon[item.brand_name] = item.icon;
            }
          });
        });
    },
    //设置要渲染的数据
    setDroneData(data) {
      this.destroyCom = false
      this.droneData = data;
      this.currentPage = 1;
      this.pagesum = Math.ceil(data.length / this.eachpage);
      setInterval(()=>{
        this.destroyCom = true
      },500)
    },
    // 选中其他的无人机
    selectBrand(brand) {
      this.destroyCom = false;
      if (brand === "全部") {
        this.flitBrand = ["全部"];
        // this.btnDroneData = "无人机";
        this.setDroneData(
          JSON.parse(JSON.stringify(this.originalDroneData[this.nowDeviceType]))
        );
      } else {
        if (this.flitBrand.length === 1 && this.flitBrand[0] === "全部") {
          this.flitBrand.splice(0, 1);
          this.droneData = [];
        }
        let index = this.flitBrand.indexOf(brand);
        if (index === -1) {
          this.flitBrand.push(brand);
          this.selectOneDrone(brand);
        } else {
          this.flitBrand.splice(index, 1);
          this.noSelectDrone(brand);
        }
        this.btnDroneData = this.flitBrand[0];
        for (let i = 1; i < this.flitBrand.length; i++) {
          this.btnDroneData = this.btnDroneData + "/" + this.flitBrand[i];
        }
      }
      setTimeout(() => {
        this.destroyCom = true;
      }, 500);
    },
    // 选中一个无人机品牌
    selectOneDrone(brand) {
      // let droneData = [];
      this.originalDroneData[this.nowDeviceType].forEach((item) => {
        if (item._source.brand_name === brand) {
          this.droneData.push(item);
        }
      });
      this.setDroneData(this.droneData);
    },
    // 取消选中无人机品牌
    noSelectDrone(brand) {
      this.originalDroneData[this.nowDeviceType].forEach((item) => {
        if (item._source.brand_name === brand) {
          this.droneData.splice(this.droneData.indexOf(item), 1);
        }
      });
      this.setDroneData(this.droneData);
    },
    // 点击选中展示更多的无人机
    showMoreDrone(deviceType) {
      if (deviceType === this.nowDeviceType) {
        this.isShowMoreDrone = !this.isShowMoreDrone;
      } else {
        this.isShowMoreDrone = true;
      }
      this.nowDeviceType = deviceType;
      this.droneBrand = JSON.parse(
        JSON.stringify(this.deviceBrand[deviceType])
      );
      this.flitBrand = ["全部"];
      this.setDroneData(this.originalDroneData[deviceType]);
    },
    handleShowChange() {
      this.showWindow = this.show;
    },
    closeMultiView() {
      this.$emit("close");
    },
    prevPage() {
      this.destroyCom = false;
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      setTimeout(() => {
        this.destroyCom = true;
      }, 500);
    },
    nextPage() {
      this.destroyCom = false;
      if (this.currentPage < this.pagesum) {
        this.currentPage++;
      }
      setTimeout(() => {
        this.destroyCom = true;
      }, 500);
      //
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: #061220 !important;
  height: 79.3%;
  width: 90.4%;
  min-width: 1024px;
  margin-top: 7% !important;
}
/deep/ .el-dialog__header {
  padding: 0;
  height: 5.2%;
}
/deep/ .el-dialog__body {
  padding: 0;
  height: 95%;
  position: relative;
}
/deep/ .el-dialog__headerbtn {
  margin-top: -4px;
}
.multiViews {
  // width: 80%;
}
.multiViewBody {
  width: 100%;
  height: 100%;
  background: #061220;
  box-sizing: border-box;
  position: relative;
}
.header {
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
  color: #fff;
  .left {
    display: flex;
    align-items: center;
    margin-left: 1.7%;
    // width: 190px;
    .onlineCount {
      color: #ffd65c;
      font-family: "DIN";
      font-weight: bold;
      font-size: 22px;
      line-height: 40px;
      width: 60px;
      margin-left: 15px;
    }

    .onlineText {
      color: #7893b9;
      font-size: 17px;
      line-height: 40px;
      margin-left: 20px;
      margin-right: 7.5%;
      width: 100px;
    }
  }
  .right {
    width: 129px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-right: 83px;
    position: absolute;
    right: 4.8%;
    .jt {
      margin-bottom: 2px;
      cursor: pointer;
      user-select: none;
      width: 10px;
    }
    .page {
      width: 28px;
      height: 24px;
      background: rgba(105, 120, 158, 0.3);
      border-radius: 2px;
      border: 1px solid #69789e;
      text-align: center;
      line-height: 24px;
    }
    .notClick {
      cursor: not-allowed;
    }
  }
  .middle {
    width: 56%;
    position: relative;
    .btns {
      display: flex;
      width: 100%;
    }
    // display: flex;
    .filterBtnMargin {
      margin-left: 10px;
    }
    .filterActive {
      background: rgba(25, 137, 250, 0.3) !important;
      border: 2px solid #1989fa !important;
    }
    .filterBtn {
      width: 166px;
      height: 40px;
      background: #212e43;
      border-radius: 4px;
      border: 2px solid #69789e;
      cursor: pointer;
      font-size: 14px;
      color: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: rgba(25, 137, 250, 0.3);
        border: 2px solid #1989fa;
      }

      .caretDown {
        position: absolute;
        top: 12px;
        right: 22px;
        transform: rotate(180deg);
      }
      .caretUp {
        transform: rotate(0deg);
      }
      .btnText {
        display: inline-block;
        width: 80px;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btnActive {
      background: rgba(25, 137, 250, 0.3);
      border: 2px solid #1989fa;
    }
  }
}
.otherDrone {
  height: 42px;
  background: #212e43;
  border-radius: 4px;
  border: 1px solid #69789e;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  margin-top: 10px;
  // left: 17.4%;
  // top: 53px;
  // left: 30px;
  .droneBrand {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    margin: 0 15px;
  }
  .activeBrand {
    color: #1989fa;
  }
}
.contentWrapper {
  height: 60%;
  min-width: 1024px;
  padding-top: 10px;
  .monitorList {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 2.2%;
    padding-top: 4px;
  }
  .monitor {
    height: 67.7%;
    width: 23.6%;

    min-width: 236px;
    margin-right: 1.3%;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 24px;
    .deviceInformation {
      // height: 48%;
      width: 100%;

      // background: #fff;
      // opacity: 0.89;
    }
    .monitorMonitor {
      border-radius: 12px;
      box-sizing: border-box;
    }
  }
}
</style>
