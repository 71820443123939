var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "新建任务", visible: _vm.newTaskNums, width: "30.8vw" },
      on: { close: _vm.closeNew },
    },
    [
      _c("div", { staticClass: "new-pop-text" }, [
        _vm._v("请先选择要新建任务的类型"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "task-bg-img" }, [
        _c("img", {
          attrs: { src: _vm.taskImgFm, alt: "" },
          on: {
            click: function ($event) {
              return _vm.clickTaskType(0)
            },
          },
        }),
        _vm._v(" "),
        _c("img", {
          attrs: { src: _vm.taskImgTy, alt: "" },
          on: {
            click: function ($event) {
              return _vm.clickTaskType(1)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "new-pop-btn" },
        [
          _c(
            "el-button",
            {
              staticStyle: {
                "margin-left": "41.65%",
                width: "23.8%",
                height: "4.45vh",
              },
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("确定")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "23.8%", height: "4.45vh" },
              attrs: { plain: "" },
              on: { click: _vm.cancelBtn },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }