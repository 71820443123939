var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-push" },
    [
      _c("navTop", { staticClass: "navTop" }),
      _vm._v(" "),
      _c(
        "el-amap",
        {
          ref: "map",
          staticClass: "amap-demo",
          attrs: {
            plugin: _vm.plugin,
            events: _vm.events,
            center: _vm.center,
            zoom: _vm.zoom,
            "amap-manager": _vm.amapManager,
          },
        },
        [
          _vm._l(_vm.polygons, function (polygon, index) {
            return _c("el-amap-polygon", {
              key: index,
              attrs: {
                "stroke-opacity": 0,
                "fill-opacity": 0.3,
                "fill-color": "#367cf3",
                vid: index,
                path: polygon,
              },
            })
          }),
          _vm._v(" "),
          _c("el-amap-polyline", {
            attrs: {
              "stroke-color": "#ffff00",
              editable: false,
              "stroke-weight": 2,
              path: _vm.polyline,
            },
          }),
          _vm._v(" "),
          _c("el-amap-polyline", {
            attrs: {
              "stroke-color": "#ffff00",
              editable: false,
              "stroke-weight": 1,
              "stroke-style": "dashed",
              path: _vm.polylines,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "left-alert" }, [
            _c(
              "div",
              { staticClass: "left-alert-ov iconfont" },
              [
                _c("div", { staticClass: "project-name" }, [
                  _vm._v(_vm._s(_vm.projectName)),
                ]),
                _vm._v(" "),
                _vm._l(_vm.projectData.arr, function (item) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c(
                        "div",
                        { staticStyle: { margin: "19px 0 18px 49px" } },
                        [_vm._v(_vm._s(item.block_name))]
                      ),
                      _vm._v(" "),
                      _vm._l(item.task, function (item2) {
                        return _c(
                          "div",
                          {
                            key: item2.id,
                            staticClass: "list-div",
                            on: {
                              click: function ($event) {
                                return _vm.getDetails(item2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item2.task_name) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      Object.keys(_vm.parameter).length > 0 && _vm.parameterIndex > 0
        ? _c("div", { staticClass: "task-particulars" }, [
            _c("div", { staticClass: "particulars-top" }, [
              _c(
                "div",
                {
                  class: { bor: _vm.parameterIndex === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(1)
                    },
                  },
                },
                [_vm._v("\n        任务信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cen",
                  class: { bor: _vm.parameterIndex === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(2)
                    },
                  },
                },
                [_vm._v("\n        无人机信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { bor: _vm.parameterIndex === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(3)
                    },
                  },
                },
                [_vm._v("\n        终端信息\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "particulars-body" }, [
              _vm.parameterIndex === 1 && _vm.nav === 1
                ? _c("div", { staticClass: "body-top" }, [
                    _c("div", { staticClass: "div name" }, [
                      _c("div", { staticClass: "task-name" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "model" }, [
                        _c("div", [
                          _vm._v("\n              设备型号:\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.content.plane_type)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "52px" } }, [
                          _vm._v("\n              载荷型号:\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.content.camera_type)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "div parameter" }, [
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("参数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("最高点")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("最低点")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.average_ground_resolution
                            ) + " cm"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.high_gsd) + "cm"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.low_gsd) + "cm"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.longitudinal_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.high_longitudinal_overlap
                            ) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.low_longitudinal_overlap
                            ) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.lateral_overlap) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.high_lateral_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.low_lateral_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_average_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_highest_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_lowest_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            飞行高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.minimum_route_height) +
                              "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            比例尺\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.work_scale)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "div message" }, [
                      _c("div", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("信息"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              航线间距:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.flight_interval) +
                                "m"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              预计时间:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.work_time) + "min"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              拍照间距:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.take_pic_interval) +
                                "m"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.parameter.content.work_area
                          ? _c("div", [
                              _vm._v(
                                "\n              预计面积:\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.work_area.slice(0, 3)
                                  ) + "km²"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              默认空速:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.default_air_speed) +
                                "m/s"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              预计里程:\n              "),
                          _vm.parameter.content.total_mileage
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.total_mileage.slice(
                                      0,
                                      5
                                    )
                                  ) + "km"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm.parameterIndex === 1 && _vm.nav === 2
                ? _c("div", { staticClass: "div uav" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("传感器")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project.sensor_type
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("授权空域")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project.auth_area
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("任务性质")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project
                                .charge_property
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("影像分辨率")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .average_ground_resolution
                                ) + "cm"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("航向重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .longitudinal_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("旁向重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .lateral_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density !== ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("激光点密度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .point_cloud_density
                                ) + "p/m²"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density !== ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("航带重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .lateral_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm.parameterIndex === 2 && _vm.parameter.uav != null
                ? _c("div", { staticClass: "uav div" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备ID")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.software_code)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备型号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.parameter.uav.name))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("联盟单位")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.unit_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备状态")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.statusArr[_vm.parameter.uav.status])
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("归属部门")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_dept)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_phone)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("创建人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.creator_phone)),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm.parameterIndex === 3 && _vm.parameter.terminal != null
                ? _c("div", { staticClass: "div uav" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备ID")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.parameter.terminal.id))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备型号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("联盟单位")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.unit_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备状态")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.statusArr[_vm.parameter.terminal.status])
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("归属部门")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_dept)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_phone)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("创建人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.creator_phone)),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.parameter.iframe_url !== ""
              ? _c(
                  "div",
                  {
                    staticClass: "iconfont play",
                    on: {
                      click: function ($event) {
                        return _vm.play(_vm.parameter.iframe_url)
                      },
                    },
                  },
                  [_vm._v("\n      \n    ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }