// 成果相关接口
import {
	fly,
	baseURL,
	start,
	finishRes,
	finishErr,
	deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
	(request) => {
		start(request)
	}
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
	(response) => {
		finishRes(response)
	},
	(err) => {
		finishErr(err)
	}
)

//  成果文件上传
export const directUpload = (data, token) => fly.post(
	baseURL + '/data/directUpload',
	data, deploy(30000, token)
)

//  成果列表
export const listResult = (data, token) => fly.post(
	baseURL + '/data/listResult',
	data, deploy(30000, token)
)

//  删除成果
export const deleteResult = (data, token) => fly.post(
	baseURL + '/data/deleteResult',
	data, deploy(30000, token)
)

//  成果待发布
export const publishResult = (data, token) => fly.post(
	baseURL + '/data/publishResult',
	data, deploy(30000, token)
)


//  添加兴趣点
export const addPoi = (data, token) => fly.post(
	baseURL + '/data/addPoi',
	data, deploy(30000, token)
)

//   删除兴趣点
export const delPoi = (data, token) => fly.post(
	baseURL + '/data/delPoi',
	data, deploy(30000, token)
)

//   兴趣点列表
export const listPoi = (data, token) => fly.post(
	baseURL + '/data/listPoi',
	data, deploy(30000, token)
)

//   兴趣点类型
export const getInterestType = (data, token) => fly.get(
	baseURL + '/data/getInterestType',
	data, deploy(30000, token)
)

// 挂接任务
export const relationWithTask = (data, token) => fly.post(
	baseURL + '/data/relationWithTask',
	data, deploy(30000, token)
)

// 下载Datamanager
export const upgrade = (data, token) => fly.post(
	baseURL + '/common/upgrade',
	data, deploy(30000, token)
)

// 成果是否发布到门户
export const publishPortal = (data, token) => fly.post(
	baseURL + '/data/publishPortal',
	data, deploy(30000, token)
)

// 保存兴趣点类型
export const dataSaveInterestType = (data, token) => fly.post(
	baseURL + '/data/saveInterestType',
	data, deploy(30000, token)
)

// 删除兴趣点类型
export const dataDelInterestType = (data, token) => fly.post(
	baseURL + '/data/delInterestType',
	data, deploy(30000, token)
)

// 保存成果海拔
export const saveDataResultHeight = (data, token) => fly.post(
	baseURL + '/data/saveDataResultHeight',
	data, deploy(30000, token)
)

// 设备状态查询
export const deviceListStatus = (data, token) => fly.post(
	baseURL + '/device/listStatus',
	data, deploy(30000, token)
)

// 添加设备状态
export const deviceAddStatus = (data, token) => fly.post(
	baseURL + '/device/addStatus',
	data, deploy(30000, token)
)

// 更新设备状态
export const deviceUpdateStatus = (data, token) => fly.post(
	baseURL + '/device/updateStatus',
	data, deploy(30000, token)
)

// 删除设备状态
export const deviceDeleteStatus = (data, token) => fly.post(
	baseURL + '/device/deleteStatus',
	data, deploy(30000, token)
)


