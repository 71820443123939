<template>
  <div>
    <el-dialog
      title="加载空域"
      :visible.sync="dialogVisible"
      class="taskBox"
      :close-on-click-modal="false"
      :modal="false"
      v-dialogDrag
    >
      <el-divider class="fenge"></el-divider>
      <div class="detailBar">
        <div class="leftTit">
          <div class="details">
            <span class="title">授权空域名称</span>
          </div>
          <div class="details">
            <span class="title">空域存续信息</span>
          </div>
          <div class="details">
            <span class="title">起止时间</span>
          </div>
          <div class="details">
            <span class="title">收授权航高</span>
          </div>
          <div class="details">
            <span class="title">任务批件信息</span>
          </div>
          <div class="details">
            <span class="title">空管部门名称</span>
          </div>
          <div class="details">
            <span class="title">空管联系电话</span>
          </div>
          <div class="details">
            <span class="title">空域批件信息</span>
          </div>
          <div class="details">
            <span class="title">主管部门</span>
          </div>
          <div class="details">
            <span class="title">联系电话</span>
          </div>
        </div>
        <div class="rightTit">
          <div class="details">
            <span class="value">{{ this.airDetails.air_space_name }}</span>
          </div>
          <div class="details">
            <span class="value"
              >---------------------------------------------</span
            >
          </div>
          <div class="details">
            <span class="value">{{ start }}～{{ end }}</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.authorize_altitude }}m</span>
          </div>
          <div class="details">
            <span class="value"
              >---------------------------------------------</span
            >
          </div>
          <div class="details">
            <span class="value">{{
              this.airDetails.traffic_control_department
            }}</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.traffic_control_tel }}</span>
          </div>
          <div class="details">
            <span class="value"
              >---------------------------------------------</span
            >
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.manage_department }}</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.manage_tel }}</span>
          </div>
        </div>
      </div>
      <el-divider class="fenge"></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBtn" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  props: {
    airDetails: {
      type: Object,
      default: {},
    },
    title: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      dialogVisible: false,
      start: "",
      end: "",
      lng: "",
      lat: "",
    };
  },
  methods: {
    open () {
      this.dialogVisible=true;
      if(this.airDetails!=undefined) {
        this.start=this.airDetails.flight_start_time.slice(0,10);
        this.end=this.airDetails.flight_end_time.slice(0,10);
      }
    },
    closeBtn () {
      this.dialogVisible=false;
    },
  },
  computed: {
    ...mapState(["user","token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-divider--horizontal {
  margin: 24px 0 10px 0;
}
/deep/.el-button--primary.is-plain {
  margin-left: 20px;
}
/deep/.el-dialog {
  height: 584px;
  width: 432px;
}
/deep/.el-dialog__title {
  color: #000000;
  font-size: 20px;
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
.title {
  float: left;
  font-size: 13px;
  color: #3c4353;
}
.details {
  width: 100%;
  float: left;
  margin-bottom: 25px;
}
.value {
  float: left;
  margin-left: 20px;
  font-size: 13px;
  color: #828a9e;
}
.detailBar {
  padding: 20px 30px 0 50px;
  float: left;
}
.fenge {
  float: left;
}
.leftTit {
  float: left;
  width: 30%;
}
.rightTit {
  float: right;
  width: 70%;
}
</style>