var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "精确筛选",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            click: _vm.turnon,
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detailBar" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.ruleForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联盟单位" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.ruleForm.unit_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "unit_id", $$v)
                            },
                            expression: "ruleForm.unit_id",
                          },
                        },
                        _vm._l(_vm.unitList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.unit_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.task_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "task_type", $$v)
                            },
                            expression: "ruleForm.task_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "航线任务", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "通用任务", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.task_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "task_name", $$v)
                          },
                          expression: "ruleForm.task_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.result_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "result_name", $$v)
                          },
                          expression: "ruleForm.result_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据格式" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.result_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "result_type", $$v)
                            },
                            expression: "ruleForm.result_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tileset", value: "TILESET" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Imagery", value: "IMAGERY" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Terrain", value: "TERRAIN" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Poi", value: "POI" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "Model", value: "MODEL" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type == "poi"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "兴趣点名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.poi_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "poi_name", $$v)
                              },
                              expression: "ruleForm.poi_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "poi"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "兴趣点类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.ruleForm.poi_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "poi_type", $$v)
                                },
                                expression: "ruleForm.poi_type",
                              },
                            },
                            _vm._l(
                              _vm.interestTypeList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.data_interest_type,
                                    value: item.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchBtn } },
                [_vm._v("查 询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("重 置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }