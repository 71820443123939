var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "allSpell" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.spellitWarning,
          expression: "spellitWarning",
        },
      ],
      staticClass: "popContainer",
      on: { click: _vm.warningCancelsClick },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "spell-it" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSpelling,
              expression: "isSpelling",
            },
          ],
          staticClass: "spellitStatus",
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSpelling,
                expression: "isSpelling",
              },
            ],
            attrs: {
              src: require("@/assets/img/homeDialog/littleBtn/refresh.png"),
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "spell-it-btn",
        on: {
          click: function ($event) {
            return _vm.spellClick()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "realTime",
          staticClass: "menu-btn",
          class: [
            _vm.wholeCourseStatus === "结束"
              ? "real-time-btn-grey"
              : "real-time-btn",
          ],
          staticStyle: { display: "none" },
          on: {
            click: function ($event) {
              _vm.wholeCourseStatus !== "结束" && _vm.realTimeClick()
            },
          },
        },
        [_vm._v("\n      实时快拼\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "theWholeWay",
          staticClass: "menu-btn",
          class: [
            _vm.realTimeStatus === "结束"
              ? "the-whole-way-btn-grey"
              : "the-whole-way-btn",
          ],
          staticStyle: { display: "none" },
          on: {
            click: function ($event) {
              _vm.realTimeStatus !== "结束" && _vm.theWholeWayClick()
            },
          },
        },
        [_vm._v("\n      全程快拼\n    ")]
      ),
      _vm._v(" "),
      _c("br"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.springFrame,
            expression: "springFrame",
          },
        ],
        staticClass: "spring-frame",
      },
      [
        _c("div", { staticClass: "spring-frame-top" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "spring-frame-tab" },
          [
            _c("div", { staticClass: "frame-tab-name" }, [
              _vm._v(_vm._s(_vm.frameTabName)),
            ]),
            _vm._v(" "),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.detailsContent,
                  placement: "bottom",
                },
              },
              [
                _c("div", {
                  staticClass: "frame-tab-attention",
                  attrs: { id: "account-for" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "frame-tab-shutdown",
              attrs: { id: "shut-down" },
              on: {
                click: function ($event) {
                  return _vm.shutDownClick()
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "spring-list",
            attrs: { id: "spring-list" },
          },
          [
            _vm.realTimeAchievements.length > 0
              ? _c(
                  "span",
                  _vm._l(_vm.realTimeAchievements, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "spring-list-okay" },
                      [
                        _c("div", { staticClass: "spring-list-taskData" }, [
                          _c("div", { staticClass: "spring-list-taskName" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "spring-list-taskTime" }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "spring-list-taskOperation" },
                          [
                            _c(
                              "div",
                              { staticClass: "spring-list-taskTarget" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.status) +
                                    "拼图："
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "rgba(38, 129, 255, 1)",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.images_qty) + "张")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.status === "正在",
                                    expression: "item.status === '正在'",
                                  },
                                ],
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeDialog/littleBtn/refresh.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "129",
                                  trigger: "click",
                                  "popper-class": "noDownFile",
                                },
                              },
                              [
                                _c("div", { staticClass: "dowmloadDio" }, [
                                  _c("div", { staticClass: "downItem" }, [
                                    _c("div", [_vm._v("下载成果")]),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/homeDialog/spellBtn/download.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downAchievements(
                                            item.ResultPath,
                                            item
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.status === "完成" &&
                                          item.path !== "",
                                        expression:
                                          "item.status === '完成' && item.path !== ''",
                                      },
                                    ],
                                    staticClass: "download-time btn-style",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      "\n                七天内下载\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  title: "",
                                  width: "128",
                                  trigger: "hover",
                                  "popper-class": "noDownFile",
                                  content: "已超过有效期，有效期为7天。",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.status === "完成" &&
                                          item.path === "",
                                        expression:
                                          "item.status === '完成' && item.path === ''",
                                      },
                                    ],
                                    staticClass:
                                      "download-time btn-style no-download",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      "\n                七天内下载\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.status === "完成",
                                    expression: "item.status === '完成'",
                                  },
                                ],
                                staticClass: "spring-list-delBtn btn-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.delItem(item)
                                  },
                                },
                              },
                              [_vm._v("\n              删除\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("span", [
                  _c("div", { staticClass: "noSepllIt" }, [_vm._v("暂无拼图")]),
                ]),
          ]
        ),
        _vm._v(" "),
        _vm.frameTabName === "全程快拼"
          ? _c(
              "div",
              {
                staticClass: "spring-operation",
                attrs: { id: "spring-operation" },
                on: {
                  click: function ($event) {
                    return _vm.startOrPause()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.status) + "拼图\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.frameTabName === "实时快拼" &&
        _vm.spellitNodeUrl !== "" &&
        !_vm.isHistory
          ? _c(
              "div",
              {
                staticClass: "spring-operation",
                class: { canStatus: _vm.isHistory },
                attrs: { id: "spring-operation" },
                on: {
                  click: function ($event) {
                    return _vm.startOrPause()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.status) + "拼图\n    ")]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.spellitWarning,
            expression: "spellitWarning",
          },
        ],
        staticClass: "spell-warning",
        attrs: { id: "warning" },
      },
      [
        _c("div", { staticClass: "warning-txt" }, [
          _c("div", { staticClass: "warning-txt-icon" }),
          _vm._v(" "),
          _c("div", { attrs: { id: "warning-name" } }, [
            _vm._v("确认要" + _vm._s(_vm.dioText) + "拼图吗？"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "warning-btn" }, [
          _c(
            "div",
            {
              attrs: { id: "warning-cancels" },
              on: {
                click: function ($event) {
                  return _vm.warningCancelsClick()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { id: "warning-confirmed" },
              on: {
                click: function ($event) {
                  return _vm.warningConfirmedClick()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }