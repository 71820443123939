<template>
  <div class="navbar">
    <div class="amap-box">
      <el-amap
        :plugin="plugin"
        :events="events"
        :zoom="zoom"
        :center="center"
        class="amap-box"
        :vid="'amap-vue'"
      >
        <el-amap-marker
          v-for="(marker, index) in markers"
          :key="index"
          :position="marker.position"
          :events="marker.events"
          :visible="marker.visible"
          :icon="marker.icon"
          :content="marker.template"
          :draggable="marker.draggable"
        />
        <el-amap-polygon
          v-for="(polygon, index) in polygons"
          :key="index + '0'"
          :ref="`polygon_${index}`"
          bubble="true"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
        <!-- 限制区域 -->
        <el-amap-polygon
          v-for="(polygon, index) in limitArea"
          v-if="limitArea.length > 0"
          :key="index + '1'"
          :ref="`polygon_${index}`"
          bubble="true"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
        <!-- 存续区域 -->
        <el-amap-polygon
          v-for="(polygon, index) in subsistArea"
          v-if="subsistArea.length > 0"
          :key="index + '2'"
          :ref="`polygon_${index}`"
          bubble="true"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
        <!-- 过起空域 -->
        <el-amap-polygon
          v-for="(polygon, index) in overdueArea"
          v-if="overdueArea.length > 0"
          :key="index + '3'"
          :ref="`polygon_${index}`"
          bubble="true"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
      </el-amap>
    </div>
    <queryBar
      ref="queryBar"
      @empty="empty"
      :title="title"
      :warZone="warZone"
      :partition="partition"
      :limitData="limitData"
    />
    <applyBar
      v-if="ableApply"
      :legal="legal"
      :centerStr="centerStr"
      :latitudeAndLongitudeStr="latitudeAndLongitudeStr"
    />
    <screenAir @limit="limit" @subsist="subsist" @overdue="overdue" />
    <slot></slot>
  </div>
</template>

<script>
const turf = require("@turf/turf");
import { mapState, mapMutations } from "vuex";
import { AMapManager, elAmapMarker, elAmapPolygon } from "vue-amap";
import queryBar from "@/components/messageBox/queryAirspace";
import applyBar from "@/components/messageBox/applyAirspace";
import screenAir from "@/components/messageBox/screeningAirspace";
import coordtransform from "@/utils/transform.js";
import publicJs from "@/utils/public.js";
import defaultConstants from "@/utils/default-constants";
export default {
  components: {
    queryBar,
    applyBar,
    screenAir,
  },
  props: {
    title: {
      type: String,
      default: "查询空域",
    },
    ableApply: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const self = this;
    return {
      latitudeAndLongitudeStr: "", //  经纬度和高度数组集合字符串
      centerStr: "", //  中心点
      legal: false, //  绘制是否合法
      zoom: 14,
      markers: [], //  点击地图标点的展示
      center: defaultConstants.mapDefaultLocation,
      events: {
        init(e, mapStyle) {
          e.setMapStyle("amap://styles/macaron"); // 自定义的高德地图的样式，我选的是马卡龙
        },
        click(e) {
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.center = [result.position.lng, result.position.lat];
                }
              });
            },
          },
        },
      ],
      polygons: [
        //绘图形成的区块
        {
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: [],
        },
      ], //  多边形的绑定数据
      lng: "",
      lat: "",
      limitArea: [], //限制区域
      subsistArea: [], //存续区域
      overdueArea: [], //过期空域
      warZone: [],
      partition: [],
      limitData: [],
    };
  },
  watch: {
    /**
     * 当lng的值发生变化是，页面新增点位
     */
    lng() {
      if (this.$refs.queryBar.flag != 1) {
        return false;
      }
      //画点之后 清空地图上所有空域
      this.limitArea = [];
      this.subsistArea = [];
      this.overdueArea = [];
      this.polygons = [];

      //添加地图上的画出的点
      const obj = {
        position: [this.lng, this.lat],
        events: {},
        visible: true,
        draggable: false,
        template:
          '<div style="width: 8px;height: 8px;background: #000;border: 2px solid #49B2FF;border-radius: 50%;margin: 28px 0 0 6px"></div>',
      };
      this.markers.push(obj);

      this.latitudeAndLongitudeStr =
        this.latitudeAndLongitudeStr === ""
          ? `${this.lng},${this.lat},0`
          : `${this.latitudeAndLongitudeStr};${this.lng},${this.lat},0`;
      //当标记4个点位后形成区块
      if (this.markers.length >= 4) {
        let path = [];
        //存储坐标掉数组集合
        const arr = []; //
        for (let i = 0; i < this.markers.length; i++) {
          path.push(this.markers[i].position);
          arr[i] = this.markers[i].position;
        }
        //末尾加上第一个点，头尾相连
        arr.push(this.markers[0].position);

        let polygon = turf.polygon([arr], {
          name: "poly1",
        });
        this.isIntersect(polygon);

        this.polygons.push({
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: path,
        });
      }
    },
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    // 限制区域
    limit(param) {
      if (param == true) {
        this.searchAir(1, "");
      } else {
        this.limitArea = [];
      }
    },
    // 存续空域
    subsist(param) {
      if (param == true) {
        this.searchAir("", 1);
      } else {
        this.subsistArea = [];
      }
    },
    // 过期空域
    overdue(param) {
      if (param == true) {
        this.searchAir("", 2);
      } else {
        this.overdueArea = [];
      }
    },
    // 空域查询
    searchAir(isLimit, type) {
      this.$apis
        .applySearch(
          {
            restricted_area: isLimit,
            valid_area: type,
          },
          this.token
        )
        .then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data;
            let result = [];
            data.forEach((item) => {
              let coordinateList = publicJs.coordinateStr2coordinateList(
                item.survey_area_coordinate_points
              );
              result.push(this.initMapPolygon(coordinateList));
            });

            if (isLimit == 1) {
              this.limitArea = result;
            } else if (type == 1) {
              this.subsistArea = result;
            } else if (type == 2) {
              this.overdueArea = result;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // kml加载空域坐标
    kmlLoadAir(coordinateStr) {
      this.empty();
      let coordinateList = publicJs.coordinateStr2coordinateList(coordinateStr);

      // 坐标系转换
      let changeCoordinateList = [];
      coordinateList.forEach((point) => {
        changeCoordinateList.push(
          coordtransform.wgs84togcj02(point[0], point[1])
        );
      });
      this.polygons = [this.initMapPolygon(changeCoordinateList)];

      //首位坐标为中心点
      this.center = changeCoordinateList[0];

      let polygon = turf.polygon([changeCoordinateList], {
        name: "poly1",
      });
      
      this.latitudeAndLongitudeStr = coordinateStr;
      //查询是否相交、是否为禁飞区
      this.isIntersect(polygon);
    },
    // 坐标数据的组装
    initMapPolygon(coordinateList) {
      let path = [];
      coordinateList.forEach((point) => {
        path.push([point[0], point[1]]);
      });
      let polygonItem = {
        draggable: false,
        strokeColor: "#49B2FF",
        fillColor: "#47ABF5",
        fillOpacity: 0.5,
        path: path,
        visible: false,
      };
      return polygonItem;
    },
    /**
     * 绘制完多边形判断Polygon是否为自相交
     * @param {Object} polygon turfpolygon类型
     */
    isIntersect(polygon) {
      let kinks = turf.kinks(polygon);
      let center = turf.centerOfMass(polygon).geometry.coordinates;
      if (kinks.features.length > 0) {
        // 存在自相交情况
        this.$message({
          showClose: true,
          message: "错误，多边形不合法，请顺时针或逆时针绘制坐标点",
          type: "error",
        });
        this.legal = false;
        this.$emit("drawError");
        return;
      }

      // 不存在自相交情况
      this.legal = true;
      this.centerStr = `${center[0]},${center[1]}`;
      // 坐标点头尾相连
      let coordinatesStr = this.latitudeAndLongitudeStr.replace(/,0/g, "");
      coordinatesStr = coordinatesStr + ";" + coordinatesStr.split(";")[0];
      //判断是否为禁飞区
      this.isNofly(coordinatesStr);
      this.$emit("drawComplete", {
        latitudeAndLongitudeStr: this.latitudeAndLongitudeStr, //含高度的坐标集合
        coordinatesStr: coordinatesStr, //不含高度且首尾相连的坐标集合
      });
    },
    //判断是否为禁飞区
    isNofly(coordinatesStr) {
      this.$apis
        .noflyzone(
          {
            polygon: coordinatesStr,
            token: "986983972bd48c14045e1fad4918cf28",
          },
          this.token
        )
        .then((res) => {
          this.warZone = res.data.result.war;
          this.partition = res.data.result.control;
          this.limitData = res.data.result.airport;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 清空所绘画的区域
    empty() {
      this.limitArea = [];
      this.subsistArea = [];
      this.overdueArea = [];

      this.legal = false;
      this.markers = [];
      this.polygons = [];
      this.latitudeAndLongitudeStr = "";
      this.centerStr = "";

      this.warZone = [];
      this.partition = [];
      this.limitData = [];

      this.$emit("drawClear");
    },
  },
  created() {},
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "air") {
      this.currentUrlStorage(["首页", "空域查询", "空域查询"]);
    }
  },
};
</script>

<style lang="less" scoped>
.navbar {
  width: calc(100vw - 220px);
  // height: 100%;
  .amap-box {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
