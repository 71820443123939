<template>
  <!-- 门户顶部菜单 -->
  <div style="overflow: hidden;width:100%;height:50px">
    <div class="topBar">
      <div class="bg-purple">
        <div class="topLeftBar">
          <div class="imgBar">
            <img style="width: 100%" src="@/assets/img/new-logo.png" />
          </div>
          <div class="titleBar" @click="returnHome">
            <span class="top-title">智慧田长管理平台</span>
            <span class="Platformname">【公众门户网站】</span>
          </div>
        </div>
        <div class="navmenu" style="width:43%">

          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            text-color="#fff"
            active-text-color="#fff"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item
              @click="leftNav(0)"
              :class="{ fmHoverBgc: currentUrl[1] === '应急任务' }"
              index="/getawayTask"
              >应急任务</el-menu-item
            >
            <el-menu-item
              @click="leftNav(1)"
              :class="{ fmHoverBgc: currentUrl[1] === '目录查询' }"
              index="/result"
              >目录查询</el-menu-item
            >
            <el-menu-item
              @click="leftNav(2)"
              :class="{ fmHoverBgc: currentUrl[1] === '数据申请' }"
              index="/application"
              >数据申请</el-menu-item
            >
            <el-menu-item
              @click="leftNav(3)"
              :class="{ fmHoverBgc: currentUrl[1] === '空域查询' }"
              index="/airsearch"
              >空域查询</el-menu-item
            >
            <el-menu-item
              @click="leftNav(4)"
              :class="{ fmHoverBgc: currentUrl[1] === '气象服务' }"
              index="/publicPortalWeather"
              >气象服务</el-menu-item
            >
            <!-- <el-menu-item
              @click="leftNav(5)"
              :class="{ fmHoverBgc: currentUrl[1] === '舆情发布' }"
              index="/sentiment"
              >舆情发布</el-menu-item
            >
            <el-menu-item
              @click="leftNav(6)"
              :class="{ fmHoverBgc: currentUrl[1] === '预警发布' }"
              index="/warning"
              >预警发布</el-menu-item
            > -->
          </el-menu>
        </div>
        <div class="userInform">
          <div @click="newBtn">
            <i class="iconfont lingdang">&#xe64b;</i>
            <el-badge
              class="mark"
              :value="unreadNoticeNum"
              v-show="unreadNoticeNum != 0"
            />
          </div>
          <div @click="userBtn" class="userBar">
            <span v-if="!token" class="userName">请登录</span>
            <avatar v-if="token" class="avatar" :avatar="user.photo_url" />
            <span v-if="token" class="userName" :title="user.name">{{ user.name }}</span>
            <i v-if="user.is_admin == 1" class="iconfont icon-VIP">&#xe632;</i>
            <i
              v-if="user.is_admin == 0 && user.is_unit == 1"
              class="iconfont icon-pre"
              >&#xe605;</i
            >
          </div>
          <!-- <div class="sent" @click="releseWarning">
            <i class="iconfont feather">&#xe64d;</i>
            <span class="release">发布预警</span>
          </div> -->
        </div>
      </div>
      <News ref="newcenter" />
      <personalInformation ref="Infor" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import News from "@/components/news.vue";
import personalInformation from "@/components/personalInformation.vue";
import avatar from "@/components/avatar/avatar";
export default {
  components: {
    avatar,
    News,
    personalInformation,
  },
  data() {
    return {
      activeIndex: "/",
    };
  },
  methods: {
    ...mapMutations([
      "userStorage",
      "tokenStorage",
      "safetyTopStorage",
      "navMessageStorage",
      "currentUrlStorage",
    ]),
    returnHome() {
      this.$router.push("/login");
    },
    handleSelect(key, keyPath) {
      this.$router.push({
        path: key,
        params: { data: keyPath },
      });
    },
    newBtn() {
      this.$refs.newcenter.open();
    },
    userBtn() {
      this.$refs.Infor.open();
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    /**
     * 修改左侧导航栏
     * @param {number} index
     */
    leftNav(index) {
      this.navMessageStorage(index);
      switch (index) {
        case 0:
          this.currentUrlStorage(["首页", "应急任务", "航线任务"]);
          break;
        case 1:
          this.currentUrlStorage(["首页", "目录查询", "目录查询"]);
          break;
        case 2:
          this.currentUrlStorage(["首页", "数据申请", "数据申请"]);
          break;
        case 3:
          this.currentUrlStorage(["首页", "空域查询", "空域查询"]);
          break;
        case 4:
          this.currentUrlStorage(["首页", "气象服务", "气象服务"]);
          break;
        case 5:
          this.currentUrlStorage(["首页", "舆情发布", "舆情发布"]);
          break;
        case 6:
          this.currentUrlStorage(["首页", "预警发布", "预警发布"]);
          break;
        default:
          this.currentUrlStorage(["首页", "个人中心", "个人信息"]);
          break;
      }
    },
    releseWarning() {
      this.$router.push("/issue");
    },
  },
  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  computed: {
    ...mapState([
      "navMessage",
      "role",
      "token",
      "user",
      "currentUrl",
      "msgCenter",
    ]),
    unreadNoticeNum() {
      let num =
        this.msgCenter.unread_num.data_apply +
        this.msgCenter.unread_num.warning +
        this.msgCenter.unread_num.disaster;
      return num > 99 ? "..." : num;
    },
  },
};
</script>

<style lang="scss" scoped>
.fmHoverBgc {
  // color: #1182fb !important;
  border-bottom: none;
  border-radius: 10px;
  background-color: #2e4d8c8f !important;
  border-bottom-color: #2e4d8c8f !important;
}
@import url("~@/assets/iconfont/iconfont.css");
/deep/.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: transparent;
}
/deep/.el-menu-item {
  &:hover {

    background-color: #2e4d8c8f !important;
    border-radius: 10px;
  }
}
/deep/.el-menu-item.is-active {
  border-bottom: none;
}
/deep/.el-menu--horizontal > .el-menu-item {
  display: flex;
  align-items: center;
  height: 40px;
}
.el-menu-demo {
  background-color: transparent;
}
.topBar {
  height: 80px;
  background-color: #04081f;
  width: 100%;
  float: left;
  // min-width: 1300px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.bg-purple {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  min-width:1280px;
  background-image: url(/static/img/navbarCenter.eadf0406.png);
    background-size: 100% 50px;
}
.topLeftBar {
  height: 100%;
  cursor: pointer;
  display: flex;
  width: 530px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  background: url("~@/assets/img/centerHome/navbarLeft.png") 620px 50px;
}
.imgBar {
  //background: url("~@/assets/img/home／logo.png") center no-repeat;
  width: 38px;
  height: 44px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  // margin-left: 40px;
}
.top-title {
  font-size: 16px;
  color: #ffffff;
  font-style: normal;
}
.Platformname {
  font-size: 14px;
  // color: #0086ff;
  color: #fff;
}
.userInform {
  // width: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  .userBar {
    display: flex;
    align-items: center;
    .avatar {
      margin-left: 10px;
    }
  }
  .lingdang {
    font-size: 24px;
    color: #fff;
    display: flex;
    justify-content: center;
    float: left;
    cursor: pointer;
  }
  .mark {
    margin-left: -10px;
    float: left;
    cursor: pointer;
  }
  .Avatar {
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    cursor: pointer;
  }
  .userName {
    color: #fff;
    font-size: 16px;
    float: left;
    margin-left: 10px;
    cursor: pointer;
    max-width: 100px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-VIP {
    font-size: 14px;
    color: #1182fb;
    display: flex;
    justify-content: center;
    float: left;
    margin-left: 5px;
    cursor: pointer;
  }
  .icon-pre {
    font-size: 14px;
    color: #faad14;
    display: flex;
    justify-content: center;
    float: left;
    margin-left: 5px;
    cursor: pointer;
  }
  .sent {
    cursor: pointer;
    width: 120px;
    height: 50px;
    background: linear-gradient(
      225deg,
      rgba(0, 110, 254, 1) 0%,
      rgba(48, 54, 255, 1) 100%
    );
    border-radius: 2px;
    float: left;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .feather {
      font-size: 26px;
      color: #ffff;
    }
    .release {
      font-size: 14px;
      color: #ffffff;
      margin-left: 6px;
    }
  }
}
@media only screen and (max-width: 1450px) {
  /deep/.el-menu--horizontal > .el-menu-item {
    width: 68px;
  }
  /deep/.el-menu-item {
    padding: 0 6px;
  }
}
</style>
