// 设备视图
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'


// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)
const timeOut = 30000
// 获取架次数量
export const sortiesCount = (data, token) => fly.post(
  baseURL + '/deviceView/sortiesCount', data, deploy(timeOut, token)
)

// 获取架次列表
export const sortiesList = (data, token) => fly.post(
  baseURL + '/deviceView/sortiesList', data, deploy(timeOut, token)
)

// 获取设备品牌
export const deviceCategoryBrand = (data, token) => fly.post(
  baseURL + '/deviceView/deviceCategoryBrand', data, deploy(timeOut, token)
)
// 获取设备品
export const deviceViewIndex = (data, token) => fly.post(
  baseURL + '/deviceView/index', data, deploy(timeOut, token)
)
// 获取设备轨迹
export const getDeviceTrack = (data, token) => fly.post(
  baseURL + '/location/getDeviceTrack', data, deploy(timeOut, token)
)
