var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "privile" }, [
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "cen" }, [
        _c(
          "div",
          { staticClass: "cen-left" },
          [
            _c("navTop"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "taxon" },
              _vm._l(_vm.labelList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.name,
                    class: { super: _vm.userListIndex == index + 1 },
                    on: {
                      click: function ($event) {
                        return _vm.roleListNav(item.id)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "configuration-right" },
          [
            _c("div", { staticStyle: { height: "1px", width: "100%" } }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userListIndex === 2,
                    expression: "userListIndex === 2",
                  },
                ],
                staticClass: "equipment-btn",
              },
              [
                _c(
                  "div",
                  {
                    class: { pitchOn: _vm.equipmentBtnNum === 0 },
                    on: {
                      click: function ($event) {
                        _vm.equipmentBtnNum = 0
                      },
                    },
                  },
                  [_vm._v("\n            无人机\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: { pitchOn: _vm.equipmentBtnNum === 1 },
                    on: {
                      click: function ($event) {
                        _vm.equipmentBtnNum = 1
                      },
                    },
                  },
                  [_vm._v("\n            终端设备\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: { pitchOn: _vm.equipmentBtnNum === -1 },
                    on: {
                      click: function ($event) {
                        _vm.equipmentBtnNum = -1
                      },
                    },
                  },
                  [_vm._v("\n            测绘装备\n          ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-menu",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userListIndex === 2,
                    expression: "userListIndex === 2",
                  },
                ],
                staticClass: "el-menu-demo configuration-navMenu",
                attrs: {
                  "default-active": _vm.activeIndex,
                  mode: "horizontal",
                },
                on: { select: _vm.handleSelect },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("设备品牌"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("设备型号"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [
                  _vm._v("设备状态"),
                ]),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.equipmentBtnNum === 1,
                        expression: "equipmentBtnNum === 1",
                      },
                    ],
                    attrs: { index: "4" },
                  },
                  [_vm._v("关联设备类型")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-search" },
              [
                _c("div", { staticClass: "form-search-text" }, [
                  _vm._v(_vm._s(_vm.configurationName)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-search-input",
                    style: {
                      right: Number(_vm.activeIndex) === 2 ? "39.6%" : "142px",
                      width: Number(_vm.activeIndex) === 2 ? "21.6%" : "234px",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入内容",
                        "suffix-icon": "el-icon-search",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.debounce(
                            _vm.roleList,
                            200,
                            _vm.userListIndex
                          )
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.debounce(
                            _vm.roleList,
                            200,
                            _vm.userListIndex
                          )
                        },
                      },
                      model: {
                        value: _vm.searchData,
                        callback: function ($$v) {
                          _vm.searchData = $$v
                        },
                        expression: "searchData",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                Number(_vm.activeIndex) === 2 && _vm.userListIndex === 2
                  ? _c(
                      "el-select",
                      {
                        staticClass: "facility-select",
                        attrs: { clearable: "", placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            return _vm.handleSelect("2")
                          },
                        },
                        model: {
                          value: _vm.brandId,
                          callback: function ($$v) {
                            _vm.brandId = $$v
                          },
                          expression: "brandId",
                        },
                      },
                      _vm._l(_vm.brandArr, function (item) {
                        return _c(
                          "el-option",
                          { key: item.id, attrs: { value: item.name } },
                          [_c("span", [_vm._v(_vm._s(item.name))])]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-search-btn",
                    on: { click: _vm.openBtn },
                  },
                  [_vm._v(_vm._s(_vm.addBtnStr))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "96px" } },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.userListIndex === 2 ? " 500px" : " 600px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "id",
                        label: _vm.tableDataId,
                        width: "200px",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.id !== ""
                                ? _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(scope.row.id) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "data",
                        label: _vm.tableDataData,
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("input", {
                                ref: "ref" + scope.row.id,
                                staticClass: "nameState",
                                attrs: {
                                  type: "text",
                                  placeholder: scope.row.data,
                                  disabled: scope.row.id !== _vm.editBoxKey,
                                  id: "id" + scope.row.id,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.inputJudge(scope)
                                  },
                                  blur: function ($event) {
                                    return _vm.cursorDisappears(scope)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "200px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.id !== ""
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.id !== ""
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }