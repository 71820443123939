import coordtransform from "@/utils/transform.js";
class DrawMapMarker {
  constructor(map, netWorkIcon) {
    this.map = map;
    this.massLayerDict = {};
    this.netWorkIcon = netWorkIcon;

    this.netWorkIcon["省级田长"]={
      "category_name": "终端设备",
      "brand_name": "省级田长",
      "icon": "https://gxtestapi.afuav.com/static/device_brand_icon/survey_cloud.png",
      "online_icon": "/static/img/tian/省级田长.svg",
      "online_icon_png": "https://gxtestapi.afuav.com/static/device_icon/online_icon/survey_cloud.png",
      "history_sorties_icon": "https://gxtestapi.afuav.com/static/device_icon/history_sorties_icon/survey_cloud.png",
      "location_icon": "https://gxtestapi.afuav.com/static/device_icon/location_icon/survey_cloud.png"
    }
    this.netWorkIcon["市级田长"]={
      "category_name": "终端设备",
      "brand_name": "市级田长",
      "icon": "https://gxtestapi.afuav.com/static/device_brand_icon/survey_cloud.png",
      "online_icon": "/static/img/tian/市级田长.svg",
      "online_icon_png": "https://gxtestapi.afuav.com/static/device_icon/online_icon/survey_cloud.png",
      "history_sorties_icon": "https://gxtestapi.afuav.com/static/device_icon/history_sorties_icon/survey_cloud.png",
      "location_icon": "https://gxtestapi.afuav.com/static/device_icon/location_icon/survey_cloud.png"
    }
    this.netWorkIcon["村级田长"]={
      "category_name": "终端设备",
      "brand_name": "村级田长",
      "icon": "https://gxtestapi.afuav.com/static/device_brand_icon/survey_cloud.png",
      "online_icon": "/static/img/tian/村级田长.svg",
      "online_icon_png": "https://gxtestapi.afuav.com/static/device_icon/online_icon/survey_cloud.png",
      "history_sorties_icon": "https://gxtestapi.afuav.com/static/device_icon/history_sorties_icon/survey_cloud.png",
      "location_icon": "https://gxtestapi.afuav.com/static/device_icon/location_icon/survey_cloud.png"
    }
    this.netWorkIcon["县区级田长"]={
      "category_name": "终端设备",
      "brand_name": "县区级田长",
      "icon": "https://gxtestapi.afuav.com/static/device_brand_icon/survey_cloud.png",
      "online_icon": "/static/img/tian/县区级田长.svg",
      "online_icon_png": "https://gxtestapi.afuav.com/static/device_icon/online_icon/survey_cloud.png",
      "history_sorties_icon": "https://gxtestapi.afuav.com/static/device_icon/history_sorties_icon/survey_cloud.png",
      "location_icon": "https://gxtestapi.afuav.com/static/device_icon/location_icon/survey_cloud.png"
    }
    this.netWorkIcon["乡镇级田长"]={
      "category_name": "终端设备",
      "brand_name": "乡镇级田长",
      "icon": "https://gxtestapi.afuav.com/static/device_brand_icon/survey_cloud.png",
      "online_icon": "/static/img/tian/乡镇级田长.svg",
      "online_icon_png": "https://gxtestapi.afuav.com/static/device_icon/online_icon/survey_cloud.png",
      "history_sorties_icon": "https://gxtestapi.afuav.com/static/device_icon/history_sorties_icon/survey_cloud.png",
      "location_icon": "https://gxtestapi.afuav.com/static/device_icon/location_icon/survey_cloud.png"
    }

    this.clustererCountry = true;
    this.clustererCloud = true;
    this.countryCoverageData = {};
    this.cloudCoverageData = {};
  }

  // 绘制海量点
  drawMassivePoint(
    data,
    photoUrl,
    number,
    zIndex = 111,
    show = false,
    isPoint = false
  ) {
    let icon = "";
    this.massLayerDict = {};
    let temData = {};
    let otherPhotoUrl = "";
    let otherPhotoUrl2 = "";

    for (let key in data) {
      if (data[key].length > 0) {
        let droneType = "common";
        let isBoolean = true;
        let lnglatType = "wgsHistoryCommon";
        // 这里判断是否是监控模块，然后使用其他的图标
        if (zIndex === 112) {
          // photoUrl = this.netWorkIcon["彩虹"].history_sorties_icon;
          photoUrl = this.getImgForNet(
            "彩虹",
            isPoint ? "location_icon" : "history_sorties_icon"
          );
        } else {
          if (key === "监控模块") {
            data[key].forEach((item, index) => {
              if (item.name.associated_device_type) {
                if (item.name.associated_device_type === "车辆") {
                  otherPhotoUrl = this.netWorkIcon["监控模块"][
                    "associated_icon"
                  ][item.name.associated_device_type][
                    isPoint ? "location_icon" : "history_sorties_icon"
                  ];
                  data[key][index].style = 1;
                } else {
                  otherPhotoUrl2 = this.netWorkIcon["监控模块"][
                    "associated_icon"
                  ][item.name.associated_device_type][
                    isPoint ? "location_icon" : "history_sorties_icon"
                  ];
                  data[key][index].style = 2;
                }
              }
            });
            photoUrl = this.netWorkIcon["监控模块"]["associated_icon"][
              "default"
            ].history_sorties_icon;
          } else {
            photoUrl = this.getImgForNet(
              key,
              isPoint ? "location_icon" : "history_sorties_icon"
            );
          }
        }
        let style = [
          {
            url: photoUrl,
            anchor: new AMap.Pixel(16, 16),
            size: new AMap.Size(32, 32)
          }, //默认
          {
            url: otherPhotoUrl,
            anchor: new AMap.Pixel(16, 16),
            size: new AMap.Size(32, 32)
          }, //车辆
          {
            url: otherPhotoUrl2,
            anchor: new AMap.Pixel(16, 16),
            size: new AMap.Size(32, 32)
          } //其他
        ];
        if (this.massLayerDict[key]) {
          this.massLayerDict[key].clear();
          this.map.remove(this.massLayerDict[key]);
        }
        let mass = new AMap.MassMarks(data[key], {
          zIndex: zIndex, // 海量点图层叠加的顺序
          cursor: "pointer",
          style: style
        });
        mass.on("click", function(e) {
          e.data["name"]["nowloaction"] = [
            Number(e.data.lnglat.lng),
            Number(e.data.lnglat.lat)
          ];
          window.getDroneInfo(e.data.name, droneType, isBoolean, lnglatType); // 注意：e.data.name, 即设备基本信息字段, 在终端请求设备详情时有依赖, 如非必要请勿调整
        });
        mass.setMap(this.map);
        this.massLayerDict[key] = mass;
        if (show) {
          mass.show();
        } else {
          mass.hide();
        }
        temData[key] = mass;
      }
    }

    return temData;
  }

  // 在线标记点，图为svg格式
  setOnlineMakers(
    data,
    isRealTime,
    coordinate,
    onlineHideData,
    clusterMaxZoom,
    initCluster = true
  ) {
    let zIndex = 111;
    let photoUrl = "";
    let coverageData = [];
    let clusterData = {};
    let droneType = "common";
    let isBoolean = true;
    let lnglatType = "wgsHistoryCommon";
    for (let key in data) {
      switch (key) {
        case "彩虹无人机":
          zIndex = 119;
          break;
        case "无人机":
          zIndex = 118;

          break;
        case "测绘装备":
          zIndex = 117;

          break;
        case "终端设备":
          zIndex = 116;

          // 这里是终端类别，然后需要替换监控模块图标，请在这里进行判断替换
          break;
        default:
          break;
      }
      coverageData[key] = {};
      let endIcon = new AMap.Icon({
        size: new AMap.Size(40, 40),
        image: photoUrl,
        imageSize: new AMap.Size(40, 40),
        imageOffset: new AMap.Pixel(0, 0)
      });
      if (key === "彩虹无人机") {
        coverageData[key] = { 其他: {}, 广西: [] };
        for (let key2 in data[key]["其他"]) {
          data[key]["其他"][key2].forEach(item => {
            let marker = this.drawOnlineMarker(
              endIcon,
              item,
              droneType,
              isBoolean,
              lnglatType,
              zIndex,
              coordinate
            );
            this.map.add(marker);
            if (coverageData[key]["其他"][key2]) {
              coverageData[key]["其他"][key2].push(marker);
            } else {
              coverageData[key]["其他"][key2] = [];
              coverageData[key]["其他"][key2].push(marker);
            }
          });
        }
        data[key]["广西"] &&
          data[key]["广西"].forEach(item => {
            let marker = this.drawOnlineMarker(
              endIcon,
              item,
              droneType,
              isBoolean,
              lnglatType,
              zIndex,
              coordinate
            );
            this.map.add(marker);
            if (!isRealTime) {
              marker.hide();
            }
            coverageData[key]["广西"].push(marker);
          });
      } else {
        for (let key3 in data[key]) {
          if (key3 === "飞马" || key3 === "大疆") {
            droneType = "feima";
            isBoolean = false;
            lnglatType = "wgsOnlineFeima";
          } else {
            droneType = "common";
            isBoolean = true;
            lnglatType = "wgsHistoryCommon";
          }

          data[key][key3].forEach(item => {
            let marker = this.drawOnlineMarker(
              endIcon,
              item,
              droneType,
              isBoolean,
              lnglatType,
              zIndex,
              coordinate
            );
            this.map.add(marker);
            if (!isRealTime) {
              marker.setMap(null);
            }
            if (
              onlineHideData[key] !== undefined &&
              onlineHideData[key] !== "showPart"
            ) {
              if (onlineHideData[key]) {
                marker.setMap(null);
              }
            } else {
              if (onlineHideData[key] === "showPart") {
                if (onlineHideData[key3] === "show") {
                  marker.show();
                } else {
                  marker.setMap(null);
                }
              } else {
                if (onlineHideData[key3] === "hide") {
                  marker.setMap(null);
                }
              }
            }
            if (coverageData[key][key3]) {
              coverageData[key][key3].push(marker);
            } else {
              coverageData[key][key3] = [];
              coverageData[key][key3].push(marker);
            }
          });
        }
      }
    }
    if (initCluster){
      for (let key in coverageData) {
        if (key === "彩虹无人机") {
          for (let key2 in coverageData[key]) {
            if (key2 === "广西" && coverageData[key][key2][0]) {
              let mapLayer = coverageData[key][key2][0].getMap();
              this.cloudCoverageData = this.drawMarkerCluster(
                coverageData[key][key2],
                "彩虹",
                clusterMaxZoom
              );

              if (!mapLayer) {
                this.cloudCoverageData.setMap(null);
              }
              coverageData[key][key2] = this.cloudCoverageData;
            }
          }
        } else {
          if (key !== "终端设备") {
            let path = [];
            let iconName = "";
            for (let key2 in coverageData[key]) {
              iconName = key2;

              if (coverageData[key][key2][0]) {
                let mapLayer = coverageData[key][key2][0].getMap();
                if (mapLayer) {
                  path = path.concat(coverageData[key][key2]);
                }
              }
            }
            clusterData[key] = this.drawMarkerCluster(
              path,
              iconName,
              clusterMaxZoom
            );
          } else {
            let cloudCoverageData = {};
            for (let key2 in coverageData[key]) {
              if (coverageData[key][key2][0]) {
                let mapLayer = coverageData[key][key2][0].getMap();
                cloudCoverageData = this.drawMarkerCluster(
                  coverageData[key][key2],
                  key2,
                  clusterMaxZoom
                );

                if (!mapLayer) {
                  cloudCoverageData.setMap(null);
                }
                coverageData[key][key2] = cloudCoverageData;
              }
            }
          }
        }
      }
    }

    return [coverageData, clusterData];
  }
  // 设备标记点，图为svg格式
  setDeviceMakers(data, isRealTime, index, coordinate) {
    let photoUrl = "";
    let coverageData = [];
    let droneType = "common";
    let isBoolean = true;
    let lnglatType = "wgsHistoryCommon";
    for (let key in data) {
      coverageData[key] = {};
      let zIndex = 111;
      if (key === "彩虹无人机") {
        coverageData[key] = { 其他: {}, 广西: [] };
        for (let key2 in data[key]["其他"]) {
          data[key]["其他"][key2].forEach(item => {
            zIndex = 112;
            if (item._source.online === 1) {
              zIndex = 113;
            }
            let endIcon = new AMap.Icon({
              size: new AMap.Size(40, 40),
              image: photoUrl,
              imageSize: new AMap.Size(40, 40),
              imageOffset: new AMap.Pixel(0, 0)
            });
            let marker = this.drawOnlineMarker(
              endIcon,
              item,
              droneType,
              isBoolean,
              lnglatType,
              zIndex,
              coordinate,
              false
            );
            this.map.add(marker);
            if (!isRealTime) {
              marker.hide();
            }
            if (coverageData[key]["其他"][key2]) {
              coverageData[key]["其他"][key2].push(marker);
            } else {
              coverageData[key]["其他"][key2] = [];
              coverageData[key]["其他"][key2].push(marker);
            }
          });
        }
        data[key]["广西"].forEach(item => {
          zIndex = 112;
          if (item._source.online === 1) {
            zIndex = 113;
          }
          let endIcon = new AMap.Icon({
            size: new AMap.Size(40, 40),
            image: photoUrl,
            imageSize: new AMap.Size(40, 40),
            imageOffset: new AMap.Pixel(0, 0)
          });
          let marker = this.drawOnlineMarker(
            endIcon,
            item,
            droneType,
            isBoolean,
            lnglatType,
            zIndex,
            coordinate,
            false
          );
          this.map.add(marker);
          if (!isRealTime) {
            marker.hide();
          }
          coverageData[key]["广西"].push(marker);
        });
      } else {
        for (let key3 in data[key]) {
          if (key3 === "飞马" || key3 === "大疆") {
            droneType = "feima";
            isBoolean = false;
            lnglatType = "wgsOnlineFeima";
          } else {
            droneType = "common";
            isBoolean = true;
            lnglatType = "wgsHistoryCommon";
          }
          data[key][key3].forEach(item => {
            zIndex = 111;
            switch (item._source.category_name) {
              case "无人机":
                zIndex = 113;
                break;
              case "测绘装备":
                zIndex = 113;
                break;
              default:
                zIndex = 113;
                break;
            }

            let endIcon = new AMap.Icon({
              size: new AMap.Size(40, 40),
              image: photoUrl,
              imageSize: new AMap.Size(40, 40),
              imageOffset: new AMap.Pixel(0, 0)
            });
            let marker = this.drawOnlineMarker(
              endIcon,
              item,
              droneType,
              isBoolean,
              lnglatType,
              zIndex,
              coordinate,
              false
            );
            this.map.add(marker);
            if (!isRealTime) {
              marker.hide();
            }
            if (coverageData[key][key3]) {
              coverageData[key][key3].push(marker);
            } else {
              coverageData[key][key3] = [];
              coverageData[key][key3].push(marker);
            }
          });
        }
      }
    }
    return coverageData;
  }
  drawOnlineMarker(
    endIcon,
    item,
    droneType,
    isBoolean,
    lnglatType,
    zIndex = 111,
    coordinate = "gcj02",
    isOnline = true
  ) {
    let lnglat = [];
    lnglat = item[coordinate];
    let photoUrl = "";
    if (item._source.brand_name === "监控模块") {
      if (isOnline) {
        if (
          item._source.associated_device_type !== "" &&
          item._source.associated_device_type
        ) {
          photoUrl = this.netWorkIcon["监控模块"]["associated_icon"][
            item._source.associated_device_type
          ].online_icon;
        } else {
          photoUrl = this.netWorkIcon["监控模块"]["associated_icon"]["default"]
            .online_icon;
        }
      } else {
        if (
          item._source.associated_device_type !== "" &&
          item._source.associated_device_type
        ) {
          photoUrl = this.netWorkIcon["监控模块"]["associated_icon"][
            item._source.associated_device_type
          ].location_icon;
        } else {
          photoUrl = this.netWorkIcon["监控模块"]["associated_icon"]["default"]
            .location_icon;
        }
      }
    } else {
      if (isOnline) {
        // photoUrl = this.netWorkIcon[item._source.brand_name].online_icon;
        photoUrl = this.getImgForNet(item._source.brand_name, "online_icon");
      } else {
        // photoUrl = this.netWorkIcon[item._source.brand_name].location_icon;
        photoUrl = this.getImgForNet(item._source.brand_name, "location_icon");
      }
    }
    endIcon = new AMap.Icon({
      size: new AMap.Size(40, 40),
      image: photoUrl,
      imageSize: new AMap.Size(40, 40),
      imageOffset: new AMap.Pixel(0, 0)
    });
    let marker = new AMap.Marker({
      position: lnglat,
      icon: endIcon,
      zIndex: zIndex,
      offset: new AMap.Pixel(-16, -16),
      extData: item._source.mission_id
    });
    marker.on("click", function(e) {
      item._source["nowloaction"] = [lnglat[0], lnglat[1]];
      window.getDroneInfo(item._source, droneType, isBoolean, lnglatType);
    });
    return marker;
  }
  /**
   * 获取到点亮的图片
   */
  getImgForNet(key, layer) {
    let img =
      this.netWorkIcon[key] && this.netWorkIcon[key][layer]
        ? this.netWorkIcon[key][layer]
        : "";
    return img;
  }
  getClustererColor(key, layer) {
    let color =
      this.netWorkIcon[key] && this.netWorkIcon[key][layer]
        ? this.netWorkIcon[key][layer]
        : "";
    return color;
  }
  /**
   * 聚合显示图标
   */
  drawMarkerCluster(data, name, clusterMaxZoom) {
    let imgUrl = "";
    let color = "";
    if (name !== "监控模块") {
      imgUrl = this.getImgForNet(name, "online_icon");
      color = this.getClustererColor(name, "clusterer_color");
    } else {
      color = this.netWorkIcon["监控模块"]["associated_icon"]["车辆"]
        .clusterer_color;
      // console.log(this.netWorkIcon);
      imgUrl = this.netWorkIcon["监控模块"]["associated_icon"]["车辆"]
        .online_icon;
    }
    color ="#33D8D6"
    const count = data.length;
    const _renderClusterMarker = function(context) {
      const div = document.createElement("div");
      const size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 15);
      div.style.width = div.style.height = size + "px";
      div.innerHTML =
        "<div style='border: 1px solid " +
        color +
        ";height: 15px;border-radius: 3px;background: #fff;font-size: 12px;color: " +
        color +
        ";font-weight: 500;    position: absolute; " +
        "bottom: 0px;padding: 4px;left: " +
        size / 2 +
        "px;display: flex;align-items: center;'>" +
        context.count +
        "</div>";
      div.style.lineHeight = size + "px";
      div.style.color = "#000";
      div.style.textAlign = "center";
      div.style.position = "relative";
      div.style.lineHeight = size + "px";
      div.style.color = "#fff";
      div.style.fontSize = "12px";
      div.style.textAlign = "center";
      div.style.paddingTop = "8px";
      div.style.fontWeight = "500";
      div.style.background = "url(" + imgUrl + ") no-repeat center";
      div.style.backgroundSize = "100%";
      context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
      context.marker.setContent(div);
    };
    const cluster = new AMap.MarkerClusterer(this.map, data, {
      maxZoom: clusterMaxZoom,
      renderClusterMarker: _renderClusterMarker
    });
    return cluster;
  }
}
export default DrawMapMarker;
