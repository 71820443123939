<template>
  <div>
    <el-dialog
      :modal="true"
      :center="true"
      title="新建航线任务引导"
      :visible.sync="dialogVisible"
      width="62.5vw"
    >
      <el-carousel :loop="false" trigger="click" height="45.46vh" @change="changeItem()" ref="feimaCarousel">
        <el-carousel-item v-for="item in 4" :key="item">
          <img class="newFm-img" :src="require('../assets/img/newFm/newFm-' + item + '.png')" alt />
        </el-carousel-item>
      </el-carousel>
      <div
        style="position: absolute;bottom: 57px;left: 50%;transform: translateX(-50%);color: #838A9D;"
      >{{textValue}}</div>
      <div style="position: absolute;bottom: 24px;left: 50%;transform: translateX(-50%);color: #007EFE;">
        {{nowItemIndex +1 }}<span style="color:#9EA2B0;">/4</span>
      </div>
      <div @click="toWin" style="bottom: 57px;" class="btn">下载无人机管家</div>
      <div @click="toIpad" style="bottom: 24px;" class="btn">下载无人机管家iPad</div>
      <div style="padding-bottom: 106px;"></div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      imgArr: [],
      listTextValue: ['在【无人机管家】客户端创建工程','设计生成航线并保存该工程','将该工程分享至第三方平台【广西云平台】','在【调度中心端】-“待推送任务”中查看该任务'],
      textValue: '在【无人机管家】客户端创建工程',
      nowItemIndex: 0,
    };
  },
  methods: {
    changeItem(){
      this.nowItemIndex = this.$refs.feimaCarousel.activeIndex;
      this.textValue = this.listTextValue[this.nowItemIndex];
    },
    /**
     * 跳转win10
     */
    toWin() {
      window.open(
        "https://files-10008291.cos.ap-shanghai.myqcloud.com/app/UAVManagerProfessional301.exe"
      );
    },
    /**
     * 跳转ipad
     */
    toIpad() {
      window.open("http://www.feimarobotics.com/zhcn/uavmanagerInstall");
    },
    reveal() {
      this.dialogVisible = true;
    },
  },
  computed: {
    ...mapState(["token", "user"]),
  },
};
</script>

<style lang="less" scoped>
.newFm-img {
  display: block;
  width: 49vw;
  height: 45.46vh;
  margin: 0 auto;
  background: url(../assets/img/newFm/newFm-1.png);
  border-radius: 12px;
}

.btn {
  position: absolute;
  right: 10.8%;
  color: #007efe;
  cursor: default;
}
</style>
