var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flying" },
    [
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "bottom-el-select" },
            [
              _c(
                "div",
                { staticClass: "refresh-btn", on: { click: _vm.init } },
                [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "el-select-power scale",
                  attrs: { placeholder: "请选择" },
                  on: {
                    change: function ($event) {
                      return _vm.taskQueryAlter()
                    },
                  },
                  model: {
                    value: _vm.taskQueryValue,
                    callback: function ($$v) {
                      _vm.taskQueryValue = $$v
                    },
                    expression: "taskQueryValue",
                  },
                },
                _vm._l(_vm.taskQueryArr, function (item) {
                  return _c("el-option", { key: item, attrs: { value: item } })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "el-select-button scale",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.newTaskNumStorage(true)
                    },
                  },
                },
                [_vm._v("新建任务")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index, staticClass: "bottom-content" }, [
              item.created_at
                ? _c("div", { staticClass: "bottom-content-top flex-view" }, [
                    _c(
                      "div",
                      {
                        staticClass: "unfold-btn iconfont",
                        on: {
                          click: function ($event) {
                            return _vm.ubfoldAlter(index, item.id)
                          },
                        },
                      },
                      [
                        _vm.unfoldArr[index]
                          ? _c("span", [_vm._v("")])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.unfoldArr[index]
                          ? _c("span", [_vm._v("")])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "content-top-name",
                        attrs: {
                          title:
                            "姓名：" +
                            (item.name || "无") +
                            "  单位：" +
                            (item.unit_name || "无"),
                        },
                      },
                      [
                        _vm._v(
                          "\n          创建人：" +
                            _vm._s(item.name) +
                            "   " +
                            _vm._s(item.unit_name) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-top-time" }, [
                      _vm._v(
                        "\n          创建时间：" +
                          _vm._s(item.created_at.slice(0, 19)) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div"),
                    _vm._v(" "),
                    _vm.role !== 2
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor",
                            class: { isPortalRed: item.is_portal },
                            staticStyle: { width: "8%", "margin-left": "12%" },
                            on: {
                              click: function ($event) {
                                return _vm.taskIsPortal(
                                  item.id,
                                  item.is_portal,
                                  index
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  !item.is_portal ? "发布门户" : "取消发布"
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.role !== 2
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor publicOpinion",
                            staticStyle: { width: "8%" },
                            on: {
                              click: function ($event) {
                                return _vm.publicSentiment(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.disaster !== null
                                    ? "编辑舆情"
                                    : "舆情发布"
                                ) +
                                "\n          "
                            ),
                            item.disaster !== null &&
                            item.disaster.is_portal !== 0
                              ? _c("span", { staticClass: "round" })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.created_at
                ? _c(
                    "div",
                    {
                      staticClass: "bottom-content-cen flex-view",
                      class: {
                        contentCenVacancy:
                          item.user_push_data === "" &&
                          item.unit_push_data === "",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content-cen-name",
                          class: {
                            contentCenVacancy:
                              item.user_push_data === "" &&
                              item.unit_push_data === "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.ubfoldAlter(index, item.id)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "content-cen-name-box" }, [
                            _vm._v(_vm._s(item.project_name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content-cen-identity",
                          class: {
                            "content-cen-conceal": item.unit_push_data == "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: {
                                title:
                                  "" +
                                  (item.unit_push_data.push_user_name || "无"),
                              },
                            },
                            [
                              _vm._v(
                                "\n            推送人：" +
                                  _vm._s(
                                    item.unit_push_data.push_user_name || "无"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              attrs: {
                                title:
                                  "" +
                                  (item.unit_push_data.push_unit_name || "无"),
                              },
                            },
                            [
                              _vm._v(
                                "\n            推送人单位：" +
                                  _vm._s(
                                    item.unit_push_data.push_unit_name || "无"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.unit_push_data
                            ? _c("div", [
                                _vm._v(
                                  "\n            推送时间：" +
                                    _vm._s(
                                      item.unit_push_data.push_datetime.slice(
                                        0,
                                        19
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送类型：" +
                                _vm._s(item.unit_push_data.push_type)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送进度：" +
                                _vm._s(item.unit_push_data.push_progress)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.unit_push_data.push_unit_qty)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送人员：" +
                                _vm._s(item.unit_push_data.push_user_qty)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content-cen-identity content-cen-company",
                          class: {
                            "content-cen-conceal": item.user_push_data == "",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.user_push_data.push_unit_name)
                            ),
                          ]),
                          _vm._v(" "),
                          item.user_push_data
                            ? _c("div", [
                                _vm._v(
                                  "\n            推送时间：" +
                                    _vm._s(
                                      item.user_push_data.push_datetime.slice(
                                        0,
                                        19
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送类型：" +
                                _vm._s(item.user_push_data.push_type)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送进度：" +
                                _vm._s(item.user_push_data.push_progress)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.user_push_data.push_user_qty)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "margin-right": "6.25%",
                          },
                        },
                        [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title:
                                  "请问该任务是自行推送还是分享给超管推送？",
                                "cancel-button-type": "Primarys",
                                "confirm-button-text": "自行推送",
                                "cancel-button-text": "超管推送",
                              },
                              on: {
                                onCancel: function ($event) {
                                  return _vm.pushTag(item.id, index)
                                },
                              },
                            },
                            [
                              _vm.role === 1 && item.mobile == _vm.user.mobile
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        disabled: item.push_flag != "self",
                                        type: "primary",
                                        plain: "",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("选择")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cursor content-cen-btn",
                          on: {
                            click: function ($event) {
                              return _vm.showPush(item, index, item.id)
                            },
                          },
                        },
                        [_vm._v("\n          概览\n        ")]
                      ),
                      _vm._v(" "),
                      _vm.role !== 2
                        ? _c(
                            "div",
                            {
                              staticClass: "cursor content-cen-btn",
                              style: {
                                color:
                                  item.redo === 0
                                    ? "rgb(187 183 183)"
                                    : "#1890ff",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.reformBtn(item, index, item.id)
                                },
                              },
                            },
                            [_vm._v("\n          重做\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (item.push_flag == "admin" && _vm.role !== 2) ||
                      (item.push_flag == "self" && _vm.role === 1)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "cursor content-cen-btn content-cen-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.taskUnitDelete(item.id)
                                },
                              },
                            },
                            [_vm._v("\n          删除\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.project_files !== "",
                              expression: "item.project_files !== ''",
                            },
                          ],
                          staticClass:
                            "cursor content-cen-btn content-cen-download",
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(item.project_files)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.project_files,
                                download: item.project_files,
                              },
                            },
                            [_vm._v("详细说明")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.unfoldArr[index]
                ? _c(
                    "div",
                    { staticClass: "bottom-content-bot" },
                    _vm._l(item.arr, function (item2, index2) {
                      return _c(
                        "div",
                        { key: index2 + "0" },
                        [
                          _c("div", { staticClass: "content-list-title" }, [
                            _vm._v(_vm._s(item2.block_name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              ref: "filterTable",
                              refInFor: true,
                              staticStyle: { width: "100%" },
                              attrs: { data: item2.task },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "task_name",
                                  label: "任务名称",
                                  sortable: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "id",
                                  label: "任务ID",
                                  sortable: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "终端进度" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", { staticClass: "state" }, [
                                            scope.row.status_name ==
                                            "已推送联盟"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.role === 0
                                                        ? scope.row.status_name
                                                        : "联盟待接单"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.status_name ==
                                            "请接单任务"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.role === 0
                                                        ? scope.row.status_name
                                                        : "终端待接单"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.status_name !=
                                              "已推送联盟" &&
                                            scope.row.status_name !=
                                              "请接单任务"
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      redness:
                                                        scope.row.status_name ==
                                                        "已拒绝",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.status_name
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "终端回传" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "instruct-btn iconfont",
                                            },
                                            [
                                              scope.row.task_data_shp_url !== ""
                                                ? _c("span", {
                                                    staticClass: "shp",
                                                    attrs: { title: "SHP文件" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getShp(
                                                          scope.row
                                                            .task_data_shp_url
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.task_brief_count !== 0
                                                ? _c("span", {
                                                    staticClass: "task",
                                                    attrs: {
                                                      title: "任务简报",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.bulletin(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row
                                                .task_data_result_count !== 0
                                                ? _c("span", {
                                                    staticClass: "original",
                                                    attrs: {
                                                      title: "成果数据回传",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.bacKikc(
                                                          "成果",
                                                          scope.row.id
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row
                                                .task_data_original_count !== 0
                                                ? _c("span", {
                                                    staticClass: "achievement",
                                                    attrs: {
                                                      title: "原始数据回传",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.bacKikc(
                                                          "原始",
                                                          scope.row.id
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "处理操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "order-btn" },
                                            [
                                              _vm.role !== 2 &&
                                              (item.push_flag == "admin" ||
                                                (item.push_flag == "self" &&
                                                  _vm.role === 1))
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        plain: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.orderOrpush(
                                                            item,
                                                            index,
                                                            item.id,
                                                            index2,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.role != 0 &&
                                                              item.unit_push_data !=
                                                                ""
                                                              ? scope.row
                                                                  .status_name ===
                                                                  "已创建，待推送" ||
                                                                scope.row
                                                                  .status_name ===
                                                                  "已推送联盟"
                                                                ? "接单"
                                                                : "推送"
                                                              : "推送"
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.role === 2 &&
                                              scope.row.status_name ===
                                                "请接单任务"
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        plain: "",
                                                      },
                                                    },
                                                    [_vm._v("接单")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "tag",
                                  label: "任务操作",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "postback-btn" },
                                            [
                                              (item.push_flag == "admin" &&
                                                _vm.role !== 2) ||
                                              (item.push_flag == "self" &&
                                                _vm.role === 1)
                                                ? _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toPush(
                                                            item,
                                                            index,
                                                            item.id,
                                                            index2,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    详情\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    cplacement: "top",
                                                    width: "150",
                                                  },
                                                },
                                                [
                                                  _vm.taskPopNum !== -1
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm.role !== 2
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "elButton",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "10px",
                                                                  },
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.evaluate(
                                                                          item.project_name,
                                                                          item2.block_name,
                                                                          scope.row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "评分评价"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "elButton",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.postBack(
                                                                      "原始",
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "回传原始数据"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "elButton",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.postBack(
                                                                      "成果",
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "回传成果数据"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.push_flag == "admin" ||
                                                  (item.push_flag == "self" &&
                                                    _vm.role === 1)
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticClass: "more",
                                                          attrs: {
                                                            slot: "reference",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              _vm.taskPopNum =
                                                                scope.row.id
                                                            },
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [_vm._v("更多")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "简报列表",
                visible: _vm.bulletinVisible,
                width: "40.1%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.bulletinVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: { data: _vm.bulletinListArr },
                  on: { "row-click": _vm.listRow },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "brief_title", label: "简报标题" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { property: "project_type", label: "任务类型" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { property: "execute_progress", label: "进度" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { property: "disaster_level", label: "灾情态势" },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    { attrs: { label: "操作" } },
                    [
                      [
                        _c("el-button", { attrs: { size: "mini" } }, [
                          _vm._v("前往"),
                        ]),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "简报详情",
                visible: _vm.bulletinDetailsVisible,
                width: "35.5vw",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.bulletinDetailsVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "bulletin-details" }, [
                _c("div", [
                  _c("span", [_vm._v("简报标题:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.brief_title) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [_vm._v("任务ID:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.id) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [_vm._v("任务下达类型:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.project_type) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [_vm._v("任务执行进度:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.execute_progress) +
                      "%\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [_vm._v("灾情态势:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.disaster_level) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "100%" } }, [
                  _c("span", [_vm._v("灾情描述:")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.bulletinDetailsData.disaster_description) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "img", staticStyle: { width: "100%" } },
                  [
                    _c("span", [_vm._v("灾情测区快照")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: { src: _vm.bulletinDetailsData.file_1, alt: "" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "img", staticStyle: { width: "100%" } },
                  [
                    _c("span", [_vm._v("灾情现场快照")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: { src: _vm.bulletinDetailsData.file_2, alt: "" },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("dataBackhaul", {
            ref: "dataBackhaul",
            on: { updateFlying: _vm.updateFlying, toPostBack: _vm.toPostBack },
          }),
          _vm._v(" "),
          _c("evaluate", { ref: "evaluate" }),
          _vm._v(" "),
          _vm.publicObj.id
            ? _c("publicSen", {
                ref: "public",
                attrs: { obj: _vm.publicObj },
                on: { updateFlying: _vm.updateFlying },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editPublicObj.id
            ? _c("editPublicOpinion", {
                ref: "editPublic",
                attrs: { obj: _vm.editPublicObj },
                on: { updateFlying: _vm.updateFlying },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "bottom-el-pagination",
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "current-page": _vm.pageIndex,
              total: _vm.sumPage,
            },
            on: { "current-change": _vm.currentChange },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("newFmTask", { ref: "newFmTask" }),
      _vm._v(" "),
      _vm.newTaskNum
        ? _c("newTaskPop", { on: { newFmPop: _vm.newFmPop } })
        : _vm._e(),
      _vm._v(" "),
      _vm.postbackShow
        ? _c("postback", {
            attrs: { obj: _vm.postbackObj },
            on: { postbackCancel: _vm.postbackCancel },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.postbackListShow
        ? _c("postbackList", {
            attrs: { obj: _vm.postbackListObj },
            on: { postbackListCancel: _vm.postbackListCancel },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isTuBan
        ? _c(
            "el-dialog",
            {
              staticStyle: { "min-width": "1080px" },
              attrs: {
                title: "图斑概览",
                visible: _vm.isTuBan,
                width: "80.1%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isTuBan = $event
                },
              },
            },
            [_c("tuban", { attrs: { blockItem: _vm.blockItem } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }