var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "appBar" }, [
      _c("div", { staticClass: "bodyBar" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "center" },
          _vm._l(_vm.noticeList, function (item, index) {
            return _c("div", { key: index, staticClass: "content" }, [
              item.type == 4
                ? _c("div", { staticClass: "content-box" }, [
                    item.data_apply.status == 1
                      ? _c("div", [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("有编号为："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("的数据申请待审核"),
                          ]),
                        ])
                      : item.data_apply.status == 2 ||
                        item.data_apply.status == 3
                      ? _c("div", [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("您的数据申请："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("审核结果为："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dataApplyStatus")(
                                  item.data_apply.status
                                )
                              )
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("编号为："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("的数据申请，"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dataApplyStatus")(
                                  item.data_apply.status
                                )
                              )
                            ),
                          ]),
                        ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.type == 5
                ? _c("div", { staticClass: "content-box" }, [
                    item.warning.status == 0
                      ? _c("div", [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("有新的预警信息待审核："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.warning.area_name))]
                          ),
                        ])
                      : _c("div", [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("您的预警信息："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.warning.area_name))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v("审核结果为："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "cont" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("warningStatus")(item.warning.status)
                              )
                            ),
                          ]),
                        ]),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.current_page,
                "page-size": this.per_page,
                layout: "total,  prev, pager, next, jumper",
                total: this.total,
              },
              on: {
                "current-change": _vm.currentchange,
                "next-click": _vm.handleNext,
                "prev-click": _vm.handlePrev,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "signBar" }),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("消息中心")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }