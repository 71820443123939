var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "informationNav" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "inforBody" }, [
        _c(
          "div",
          { staticClass: "leftBar" },
          [
            _c(
              "el-form",
              { ref: "ruleForm", attrs: { "label-width": "100px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { prop: "username", label: "账户名称" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "alliInput",
                      attrs: {
                        placeholder: "请输入",
                        "suffix-icon": "iconfont iconsousuo-copy",
                      },
                      model: {
                        value: _vm.ruleForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "username", $$v)
                        },
                        expression: "ruleForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { prop: "profile", label: "个人简介" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "alliInput",
                      attrs: {
                        placeholder: "个人简介",
                        type: "textarea",
                        rows: 8,
                      },
                      model: {
                        value: _vm.ruleForm.profile,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "profile", $$v)
                        },
                        expression: "ruleForm.profile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "local",
                attrs: { type: "primary" },
                on: { click: _vm.saveBtn },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rightBar" },
          [
            _c(
              "el-form",
              { ref: "ruleForm", attrs: { "label-width": "100px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { prop: "username", label: "用户头像" },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: "aaa",
                          accept: ".jpg, .jpeg, .png",
                          "show-file-list": false,
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-change": _vm.avatarUpload,
                        },
                      },
                      [
                        _vm.imageUrl
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.imageUrl },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }