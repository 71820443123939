var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "releaseTask" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            { name: "dialogDrag", rawName: "v-dialogDrag" },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            visible: _vm.releaseShow,
            width: "382px",
            "before-close": _vm.handleClose,
            "destroy-on-close": true,
            modal: false,
            "element-loading-text": "上传中",
          },
          on: {
            "update:visible": function ($event) {
              _vm.releaseShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "releaseBody" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.changeTest },
                  model: {
                    value: _vm.labelSelect,
                    callback: function ($$v) {
                      _vm.labelSelect = $$v
                    },
                    expression: "labelSelect",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "releasePeople" } }, [
                    _vm._v("发布人"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-button",
                    { attrs: { label: "taskDescription" } },
                    [_vm._v("任务描述")]
                  ),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "taskTest" } }, [
                    _vm._v("任务面积"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "supplement" } }, [
                    _vm._v("补充说明"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { margin: "15px" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "formBody" },
                [
                  _c(
                    "table",
                    { staticClass: "fixedVal", attrs: { id: "releasePeople" } },
                    [
                      _c("tr", [
                        _c("td", [_vm._v("账户")]),
                        _vm._v(" "),
                        _c("td", { attrs: { title: _vm.userInfo.mobile } }, [
                          _vm._v(_vm._s(_vm.userInfo.mobile)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("账户类型")]),
                        _vm._v(" "),
                        _c("td", { attrs: { title: _vm.userInfo.role_name } }, [
                          _vm._v(_vm._s(_vm.userInfo.role_name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("单位")]),
                        _vm._v(" "),
                        _c("td", { attrs: { title: _vm.unit_name } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.userInfo.unit_name) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("账户名称")]),
                        _vm._v(" "),
                        _c("td", { attrs: { title: _vm.userInfo.name } }, [
                          _vm._v(_vm._s(_vm.userInfo.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.title === "发布任务"
                        ? _c("tr", [
                            _c("td", [_vm._v("*发布方式")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "一键发布" },
                                    on: { change: _vm.selectPublishType },
                                    model: {
                                      value: _vm.publishType,
                                      callback: function ($$v) {
                                        _vm.publishType = $$v
                                      },
                                      expression: "publishType",
                                    },
                                  },
                                  [_vm._v("一键发布")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "详细发布" },
                                    on: { change: _vm.selectPublishType },
                                    model: {
                                      value: _vm.publishType,
                                      callback: function ($$v) {
                                        _vm.publishType = $$v
                                      },
                                      expression: "publishType",
                                    },
                                  },
                                  [_vm._v("详细发布")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.detailDescription,
                        rules: _vm.rules,
                        "label-width": "98px",
                        "label-position": "left",
                        id: _vm.taskDescription,
                      },
                    },
                    [
                      _vm.publishType === "详细发布"
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务类型",
                                    prop: "task_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "无人机" },
                                      model: {
                                        value: _vm.taskType,
                                        callback: function ($$v) {
                                          _vm.taskType = $$v
                                        },
                                        expression: "taskType",
                                      },
                                    },
                                    [_vm._v("无人机")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.publishType === "一键发布",
                                          expression:
                                            "publishType==='一键发布'",
                                        },
                                      ],
                                      attrs: { label: "非无人机" },
                                      model: {
                                        value: _vm.taskType,
                                        callback: function ($$v) {
                                          _vm.taskType = $$v
                                        },
                                        expression: "taskType",
                                      },
                                    },
                                    [_vm._v("非无人机")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务名称",
                                    prop: "task_name",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.detailDescription.task_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailDescription,
                                              "task_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailDescription.task_name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "授权空域",
                                    prop: "auth_area",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "有" },
                                      model: {
                                        value: _vm.detailDescription.auth_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "auth_area",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.auth_area",
                                      },
                                    },
                                    [_vm._v("有")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "无" },
                                      model: {
                                        value: _vm.detailDescription.auth_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "auth_area",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.auth_area",
                                      },
                                    },
                                    [_vm._v("无")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "申请中" },
                                      model: {
                                        value: _vm.detailDescription.auth_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "auth_area",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.auth_area",
                                      },
                                    },
                                    [_vm._v("申请中")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "传感器",
                                    prop: "sensor_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.selectSensorType },
                                      model: {
                                        value:
                                          _vm.detailDescription.sensor_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "sensor_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.sensor_type",
                                      },
                                    },
                                    _vm._l(_vm.sensor_type, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.detailDescription.sensor_type.indexOf(
                                          "LIDAR"
                                        ) === -1,
                                      expression:
                                        "detailDescription.sensor_type.indexOf('LIDAR') === -1",
                                    },
                                  ],
                                  attrs: {
                                    label: "影像GSD",
                                    prop: "data_requirements",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.sensors[
                                      _vm.detailDescription.sensor_type
                                    ]["gsdTypes"],
                                    function (item, index) {
                                      return _c(
                                        "span",
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: item.value,
                                                value: item.checked,
                                              },
                                              on: {
                                                change:
                                                  _vm.radioSelectDataRequirements,
                                              },
                                              model: {
                                                value:
                                                  _vm.detailDescription
                                                    .data_requirements,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detailDescription,
                                                    "data_requirements",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detailDescription.data_requirements",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "otherVal" },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "otherInput",
                                          attrs: { placeholder: "其他" },
                                          on: {
                                            change: _vm.changeData_requirements,
                                          },
                                          model: {
                                            value: _vm.data_requirements,
                                            callback: function ($$v) {
                                              _vm.data_requirements = $$v
                                            },
                                            expression: "data_requirements",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_vm._v("cm")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.detailDescription.sensor_type.indexOf(
                                          "LIDAR"
                                        ) === -1,
                                      expression:
                                        "detailDescription.sensor_type.indexOf('LIDAR') === -1",
                                    },
                                  ],
                                  attrs: {
                                    label: "航向重叠度",
                                    prop: "longitudinal_overlap",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          model: {
                                            value:
                                              _vm.detailDescription
                                                .longitudinal_overlap,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailDescription,
                                                "longitudinal_overlap",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailDescription.longitudinal_overlap",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    %\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.detailDescription.sensor_type.indexOf(
                                          "LIDAR"
                                        ) !== -1,
                                      expression:
                                        "detailDescription.sensor_type.indexOf('LIDAR') !== -1",
                                    },
                                  ],
                                  attrs: { label: "点密度", prop: "pts" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { placeholder: "请输入" },
                                          model: {
                                            value: _vm.detailDescription.pts,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailDescription,
                                                "pts",
                                                $$v
                                              )
                                            },
                                            expression: "detailDescription.pts",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_vm._v("pts/m²")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.detailDescription.sensor_type ===
                                        "LIDAR+正射",
                                      expression:
                                        "detailDescription.sensor_type === 'LIDAR+正射'",
                                    },
                                  ],
                                  attrs: { label: "影像GSD" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请输入",
                                            type: "number",
                                          },
                                          model: {
                                            value:
                                              _vm.detailDescription
                                                .data_requirements,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailDescription,
                                                "data_requirements",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailDescription.data_requirements",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_vm._v("cm")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "旁向重叠度",
                                    prop: "lateral_overlap",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          model: {
                                            value:
                                              _vm.detailDescription
                                                .lateral_overlap,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailDescription,
                                                "lateral_overlap",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailDescription.lateral_overlap",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "arrowUpAndDownAll",
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_c("div", [_vm._v("%")])]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.detailDescription.sensor_type ===
                                        "LIDAR+正射",
                                      expression:
                                        "detailDescription.sensor_type === 'LIDAR+正射'",
                                    },
                                  ],
                                  attrs: { label: "航向重叠度" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请输入",
                                            type: "number",
                                          },
                                          model: {
                                            value:
                                              _vm.detailDescription
                                                .longitudinal_overlap,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailDescription,
                                                "longitudinal_overlap",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailDescription.longitudinal_overlap",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "suffix" },
                                              slot: "suffix",
                                            },
                                            [_vm._v("%")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "成果类型",
                                    prop: "result_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        multiple: "",
                                        "collapse-tags": "",
                                      },
                                      model: {
                                        value:
                                          _vm.detailDescription.result_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "result_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.result_type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.sensors[
                                        _vm.detailDescription.sensor_type
                                      ]["resultTypes"],
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "成果精度",
                                    prop: "result_precision",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1:500" },
                                      on: { change: _vm.oneSelectPrecision },
                                      model: {
                                        value:
                                          _vm.detailDescription
                                            .result_precision,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "result_precision",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.result_precision",
                                      },
                                    },
                                    [_vm._v("1:500")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1:1000" },
                                      on: { change: _vm.oneSelectPrecision },
                                      model: {
                                        value:
                                          _vm.detailDescription
                                            .result_precision,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "result_precision",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.result_precision",
                                      },
                                    },
                                    [_vm._v("1:1000")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1:2000" },
                                      on: { change: _vm.oneSelectPrecision },
                                      model: {
                                        value:
                                          _vm.detailDescription
                                            .result_precision,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailDescription,
                                            "result_precision",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailDescription.result_precision",
                                      },
                                    },
                                    [_vm._v("1:2000")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "otherVal" },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "其他" },
                                        on: {
                                          change: _vm.changeResultPrecision,
                                        },
                                        model: {
                                          value: _vm.result_precision,
                                          callback: function ($$v) {
                                            _vm.result_precision = $$v
                                          },
                                          expression: "result_precision",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "截止工期",
                                    prop: "end_date",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "otherVal",
                                      staticStyle: {
                                        display: "inline-block",
                                        margin: "0",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          "value-format": "yyyy-MM-dd",
                                          type: "date",
                                          placeholder: "截止日期",
                                        },
                                        model: {
                                          value: _vm.detailDescription.end_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailDescription,
                                              "end_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailDescription.end_date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            { attrs: { id: "aKey" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务类型",
                                    prop: "task_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "无人机" },
                                      model: {
                                        value: _vm.aKeyDescription.task_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.aKeyDescription,
                                            "task_type",
                                            $$v
                                          )
                                        },
                                        expression: "aKeyDescription.task_type",
                                      },
                                    },
                                    [_vm._v("无人机")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "非无人机" },
                                      model: {
                                        value: _vm.aKeyDescription.task_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.aKeyDescription,
                                            "task_type",
                                            $$v
                                          )
                                        },
                                        expression: "aKeyDescription.task_type",
                                      },
                                    },
                                    [_vm._v("非无人机")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务描述",
                                    prop: "description",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "62%",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 3,
                                          placeholder: "文本描述",
                                        },
                                        model: {
                                          value:
                                            _vm.aKeyDescription.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.aKeyDescription,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "aKeyDescription.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                staticStyle: { "margin-top": "14px" },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { id: "taskTest" } },
                    [
                      _vm.title === "发布任务"
                        ? _c("table", { staticClass: "fixedVal" }, [
                            _c("tr", [
                              _c("td", [_vm._v("任务面积")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v("\n                共"),
                                _c("span", { staticClass: "measurementArea" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.listDrawShapeData[2].length +
                                        _vm.kmlShapeCount
                                    )
                                  ),
                                ]),
                                _vm._v("个区块，累计面积"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "measurementArea",
                                    attrs: { title: _vm.totalArea + "Km²" },
                                  },
                                  [_vm._v(_vm._s(_vm.totalArea))]
                                ),
                                _vm._v("km²\n              "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.selectShape, function (item, key, index) {
                        return _c("span", { key: key }, [
                          _c("div", { staticClass: "testArea" }, [
                            _c("div", [_vm._v("任务" + _vm._s(index + 1))]),
                            _vm._v(" "),
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/homeDialog/taskTestArea.png"),
                                  alt: "",
                                  width: "12px",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "area" }, [
                                _vm._v(_vm._s(item.area) + "Km²"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "location" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/centerHome/onlinetask.png"),
                                alt: "",
                                width: "14px",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.location))]),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("table", { staticClass: "fixedVal" }, [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: { position: "relative", top: "-29px" },
                          },
                          [_vm._v("补充说明")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                placeholder: "文本描述",
                              },
                              model: {
                                value: _vm.applyForm.additional_remarks,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.applyForm,
                                    "additional_remarks",
                                    $$v
                                  )
                                },
                                expression: "applyForm.additional_remarks",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "fixedVal", attrs: { id: "supplement" } },
                      [
                        _c("tr", [
                          _c("td", [_vm._v("附件")]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "files" },
                              _vm._l(_vm.fileList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "file",
                                    class: {
                                      canLoad: _vm.title === "任务详情",
                                    },
                                  },
                                  [
                                    item.name
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.name) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.source_name) +
                                              "\n                    "
                                          ),
                                        ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td"),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "aaaa",
                                    accept: "All",
                                    "show-file-list": false,
                                    "file-list": _vm.fileList,
                                    "on-change": _vm.FileUpload,
                                    "auto-upload": false,
                                    "on-remove": _vm.handleRemove,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "uploadBtn",
                                      attrs: { slot: "trigger" },
                                      slot: "trigger",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/img/upload.png"),
                                          alt: "",
                                          width: "20",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                    上传\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.title === "发布任务"
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.canPost },
                      on: { click: _vm.saveDraft },
                    },
                    [_vm._v("保存草稿")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.canPost, type: "primary" },
                      on: { click: _vm.saveOrder },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.canPost },
                      on: { click: _vm.saveAkeyTask },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.promptDia, width: "382px" },
          on: {
            "update:visible": function ($event) {
              _vm.promptDia = $event
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: {
              color: "#faad14",
              "font-size": "21px",
              "margin-left": "15px",
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("请对该任务进行派单。")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("外部协作")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("内部派单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("中止")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }