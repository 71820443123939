<template>
  <!-- 手机号登录 -->
  <div class="centerWindow">
    <div class="loginBgc">
      <div class="fmWelcome">
        <div class="fmWelLogin">
          <p class="sayHello">您好,</p>
          <p class="welLogin">欢迎登录</p>
          <p class="fmsider" />
          <p class="fmTitle">智慧田长管理平台</p>
          <!-- <p class="sonTitle">调度中心端</p> -->
        </div>
        <div class="interlinkage interlinkage-place">
        <el-popover placement="right" width="120px" trigger="hover">
          <vue-qr
            class="qr"
            :size="120"
            :margin="0"
            :auto-color="true"
            :dot-scale="1"
            :text="htmlUrl"
          />
          <div
            slot="reference"
            @click="toDownload('app')"
            style="margin-bottom: 20px"
            class="showQr"
          >
            下载【飞享终端】APP
          </div>
        </el-popover>
        <div @click="toDownload('DataManager')">下载【DataManager】工具</div>
      </div>
      </div>
      <div class="fmLoginNav">
        <div class="loginNav">
          <div class="loginBox">
            <p class="loginTitle">登录账号</p>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="100px"
            >
              <el-form-item prop="mobile" class="demo-ruleForm">
                <el-input
                  v-model="ruleForm.mobile"
                  placeholder="请输入手机号"
                />
              </el-form-item>
              <el-form-item prop="password" class="demo-ruleForm">
                <el-input
                  v-model="ruleForm.password"
                  placeholder="请输入密码"
                  type="password"
                />
              </el-form-item>

              <el-form-item class="SignInBar">
                <el-checkbox v-model="agreeStatement"
                  ><span style="color: rgb(96, 98, 102)"
                    >我已同意并认真阅读</span
                  ><a href="#" @click="lookStatement" style="color: #5185ff"
                    >《免责声明》</a
                  ></el-checkbox
                >
                <el-button
                  :loading="loading"
                  class="loginBar"
                  type="primary"
                  @click="userLogin"
                  :disabled="!agreeStatement"
                  >登&nbsp;&nbsp;录</el-button
                >
              </el-form-item>
              <el-form-item class="demo-ruleForm">
                <div class="otherLogin">
                  <div class="leftBoder" />
                  <el-link class="FMword" :underline="false" @click="otherLogin"
                    >联盟代码登录</el-link
                  >
                  <div class="rightBoder" />
                </div>
              </el-form-item>
            </el-form>
            <div class="footerBar">
              <el-link class="register" :underline="false" @click="registerNum"
                >注册账号</el-link
              >
              <div class="dividing" />
              <el-link class="forgetPsw" :underline="false" @click="forgetPsw"
                >忘记密码</el-link
              >
            </div>
          </div>

          <div class="versions">
            <div>调度中心{{ versions }}</div>
          </div>
        </div>
      </div>


    </div>
    <el-dialog
      title="免责声明"
      :visible.sync="disStatemenetData"
      width="302px"
      center
      :show-close="false"
    >
      <disclaimer :show="disStatemenetData" />
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="disStatemenetData = false;agreeStatement = false">取 消</el-button> -->
        <el-button type="primary" @click="disStatemenetData = false;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import disclaimer from "@/components/statemenet/disclaimer";
window.VUE_APP_VERSION = process.env.VUE_APP_VERSION;
window.VUE_APP_BUILD_TIME = process.env.VUE_APP_BUILD_TIME;
// let appUrl = "";
// let dataManagerUrl = "";
export default {
  data() {
    return {
      htmlUrl: "",
      appUrl: "",
      dataManagerUrl: "",
      versions: "1.0.1",
      otherQuery: {},
      redirect: undefined,
      loading: false,
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {
        mobile: [
          { required: true, message: "该手机号不存在", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "最小六位", trigger: "blur" },
        ],
      },
      agreeStatement: false, //同意免责声明
      disStatemenetData: false, //免责声明弹窗
    };
  },
  components: {
    disclaimer,
  },
  mounted() {
    this.upgradeApp("app", false);

  },
  methods: {
    ...mapMutations(["userStorage", "tokenStorage"]),
    /**
     * 查看免责声明
     */
    lookStatement() {
      this.disStatemenetData = true;
    },
    /**
     * 下载
     */
    toDownload(name) {
      switch (name) {
        case "DataManager":
          this.dataManagerUrl === ""
            ? this.upgradeApp("data", true)
            : window.open(this.dataManagerUrl);
          break;
        case "app":
          this.appUrl === ""
            ? this.upgradeApp("app", true)
            : window.open(this.appUrl);
          break;
        default:
          break;
      }
    },
    /**
     * 获取app的下载链接。
     */
    upgradeApp(category, open = false) {
      this.$apis
        .upgrade(
          {
            category: category,
            version_int: "0.0.1",
          },
          this.token
        )
        .then((data) => {
          category === "app"
            ? (this.appUrl = data.data.data.file_url)
            : (this.dataManagerUrl = data.data.data.file_url);
          if (open) {
            window.open(data.data.data.file_url);
          }
          let ishttps = document.location.protocol
          this.htmlUrl = ishttps+"//"+window.location.host + "/downApp.html"
          console.log(this.htmlUrl);
        })

        .catch((err) => {
          console.error(err);
        });
    },
    // 注册账号按钮
    registerNum() {
      this.$router.push("/register");
    },
    // 登录按钮
    userLogin() {
      // validate：验证当前表单元素中所有的规则
      if (this.agreeStatement) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.submitData();
          } else {
            this.loading = false;
          }
        });
      }
    },
    submitData() {
      this.loading = true;
      this.$apis
        .login({
          mobile: this.ruleForm.mobile,
          password: this.ruleForm.password,
          register_channel: "手机号注册",
        })
        .then((data) => {
          this.loading = false;
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
              offset: 80,
            });
            this.tokenStorage(data.data.data.token);
            localStorage.setItem("TokenKeyToUser", data.data.data.token);
            this.$apis.info({}, this.token).then((userData) => {
              localStorage.setItem(
                "userInfo",
                JSON.stringify(userData.data.data)
              );
              this.userStorage(userData.data.data);
            });
            this.$router.push("/home");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 联盟代码登录
    otherLogin() {
      this.$router.push("/otherlogin");
    },
    // 忘记密码
    forgetPsw() {
      this.$router.push("/forgetPsw");
    },
  },
  created() {
    // enter事件
    const that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      // 验证在登录界面和按得键是回车键enter
      if (that.$route.path == "/login" && e.keyCode == 13) {
        that.userLogin(); // 登录函数
      }
    };
    this.versions = process.env.VUE_APP_VERSION;
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.centerWindow {
  width: 100%;
  height: 100%;
  overflow: auto;
  .loginBgc {
    min-height: 850px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("../../assets/img/bgc-login.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    .fmWelcome {
      display: block;
      // float: left;
      margin: 0 auto;
      padding-top: 25vh;
      margin-left: 6%;
      .sayHello {
        width: 120px;
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 52px;
      }
      .welLogin {
        width: 160px;
        height: 52px;
        line-height: 82px;
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
      }
      .fmsider {
        width: 166px;
        height: 4px;
        background: rgba(255, 255, 255, 0.43);
        margin-top: 59px;
      }
      .fmTitle {
        width: 850px;
        height: 74px;
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 74px;
        margin-top: 51px;
      }
      .sonTitle {
        width: 270px;
        height: 64px;
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 64px;
        margin-top: 53px;
      }
    }
  }
  .fmLoginNav {
    padding: 68px 68px 0px 0px;
    .loginNav {
      width: 615px;
      min-height: 746px;
      height: calc(100vh - 136px);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      // float: right;
      padding: 167px 164px 147px 125px;
      position: relative;
      .loginBox {
        width: 100%;
        height: 100%;
        .loginTitle {
          // width: 128px;
          height: 42px;
          font-size: 32px;
          color: rgba(59, 66, 83, 1);
          line-height: 42px;
        }
        .demo-ruleForm {
          margin-top: 37px;
          width: 370px;
        }
        .SignInBar {
          margin-top: 80px;
          .loginBar {
            font-size: 20px;
            width: 370px;
            height: 55px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.otherLogin {
  display: block;
  width: 370px;
}
.FMword {
  color: #0094ff !important;
  float: left;
  text-align: center;
  padding: 0px 20px 0 20px;
  margin-top: -18px;
  cursor: pointer;
}
.register {
  cursor: pointer;
}
.forgetPsw {
  cursor: pointer;
}
.leftBoder {
  width: 115px;
  height: 2px;
  background-color: #8099bb;
  float: left;
}
.rightBoder {
  float: left;
  width: 123px;
  height: 2px;
  background-color: #8099bb;
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}

.interlinkage {
  // position: fixed;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  // bottom: 80px;
  width: 200px;
  margin-top: 5%;
}


/deep/ .el-input__inner {
  border-radius: 8px;
  height: 44px;
  border: 2px solid rgba(166, 170, 184, 1);
  background-color: rgba(255, 255, 255, 0.3);
}
/deep/.el-divider__text {
  background-color: transparent;
}
@media screen and (min-width: 1650px) and (max-width: 1920px) {
}
@media only screen and (max-width: 1650px) {
  .interlinkage-place {
    left: 6%;
  }
  .centerWindow {
    width: 100%;
    height: 100%;
    // min-width: 1280px;
    // min-height: 600px;

    .loginBgc {
      min-width: 1280px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: url("../../assets/img/bgc-login.png") no-repeat center;
      background-size: 100% 100%;
      .fmWelcome {
        display: block;
        // position: absolute;
        // bottom: 18%;
        margin-left: 6%;
        .sayHello {
          width: 120px;
          height: 52px;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 1);
          line-height: 52px;
        }
        .welLogin {
          width: 160px;
          height: 52px;
          line-height: 82px;
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .fmsider {
          width: 166px;
          height: 4px;
          background: rgba(255, 255, 255, 0.43);
          margin-top: 59px;
        }
        .fmTitle {
          width: 100%;
          height: 74px;
          font-size: 36px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 74px;
          margin-top: 51px;
        }
        .sonTitle {
          width: 270px;
          height: 64px;
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 64px;
          margin-top: 53px;
        }
      }
    }
    .fmLoginNav {
      display: block;
      bottom: 10%;
      margin-top: 10%;
      // position: absolute;
      // right: 1%;
      .loginNav {
        width: 480px;
        height: 600px;
        min-height: 500px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        // float: right;
        padding: 45px 45px 45px 55px;
        .loginBox {
          width: 100%;
          height: 100%;
          .loginTitle {
            // width: 128px;
            height: 42px;
            font-size: 32px;
            color: rgba(59, 66, 83, 1);
            line-height: 42px;
          }
          .demo-ruleForm {
            margin-top: 37px;
            width: 370px;
          }
          .SignInBar {
            margin-top: 80px;
            .loginBar {
              font-size: 20px;
              width: 370px;
              height: 55px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  .footerBar {
    width: 370px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
  }
}

.versions {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #606266;
  // opacity: 0.7;
  font-size: 16px;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-dialog__footer {
  padding-top: 0;
}
</style>
