
<script>
import defaultConstants from "@/utils/default-constants";
export default {
  getResultType(type) {
    type = type.toUpperCase();
    switch (type) {
      case "VECTOR":
        return 1;
      case "TILESET":
        return 2;
      case "IMAGERY":
        return 3;
      case "TERRAIN":
        return 4;
      case "POI":
        return 5;
      case "MODEL":
        return 6;
      default:
        return 0;
    }
  },
  // 获取服务框架
  getMessageService() {
    return window.iWindow.MessageService;
  },
  //添加实体
  addEntity(param) {
    return new Promise((resolve, reject) => {
      let messageService = this.getMessageService();
      let entity = messageService.getEntity(param.id);

      if (entity) {
        // do nothing
        messageService.setMountId(param.id);
        resolve(entity);
      } else {
        if (param.type == 1) {
          //geojson
          var httpRequest = new XMLHttpRequest();
          httpRequest.open("GET", param.data.url, true);
          httpRequest.send();
          httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
              let json = JSON.parse(httpRequest.responseText); //获取到json字符串，还需解析
              let geojson = {
                geometryType: json.features[0].geometry.type,
                coordinates: json.features[0].geometry.coordinates,
              };
              messageService
                .addEntity({
                  id: param.id,
                  type: 1,
                  data: geojson,
                })
                .then((data) => {
                  messageService.setMountId(param.id);
                  resolve(data);
                });
            }
          };
        } else if (param.type == 6) {
          //gtil

          messageService
            .addEntity({
              id: param.id,
              type: 6,
              data: {
                url: param.data.url,
                coordinate: defaultConstants.mapDefaultLocation,
              },
            })
            .then((entity) => {
              entity.locate();
              entity.show(true);
            });
        } else {
          messageService.addEntity(param).then((data) => {
            messageService.setMountId(param.id);
            resolve(data);
          });
        }
      }
    });
  },
  getUrlData(itemData) {
    var httpRequest = new XMLHttpRequest();
    httpRequest.open("GET", itemData.data.url, false);
    httpRequest.send();
    let json = JSON.parse(httpRequest.responseText.trim()); //获取到json字符串，还需解析
    let geojson = {
      geometryType: json.features[0].geometry.type,
      coordinates: json.features[0].geometry.coordinates,
    };
    return { id: itemData.id, type: 1, data: geojson };
  },
  // 获取实体的类型数据
  getEntityTypeData(item) {
    let typeNum = this.getResultType(item.type);
    let itemData = {
      id: item.id, //成果id
      type: typeNum, //EntityType
      data: {
        url: item.url,
      },
    };
    if (itemData.type == 1) {
      //geojson
      let res = this.getUrlData(itemData);
      // console.log(res);
      return res;
    } else if (item.type == 6) {
      return {
        id: item.id,
        type: 6,
        data: {
          url: itemData.data.url,
          coordinate: defaultConstants.mapDefaultLocation,
        },
      };
    } else {
      return itemData;
    }
  },
  getEntityListData(params) {
    let listEntityData = [];
    let messageService = this.getMessageService();
    params.forEach((item) => {
      let res = this.getEntityTypeData(item);
      console.log(res);
      listEntityData.push(res);
    });
    return listEntityData;
  },
  // 添加多个实体
  addListEntity(params) {
    return new Promise((resolve, reject) => {
      let listEntityData = [];
      let messageService = this.getMessageService();
      listEntityData = this.getEntityListData(params);
      messageService.addMultiEntity(listEntityData).then((entity) => {
        // entity.locate();
        entity.show(true);
        console.log("加载完成", entity);
      });
    });
  },
  // 获取成果实体
  getEntity(entityId) {
    let messageService = this.getMessageService();
    if (messageService) {
      let entity = messageService.getEntity(entityId);
      if (entity) return entity;
    }
    return false;
  },
  // 获取poi
  getPoiById(entityId, poiId) {
    let messageService = this.getMessageService();
    if (messageService) {
      let entity = messageService.getEntity(entityId);
      if (entity) {
        let poi = entity.getPoiById(poiId);
        if (poi) return poi;
      }
    }
    return false;
  },
  // 添加poi信息到地球上
  addPoi2Earth(element) {
    let poiData = {
      id: element.id,
      name: element.name,
      type: element.type,
      coordanite: [element.longitude, element.latitude, element.altitude],
      description: element.desc,
      data_result_id: element.data_result_id,
    };
    let poiItem = this.getPoiById(poiData.data_result_id, poiData.id);

    if (poiItem) {
      return poiItem;
      // do nothing
    } else {
      let entity = this.getEntity(poiData.data_result_id);
      if (entity) {
        let poi = entity.addPoi(poiData, poiData.id);
        if (poi) poi.show(false);
        return poi;
      }
    }
    return false;
  },
};
</script>
