var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            modal: false,
            "show-close": true,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "372px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "topBar" }, [
            _c("div", { staticClass: "headerImg" }, [
              _c("img", {
                staticClass: "headImg",
                attrs: { src: _vm.avatarUrl, alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "userInfo" }, [
                _c("span", { staticClass: "username" }, [
                  _vm._v(_vm._s(this.detailObj.name)),
                ]),
                _vm._v(" "),
                this.detailObj.is_admin == 1
                  ? _c("i", { staticClass: "iconfont icon-VIP" }, [_vm._v("")])
                  : _vm._e(),
                _vm._v(" "),
                this.detailObj.is_admin == 0 && this.detailObj.is_unit == 1
                  ? _c("i", { staticClass: "iconfont icon-pre" }, [_vm._v("")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              this.detailObj.is_admin == 1
                ? _c("div", { staticClass: "userBar" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.overpipe) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.detailObj.is_admin == 0 && this.detailObj.is_unit == 1
                ? _c("div", { staticClass: "userBarOne" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.unionpipe) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.detailObj.is_admin == 0 && this.detailObj.is_unit == 0
                ? _c("div", { staticClass: "userBarTwo" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.terminaluser) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "user" }, [
                _c("span", { staticClass: "userTit" }, [_vm._v("账号")]),
                _vm._v(" "),
                _c("span", { staticClass: "userNum" }, [
                  _vm._v(_vm._s(this.detailObj.mobile)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user" }, [
                _c("span", { staticClass: "userTit" }, [_vm._v("账户类型")]),
                _vm._v(" "),
                _c("span", { staticClass: "userNum" }, [
                  _vm._v(_vm._s(this.detailObj.register_channel)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user" }, [
                _c("span", { staticClass: "userTit" }, [_vm._v("联盟单位")]),
                _vm._v(" "),
                _c("span", { staticClass: "userLocal userNum" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.detailList.unit_name) +
                      " (" +
                      _vm._s(this.detailList.unit_code) +
                      ")\n          "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }