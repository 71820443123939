var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-top" }, [
    _c("div", { staticClass: "top cursor" }, [
      _c("span", { on: { click: _vm.toIndex } }, [
        _vm._v(_vm._s(_vm.currentUrl[0])),
      ]),
      _vm._v("\n    /\n    "),
      _c("span", { on: { click: _vm.navigational } }, [
        _vm._v(_vm._s(_vm.currentUrl[1])),
      ]),
      _vm._v("\n    /\n    "),
      _c("span", { staticClass: "span" }, [_vm._v(_vm._s(_vm.currentUrl[2]))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }