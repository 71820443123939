<template>
  <div>
    <el-dialog
      v-if="dialogVisible"
      :title="ruleForm.id ? '编辑设备' : '新增设备'"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @click="open"
      @close="closeDialog"
      class="taskBox"
      v-dialogDrag
    >
      <div class="detailBar">
        <div class="formBox">
          <el-form

          :key="ruleForm.id"
            class="formbox"
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item class="userForm" prop="name" label="设备名称" v-if="type != 'terminal' && type != 'monitorModule' && type != 'countryApp'">
              <el-input
                placeholder="请输入设备名称"
                v-model="ruleForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="type != 'terminal' && type !=='countryApp'" class="userForm" prop="software_code" label="设备ID">
              <el-input
                placeholder="请输入设备ID"
                v-model="ruleForm.software_code"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="holder_dept" label="归属部门">
              <el-input
                placeholder="请输入归属部门"
                v-model="ruleForm.holder_dept"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="brand_id" label="设备品牌" v-if="type != 'terminal' && type != 'monitorModule'">
              <el-select
                v-model="ruleForm.brand_id"
                placeholder="请选择设备品牌"
                @change="brandChange(ruleForm.brand_id)"
              >
                <el-option
                  v-for="(item, index) in brandList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="userForm" prop="model_id" label="设备型号" v-if="type != 'terminal' && type != 'monitorModule'">
              <el-select
                v-model="ruleForm.model_id"
                placeholder="请选择设备型号"
              >
                <el-option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="userForm" prop="holder_name" label="持有人">
              <el-input
                placeholder="请输入持有人"
                v-model="ruleForm.holder_name"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="unit_id" label="联盟单位">
              <el-select
                v-model="ruleForm.unit_id"
                placeholder="请选择联盟单位"
              >
                <el-option
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="holder_phone"
              label="持有人手机"
            >
              <el-input
                placeholder="请输入持有人手机"
                v-model="ruleForm.holder_phone"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="status"
              label="设备状态"
              style=""
            >
              <el-select v-model="ruleForm.status" placeholder="请选择设备状态">
                <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.key"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              label="登记位置"
              required
              style="height: 130px"
            >
              <el-form-item prop="longitude" style="margin-bottom: 20px">
                <el-input
                  placeholder="经度"
                  v-model="ruleForm.longitude"
                ></el-input>
              </el-form-item>
              <el-form-item prop="latitude" style="margin-bottom: 20px">
                <el-input
                  placeholder="纬度"
                  v-model="ruleForm.latitude"
                ></el-input>
              </el-form-item>
              <el-button
                type="info"
                plain
                icon="el-icon-map-location"
                @click="selectMapPointDialog.visible = true"
                >地图</el-button
              >
            </el-form-item>
            <el-form-item
              class="userForm"
              label="关联设备类型"
              v-if="type == 'monitorModule'"
            >
              <el-select v-model="ruleForm.associated_device_type_id" placeholder="请选择关联设备类型" >
                <el-option :value="0" label="请选择"></el-option>
                <el-option
                  v-for="(item, index) in associatedDeviceTypeList"
                  :key="index"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              label="关联设备编号"
              v-if="type == 'monitorModule'"
            >
              <el-input
                placeholder="请输入关联设备编号"
                v-model="ruleForm.associated_device_software_code"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-loading="loading" @click="confirm" :disabled="loading"
          >确 定</el-button
        >
        <el-button @click="closeDialog">关 闭</el-button>
      </div>
    </el-dialog>
    <selectMapPoint
      :lonlatForm="ruleForm"
      :selectMapPointDialog="selectMapPointDialog"
      :vid="ruleForm.id"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import selectMapPoint from "@/components/messageBox/selectMapPoint";

export default {
  components: {
    selectMapPoint,
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        id: "",
        name: "",
        software_code: "", //设备ID
        category_id: "",
        brand_id: "", //品牌
        model_id: "", //型号
        unit_id: "", //单位
        status: "", //状态
        holder_name: "", //持有人
        holder_phone: "", //手机号
        holder_dept: "",
        longitude: "",
        latitude: "",
        associated_device_type_id: 0,
        associated_device_software_code: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入设备名称", trigger: "change" },
        ],
        software_code: [
          { required: true, message: "请输入设备ID", trigger: "change" },
        ],
        brand_id: [
          { required: true, message: "设备品牌不能为空", trigger: "change" },
        ],
        model_id: [
          { required: true, message: "设备型号不能为空", trigger: "change" },
        ],
        unit_id: [
          { required: true, message: "联盟单位不能为空", trigger: "change" },
        ],
        status: [
          { required: true, message: "设备状态不能为空", trigger: "change" },
        ],
        holder_name: [
          { required: true, message: "请输入持有人姓名", trigger: "change" },
        ],
        holder_phone: [
          { required: true, message: "请输入持有人手机号", trigger: "change" },
        ],
        holder_dept: [
          { required: true, message: "请输入归属部门", trigger: "change" },
        ],
        longitude: [
          { required: true, message: "请输入经度", trigger: "change" },
        ],
        latitude: [
          { required: true, message: "请输入纬度", trigger: "change" },
        ],
      },
      unitList: [],
      modelList: [], // 型号
      statusList: [],
      brandList: [],
      associatedDeviceTypeList: [],
      loading: false,
      selectMapPointDialog: {
        visible: false,
      },
    };
  },
  methods: {
    searchUnit() {
      this.$apis
        .unitLists({ per_page: 10000 }, this.token)
        .then((data) => {
          this.unitList = data.data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 型号查询
    searchModel() {
      this.$apis
        .ListModel(
          {
            category_id: this.categoryId,
            brand_id: this.ruleForm.brand_id,
          },
          this.token
        )
        .then((data) => {
          this.modelList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 品牌查询
    searchBrand() {
      this.$apis
        .ListBrand(
          {
            category_id: this.categoryId,
          },
          this.token
        )
        .then((data) => {
          this.brandList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 状态查询
    searchStatus() {
      this.$apis
        .ListStatus(
          {
            category_id: this.categoryId,
          },
          this.token
        )
        .then((data) => {
          this.statusList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    brandChange() {
      this.ruleForm.model_id = "";
      this.searchModel();
    },
    searchAssociatedDeviceType() {
      this.$apis
        .listEnum({ type: 'associated_device_type' }, this.token)
        .then((data) => {
          this.associatedDeviceTypeList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    open(data) {
      if (data != null) {
        for (var key in this.ruleForm) {
          this.ruleForm[key] = data[key];
        }
      } else {
        for (var key in this.$options.data().ruleForm) {
          this.ruleForm[key] = this.$options.data().ruleForm[key];
        }
        this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      }

      this.dialogVisible = true;
      this.searchUnit();
      this.searchBrand();
      this.searchModel();
      this.searchStatus();
      this.searchAssociatedDeviceType()
    },
    closeDialog() {
      this.dialogVisible = false;
      this.loading = false;
    },
    // 更新设备
    confirm() {
      this.loading = true;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.type = this.type
          if (this.ruleForm.id) {
            this.$apis.UpdateDevice(this.ruleForm, this.token).then((res) => {
              if (res.data.status == 0) {
                this.uploadCallback(res.data.message);
              }else {
                this.loading = false;
              }
            });
          } else {
            this.$apis.AddDevice(this.ruleForm, this.token).then((res) => {
              if (res.data.status == 0) {
                this.uploadCallback(res.data.message);
              }else {
                this.loading = false;
              }
            });
          }
        } else {
          this.loading = false;
        }
      });
    },
    uploadCallback(message) {
      this.$message({
        message: message,
        type: "success",
      });
      this.dialogVisible = false;
      this.loading = false;
      this.$emit("reload");
    },
  },

  computed: {
    ...mapState(["token", "user"]),
  },
  created() {},
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 790px;
}
/deep/.el-dialog__body {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-form-item {
  height: 36px;
}
/deep/.el-form-item--medium .el-form-item__label {
  width: 110px !important;
}
/deep/.el-dialog__footer {
  padding: 0px;
}
/deep/.el-form-item__content {
  width: 190px;
}
.detailBar {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 30px 0;
  .detailLeft {
    width: 40%;
    float: left;
    margin-left: 45px;
  }
  .detailRight {
    width: 40%;
    float: right;
    margin-right: 20px;
  }
}
.datailTit {
  font-size: 16px;
  color: #3c4353;
  margin-right: 28px;
}
.detailLine {
  line-height: 40px;
}
.formbox {
  padding: 0 40px;
}
.userForm {
  width: 50%;
}
</style>
