<template>
  <div class="subject">
    <navTop />
    <div class="bodyNav">
      <div class="top">
        <span class="title">历史已申请数据</span>
        <div class="fmNav">
          <el-button :style="ImportSty" type="text" @click="Import(0)">全部订单</el-button>
          <el-button :style="drawSty" type="text" @click="draw(1)">审核中</el-button>
          <el-button :style="confirmed" type="text" @click="confir(2)">待确认</el-button>
          <el-button :style="returned" type="text" @click="retur(3)">已退回</el-button>
          <el-button :style="completed" type="text" @click="comple(4)">已完成</el-button>
          <el-button :style="termina" type="text" @click="termination(5)">已终止</el-button>
        </div>
        <el-divider></el-divider>
        <div class="midBar">
          <div class="lineBar" v-for="(item,index) in dataList" :key="index">
            <div class="topNav">
              <div class="titles">
                <span class="titword">数据申请单号: {{item.data_apply_no}}</span>
              </div>
              <div class="time">{{item.created_at}}</div>
              <div v-if="item.status==1" class="status1">审核中</div>
              <div v-if="item.status==2" class="status2">待确认</div>
              <div v-if="item.status==3" class="status3">已退回</div>
              <div v-if="item.status==4" class="status4">已完成</div>
              <div v-if="item.status==5" class="status3">已终止</div>
            </div>
            <div class="botNav">
              <div class="typeNav">
                <span class="application">数据申请类型</span>
                <span
                  class="type"
                  v-for="(item,index) in JSON.parse(item.category)"
                  :key="index"
                >{{item}}</span>
              </div>
              <div class="dataNav">
                <span class="dataType">数据完整性</span>
                <span v-if="item.integrality==0" class="types">可接受只覆盖部分区域</span>
                <span v-if="item.integrality==1" class="types">只接受只全区域覆盖</span>
              </div>
              <div class="waitNav">
                <span class="waitingperiod">可接受的反馈等待期</span>
                <span class="waitday">{{item.wait_time}}</span>
              </div>
              <div class="watchNab">
                <el-button style="color:#0094ff" @click="wacthBtn(item)" type="text">查看</el-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="per_page"
            layout="total,  prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let param = 0;
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      ImportSty: "",
      drawSty: "",
      confirmed: "",
      returned: "",
      completed: "",
      termina: "",
      flag: 0,
      dataList: [],
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 4, //每页多少条
      page: 1,
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    Import(param) {

      if (param == 0) {
        this.getMyApplyData(param);
        this.ImportSty = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.drawSty = "";
        this.confirmed = "";
        this.returned = "";
        this.completed = "";
        this.termina = "";
      }
    },
    draw(param) {

      if (param == 1) {
        this.getMyApplyData(param);
        this.drawSty = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.ImportSty = "";
        this.confirmed = "";
        this.returned = "";
        this.completed = "";
        this.termina = "";
      }
    },
    confir(param) {

      if (param == 2) {
        this.getMyApplyData(param);
        this.confirmed = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.ImportSty = "";
        this.returned = "";
        this.completed = "";
        this.drawSty = "";
        this.termina = "";
      }
    },
    retur(param) {

      if (param == 3) {
        this.getMyApplyData(param);
        this.returned = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.ImportSty = "";
        this.confirmed = "";
        this.completed = "";
        this.drawSty = "";
        this.termina = "";
      }
    },
    comple(param) {

      if (param == 4) {
        this.getMyApplyData(param);
        this.completed = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.ImportSty = "";
        this.confirmed = "";
        this.returned = "";
        this.drawSty = "";
        this.termina = "";
      }
    },
    termination(param) {

      if (param == 5) {
        this.getMyApplyData(param);
        this.termina = "border-bottom:4px solid #235EFF;color:#235EFF";
        this.ImportSty = "";
        this.confirmed = "";
        this.returned = "";
        this.drawSty = "";
        this.completed = "";
      }
    },
    getMyApplyData(param) {
      this.$apis
        .getMyApplyData(
          {
            per_page: this.per_page,
            page: this.page,
            status: param,
          },
          this.token
        )
        .then((data) => {

          if (data.data.status == 0) {
            this.dataList = data.data.data.data;
            this.total = data.data.data.total;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleNext() {
      this.page++;
      this.getMyApplyData(param);
    },
    handlePrev() {
      this.page--;
      this.getMyApplyData(param);
    },
    currentchange(page) {
      this.page = page;
      this.getMyApplyData(param);
    },
    // 查看
    wacthBtn(param) {
      this.$router.push({ path: "/datadetails", query: { id: param.id } });
    },
  },
  created() {
    this.getMyApplyData(0);
    if (this.flag == 0) {
      this.ImportSty = "border-bottom:4px solid #235EFF;color:#235EFF";
      this.drawSty = "";
      this.confirmed = "";
      this.returned = "";
      this.completed = "";
    }
  },
  computed: {
    ...mapState(["navMessage", "role", "token", "user", "currentUrl"]),
  },
  mounted() {
    if (this.$route.query.nav == "Appl") {
      this.currentUrlStorage(["首页", "个人中心", "已申请数据"]);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 0 15px 2% 15px;
  background-color: #f0f0f0;
}
.bodyNav {
  height: calc(100vh - 130px);
  width: 100%;
}
.top {
  width: 100%;
  height: 100%;
  display: flow-root;
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  padding: 23px 20px 0 20px;
  .title {
    font-size: 17px;
    color: #c7c7c7;
    margin-bottom: 24px;
    float: left;
    width: 100%;
  }
  .fmNav {
    font-size: 16px;
  }
  /deep/.el-button--text {
    color: #000000;
    width: 72px;
    border-bottom-style: solid;
    border-radius: 0;
  }
  .midBar {
    width: 100%;
    height: 570px;
    .lineBar {
      width: 100%;
      height: 108px;
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      margin-top: 20px;
      float: left;
      .topNav {
        width: 100%;
        height: 43px;
        background-color: #f8f8f8;
        line-height: 43px;
        .titles {
          float: left;
          .titword {
            font-size: 15px;
            color: #797979;
            margin-left: 15px;
          }
        }
        .time {
          font-size: 15px;
          color: #98a6cd;
          float: right;
          margin-right: 34px;
        }
        .status1 {
          width: 87px;
          height: 24px;
          background-color: #1335ad;
          float: right;
          color: #f0f0f0;
          line-height: 24px;
          text-align: center;
          border-radius: 2px;
          margin-top: 9.5px;
          margin-right: 30px;
          font-size: 14px;
        }
        .status2 {
          width: 87px;
          height: 24px;
          background-color: #d79c33;
          float: right;
          color: #f0f0f0;
          line-height: 24px;
          text-align: center;
          border-radius: 2px;
          margin-top: 9.5px;
          margin-right: 30px;
          font-size: 14px;
        }
        .status3 {
          width: 87px;
          height: 24px;
          background-color: #595959;
          float: right;
          color: #f0f0f0;
          line-height: 24px;
          text-align: center;
          border-radius: 2px;
          margin-top: 9.5px;
          margin-right: 30px;
          font-size: 14px;
        }
        .status4 {
          width: 87px;
          height: 24px;
          background-color: #0bb768;
          float: right;
          color: #f0f0f0;
          line-height: 24px;
          text-align: center;
          border-radius: 2px;
          margin-top: 9.5px;
          margin-right: 30px;
          font-size: 14px;
        }
      }
      .botNav {
        width: 100%;
        height: 65px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .typeNav {
          width: 550px;
          float: left;
          .application {
            font-size: 12px;
            color: #8b8b8b;
          }
          .type {
            font-size: 12px;
            color: #000000;
            margin-left: 10px;
          }
        }
        .dataNav {
          width: 210px;
          float: left;
          .dataType {
            font-size: 12px;
            color: #8b8b8b;
          }
          .types {
            font-size: 12px;
            color: #000000;
            margin-left: 20px;
          }
        }
        .waitNav {
          float: left;
          .waitingperiod {
            font-size: 12px;
            color: #8b8b8b;
          }
        }
        .waitday {
          font-size: 12px;
          color: #000000;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
