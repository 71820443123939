var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "task-push",
    },
    [
      _c("div", { staticClass: "left-alert" }, [
        _c("div", { staticClass: "left-alert-ov iconfont" }, [
          _c("div", { staticClass: "left-alert-top" }, [
            _c("div", { staticClass: "project_name" }, [
              _c("div", [
                _c("div", [
                  _c("i", {
                    staticClass: "el-icon-arrow-left back",
                    on: { click: _vm.backPath },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "projectNameClass",
                      attrs: { title: _vm.projectName },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.projectName) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "otherData" }, [
                  _vm._v("任务ID:" + _vm._s(_vm.projectId)),
                ]),
              ]),
              _vm._v(" "),
              _vm.nav === 3
                ? _c("div", { staticClass: "otherData" }, [
                    _c("div", [
                      _vm._v("推送人:" + _vm._s(_vm.audit_user_mobile)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-top": "12px" } }, [
                      _vm._v("发布ID:" + _vm._s(_vm.demand_order_no)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.nav === 1
                ? _c("div", { staticClass: "otherData" }, [
                    _vm.projectData.arr &&
                    _vm.projectData.arr[0] &&
                    _vm.projectData.arr[0].task[0].task_push_data
                      .push_to_unit_user_data
                      ? _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v(
                            "\n              推送人:" +
                              _vm._s(
                                _vm.projectData.arr[0].task[0].task_push_data
                                  .push_to_unit_user_data.created_user_mobile
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm.projectData.arr &&
                        _vm.projectData.arr[0] &&
                        _vm.projectData.arr[0].task[0].task_push_data
                          .push_to_user_user_data
                      ? _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v(
                            "\n              推送人:" +
                              _vm._s(
                                _vm.projectData.arr[0].task[0].task_push_data
                                  .push_to_user_user_data.created_user_mobile
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v("推送人:尚未推送"),
                        ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.nav === 2
                ? _c("div", { staticClass: "otherData" }, [
                    _vm.projectData.arr &&
                    _vm.projectData.arr[0].task &&
                    _vm.projectData.arr[0].task[0].task_push_data
                      .push_to_unit_user_data
                      ? _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v(
                            "\n              推送人:" +
                              _vm._s(
                                _vm.projectData.arr[0].task[0].task_push_data
                                  .push_to_unit_user_data.created_user_mobile
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm.projectData.arr &&
                        _vm.projectData.arr[0].task &&
                        _vm.projectData.arr[0].task[0].task_push_data
                          .push_to_user_user_data
                      ? _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v(
                            "\n              推送人:" +
                              _vm._s(
                                _vm.projectData.arr[0].task[0].task_push_data
                                  .push_to_user_user_data.created_user_mobile
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c("div", { staticStyle: { "margin-top": "42px" } }, [
                          _vm._v("推送人:尚未推送"),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.authority
              ? _c("div", { staticClass: "sendType" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "whole" },
                          model: {
                            value: _vm.sendType,
                            callback: function ($$v) {
                              _vm.sendType = $$v
                            },
                            expression: "sendType",
                          },
                        },
                        [_vm._v("打包发送")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "oneTask" },
                          model: {
                            value: _vm.sendType,
                            callback: function ($$v) {
                              _vm.sendType = $$v
                            },
                            expression: "sendType",
                          },
                        },
                        [_vm._v("打散发送")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "nowSendType" }, [
                    _vm.authority
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor alert-top-btn",
                            on: { click: _vm.designate },
                          },
                          [
                            _c("span", [_vm._v("")]),
                            _vm._v("\n              指派\n            "),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.authority
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor alert-top-btn mass",
                            on: {
                              click: function ($event) {
                                return _vm.flockSend(true)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("")]),
                            _vm._v("\n              群发\n            "),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sendType",
                staticStyle: { position: "relative" },
              },
              [
                _vm.isWhole && !_vm.authority
                  ? _c(
                      "div",
                      {
                        staticClass: "cursor alert-top-btn mass",
                        staticStyle: { position: "absolute" },
                        on: {
                          click: function ($event) {
                            return _vm.taskUnitOneConfirm()
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { margin: "0" } }, [
                          _vm._v(""),
                        ]),
                        _vm._v("\n            一键接单\n          "),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "taskItems" },
            _vm._l(_vm.projectData.arr, function (item, index) {
              return _c(
                "div",
                { key: "0" + index },
                _vm._l(item.task, function (item2, index2) {
                  return _c("blockTaskItem", {
                    key: index + "0" + index2,
                    staticClass: "taskItem",
                    attrs: {
                      checkeds: item2.checked,
                      index: index2,
                      "index-i": index,
                      "block-id": item.id,
                      "project-obj": item2,
                      block: item.block_name,
                      authority: _vm.authority,
                      nav: _vm.nav,
                    },
                    on: {
                      taskRedoTaskUpdate: _vm.taskRedoTaskUpdate,
                      sonChecked: _vm.sonChecked,
                      instructions: _vm.instructions,
                      taskListTask: _vm.taskListTask,
                      clickConversation: _vm.clickConversation,
                    },
                  })
                }),
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mapLoading,
              expression: "mapLoading",
            },
          ],
          staticClass: "amap-demo",
        },
        [
          _c("singleMonitor", {
            ref: "singleMonitor",
            attrs: {
              planeType: "feima",
              software_code: _vm.software_code,
              order_id: _vm.order_id,
              brandName: "飞马",
              moreScreen: _vm.moreScreen,
              isBlock: _vm.isBlock,
              canFly: _vm.canFly,
            },
            on: {
              setFlyArea: _vm.setFlyArea,
              setFeimaDroneInfo: _vm.setFeimaDroneInfo,
              getMap: _vm.getMap,
              changeMonitorMap: _vm.changeMonitorMap,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "el-dialog-wi",
          attrs: {
            title: _vm.designateVisible == false ? "群发" : "指派",
            visible: _vm.centerDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
            close: _vm.abolish,
          },
        },
        [
          _c("div", { staticClass: "el-dialong-body" }, [
            _c("div", { staticClass: "el-dialong-div el-dialong-left" }, [
              _c(
                "div",
                { staticClass: "el-left-checkbox" },
                [
                  _vm._l(_vm.checkedCities, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "iconfont cursor",
                        on: {
                          click: function ($event) {
                            return _vm.clearAway(index)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "unitData",
                            attrs: {
                              title: item.unit_name
                                ? item.unit_name
                                : item.name,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.unit_name ? item.unit_name : item.name
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("")]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.designateValue
                    ? _c(
                        "div",
                        {
                          staticClass: "iconfont cursor",
                          on: {
                            click: function ($event) {
                              return _vm.clearAway()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "unitData",
                              attrs: {
                                title: _vm.designateValue.unit_name
                                  ? _vm.designateValue.unit_name
                                  : _vm.designateValue.name,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.designateValue.unit_name
                                      ? _vm.designateValue.unit_name
                                      : _vm.designateValue.name
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("")]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "time-text" }, [_vm._v("设置截至时间")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "el-dialong-time" },
                [
                  _c("el-date-picker", {
                    staticClass: "time",
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.valueDate,
                      callback: function ($$v) {
                        _vm.valueDate = $$v
                      },
                      expression: "valueDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-time-select", {
                    staticClass: "time",
                    attrs: {
                      "picker-options": {
                        step: "00:15",
                        end: "23:45",
                      },
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.valueTime,
                      callback: function ($$v) {
                        _vm.valueTime = $$v
                      },
                      expression: "valueTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "cursor el-dialong-btn" }, [
                _c("div", { on: { click: _vm.selectConfirm } }, [
                  _vm._v(
                    "\n            确定(" +
                      _vm._s(
                        _vm.designateValue != "" ? 1 : _vm.checkedCities.length
                      ) +
                      ")\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cancel", on: { click: _vm.abolish } },
                  [_vm._v("取消")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.underling == false && _vm.designateVisible == false
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入内容",
                        "suffix-icon": "el-icon-search",
                        disabled: _vm.underling !== _vm.pitchOn,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.flockSend(false)
                        },
                      },
                      model: {
                        value: _vm.inputData,
                        callback: function ($$v) {
                          _vm.inputData = $$v
                        },
                        expression: "inputData",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "cheak-all",
                              attrs: {
                                disabled: _vm.underling !== _vm.pitchOn,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckAllChange(_vm.cities)
                                },
                              },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            },
                            [_vm._v("全选")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "cheak-all",
                              attrs: {
                                disabled: _vm.underling !== _vm.pitchOn,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.checkAllUnitAdministrator(
                                    _vm.cities
                                  )
                                },
                              },
                              model: {
                                value: _vm.checkAllAdministrator,
                                callback: function ($$v) {
                                  _vm.checkAllAdministrator = $$v
                                },
                                expression: "checkAllAdministrator",
                              },
                            },
                            [_vm._v("全选联盟管理员")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "el-dialong-group",
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckedCitiesChange(false)
                          },
                        },
                        model: {
                          value: _vm.checkedCities,
                          callback: function ($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities",
                        },
                      },
                      [
                        _vm.cities.length > 0
                          ? _c(
                              "div",
                              { staticClass: "dialog-one-div" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "el-dialong-checkbox",
                                    attrs: {
                                      label: _vm.cities[0],
                                      disabled:
                                        _vm.underling !== _vm.pitchOn ||
                                        _vm.checkAllAdministrator,
                                      title: _vm.cities[0].unit_name,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.cities[0].unit_name))]
                                ),
                                _vm._v(" "),
                                _vm.cities[0].unit_code ===
                                  _vm.user.user_unit.unit_code || _vm.role === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "el-dialong-chdiv iconfont cursor",
                                        on: {
                                          click: function ($event) {
                                            return _vm.subordinate(0)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              \n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.cities.slice(1), function (city, index) {
                          return _c(
                            "div",
                            { key: city.id, staticClass: "dialog-one-div" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "el-dialong-checkbox",
                                  attrs: {
                                    label: city,
                                    disabled:
                                      _vm.underling !== _vm.pitchOn ||
                                      _vm.checkAllAdministrator,
                                    title: city.unit_name,
                                  },
                                },
                                [_vm._v(_vm._s(city.unit_name))]
                              ),
                              _vm._v(" "),
                              _vm.role === 0 && (_vm.nav === 2 || _vm.nav === 3)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-dialong-chdiv iconfont cursor",
                                      on: {
                                        click: function ($event) {
                                          return _vm.subordinate(index + 1)
                                        },
                                      },
                                    },
                                    [_vm._v("\n              \n            ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling != false && _vm.designateVisible == false
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入所需搜索用户",
                        "suffix-icon": "el-icon-search",
                        disabled: _vm.underling !== _vm.pitchOn,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.subordinate(_vm.selectNum)
                        },
                      },
                      model: {
                        value: _vm.inputDatas,
                        callback: function ($$v) {
                          _vm.inputDatas = $$v
                        },
                        expression: "inputDatas",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cursor el-dialong-company",
                        on: {
                          click: function ($event) {
                            _vm.getBackLeague(), _vm.estimateAllChange()
                          },
                        },
                      },
                      [
                        _vm.role === 0
                          ? _c("span", { staticClass: "iconfont" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                        _vm._v("\n          联盟下用户\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "cheak-all",
                              attrs: {
                                disabled: _vm.underling !== _vm.pitchOn,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckAllChange(
                                    _vm.underlingArr
                                  )
                                },
                              },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            },
                            [_vm._v("全部")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "el-dialong-group",
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckedCitiesChange(true)
                          },
                        },
                        model: {
                          value: _vm.checkedCities,
                          callback: function ($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities",
                        },
                      },
                      _vm._l(_vm.underlingArr, function (city, index) {
                        return _c(
                          "el-checkbox",
                          {
                            key: index,
                            staticClass: "el-dialong-checkbox",
                            attrs: {
                              disabled: _vm.underling !== _vm.pitchOn,
                              label: city,
                            },
                          },
                          [_vm._v(_vm._s(city.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling == false && _vm.designateVisible
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入内容",
                        "suffix-icon": "el-icon-search",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.flockSend(false)
                        },
                      },
                      model: {
                        value: _vm.inputData,
                        callback: function ($$v) {
                          _vm.inputData = $$v
                        },
                        expression: "inputData",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "cheak-all" }, [_vm._v("指派")]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dialog-one-div" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "el-dialong-checkbox",
                            attrs: {
                              label: _vm.cities[0],
                              title: _vm.cities[0].unit_name,
                            },
                            model: {
                              value: _vm.designateValue,
                              callback: function ($$v) {
                                _vm.designateValue = $$v
                              },
                              expression: "designateValue",
                            },
                          },
                          [_vm._v(_vm._s(_vm.cities[0].unit_name))]
                        ),
                        _vm._v(" "),
                        _vm.cities[0].unit_code ===
                          _vm.user.user_unit.unit_code || _vm.role === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "el-dialong-chdiv iconfont cursor",
                                on: {
                                  click: function ($event) {
                                    return _vm.subordinate(0)
                                  },
                                },
                              },
                              [_vm._v("\n            \n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.cities.slice(1), function (city, index) {
                      return _c(
                        "div",
                        { key: city.id, staticClass: "dialog-one-div" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "el-dialong-checkbox",
                              attrs: { label: city, title: city.unit_name },
                              model: {
                                value: _vm.designateValue,
                                callback: function ($$v) {
                                  _vm.designateValue = $$v
                                },
                                expression: "designateValue",
                              },
                            },
                            [_vm._v(_vm._s(city.unit_name))]
                          ),
                          _vm._v(" "),
                          _vm.role === 0 && (_vm.nav === 3 || _vm.nav === 2)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "el-dialong-chdiv iconfont cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.subordinate(index + 1)
                                    },
                                  },
                                },
                                [_vm._v("\n            \n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling != false && _vm.designateVisible
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入所需搜索用户",
                        "suffix-icon": "el-icon-search",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.subordinate(_vm.selectNum)
                        },
                      },
                      model: {
                        value: _vm.inputDatas,
                        callback: function ($$v) {
                          _vm.inputDatas = $$v
                        },
                        expression: "inputDatas",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cursor el-dialong-company",
                        on: { click: _vm.getBackLeague },
                      },
                      [
                        _vm.role === 0
                          ? _c("span", { staticClass: "iconfont" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                        _vm._v("\n          联盟下用户\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _vm._l(_vm.underlingArr, function (city, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          staticClass: "el-dialong-checkbox",
                          attrs: { label: city },
                          model: {
                            value: _vm.designateValue,
                            callback: function ($$v) {
                              _vm.designateValue = $$v
                            },
                            expression: "designateValue",
                          },
                        },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.directiveReveal
        ? _c(
            "div",
            { staticClass: "directive" },
            _vm._l(_vm.directiveArr, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.directivePush(item)
                    },
                  },
                },
                [
                  _c("img", { attrs: { src: item.url, alt: "" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "newDialog" },
        [
          _c(
            "el-dialog",
            {
              directives: [
                { name: "dialogDragCustom", rawName: "v-dialogDragCustom" },
              ],
              staticClass: "blockInformation",
              attrs: {
                width: "382px",
                top: "0px",
                modal: false,
                "append-to-body": false,
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "lock-scroll": false,
                visible: _vm.showInformation,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showInformation = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      class: { selectBtn: _vm.btnIndex === 1 },
                      on: {
                        click: function ($event) {
                          return _vm.clickInformation(1)
                        },
                      },
                    },
                    [_vm._v("\n          任务详情\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.order_id !== "-1",
                          expression: "order_id !== '-1'",
                        },
                      ],
                      staticClass: "btn setMargin",
                      class: { selectBtn: _vm.btnIndex === 2 },
                      on: {
                        click: function ($event) {
                          return _vm.clickInformation(2)
                        },
                      },
                    },
                    [_vm._v("\n          飞机信息\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.parameter.terminal,
                          expression: "parameter.terminal",
                        },
                      ],
                      staticClass: "btn setMargin",
                      class: { selectBtn: _vm.btnIndex === 3 },
                      on: {
                        click: function ($event) {
                          return _vm.clickInformation(3)
                        },
                      },
                    },
                    [_vm._v("\n          终端信息\n        ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.btnIndex === 1 && _vm.nav === 3
                ? _c(
                    "div",
                    { staticClass: "dialog-body" },
                    [
                      _c("taskDetail", {
                        ref: "taskDetail",
                        attrs: {
                          id: Number(_vm.projectId),
                          isBlockInformation: _vm.isBlockInformation,
                          releaseShow: _vm.taskDetailShow,
                          showAllData: _vm.showAllData,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.btnIndex === 1 && _vm.nav === 1 && _vm.parameter.content
                ? _c("div", { staticClass: "particulars-body" }, [
                    _vm.parameterIndex === 1 && _vm.nav === 1
                      ? _c("div", { staticClass: "body-top" }, [
                          _c("div", { staticClass: "div name" }, [
                            _c("div", { staticClass: "task-name" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "model" }, [
                              _c("div", [
                                _vm._v(
                                  "\n                设备型号:\n                "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.parameter.content.plane_type)
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "52px" } },
                                [
                                  _vm._v(
                                    "\n                载荷型号:\n                "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.parameter.content.camera_type)
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div parameter" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "rgb(102, 102, 102)",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("参数")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "rgb(102, 102, 102)",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("\n              最高点\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "rgb(102, 102, 102)",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("\n              最低点\n            ")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("\n              GSD\n              "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .average_ground_resolution
                                  ) + " cm"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("\n              GSD\n              "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parameter.content.high_gsd) + "cm"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("\n              GSD\n              "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parameter.content.low_gsd) + "cm"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              航向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.longitudinal_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              航向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .high_longitudinal_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              航向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .low_longitudinal_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              旁向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.lateral_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              旁向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.high_lateral_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              旁向重叠\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.low_lateral_overlap
                                  ) + "%"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              海拔高度\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .survey_average_altitude
                                  ) + "m"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              海拔高度\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .survey_highest_altitude
                                  ) + "m"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              海拔高度\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.survey_lowest_altitude
                                  ) + "m"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              飞行高度\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content
                                      .flight_relative_altitude
                                  ) + "m"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div"),
                            _vm._v(" "),
                            _c("div"),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("\n              比例尺\n              "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parameter.content.work_scale)
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "div message" }, [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [_vm._v("信息")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "message-div" }, [
                              _c("div", [
                                _vm._v(
                                  "\n                航线间距:\n                "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.content.flight_interval
                                    ) + "m"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                预计时间:\n                "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.parameter.content.work_time) +
                                      "min"
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "message-div" }, [
                              _c("div", [
                                _vm._v(
                                  "\n                拍照间距:\n                "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.content.take_pic_interval
                                    ) + "m"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.parameter.content.work_area
                                ? _c("div", [
                                    _vm._v(
                                      "\n                预计面积:\n                "
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.content.work_area.slice(
                                            0,
                                            3
                                          )
                                        ) + "km²"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "message-div" }, [
                              _c("div", [
                                _vm._v(
                                  "\n                默认空速:\n                "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.content.default_air_speed
                                    ) + "m/s"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                预计里程:\n                "
                                ),
                                _vm.parameter.content.total_mileage
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.content.total_mileage.slice(
                                            0,
                                            5
                                          )
                                        ) + "km"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.btnIndex === 1 && _vm.nav === 2
                ? _c("div", [
                    _vm.parameter.project_content &&
                    _vm.parameter.project_content.project
                      ? _c("div", { staticClass: "dialog-body" }, [
                          _c("div", { staticClass: "div uav" }, [
                            _c("div", { staticClass: "uav-div" }, [
                              _c("div", [_vm._v("传感器")]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.project_content.project
                                        .sensor_type
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uav-div" }, [
                              _c("div", [_vm._v("授权空域")]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.project_content.project
                                        .auth_area
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uav-div" }, [
                              _c("div", [_vm._v("任务性质")]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parameter.project_content.project
                                        .charge_property
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.parameter.project_content.project
                              .point_cloud_density === ""
                              ? _c("div", { staticClass: "uav-div" }, [
                                  _c("div", [_vm._v("影像分辨率")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.project_content.project
                                            .average_ground_resolution
                                        ) + "cm"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.parameter.project_content.project
                              .point_cloud_density === ""
                              ? _c("div", { staticClass: "uav-div" }, [
                                  _c("div", [_vm._v("航向重叠度")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.project_content.project
                                            .longitudinal_overlap
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.parameter.project_content.project
                              .point_cloud_density === ""
                              ? _c("div", { staticClass: "uav-div" }, [
                                  _c("div", [_vm._v("旁向重叠度")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.project_content.project
                                            .lateral_overlap
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.parameter.project_content.project
                              .point_cloud_density !== ""
                              ? _c("div", { staticClass: "uav-div" }, [
                                  _c("div", [_vm._v("激光点密度")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.project_content.project
                                            .point_cloud_density
                                        ) + "p/m²"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.parameter.project_content.project
                              .point_cloud_density !== ""
                              ? _c("div", { staticClass: "uav-div" }, [
                                  _c("div", [_vm._v("航带重叠度")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameter.project_content.project
                                            .lateral_overlap
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.btnIndex === 2 && _vm.parameter.uav
                ? _c("div", { staticClass: "dialog-body" }, [
                    _c("div", { staticClass: "uav div" }, [
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("设备ID")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.software_code)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("设备型号")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.device_model)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("联盟单位")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.unit_name)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("设备状态")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.statusArr[_vm.parameter.uav.status])
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("归属部门")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.holder_dept)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("持有人")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.holder_name)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("持有人手机号")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.holder_phone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uav-div" }, [
                        _c("div", [_vm._v("创建人手机号")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.uav.creator_phone)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.btnIndex === 3
                ? _c(
                    "div",
                    { staticClass: "dialog-body" },
                    [
                      _c("deviceData", {
                        attrs: {
                          terminal: _vm.parameter.terminal,
                          blockSetStyle: _vm.blockSetStyle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.showInformation = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tool" },
        [
          _c("measurementTool", {
            ref: "measurementTool",
            attrs: {
              map: _vm.map,
              mouseTool: this.mouseTool,
              mapToolManage: _vm.mapToolManage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("newFmTask", { ref: "newFmTask" }),
      _vm._v(" "),
      _vm.newTaskNum
        ? _c("newTaskPop", { on: { newFmPop: _vm.newFmPop } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "video", on: { click: _vm.chat } }, [
        _vm.chatDialog.unread_num_arr[_vm.projectId] > 99
          ? _c("div", { staticClass: "unread" }, [_vm._v("\n      ···\n    ")])
          : _vm.chatDialog.unread_num_arr[_vm.projectId] > 0
          ? _c("div", { staticClass: "unread" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.chatDialog.unread_num_arr[_vm.projectId]) +
                  "\n    "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "chat-add-dialog",
          attrs: {
            modal: false,
            "lock-scroll": false,
            visible: _vm.chatAddDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.chatAddDialogVisible = $event
            },
          },
        },
        [
          _c("chatAdd", {
            key: _vm.chatAddDialogKey,
            ref: "chatAdd",
            attrs: {
              type: _vm.roomActionType,
              project: _vm.chatDialog.project,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }