var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "privile" },
    [
      _c("div", { staticClass: "privileBody" }, [
        _c("div", { staticClass: "cen" }, [
          _c(
            "div",
            { staticClass: "cen-left" },
            [
              _c("navTop"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "taxon" },
                _vm._l(_vm.userList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      class: {
                        super: _vm.userListIndex == _vm.userList.length - index,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.roleList(item.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cen-right" }, [
            _c(
              "div",
              { staticClass: "cen-right-elMenu" },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo configuration-navMenu",
                    attrs: {
                      "default-active": _vm.activeIndex,
                      mode: "horizontal",
                    },
                    on: { select: _vm.handleSelect },
                  },
                  [
                    _c("el-menu-item", { attrs: { index: "1" } }, [
                      _vm._v("权限设置"),
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "2" } }, [
                      _vm._v("角色设置"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.activeIndex === "2"
              ? _c("div", { staticClass: "search" }, [
                  _c(
                    "div",
                    { staticClass: "search-input" },
                    [
                      _c("span", [_vm._v("角色ID")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "import scale",
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.roleSeekId,
                          callback: function ($$v) {
                            _vm.roleSeekId = $$v
                          },
                          expression: "roleSeekId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-input" },
                    [
                      _c("span", [_vm._v("角色名称")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "import scale",
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.roleSeekName,
                          callback: function ($$v) {
                            _vm.roleSeekName = $$v
                          },
                          expression: "roleSeekName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "newRole inquire btn",
                      attrs: { type: "default" },
                      on: { click: _vm.newRole },
                    },
                    [_vm._v("\n            新建角色\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "inquire btn",
                      attrs: { type: "default" },
                      on: { click: _vm.inquire },
                    },
                    [_vm._v("\n            查询\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "reset btn",
                      attrs: { type: "default" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("\n            重置\n          ")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.activeIndex === "1"
              ? _c(
                  "div",
                  { staticClass: "cen-right-top" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.allChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      },
                      [_vm._v("全部选择")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedCitiesArr != [] && _vm.activeIndex === "1"
              ? _c(
                  "div",
                  { staticClass: "cen-right-body" },
                  [
                    _vm._l(_vm.checkedCitiesArr, function (item, index) {
                      return _c(
                        "div",
                        { key: item.name, staticClass: "option" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.allListChange(item.name, index)
                                },
                              },
                              model: {
                                value: _vm.checkAllArr[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.checkAllArr, index, $$v)
                                },
                                expression: "checkAllArr[index]",
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "division" }),
                          _vm._v(" "),
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.permissionsArr[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissionsArr, index, $$v)
                                },
                                expression: "permissionsArr[index]",
                              },
                            },
                            _vm._l(item.list, function (city, num) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: num,
                                  staticStyle: { margin: "10px  4%" },
                                  attrs: { label: city },
                                  on: {
                                    change: function ($event) {
                                      return _vm.GroupChange(
                                        _vm.checkedCitiesArr[index],
                                        index,
                                        num
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(city.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.preserve } },
                      [_vm._v("保存")]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.activeIndex === "2"
            ? _c(
                "div",
                {
                  staticClass: "cen-right",
                  staticStyle: { "margin-top": "15px", width: "76%" },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.roleDataArr,
                        height: _vm.userListIndex === 2 ? " 500px" : " 600px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          label: "角色ID",
                          width: "200px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.id !== ""
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.id) +
                                            "\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          572718287
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "角色名称",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("input", {
                                    ref: "ref" + scope.row.id,
                                    staticClass: "nameState",
                                    attrs: {
                                      type: "text",
                                      placeholder: scope.row.name,
                                      id: "id" + scope.row.id,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.inputJudge(scope)
                                      },
                                      blur: function ($event) {
                                        return _vm.cursorDisappears(scope)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          66241029
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "200px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.id !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.configurationAccess(
                                                scope
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("权限配置")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.id !== ""
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rolechildDel(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2071541489
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.subcharactersShow
        ? _c("subcharacters", {
            attrs: { obj: _vm.subcharactersObj },
            on: { shut: _vm.shut },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }