var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply" },
    [
      _c(
        "div",
        { staticClass: "palyBar" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "wordBar" }, [_vm._v("我想要申请空域")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: {
                title: "请绘制合法空域",
                type: "primary",
                plain: "",
                disabled: !_vm.legal,
              },
              on: { click: _vm.open },
            },
            [_vm._v("申请空域")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "申请空域",
            visible: _vm.dialogVisible,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodyBar" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "空域名称", prop: "air_space_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.air_space_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "air_space_name", $$v)
                          },
                          expression: "ruleForm.air_space_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "空域中心点", prop: "coordinate_point" },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.coordinate_point)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "作业高度（真高）",
                        prop: "flight_altitude",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.flight_altitude,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "flight_altitude", $$v)
                          },
                          expression: "ruleForm.flight_altitude",
                        },
                      }),
                      _vm._v("米\n        "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "航飞作业单位名称",
                        prop: "flight_company_name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.flight_company_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "flight_company_name", $$v)
                          },
                          expression: "ruleForm.flight_company_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "委托联盟单位名称", prop: "unit_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", disabled: "" },
                        model: {
                          value: _vm.ruleForm.unit_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "unit_name", $$v)
                          },
                          expression: "ruleForm.unit_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规划飞行窗口", prop: "flight_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.getBarchart },
                        model: {
                          value: _vm.ruleForm.flight_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "flight_time", $$v)
                          },
                          expression: "ruleForm.flight_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "加载申请函",
                        prop: "application_letter",
                      },
                    },
                    [
                      _c("div", { staticClass: "snapshotbar" }, [
                        _c("input", {
                          staticClass: "imgupload",
                          attrs: { type: "file", accept: ".doc, .docx, .pdf" },
                          on: { change: _vm.uploadImg },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "snapshotBar" }, [
                          _c("img", {
                            staticClass: "uploadImg",
                            attrs: {
                              src: require("@/assets/img/Upload／pic .png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "describe" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.fileName == ""
                                    ? "点击或将文件拖拽到这里上传"
                                    : _vm.fileName
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "application_letter" } },
                    [
                      _c(
                        "div",
                        { staticClass: "template" },
                        [
                          _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.download },
                            },
                            [_vm._v("下载申请函模版")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.sureBtn },
                },
                [_vm._v("确 认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBar" }, [
      _c("img", {
        staticClass: "feimaimg",
        attrs: {
          src: require("@/assets/img/airspace／apply／nor.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }