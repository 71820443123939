<template>
  <div class="allianceNav">
    <navTop />
    <div class="allianceBody">
      <div class="navBody">
        <div class="topNav">
          <div class="rightInp">
            <!-- 搜索框 -->
            <el-input
              class="alliInput"
              placeholder="请输入账户/账户名进行搜索"
              v-model="ruleForm.keyword"
              @change="search"
              @input="fucus(ruleForm.keyword)"
            >
              <i slot="suffix" @click="search" class="iconfont searchAll"
                >&#xe61d;</i
              >
            </el-input>
          </div>
        </div>
        <div class="midlleNav">
          <el-table
            height="100%"
            :data="unitList"
            style="width: 100%"
            :stripe="true"
            :header-cell-style="{ background: '#DDE6EF' }"
          >
            <el-table-column
              prop="mobile"
              label="账号"
              width="180"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="账户名称"
              align="center"
            ></el-table-column>
            <el-table-column label="账户权限" align="center">
              <span>公众门户用户</span>
            </el-table-column>
            <el-table-column label="账户类型" align="center">
              <span>门户网站注册</span>
            </el-table-column>
            <el-table-column
              prop="created_at_format"
              label="注册时间"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="delBtn(scope.row)"
                  class="deletBtn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
        <!-- <div>
          导出
        </div> -->
        <div class="footNav">

          <p class="numalliance">门户网站用户：共{{ this.total }}个<el-button v-if="role !== 2" @click="exportFile" type="primary" plain class="exportFileStyle" size="mini">导出</el-button></p>
        </div>
      </div>
    </div>
    <DelUser
      :delStr="'portal'"
      :unitUser="unitUser"
      ref="deledit"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DelUser from "@/components/messageBox/delUnitUser";
import {exportFile} from "@/utils/exportFile"
export default {
  inject: ["reload"],
  components: {
    DelUser,
  },
  data() {
    return {
      ruleForm: {
        keyword: "",
      },
      unitList: [],
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      currentParam: {},
      unitUser: {},
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    fucus(keyword) {
      if (keyword == "") {
        this.search();
      }
    },
    refresh() {
      this.search();
    },
    /**
     * 搜索转接
     */
    search() {
      this.page = 1;
      this.unitListFunc();
    },
    unitListFunc() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .getPortal(
          {
            page: this.page,
            per_page: this.per_page,
            keyword: this.ruleForm.keyword,
          },
          this.token
        )
        .then((data) => {
          this.unitList = data.data.data.data;
          this.total = data.data.data.total;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.error(err);
        });
    },
    handleNext() {
      this.page++;
      this.unitListFunc(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.unitListFunc(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.unitListFunc(this.page, this.per_page);
    },
    delBtn(item) {
      this.unitUser = item;
      setTimeout(() => {
        this.$refs.deledit.openDelEdit();
      }, 0);
    },
    exportFile() {
      this.$apis
        .exportPortal({}, this.token)
        .then((data) => {
          exportFile(data)
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token","role"]),
  },
  created() {
    this.unitListFunc();
  },
  mounted() {
    if (this.$route.query.nav == "portal") {
      this.currentUrlStorage(["首页", "用户管理", "门户网站用户"]);
    }
  },
};
</script>

<style lang="less" scoped>
.searchAll {
  cursor: pointer;
  display: block;
  margin-top: 50%;
}
@import url("../../assets/iconfont/iconfont.css");
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
.deletBtn {
  color: #ff5c5e;
}
.block {
  padding: 30px 0 0 50px;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
/deep/.el-dialog__footer {
  text-align: center;
  line-height: 70px;
}
.formBox {
  margin-top: 50px !important;
  margin-left: 30px;
  margin-bottom: 50px;
}
/deep/.el-dialog {
  width: 460px;
  height: 345px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 0;
}
.el-input {
  width: 277px;
}
th,
tr {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-size: 13px;
}
.alliInput {
  width: 310px;
  font-size: 13px;
}
.tableData {
  // height: calc(100vh - 500px);
  border-collapse: collapse;
  .tabTitle {
    height: 56px;
  }
}
.tableFile {
  height: 30px;
  color: #000000;
  font-size: 14px;
  font-family: MicrosoftYaHei;
}
.tableTitle {
  height: 30px;
  background-color: #dde6ef;
  font-size: 16px;
  color: #3c4353;
  font-weight: bold;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
}

table tr:nth-child(odd) {
  background-color: #e5eaf1;
}

table tr:nth-child(even) {
  background: #fff;
}
.footNav {
  position: relative;
  bottom: 40px;
  .numalliance {
    float: right;
    color: #606266;
    font-size: 14px;
  }
  .exportFileStyle {
    // position: absolute;
    // right: 0;
    // top: -30px;
    margin-left: 10px;
  }
}
.allianceNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 75px 30px;
  .allianceBody {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .navBody {
      width: 100%;
      height: 100%;
      padding: 0 2%;
      .topNav {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        .rightInp {
          margin-top: 50px;
        }
      }
      .midlleNav {
        margin-top: 65px;
        height: 75%;
      }
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1650px) {
  .block {
    padding: 30px 0 0 50px;
  }
  .tableTitle {
    height: 30px;
    background-color: #dde6ef;
    font-size: 16px;
    color: #3c4353;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .allianceNav {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 0px 30px 75px 30px;
    .allianceBody {
      width: 100%;
      height: calc(100vh - 150px);
      background-color: #ffffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      overflow-y: auto;
      .navBody {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        .topNav {
          height: 10px;
          display: flex;
          justify-content: space-between;
          .rightInp {
            margin-top: 20px;
          }
        }
        .midlleNav {
          margin-top: 70px;
        }
        .footNav {
          position: relative;
          bottom: 40px;
        }
      }
    }
  }
  .tableData {
    border-collapse: collapse;
    .tabTitle {
      height: 30px;
    }
  }
  .tabletr {
    height: 25px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .tableTitle {
    height: 30px;
    background-color: #dde6ef;
    font-size: 16px;
    color: #3c4353;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .allianceNav {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 0px 30px 75px 30px;
    .allianceBody {
      width: 100%;
      height: calc(100vh - 150px);
      background-color: #ffffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      overflow-y: auto;
      .navBody {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        .topNav {
          height: 10px;
          display: flex;
          justify-content: space-between;
          .rightInp {
            margin-top: 20px;
          }
        }
        .midlleNav {
          margin-top: 70px;
        }
        .footNav {
          position: relative;
          bottom: 40px;
        }
      }
    }
  }
}
</style>
