import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
export const constantRoutes = [
  // 以下为不放入公告layout
  // 手机号用户登录
  {
    path: '/login',
    component: () => {
      if (window.runEnv !== 'portal') {
        return import('@/views/login/index')
      } else {
        return import('@/views/publicPortal/gatewayLogin/index')
      }
    },
    hidden: true
  },
  // 联盟代码用户登录
  {
    path: '/otherlogin',
    component: () => import('@/views/otherLogin/otherLogin'),
    hidden: true
  },
  // 联盟代码登录
  {
    path: '/loginsuccess',
    component: () => import('@/views/loginSuccess/index.vue'),
    hidden: true
  },
  // 忘记密码-身份验证页面
  {
    path: '/forgetPsw',
    component: () => import('@/views/forgetPsw/index'),
    hidden: true
  },
  // 修改联盟代码密码
  {
    path: '/changeregpsw',
    component: () => import('@/views/changeRegPsw/index.vue'),
    hidden: true
  },
  // 用户注册
  {
    path: '/register',
    component: () => import('@/views/register/register.vue'),
    hidden: true
  },

  // 任务跳转tab数据回传
  {
    path: '/postback',
    component: () => import('@/views/dataReturn/taskPostBack.vue'),
    hidden: true,
    meta: {
      hideSidebar: true
    }
  },
  // 数据回传
  {
    path: '/datareturn',
    component: () => import('@/views/dataReturn/index'),
    hidden: true,
    meta: {
      hideSidebar: true
    }
  },





  // 调度中心
  {
    path: '/',
    component: Layout,
    children: [
      // 创建通用任务 绘制页面
      {
        path: '/newgeneric',
        component: () => import('@/views/task/newGenericTask'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },
      // {
      //   path: '/testHome',
      //   component: () => import('@/views/testHome/index.vue'),
      //   hidden: true,
      //   meta: {
      //     hideSidebar: true
      //   }
      // },
      {
        path: '/home',
        component: () => import('@/views/home'),
        name: 'Home',
        meta: {
          title: 'Home',
          icon: 'home',
          affix: true,
          hideSidebar: true
        }
      }, {
        path: '/weather',
        component: () => import('@/components/weather/weather.vue'), // 气象
        name: 'Weather',
        meta: {
          title: 'Weather',
          icon: 'weather',
          affix: true,
          hideSidebar: true
        }
      }, {
        path: '/weatherForecast',
        component: () => import('@/components/weather/weatherForecast.vue'), // 气象
        name: 'weatherForecast',
        meta: {
          title: 'weatherForecast',
          icon: 'weatherForecast',
          affix: true,
          hideSidebar: true
        }
      }, {
        path: 'achievement',
        component: () => import('@/views/achievement'),
        name: 'Achievement',
        meta: {
          title: 'Achievement',
          icon: 'achievement',
          affix: true
        }
      }, {
        path: 'released',
        component: () => import('@/views/achievement/released'),
        name: 'Released',
        meta: {
          title: 'Released',
          icon: 'released',
          affix: true
        }
      },
      {
        path: 'publish',
        component: () => import('@/views/achievement/published'),
        name: 'Publish',
        meta: {
          affix: true
        }
      },
      {
        path: 'permissionManagement',
        component: () => import('@/views/permission/index.vue'),
        name: 'permissionManagement',
        meta: {
          title: 'permissionManagement',
          icon: 'permissionManagement',
          affix: true
        }
      },
      {
        path: 'configuration',
        component: () => import('@/views/permission/configuration.vue'),
        name: 'configuration',
        meta: {
          title: 'configuration',
          icon: 'configuration',
          affix: true
        }
      },
      {
        path: 'alliance',
        component: () => import('@/views/userManagement/allianceUnit'),
        name: 'Alliance',
        meta: {
          title: 'Alliance',
          icon: 'alliance',
          affix: true
        }
      }, {
        path: 'terminal',
        component: () => import('@/views/userManagement/terminalUser'),
        name: 'Terminal',
        meta: {
          title: 'Terminal',
          icon: 'terminal',
          affix: true
        }
      },
      {
        path: 'portal',
        component: () => import('@/views/userManagement/portalUser'),
        name: 'Portal',
        meta: {
          title: 'Portal',
          icon: 'portal',
          affix: true
        }
      },
      {
        path: 'ranking',
        component: () => import('@/views/userManagement/rankingList'),
        name: 'Ranking',
        meta: {
          title: 'Ranking',
          icon: 'ranking',
        }
      },
      {
        path: 'flying',
        component: () => import('@/views/task/flying.vue'),
        name: 'Flying',
        meta: {
          title: 'Flying',
          icon: 'flying',
          affix: true,
          hideSidebar: true
        }
      },
      {
        path: 'deviceManagement',
        component: () => import('@/views/deviceManagement/index.vue'),
        name: 'deviceManagement',
        meta: {
          title: 'deviceManagement',
          icon: 'deviceManagement',
          affix: true
        }
      },
      {
        path: 'taskPush',
        component: () => import('@/views/task/taskPush.vue'),
        name: 'TaskPush',
        meta: {
          title: 'TaskPush',
          icon: 'taskPush',
          affix: true
        }
      },
      {
        path: 'blockTaskPush',
        component: () => import('@/views/task/blockTaskPush.vue'),
        name: 'blockTaskPush',
        meta: {
          title: 'blockTaskPush',
          icon: 'taskPush',
          affix: true
        }
      },
      {
        path: 'taskstat',
        component: () => import('@/views/analysis/taskStatistics.vue'),
        name: 'Taskstat',
        meta: {
          title: 'Taskstat',
          icon: 'taskstat',
          affix: true
        }
      },
      {
        path: 'userstat',
        component: () => import('@/views/analysis/userStatistics.vue'),
        name: 'Userstat',
        meta: {
          title: 'Userstat',
          icon: 'userstat',
          affix: true
        }
      },
      {
        path: 'flystat',
        component: () => import('@/views/analysis/flyStatistics.vue'),
        name: 'Flystat',
        meta: {
          title: 'Flystat',
          icon: 'flystat',
          affix: true
        }
      },
      {
        path: 'information',
        component: () => import('@/views/personalCenter/information'),
        name: 'Information',
        meta: {
          title: 'Information',
          icon: 'information',
          affix: true
        }
      },
      {
        path: 'setting',
        component: () => import('@/views/personalCenter/accountSettings'),
        name: 'Setting',
        meta: {
          title: 'Setting',
          icon: 'setting',
          affix: true
        }
      },
      {
        path: 'airspaceQuery', //空域查询
        component: () => import('../views/airspace/airspaceQuery/airspaceQuery.vue'),
        name: 'airspaceQuery',
        meta: {
          affix: true
        }
      },
      {
        path: 'airspaceApplied', //已申请空域
        component: () => import('../views/airspace/airspaceQuery/airspaceApplied.vue'),
        name: 'airspaceApplied',
        meta: {
          affix: true
        }
      },
      {
        path: 'approved', //已审批空域
        component: () => import('../views/airspace/approvalAirspace/approved.vue'),
        name: 'approved',
        meta: {
          affix: true
        }
      },
      {
        path: 'pendingApprova', //待审批空域
        component: () => import('../views/airspace/approvalAirspace/pendingApprova.vue'),
        name: 'pendingApprova',
        meta: {
          affix: true
        }
      },
      {
        path: 'airspaceCataloging', //空域编目
        component: () => import('../views/airspace/airspaceArchives/airspaceCataloging.vue'),
        name: 'airspaceCataloging',
        meta: {
          affix: true
        }
      },
      {
        path: 'authorizedAirspace', //授权空域
        component: () => import('../views/airspace/airspaceArchives/authorizedAirspace.vue'),
        name: 'authorizedAirspace',
        meta: {
          affix: true
        }
      },
      {
        path: 'taskpage', //待办任务
        component: () => import('../views/messageNotification/taskPage.vue'),
        name: 'taskpage',
        meta: {
          affix: true
        }
      },
      {
        path: 'actionpage', //标准动作指令
        component: () => import('../views/messageNotification/actionPage.vue'),
        name: 'actionpage',
        meta: {
          affix: true
        }
      },
      {
        path: 'shpage', //SHP文件
        component: () => import('../views/messageNotification/SHPage'),
        name: 'shpage',
        meta: {
          affix: true
        }
      },
      {
        path: 'taskCoordination', //发布任务
        component: () => import('../views/taskCoordination/publishTask'),
        name: 'taskCoordination',
        meta: {
          affix: true
        }
      },
      {
        path: 'taskList', //任务列表
        component: () => import('../views/taskCoordination/taskList'),
        name: 'taskList',
        meta: {
          affix: true
        }
      },
      {
        path: 'gridManagement', //网格管理
        component: () => import('../views/gridManagement/index.vue'),
        name: 'gridManagement',
        meta: {
          affix: true,
          hideSidebar: true
        }
      }
    ]
  },

  // 门户网
  {
    path: '/geta',
    component: () => import('../layout/geta.vue'),
    children: [
      // 门户成果查看
      {
        path: '/result',
        component: () => import('@/views/publicPortal/resultView/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },
      // 门户舆情
      {
        path: '/sentiment',
        component: () => import('@/views/publicPortal/publicSentiment/index.vue'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户预警发布
      {
        path: '/warning',
        component: () => import('@/views/publicPortal/earlyWarning/index.vue'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户数据申请
      {
        path: '/application',
        component: () => import('@/views/publicPortal/dataApplication/index.vue'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户发布预警
      {
        path: '/issue',
        component: () => import('@/views/publicPortal/issueEarlyWarning/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户预警详情
      {
        path: '/details',
        component: () => import('@/views/publicPortal/warningDetails/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户数据申请详情
      {
        path: '/datadetails',
        component: () => import('@/views/publicPortal/dataDetails/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户数据申请审核
      {
        path: '/auditpage',
        component: () => import('@/views/publicPortal/auditPage/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },

      // 门户全部消息
      {
        path: '/Allmessage',
        component: () => import('@/views/publicPortal/allMessage/index'),
        hidden: true,
        meta: {
          hideSidebar: true
        }
      },
      {
        path: '/publicPortalWeather',
        component: () => import('@/components/weather/weather.vue'),
        hidden: true,
        name: 'PublicPortalWeather',
        meta: {
          hideSidebar: true
        }
      },
      {
        path: '/publicWeatherForecast',
        component: () => import('@/components/weather/weatherForecast.vue'), // 气象
        name: 'weatherForecast',
        meta: {
          title: 'weatherForecast',
          icon: 'weatherForecast',
          affix: true,
          hideSidebar: true
        }
      },
      {
        path: '/getawayTask',
        component: () => import('@/views/publicPortal/getawayTask/index.vue'),
        hidden: true,
        name: 'GetawayTask',
      },
      {
        path: '/taskDetails',
        component: () => import('@/views/publicPortal/getawayTask/taskDetails.vue'),
        hidden: true,
        name: 'taskDetails',
      },
      {
        path: '/airsearch', //空域查询
        component: () => import('@/views/publicPortal/airspace/airspaceQuery/index.vue'),
        name: 'airsearch',
        meta: {
          affix: true
        }
      },
      {
        path: '/authorization', //授权空域
        component: () => import('@/views/publicPortal/airspace/authorizationAirspace/index.vue'),
        name: 'authorization',
        meta: {
          affix: true
        }
      },
      {
        path: '/personal', //个人信息
        component: () => import('@/views/publicPortal/personalInformation/index.vue'),
        name: 'personal',
        meta: {
          affix: true
        }
      },
      {
        path: '/settings', //账户设置
        component: () => import('@/views/publicPortal/accountSettings/index.vue'),
        name: 'settings',
        meta: {
          affix: true
        }
      },
      {
        path: '/Applied', //已申请数据
        component: () => import('@/views/publicPortal/appliedData/index.vue'),
        name: 'Applied',
        meta: {
          affix: true
        }
      },
      {
        path: '/issued', //已发布预警
        component: () => import('@/views/publicPortal/alertIssued/index.vue'),
        name: 'issued',
        meta: {
          affix: true
        }
      },
      {
        path: '/Airspace', //已申请空域
        component: () => import('../views/publicPortal/airspaceApplied/index'),
        name: 'Airspace',
        meta: {
          affix: true
        }
      },
      {
        path: '/datamanage', //数据申请管理
        component: () => import('../views/publicPortal/backstage/dataManage'),
        name: 'datamanage',
        meta: {
          affix: true
        }
      },
      {
        path: '/warningmanage', //预警发布管理
        component: () => import('../views/publicPortal/backstage/warningManage'),
        name: 'warningmanage',
        meta: {
          affix: true
        }
      }
    ]
  },
]


const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
