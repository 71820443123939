var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "liteMonitor",
      on: {
        mouseenter: _vm.showMoreInformation,
        mouseleave: _vm.hideMoreInformation,
      },
    },
    [
      _vm.category_name === "无人机"
        ? _c("span", [
            _c("div", {
              staticStyle: {
                width: "100%",
                height: "100%",
                position: "absolute",
                "z-index": "9999",
              },
              on: { click: _vm.openSingleMonitor },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "monitorBody",
                on: { click: _vm.openSingleMonitor },
              },
              [
                _c("singleMonitor", {
                  ref: _vm.software_code,
                  attrs: {
                    software_code: _vm.software_code,
                    order_id: _vm.mission_id,
                    isImageMap: _vm.isImageMap,
                    brandName: _vm.brandName,
                    listDeviceData: _vm.listDeviceData,
                    moreScreen: _vm.moreScreen,
                  },
                  on: {
                    setFlyArea: _vm.setFlyArea,
                    setFeimaDroneInfo: _vm.setFeimaDroneInfo,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "Footer" }, [
              _c("div", { staticClass: "monitorFooter" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    staticStyle: { height: "43px", "margin-left": "10px" },
                    attrs: { src: _vm.device_data.device_brands_icon, alt: "" },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "area" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/area.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "area_data",
                        attrs: { title: _vm.flyArea },
                      },
                      [_vm._v(_vm._s(Number(_vm.flyArea).toFixed(1)))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "flyarea",
                        staticStyle: { "font-size": "13px" },
                      },
                      [_vm._v("已飞面积")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "location" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/location.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "13px" },
                        attrs: {
                          title: _vm.province + " " + _vm.city + " " + _vm.area,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.province + " " + _vm.city + " " + _vm.area)
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.brandName === "飞马"
                ? _c(
                    "div",
                    {
                      ref: "droneInformation",
                      staticClass: "droneInformation",
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "droneInformationData",
                            attrs: { title: _vm.holder_user_data.name },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.holder_user_data.name) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "droneInformationTitle" }, [
                          _vm._v("用户名"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "droneInformationData",
                            attrs: { title: _vm.holder_user_data.mobile },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.holder_user_data.mobile) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "droneInformationTitle" }, [
                          _vm._v("手机号"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "droneInformationData",
                            attrs: { title: _vm.software_code },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.software_code) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "droneInformationTitle" }, [
                          _vm._v("飞机序列号"),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.singleDialogVisible
              ? _c(
                  "span",
                  [
                    _c("dialog-single-monitor", {
                      attrs: {
                        terminal: _vm.terminal,
                        listDeviceData: _vm.userData,
                        source: "two",
                      },
                      on: {
                        closeDialogSingleMonitor: _vm.closeDialogSingleMonitor,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("span", [
            _c("div", { staticStyle: { margin: "15px" } }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: {
                  src: _vm.deviceIcon[
                    _vm.brandName === "监控模块"
                      ? _vm.deviceData._source.associated_device_type
                      : _vm.deviceData._source.brand_name
                  ],
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deviceInformation" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.deviceData._source.unit_name)),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.detailedAddress))]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }