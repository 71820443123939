<template>
  <div style="width:100%;height:100%">
    <span v-if="runEnv==='backend'" style="width:100%;height:100%;">
      <newHome />
    </span>
    <span v-else>
       <div class="LoginHome">
      <div class="oneBar">
        <div v-show="leftTopStatus" class="echartsOne">
          <!-- echart -->
          <div class="titleWBar">
            <span class="title">任务下达</span>
            <el-button class="details" type="text" @click="jumpGmTask"
              >详情</el-button
            >
          </div>
          <div class="flyNum">
            <div class="leftBar">
              <img class="iconImg" src="../../assets/img/homeImg/right.png" alt />
              <span class="countleft">{{
                homeDataList.report_home_today_pushed
              }}</span>
              <span class="danwei">个</span>
              <p class="describe">今日已推送任务</p>
            </div>
            <div class="rightBar">
              <img class="iconImg" src="../../assets/img/homeImg/left.png" alt />
              <span class="countleft">{{
                homeDataList.report_home_today_not_pushed
              }}</span>
              <span class="danwei">个</span>
              <p class="describe">今日未推送任务</p>
            </div>
          </div>
          <div class="pushCount">
            <div class="topPush">
              <span class="pushtask">历史已推送任务</span>
              <div class="progressBar">
                <el-progress
                  class="progress"
                  :percentage="historyPush"
                  :color="historyPush | taskProgressColor"
                />
              </div>
            </div>
            <div class="botPush">
              <span class="pushtask">历史未推送任务</span>
              <div class="progressBar">
                <el-progress
                  class="progress"
                  :percentage="historyNotPush"
                  :color="historyNotPush | taskProgressColor"
                />
              </div>
            </div>
          </div>
          <div class="botImg" />
          <div class="footBtn">
            <el-dropdown
              trigger="click"
              @command="feimaTask"
              size="medium"
              class="newTask"
            >
              <el-button type="primary" round>
                <span style="display: flex">
                  <div class="but-left">新建任务</div>
                  <div class="but-right">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                </span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="feima"
                  >新建航线任务</el-dropdown-item
                >
                <el-dropdown-item command="currency"
                  >新建通用任务</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-dropdown
              size="medium"
              trigger="click"
              split-button
              type="primary"
              @command="feimaTask"
            >
              新建任务
              <el-dropdown-menu slot="dropdown" class="newTask">
                <el-dropdown-item command="feima"
                  >新建飞马任务</el-dropdown-item
                >
                <el-dropdown-item command="currency"
                  >新建通用任务</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->
            <span class="footTit" @click="publicSentiment">舆情发布</span>
          </div>
        </div>
        <div class="imgBtnOne" @click="leftTop">
          <img class="iconImg" :src="leftFoldTop" alt />
        </div>
      </div>
      <div class="twoBar">
        <div v-show="leftFootStatus" class="echartsTwo">
          <div class="titleWBar second">
            <span v-if="pointIndex === 1" class="title">飞行面积</span>
            <span v-if="pointIndex === 2" class="title">飞行里程</span>
            <span v-if="pointIndex === 3" class="title">飞行架次</span>
            <span v-if="pointIndex === 4" class="title">飞行时长</span>
            <span style="color: #dde2ee" class="title">全部无人机</span>
            <div class="point">
              <div
                @click="repeatReport(1)"
                @mouseenter="repeatReport(1)"
                title="飞行面积"
                :style="{ opacity: pointIndex === 1 ? 1 : 0.4 }"
              ></div>
              <div
                @click="repeatReport(2)"
                @mouseenter="repeatReport(2)"
                title="飞行里程"
                :style="{ opacity: pointIndex === 2 ? 1 : 0.4 }"
              ></div>
              <div
                @click="repeatReport(3)"
                @mouseenter="repeatReport(3)"
                title="飞行架次"
                :style="{ opacity: pointIndex === 3 ? 1 : 0.4 }"
              ></div>
              <div
                @click="repeatReport(4)"
                @mouseenter="repeatReport(4)"
                title="飞行时长"
                :style="{ opacity: pointIndex === 4 ? 1 : 0.4 }"
              ></div>
            </div>
          </div>
          <div id="monthTaskPushChart" />
        </div>
        <div class="imgBtnTwo" @click="leftFoot">
          <img class="iconImg" :src="leftFoldFoot" alt />
        </div>
      </div>
      <div class="thrBar">
        <div v-show="rightTopStatus" class="echartsThr">
          <div class="titleWBar">
            <span class="title">用户管理</span>
            <el-button class="details" type="text" @click="jumpTerminalUser"
              >详情</el-button
            >
          </div>
          <div id="userManagement" />
          <div class="leftNav">
            <img class="userImg" src="../../assets/img/home／union.png" alt />
            <span class="statistics">{{
              homeDataList.report_home_unit_qty
            }}</span>
            <span class="individual">个</span>
            <p class="already">授权联盟单位</p>
          </div>
          <div class="rightNav">
            <img
              class="userImg"
              src="../../assets/img/home／terminal.png"
              alt
            />
            <span class="statistics">{{ terminalOnlineCount }}</span>
            <span class="individual">个</span>
            <p class="already">当前在线用户</p>
          </div>
        </div>
        <div class="imgBtnThr" @click="rightTop">
          <img class="iconImg" :src="rightFoldtop" alt />
        </div>
      </div>
      <div class="fourBar">
        <div v-show="rightFootStatus" class="echartsFour">
          <div class="titleWBar">
            <span class="title">应急装备</span>
            <el-button class="details" type="text" @click="jumpUav"
              >详情</el-button
            >
          </div>
          <div class="leftbar">
            <div class="topNav every">
              <img
                class="userImg"
                src="../../assets/img/home／equipment／feima.png"
                alt
              />
              <span class="statistics">{{
                homeDataList.report_home_feima_plane
              }}</span>
              <span class="already">注册飞马无人机</span>
            </div>
            <div class="midNav every">
              <img
                class="userImg"
                src="../../assets/img/home／equipment／general.png"
                alt
              />
              <span class="statistics">{{
                homeDataList.report_home_other_plane
              }}</span>
              <span class="already">注册其他无人机</span>
            </div>
            <div class="footNav every">
              <img
                class="userImg"
                src="../../assets/img/home／equipment／terminal.png"
                alt
              />
              <span class="statistics">{{
                homeDataList.report_home_terminal
              }}</span>
              <span class="already">注册飞享终端</span>
            </div>
          </div>
          <div class="rightBar">
            <img src="../../assets/img/home／equipment／pic1.png" alt />
            <img
              class="ritImg"
              src="../../assets/img/home／equipment／pic2.png"
              alt
            />
          </div>
        </div>
        <div class="imgBtnFour" @click="rightFoot">
          <img class="iconImg" :src="rightFoldFoot" alt />
        </div>
      </div>
      <div class="five">
        <div v-show="rightStatus" class="echartsFive">
          <div class="titleWBar">
            <span class="title">最新任务条目</span>
            <el-button class="details" type="text" @click="jumpNewTaskline"
              >详情</el-button
            >
          </div>
          <div class="bodyBar">
            <el-tooltip
              v-for="(item, index) in taskList"
              :key="index"
              :content="
                item.project_name + '-' + item.block_name + '-' + item.task_name
              "
              placement="bottom"
              effect="light"
            >
              <div class="taskLine" @click="jumpTask(item)">
                <span class="taskName">{{ item.task_name }}</span>
                <span class="taskTime">{{ item.created_at }}</span>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="imgBtnFive" @click="rightMid">
          <img class="iconImg" :src="rightFoldMid" alt />
        </div>
      </div>

      <!-- 切换监控设备显示类型 -->
      <div class="monitor-type-list" title="飞行脚印" @click="changeMonitorType('list')" />
      <div class="monitor-type-point" title="设备脚印" @click="changeMonitorType('point')" />

      <div class="task" title="数据回传" @click="dataReturn" />
      <div class="video" @click="chat">
        <div v-if="chatDialog.unread_num > 99" class="unread">···</div>
        <div v-else-if="chatDialog.unread_num > 0" class="unread">
          {{ chatDialog.unread_num }}
        </div>
      </div>
      <!-- 地图 -->
      <!-- <newHome /> -->
      <!-- <Monitor ref="monitor" @updateMonitor="updateMonitor" :monitorType="monitorType" :monitorKey="monitorKey" /> -->
    </div>
    <publicSen ref="public" />

    <newFmTask ref="newFmTask" />
    </span>

  </div>
</template>

<script>

import newHome from "@/components/backEndHome/index.vue"

let getFlyDataObj = {};
export default {
  components: {
    newHome
  },
  data() {
    const self = this;
    return {
      runEnv: window.runEnv,

    };
  },
};
</script>

