<template>
  <el-dialog
    :visible.sync="Ydialog"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-dialogDrag
  >
    <span v-if="this.userform.is_admin == 0" class="titleBar"
      >确定将&nbsp;&nbsp;&nbsp;&nbsp;{{
        this.userform.name
      }}&nbsp;&nbsp;&nbsp;&nbsp;设置成超级管理员吗？</span
    >
    <span v-if="this.userform.is_admin == 1" class="titleBar"
      >确定取消&nbsp;&nbsp;&nbsp;&nbsp;{{
        this.userform.name
      }}&nbsp;&nbsp;&nbsp;&nbsp;超级管理员身份吗？</span
    >
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="this.userform.is_admin == 0"
        class="footBtn"
        type="primary"
        @click="yesSuper"
        >确定</el-button
      >
      <el-button
        v-if="this.userform.is_admin == 1"
        class="footBtn"
        type="primary"
        @click="noSuper"
        >确定</el-button
      >
      <el-button class="footBtn" @click="Ydialog = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  inject: ["reload"],
  props: {
    userform: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      Ydialog: false
    };
  },
  methods: {
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    open () {
      this.Ydialog=true;
    },
    yesSuper () {
      this.$apis
        .setsuper(
          {
            user_id: this.userform.id,
            is_admin: 1
          },
          this.token
        )
        .then(data => {
         
          this.Ydialog=false;
          if(data.data.status==0) {
            this.$message({
              message: data.data.message,
              type: "success"
            });
          }
          this.reload();
        })
        .catch(err => {
          this.Ydialog=false;
          console.error(err);
        });
    },
    noSuper () {
      this.$apis
        .setsuper(
          {
            user_id: this.userform.id,
            is_admin: 0
          },
          this.token
        )
        .then(data => {
         
          this.Ydialog=false;
          if(data.data.status==0) {
            this.$message({
              message: data.data.message,
              type: "success"
            });
          }
          this.reload();
        })
        .catch(err => {
          this.Ydialog=false;
          console.error(err);
        });
    }
  },
  computed: {
    ...mapState(["user","token"])
  }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 308px !important;
  height: 200px !important;
  margin-top: 38vh !important;
}
/deep/ .el-dialog__footer {
  margin-top: 20px !important;
  padding-right: 50px;
}
.footBtn {
  width: 96px;
  height: 36px;
  margin-left: 10px;
}
.titleBar {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 30px;
  line-height: 25px;
}
/deep/.el-dialog__body {
  padding: 0px 20px;
}
</style>