var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerWindow" },
    [
      _c("div", { staticClass: "loginBgc" }, [
        _c("div", { staticClass: "fmWelcome" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "interlinkage interlinkage-place" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right",
                    width: "120px",
                    trigger: "hover",
                  },
                },
                [
                  _c("vue-qr", {
                    staticClass: "qr",
                    attrs: {
                      size: 120,
                      margin: 0,
                      "auto-color": true,
                      "dot-scale": 1,
                      text: _vm.htmlUrl,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "showQr",
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          return _vm.toDownload("app")
                        },
                      },
                      slot: "reference",
                    },
                    [_vm._v("\n        下载【飞享终端】APP\n      ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toDownload("DataManager")
                    },
                  },
                },
                [_vm._v("下载【DataManager】工具")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fmLoginNav" }, [
          _c("div", { staticClass: "loginNav" }, [
            _c(
              "div",
              { staticClass: "loginBox" },
              [
                _c("span", { staticClass: "loginTitle" }, [_vm._v("注册账号")]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "loginNavbar",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        ref: "mobile",
                        class: [_vm.formMessage.mobile ? "isNotError" : ""],
                        attrs: { prop: "mobile" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号" },
                          on: { blur: _vm.blurMobile },
                          model: {
                            value: _vm.ruleForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mobile", $$v)
                            },
                            expression: "ruleForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        class: [_vm.formMessage.sms_code ? "isNotError" : ""],
                        attrs: { prop: "sms_code" },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "请输入验证码" },
                            on: { blur: _vm.blurCode },
                            model: {
                              value: _vm.ruleForm.sms_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sms_code", $$v)
                              },
                              expression: "ruleForm.sms_code",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "getVerification",
                                attrs: {
                                  slot: "suffix",
                                  type: "text",
                                  disabled: _vm.disabled,
                                  sms_code: _vm.ruleForm.sms_code,
                                },
                                on: { click: _vm.getRegister },
                                slot: "suffix",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "getCodeStyle",
                                    staticStyle: { "line-height": "22px" },
                                  },
                                  [_vm._v(_vm._s(_vm.btnTitle))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        class: [_vm.formMessage.name ? "isNotError" : ""],
                        attrs: { prop: "name" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入账户昵称,请使用真实姓名",
                          },
                          on: { blur: _vm.blurName },
                          model: {
                            value: _vm.ruleForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "name", $$v)
                            },
                            expression: "ruleForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        class: [_vm.formMessage.unit_id ? "isNotError" : ""],
                        attrs: { prop: "unit_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "fmSelect",
                            attrs: {
                              placeholder: "请选择联盟单位",
                              clearable: "",
                              filterable: "",
                            },
                            on: { blur: _vm.blurUnit },
                            model: {
                              value: _vm.ruleForm.unit_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "unit_id", $$v)
                              },
                              expression: "ruleForm.unit_id",
                            },
                          },
                          _vm._l(_vm.unitList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.unit_name + item.unit_code,
                                value: item.id,
                                unit_id: item.unit_id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        class: [_vm.formMessage.password ? "isNotError" : ""],
                        attrs: { prop: "password" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请输入密码",
                            autocomplete: "off",
                          },
                          on: { blur: _vm.blurPassword },
                          model: {
                            value: _vm.ruleForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "password", $$v)
                            },
                            expression: "ruleForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        class: [
                          _vm.formMessage.surePassword ? "isNotError" : "",
                        ],
                        attrs: { prop: "surePassword" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "password",
                            placeholder: "请确认密码",
                            autocomplete: "new-password",
                          },
                          on: { blur: _vm.blursurePassword },
                          model: {
                            value: _vm.ruleForm.surePassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "surePassword", $$v)
                            },
                            expression: "ruleForm.surePassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "SignInBar" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.agreeStatement,
                              callback: function ($$v) {
                                _vm.agreeStatement = $$v
                              },
                              expression: "agreeStatement",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { color: "rgb(96, 98, 102)" } },
                              [_vm._v("我已同意并认真阅读")]
                            ),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "#5185ff" },
                                attrs: { href: "#" },
                                on: { click: _vm.lookStatement },
                              },
                              [_vm._v("《免责声明》")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "loginBar",
                            attrs: {
                              type: "primary",
                              disabled: !_vm.agreeStatement,
                            },
                            on: { click: _vm.userRegister },
                          },
                          [_vm._v("注  册")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footerBar" },
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "register",
                        attrs: { underline: false },
                        on: { click: _vm.registerNum },
                      },
                      [_vm._v("登录账号")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dividing" }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "forgetPsw",
                        attrs: { underline: false },
                        on: { click: _vm.forgetPsw },
                      },
                      [_vm._v("忘记密码")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "免责声明",
            visible: _vm.disStatemenetData,
            width: "302px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.disStatemenetData = $event
            },
          },
        },
        [
          _c("disclaimer", { attrs: { show: _vm.disStatemenetData } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.disStatemenetData = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fmWelLogin" }, [
      _c("p", { staticClass: "sayHello" }, [_vm._v("您好,")]),
      _vm._v(" "),
      _c("p", { staticClass: "welLogin" }, [_vm._v("欢迎注册")]),
      _vm._v(" "),
      _c("p", { staticClass: "fmsider" }),
      _vm._v(" "),
      _c("p", { staticClass: "fmTitle" }, [_vm._v("智慧田长管理平台")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }