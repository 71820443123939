

class SwitchMap {
  constructor(map) {
    this.map = map;
    this.isWgs = false;
    this.tiandituLayer = new AMap.TileLayer({
      getTileUrl:
        "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=vec_w&tk="+process.env.VUE_APP_TIANDITU_KEY+"&x=[x]&y=[y]&l=[z]",
      zIndex: 100
    });
    this.tianditutext = new AMap.TileLayer({
      getTileUrl:
        "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=cva_w&tk="+process.env.VUE_APP_TIANDITU_KEY+"&x=[x]&y=[y]&l=[z]",
      zIndex: 101
    });
    this.googleLayer = new AMap.TileLayer({
      getTileUrl:
        "//wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
      zIndex: 2
    });

    // 天地图影像图层
    this.tiandituYinxiangLayer = new AMap.TileLayer({
      getTileUrl:
        // http://t0.tianditu.gov.cn/img_w/wmts
        "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=img_w&tk=" +
        process.env.VUE_APP_TIANDITU_KEY +
        "&x=[x]&y=[y]&l=[z]",
      zIndex: 100
    });
  }
  switchDifferentMap(index) {
    console.log("切换");
    return new Promise((resolve, reject) => {
      switch (index) {
        case 1:
          this.tiandituLayer.setMap(null);
          this.tiandituYinxiangLayer.setMap(null);
          this.tianditutext.setMap(null);
          this.isWgs = false;
          break;
        case 2:
          this.tiandituYinxiangLayer.setMap(null);

          this.tianditutext.setMap(this.map);
          this.tiandituLayer.setMap(this.map);
          this.isWgs = true;
          break;
        case 3:
          this.tianditutext.setMap(this.map);
          this.tiandituYinxiangLayer.setMap(this.map);
          this.isWgs = true;
          break;
        default:
          break;
      }
      resolve(this.isWgs);
    });
  }
}
export default SwitchMap
