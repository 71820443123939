var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("TopBar", {
            ref: "navBox",
            on: { Achieve: _vm.Achieve, jurisidStorage: _vm.jurisidStorage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Drawer", { ref: "child" }),
      _vm._v(" "),
      _c("Intrdrawer", { ref: "intrdrw" }),
      _vm._v(" "),
      _c("Sidebar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHasSidebar,
            expression: "isHasSidebar",
          },
        ],
        ref: "siderChild",
        staticClass: "sidebar-container",
        on: { watchachve: _vm.watchachve, intrTags: _vm.intrTags },
      }),
      _vm._v(" "),
      _c("app-main", {
        ref: "childra",
        class: [
          "app-main-backend",
          { "app-main-no-siderBar": !_vm.isHasSidebar },
        ],
        attrs: { id: "feimaapp" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }