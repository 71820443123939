<template>
  <div class="bodyBar">
    <div class="topTit">
      <span class="tit">发布预警</span>
    </div>
    <el-divider class="fenge"></el-divider>
    <div class="imgBar">
      <p class="huizhiTit">绘制区域</p>
      <img class="imgs" src="../assets/img/draw.png" alt />
    </div>
    <div class="signTit">
      <div class="signWord">
        请在地图中绘制预警范围（注：至少选择四个点进行绘制）
      </div>
      <el-button @click="eliminate" type="primary">清除重绘</el-button>
    </div>
    <div class="Dottedline"></div>
    <el-form class="formbox" ref="ruleForm" :rules="rules" :model="ruleForm">
      <el-form-item class="userForm" prop="area_name" label="预警区域名称">
        <el-input placeholder="请输入" v-model="ruleForm.area_name"></el-input>
      </el-form-item>
      <el-form-item class="userForm" prop="disaster_name" label="预警灾害">
        <el-input
          placeholder="请输入"
          v-model="ruleForm.disaster_name"
        ></el-input>
        <p class="tishi">（例如：地震，泥石流，滑坡等等）</p>
      </el-form-item>
      <el-form-item class="userForm" prop="advise" label="预警建议">
        <el-radio-group v-model="ruleForm.advise" class="radioBar degree">
          <el-radio label="及其严重">及其严重</el-radio>
          <el-radio label="严重">严重</el-radio>
          <el-radio label="中度">中度</el-radio>
          <el-radio label="一般">一般</el-radio>
          <el-radio label="轻微">轻微</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="userForm" prop="contact" label="联系方式">
        <el-input placeholder="请输入" v-model="ruleForm.contact"></el-input>
      </el-form-item>
    </el-form>
    <el-divider class="line"></el-divider>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="release">发 布</el-button>
    </div>
  </div>
</template>

<script>
/**
 * 发布预警发布组件
 */

import { string } from "clipboard";
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    latitudeAndLongitudeStr: {
      type: String,
      default: "",
    },
    dataURL: {
      type: String,
    },
  },
  data() {
    return {
      ruleForm: {
        area_name: "",
        disaster_name: "",
        advise: "",
        contact: "",
      },
      rules: {
        area_name: [{ required: true, message: "请填写预警区域名称" }],
        disaster_name: [{ required: true, message: "请填写预警灾害" }],
        advise: [{ required: true, message: "请选择预警建议" }],
        contact: [{ required: true, message: "请填写联系方式" }],
      },
      situation: "", //预警建议
      ifUpload: false, //  是否可以点击上传按钮
    };
  },
  methods: {
    //   清除重绘
    eliminate() {
      this.$emit("eliminate");
    },
    // 发布按钮
    release() {
      if (this.latitudeAndLongitudeStr == "") {
        this.$message({
          message: "请绘制预警区域",
          type: "error",
        });
        return false;
      }
      function base64URLtoFile(base64Url, filename) {
        let arr = base64Url.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
          type: mime,
        });
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let formData = new FormData();
      let file = base64URLtoFile(this.dataURL, Date.parse(new Date()) + ".png");
      
      formData.append("file", file);

      formData.append("area_name", this.ruleForm.area_name);
      formData.append(
        "survey_area_coordinate_points",
        this.latitudeAndLongitudeStr
      );
      formData.append("disaster_name", this.ruleForm.disaster_name);
      formData.append("advise", this.ruleForm.advise);
      formData.append("contact", this.ruleForm.contact);

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$apis
            .saveWarning(
              // {
              //   area_name: this.ruleForm.area_name,
              //   survey_area_coordinate_points: this.latitudeAndLongitudeStr,
              //   disaster_name: this.ruleForm.disaster_name,
              //   advise: this.ruleForm.advise,
              //   contact: this.ruleForm.contact,
              // },
              formData,
              this.token
            )
            .then((data) => {
              loading.close();
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                this.$refs.ruleForm.resetFields();
                this.$emit("eliminate");
              }
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });
        }
      });
    },
  },
  created() {},
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.bodyBar {
  width: 450px;
  height: 85%;
  position: absolute;
  left: 21px;
  top: 70px;
  background-color: #fff;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.topTit {
  width: 100%;
  height: 52px;
  float: left;
  line-height: 52px;
  margin-left: 30px;
}
.tit {
  font-size: 16px;
  color: #000000;
}
.fenge {
  float: left;
  margin: 0 0 16px 0;
}
.line {
  float: left;
  margin: 15px 0 18px 0;
}
.imgBar {
  margin-left: 40px;
  float: left;
  .huizhiTit {
    font-size: 14px;
    color: #3c4353;
  }
  .imgs {
    width: 295px;
    height: 170px;
  }
}
.signTit {
  height: 40px;
  margin-left: 40px;
  float: left;
  width: 320px;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  .signWord {
    font-size: 12px;
    color: #838a9d;
    line-height: 20px;
    width: 200px;
  }
}
.Dottedline {
  float: left;
  width: 100%;
  height: 1px;
  border: 1px dashed #a6aab8;
  margin-top: 15px;
  margin-bottom: 30px;
}
/deep/.el-input {
  width: 224px;
}
.userForm {
  margin-bottom: 18px;
  margin-left: 20px;
}
/deep/.el-form-item__label {
  width: 120px;
}
.tishi {
  font-size: 12px;
  color: #1782fb;
  margin: 0;
  float: right;
  margin-right: 50px;
}
/deep/.el-radio__label {
  padding-left: 2px;
}
/deep/.el-radio {
  margin-right: 5px;
}
.timeBar {
  float: left;
  margin-left: 50px;
  .tit {
    font-size: 13px;
    color: #3c4353;
  }
  .times {
    font-size: 13px;
    color: #838a9d;
    margin-left: 14px;
  }
}
.formbox {
  float: left;
  height: 260px;
}
.dialog-footer {
  float: right;
  margin-right: 36px;
}
</style>