<template>
  <div>
    <el-dialog
      title="任务详情"
      :visible.sync="dialogVisible"
      @click="open"
      class="taskBox"
      :modal="false"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="detailLeft">
          <p class="detailLine">
            <span class="datailTit">数据名称</span>
            <span class="dataContent" :title="taskData.name">{{ taskData.name }}</span>
          </p>
          <p class="detailLine">
            <span class="datailTit">发布账号</span>
            <span class="dataContent">{{ taskData.user_mobile }}</span>
          </p>
          <p class="detailLine">
            <span class="datailTit">数据类型</span>
            <span class="dataContent">{{ taskData.type }}</span>
          </p>
        </div>
        <div class="detailRight">
          <p class="detailLine">
            <span class="datailTit">联盟单位</span>
            <span class="dataContent" :title="taskData.unit_name">{{ taskData.unit_name }}</span>
          </p>
          <p class="detailLine">
            <span class="datailTit">发布时间</span>
            <span class="dataContent">{{ taskData.published_at }}</span>
          </p>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="detailLeft">
          <p class="detailLine">
            <span class="datailTit">任务 ID</span>
            <span class="dataContent">{{ taskData.task_id }}</span>
          </p>
          <p class="detailLine">
            <span class="datailTit">任务名称</span>
            <span class="dataContent" :title="taskData.project_name">{{ taskData.project_name }}</span>
          </p>
          <p class="detailLine">
            <span class="datailTit">任务日期</span>
            <span class="dataContent">{{ taskData.project_created_at }}</span>
          </p>
        </div>
        <div class="detailRight">
          <p class="detailLine">
            <span class="datailTit">任务类型</span>
            <span class="dataContent">{{
              taskData.task_type == 1 ? "航线任务" : "通用任务"
            }}</span>
          </p>
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    taskData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    open() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  height: inherit !important;
  width: 622px !important;
}
/deep/.el-dialog__body {
  padding: 0;
}
.detailBar {
  width: 100%;
  display: flex;
  .detailLeft {
    width: 40%;
    margin-left: 45px;
  }
  .detailRight {
    width: 40%;
    margin-right: 20px;
  }
}

.detailLine {
  line-height: 40px;
  display: flex;
  .datailTit {
    font-size: 16px;
    color: #3c4353;
    width: 100px;
  }
  .dataContent {
    flex: 1;
    width: 120px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
