// 群组
import {
    fly,
    baseURL,
    start,
    finishRes,
    finishErr,
    deploy
  } from '../fly.js'
  
  // 添加请求拦截器
  fly.interceptors.request.use(
    (request) => {
      start(request)
    }
  )
  
  // 添加响应拦截器，响应拦截器会在then/catch处理之前执行
  fly.interceptors.response.use(
    (response) => {
      finishRes(response)
    },
    (err) => {
      finishErr(err)
    }
  )


export const listEnum = (data, token) => fly.post(
  baseURL + '/common/listEnum', data, deploy(30000, token)
)


export const addEnum = (data, token) => fly.post(
  baseURL + '/common/addEnum', data, deploy(30000, token)
)

export const editEnum = (data, token) => fly.post(
    baseURL + '/common/editEnum', data, deploy(30000, token)
  )
  
  
  export const delEnum = (data, token) => fly.post(
    baseURL + '/common/delEnum', data, deploy(30000, token)
  )
