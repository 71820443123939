<template>
  <div>
    <el-dialog
      :show-close="false"
      :visible.sync="this.forgetVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div v-if="flagOne" class="registerNav">
        <div class="loginLogo">
          <img src="@/assets/img/new-logo.png" alt width="60px"/>

        </div>
        <div class="loginTit">
          <span class="loginword">智慧田长管理平台</span>
        </div>
        <div class="getawaytit">
          <span class="getaword">【公众门户网站】</span>
        </div>
        <el-form
          ref="ruleForm"
          class="loginInput"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item prop="mobile" class="regInputBar">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="sms_code" class="demo-ruleForm">
            <el-input placeholder="请输入验证码" v-model="ruleForm.sms_code">
              <el-button
                @click="getRegister"
                class="getVerification"
                slot="suffix"
                type="text"
                :disabled="disabled"
                :sms_code="ruleForm.sms_code"
              >
                <!-- 获取验证码 -->
                {{ btnTitle }}
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" class="regInputBar">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入密码"
              type="password"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item prop="surePassword" class="regInputBar">
            <el-input
              type="password"
              placeholder="请确认密码"
              v-model="ruleForm.surePassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="regBar">
            <el-button
              :loading="loading"
              class="loginBar"
              type="primary"
              @click="userRegister('ruleForm')"
              >完&nbsp;&nbsp;成</el-button
            >
          </el-form-item>
        </el-form>
        <div class="footerBar">
          <el-link class="forgetPsw" :underline="false" @click="loginBtn"
            >登录账号</el-link
          >
        </div>
      </div>
      <div class="regisSuccess" v-if="FlagTwo">
        <img
          class="loginImg"
          src="../assets/img/gatewayImg/Icon／Fill／Check-Circle-Fill.png"
          alt
        />
        <p class="rrgisWord">重置密码成功 !</p>
        <p class="logintext">{{ timeDown }}s后自动跳转</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { validPhone } from "../utils/validate.js";
let rule = /^1[0-9][0-9]{9}$/;
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.surePassword !== "") {
          this.$refs.ruleForm.validateField("surePassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      timeDown: 3,
      loading: false,
      disabled: false,
      btnTitle: "获取验证码",
      ruleForm: {
        mobile: "",
        sms_code: "",
        password: "",
        surePassword: "",
      },
      rules: {
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确验证码", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        surePassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      flagOne: true, //整个表单隐藏
      FlagTwo: false, //注册成功后的状态
    };
  },
  methods: {
    ...mapMutations(["loginStorage", "forgetStorage", "registerStorage"]),
    loginBtn() {
      this.forgetStorage(false);
      this.loginStorage(true);
    },
    // 完成按钮
    userRegister(formName) {
      // validate：验证当前表单元素中所有的规则
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fogetPassData();
        } else {
        }
      });
    },
    // 新密码数据提交
    fogetPassData() {
      this.$apis
        .resetPassword({
          mobile: this.ruleForm.mobile,
          password: this.ruleForm.password,
          sms_code: this.ruleForm.sms_code,
        })
        .then((data) => {
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.flagOne = false;
            this.FlagTwo = true;
            this.countDown();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 3秒后自动跳转
    countDown() {
      let time = 3;
      let timer = setInterval(() => {
        if (time > 0) {
          this.timeDown = time;
          time--;
        } else if (time == 0) {
          this.forgetStorage(false);
          this.loginStorage(true);
          clearInterval(timer);
        }
      }, 1000);
    },
    // 发送验证码
    getRegister() {
      // 开启倒计时
      if (!validPhone(this.ruleForm.mobile)) {
        this.$message.error("手机号不能为空或不合法");
        return;
      } else {
        // 发送验证码请求
        this.$apis
          .note({
            action: "reset_password",
            mobile: this.ruleForm.mobile,
          })
          .then((data) => {
            if (data.data.status == 0) {
              // 倒计时
              this.validateBtn();
              this.$message({
                message: "验证码发送成功",
                type: "success",
              });
            }
          })
          .catch((err) => {});
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState(["user", "token", "forgetVisible"]),
  },
};
</script>

<style lang="less" scoped>
.regBar {
  margin-top: 50px;
  .loginBar {
    font-size: 16px;
    width: 316px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(
      225deg,
      rgba(0, 110, 254, 1) 0%,
      rgba(73, 74, 255, 1) 70%
    );
  }
}
/deep/.el-form-item.is-error.isNotError .el-input__inner {
  border: 1px solid #dcdfe6;
}
/deep/.el-form-item.is-error.isNotError {
  .el-form-item__error {
    display: none !important;
  }
}
.bodyBar {
  width: 358px;
  height: 410px;
  margin: 0 auto;
  text-align: center;
}
.loginLogo {
  float: left;
  width: 100%;
}
.loginTit {
  float: left;
  width: 100%;
  margin-top: 10px;
  .loginword {
    font-size: 16px;
    color: #3c4353;
  }
}
.getawaytit {
  float: left;
  width: 100%;
  margin-top: 12px;
  .getaword {
    font-size: 14px;
    color: #3c4353;
  }
}
.loginInput {
  float: left;
  width: 100%;
  margin-top: 46px;
}
.demo-ruleForm {
  width: 316px;
  margin: 0px auto 37px auto;
}
.regInputBar {
  width: 316px;
  margin: 0px auto 25px auto;
}
.SignInBar {
  .loginBar {
    font-size: 16px;
    width: 316px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(
      225deg,
      rgba(0, 110, 254, 1) 0%,
      rgba(73, 74, 255, 1) 70%
    );
  }
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.register {
  cursor: pointer;
  color: #006efe;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}
.forgetPsw {
  cursor: pointer;
  color: #006efe;
}
/deep/.el-dialog {
  width: 400px;
  border-radius: 8px;
  border: 1px solid #a4aab9;
  height: 610px !important;
}
.registerNav {
  margin: 0 auto;
  text-align: center;
  width: 358px;
}
/deep/.el-dialog__body {
  padding: 10px 10px;
}
.regisSuccess {
  margin-top: 150px;
  text-align: center;
  .loginImg {
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
  .rrgisWord {
    font-size: 24px;
    line-height: 32px;
    height: 32px;
  }
  .logintext {
    height: 32px;
    font-size: 14px;
    color: rgba(131, 138, 157, 1);
    line-height: 32px;
  }
}
</style>
