// 群组
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

// 获取权限接口
export const permissionsGet = (data, token) => fly.get(
  baseURL + '/permissions/get', data, deploy(30000, token)
)

//  权限保存接口
export const permissionsSave = (data, token) => fly.post(
  baseURL + '/permissions/save',
  data, deploy(30000, token)
)
