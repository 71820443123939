<template>
  <div class="subject">
    <navTop />
    <div class="top">
      <el-form
        class="formbox"
        ref="ruleForm"
        :model="ruleForm"
        label-width="80px"
      >
        <el-form-item class="userForm" prop="unit_id" label="联盟单位">
          <el-select
            v-model="ruleForm.unit_id"
            filterable
            placeholder="请选择(可搜索)"
            clearable
          >
            <el-option
              v-for="(item, index) in unitData"
              :key="index"
              :label="item.unit_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="ID" label="空域ID">
          <el-input placeholder="请输入" v-model="ruleForm.ID"></el-input>
        </el-form-item>
        <el-form-item class="userForm" prop="user" label="账户">
          <el-select v-model="ruleForm.type" placeholder="请输入">
            <el-input placeholder="请输入" v-model="ruleForm.user"></el-input>
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="name" label="空域名称">
          <el-select v-model="ruleForm.medium" placeholder="请输入">
            <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
          </el-select>
        </el-form-item>
        <div class="footBtn">
          <div class="fmBtn">
            <el-button class="searchBtn" type="primary" @click="searchBtn"
              >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
            >
            <el-button
              class="reset"
              @click="resetForm('ruleForm')"
              type="primary"
              plain
              >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="center-body">
      <el-table
        height="80%"
        :stripe="true"
        :header-cell-style="{ background: '#DDE6EF' }"
        :data="tableData"
        style="width: 98%; margin-left: 1%; margin-top: 1%"
      >
        <el-table-column
          align="center"
          prop="air_space_id"
          label="授权空域ID"
          class="number"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="air_space_name"
          label="空域名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="mobile"
          label="账户"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="flight_start_time"
          label="起始日期"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="flight_end_time"
          label="结束日期"
        ></el-table-column>
        <el-table-column align="center" prop="hookTask" label="空域状态">
          <template slot-scope="scope">
            <span
              v-if="Date.parse(scope.row.flight_end_time) < new Date()"
              type="text"
              >过期</span
            >
            <span
              v-if="Date.parse(scope.row.flight_end_time) > new Date()"
              type="text"
              >存续</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="taskDetails"
          label="批件附件"
          width="240"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="download(scope.row.approval_document_1)"
              plain
              >空域批件.pdf</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="download(scope.row.approval_document_2)"
              plain
              >任务批件.pdf</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" prop="uploadMediaTable" label="查看">
          <template slot-scope="scope">
            <el-button type="text" :underline="false" @click="watch(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @current-change="currentchange"
          @next-click="handleNext"
          @prev-click="handlePrev"
          :current-page="current_page"
          :page-size="this.per_page"
          layout="total,  prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      ruleForm: {
        unit_id: "",
        air_space_id: "",
        air_space_name: "",
        mobile: "",
      },
      tableData: [],
      unitData: [],
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    upload() {},
    searchBtn() {
      this.page = 1;
      this.authorizeList();
    },
    // 授权空域编目
    authorizeList() {
      this.$apis
        .authorizeList(
          {
            unit_id: this.ruleForm.unit_id,
            air_space_id: this.ruleForm.air_space_id,
            air_space_name: this.ruleForm.air_space_name,
            mobile: this.ruleForm.mobile,
            per_page: 8,
            page: this.page,
          },
          this.token
        )
        .then((data) => {
          this.tableData = data.data.data.data;
          this.total = data.data.data.total;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 分页
    handleNext() {
      this.page++;
      this.authorizeList();
    },
    handlePrev() {
      this.page--;
      this.authorizeList();
    },
    currentchange(page) {
      this.page = page;
      this.authorizeList();
    },
    // 获取联盟ID
    getUnitId(param) {},
    // 联盟列表
    unitsList() {
      this.$apis
        .unitList({ per_page: 1000000000000 }, this.token, "?page=" + this.page)
        .then((data) => {
          this.unitData = this.unitData.concat(data.data.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 查看
    watch(param) {},
    // 下载附件
    download(param) {
      window.open(param);
    },
  },
  created() {
    this.unitsList();
    this.authorizeList();
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "aired") {
      this.currentUrlStorage(["首页", "空域查询", "已加载授权空域"]);
    }
  },
};
</script>

<style lang="less" scoped>
.el-tag.el-tag--success {
  background-color: #e7faf0;
  border-color: #1cd0bd;
  color: #1cd0bd;
  width: 80px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  margin-left: 70px;
  width: 25%;
}
.online {
  color: #409eff;
  font-size: 14px;
}
.formbox {
  float: left;
  margin-top: 2%;
  width: 100%;
}
.fmBtn {
  float: right;
  width: 35%;
}
.block {
  padding: 20px 0 0 70px;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 0 15px 2% 15px;
  background-color: #f0f0f0;

  .top {
    width: 100%;
    display: flow-root;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .nape {
      margin: 2.664% 2% 0 0;
      display: inline-block;
      width: 22%;
      height: 40px;
      span {
        padding-right: 8.3%;
      }
      .import {
        display: inline-block;
        width: 58.363%;
      }
    }
  }
  .center-body {
    width: 100%;
    height: 80%;
    margin-top: 15px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    position: relative;
    overflow-y: auto;
    /deep/ .el-table .cell {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    /deep/ .el-table .cell:hover {
      text-overflow: inherit;
      overflow: visible;
      white-space: pre-line;
    }
    .pagination {
      position: absolute;
      bottom: 4.973%;
      right: 19.216%;
    }
  }
}
@media only screen and (max-width: 1344px) {
  .subject {
    .center-body {
      height: 67%;
    }
  }
}
</style>
