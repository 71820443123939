<template>
  <div>
    <div class="appBar">
      <div class="bodyBar">
        <div class="signBar">
          <span class="title">预警发布</span>
          <el-input
            v-model="keywordAll"
            class="alliInput"
            placeholder="请输入区域名称进行搜索"
            @keyup.enter.native="searchRegion"
            @input="fucusAll(keywordAll)"
          >
            <i slot="suffix" class="iconfont searchAll" @click="searchRegion"
              >&#xe61d;</i
            >
          </el-input>
        </div>
        <div class="center-body">
          <el-table
            height="100%"
            :stripe="true"
            :data="tableData"
            style="width: 100%; margin-top: 5%"
          >
            <el-table-column
              align="center"
              prop="catalog"
              label="区域名称"
              width="300"
            >
              <template slot-scope="scope" class="taskLine">
                <div class="taskBar">
                  <img
                    class="taskImg"
                    :src="
                      scope.row.pic !== ''
                        ? scope.row.pic
                        : '../../../assets/img/test.jpg'
                    "
                    alt
                  />
                  <span class="taskWord">{{ scope.row.area_name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="disaster_name"
              label="预警灾害"
              width="180"
            />
            <el-table-column
              align="center"
              prop="advise"
              label="预警建议"
              width="180"
            />
            <el-table-column
              align="center"
              prop="mobile"
              label="账户"
              width="140"
            />
            <el-table-column
              align="center"
              prop="created_at"
              label="创建时间"
            />
            <el-table-column align="center" prop="taskDetails" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="upload(scope.row)"
                  >详情</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="block">
            <el-pagination
              :current-page="current_page"
              :page-size="per_page"
              layout="total,  prev, pager, next, jumper"
              :total="total"
              @current-change="currentchange"
              @next-click="handleNext"
              @prev-click="handlePrev"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      keywordAll: "",
      tableData: [],
      current_page: 1, // 当前页
      total: 0, // 总条目数
      per_page: 6, // 每页多少条
      page: 1,
    };
  },
  created() {
    this.getWarningList();
  },
  methods: {
    fucusAll(keywordAll) {
      if (keywordAll == "") {
        this.searchRegion();
      }
    },
    upload(param) {
      this.$router.push({ path: "/details", query: { id: param.id } });
    },
    getWarningList() {
      this.$apis
        .getWarningList(
          { area_name: this.keywordAll, per_page: 6, page: this.page },
          this.token
        )
        .then((data) => {
         
          if (data.data.status == 0) {
            this.tableData = data.data.data.data;
            this.total = data.data.data.total;
            this.current_page = data.data.data.from;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 分页
    handleNext() {
      this.page++;
      this.getWarningList(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.getWarningList(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.getWarningList(this.page, this.per_page);
    },
    // 搜索按钮
    searchRegion(){
      this.page = 1;
      this.getWarningList();
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
.taskBar {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  line-height: 74px;
}
.block {
  position: absolute;
  bottom: 20px;
  margin-left: 10px;
}
.taskImg {
  width: 74px;
  height: 74px;
  border-radius: 4px;
}
.taskWord {
  text-align: center;
  margin-left: 15px;
}
/deep/.el-table .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper {
  height: 100% !important;
}
.appBar {
  width: 100%;
  height: calc(100vh - 50px);
  float: left;
  background-color: #f0f0f0;
}
.bodyBar {
  // height: calc(100vh - 50px);
  height: 98%;
  width: 70%;
  float: left;
  margin-left: 15%;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 20px 0px 20px;
}
.signBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #828a9e;
    font-size: 16px;
  }
}
.searchAll {
  cursor: pointer;
  margin-top: 9px;
  display: block;
}
.alliInput {
  width: 300px;
}
</style>
