import utils from "../utils/public"; // 任务公用方法
export function exportFile(data) {
  let fileUrl = window.URL.createObjectURL(
    new Blob([data.data], { type: "application/xlsx;charset=UTF-8" })
  );
  // let temp = data.headers["content-disposition"].split(";")[1].split("filename=")[1];
  console.log(data.headers["content-disposition"]);
  if (data.headers["content-disposition"] !== undefined) {
    let fileName = "";
    try {
      if (data.headers["content-disposition"].split(";")[2] === undefined) {
        fileName = data.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];
      } else {
        fileName = data.headers["content-disposition"]
          .split(";")[2]
          .split("''")[1];
      }
    } catch (err) {
      fileName = "未知命名.xlsx";
    }
    // let
    // // let fileName = "测试.xlsx";

    fileName = decodeURIComponent(fileName);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    utils.$message("非法操作", "warning");
  }
}
