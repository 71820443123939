<template>
  <div class="allSpell">
    <!-- <div class="allSpell" > -->
    <div
      v-show="spellitWarning"
      class="popContainer"
      @click="warningCancelsClick"
    ></div>
    <div class="spell-it">
      <div class="spellitStatus" v-show="isSpelling">
        <img
          src="@/assets/img/homeDialog/littleBtn/refresh.png"
          alt=""
          v-show="isSpelling"
        />
        <!-- <span>{{total}}</span> -->
      </div>
      <div @click="spellClick()" class="spell-it-btn"></div>

      <div
        ref="realTime"
        @click="wholeCourseStatus !== '结束' && realTimeClick()"
        style="display: none"
        class="menu-btn"
        :class="[
          wholeCourseStatus === '结束' ? 'real-time-btn-grey' : 'real-time-btn',
        ]"
      >
        <!-- <div class="spellitStatus" style="left: 115px;">1</div> -->
        实时快拼
      </div>
      <div
        ref="theWholeWay"
        @click="realTimeStatus !== '结束' && theWholeWayClick()"
        style="display: none"
        class="menu-btn"
        :class="[
          realTimeStatus === '结束'
            ? 'the-whole-way-btn-grey'
            : 'the-whole-way-btn',
        ]"
      >
        <!-- <div class="spellitStatus" style="left: 250px;" >1</div> -->
        全程快拼
      </div>
      <br />
      <!-- <div style="position: absolute; top: -34px; right: -111px">
        <el-input v-model="mission_id"></el-input>
      </div> -->
    </div>
    <!-- 快拼弹框 -->
    <div v-show="springFrame" class="spring-frame">
      <div class="spring-frame-top"></div>
      <div class="spring-frame-tab">
        <div class="frame-tab-name">{{ frameTabName }}</div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="detailsContent"
          placement="bottom"
        >
          <div id="account-for" class="frame-tab-attention"></div>
        </el-tooltip>
        <div
          id="shut-down"
          @click="shutDownClick()"
          class="frame-tab-shutdown"
        ></div>
      </div>
      <div id="spring-list" class="spring-list" v-loading="loading">
        <span v-if="realTimeAchievements.length > 0">
          <div
            v-for="(item, index) in realTimeAchievements"
            :key="index"
            class="spring-list-okay"
          >
            <div class="spring-list-taskData">
              <div class="spring-list-taskName">{{ item.name }}</div>
              <div class="spring-list-taskTime">
                <!-- {{ item.StartTime }}
                <span v-show="item.end_at !== ''">-</span>
                {{ item.EndTime }} -->
              </div>
            </div>
            <div class="spring-list-taskOperation">
              <div class="spring-list-taskTarget">
                {{ item.status }}拼图：<span
                  style="color: rgba(38, 129, 255, 1)"
                  >{{ item.images_qty }}张</span
                >
              </div>
              <div v-show="item.status === '正在'">
                <img
                  src="@/assets/img/homeDialog/littleBtn/refresh.png"
                  alt=""
                />
              </div>
              <el-popover
                placement="bottom"
                width="129"
                trigger="click"
                popper-class="noDownFile"
              >
                <div class="dowmloadDio">
                  <div class="downItem">
                    <div>下载成果</div>
                    <img
                      src="@/assets/img/homeDialog/spellBtn/download.png"
                      alt=""
                      @click="downAchievements(item.ResultPath, item)"
                    />
                  </div>
                  <!-- <div class="downItem">
                    <div>{{ downPhoto }}</div>
                    <img
                      v-show="downPhoto === '下载原片'"
                      src="@/assets/img/homeDialog/spellBtn/download.png"
                      alt=""
                      @click="downAchievements(item.ResultPath, item)"
                    />
                    <div
                      v-show="downPhoto === '原片压缩中'"
                      style="height: 16px; width: 16px"
                    >
                      <i class="el-icon-loading"> </i>
                    </div>
                  </div> -->
                </div>
                <div
                  slot="reference"
                  v-show="item.status === '完成' && item.path !== ''"
                  class="download-time btn-style"
                >
                  七天内下载
                </div>
              </el-popover>
              <el-popover
                placement="bottom"
                title=""
                width="128"
                trigger="hover"
                popper-class="noDownFile"
                content="已超过有效期，有效期为7天。"
              >
                <div
                  slot="reference"
                  v-show="item.status === '完成' && item.path === ''"
                  class="download-time btn-style no-download"
                >
                  七天内下载
                </div>
              </el-popover>
              <div
                v-show="item.status === '完成'"
                class="spring-list-delBtn btn-style"
                @click="delItem(item)"
              >
                删除
              </div>
            </div>
          </div>
        </span>
        <span v-else>
          <div class="noSepllIt">暂无拼图</div>
        </span>
      </div>
      <div
        id="spring-operation"
        class="spring-operation"
        @click="startOrPause()"
        v-if="frameTabName === '全程快拼'"
      >
        {{ status }}拼图
      </div>
      <div
        id="spring-operation"
        class="spring-operation"
        @click="startOrPause()"
        v-if="
          frameTabName === '实时快拼' && spellitNodeUrl !== '' && !isHistory
        "
        :class="{ canStatus: isHistory }"
      >
        {{ status }}拼图
      </div>
    </div>

    <!-- 快拼警告弹框 -->
    <div id="warning" class="spell-warning" v-show="spellitWarning">
      <div class="warning-txt">
        <div class="warning-txt-icon"></div>
        <div id="warning-name">确认要{{ dioText }}拼图吗？</div>
      </div>
      <div class="warning-btn">
        <div @click="warningCancelsClick()" id="warning-cancels">取消</div>
        <div @click="warningConfirmedClick()" id="warning-confirmed">确定</div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    mission_id: {
      type: String,
    },
    flight_task_name: {
      type: String,
    },
    isHistory: {},
  },
  data() {
    return {
      requestUrl: "",
      realTime: "",
      theWholeWay: "",
      springFrame: false,
      frameTabName: "实时快拼",
      detailsContent: "“实时快拼”即从当前帧开始进行快拼。",
      realTimeStatus: "开始",
      wholeCourseStatus: "开始",
      realTimeAchievementsList: [],
      realTimeAchievements: [],
      wholeAchievements: [],
      spellitWarning: false, //警告弹窗
      dioText: "结束", //警告弹窗文本
      requestTimer: null, //定时请求器
      spellitButlerUrl: process.env.VUE_APP_SPELLIT_URL,
      spellitNodeUrl: process.env.VUE_APP_SPELLIT_URL, //快拼节点地址
      // mission_id: "2f1f2a48ded34875a3e17883d1f5d260",
      delId: -1, //要删除的成果编号
      // visible: false,
      getNodeCounter: 5, //获取有效节点次数，此范围获取不到时返回错误提示
      downPhoto: "下载原片",
      loading: false,
      housekeeperList: false, //是否是在管家的获取的列表
      canEntire: false, //是否可以全程快拼
      getListTimer: null,
      useUrl: "", //历史使用过的url，来判断历史回放是否显示快拼按钮
    };
  },
  filters: {
    // 过滤出时间
    filTime(val) {
      if (val && val !== "") {
        let time = val.split(" ")[1];
        return time;
      }
      return "";
    },
  },
  computed: {
    status: function () {
      if (this.frameTabName === "实时快拼") {
        return this.realTimeStatus;
      } else {
        return this.wholeCourseStatus;
      }
    },
    isSpelling() {
      if (this.realTimeStatus === "结束" || this.wholeCourseStatus === "结束") {
        // this.spellClick()
        return true;
      }
      return false;
    },
    achievementsList() {
      if (this.frameTabName === "实时快拼") {
        return this.realTimeAchievements;
      } else {
        return this.wholeAchievements;
      }
    },
  },
  watch: {
    realTimeAchievements(newVal, oldVal) {
      if (!this.isHistory) {
        this.canEntire = true;
      } else {
        if (newVal.length === 0) {
          this.canEntire = false;
        } else {
          newVal.forEach((item) => {
            if (item.path !== "") {
              this.canEntire = true;
            } else {
              this.canEntire = false;
              return;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.realTime = this.$refs.realTime;
    this.theWholeWay = this.$refs.theWholeWay;
  },
  methods: {
    /**
     * 快拼默认按钮
     */
    spellClick() {
      this.realTime.style.display === "none"
        ? (this.realTime.style.display = "block")
        : (this.realTime.style.display = "none");
      this.theWholeWay.style.display === "none"
        ? (this.theWholeWay.style.display = "block")
        : (this.theWholeWay.style.display = "none");
    },
    // 封装axios请求
    requestData(requestUrl, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(this.spellitNodeUrl + requestUrl, params, {
            headers: {
              Accept: "*/*",
              "Content-Type": "multipart/form-data", //将表单数据传递转化为text/plain类型
            },
          })
          .then((res) => {
            resolve(res.data);
          });
      });
    },
    /**
     * 获取状态
     */
    getStatus() {
      let params = new FormData();
      params.append("mission_id", this.mission_id);
      this.requestData("/quickmap/status", params).then((res) => {
        if (res.data.result) {
          res.data.result.forEach((res) => {
            this.$emit("spellitImg", res);
          });
        }
        switch (res.data.status) {
          case "realtime_starting":
            this.realTimeStatus = "结束";
            this.frameTabName = "实时快拼";
            this.realTime.style.display = "block";
            this.theWholeWay.style.display = "block";
            this.springFrame = true;
            // this.realTimeAchievementsList.push({
            //   name: this.flight_task_name,
            //   create_at:
            //     res.data.result !== null && res.data.result.length !== 0
            //       ? res.data.result[0].created_at || "none"
            //       : "none",
            //   status: "正在",
            //   images_qty: 0,
            // });
            this.requestTimer = setInterval(() => {
              this.getQuickMapresult();
            }, 6000);
            break;
          case "global_starting":
            this.wholeCourseStatus = "结束";
            this.frameTabName = "全程快拼";
            this.realTime.style.display = "block";
            this.theWholeWay.style.display = "block";
            this.springFrame = true;
            // this.wholeAchievements.push({
            //   name: this.flight_task_name,
            //   create_at:
            //     res.data.result !== null && res.data.result.length !== 0
            //       ? res.data.result[0].created_at || "none"
            //       : "none",
            //   status: "正在",
            //   images_qty: 0,
            // });
            this.requestTimer = setInterval(() => {
              this.getQuickMapresult();
            }, 6000);
            break;
          case "idle":
            this.realTimeStatus = "开始";
            this.wholeCourseStatus = "开始";
            break;
          default:
            break;
        }
        this.getSpellitList(false).then(res=>{
          this.getLastData()
        });
      });
    },
    // 请求快拼列表
    getSpellitList(isClear = true) {
      return new Promise((resolve, reject) => {
        // this.realTimeAchievements = [];
        if (isClear) {
          this.realTimeAchievementsList = [];
          this.wholeAchievements = [];
        }

        let params = new FormData();
        params.append("mission_id", this.mission_id);
        this.requestData("/quickmap/result", params).then((res) => {
          // console.log(res);
          res.data.result.forEach((item, index) => {
            item["name"] = this.flight_task_name + "_" + (index + 1);
            if (item.EndTime !== "") {
              item["status"] = "完成";
              item["images_qty"] = item.ImageCount;
            } else {
              item["status"] = "正在";
              item["images_qty"] = 0;
            }

            // item.images_qty =
            if (item.TaskName.indexOf("realtime") !== -1) {
              this.realTimeAchievementsList.push(item);
            } else {
              this.wholeAchievements.push(item);
            }
          });
          if (this.frameTabName === "实时快拼") {
            if (
              JSON.stringify(this.realTimeAchievements) !==
              JSON.stringify(this.realTimeAchievementsList)
            ) {
              this.realTimeAchievements = JSON.parse(
                JSON.stringify(this.realTimeAchievementsList)
              );
              // resolve(this.realTimeAchievementsList);

            }
          } else {
            if (
              JSON.stringify(this.realTimeAchievements) !==
              JSON.stringify(this.wholeAchievements)
            ) {
              this.realTimeAchievements = JSON.parse(
                JSON.stringify(this.wholeAchievements)
              );

            }
          }
          resolve(this.realTimeAchievements);
        });
      });
    },
    /**
     * 解析获取到快拼列表
     */
    parsingList(data) {
      data.forEach((item, index) => {
        item["name"] = this.flight_task_name + "_" + (index + 1);
        switch (Number(item.type)) {
          case 1:
            item["status"] = "正在";
            this.realTimeAchievementsList.push(item);

            break;
          case 2:
            item["status"] = "完成";
            this.realTimeAchievementsList.push(item);

            break;
          case 11:
            item["status"] = "正在";
            this.wholeAchievements.push(item);

            break;
          default:
            item["status"] = "完成";
            this.wholeAchievements.push(item);

            break;
        }
      });
      if (this.frameTabName === "实时快拼") {
        if (
          JSON.stringify(this.realTimeAchievements) !==
          JSON.stringify(this.realTimeAchievementsList)
        ) {
          this.realTimeAchievements = JSON.parse(
            JSON.stringify(this.realTimeAchievementsList)
          );
        }
      } else {
        if (
          JSON.stringify(this.realTimeAchievements) !==
          JSON.stringify(this.wholeAchievements)
        ) {
          this.realTimeAchievements = JSON.parse(
            JSON.stringify(this.wholeAchievements)
          );
        }
      }
    },
    /**
     * 实时快拼
     */
    realTimeClick() {
      this.springFrame = true;
      this.frameTabName = "实时快拼";
      this.detailsContent = "“实时快拼”即从当前帧开始进行快拼。";
      this.realTimeAchievements = JSON.parse(
        JSON.stringify(this.realTimeAchievementsList)
      );
    },
    /**
     * 全程快拼
     */
    theWholeWayClick() {
      this.springFrame = true;
      this.frameTabName = "全程快拼";
      this.detailsContent = "“全程快拼”即从飞行起的第一帧到最后一帧进行快拼。";
      this.realTimeAchievements = JSON.parse(
        JSON.stringify(this.wholeAchievements)
      );
    },
    // 获取成果数据
    getQuickMapresult() {
      let params = new FormData();
      params.append("mission_id", this.mission_id);
      this.requestData("/quickmap/status", params).then((res) => {
        if (res.data.result && res.data.result.length > 0) {
          this.$set(
            this.realTimeAchievements[this.realTimeAchievements.length - 1],
            "images_qty",
            res.data.result.length
          );
          res.data.result.forEach((res) => {
            // console.log(res);
            this.$emit("spellitImg", res);
          });
        }
      });
    },
    // 点击开始快拼或结束快拼
    startOrPause() {
      if (this.frameTabName === "实时快拼") {
        if (this.realTimeStatus === "开始") {
          this.openSpellit();
        } else {
          this.closeSpellit();
        }
      } else {
        if (this.wholeCourseStatus === "开始") {
          // if (this.canEntire) {
          this.openSpellit();
          // }
        } else {
          this.closeSpellit();
        }
      }
    },
    //开始拼图
    openSpellit() {
      let spellType = "/quickmap/operation";
      let action;
      let achievementName;
      let params = new FormData();
      params.append("mission_id", this.mission_id);
      if (this.frameTabName === "实时快拼") {
        params.append("action", "start_realtime");
        achievementName = "realTimeAchievements";
      } else {
        params.append("action", "start_global");

        achievementName = "realTimeAchievements";
      }
      this.requestData(spellType, params).then((res) => {
        if (res.status === 0) {
          const time = this.getNowTime();
          this[achievementName].push({
            name: this.flight_task_name,
            create_at: time,
            status: "正在",
            images_qty: 0,
          });
          if (this.frameTabName === "实时快拼") {
            this.realTimeStatus = "结束";
          } else {
            this.wholeCourseStatus = "结束";
          }
          this.requestTimer = setInterval(() => {
            this.getQuickMapresult();
          }, 6000);
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    // 点击关闭拼图按钮
    closeSpellit() {
      this.dioText = "结束";
      this.spellitWarning = true;
    },
    // 删除快拼项目
    delItem(item) {
      console.log(item);
      this.dioText = "删除此次";
      this.spellitWarning = true;
      this.delId = item.TaskId;
    },
    /**
     * 关闭快拼弹框
     */
    shutDownClick() {
      this.springFrame = false;
      // this.spellitNodeUrl = "";
    },

    // 点击弹窗的取消
    warningCancelsClick() {
      this.spellitWarning = false;
    },
    //
    closeIntervalStart() {
      console.log("关闭定时器", 453453453);
      clearInterval(this.requestTimer);
      clearInterval(this.getListTimer);
    },
    // 关闭定时器
    closeInterval() {
      console.log("关闭定时器");
      clearInterval(this.requestTimer);
      let spellType = "/quickmap/operation";
      let params = new FormData();
      if (this.realTimeStatus === "结束" || this.wholeCourseStatus === "结束") {
        if (this.realTimeStatus === "结束") {
          params.append("action", "stop_realtime");
        } else {
          params.append("action", "stop_global");
        }
        this.requestData(spellType, params).then((res) => {
          // console.log(res);
          this.$message(res.message);
        });
      }
    },
    // 点击弹窗的确定
    warningConfirmedClick() {
      clearInterval(this.requestTimer);
      let spellType = "/quickmap/operation";
      let params = new FormData();
      params.append("mission_id", this.mission_id);
      if (this.dioText === "结束") {
        this.spellitWarning = false;
        if (this.frameTabName === "实时快拼") {
          this.realTimeStatus = "开始";
          params.append("action", "stop_realtime");
        } else {
          this.wholeCourseStatus = "开始";
          params.append("action", "stop_global");
        }

        this.loading = true
        this.requestData(spellType, params).then((res) => {
          this.$message(res.message);
          let temTimer = null;
          let count = 5;
          let dataCount = this.realTimeAchievements.length


          this.getSpellitList().then((res) => {
            if (res.length !== dataCount) {
              temTimer = setInterval(() => {
                count--;
                this.getSpellitList().then((res) => {
                  if (res.length === dataCount || count===0) {
                    this.loading = false
                    this.getLastData()
                    clearInterval(temTimer);
                  }
                });
              }, 5000);
            }else {
              this.loading = false
              this.getLastData()
            }
          });
        });
      } else {
        // 做删除项目业务逻辑
        this.spellitWarning = false;
        let params = new FormData();
        params.append("mission_id", this.mission_id);
        params.append("task_id", this.delId);
        this.requestData("/quickmap/delete",params).then((res) => {
          this.$message(res.message);
          this.getSpellitList();
        });
      }
    },
    getLastData(){
      if (this.realTimeStatus === '结束' || this.wholeCourseStatus === '结束'){
        const time = this.getNowTime();
        this.realTimeAchievements.push({
            name: this.flight_task_name,
            create_at: time,
            status: "正在",
            images_qty: 0,
        })
      }
    },
    // 停止快拼后获取列表，因为压缩问题，采用获取长度不同的数据时才结束请求
    cycleGetList() {
      this.getAgainSpellitList().then((res) => {
        if (
          res.length !==
          this.realTimeAchievementsList.length + this.wholeAchievements.length
        ) {
          this.loading = false;
          this.realTimeAchievementsList = [];
          this.wholeAchievements = [];
          this.parsingList(res);
        } else {
          setTimeout(() => {
            this.cycleGetList();
          }, 3000);
        }
      }); //节点获取列表
    },
    // 下载成果
    downAchievements(path, item) {
      if (path && path !== "") {
        window.open(path, "_self");
      } else {
        if (item.result_url !== -1 && item.result_url !== "") {
          window.open(item.result_url, "_self");
        } else {
          this.$message("暂无下载地址");
        }
      }
    },
    // 下载原始数据
    downOriginal(id, item) {
      this.downPhoto = "原片压缩中";
      if (this.spellitNodeUrl !== "") {
        this.requestData("/get_quick_map_raw_data", {
          action: "get_quick_map_raw_data",
          mission_id: this.mission_id,
        }).then((res) => {
          if (res.message.indexOf("5秒") !== -1) {
            setTimeout(() => {
              this.downOriginal(id);
            }, 5000);
          } else {
            let path = "";
            res.data.forEach((item) => {
              if (item.id === id) {
                path = item.path;
              }
            });
            if (path !== "") {
              window.open(path, "_self");
              this.downPhoto = "下载原片";
            }
          }
        });
      } else {
        if (item.pic_src_url !== "") {
          this.downPhoto = "下载原片";
          window.open(item.pic_src_url, "_self");
        } else {
          this.downPhoto = "下载原片";
          this.$message("暂无下载地址");
        }
      }
    },
    // 获取当前时间
    getNowTime() {
      let timeStamp = new Date();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      let time = hh + ":" + mm + ":" + ss;
      return time;
    },
    closeHistorySpellit() {
      // if (this.wholeCourseStatus === "结束") {
      //   this.requestData("/stop_all", {
      //     action: "/stop_all",
      //     mission_id: this.mission_id,
      //   }).then((res) => {
      //     // do nothing
      //   });
      // }
    },
  },
  beforeDestroy() {
    if (this.isHistory) {
      this.closeHistorySpellit();
    }
  },
};
</script>
<style lang="less">
// 快拼
.spell-it {
  // position: fixed;
  // top: 10%;
  // left: 30px;
  display: flex;
  background: rgba(3, 13, 28, 0.65);
  border-radius: 32px;
  padding: 6px;
  z-index: -1;
  // position: relative;
  .spellitStatus {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #007aff;
    line-height: 20px;
    text-align: center;
    left: 41px;
  }
  div {
    box-sizing: border-box;
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    color: #fff;
  }

  .spell-it-btn {
    width: 52px;
    border-radius: 50%;
    background: url("../../assets/img/homeDialog/spellBtn/Jigsaw-puzzle-nor.png")
      no-repeat center center;
    background-size: 52px 52px;

    &:hover {
      background-image: url("../../assets/img/homeDialog/spellBtn/Jigsaw-puzzle-hov.png");
    }

    &:active {
      background-image: url("../../assets/img/homeDialog/spellBtn/Jigsaw-puzzle-pre.png");
    }

    &:focus {
      background-image: url("../../assets/img/homeDialog/spellBtn/Jigsaw-puzzle-gray.png");
    }
  }

  .menu-btn {
    padding-left: 60px;
    padding-right: 20px;
  }

  .real-time-btn {
    background: url("../../assets/img/homeDialog/spellBtn/real-time-nor.png")
      no-repeat left center;
    background-size: 52px 52px;
    margin-left: 20px;

    &:hover {
      background-image: url("../../assets/img/homeDialog/spellBtn/real-time-hov.png");
    }

    &:active {
      background-image: url("../../assets/img/homeDialog/spellBtn/real-time-pre.png");
    }
  }
  .real-time-btn-active {
    background-image: url("../../assets/img/homeDialog/spellBtn/real-time-pre.png");
  }
  .real-time-btn-grey {
    background: url("../../assets/img/homeDialog/spellBtn/real-time-gray.png")
      no-repeat left center;
    background-size: 52px 52px;
    margin-left: 20px;
    cursor: no-drop;
    color: #4f5763;
  }

  .the-whole-way-btn {
    background: url("../../assets/img/homeDialog/spellBtn/the-whole-way-nor.png")
      no-repeat left center;
    background-size: 52px 52px;

    &:hover {
      background-image: url("../../assets/img/homeDialog/spellBtn/the-whole-way-hov.png");
    }

    &:active {
      background-image: url("../../assets/img/homeDialog/spellBtn/the-whole-way-pre.png");
    }
  }
  .the-whole-way-btn-active {
    background-image: url("../../assets/img/homeDialog/spellBtn/the-whole-way-pre.png");
  }
  .the-whole-way-btn-grey {
    background: url("../../assets/img/homeDialog/spellBtn/the-whole-way-gray.png")
      no-repeat left center;
    background-size: 52px 52px;
    cursor: no-drop;
    color: #4f5763;
  }
}
// 快拼弹框
.spring-frame {
  width: 400px;
  height: 493px;
  background: rgba(3, 13, 28, 0.75);
  margin-top: 10px;
  position: absolute;
  .spring-frame-top {
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, #00ffc9 0%, #0064ff 100%);
  }

  .spring-frame-tab {
    width: 100%;
    height: 62px;
    line-height: 62px;
    display: flex;
    position: relative;

    div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      background-size: 100% 100%;
    }

    .frame-tab-name {
      padding-left: 30px;
      font-size: 14px;
    }

    .frame-tab-attention {
      width: 40px;
      height: 40px;
      right: 50px;
      background-image: url("../../assets/img/homeDialog/littleBtn/account-for-nor.png");

      &:hover {
        background-image: url("../../assets/img/homeDialog/littleBtn/account-for-hov.png");
      }
    }

    .frame-tab-shutdown {
      width: 40px;
      height: 40px;
      right: 15px;
      background-image: url("../../assets/img/homeDialog/littleBtn/off-nor.png");

      &:hover {
        background-image: url("../../assets/img/homeDialog/littleBtn/off-hov.png");
      }

      &:active {
        background-image: url("../../assets/img/homeDialog/littleBtn/off-pre.png");
      }
    }
  }

  .spring-list {
    width: 340px;
    height: 329px;
    // line-height: 329px;
    background: #030d1c;
    margin: 0 auto;
    overflow: auto;
    box-sizing: border-box;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    padding-top: 14px;
    .noSepllIt {
      position: relative;
      top: 50%;
    }
  }

  .spring-list::-webkit-scrollbar {
    display: none;
  }

  .spring-list-okay {
    height: 59px;
    margin: 28px 28px 0;
    font-size: 13px;

    div {
      display: flex;
      overflow: hidden;
      line-height: 20px;
    }

    .spring-list-taskData {
      .spring-list-taskName {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(255, 255, 255, 1);
      }

      .spring-list-taskTime {
        margin-left: 8px;
        width: 116px;
        text-align: right;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
      }
    }

    .spring-list-taskOperation {
      display: flex;
      margin-top: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.27);
      padding-bottom: 11px;

      .spring-list-taskTarget {
        width: 112px;
        text-align: left;
        font-size: 12px;
      }

      .download-time {
        color: rgba(0, 122, 255, 1);
        margin-left: 62px;
        margin-right: 18px;
        position: relative;
      }

      .spring-list-delBtn {
        color: rgba(255, 77, 79, 1);
        cursor: pointer;
      }

      .spring-list-load {
        width: 21px;
        height: 21px;
        background: url("../../assets/img/homeDialog/icon-btn/spell-it-load.png")
          no-repeat center center;
        background-size: contain;
      }
    }
  }

  .spring-operation {
    width: 340px;
    height: 40px;
    line-height: 40px;
    background: #007aff;
    text-align: center;
    cursor: pointer;
    margin: 20px auto 38px;
    color: #fff;

    &:hover {
      background: rgba(50, 148, 255, 1);
    }

    &:active {
      background-image: rgba(0, 103, 216, 1);
    }
  }
  .canStatus {
    cursor: not-allowed;
  }
}
// 快拼警告弹框
.spell-warning {
  // display: none;
  position: fixed;
  min-width: 269px;
  height: 144px;
  background: rgba(3, 13, 28, 1);
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.12),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 6px 16px -8px rgba(0, 0, 0, 0.32);
  border-radius: 2px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;

  .warning-txt {
    display: flex;
    line-height: 24px;
    margin: 33px;
    font-size: 16px;

    .warning-txt-icon {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: url("../../assets/img/homeDialog/icon-btn/warning.png")
        no-repeat center center;
      background-size: contain;
      margin-right: 17px;
    }
  }

  .warning-btn {
    display: flex;

    div {
      width: 65px;
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: #fff;
      text-align: center;
      font-size: 14px;
    }

    #warning-cancels {
      border-color: rgba(255, 255, 255, 0.2);
      margin-left: 99px;
      cursor: pointer;
      user-select: none;
    }

    #warning-confirmed {
      border-color: #177ddc;
      background: #177ddc;
      margin-left: 8px;
      cursor: pointer;
      user-select: none;
    }
  }
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.btn-style {
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  cursor: pointer;
}
.el-popper.noDownFile {
  padding: 5px 8px !important;
  color: #acafb5 !important;
  background-color: #141f2f !important;
  border: none !important;
  .dowmloadDio {
    // width: 129px;
    height: 26px;
    // background: #141F2F;
    // box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.2), 0px 6px 16px 0px rgba(0, 0, 0, 0.32), 0px 3px 6px -4px rgba(0, 0, 0, 0.48);
    // border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .downItem {
      display: flex;
      justify-content: space-around;
      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
}
.el-popper.noDownFile.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #141f2f !important;
}
.el-popper.noDownFile.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #141f2f !important;
}
.no-download {
  color: #4b5668 !important;
}
@media screen and (max-height: 1080px) {
  .spring-frame {
    transform: scale(0.8);
    top: 12px;
    left: -25px;
  }
}
</style>
