var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mapKey
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-amap",
            {
              staticClass: "amap-box",
              attrs: {
                id: "topNav",
                plugin: _vm.plugin,
                events: _vm.events,
                zoom: _vm.zoom,
                center: _vm.center,
                vid: "amap-vue",
                "amap-manager": _vm.amapManager,
              },
            },
            [
              !_vm.isGcjPosition
                ? _c(
                    "span",
                    _vm._l(_vm.onlineTerminal, function (item, index) {
                      return _c(
                        "span",
                        _vm._l(item.track.path, function (trackItem, yindex) {
                          return _c("el-amap-polyline", {
                            key: yindex + "wgsOnlineTerminalTrack",
                            attrs: {
                              "stroke-color": "#ffc300",
                              editable: false,
                              "stroke-weight": 2,
                              visible: item.track.visible,
                              path: _vm.wgsOnlineTerminal[index].track.path[
                                yindex
                              ],
                              events: trackItem.events,
                            },
                          })
                        }),
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isGcjPosition
                ? _c(
                    "span",
                    _vm._l(_vm.onlineTerminal, function (item, index) {
                      return _c(
                        "span",
                        _vm._l(item.track.path, function (trackItem, yindex) {
                          return _c("el-amap-polyline", {
                            key: yindex + "onlineTerminalTrack",
                            attrs: {
                              "stroke-color": "#ffc300",
                              editable: false,
                              "stroke-weight": 2,
                              visible: item.track.visible,
                              path: trackItem,
                              events: trackItem.events,
                            },
                          })
                        }),
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isGcjPosition
                ? _c(
                    "span",
                    _vm._l(_vm.historyTerminal, function (item, index) {
                      return _c(
                        "span",
                        _vm._l(item.track.path, function (trackItem, yindex) {
                          return _c("el-amap-polyline", {
                            key: yindex + "wgsHistoryTerminalTrack",
                            attrs: {
                              "stroke-color": "#ffc300",
                              editable: false,
                              "stroke-weight": 2,
                              visible: item.track.visible,
                              path: _vm.wgsHistoryTerminal[index].track.path[
                                yindex
                              ],
                              events: trackItem.events,
                            },
                          })
                        }),
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isGcjPosition
                ? _c(
                    "span",
                    _vm._l(_vm.historyTerminal, function (item, index) {
                      return _c(
                        "span",
                        _vm._l(item.track.path, function (trackItem, yindex) {
                          return _c("el-amap-polyline", {
                            key: yindex + "historyTerminalTrack",
                            attrs: {
                              "stroke-color": "#ffc300",
                              editable: false,
                              "stroke-weight": 2,
                              visible: item.track.visible,
                              path: trackItem,
                              events: trackItem.events,
                            },
                          })
                        }),
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.monitorType == "list",
                  expression: "monitorType == 'list'",
                },
              ],
            },
            [
              _vm.runEnv == "backend"
                ? _c("div", { staticClass: "backend-panel" }, [
                    _c("div", {
                      staticClass: "setup",
                      on: { click: _vm.openBar },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "online", on: { click: _vm.online } },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("在线设备"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.onlineCount)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "history", on: { click: _vm.historyBtn } },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("历史设备"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(_vm.historyCount)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.onlineShow,
                            expression: "onlineShow",
                          },
                        ],
                        staticClass: "onlineBox",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "feima",
                            on: { click: _vm.feimaOnline },
                          },
                          [
                            _c("img", {
                              staticClass: "userIcon",
                              attrs: {
                                src: require("@/assets/img/home／feima／on.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "feimaTit" }, [
                              _c("span", { staticClass: "usertype" }, [
                                _vm._v("飞马"),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "count" }, [
                                _vm._v(_vm._s(_vm.feimaOnlineCount)),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "currency" }, [
                          _c("img", {
                            staticClass: "userIcon",
                            attrs: {
                              src: require("@/assets/img/home／general／on.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "feimaTit" }, [
                            _c("span", { staticClass: "usertype" }, [
                              _vm._v("通用"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.commonOnlineCount)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "terminal" }, [
                          _c("img", {
                            staticClass: "userIcon",
                            attrs: {
                              src: require("@/assets/img/home／phone／on.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "feimaTit" }, [
                            _c("span", { staticClass: "usertype" }, [
                              _vm._v("终端"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.terminalOnlineCount)),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.historyShow,
                            expression: "historyShow",
                          },
                        ],
                        staticClass: "historyBox",
                      },
                      [
                        _c("div", { staticClass: "feima" }, [
                          _c("img", {
                            staticClass: "userIcon",
                            attrs: {
                              src: require("@/assets/img/home／feima／off.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "feimaTit" }, [
                            _c("span", { staticClass: "usertype" }, [
                              _vm._v("飞马"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.feimaHistoryCount)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "currency" }, [
                          _c("img", {
                            staticClass: "userIcon",
                            attrs: {
                              src: require("@/assets/img/home／general／off.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "feimaTit" }, [
                            _c("span", { staticClass: "usertype" }, [
                              _vm._v("通用"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.commonHistoryCount)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "terminal" }, [
                          _c("img", {
                            staticClass: "userIcon",
                            attrs: {
                              src: require("@/assets/img/home-phone-off.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "feimaTit" }, [
                            _c("span", { staticClass: "usertype" }, [
                              _vm._v("终端"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.terminalHistoryCount)),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.runEnv == "portal"
                ? _c("div", { staticClass: "portal-panel" }, [
                    _c("div", {
                      staticClass: "setup",
                      on: { click: _vm.openBar },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "online", on: { click: _vm.online } },
                      [
                        _c("i", { staticClass: "iconfont flying" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _vm.token
                          ? _c("span", { staticClass: "bottBar" }, [
                              _c("p", { staticClass: "count" }, [
                                _vm._v(_vm._s(_vm.onlineCount)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "title" }, [
                                _vm._v("在线设备"),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "history", on: { click: _vm.historyBtn } },
                      [
                        _c("i", { staticClass: "iconfont flying" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _vm.token
                          ? _c("span", { staticClass: "bottBar" }, [
                              _c("p", { staticClass: "count" }, [
                                _vm._v(_vm._s(_vm.historyCount)),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "title" }, [
                                _vm._v("历史设备"),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.onlineShow,
                            expression: "onlineShow",
                          },
                        ],
                        staticClass: "onlinedeta",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "line",
                            on: { click: _vm.feimaOnline },
                          },
                          [
                            _c("span", { staticClass: "feima" }, [
                              _vm._v(_vm._s(_vm.feimaOnlineCount)),
                            ]),
                            _vm._v(" "),
                            _c("i", { staticClass: "iconfont circelone" }, [
                              _vm._v(""),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "brand" }, [
                              _vm._v("飞马无人机"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "tongyong" }, [
                            _vm._v(_vm._s(_vm.commonOnlineCount)),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "iconfont circeltwo" }, [
                            _vm._v(""),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "brand" }, [
                            _vm._v("通用无人机"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "yidong" }, [
                            _vm._v(_vm._s(_vm.terminalOnlineCount)),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "iconfont circelthree" }, [
                            _vm._v(""),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "brand" }, [
                            _vm._v("移动终端"),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.historyShow,
                            expression: "historyShow",
                          },
                        ],
                        staticClass: "historydeta",
                      },
                      [
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "feima" }, [
                            _vm._v(_vm._s(_vm.feimaHistoryCount)),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "iconfont circelone" }, [
                            _vm._v(""),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "brand" }, [
                            _vm._v("飞马无人机"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "tongyong" }, [
                            _vm._v(_vm._s(_vm.commonHistoryCount)),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "iconfont circeltwo" }, [
                            _vm._v(""),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "brand" }, [
                            _vm._v("通用无人机"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "line" }, [
                          _c("span", { staticClass: "yidong" }, [
                            _vm._v(_vm._s(_vm.terminalHistoryCount)),
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "iconfont circelthree" }, [
                            _vm._v(""),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "brand" }, [
                            _vm._v("移动终端"),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.monitorType == "list",
                  expression: "monitorType == 'list'",
                },
              ],
            },
            [
              _c("setBar", {
                ref: "setup",
                style: { right: _vm.runEnv == "backend" ? "22%" : "44px" },
                attrs: {
                  feimaOnlineCount: _vm.feimaOnlineCount,
                  feimaHistoryFliterCount: _vm.feimaHistoryFliterCount,
                  commonOnlineCount: _vm.commonOnlineCount,
                  commonHistoryFliterCount: _vm.commonHistoryFliterCount,
                  terminalOnlineCount: _vm.terminalOnlineCount,
                  terminalHistoryFliterCount: _vm.terminalHistoryFliterCount,
                },
                on: {
                  changeOnlineTermianalTrackVisible:
                    _vm.changeOnlineTermianalTrackVisible,
                  changeHistoryTerminal: _vm.changeHistoryTerminal,
                  changeHistoryFeima: _vm.changeHistoryFeima,
                  changeHistoryCommon: _vm.changeHistoryCommon,
                  guideMap: _vm.guideMap,
                  skyMap: _vm.skyMap,
                  imageTile: _vm.imageTile,
                  hideOrshowMassMaker: _vm.hideOrshowMassMaker,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "historyTerminaldialog" },
            [
              _vm.historyTerminaldialogVisible
                ? _c(
                    "el-dialog",
                    {
                      directives: [
                        { name: "dialogDrag", rawName: "v-dialogDrag" },
                      ],
                      attrs: {
                        modal: false,
                        visible: _vm.historyTerminaldialogVisible,
                        "show-close": true,
                        title: _vm.terminalDialogTitle,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.historyTerminaldialogVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "dialog-header",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          _vm.terminal.dialogType === "drone"
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.monitorType === "list",
                                        expression: "monitorType === 'list'",
                                      },
                                    ],
                                    staticClass: "dia_title",
                                  },
                                  [_vm._v("任务信息")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.monitorType === "point",
                                        expression: "monitorType === 'point'",
                                      },
                                    ],
                                    staticClass: "dia_title",
                                  },
                                  [_vm._v("设备信息")]
                                ),
                                _vm._v(" "),
                                _vm.terminal.dialogType === "drone"
                                  ? _c("span", [
                                      _vm.terminal.droneInfo.brand_name !==
                                      "彩虹"
                                        ? _c(
                                            "span",
                                            { staticClass: "dia_name" },
                                            [_vm._v(" 其他无人机 ")]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "dia_name" },
                                            [_vm._v(" 彩虹无人机 ")]
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.terminal.planeType ===
                                                  "feima" &&
                                                _vm.monitorType === "list",
                                              expression:
                                                "\n                terminal.planeType === 'feima' && monitorType === 'list'\n              ",
                                            },
                                          ],
                                          staticClass: "trajectoryBar",
                                          on: { click: _vm.lookParams },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              src: require("@/assets/img/homeDialog/moreInformation.png"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("span", { staticClass: "dia_title" }, [
                                _vm._v("\n          设备信息\n          "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.monitorType === "list",
                                        expression: "monitorType === 'list'",
                                      },
                                    ],
                                    staticClass: "trajectoryBar",
                                  },
                                  [
                                    _c("span", { staticClass: "trajectory" }, [
                                      _vm._v("移动轨迹"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-switch", {
                                      staticClass: "switchTurn",
                                      on: { change: _vm.showThisTrack },
                                      model: {
                                        value: _vm.isShowThisTrack,
                                        callback: function ($$v) {
                                          _vm.isShowThisTrack = $$v
                                        },
                                        expression: "isShowThisTrack",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("dtuPop", {
                        ref: "dtupop",
                        attrs: {
                          terminal: _vm.terminal,
                          trackButtonValue: _vm.trackButtonValue,
                          monitorType: _vm.monitorType,
                        },
                        on: { setLocationInMap: _vm.setLocationInMap },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }