var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm._module }, [
    _c("div", { staticClass: "status setLineHeight" }, [
      _c("div", { staticClass: "setDisplay" }, [
        _c("span", { staticClass: "deviceTitle" }, [_vm._v("设备状态")]),
        _vm._v(" "),
        _vm.terminal.status === 0
          ? _c("span", { staticClass: "deviceData" }, [_vm._v("正常")])
          : _vm.terminal.status === 1
          ? _c("span", { staticClass: "deviceData" }, [_vm._v("故障")])
          : _vm.terminal.status === 2
          ? _c("span", { staticClass: "deviceData" }, [_vm._v("设备维修")])
          : _vm.terminal.status === 3
          ? _c("span", { staticClass: "deviceData" }, [_vm._v("设备遗失")])
          : _c("span", { staticClass: "deviceData" }, [_vm._v("设备报废")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "setDisplay" }, [
        _c("span", { staticClass: "deviceTitle" }, [_vm._v("归属部门")]),
        _vm.terminal.holder_dept !== ""
          ? _c(
              "span",
              {
                staticClass: "deviceData",
                staticStyle: { width: "50px" },
                attrs: { title: _vm.terminal.holder_dept },
              },
              [_vm._v(_vm._s(_vm.terminal.holder_dept))]
            )
          : _c("span", { staticClass: "deviceData" }, [_vm._v("无")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "setLineHeight" }, [
      _c("span", { staticClass: "deviceTitle" }, [_vm._v("联盟单位")]),
      _c(
        "span",
        {
          staticClass: "deviceData",
          staticStyle: { width: "190px" },
          attrs: { title: _vm.terminal.unit_name },
        },
        [_vm._v(_vm._s(_vm._f("filterData")(_vm.terminal.unit_name)))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "setLineHeight" }, [
      _c(
        "span",
        { staticClass: "deviceTitle", staticStyle: { "margin-right": "25px" } },
        [_vm._v("持有人")]
      ),
      _c(
        "span",
        {
          staticClass: "deviceData",
          staticStyle: { width: "85px" },
          attrs: { title: _vm.terminal.name },
        },
        [_vm._v(_vm._s(_vm._f("filterData")(_vm.terminal.holder_name)))]
      ),
      _c("span", { staticClass: "phone" }, [
        _vm._v(_vm._s(_vm._f("filterMobile")(_vm.terminal.mobile))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }