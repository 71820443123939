<template>
  <div>
    <div v-show="intrdrawer" class="animated slideInLeft fast PoiBar">
      <div class="searchhead">
        <!-- 搜索框 -->
        <el-input
          class="searchInput"
          placeholder="请输入兴趣标注名称进行搜索"
          suffix-icon="iconfont icon-sousuo-copy"
          v-model="keyword"
          @keyup.enter.native="
            (e) => {
              search();
            }
          "
        >
          <i slot="suffix" @click="search(null)" class="iconfont search"
            >&#xe61d;</i
          >
        </el-input>

        <div class="wordBtn">
          <el-link type="primary" :underline="false" @click="accurateSearch"
            >精确筛选
          </el-link>
        </div>
      </div>
      <!-- 下拉菜单 -->
      <div class="collapse">
        <el-collapse v-model="activeUnit">
          <el-collapse-item
            v-for="(unit, unitIndex) in unitList"
            :key="unitIndex"
            :name="unit.id"
          >
            <template slot="title">
              <div class="getItem" @click="changeUnit(unit)">
                <span :title="unit.unit_name" class="titlesty">{{
                  unit.unit_name
                }}</span>
              </div>
            </template>
            <el-collapse
              v-for="result in unit.resultList"
              :key="result.id"
              v-model="activeResult"
              @change="handleChange"
            >
              <el-collapse-item class="entity" :name="result.id">
                <template class="titlesty" slot="title">
                  <div class="cardswi" @click="showTags(result)">
                    <div class="tagLeft">
                      <span class="cgname">
                        {{ result.name }}
                        <i
                          class="iconfont lianjie"
                          v-if="result.project_name != null"
                          @click.stop="taskdetails(result)"
                          >&#xe628;</i
                        >
                      </span>
                    </div>
                    <div class="tagRight">
                      <el-tag class="tagsuc" type="success"
                        >{{ result.type }}
                      </el-tag>
                    </div>
                  </div>
                </template>
                <div
                  v-for="(poi, poiIndex) in result.poiList"
                  :key="poiIndex"
                  class="intrtags"
                >
                  <div class="left">
                    <i class="iconfont tagsmall icon-dingwei" />
                    &nbsp;&nbsp;&nbsp;
                    <el-tooltip :content="poi.name" placement="top">
                      <span>
                        {{ poi.name }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="right">
                    <el-switch
                      v-model="poi.switchButton"
                      class="switchBar"
                      @change="poiShow($event, poi)"
                    />
                    <i
                      @click="locateBtn(poi)"
                      :style="{
                        color: activePoiId === poi.id ? '#1882fb' : '',
                      }"
                      class="iconfont tagsbig"
                      >&#xe61b;</i
                    >
                    <i @click="delPoiBtn(poi)" class="iconfont delIcon"
                      >&#xe612;</i
                    >
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <TaskDetail ref="childtask" :taskData="taskData" />
    <AccurateSearch
      :type="'poi'"
      @searchDetail="setSearchForm"
      ref="childsearch"
    />
  </div>
</template>

<script>
import TaskDetail from "./taskDetail";
import AccurateSearch from "./accurateSearch";
import { mapState, mapMutations } from "vuex";
import EarthHelper from "@/components/earthOperator/earthHelper";
import timeFn from "@/utils/time.js";

let file = [];
let files = {};
let formData = new FormData();
export default {
  inject: ["reload"],
  components: {
    TaskDetail,
    AccurateSearch,
  },
  data() {
    return {
      keyword: "",
      direction: "ltr",
      radio: "1",
      activeUnit: [],
      activeResult: [],
      unitList: [],
      page: 1,
      Poikey: [],
      poiArr: [],
      fileArr: [],
      catalog: [],
      activePoiId: "",
      per_page: 10000,
      type: "",
      typeNum: 0,
      currentTime: "", // 获取当前时间
      taskData: {},
    };
  },
  methods: {
    ...mapMutations(["chawidStorage", "chaintrStorage", "currentUrlStorage"]),
    getTime() {
      let time = new Date();
      var Milliseconds =
        time.getMilliseconds() <= 9
          ? "0" + time.getMilliseconds()
          : time.getMilliseconds();
      if (Milliseconds <= 9) {
        Milliseconds = "00" + time.getMilliseconds();
      } else if (Milliseconds > 9 && Milliseconds <= 99) {
        Milliseconds = "0" + time.getMilliseconds();
      } else {
        Milliseconds = time.getMilliseconds();
      }
      let y = time.getFullYear(); //年
      let m = time.getMonth() + 1; //月
      let d = time.getDate(); //日
      if (d < 10) d = "0" + d;
      this.currentTime = `${y}${m}${d}`; //修改数据date
      this.currentTime =
        this.currentTime +
        "_" +
        time.getHours() +
        (time.getMinutes() <= 9 ? "0" + time.getMinutes() : time.getMinutes()) +
        (time.getSeconds() <= 9 ? "0" + time.getSeconds() : time.getSeconds()) +
        "_" +
        Milliseconds;
    },
    // 搜索
    async search(param) {
      let poiParam = {};
      if (param) {
        poiParam = param;
      } else {
        poiParam = {
          per_page: this.per_page,
          name: this.keyword,
        };
      }
      let poiList = await this.getPoiList(null, poiParam);
      let dataResultIds = [];
      poiList.forEach((item) => {
        if (dataResultIds.indexOf(item.data_result_id) < 0)
          dataResultIds.push(item.data_result_id);
      });
      let resultParam = {
        per_page: this.per_page,
        ids: dataResultIds.join(","),
      };
      let resultList = await this.getResultList(null, resultParam);
      resultList.forEach((result) => {
        result.poiList = [];
        poiList.forEach((poi) => {
          if (poi.data_result_id == result.id) {
            poi.switchButton = false;
            result.poiList.push(poi);
          }
        });
      });
      this.unitList.forEach((unit) => {
        unit.resultList = [];
        resultList.forEach((result) => {
          if (result.unit_id == unit.id) {
            unit.resultList.push(result);
            //展开对应的联盟
            this.activeUnit.push(unit.id);
            //展开对应的成果
            this.activeResult.push(result.id);
          }
        });
      });
    },
    // 精确搜索dialog
    accurateSearch() {
      this.$refs.childsearch.turnon();
    },
    // 精确搜索
    setSearchForm(res) {
      let param = {
        unit_id: res.unit_id, //单位
        name: res.poi_name,
        type: res.poi_type,
        result_name: res.result_name,
        result_type: res.result_type,
        task_name: res.task_name,
        task_type: res.task_type,
        per_page: this.per_page,
      };
      this.search(param);
    },
    // 切换联盟
    changeUnit(unit) {
      this.getResultList(unit);
    },
    // 弹出左侧菜单
    openIntrDrw() {
      if (!this.intrdrawer) {
        this.activeUnit = [];
        this.getUnitList();
      }
      this.chaintrStorage(!this.intrdrawer);
      this.chawidStorage(false);
      this.currentUrlStorage(["首页", "目录查询", "兴趣标注"]);
    },
    // 任务详情
    taskdetails(param) {
      setTimeout(() => {
        this.$refs.childtask.open();
      }, 0);
      this.taskData = param;
    },
    /**
     * @description: 添加兴趣点
     * @param {type:object} :添加时用户输入的Poi信息
     * @return: 抛出一个promise对象供外部使用
     */
    addPoi(param) {
      var poiItem = {
        name: param.name,
        type: param.type,
        latitude: param.coordanite[1],
        longitude: param.coordanite[0],
        altitude: param.coordanite[2],
        desc: param.description,
        data_result_id: param.mountId,
      };

      return new Promise((resolve, reject) => {
        this.$apis
          .addPoi(poiItem, this.token)
          .then((res) => {
            resolve(true);
            let poiDataItem,
              earthPoiItem = res.data.data;
            //将type转为为对应的文本
            let findType = this.interestTypeList.find((v) => {
              return v.id == earthPoiItem.type;
            });
            if (findType) earthPoiItem.type = findType.data_interest_type;
            let poi = EarthHelper.addPoi2Earth(earthPoiItem, param.mountId);
            if (poi) {
              poi.show(true);
              poi.locate();
              // 将新纪录插入list
              this.unitList.forEach((unit) => {
                let findResult = unit.resultList.find((v) => {
                  return v.id == poiItem.data_result_id;
                });
                if (findResult) {
                  poiDataItem.switchButton = true;
                  this.activePoiId = poiDataItem.id;
                  findResult.poiList.push(poiDataItem);
                }
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * @description: 删除兴趣点
     * @param {type:object} :删除的某一个Poi的信息
     * @return: 抛出一个promise对象供外部使用
     */
    delPoi(param) {
      return new Promise((resolve, reject) => {
        this.$apis
          .delPoi(
            {
              id: param.id,
            },
            this.token
          )
          .then((res) => {
            const MessageService = window.iWindow.MessageService;
            let poi = MessageService.getEntity(param.mountId).delPoi(param);

            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    /**
     * @description: 上传成果文件
     * @param {type:object} :成果文件信息对象
     * @return: 抛出一个promise对象供外部使用
     */
    uploadFile(data) {
      // console.log(data);
      let date = ""
      let have_result_type =""
      let resolution = ""
      if (data.flyTime){
        date = timeFn.getYmdTime(data.flyTime._d)
      }
      if (data.fileType){
        have_result_type = JSON.stringify(data.fileType).replace('[','').replace(']','')
      }
      if (data.resolutionRatio){
        resolution =  data.resolutionRatio
      }
      // console.log(resolution);
      if (data.type == 1) {
        this.type = "VECTOR";
      } else if (data.type == 2) {
        this.type = "TILESET";
      } else if (data.type == 3) {
        this.type = "IMAGERY";
      } else if (data.type == 4) {
        this.type = "TERRAIN";
      } else if (data.type == 5) {
        this.type = "POI";
      } else if (data.type == 6) {
        this.type = "MODEL";
      }
      // data.file.name
      return new Promise((resolve, reject) => {
        this.getTime();
        var catalog =
          this.user.user_unit.unit_code +
          "-" +
          this.currentTime +
          "-" +
          this.user.mobile +
          "-" +
          data.file.name.split(".")[0];
        //   console.log(data);
        // const catalog = 123
        // console.log(catalog,data.file.name.split(".")[0]);
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(data.file);
        fileReader.onload = (e) => {

          if (formData.has("catalog")){
            // formData.set("catalog","测试")
            formData.set("file", data.file);
            formData.set("type", this.type);
            formData.set("catalog", catalog);
            formData.set("mission_date_at", date);
            formData.set("resolution", resolution);
            formData.set("have_result_type", have_result_type);
          }else {
            formData.append("file", data.file);
            formData.append("type", this.type);
            formData.append("catalog", catalog);
            formData.append("mission_date_at", date);
            formData.append("resolution", resolution);
            formData.append("have_result_type", have_result_type);

          }

          this.$apis
            .directUpload(formData, this.token)
            .then((data) => {
              // formData = new FormData()
              resolve(data.data);
            })
            .catch((err) => {
              console.error(err);
            });
        };
      });
    },
    // 展开、收起成果
    showTags(result) {
      // 只有展开的时候才去定位
      if (this.activeResult.indexOf(result.id) < 0) {
        //定位
        EarthHelper.addEntity({
          id: result.id, //成果id
          type: EarthHelper.getResultType(result.type), //EntityType
          data: {
            url: result.url,
          },
        }).then((entity) => {
          if (entity) {
            //获取兴趣点列表
            this.getPoiList(result);
            entity.locate();
            entity.show(true);
            //渲染海拔
            if (result.height != null) {
              entity.tileHeight(result.height);
            }
          }
        });
      }
    },
    /**
     * @description: 控制Poi显隐的开关
     * @param {type:object}:点击的哪一个Poi的信息
     * @$event {type:boolean}:开关的状态
     * @return:
     */
    poiShow($event, param) {
      let isPoiShow = param.switchButton;
      let poi = EarthHelper.getPoiById(param.data_result_id, param.id);
      if (poi) poi.show(isPoiShow);
      this.$forceUpdate();
    },
    // 定位按钮
    locateBtn(param) {
      this.activePoiId = param.id;

      let entity = EarthHelper.getEntity(param.data_result_id);
      if (!entity) {
        let result = {};
        this.unitList.forEach((unit) => {
          let find = unit.resultList.find((v) => {
            return v.id == param.data_result_id;
          });
          if (find) result = find;
        });
        EarthHelper.addEntity({
          id: result.id, //成果id
          type: EarthHelper.getResultType(result.type), //EntityType
          data: {
            url: result.url,
          },
        }).then((entity) => {
          entity.locate();
          entity.show(true);
          EarthHelper.addPoi2Earth(param);
          let poi = EarthHelper.getPoiById(param.data_result_id, param.id);
          if (poi) {
            param.switchButton = true;
            poi.show(true);
            poi.locate();
          }
          this.$forceUpdate();
        });
      } else {
        let poi = EarthHelper.getPoiById(param.data_result_id, param.id);
        if (poi) {
          param.switchButton = true;
          poi.show(true);
          poi.locate();
        }
        this.$forceUpdate();
      }
    },
    // 删除Poi
    delPoiBtn(param) {
      this.$confirm("您确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const MessageService = window.iWindow.MessageService;
          let removepoi = MessageService.getEntity(
            param.data_result_id
          ).removePoi(param.id);
          if (removepoi == true) {
            this.$apis
              .delPoi(
                {
                  id: param.id,
                },
                this.token
              )
              .then((data) => {
                if (data.data.status == 0) {
                  // 将记录从list中删除
                  this.unitList.forEach((unit) => {
                    unit.resultList.forEach((result) => {
                      let findIndex = result.poiList.findIndex((v) => {
                        return v.id == param.id;
                      });
                      if (findIndex > -1) result.poiList.splice(findIndex, 1);
                    });
                  });
                }
              })
              .catch((err) => {});
          } else {
            return;
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 获取联盟单位列表
    getUnitList() {
      this.$apis
        .unitList({ per_page: 100000000 }, this.token, "?page=" + this.page)
        .then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data.data;
            data.forEach((item) => {
              item.resultList = [];
            });
            this.unitList = data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取成果列表
    getResultList(unit, param) {
      return new Promise((resolve, reject) => {
        if (!param)
          param = {
            per_page: 999,
          };
        if (unit) param.unit_id = unit.id;
        this.$apis
          .listResult(param, this.token)
          .then((res) => {
            if (res.data.status == 0) {
              let data = res.data.data.data;
              if (!unit) {
                resolve(data);
              } else {
                data.forEach((item) => {
                  let findIndex = unit.resultList.findIndex((v) => {
                    return v.id == item.id;
                  });
                  if (findIndex < 0) {
                    item.poiList = [];
                    unit.resultList.push(item);
                  }
                });
                this.$forceUpdate();
                resolve();
              }
            }
          })
          .catch((err) => {
            reject();
          });
      });
    },
    // 点击联盟单位展开时触发的函数
    handleChange(val) {
      // this.getPoiList();
    },
    // 获取Poi列表
    getPoiList(result, param) {
      return new Promise((resolve, reject) => {
        if (!param) param = { per_page: this.per_page };
        if (result) param.data_result_id = result.id;
        this.$apis
          .listPoi(param, this.token)
          .then((res) => {
            if (res.data.status == 0) {
              let data = res.data.data.data;
              if (!result) {
                resolve(data);
              } else {
                data.forEach((item) => {
                  let findIndex = result.poiList.findIndex((v) => {
                    return v.id == item.id;
                  });
                  if (findIndex < 0) {
                    item.switchButton = false;
                    result.poiList.push(item);
                    // 画点
                    let poiItem = EarthHelper.addPoi2Earth(item);
                    // 默认打开的时候，状态设置为关闭, 后续根据实际状况，再进行调整优化
                    //if (poiItem) poiItem.show(false);
                  }
                });
                this.$forceUpdate();
                resolve();
              }
            }
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    // 监听左侧菜单
    turnOff() {
      this.chawidStorage(false);
      this.chaintrStorage(false);
    },
  },
  mounted() {
    // 联盟列表
    this.getUnitList();
    window.DataPlatform = {
      addPoi: (param) => {
        return this.addPoi(param);
      },
      uploadFile: (e) => {
        return this.uploadFile(e);
      },
      delPoi: (param) => {
        return this.delPoi(param);
      },
      downDatamanager: () => {
        return this.downDatamanager();
      },
    };
  },
  created() {},
  beforeDestroy() {
    this.turnOff();
  },
  computed: {
    ...mapState(["user", "token", "drawer", "intrdrawer", "interestTypeList"]),
  },
  watch: {
    $route: "turnOff",
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/iconfont/iconfont.css";

.lianjie {
  height: 20px;
  width: 20px;
  color: #000000;
}

.lianjie:hover {
  background-color: #dde2ee;
}

.cgname {
  color: #828a9e;
  font-size: 16px;
}

.red {
  color: red;
}

/deep/ .el-tag.el-tag--success {
  background-color: #e6fffc;
  border-color: #1cd0bd;
  color: #1cd0bd;
  margin-top: 4px;
  border-radius: 2px;
}

/deep/ .el-collapse-item__header {
  height: auto;
}

// /deep/ .el-collapse-item__wrap {
//   border-bottom: none;
// }

// /deep/ .el-collapse-item__header {
//   border-bottom: none;
// }

/deep/ .el-collapse-item__header.is-active {
  background-color: rgba(17, 130, 251, 0.2);
}

.getItem {
  width: 100%;
  max-height: 50px;
  overflow: hidden;
  box-sizing: border-box;
}

.PoiBar {
  width: 20%;
  position: absolute;
  left: 260px;
  top: 80px;
  z-index: 999;
  background-color: #fff;
  overflow-y: auto;
  height: calc(100% - 80px);
}
.PoiBar::-webkit-scrollbar {
  width: 0 !important;
}

.search {
  cursor: pointer;
  // display: block;
}
.searchhead {
  -webkit-column-width: 100%;
  margin-top: 30px;
  margin-left: 12px;

  .searchInput {
    float: left;
    width: 236px;
  }

  .el-form {
    height: 0px !important;
  }

  .wordBtn {
    float: right;
    margin-right: 22px;
    margin-top: 8px;
  }
}

.titlesty {
  color: #3c4353;
  font-size: 15px;
  margin-left: 30px;
}

.collapse {
  margin-top: 85px;
  width: 100%;

  .cardswi {
    width: 400px;
    height: 80px;
    cursor: pointer;

    .tagLeft {
      margin-left: 51px;
    }

    .tagRight {
      width: 100%;
      float: right;
      margin-top: -18px;

      .icon-dingwei {
        color: #1882fb;
        font-size: 28px;
        margin-left: 20px;
      }

      .tagsuc {
        margin-left: 51px;
      }
    }
  }

  // .cardswi:hover {
  //   background-color: rgba(17, 130, 251, 0.2);
  // }
}

.intrtags {
  display: flex;
  justify-content: center;

  .left {
    width: 50%;
    line-height: 50px;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }
}

.tagsmall {
  color: #828a9e;
}

.switchBar {
  margin-bottom: 5%;
}

.tagsbig {
  font-size: 28px;
  margin-left: 15px;
  color: #a6aab8;
  cursor: pointer;
}

.delIcon {
  margin-left: 15px;
  color: #fd5c5e;
  font-size: 28px;
  cursor: pointer;
}

/deep/ .el-radio__input {
  position: absolute;
  top: 29px;
  left: 24px;
}

/deep/ .el-drawer__body {
  overflow-y: auto;
}

/deep/ .el-tag.el-tag--success {
  width: 75px;
}
</style>





