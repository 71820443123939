var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uavBody" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "topBar" }, [
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("今日已推送任务")]),
            _vm._v(" "),
            _c("span", { staticClass: "countOne" }, [
              _vm._v(_vm._s(_vm.taskDataList.report_home_today_pushed)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "growthrate" }, [
            _vm.taskDataList.report_home_today_pushed == 0 &&
            _vm.taskDataList.report_home_yesterday_pushed != 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("-100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_yesterday_pushed == 0 &&
            _vm.taskDataList.report_home_today_pushed != 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("+100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_yesterday_pushed == 0 &&
            _vm.taskDataList.report_home_today_pushed == 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("+100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_today_pushed != 0 &&
            _vm.taskDataList.report_home_yesterday_pushed != 0 &&
            _vm.yesterday_push
              ? _c("span", { staticClass: "growthNum" }, [
                  _vm._v(
                    _vm._s(_vm.yesterday_push > 0 ? "+" : "-") +
                      _vm._s(_vm.yesterday_push.toFixed(2)) +
                      "%"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "growthWord" }, [_vm._v("相较昨日")]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("今日未推送任务")]),
            _vm._v(" "),
            _c("span", { staticClass: "countTwo" }, [
              _vm._v(_vm._s(_vm.taskDataList.report_home_today_not_pushed)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiTwo" }, [_vm._v("个")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "growthrate" }, [
            _vm.taskDataList.report_home_today_not_pushed == 0 &&
            _vm.taskDataList.report_home_yesterday_not_pushed != 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("-100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_yesterday_not_pushed == 0 &&
            _vm.taskDataList.report_home_today_not_pushed != 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("+100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_yesterday_not_pushed == 0 &&
            _vm.taskDataList.report_home_today_not_pushed == 0
              ? _c("span", { staticClass: "growthNum" }, [_vm._v("+100%")])
              : _vm._e(),
            _vm._v(" "),
            _vm.taskDataList.report_home_today_not_pushed != 0 &&
            _vm.taskDataList.report_home_yesterday_not_pushed != 0 &&
            _vm.yesterday_not_push
              ? _c("span", { staticClass: "growthNum" }, [
                  _vm._v(
                    _vm._s(_vm.yesterday_not_push > 0 ? "+" : "-") +
                      _vm._s(_vm.yesterday_not_push.toFixed(2)) +
                      "%"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "growthWord" }, [_vm._v("相较昨日")]),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("历史已推送任务")]),
            _vm._v(" "),
            _c("span", { staticClass: "countThr" }, [
              _vm._v(_vm._s(_vm.taskDataList.report_home_history_pushed_count)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiThr" }, [_vm._v("个")]),
          ]),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("历史未推送任务")]),
            _vm._v(" "),
            _c("span", { staticClass: "countFour" }, [
              _vm._v(
                _vm._s(_vm.taskDataList.report_home_history_not_pushed_count)
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiFour" }, [_vm._v("个")]),
          ]),
          _vm._v(" "),
          _vm._m(3),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bodyBar" }, [
        _c(
          "div",
          { staticClass: "leftBar" },
          [
            _c("div", { staticClass: "titleBar" }, [_vm._v("统计分析")]),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { staticClass: "middleBar" }, [
              _c("div", { staticClass: "totalBar" }, [
                _c("span", { staticClass: "wordBtn totalTitle" }, [
                  _vm._v("总分任务"),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn zongtask",
                    style: { color: _vm.zongsty },
                    on: { click: _vm.zongTask },
                  },
                  [_vm._v("总任务")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn numtask",
                    style: { color: _vm.numsty },
                    on: { click: _vm.numTask },
                  },
                  [_vm._v("分任务")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "totalBar" }, [
                _c("span", { staticClass: "wordBtn totalTitle" }, [
                  _vm._v("任务类型"),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn alltask",
                    style: { color: _vm.allstyOne },
                    on: { click: _vm.allTaskOne },
                  },
                  [_vm._v("全部任务")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn zongtask",
                    style: { color: _vm.zongstyOne },
                    on: { click: _vm.zongTaskOne },
                  },
                  [_vm._v("航线任务")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn numtask",
                    style: { color: _vm.numstyOne },
                    on: { click: _vm.numTaskOne },
                  },
                  [_vm._v("通用任务")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "totalBar" }, [
                _c("span", { staticClass: "wordBtn totalTitle" }, [
                  _vm._v("推送类型"),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn alltask",
                    style: { color: _vm.allstyTwo },
                    on: { click: _vm.allTaskTwo },
                  },
                  [_vm._v("全部任务")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn zongtask",
                    style: { color: _vm.zongstyTwo },
                    on: { click: _vm.zongTaskTwo },
                  },
                  [_vm._v("指派任务")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "wordBtn numtask",
                    style: { color: _vm.numstyTwo },
                    on: { click: _vm.numTaskTwo },
                  },
                  [_vm._v("群发任务")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "totalBar" },
                [
                  _c(
                    "span",
                    { staticClass: "wordBtn wordBtnTime totalTitle" },
                    [_vm._v("推送时间")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "roundBtn",
                      attrs: { round: "", plain: "" },
                      on: { click: _vm.getday },
                    },
                    [_vm._v("今日")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "roundBtn",
                      attrs: { round: "", plain: "" },
                      on: { click: _vm.getweek },
                    },
                    [_vm._v("本周")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "roundBtn",
                      attrs: { round: "", plain: "" },
                      on: { click: _vm.getmonth },
                    },
                    [_vm._v("本月")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.getDateRange },
                    model: {
                      value: _vm.DateRange,
                      callback: function ($$v) {
                        _vm.DateRange = $$v
                      },
                      expression: "DateRange",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { staticClass: "progress" }, [
              _c(
                "div",
                { staticClass: "gressline" },
                [
                  _c("div", { staticClass: "numCount" }, [
                    this.project_type === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.project_or_task_count_1)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.project_type === 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.project_or_task_count_2)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n            |\n            "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-left": "10px",
                          "line-height": "20px",
                          color: "#a6aab8",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.taskDataList.project_or_task_total_count) +
                            "个"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progessWord" }, [
                    _vm._v("任务个数"),
                  ]),
                  _vm._v(" "),
                  this.taskNum
                    ? _c("el-progress", {
                        staticClass: "progreBar first",
                        attrs: { "show-text": false, percentage: this.taskNum },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gressline" },
                [
                  _c("div", { staticClass: "numCount" }, [
                    this.project_type === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.taskDataList.push_unit_count_1))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.project_type === 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.taskDataList.push_unit_count_2))]
                        )
                      : _vm._e(),
                    _vm._v("\n            |\n            "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-left": "10px",
                          "line-height": "20px",
                          color: "#a6aab8",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.taskDataList.push_unit_total_count) + "个"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progessWord" }, [
                    _vm._v("推送联盟单位"),
                  ]),
                  _vm._v(" "),
                  this.terminal_unit
                    ? _c("el-progress", {
                        staticClass: "progreBar",
                        attrs: {
                          "show-text": false,
                          percentage: _vm.terminal_unit,
                          color: "#C75CDF",
                          format: _vm.format,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gressline" },
                [
                  _c("div", { staticClass: "numCount" }, [
                    this.project_type === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.taskDataList.push_user_count_1))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.project_type === 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.taskDataList.push_user_count_2))]
                        )
                      : _vm._e(),
                    _vm._v("\n            |\n            "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-left": "10px",
                          "line-height": "20px",
                          color: "#a6aab8",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.taskDataList.push_user_total_count) + "个"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progessWord" }, [
                    _vm._v("推送终端用户"),
                  ]),
                  _vm._v(" "),
                  this.terminal_user
                    ? _c("el-progress", {
                        staticClass: "progreBar",
                        attrs: {
                          "show-text": false,
                          percentage: _vm.terminal_user,
                          color: "#15BEA5",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gressline" },
                [
                  _c("div", { staticClass: "numCount" }, [
                    this.project_type === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.confirm_unit_count_1)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.project_type === 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.confirm_unit_count_2)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n            |\n            "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-left": "10px",
                          "line-height": "20px",
                          color: "#a6aab8",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.taskDataList.confirm_unit_total_count) +
                            "个"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progessWord" }, [
                    _vm._v("接单联盟单位"),
                  ]),
                  _vm._v(" "),
                  this.receiving_unit
                    ? _c("el-progress", {
                        staticClass: "progreBar",
                        attrs: {
                          "show-text": false,
                          percentage: _vm.receiving_unit,
                          color: "#FF5C5E",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gressline" },
                [
                  _c("div", { staticClass: "numCount" }, [
                    this.project_type === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.confirm_user_count_1)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.project_type === 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#3c4353",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.taskDataList.confirm_user_count_2)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n            |\n            "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-left": "10px",
                          "line-height": "20px",
                          color: "#a6aab8",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.taskDataList.confirm_user_total_count) +
                            "个"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "progessWord" }, [
                    _vm._v("接单联盟用户"),
                  ]),
                  _vm._v(" "),
                  this.receiving_user
                    ? _c("el-progress", {
                        staticClass: "progreBar",
                        attrs: {
                          "show-text": false,
                          percentage: _vm.receiving_user,
                          color: "#F5BB4B",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "rightBar" }, [
          _c(
            "div",
            { staticClass: "rTopBar" },
            [
              _c("div", { staticClass: "topTitle" }, [
                _c("span", { staticClass: "titleWord" }, [
                  _vm._v("月推送任务"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "blocks" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                      },
                      on: { change: _vm.getBarchart },
                      model: {
                        value: _vm.barchart,
                        callback: function ($$v) {
                          _vm.barchart = $$v
                        },
                        expression: "barchart",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { attrs: { id: "myChart" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rBotBar" },
            [
              _c("div", { staticClass: "topTitle" }, [
                _c("span", { staticClass: "titleWord" }, [
                  _vm._v("任务接单时长类别占比"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btnGroup" },
                  [
                    _c(
                      "el-button-group",
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", plain: "" } },
                          [_vm._v("全部任务")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { attrs: { type: "primary", plain: "" } },
                          [_vm._v("航线任务")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { attrs: { type: "primary", plain: "" } },
                          [_vm._v("通用任务")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { attrs: { id: "sector" } }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBox" }, [
      _c("img", {
        staticClass: "topBgcImg",
        attrs: { src: require("../../assets/img/bg1.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBox" }, [
      _c("img", {
        staticClass: "topBgcImg",
        attrs: { src: require("../../assets/img/bg2.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBox" }, [
      _c("img", {
        staticClass: "topBgcImg",
        attrs: { src: require("../../assets/img/bg3.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBox" }, [
      _c("img", {
        staticClass: "topBgcImg",
        attrs: { src: require("../../assets/img/bg4.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }