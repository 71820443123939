import { planeM } from "../Tools/getPlaneImage.js";
import { wgs84togcj02, gcj02towgs84 } from "../Tools/transfrom.js";
import FmSocket from "./FmSocket.js";
import FmMonitorBase from "./FmMonitorBase.js";

let _local_id;
let _webSocketUrl;
let _weksocket;

// 回放相关内容
let _playbackTimer = null; //回放定时器
let _curPackageIndex = 0;
let _allTime = 0; //总的时间
let _sToCount = 0; //每秒运行多少次


class FeimaMonitor extends FmMonitorBase {
  constructor(options) {
    super(options);
    // 请求数据
    _local_id = options.local_id;
    _webSocketUrl = options.url;
    this.playbackTimer = null; //回放定时器
    this.curPackageIndex = 0;
    this.options = options;
    this.totalCount = 0;
    this.getDeviceDetail(this.flightMission)
  }
  // 初始化并连接websocket
  openSocket(websocketData) {
    const msg = this.getSendMessageFun(
      this.flightMission ? this.flightMission.user_id : '',
      _local_id
    );
    const socketOptions = {
      platform: this.platform,
      url: _webSocketUrl,
      msg,
    };
    _weksocket = new FmSocket(socketOptions);
    _weksocket.open();
    _weksocket.onMessage = (res) => {
      const data = JSON.parse(res.data).data
      if (data){
        const time = data.message && data.message[0] ? data.message[0][0] : ''
        if (time){
          this.flightPanelDatas.updated_at = this.getUpdate(time); // 更新时间  更新
        }
        const dataArray = data.message;
        this.updateDatItem([dataArray]);
      }

    };
    _weksocket.onError((err) => {
      this.onErrorSocket(err);
    });
  }
  /**
   * 关闭Socket
   */
  closeSocket() {
    _weksocket.close();
  }
  /**
   * 连接错误
   */
  onErrorSocket(err) {}
  /**
   * 获取发送的指令
   * @returns {string}
   * @private
   */
  getSendMessageFun(userId, missionLocalId) {
    return (
      '{"uid":"' + userId + '","flight_mission_id":"' + missionLocalId + '"}'
    );
  }

  /**
   * 航线数据处理
   * */
  getDeviceDetail(flightMission) {
    // 初始各项数据
    if(flightMission) {
      this.initDatas(flightMission);
    }

    if (!flightMission || !flightMission.flight_log) {
      this.openSocket();
    }
  }
  /**回放数据处理 */
  getBackData(data){
    let totalCount = data.flight_log.packages.length;
    this.totalCount = totalCount;
    this.playbackTimer = setInterval(() => {
      if (this.curPackageIndex < totalCount) {
        let date = parseInt(
          Number(data.flight_log.packages[this.curPackageIndex].created_str)
        );
        let time = this.getUpdate(date);
        this.flightPanelDatas.updated_at = time; // 更新时间  更新
        var dataItem = data.flight_log.packages[this.curPackageIndex];
        // console.log(dataItem);
        this.updateDatItem([[[Number(dataItem.created_str),JSON.stringify(dataItem.data[0]) ]]]);
        this.curPackageIndex++;
      } else {
        clearInterval(this.playbackTimer);
      }
    }, 62.5);
  }
  /**
   * 回放相关
   */
  /**
   * 回放时根据时间戳获取更新时间。
   * @param {时间戳} date
   * @returns
   */
  getUpdate(date) {
    var time = new Date(date);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return add0(h) + ":" + add0(mm) + ":" + add0(s);
  }
  // 回放初始化
  initPlayback() {

  }
  /**
   * 暂停播放
   */
   pausePlayBack() {
    clearInterval(this.playbackTimer);
    this.initLastUpdateTime();
  }
  /**
   * 开始播放
   */
  openPlayBack() {
    this.isBackPlay = true
    this.getBackData(this.flightMission);
  }

  /** 移动*/
  setPlaybackProgress(precent) {
    return new Promise((resolve, reject)=>{
      this.pausePlayBack();

      this.clearPath()
      let data = this.options.flight_data;
      let newCurPackageIndex = Math.ceil(this.totalCount * precent);

      for (let i = 0; i < newCurPackageIndex; i++) {

        let dataItem = data.flight_log.packages[i];
        // this.updateDatItem([[[Number(dataItem.created_str),JSON.stringify(dataItem.data[0]) ]]]);
        this.updatelocation(dataItem.data);
      }
      this.curPackageIndex = newCurPackageIndex;
      setTimeout(()=>{
        this.getBackData(this.flightMission);
      },1000)

      resolve(true)
    })

  }

  // 位置更新
  updateLocation(dataArray) {
    if (!dataArray) {
      return;
    }
    for (let index = 0; index < dataArray.length; index++) {
      let item = JSON.parse(JSON.stringify(dataArray[index])) ; // console.log(item)
      if (item.msgid == 33) {
        let position = this.updateTimeNLocation(item,true)
        return position
      }
      return false
    }
  }

  /**
   * 回放的更新航线和里程
   * @param {Object} position, {latitude, longitude}
   */
   updatePlaybackPosition(position) {
    const { longitude: lng, latitude:lat, altitude:alt } = position
    let val = this.getWgs84Position({ lng, lat, alt });
    // 触发回放改变事件
    this.onPositionUpdated(val);
  }
  // 进度条改变事件监听
  onPlaybackPercentChanged(percent) {}
}
  /**
   * 判断是否要加0
   * @param {时，分，秒} m
   * @returns
   */
function add0(m) {
  return m < 10 ? "0" + m : m;
}
export default FeimaMonitor;
