var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flying" }, [
    _c("div", { staticClass: "title" }, [_vm._v("选择任务")]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "type-select" }, [
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nav == 1,
              expression: "nav == 1",
            },
          ],
          staticClass: "task-type",
          attrs: { src: require("@/assets/img/newFm/clickFm.png") },
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nav == 2,
              expression: "nav == 2",
            },
          ],
          staticClass: "task-type",
          attrs: { src: require("@/assets/img/newFm/fm.png") },
          on: {
            click: function ($event) {
              return _vm.getTaskList(1)
            },
          },
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nav == 2,
              expression: "nav == 2",
            },
          ],
          staticClass: "task-type",
          attrs: { src: require("@/assets/img/newFm/clickTy.png") },
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nav == 1,
              expression: "nav == 1",
            },
          ],
          staticClass: "task-type",
          attrs: { src: require("@/assets/img/newFm/ty.png") },
          on: {
            click: function ($event) {
              return _vm.getTaskList(2)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cen" }, [
        _c(
          "div",
          { staticClass: "nape nape-one nape-top-one" },
          [
            _c("span", [_vm._v("任务名称")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "import scale",
              attrs: { placeholder: "请输入", clearable: "" },
              model: {
                value: _vm.taskName,
                callback: function ($$v) {
                  _vm.taskName = $$v
                },
                expression: "taskName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-two nape-top-one" },
          [
            _c("span", [_vm._v("创建人")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "import scale",
              attrs: { placeholder: "请输入", clearable: "" },
              model: {
                value: _vm.creator,
                callback: function ($$v) {
                  _vm.creator = $$v
                },
                expression: "creator",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-three nape-top-one" },
          [
            _c("span", [_vm._v("创建时间")]),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "import scale",
              attrs: {
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.creationTime,
                callback: function ($$v) {
                  _vm.creationTime = $$v
                },
                expression: "creationTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-one nape-top-two" },
          [
            _c("span", [_vm._v("一级推送类型")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "import scale",
                attrs: { clearable: "", placeholder: "请选择" },
                on: { "visible-change": _vm.dropDown },
                model: {
                  value: _vm.stairType,
                  callback: function ($$v) {
                    _vm.stairType = $$v
                  },
                  expression: "stairType",
                },
              },
              _vm._l(_vm.TypeArr, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.name },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-two nape-top-two" },
          [
            _c("span", [_vm._v("一级推送人")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "import scale",
              attrs: { placeholder: "请输入", clearable: "" },
              model: {
                value: _vm.stairPeople,
                callback: function ($$v) {
                  _vm.stairPeople = $$v
                },
                expression: "stairPeople",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-three nape-top-two" },
          [
            _c("span", [_vm._v("一级推送时间")]),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "import scale",
              attrs: {
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.stairTime,
                callback: function ($$v) {
                  _vm.stairTime = $$v
                },
                expression: "stairTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-one nape-top-three" },
          [
            _c("span", [_vm._v("二级推送类型")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "import scale",
                attrs: { clearable: "", placeholder: "请选择" },
                on: { "visible-change": _vm.dropDown },
                model: {
                  value: _vm.secondType,
                  callback: function ($$v) {
                    _vm.secondType = $$v
                  },
                  expression: "secondType",
                },
              },
              _vm._l(_vm.TypeArr, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.name },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-two nape-top-three" },
          [
            _c("span", [_vm._v("二级推送人")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "import scale",
              attrs: { placeholder: "请输入", clearable: "" },
              model: {
                value: _vm.secondPeople,
                callback: function ($$v) {
                  _vm.secondPeople = $$v
                },
                expression: "secondPeople",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-three nape-top-three" },
          [
            _c("span", [_vm._v("二级推送时间")]),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "import scale",
              attrs: {
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.secondTime,
                callback: function ($$v) {
                  _vm.secondTime = $$v
                },
                expression: "secondTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-one", staticStyle: { top: "203.2px" } },
          [
            _c("span", [_vm._v("任务ID")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "import scale",
              attrs: { placeholder: "请输入", clearable: "" },
              model: {
                value: _vm.taskID,
                callback: function ($$v) {
                  _vm.taskID = $$v
                },
                expression: "taskID",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-two nape-top-four" },
          [
            _c("span", [_vm._v("发布门户")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "import scale",
                attrs: { clearable: "", placeholder: "请选择" },
                on: { "visible-change": _vm.dropDown },
                model: {
                  value: _vm.releasePortal,
                  callback: function ($$v) {
                    _vm.releasePortal = $$v
                  },
                  expression: "releasePortal",
                },
              },
              _vm._l(
                ["全部任务", "已发布至门户网站", "未发布至门户网站"],
                function (item) {
                  return _c("el-option", { key: item, attrs: { value: item } })
                }
              ),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nape nape-three nape-top-four" },
          [
            _c("span", [_vm._v("舆情发布")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "import scale",
                attrs: { clearable: "", placeholder: "请选择" },
                on: { "visible-change": _vm.dropDown },
                model: {
                  value: _vm.releaseOpinion,
                  callback: function ($$v) {
                    _vm.releaseOpinion = $$v
                  },
                  expression: "releaseOpinion",
                },
              },
              _vm._l(
                [
                  "全部任务",
                  "已发布舆情",
                  "未发布舆情",
                  "已发布至门户网站",
                  "未发布至门户网站",
                ],
                function (item) {
                  return _c("el-option", { key: item, attrs: { value: item } })
                }
              ),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-option" }, [
        _c(
          "button",
          {
            staticClass: "inquire btn",
            attrs: { type: "default" },
            on: { click: _vm.inquire },
          },
          [_vm._v("\n        查询\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "reset btn",
            attrs: { type: "default" },
            on: { click: _vm.reset },
          },
          [_vm._v("重置")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dividing-line" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index, staticClass: "bottom-content" }, [
              item.created_at
                ? _c("div", { staticClass: "bottom-content-top flex-view" }, [
                    _vm.selectType == "task"
                      ? _c(
                          "div",
                          {
                            staticClass: "unfold-btn",
                            on: {
                              click: function ($event) {
                                return _vm.ubfoldAlter(index, item.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.unfoldArr[index] ? "-" : "+") +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-top-name" }, [
                      _vm._v("创建人：" + _vm._s(item.mobile)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-top-time" }, [
                      _vm._v(
                        "\n            创建时间：" +
                          _vm._s(item.created_at.slice(0, 19)) +
                          "\n          "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.created_at
                ? _c(
                    "div",
                    {
                      staticClass: "bottom-content-cen flex-view",
                      class: {
                        contentCenVacancy:
                          item.user_push_data === "" &&
                          item.unit_push_data === "",
                      },
                    },
                    [
                      _c("div", { staticClass: "content-cen-name" }, [
                        _vm._v(_vm._s(item.project_name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content-cen-identity",
                          class: {
                            "content-cen-conceal": item.unit_push_data == "",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "推送人：" + _vm._s(item.unit_push_data.push_user)
                            ),
                          ]),
                          _vm._v(" "),
                          item.unit_push_data
                            ? _c("div", [
                                _vm._v(
                                  "\n              推送时间：" +
                                    _vm._s(
                                      item.unit_push_data.push_datetime.slice(
                                        0,
                                        19
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送类型：" +
                                _vm._s(item.unit_push_data.push_type)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送进度：" +
                                _vm._s(item.unit_push_data.push_progress)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.unit_push_data.push_unit_qty)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送人员：" +
                                _vm._s(item.unit_push_data.push_user_qty)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "content-cen-identity content-cen-company",
                          class: {
                            "content-cen-conceal": item.user_push_data == "",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.user_push_data.push_unit_name)
                            ),
                          ]),
                          _vm._v(" "),
                          item.user_push_data
                            ? _c("div", [
                                _vm._v(
                                  "\n              推送时间：" +
                                    _vm._s(
                                      item.user_push_data.push_datetime.slice(
                                        0,
                                        19
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送类型：" +
                                _vm._s(item.user_push_data.push_type)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送进度：" +
                                _vm._s(item.user_push_data.push_progress)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "推送联盟：" +
                                _vm._s(item.user_push_data.push_user_qty)
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _vm.selectType == "project"
                        ? _c("div", { staticClass: "select-box" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.id == _vm.selectProjectObj.id,
                                  expression: "item.id == selectProjectObj.id",
                                },
                              ],
                              staticClass: "select-icon",
                              attrs: {
                                src: require("@/assets/img/chat/select.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.id != _vm.selectProjectObj.id,
                                  expression: "item.id != selectProjectObj.id",
                                },
                              ],
                              staticClass: "select-icon",
                              attrs: {
                                src: require("@/assets/img/chat/no_select.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTask(item, "project")
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.unfoldArr[index]
                ? _c(
                    "div",
                    { staticClass: "bottom-content-bot" },
                    _vm._l(item.arr, function (item2, index2) {
                      return _c(
                        "div",
                        { key: index2 + "0" },
                        [
                          _c("div", { staticClass: "content-list-title" }, [
                            _vm._v(_vm._s(item2.block_name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              ref: "filterTable",
                              refInFor: true,
                              staticStyle: { width: "100%" },
                              attrs: { data: item2.task },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "task_name",
                                  label: "任务名称",
                                  sortable: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "id",
                                  label: "任务ID",
                                  sortable: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.id ==
                                                      _vm.selectTaskObj.id,
                                                    expression:
                                                      "scope.row.id == selectTaskObj.id",
                                                  },
                                                ],
                                                staticClass:
                                                  "select-icon small",
                                                attrs: {
                                                  src: require("@/assets/img/chat/select.png"),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("img", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.row.id !=
                                                      _vm.selectTaskObj.id,
                                                    expression:
                                                      "scope.row.id != selectTaskObj.id",
                                                  },
                                                ],
                                                staticClass:
                                                  "select-icon small",
                                                attrs: {
                                                  src: require("@/assets/img/chat/no_select.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectTask(
                                                      scope.row,
                                                      "task"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          }),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "bottom-el-pagination",
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "current-page": _vm.pageIndex,
              total: _vm.sumPage,
            },
            on: { "current-change": _vm.currentChange },
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "foot" }, [
      _c("div", { staticClass: "form-option" }, [
        _c(
          "button",
          {
            staticClass: "inquire btn",
            attrs: { type: "default" },
            on: { click: _vm.confirmTask },
          },
          [_vm._v("\n        确定\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "reset btn",
            attrs: { type: "default" },
            on: { click: _vm.cancel },
          },
          [_vm._v("取消")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }