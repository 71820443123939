var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map newGeneric" }, [
    _c(
      "div",
      { staticClass: "amap-wrapper" },
      [
        _c(
          "el-amap",
          {
            staticClass: "amap-box",
            attrs: {
              "amap-manager": _vm.amapManager,
              vid: "amap-vue",
              events: _vm.events,
              plugin: _vm.plugin,
              zoom: _vm.zoom,
              center: _vm.center,
            },
          },
          [
            _vm._l(_vm.markers, function (marker, index) {
              return _c("el-amap-marker", {
                key: index,
                attrs: {
                  position: marker.position,
                  events: marker.events,
                  visible: marker.visible,
                  icon: marker.icon,
                  content: marker.template,
                  draggable: marker.draggable,
                },
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.tempolygons, function (polygon, index) {
              return polygon != []
                ? _c("el-amap-polygon", {
                    key: "polygon" + index,
                    ref: "polygon_" + index,
                    refInFor: true,
                    attrs: {
                      bubble: "true",
                      path: polygon.path,
                      "stroke-color": polygon.strokeColor,
                      "fill-color": polygon.fillColor,
                      "fill-opacity": polygon.fillOpacity,
                      draggable: polygon.draggable,
                      events: polygon.events,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm._l(_vm.polygonArr, function (polygon, index) {
              return polygon != []
                ? _c("el-amap-polygon", {
                    key: index + "00a",
                    ref: "polygon_" + index,
                    refInFor: true,
                    attrs: {
                      path: polygon.path,
                      "stroke-color": polygon.strokeColor,
                      "fill-color": polygon.fillColor,
                      "fill-opacity": polygon.fillOpacity,
                      draggable: polygon.draggable,
                      events: polygon.events,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            _c("div", { staticClass: "iconfont" }),
            _vm._v(" "),
            _vm.alertShow
              ? _c("div", { staticClass: "alert-left" }, [
                  _c("div", { staticClass: "iconfont headline" }, [
                    _c("div", {}, [_vm._v("新建通用任务")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "closeBtn", on: { click: _vm.newTask } },
                      [_vm._v("")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "eliminate" }, [
                    _c("div", { staticClass: "eliminate-title" }, [
                      _vm._v("\n            绘制测区\n            "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.kmlInputEl.click()
                            },
                          },
                        },
                        [_vm._v("导入KML")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "kmlInputEl",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: ".kml, .KML" },
                        on: { change: _vm.uploadKmlFile },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "eliminate-img" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "eliminate-hint" }, [
                      _vm._v(
                        "\n            请在地图中绘制您想要飞行的测区范围\n            "
                      ),
                      _c("br"),
                      _vm._v("（注：至少选择四个点进行绘制）\n          "),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "eliminate-btn-div" }, [
                      _c(
                        "div",
                        {
                          staticClass: "eliminate-btn cursor",
                          on: { click: _vm.obliterateall },
                        },
                        [_vm._v("\n              清除重绘\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "eliminate-btn cursor",
                          on: { click: _vm.finishDrawing },
                        },
                        [_vm._v("\n              结束绘制\n            ")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list" }, [
                    _c(
                      "div",
                      { staticClass: "topic" },
                      [
                        _c("div", { staticClass: "topic-view" }, [
                          _vm._v("任务名称"),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "topic-select",
                          attrs: { placeholder: "样例：20210312钦州市青塘镇" },
                          model: {
                            value: _vm.taskName,
                            callback: function ($$v) {
                              _vm.taskName = $$v
                            },
                            expression: "taskName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "topic" },
                      [
                        _c("div", { staticClass: "topic-view" }, [
                          _vm._v("传感器"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "topic-select",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.topicSelect },
                            model: {
                              value: _vm.sensor,
                              callback: function ($$v) {
                                _vm.sensor = $$v
                              },
                              expression: "sensor",
                            },
                          },
                          _vm._l(_vm.sensorArr, function (item) {
                            return _c("el-option", {
                              key: item.name,
                              attrs: { value: item.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "topic" },
                      [
                        _c("div", { staticClass: "topic-view" }, [
                          _vm._v("授权空域"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "topic-el-radio",
                            model: {
                              value: _vm.airspace,
                              callback: function ($$v) {
                                _vm.airspace = $$v
                              },
                              expression: "airspace",
                            },
                          },
                          _vm._l(_vm.airspaceArr, function (item, index) {
                            return _c(
                              "el-radio",
                              { key: item.name, attrs: { label: item.name } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "topic" },
                      [
                        _c("div", { staticClass: "topic-view" }, [
                          _vm._v("任务性质"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "topic-el-radio",
                            model: {
                              value: _vm.taskNature,
                              callback: function ($$v) {
                                _vm.taskNature = $$v
                              },
                              expression: "taskNature",
                            },
                          },
                          _vm._l(_vm.taskNatureArr, function (item, index) {
                            return _c(
                              "el-radio",
                              { key: item.name, attrs: { label: item.name } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.sensorNum == 1
                      ? _c(
                          "div",
                          { staticClass: "topic" },
                          [
                            _c("div", { staticClass: "topic-view" }, [
                              _vm._v("影像分辨率"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "topic-select",
                              attrs: {
                                type: "number",
                                clearable: true,
                                max: 100,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.imageSize,
                                callback: function ($$v) {
                                  _vm.imageSize = $$v
                                },
                                expression: "imageSize",
                              },
                            }),
                            _vm._v("  cm\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sensorNum == 1
                      ? _c(
                          "div",
                          { staticClass: "topic" },
                          [
                            _c("div", { staticClass: "topic-view" }, [
                              _vm._v("航向重叠度"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "topic-select",
                              attrs: {
                                type: "number",
                                clearable: true,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.courseOverlap,
                                callback: function ($$v) {
                                  _vm.courseOverlap = $$v
                                },
                                expression: "courseOverlap",
                              },
                            }),
                            _vm._v("  %\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sensorNum == 1
                      ? _c(
                          "div",
                          { staticClass: "topic" },
                          [
                            _c("div", { staticClass: "topic-view" }, [
                              _vm._v("旁向重叠度"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "topic-select",
                              attrs: {
                                type: "number",
                                clearable: true,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.lateralOverlap,
                                callback: function ($$v) {
                                  _vm.lateralOverlap = $$v
                                },
                                expression: "lateralOverlap",
                              },
                            }),
                            _vm._v("  %\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sensorNum == 2
                      ? _c(
                          "div",
                          { staticClass: "topic" },
                          [
                            _c("div", { staticClass: "topic-view" }, [
                              _vm._v("激光点密度"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "topic-select",
                              attrs: {
                                type: "number",
                                clearable: true,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.laserDot,
                                callback: function ($$v) {
                                  _vm.laserDot = $$v
                                },
                                expression: "laserDot",
                              },
                            }),
                            _vm._v("  p/m²\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sensorNum == 2
                      ? _c(
                          "div",
                          { staticClass: "topic" },
                          [
                            _c("div", { staticClass: "topic-view" }, [
                              _vm._v("航带重叠度"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "topic-select",
                              attrs: {
                                type: "number",
                                clearable: true,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.lateralOverlap,
                                callback: function ($$v) {
                                  _vm.lateralOverlap = $$v
                                },
                                expression: "lateralOverlap",
                              },
                            }),
                            _vm._v("  %\n          "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "80px",
                          "margin-top": "4%",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "light",
                              content: "上传详细说明文件",
                              placement: "right-start",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-demo",
                                attrs: {
                                  action: "aaaa",
                                  accept: "All",
                                  "file-list": _vm.fileList,
                                  "on-change": _vm.FileUpload,
                                  "auto-upload": false,
                                  "on-remove": _vm.handleRemove,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "uploadBtn",
                                    attrs: { slot: "trigger" },
                                    slot: "trigger",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/upload.png"),
                                        alt: "",
                                        width: "20",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  上传\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "alert-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "new-btn",
                          attrs: {
                            type: "primary",
                            disabled: !_vm.drawingConfirmation,
                          },
                          on: { click: _vm.newBtn },
                        },
                        [_vm._v("新建")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cancel-btn cursor",
                          on: { click: _vm.newTask },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "switching" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    on: { change: _vm.switchingChange },
                    model: {
                      value: _vm.switchingValue,
                      callback: function ($$v) {
                        _vm.switchingValue = $$v
                      },
                      expression: "switchingValue",
                    },
                  },
                  _vm._l(_vm.switchingArr, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              float: "right",
                              color: "#8492a6",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(item.platform))]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }