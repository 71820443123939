import store from "@/store";
import { apis } from "@/http/api";
import coordtransform from "@/utils/transform.js";
class RequestData {
  constructor() {
    this.sortiesListData = {
      彩虹: [],
      其他: [],
      测绘装备: [],
      终端设备: [],
      未知: []
    };
    this.categoryBrandData = { 彩虹无人机: { 其他: {}, 广西: [] } };
    this.temCategoryBrandData = { 彩虹无人机: { 其他: {}, 广西: [] } };
    this.historySortiesData = []; //一开始所有的历史架次数据
    this.dateHistorySortiesData = []; //时间筛选的历史数据
    this.temTestOnline = [];
    this.getDeviceCategoryBrand().then(data => {
      // this.categoryBrandData = data;
      data["无人机"].splice(data["无人机"].indexOf("彩虹"), 1);
      for (let key in data) {
        this.temCategoryBrandData[key] = {};
        data[key].forEach(item => {
          this.temCategoryBrandData[key][item] = [];
        });
      }
    });
  }
  // 获取架次数量
  getSortiesCount(date) {
    let params = {};
    if (date !== "") {
      params = date;
    }
    return new Promise((resolve, reject) => {
      apis.sortiesCount(params, store.state.token).then(res => {
        let data = res.data.data;
        resolve(data);
      });
    });
  }
  // 通过日期获取数据
  getDateSortiesList(data, page) {
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    let wgsCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    let allData = { wgs84: {}, gcj02: {} };
    if (page === 1) {
      this.dateHistorySortiesData = [];
    }
    data["per_page"] = 2000;
    return new Promise((resolve, reject) => {
      apis.sortiesList(data, store.state.token).then(res => {
        let data = res.data.data.data;
        this.dateHistorySortiesData = this.dateHistorySortiesData.concat(data);
        temCategoryBrandData = this.analysisData(
          this.dateHistorySortiesData,
          temCategoryBrandData,
          "gcj02"
        );
        wgsCategoryBrandData = this.analysisData(
          this.dateHistorySortiesData,
          wgsCategoryBrandData,
          "wgs84"
        );
        allData["totalPage"] = res.data.data.total;
        allData["per_page"] = res.data.data.per_page;
        allData["gcj02"] = temCategoryBrandData;
        allData["wgs84"] = wgsCategoryBrandData;
        resolve(allData);
      });
    });
  }
  getMassData(data, lnglatText) {
    let that = this;
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(that.temCategoryBrandData)
    );
    let wgsCategoryBrandData = JSON.parse(
      JSON.stringify(that.temCategoryBrandData)
    );
    let allData = { wgs84: {}, gcj02: {} };
    temCategoryBrandData = this.analysisData(
      data,
      temCategoryBrandData,
      "gcj02",
      lnglatText
    );
    wgsCategoryBrandData = this.analysisData(
      data,
      wgsCategoryBrandData,
      "wgs84",
      lnglatText
    );
    allData["totalPage"] = data.length;
    allData["gcj02"] = temCategoryBrandData;
    allData["wgs84"] = wgsCategoryBrandData;
    return allData;
  }
  // 获取架次列表
  getSortiesList(data, page = -1) {
    let that = this;

    return new Promise((resolve, reject) => {
      let params = data;
      let temCategoryBrandData = JSON.parse(
        JSON.stringify(that.temCategoryBrandData)
      );
      let wgsCategoryBrandData = JSON.parse(
        JSON.stringify(that.temCategoryBrandData)
      );
      let allData = { wgs84: {}, gcj02: {} };
      if (page !== -1) {
        params["page"] = page;
      }
      data["per_page"] = 2000;
      apis.sortiesList(params, store.state.token).then(res => {
        let data = res.data.data.data;
        this.historySortiesData = this.historySortiesData.concat(data);
        temCategoryBrandData = this.analysisData(
          this.historySortiesData,
          temCategoryBrandData,
          "gcj02"
        );
        wgsCategoryBrandData = this.analysisData(
          this.historySortiesData,
          wgsCategoryBrandData,
          "wgs84"
        );
        allData["totalPage"] = res.data.data.total;
        allData["gcj02"] = temCategoryBrandData;
        allData["wgs84"] = wgsCategoryBrandData;
        // temCategoryBrandData["totalPage"] = res.data.data.total;
        resolve(allData);
      });
    });
  }

  // 获取设备品牌
  getDeviceCategoryBrand() {
    return new Promise((resolve, reject) => {
      apis.deviceCategoryBrand({}, store.state.token).then(res => {
        let data = res.data.data;
        // this.categoryBrandData = data;
        data["无人机"].splice(data["无人机"].indexOf("彩虹"), 1);
        for (let key in data) {
          this.categoryBrandData[key] = {};
          data[key].forEach(item => {
            this.categoryBrandData[key][item] = [];
          });
        }
        resolve(data);
      });
    });
  }
  getOnlineData() {
    return new Promise((resolve, reject) => {
      apis.deviceViewIndex({ is_online: 1 }, store.state.token).then(res => {
        let data = res.data.data;

        resolve(data);
      });
    });
  }
  // 获取设备视图
  getDeviceViewIndex(is_online = "") {
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    temCategoryBrandData["终端设备"]["省级田长"] = [];
    temCategoryBrandData["终端设备"]["市级田长"] = [];
    temCategoryBrandData["终端设备"]["县区级田长"] = [];
    temCategoryBrandData["终端设备"]["乡镇级田长"] = [];
    temCategoryBrandData["终端设备"]["村级田长"] = [];
    let temData1 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData2 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData3 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData4 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData5 = "";
    let temData6 = "";
    let temData7 = "";
    let temData8 = "";
    let total = 0;
    // temCategoryBrandData["无人机"]["彩虹无人机"] = [];
    let params = is_online === "" ? {} : { is_online: is_online };
    return new Promise((resolve, reject) => {
      apis
        .deviceViewIndex(params, store.state.token)
        .then(res => {
          let data = res.data.data;
          let temData = [
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "4758",
              _score: 0,
              _source: {
                name: "",
                software_code: "13507836602",
                unit_name: "太白地村",
                brand_name: "市级田长",
                category_name: "终端设备",
                last_point: {
                  lon: 106.5751,
                  lat: 23.8869
                },
                last_two_point: {
                  lon: 106.5751,
                  lat: 23.8869
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  mobile: "13507836602",
                  unit_name: "太白地村",
                  name: "唐春秀"
                },
                holder_user_data: null,
                mission_id: "oIas16BD-20211228-X9f3tEhf"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "3695",
              _score: 0,
              _source: {
                name: "",
                software_code: "17776506006",
                unit_name: "东山乡白竹村委",
                brand_name: "乡村绘",
                category_name: "村级田长",
                last_point: {
                  lon: 106.9047,
                  lat: 22.8178
                },
                last_two_point: {
                  lon: 106.9047,
                  lat: 22.8178
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  mobile: "17776506006",
                  unit_name: "东山乡白竹村委",
                  name: "俸永卓"
                },
                holder_user_data: null,
                mission_id: "ivcFHtyj-20211229-m20f28ez"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "3696",
              _score: 0,
              _source: {
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                associated_device_type: "",
                mission_id: "DXgdGvif-20211229-bAA8hApB",
                brand_name: "县区级田长",
                user_data: {
                  unit_name: "东山乡白竹村委",
                  mobile: "17776506006",
                  name: "俸永卓"
                },
                register_province: "",
                unit_name: "东山乡白竹村委",
                software_code: "17776506006",
                last_two_point: {
                  lon: 108.4537,
                  lat: 24.228
                },
                name: "",
                last_point: {
                  lon: 108.4537,
                  lat: 24.228
                },
                online: 1
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "1953",
              _score: 0,
              _source: {
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                associated_device_type: "",
                mission_id: "eobfeCqd-20211230-CX5k9cDY",
                brand_name: "乡镇级田长",
                user_data: {
                  unit_name: "麻垌镇梁村",
                  name: "梁志勇",
                  mobile: "13457620205"
                },
                register_province: "",
                unit_name: "麻垌镇梁村",
                software_code: "13457620205",
                last_two_point: {
                  lon: 108.1571,
                  lat: 23.7663
                },
                name: "",
                last_point: {
                  lon: 108.1571,
                  lat: 23.7663
                },
                online: 1
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "3077",
              _score: 0,
              _source: {
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                associated_device_type: "",
                mission_id: "is9XuD85-20211231-XDG4jJ2r",
                brand_name: "省级田长",
                user_data: {
                  name: "蒋海星",
                  mobile: "18978308826"
                },
                register_province: "",
                unit_name: "广西壮族自治区自然资源调查监测院",
                software_code: "18978308826",
                last_two_point: {
                  lon: 108.3274,
                  lat: 22.8229
                },
                name: "",
                last_point: {
                  lon: 108.3274,
                  lat: 22.8229
                },
                online: 1
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "2883",
              _score: 0,
              _source: {
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                associated_device_type: "",
                mission_id: "sito8xn7-20220109-xdCqXtby",
                brand_name: "市级田长",
                user_data: {
                  unit_name: "水西村委",
                  name: "秦红艳",
                  mobile: "18277371880"
                },
                register_province: "",
                unit_name: "水西村委",
                software_code: "18277371880",
                last_two_point: {
                  lon: 108.8273,
                  lat: 21.9645
                },
                name: "",
                last_point: {
                  lon: 108.8273,
                  lat: 21.9645
                },
                online: 1
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "3073",
              _score: 0,
              _source: {
                name: "",
                software_code: "18978308826",
                unit_name: "广西壮族自治区自然资源调查监测院",
                brand_name: "乡村绘",
                category_name: "乡镇级田长",
                last_point: {
                  lon: 109.7886,
                  lat: 22.0002
                },
                last_two_point: {
                  lon: 109.7886,
                  lat: 22.0002
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  mobile: "18978308826",
                  name: "蒋海星"
                },
                holder_user_data: null,
                mission_id: "msqck45H-20220120-6g3GHp2v"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "2436",
              _score: 0,
              _source: {
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                associated_device_type: "",
                mission_id: "CEYh7v9h-20220129-fDZ4fiHE",
                brand_name: "村级田长",
                user_data: {
                  unit_name: "湾里村委会",
                  name: "蒋明主",
                  mobile: "13471310291"
                },
                register_province: "",
                unit_name: "湾里村委会",
                software_code: "13471310291",
                last_two_point: {
                  lon: 109.2557,
                  lat: 24.2581
                },
                name: "",
                last_point: {
                  lon: 109.2557,
                  lat: 24.2581
                },
                online: 1
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "1622",
              _score: 0,
              _source: {
                name: "",
                software_code: "13768517005",
                unit_name: "广西壮族自治区自然资源调查监测院",
                brand_name: "村级田长",
                category_name: "终端设备",
                last_point: {
                  lon: 109.4535,
                  lat: 25.1812
                },
                last_two_point: {
                  lon: 109.4535,
                  lat: 25.1812
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  unit_name: "广西壮族自治区自然资源调查监测院",
                  name: "陈文林",
                  mobile: "13768517005"
                },
                holder_user_data: null,
                mission_id: "dBnAjYGi-20220215-s5eCoqjp"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "2799",
              _score: 0,
              _source: {
                name: "",
                software_code: "17376202206",
                unit_name: "三江村委",
                brand_name: "县区级田长",
                category_name: "终端设备",
                last_point: {
                  lon: 110.9257,
                  lat: 24.0325
                },
                last_two_point: {
                  lon: 110.9257,
                  lat: 24.0325
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  unit_name: "三江村委",
                  name: "蒋正辉",
                  mobile: "17376202206"
                },
                holder_user_data: null,
                mission_id: "tJJI9gj8-20220215-zlk9iYoi"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "1134",
              _score: 0,
              _source: {
                holder_user_data: null,
                category_name: "终端设备",
                associated_device_software_code: "",
                city: 0,
                created_user_id: 0,
                mission_id: "fHDdawIp-20220215-1kIs4zvZ",
                created_at: "2021-10-08 18:55:56",
                uuid: "",
                register_province: null,
                holder_user_id: 0,
                province: 0,
                updated_at: "2021-10-08 18:55:56",
                last_two_point: {
                  lon: 109.393187,
                  lat: 22.738135
                },
                hardware_code: null,
                id: 1134,
                unit_id: 1,
                holder_name: "",
                register_point: {
                  lon: "0.000000",
                  lat: "0.000000"
                },
                area: 0,
                address: "",
                holder_dept: "",
                associated_device_type: null,
                brand_name: "村级田长",
                user_data: {
                  unit_name: "广西壮族自治区自然资源调查监测院",
                  name: "黄鹰",
                  mobile: "13211592993"
                },
                model_id: 36,
                unit_name: "广西壮族自治区自然资源调查监测院",
                associated_device_type_id: 0,
                user_id: 0,
                software_code: "13211592993",
                name: "",
                last_point: {
                  lon: 109.393184,
                  lat: 22.73831
                },
                online: 1,
                comment: null,
                holder_phone: "13211592993",
                status: 0
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "2608",
              _score: 0,
              _source: {
                name: "",
                software_code: "13517537309",
                unit_name: "全州镇竹溪田村委",
                brand_name: "村级田长",
                category_name: "终端设备",
                last_point: {
                  lon: 110.994938,
                  lat: 25.958328
                },
                last_two_point: {
                  lon: 110.993463,
                  lat: 25.958258
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  unit_name: "全州镇竹溪田村委",
                  name: "胡焕璞",
                  mobile: "13517537309"
                },
                holder_user_data: null,
                mission_id: "5bm7qBKl-20220215-utX9mxs9"
              }
            },
            {
              _index: "gx_device",
              _type: "_doc",
              _id: "2608",
              _score: 0,
              _source: {
                name: "",
                software_code: "13517537309",
                unit_name: "全州镇竹溪田村委",
                brand_name: "乡镇级田长",
                category_name: "终端设备",
                last_point: {
                  lon: 106.9596,
                  lat: 23.0151
                },
                last_two_point: {
                  lon: 106.9596,
                  lat: 23.0151
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  unit_name: "全州镇竹溪田村委",
                  name: "胡焕璞",
                  mobile: "13517537309"
                },
                holder_user_data: null,
                mission_id: "5bm7qBKl-20220215-utX9mxs9"
              }
            }
          ];
          let data1 =
            "106.604609,23.937791;106.680827,23.974813;106.716532,23.918335;106.764597,23.848015;106.80305,23.778915;106.763224,23.743722;106.57577,23.686514;106.342037,23.7529;106.427181,23.848393;106.427181,23.996521;106.594723,24.259716;106.814449,24.177056;106.866634,24.07679;106.770504,24.074283;107.813792,23.876901;108.060984,24.338192;108.868479,24.130316;109.178843,23.595306;108.549876,23.502145;108.429026,23.763833;108.198313,23.974813;108.231272,24.215511;108.08845,24.055096;108.033518,23.793994;107.9676,23.904525;110.365366,24.363215;109.975352,23.841735;109.200816,23.886947;108.893199,24.187954;109.217295,24.663097;109.997325,24.827726;110.722422,24.703027;111.518931,24.343197;111.150889,23.252555;110.25001,22.640482;109.612803,23.176829;109.667735,23.262648;109.733653,23.373627;110.239024,23.841735;";
          let points = [];
          let allPoint = data1.split(";");
          let arrPoint = [];
          allPoint.forEach(item => {
            if (item) arrPoint.push(item.split(","));
          });
          arrPoint.forEach(item => {
            temData.push({
              _index: "gx_device",
              _type: "_doc",
              _id: "2608",
              _score: 0,
              _source: {
                name: "",
                software_code: "13517537309",
                unit_name: "全州镇竹溪田村委",
                brand_name: "乡镇级田长",
                category_name: "终端设备",
                last_point: {
                  lon: Number(item[0]),
                  lat: Number(item[1])
                },
                last_two_point: {
                  lon: Number(item[0]),
                  lat: Number(item[1])
                },
                register_point: {
                  lat: "0.000000",
                  lon: "0.000000"
                },
                register_province: "",
                online: 1,
                associated_device_software_code: "",
                associated_device_type: "",
                user_data: {
                  unit_name: "全州镇竹溪田村委",
                  name: "胡焕璞",
                  mobile: "13517537309"
                },
                holder_user_data: null,
                mission_id: "5bm7qBKl-20220215-utX9mxs9"
              }
            });
          });
          data = temData;
          // for (let i=0;i<3;i++){
          //   data = data.concat(data)
          // }
          if (is_online === "") {
            temData5 = this.getMassData(data, "last_point");
            temData6 = this.getMassData(data, "last_two_point");
            temData7 = this.getMassData(data, "register_point");
            temData8 = this.getMassData(data, "register_point");
          }
          data.forEach((item, index) => {
            // if (
            //   item._source.last_point.lon
            // ) {
            let val = item._source;

            if (is_online === "") {
              if (val.last_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [val.last_point.lon, val.last_point.lat];

                temData1 = this.analysisHistoryData(val, temData1, temItem);
                // let testTemData1 = this.getMassData(val)
              }
              if (val.last_two_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [
                  val.last_two_point.lon,
                  val.last_two_point.lat
                ];
                temData2 = this.analysisHistoryData(val, temData2, temItem);
              }
              if (val.register_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [
                  val.register_point.lon,
                  val.register_point.lat
                ];
                temData3 = this.analysisHistoryData(val, temData3, temItem);
              }
              total = total + 1;
              temData4 = this.analysisHistoryData(val, temData4, item);
            } else {
              if (
                val.category_name === "终端设备" &&
                val.brand_name === "飞享终端"
              ) {
                item["gcj02"] = [val.last_point.lon, val.last_point.lat];
                item["wgs84"] = coordtransform.gcj02towgs84(
                  val.last_point.lon,
                  val.last_point.lat
                );
              } else {
                item["wgs84"] = [val.last_point.lon, val.last_point.lat];
                item["gcj02"] = coordtransform.wgs84togcj02(
                  val.last_point.lon,
                  val.last_point.lat
                );
              }
              switch (val.category_name) {
                case "无人机":
                  if (val.brand_name === "彩虹") {
                    if (val.register_province.indexOf("广西") !== -1) {
                      temCategoryBrandData["彩虹无人机"]["广西"].push(item);
                    } else {
                      if (
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ]
                      ) {
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ].push(item);
                      } else {
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ] = [];
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ].push(item);
                      }
                    }
                  } else {
                    temCategoryBrandData["无人机"][val.brand_name].push(item);
                  }
                  break;
                case "测绘装备":
                  temCategoryBrandData["测绘装备"][val.brand_name].push(item);
                  break;
                case "终端设备":
                  temCategoryBrandData["终端设备"][val.brand_name].push(item);
                  break;
                default:
                  break;
              }
            }
            // } else {
            //   // console.log("错误经纬度",item._source);
            // }
          });
          if (is_online === "") {
            temCategoryBrandData = [
              temData1,
              temData2,
              temData3,
              temData4,
              temData5,
              temData6,
              temData7,
              temData8
            ];
          }

          resolve({ data: temCategoryBrandData, allOnlineData: res.data.data });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
  analysisHistoryData(val, temCategoryBrandData, item) {
    item["name"] = item._source;
    if (item.lnglat) {
      if (val.category_name === "终端设备" && val.brand_name === "飞享终端") {
        item["gcj02"] = item.lnglat;
        item["wgs84"] = coordtransform.gcj02towgs84(
          item.lnglat[0],
          item.lnglat[1]
        );
      } else {
        item["wgs84"] = item.lnglat;

        item["gcj02"] = coordtransform.wgs84togcj02(
          item.lnglat[0],
          item.lnglat[1]
        );
      }
    }

    switch (val.category_name) {
      case "无人机":
        if (val.brand_name === "彩虹") {
          // temCategoryBrandData["无人机"]["彩虹无人机"].push(item);
          if (val.register_province.indexOf("广西") !== -1) {
            temCategoryBrandData["彩虹无人机"]["广西"].push(item);
          } else {
            if (
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ]
            ) {
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ].push(item);
            } else {
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ] = [];
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ].push(item);
            }
            // temCategoryBrandData["彩虹无人机"]["其他"].push(item)
          }
        } else {
          temCategoryBrandData["无人机"][val.brand_name].push(item);
        }
        break;
      case "测绘装备":
        temCategoryBrandData["测绘装备"][val.brand_name].push(item);
        break;
      case "终端设备":
        temCategoryBrandData["终端设备"][val.brand_name].push(item);
        break;
      default:
        break;
    }
    if (item._source.online === 1) {
      this.temTestOnline.push(item);
    }
    return temCategoryBrandData;
  }
  analysisData(
    data,
    temCategoryBrandData,
    type = "gcj02",
    lnglatText = "point"
  ) {
    data.forEach(item => {
      if (item._source[lnglatText].lon) {
        let val = item._source;
        let temData = {};
        let gcjData = {}; //存储gcj02坐标系
        let wgsData = {}; //存储wgs84坐标系

        if (val.category_name === "终端设备" && val.brand_name === "飞享终端") {
          gcjData["lnglat"] = [
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          ];
          wgsData["lnglat"] = coordtransform.gcj02towgs84(
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          );
        } else {
          gcjData["lnglat"] = coordtransform.wgs84togcj02(
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          );
          wgsData["lnglat"] = [
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          ];
        }

        gcjData["style"] = 0;
        gcjData["name"] = item._source;
        wgsData["style"] = 0;
        wgsData["name"] = item._source;

        wgsData["_source"] = item._source; //要删除
        wgsData["gcj02"] = [
          item._source[lnglatText].lon,
          item._source[lnglatText].lat
        ];
        wgsData["wgs84"] = [
          item._source[lnglatText].lon,

          item._source[lnglatText].lat
        ];
        if (type === "gcj02") {
          temData = gcjData;
        } else {
          temData = wgsData;
        }
        switch (val.category_name) {
          case "无人机":
            if (val.brand_name === "彩虹") {
              // temCategoryBrandData["无人机"]["彩虹无人机"].push(item);
              if (val.register_province.indexOf("广西") !== -1) {
                temCategoryBrandData["彩虹无人机"]["广西"].push(temData);
              } else {
                if (
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ]
                ) {
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ].push(temData);
                } else {
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ] = [];
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ].push(temData);
                }
              }
            } else {
              if (!temCategoryBrandData["无人机"]) {
                temCategoryBrandData["无人机"] = {};
              }
              if (temCategoryBrandData["无人机"][val.brand_name]) {
                temCategoryBrandData["无人机"][val.brand_name].push(temData);
              } else {
                temCategoryBrandData["无人机"][val.brand_name] = [];
                temCategoryBrandData["无人机"][val.brand_name].push(temData);
              }
            }
            break;
          case "测绘装备":
            if (!temCategoryBrandData["测绘装备"]) {
              temCategoryBrandData["测绘装备"] = {};
            }
            if (temCategoryBrandData["测绘装备"][val.brand_name]) {
              temCategoryBrandData["测绘装备"][val.brand_name].push(temData);
            } else {
              temCategoryBrandData["测绘装备"][val.brand_name] = [];
              temCategoryBrandData["测绘装备"][val.brand_name].push(temData);
            }
            break;
          case "终端设备":
            if (!temCategoryBrandData["终端设备"]) {
              temCategoryBrandData["终端设备"] = {};
            }
            if (temCategoryBrandData["终端设备"][val.brand_name]) {
              temCategoryBrandData["终端设备"][val.brand_name].push(temData);
            } else {
              temCategoryBrandData["终端设备"][val.brand_name] = [];
              temCategoryBrandData["终端设备"][val.brand_name].push(temData);
            }
            break;
          default:
            break;
        }
      }
    });
    return temCategoryBrandData;
  }
}
export default RequestData;
