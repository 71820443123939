<template>
  <div>
    <TopBar />
    <SiderBar v-show="isHasSidebar" />
    <app-main
      :class="['app-main-portal', { 'app-main-no-siderBar': !isHasSidebar }]"
    />
  </div>
</template>

<script>
import TopBar from "./components/portal/topBar";
import SiderBar from "./components/portal/sidebar";
import AppMain from "./components/AppMain";
export default {
  components: {
    TopBar,
    SiderBar,
    AppMain,
  },
  data() {
    return {
      isHasSidebar: !this.$route.meta.hideSidebar,
    };
  },
  watch: {
    $route() {
      // 判断是否有侧边栏
      this.isHasSidebar = !this.$route.meta.hideSidebar;
    },
  },
};
</script>

<style scoped>
.app-main-portal {
  width: calc(100% - 220px);
  height: 1px;
  min-height: calc(100vh - 50px);
}
.app-main-no-siderBar {
  width: 100%;
}
</style>