var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "加载空域",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-divider", { staticClass: "fenge" }),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c("div", { staticClass: "leftTit" }, [
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("授权空域名称")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空域存续信息")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("起止时间")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("收授权航高")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("任务批件信息")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空管部门名称")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空管联系电话")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空域批件信息")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("主管部门")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("联系电话")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "rightTit" }, [
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.air_space_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v("---------------------------------------------"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.start) + "～" + _vm._s(_vm.end)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.authorize_altitude) + "m"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v("---------------------------------------------"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.traffic_control_department)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.traffic_control_tel)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v("---------------------------------------------"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.manage_department)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.manage_tel)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider", { staticClass: "fenge" }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeBtn } },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }