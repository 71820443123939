<template>
  <div>
    <!-- <el-dialog
      :modal="false"
      :visible.sync="dialogVisible"
      :show-close="false"
      v-dialogDrag
      title="终端信息"
    > -->
    <el-divider></el-divider>
    <div class="particulars-body">
      <div class="div uav">
        <div class="leftBar">
          <span class="title" v-for="item in displayedInfo" :key="item.no">{{
            item.name
          }}</span>
          <!-- <span class="title">设备ID</span>
            <span class="title">联盟单位</span>
            <span class="title">设备状态</span>
            <span class="title">归属部门</span>
            <span class="title">持有人</span>
            <span class="title">持有人手机号</span>
            <span class="title">使用人手机号</span> -->
        </div>

        <div v-if="Object.keys(displayedInfo).length > 0" class="rightNav">
          <span
            class="rightBar"
            v-for="item in displayedInfo"
            :key="item.no"
            :title="allInfo[item.id]"
            >{{ allInfo[item.id] | filterEmpty | statusName }}</span
          >
          <!-- <span
              :title="terminal.device_data.software_code"
              class="rightBar"
            >{{ terminal.device_data ? terminal.device_data.software_code : '无' | filterEmpty}}</span>
            <span class="rightBar" :title="terminal.user_data ? terminal.user_data.unit_name : '无' | filterEmpty">
              {{ terminal.user_data ? terminal.user_data.unit_name : '无' | filterEmpty }}
            </span>
            <span class="rightBar" :title="terminal.device_data ? terminal.device_data.status : '无' | statusName">
              {{ terminal.device_data ? terminal.device_data.status : '无' | statusName }}
            </span>
            <span class="rightBar" :title="terminal.device_data ? terminal.device_data.holder_dept : '无' | filterEmpty">
              {{ terminal.device_data ? terminal.device_data.holder_dept : '无' | filterEmpty }}
            </span>
            <span class="rightBar" :title="terminal.device_data ? terminal.device_data.holder_name : '无' | filterEmpty">
              {{ terminal.device_data ? terminal.device_data.holder_name : '无' | filterEmpty }}
            </span>
            <span class="rightBar" :title="terminal.device_data ? terminal.device_data.holder_phone : '无' | filterEmpty">
              {{ terminal.device_data ? terminal.device_data.holder_phone : '无' | filterEmpty }}
            </span>
            <span class="rightBar" :title="terminal.user_data ? terminal.user_data.mobile : '无' | filterEmpty">
              {{ terminal.user_data ? terminal.user_data.mobile : '无' | filterEmpty }}
            </span> -->
        </div>
      </div>
      <div class="trajectoryBar" v-if="popType === 'terminal'">
        <el-switch
          class="switchTurn"
          @change="showThisTrack"
          v-model="value"
        ></el-switch>
        <span class="trajectory">移动轨迹</span>
      </div>
      <div class="trajectoryBar" v-if="popType === 'drone'">
        <el-button
          v-if="this.droneInfo.url || this.droneInfo.iframe_url"
          class="toDetail"
          @click="toDetail"
          >查看详情</el-button
        >
      </div>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import { string } from "clipboard";
import { mapState } from "vuex";
export default {
  data() {
    const self = this;
    return {
      value: this.trackButtonValue,
      dialogVisible: false,
      holder_dept: "",
      name: "",
      mobile: "",
      popType: "terminal",
      droneInfo: {},
      droneDisplay: [
        {
          no: 1,
          name: "持有单位",
          id: "holder_dept",
        },
        {
          no: 7,
          name: "设备品牌",
          id: "device_brands_name",
        },
        {
          no: 8,
          name: "设备型号",
          id: "device_models_name",
        },
        {
          no: 4,
          name: "持有人",
          id: "holder_name",
        },
        {
          no: 5,
          name: "持有人手机号",
          id: "holder_phone",
        },
      ],
      terminalDispaly: [
        {
          no: 0,
          name: "设备ID",
          id: "software_code",
        },
        {
          no: 1,
          name: "联盟单位",
          id: "unit_name",
        },
        {
          no: 2,
          name: "设备状态",
          id: "status",
        },
        {
          no: 3,
          name: "归属部门",
          id: "holder_dept",
        },
        {
          no: 4,
          name: "持有人",
          id: "holder_name",
        },
        {
          no: 6,
          name: "使用人手机号",
          id: "user_phone",
        },
      ],
      allInfo: {
        software_code: "",
        unit_name: "",
        status: "",
        holder_dept: "",
        holder_name: "",
        holder_phone: "",
        user_phone: "",
        device_brand: "",
        device_model: "",
      },
      displayedInfo: [],
    };
  },
  props: {
    terminal: {
      type: Object,
      data: {},
    },
    trackButtonValue: {
      type: Boolean,
      data: false,
    },
  },
  watch: {
    "terminal.device_data.software_code": {
      handler: "initData",
    },
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl"]),
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    // 更改开关状态
    showThisTrack() {
      this.terminal.track.visible = this.value;
    },
    open(location = false) {
      //获取开关状态
      this.value = this.terminal.track.visible;
      // this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false;
    },
    // 数据初始化
    initData() {
      if (this.terminal == null) return;
      if (Object.keys(this.terminal).length <= 0) return;
      const { droneInfo, dialogType } = this.terminal;
      this.popType = dialogType || "terminal";
      if (droneInfo != null) {
        this.droneInfo = droneInfo;
        this.displayedInfo = { ...this.droneDisplay };
      } else {
        this.displayedInfo = { ...this.terminalDispaly };
      }

      if (this.terminal.device_data != null) {
        try {
          const {
            software_code,
            status,
            holder_dept,
            holder_name,
            holder_phone,
            device_brands_name,
            device_models_name,
          } = this.terminal.device_data;
          this.allInfo = {
            ...{
              software_code,
              status,
              holder_dept,
              holder_name,
              holder_phone,
              device_brands_name,
              device_models_name,
            },
          };
        } catch (e) {
          console.log(e);
        }
      }

      if (this.terminal.user_data != null) {
        try {
          const { unit_name, mobile: user_phone } = this.terminal.user_data;
          this.allInfo = {
            ...this.allInfo,
            unit_name,
            user_phone,
          };
        } catch (e) {
          console.log(e);
        }
      }
    },
    toDetail() {
      const { url, iframe_url } = this.droneInfo;
      window.open(url || iframe_url);
    },
  },
  filters: {
    statusName(val) {
      if (isNaN(val)) return val;
      let text = "";
      switch (val) {
        case 0:
          text = "正常";
          break;
        case 1:
          text = "故障";
          break;
        case 2:
          text = "设备维修";
          break;
        case 3:
          text = "设备遗失";
          break;
        case 4:
          text = "设备报废";
          break;
        default:
          text = val;
          break;
      }
      return text;
    },
    filterEmpty(val) {
      if (val === 0) return val;
      if (!val) {
        return "无";
      }
      return val;
    },
  },
};
</script>

<style lang="less" scoped>
.particulars {
  position: absolute;
  z-index: 9999;
  // top:30%;
  // left: 40%;
}
/deep/.el-dialog__title {
  color: #fff;
}
/deep/.el-divider--horizontal {
  margin: 0 !important;
}
/deep/.el-dialog {
  height: 480px !important;
  width: 336px !important;
  border-radius: 4px;
}
/deep/.el-dialog__header {
  height: 50px;
  background: #000620 !important;
  padding: 15px 0 0 20px;
}
/deep/.el-dialog__body {
  padding: 0;
  height: 90%;
  background: #242f5d !important;
}
.dialogTitle {
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  background: #000620;
  font-size: 21px;
  color: #fff;
}
.particulars-body {
  background: #242f5d;
  display: flex;
  flex-direction: column;
  .trajectoryBar {
    align-self: flex-end;
    bottom: 0;
    margin: 60px 20px 40px 0;
    .trajectory {
      margin-left: 5px;
      color: #a4aee2;
    }
    button {
      border-radius: 20px;
      background-color: #0052f7;
      border-color: #1890ff;
      color: #ffffff;
      opacity: 0.7;
    }
    button:hover {
      opacity: 1;
    }
  }
  .uav {
    display: flex;
    .leftBar {
      width: 80px;
      margin: 25px 0 0 40px;
      display: flex;
      flex-direction: column;
      .title {
        width: 80px;
        color: #a6acb9;
        font-size: 13px;
        line-height: 35px;
        text-align: right;
      }
    }
    .rightNav {
      display: flex;
      width: 180px;
      margin: 25px 0 0 35px;
      flex-direction: column;
      .rightBar {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        font-size: 13px;
        line-height: 35px;
      }
    }
  }
}
</style>
