var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.dialogVisible,
            width: "260px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "userInfor" }, [
            _c(
              "div",
              { staticClass: "userAva" },
              [
                _vm.token
                  ? _c("avatar", {
                      staticClass: "avatar",
                      attrs: {
                        avatar: _vm.user.photo_url,
                        width: 51,
                        height: 51,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "userIdentity" }, [
              _c(
                "span",
                { staticClass: "usernames", attrs: { title: _vm.user.name } },
                [_vm._v(_vm._s(_vm.user.name))]
              ),
              _vm._v(" "),
              _vm.user.is_admin == 1
                ? _c("i", { staticClass: "iconfont userIcons" }, [_vm._v("")])
                : _vm._e(),
              _vm._v(" "),
              _vm.user.is_admin == 0 && _vm.user.is_unit == 1
                ? _c("i", { staticClass: "iconfont userIconsone" }, [
                    _vm._v(""),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.user.is_admin == 1
              ? _c("div", { staticClass: "unitBtn" }, [_vm._v("超级管理员")])
              : _vm._e(),
            _vm._v(" "),
            _vm.user.is_admin == 0 && _vm.user.is_unit == 1
              ? _c("div", { staticClass: "userBtn" }, [
                  _vm._v("\n        联盟管理员\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.user.is_admin == 0 &&
            _vm.user.is_unit == 0 &&
            _vm.user.register_channel !== "门户网站用户"
              ? _c("div", { staticClass: "zhongduan" }, [
                  _vm._v("\n        联盟终端用户\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.user.is_admin == 0 &&
            _vm.user.is_unit == 0 &&
            _vm.user.register_channel === "门户网站用户"
              ? _c("div", { staticClass: "enduser" }, [
                  _vm._v("\n        门户网站用户\n      "),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dividing" }),
          _vm._v(" "),
          _c("div", { staticClass: "bodyBar" }, [
            _c("div", { staticClass: "settings" }, [
              _c("span", { staticClass: "title" }, [_vm._v("个人设置")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu",
                  on: {
                    click: function ($event) {
                      return _vm.personal(7)
                    },
                  },
                },
                [_vm._v("个人信息")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu",
                  on: {
                    click: function ($event) {
                      return _vm.settings(7)
                    },
                  },
                },
                [_vm._v("账户设置")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dividing" }),
            _vm._v(" "),
            _c("div", { staticClass: "application" }, [
              _c("span", { staticClass: "title" }, [_vm._v("数据申请")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu",
                  on: {
                    click: function ($event) {
                      return _vm.Applied(7)
                    },
                  },
                },
                [_vm._v("已申请数据")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dividing" }),
            _vm._v(" "),
            _c("div", { staticClass: "application" }, [
              _c("span", { staticClass: "title" }, [_vm._v("预警发布")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu",
                  on: {
                    click: function ($event) {
                      return _vm.issued(7)
                    },
                  },
                },
                [_vm._v("已发布预警")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dividing" }),
            _vm._v(" "),
            _c("div", { staticClass: "application" }, [
              _c("span", { staticClass: "title" }, [_vm._v("空域查询")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "menu",
                  on: {
                    click: function ($event) {
                      return _vm.Airspaceapplied(7)
                    },
                  },
                },
                [_vm._v("已申请空域")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "divsolid" }),
          _vm._v(" "),
          _c("div", { staticClass: "loginOut" }, [
            _vm.user.is_admin == 1
              ? _c(
                  "div",
                  {
                    staticClass: "signout",
                    on: {
                      click: function ($event) {
                        return _vm.backstage(8)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont backstage" }, [
                      _vm._v(""),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "backstageword" }, [
                      _vm._v("后台管理"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "out", on: { click: _vm.loginOut } }, [
              _vm._v("退出登录"),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }