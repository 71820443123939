var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "大文件回传",
        visible: _vm.dialogVisible,
        "custom-class": "uppy-dialog",
        "before-close": _vm.shut,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", [_vm._v("提示：上传过程中删除文件将无法保存文件上传记录")]),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("iframe", {
            ref: "iframe",
            staticStyle: { border: "0px" },
            attrs: {
              src: _vm.uppyIframe,
              frameborder: "0",
              scrolling: "no",
              height: "600",
              width: "780",
              id: "uppyIframe",
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }