var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        visible: _vm.Ydialog,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.Ydialog = $event
        },
      },
    },
    [
      this.userform.is_admin == 0
        ? _c("span", { staticClass: "titleBar" }, [
            _vm._v(
              "确定将    " +
                _vm._s(this.userform.name) +
                "    设置成超级管理员吗？"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      this.userform.is_admin == 1
        ? _c("span", { staticClass: "titleBar" }, [
            _vm._v(
              "确定取消    " +
                _vm._s(this.userform.name) +
                "    超级管理员身份吗？"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          this.userform.is_admin == 0
            ? _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.yesSuper },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _vm._v(" "),
          this.userform.is_admin == 1
            ? _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.noSuper },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "footBtn",
              on: {
                click: function ($event) {
                  _vm.Ydialog = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }