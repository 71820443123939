<template>
  <el-dialog
    title="舆情发布"
    :visible.sync="dialogVisible"
    width="720px"
    :close-on-click-modal="false"
    :before-close="handleClose"
    v-dialogDrag
  >
    <el-divider></el-divider>
    <div class="bodyBar">
      <el-form class="formbox" ref="ruleForm" :model="ruleForm">
        <el-form-item
          class="userForm"
          prop="project_id"
          label="灾害应急任务名称"
        >
          <el-select
            v-model="ruleForm.project_name"
            filterable
            class="fmSelect"
            placeholder="请选择"
            :disabled="true"
          ></el-select>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="灾害应急任务包络"
        >
          <el-button plain v-for="(item, index) in projectName" :key="index">
            {{ item.task_name }}
          </el-button>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="任务下达类型"
        >
          <span class="taskType">
            {{
              sentimentData.project_type == 1 ? "航线任务下达" : "通用任务下达"
            }}
          </span>
        </el-form-item>
        <div class="summary">
          <span class="summarytit">灾害应急任务汇总</span>
          <div class="summaryBar">
            <span class="count">包含总任务数：{{ project_task_count }}个</span>
            <span class="count"
              >已接单任务数：{{ project_task_confirm_count }}个</span
            >
            <span class="count"
              >待接单任务数：{{ project_task_unconfirmed_count }}个</span
            >
            <span class="count"
              >进行中任务数：{{ project_task_progress }}个</span
            >
            <span class="count"
              >已完成任务数：{{ project_task_finished }}个</span
            >
          </div>
        </div>
        <div class="scene">
          <span class="scenetit">灾害应急任务现场</span>
          <div class="sceneBar"></div>
        </div>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="是否发起现场连线"
        >
          <span class="connection">
            {{ sentimentData.spot_connection == 0 ? "否" : "是" }}
          </span>
        </el-form-item>
        <el-form-item class="userForm" prop="disaster_level" label="灾情态势">
          <span class="situation">{{ sentimentData.disaster_level }}</span>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="disaster_description"
          label="灾情评述"
        >
          <span class="situation">
            {{ sentimentData.disaster_description }}
          </span>
        </el-form-item>
        <el-form-item label>
          <div class="snapshot">
            <span class="snapshotit">灾情测区快照</span>
            <div class="imgNav">
              <img class="imgtit" :src="sentimentData.area_snapshoot" alt />
            </div>
          </div>
        </el-form-item>
        <el-form-item label>
          <div class="henduoren">
            <span class="henduorenWord">灾情现场快照</span>
            <div class="imgNav">
              <img
                v-if="sentimentData.snapshoot_1 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_1"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_2 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_2"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_3 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_3"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_4 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_4"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_5 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_5"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_6 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_6"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_7 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_7"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_8 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_8"
                alt
              />
              <img
                v-if="sentimentData.snapshoot_9 != ''"
                class="imgtit"
                :src="sentimentData.snapshoot_9"
                alt
              />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-divider class="divd"></el-divider>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    sentimentData: {
      type: Object,
      default: {},
    },
    projectName: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: { project_name: "", disaster_description: "" },
      visible: true,
      project_id: "",
      projectData: [],
      project_task_count: "",
      project_task_confirm_count: "",
      project_task_unconfirmed_count: "",
      project_task_progress: "",
      project_task_finished: "",
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    open() {
      this.dialogVisible = true;
      this.ruleForm.project_name = this.sentimentData.project_name;
      this.project_id = this.sentimentData.project_name;
      this.projectData = JSON.parse(this.sentimentData.relation_task_id);
      this.getProjectDetail(this.sentimentData.project_id);
    },
    // 获取项目详情
    getProjectDetail(param) {
      this.$apis
        .getProjectDetail({ project_id: param }, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.project_task_count = data.data.data.project_task_count;
            this.project_task_confirm_count =
              data.data.data.project_task_confirm_count;
            this.project_task_unconfirmed_count =
              data.data.data.project_task_unconfirmed_count;
            this.project_task_progress = data.data.data.project_task_progress;
            this.project_task_finished = data.data.data.project_task_finished;
            this.taskType = data.data.data.project_data.type;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    uploadImg(e) {
      files = e.target.files[0];
      this.fileUrl = window.URL.createObjectURL(e.srcElement.files[0]);
      this.formDatas(files);
    },
  },
  computed: {
    ...mapState(["token", "user"]),
  },
};
</script>

<style lang="less" scoped>
.taskList {
  width: 56px;
  height: 24px;
  border: 1px solid #a6aab8;
  border-radius: 2px;
  float: left;
  margin-right: 4px;
  text-align: center;
  line-height: 22px;
  .word {
    font-size: 12px;
    color: #838a9d;
  }
}
.taskType {
  font-size: 13px;
  color: #838a9d;
  margin-left: 27px;
}
.connection {
  font-size: 13px;
  color: #838a9d;
}
.situation {
  font-size: 13px;
  color: #838a9d;
  margin-left: 55px;
}
.sign {
  float: left;
}
.taskchoose {
  margin-left: 28px;
}
.degree {
  margin-left: 55px;
}
/deep/.el-form-item {
  width: 100%;
}
/deep/.el-dialog {
  height: 650px !important;
  overflow-y: auto;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
.bodyBar {
  width: 92%;
  height: 100%;
  margin-left: 8%;
}
.fmSelect {
  width: 240px;
}
.summary {
  width: 100%;
  margin-top: 20px;
  float: left;
  .summarytit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .summaryBar {
    width: 410px;
    height: 100px;
    border-radius: 2px;
    background-color: #f6f7f9;
    float: left;
    display: flex;
    flex-wrap: wrap-reverse;
    margin-left: 20px;
    .count {
      color: #838a9d;
      font-size: 13px;
      line-height: 45px;
      margin-left: 10px;
    }
  }
}
.scene {
  width: 100%;
  margin-top: 20px;
  float: left;
  .scenetit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .sceneBar {
    height: 15px;
    width: 410px;
    float: left;
    margin-left: 20px;
    border-bottom: 1px dashed #a6aab8;
  }
}
/deep/.el-textarea {
  width: 74%;
  float: right;
}
.snapshot {
  width: 100%;
  margin-top: 20px;
  float: left;
  .snapshotit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .snapshotbar {
    margin-left: 12%;
    float: left;
    line-height: 45px;
    .imgupload {
      font-size: 0;
      width: 224px;
      height: 130px;
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 13%;
      left: 21.5%;
    }
    .snapshotBar {
      width: 224px;
      height: 130px;
      background-color: #f6f7f9;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      .uploadImg {
        margin-left: 89px;
        margin-top: 20px;
      }
      .describe {
        color: #838a9d;
        font-size: 13px;
        text-align: center;
        line-height: 1px;
      }
    }
  }
}
.henduoren {
  float: left;
  width: 100%;
  margin-top: 20px;
  .henduorenWord {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
}
.imgNav {
  width: 80%;
  margin-left: 6.5%;
  float: left;
  .imgtit {
    width: 224px;
    height: 130px;
    margin-right: 10px;
    float: left;
    margin-bottom: 15px;
  }
}
.divd {
  float: left;
}
.dialog-footer {
  float: right;
  margin-bottom: 10px;
}
</style>
