// 登录注册等用户相关接口
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  登录接口
export const login = data => fly.post(
  baseURL + '/user/login',
  data, deploy(30000, '')
)
//  注册接口
export const resetPassword = data => fly.post(
  baseURL + '/user/resetPassword',
  data, deploy(30000, '')
)

//  重置密码
export const register = data => fly.post(
  baseURL + '/user/register',
  data, deploy(30000, '')
)

// 用户信息
export const info = (data, token) => fly.post(
  baseURL + '/user/info',
  data, deploy(30000, token)
)
//  获取验证短信
export const note = data => fly.post(
  baseURL + '/common/smsValid',
  data, deploy(30000, '')
)

//  文件上传接口
export const uploadData = (data, token) => fly.post(
  baseURL + '/data/upload',
  data, deploy(900000, token)
)

// 获取文件列表
export const uploadList = (data, token) => fly.get(
  baseURL + '/data/list',
  data, deploy(30000, token)
)

// 删除文件
export const delfile = (data, token) => fly.get(
  baseURL + '/data/delete',
  data, deploy(300000, token)
)

// 下载文件
export const download = (data, token) => fly.get(
  baseURL + '/data/download',
  data, {
    timeout: 30000,
    headers: {
      'Access-Control-Max-Age': '86400',
      'Content-Type': 'application/octet-stream',
      token: token
    },
    parseJson: true
  }
)

//  角色列表接口
export const roleList = (data, token) => fly.post(
  baseURL + '/role/list',
  data, deploy(30000, token)
)

//  角色子列表
export const rolechildList = (data, token) => fly.post(
  baseURL + '/rolechild/list',
  data, deploy(30000, token)
)

//  保存子角色
export const rolechildSave = (data, token) => fly.post(
  baseURL + '/rolechild/save',
  data, deploy(30000, token)
)

//  删除子角色
export const rolechildDel = (data, token) => fly.post(
  baseURL + '/rolechild/del',
  data, deploy(30000, token)
)

// 联盟统计
export const getUserRelatedCount = (data, token) => fly.get(
  baseURL + '/report/getUserRelatedCount',
  data, deploy(30000, token)
)


// 联盟统计
export const userUpdateInfo = (data, token) => fly.post(
  baseURL + '/user/updateInfo',
  data, deploy(30000, token)
)


// 修改联盟
export const updateUnit = (data, token) => fly.post(
  baseURL + '/user/updateUnit',
  data, deploy(30000, token)
)

// 验证手机号
export const userVerifyMobile = (data, token) => fly.post(
  baseURL + '/user/verifyMobile',
  data, deploy(30000, token)
)

// 修改手机号
export const userUpdateMobile = (data, token) => fly.post(
  baseURL + '/user/updateMobile',
  data, deploy(30000, token)
)


// 修改密码
export const userEditPassword = (data, token) => fly.post(
  baseURL + '/user/editPassword',
  data, deploy(30000, token)
)

// 城市列表
export const getCity = (data, token) => fly.post(
  baseURL + '/common/getCity',
  data, deploy(30000, token)
)

// 用户上传头像
export const userPhoto = (data, token) => fly.post(
  baseURL + '/user/photo',
  data, deploy(30000, token)
)

