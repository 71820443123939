var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            "default-active": _vm.$route.path,
            "text-color": "#fff",
            router: "",
          },
          on: { select: _vm.handleSelect },
        },
        [
          _vm.navMessage == 0
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "航线任务" },
                  attrs: { index: "/getawayTask?nav=feima" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("航线任务"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 0
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "通用任务" },
                  attrs: { index: "/getawayTask?nav=gm" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("通用任务"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 3
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("空域查询"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 3
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "空域查询" },
                  attrs: { index: "/airsearch?nav=air" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("空域查询"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 3
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("空域档案"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 3
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "已加载授权空域" },
                  attrs: { index: "/authorization?nav=aired" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("已加载授权空域"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("个人设置"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "个人信息" },
                  attrs: { index: "/personal?nav=pers" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("个人信息"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "账户设置" },
                  attrs: { index: "/settings?nav=sett" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("账户设置"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("数据申请"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "已申请数据" },
                  attrs: { index: "/Applied?nav=Appl" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("已申请数据"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("预警发布"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "已发布预警" },
                  attrs: { index: "/issued?nav=issued" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("已发布预警"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item-group",
                [
                  _c("template", { staticClass: "airTitle", slot: "title" }, [
                    _vm._v("空域咨询"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 7
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "已申请空域" },
                  attrs: { index: "/Airspace?nav=Airs" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("已申请空域"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 8
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "数据申请管理" },
                  attrs: { index: "/datamanage?nav=dman" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("数据申请管理"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 8
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "预警发布管理" },
                  attrs: { index: "/warningmanage?nav=wman" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("预警发布管理"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.navMessage == 9
            ? _c(
                "el-menu-item",
                {
                  class: { active: _vm.currentUrl[2] == "发布任务" },
                  attrs: { index: "/publishTask" },
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("发布任务"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }