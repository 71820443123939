import { gcj02towgs84 } from "../Tools/transfrom.js";
class BaseFmMap {
  constructor(mapId) {
    this.mapId = mapId;
  }
  /**
   * 获取Polyline
   * @param {Object} positions
   * @param {Object} polyOptions
   */
  getPolyline(positions, polyOptions) {}

  /*
   * 更新Polyline
   * @param {Object} position
   * @param {Object} airline
   */
  addPositionToPolyline(position, airline) {}

  /**
   * 获取marker
   * @param {Object} position
   * @param {Object} makerOption
   */
  getMarker(position, makerOption) {}

  /*
   * 过滤空元素
   */
  filterEmpty(object) {
    for (let key in object) {
      if (object[key] === "" || object[key] == null) {
        delete object[key];
      }
    }
    return object;
  }
}

class MapHelper {
  constructor(platform, mapId, options) {
    this.map = new BaseFmMap(mapId);
    this.platform = platform

    this.airlineConfig = null
    this.flyingAirlineConfig = null;
    this.airlineOptions = this.getAirlineOptions(options)
    
    if (platform === "uniapp") {
      this.map = new UniMap(mapId);
    } else {
      this.map = new Amap(mapId);
    }

    this.markers = [];
  }
  getAirline(positions, type) {
    const polyOptions = type === 'base' ? this.airlineConfig : this.flyingAirlineConfig
    return this.map.getPolyline(positions, polyOptions);
  }

  updateAirline(position, airline) {
    return this.map.addPositionToPolyline(position, airline);
  }

  addMarker(position, markerOption) {
    const marker = this.map.createMarkerObject(position, markerOption);
    this.markers.push(marker);
    return marker;
  }

  getAirlineOptions(options) {
    const { base_airline_options, upper_airline_options } = options;

    const _config = {
      strokeColor: "#aaccaa",
      strokeWidth: 2,
    };
    const _uniAirlineConfig = {
      color: "#aaccaa",
      width: 2,
    };
    const airlineBaseConfig =
      this.platform === "uniapp" ? _uniAirlineConfig : _config;

    this.airlineConfig = base_airline_options || airlineBaseConfig;

    this.flyingAirlineConfig =
      upper_airline_options || base_airline_options || airlineBaseConfig;

  }
}
class UniMap extends BaseFmMap {
  constructor(mapId) {
    super(mapId);
  }
  /*
   * 获取 polyline -- uniapp
   * @params { Array } positions数组, [{latitude, longitude}]
   * @return { Object } polyline对象
   * 文档：https://uniapp.dcloud.io/component/map?id=map
   * {
   *   points: [],
   *   color: '#ffffff',
   *   width: 2,
   *   id:
   * }
   * */
  getPolyline(positions, polyOptions) {
    const polyline = {
      ...polyOptions,
      points: [...positions],
    };
    return this.filterEmpty(polyline);
  }

  /**
   * @param {Object} position: {latitude: 纬度， longitude: 经度}
   */
  addPositionToPolyline(position, polyline) {
    const points = polyline.points;
    points.push(position);
    polyline.points = [...points];
    return polyline;
  }

  /**
   * @param {Object} position: {latitude: 纬度， longitude: 经度}
   */
  createMarkerObject(position, markerOption) {
    const marker = {
      ...markerOption,
      iconPath: markerOption.icon,
      latitude: position.latitude,
      longitude: position.longitude,
      altitude: position.altitude,
    };
    if ("icon" in marker) delete marker["icon"];
    return marker;
  }
}

class Amap extends BaseFmMap {
  constructor(mapId) {
    super(mapId);
  }
  /*
   * 获取 polyline -- uniapp
   * @params { Array } positions数组, [{latitude, longitude}]
   * @return { Object } polyline对象
   * 文档： https://lbs.amap.com/demo/javascript-api/example/overlayers/polyline-draw-and-edit
   * {
   *   path: [],
   *   strokeColor: '#ffffff',
   *   borderWeight: 2,
   *   lineJoint: 'round'
   * }
   * */
  getPolyline(positions, polyOptions) {
    const path = positions.map((position) => {
      return [position.longitude, position.latitude, position.altitude];
    });
    const options = { ...polyOptions }
    options.path = path
    return options;
  }
  /**
   * @param {Object} position: {latitude: 纬度， longitude: 经度}
   */
  addPositionToPolyline(position, polyline) {
    const path = polyline.path;
    path.push([position.longitude, position.latitude, position.altitude]);
    return polyline;
  }
  /**
   * @param {Object} position: {latitude: 纬度， longitude: 经度}
   */
  createMarkerObject(position, markerOptions) {
    const marker = {
      ...markerOptions,
    };
    marker.lnglat = [position.longitude, position.latitude, position.altitude];
    if(!position.altitude) marker.lnglat.push(position.altitude)
    return marker;
  }
}

export default MapHelper;
