<template>
  <!-- 手机号登录 -->
  <div class="centerWindow">
    <div class="loginBgc">
      <div class="fmWelcome">
        <div class="fmWelLogin">
          <p class="sayHello">您好,</p>
          <p class="welLogin">欢迎登录</p>
          <p class="fmsider"></p>
          <p class="fmTitle">智慧田长管理平台</p>
          <!-- <p class="sonTitle">调度中心端</p> -->
        </div>
      </div>
      <div class="fmLoginNav">
        <div class="loginNav">
          <div class="loginBox">
            <p class="loginTitle">联盟代码登录</p>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-alert
                title="如忘记密码，请联系超级管理员。"
                type="warning"
                show-icon
                :closable="false"
                v-if="showWarning"
              ></el-alert>
              <el-form-item prop="unit_code" class="demo-ruleForm">
                <el-input
                  placeholder="请输入联盟代码"
                  v-model="ruleForm.unit_code"
                ></el-input>
              </el-form-item>
              <el-form-item prop="unit_password" class="demo-ruleForm">
                <el-input
                  placeholder="请输入密码"
                  v-model="ruleForm.unit_password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item class="SignInBar">
                <el-checkbox v-model="agreeStatement"
                  ><span style="color:rgb(96, 98, 102)">我已同意并认真阅读</span><a href="#" @click="lookStatement" style="color:#5185ff">《免责声明》</a></el-checkbox
                >
                <el-button
                  class="loginBar"
                  type="primary"
                  :loading="loading"
                  @click="userLogin"
                  :disabled="!agreeStatement"
                  >登&nbsp;&nbsp;录</el-button
                >
              </el-form-item>
              <el-form-item class="demo-ruleForm">
                <div class="otherLogin">
                  <div class="leftBoder"></div>
                  <div class="FMword" @click="phoneLogin">手机号登录</div>
                  <div class="rightBoder"></div>
                </div>
              </el-form-item>
            </el-form>
            <div class="footerBar">
              <el-link class="register" @click="registerNum" :underline="false"
                >注册账号</el-link
              >
              <div class="dividing"></div>
              <el-link class="forgetPsw" @click="changeReg" :underline="false"
                >忘记联盟代码密码</el-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="免责声明" :visible.sync="disStatemenetData" width="302px" center>
      <disclaimer :show="disStatemenetData" />
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="disStatemenetData = false;agreeStatement = false">取 消</el-button> -->
        <el-button type="primary" @click="disStatemenetData = false;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import disclaimer from "@/components/statemenet/disclaimer"
export default {
  data() {
    return {
      showWarning: false,
      ruleForm: {
        unit_code: "",
        unit_password: "",
      },
      rules: {
        unit_code: [
          { required: true, message: "联盟代码不存在", trigger: "blur" },
        ],
        unit_password: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      loading: false,
      agreeStatement: false,
      disStatemenetData: false, //免责声明弹窗
    };
  },
  methods: {
    ...mapMutations(["unitStorage", "unituserStorage"]),
    registerNum() {
      this.$router.push("/register");
    },
    /**
     * 查看免责声明
     */
    lookStatement() {
      this.disStatemenetData = true
    },
    userLogin() {
      // validate：验证当前表单元素中所有的规则
      if (this.agreeStatement){
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.submitData();
          } else {
            this.loading = false;
          }
        });
      }

    },
    submitData() {
      this.$apis
        .loginByCode({
          unit_code: this.ruleForm.unit_code,
          unit_password: this.ruleForm.unit_password,
        })
        .then((data) => {
          if (data.data.status == 0) {
            this.unitStorage(data.data.data.unit_token);
            this.unituserStorage(data.data.data.unit);
            this.loading = true;
            this.$router.push("/loginsuccess");
            this.$message({
              message: data.data.message,
              type: "success",
              offset: 80,
            });
          } else {
            this.$message({
              message: data.data.message,
              type: "error",
            });
            this.loading = false;
          }
        })
        .catch((err) => {});
    },
    // 手机号登录
    phoneLogin() {
      this.$router.push("/login");
    },
    // 忘记联盟代码密码
    changeReg() {
      this.showWarning = true;
    },
  },
  components: {
    disclaimer
  },
  created() {
    // enter事件
    const that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      // 验证在登录界面和按得键是回车键enter
      if (that.$route.path == "/otherlogin" && e.keyCode == 13) {
        that.userLogin(); // 登录函数
      }
    };
  },
};
</script>

<style lang="less" scoped>
.centerWindow {
  width: 100%;
  height: 100%;
  overflow: auto;

  .loginBgc {
    min-width: 1280px;
    min-height: 800px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("../../assets/img/bgc-login.png") no-repeat center;
    background-size: 100% 100%;
    padding-bottom: 68px;
    display: flex;
    .fmWelcome {
      display: block;
      // float: left;
      margin: 0 auto;
      padding-top: 25vh;
      margin-left: 6%;
      .sayHello {
        width: 120px;
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 52px;
      }
      .welLogin {
        width: 160px;
        height: 52px;
        line-height: 82px;
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
      }
      .fmsider {
        width: 166px;
        height: 4px;
        background: rgba(255, 255, 255, 0.43);
        margin-top: 59px;
      }
      .fmTitle {
        width: 850px;
        height: 74px;
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 74px;
        margin-top: 51px;
      }
      .sonTitle {
        width: 250px;
        height: 64px;
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 64px;
        margin-top: 53px;
      }
    }
  }
  .fmLoginNav {
    padding: 68px 68px 0px 0px;
    .loginNav {
      width: 615px;
      // min-height: 800px;
      height: calc(100vh - 136px);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      // float: right;
      padding: 167px 164px 147px 125px;
      .loginBox {
        width: 100%;
        height: 100%;
        .loginTitle {
          width: 100%;
          height: 42px;
          font-size: 32px;
          color: rgba(59, 66, 83, 1);
          line-height: 2px;
        }
        .demo-ruleForm {
          margin-top: 37px;
          width: 370px;
        }
        .SignInBar {
          margin-top: 80px;
          .loginBar {
            font-size: 20px;
            width: 370px;
            height: 55px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.otherLogin {
  display: block;
  width: 370px;
}
.FMword {
  color: #0094ff;
  float: left;
  text-align: center;
  padding: 0px 20px 0 20px;
  margin-top: -18px;
  cursor: pointer;
}
.register {
  cursor: pointer;
}
.forgetPsw {
  cursor: pointer;
}
.leftBoder {
  width: 120px;
  height: 2px;
  background-color: #8099bb;
  float: left;
}
.rightBoder {
  float: left;
  width: 130px;
  height: 2px;
  background-color: #8099bb;
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}
/deep/ .el-input__inner {
  border-radius: 8px;
  height: 44px;
  border: 2px solid rgba(166, 170, 184, 1);
  background-color: rgba(255, 255, 255, 0.3);
}
/deep/.el-divider__text {
  background-color: transparent;
}

.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;
}
.el-alert {
  width: 280px;
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 0px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  border: 1px solid orange;
}
@media only screen and (max-width: 1650px) {
  .rightBoder {
    float: left;
    width: 130px;
    height: 2px;
    background-color: #8099bb;
  }
  .leftBoder {
    width: 130px;
    height: 2px;
    background-color: #8099bb;
    float: left;
  }
  .centerWindow {
    width: 100%;
    height: 100%;
    overflow: auto;
    .loginBgc {
      min-width: 1280px;
      min-height: 500px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: url("../../assets/img/bgc-login.png") no-repeat center;
      background-size: 100% 100%;
      .fmWelcome {
        display: block;
        // position: absolute;
        // bottom: 18%;
        margin-left: 6%;
        .sayHello {
          width: 120px;
          height: 52px;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 1);
          line-height: 52px;
        }
        .welLogin {
          width: 160px;
          height: 52px;
          line-height: 82px;
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .fmsider {
          width: 166px;
          height: 4px;
          background: rgba(255, 255, 255, 0.43);
          margin-top: 59px;
        }
        .fmTitle {
          width: 100%;
          height: 74px;
          font-size: 36px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 74px;
          margin-top: 51px;
        }
        .sonTitle {
          width: 240px;
          height: 64px;
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 64px;
          margin-top: 53px;
        }
      }
    }
    .fmLoginNav {
      display: block;
      margin-top: 10%;
      // bottom: 10%;
      // position: absolute;
      // right: 1%;
      .loginNav {
        width: 480px;
        // height: 600px;
        height: 618px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        padding: 45px 45px 45px 55px;
        .loginBox {
          width: 100%;
          height: 100%;
          .loginTitle {
            width: 100%;
            height: 42px;
            font-size: 32px;
            color: rgba(59, 66, 83, 1);
            line-height: 2px;
            margin-top: 32px;
          }
          .loginNavbar {
            margin-top: 35px;
          }
          .demo-ruleForm {
            margin-top: 30px;
            width: 370px;
          }
          .SignInBar {
            margin-top: 110px;
            .loginBar {
              font-size: 20px;
              width: 370px;
              height: 55px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  .footerBar {
    width: 370px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
  }
}
/deep/.el-dialog__header{
  padding-bottom: 0px;
  }
  /deep/.el-dialog__footer {
    padding-top: 0;
  }
</style>
