var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subject" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formbox",
              attrs: { model: _vm.ruleForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "unit_id", label: "联盟单位" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择(可搜索)",
                        clearable: "",
                      },
                      model: {
                        value: _vm.ruleForm.unit_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "unit_id", $$v)
                        },
                        expression: "ruleForm.unit_id",
                      },
                    },
                    _vm._l(_vm.unitData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.unit_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "ID", label: "空域ID" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.air_space_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "air_space_id", $$v)
                      },
                      expression: "ruleForm.air_space_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "user", label: "账户" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "mobile", $$v)
                      },
                      expression: "ruleForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "air_space_name", label: "空域名称" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.air_space_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "air_space_name", $$v)
                      },
                      expression: "ruleForm.air_space_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footBtn" }, [
                _c(
                  "div",
                  { staticClass: "fmBtn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "searchBtn",
                        attrs: { type: "primary" },
                        on: { click: _vm.searchBtn },
                      },
                      [_vm._v("查    询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "reset",
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("重    置")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center-body" },
        [
          _c(
            "el-table",
            {
              staticStyle: {
                width: "98%",
                "margin-left": "1%",
                "margin-top": "1%",
              },
              attrs: {
                height: "80%",
                stripe: true,
                "header-cell-style": { background: "#DDE6EF" },
                data: _vm.tableData,
              },
            },
            [
              _c("el-table-column", {
                staticClass: "number",
                attrs: {
                  align: "center",
                  prop: "air_space_id",
                  label: "授权空域ID",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "air_space_name",
                  label: "空域名称",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", prop: "mobile", label: "账户" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "flight_start_time",
                  label: "起始日期",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "flight_end_time",
                  label: "结束日期",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", prop: "hookTask", label: "空域状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        Date.parse(scope.row.flight_end_time) <= new Date()
                          ? _c("span", { attrs: { type: "text" } }, [
                              _vm._v("过期"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        Date.parse(scope.row.flight_end_time) > new Date()
                          ? _c("span", { attrs: { type: "text" } }, [
                              _vm._v("存续"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "taskDetails",
                  label: "批件附件",
                  width: "240",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.download(
                                  scope.row.approval_document_1
                                )
                              },
                            },
                          },
                          [_vm._v("空域批件.pdf")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.download(
                                  scope.row.approval_document_2
                                )
                              },
                            },
                          },
                          [_vm._v("任务批件.pdf")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "uploadMediaTable",
                  label: "查看",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.watch(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.current_page,
                  "page-size": this.per_page,
                  layout: "total,  prev, pager, next, jumper",
                  total: this.total,
                },
                on: {
                  "current-change": _vm.currentchange,
                  "next-click": _vm.handleNext,
                  "prev-click": _vm.handlePrev,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("loadingAirspace", {
        ref: "load",
        attrs: { airDetails: _vm.airDetails },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }