var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.downloadLength !== 0
    ? _c("div", { staticClass: "mask-layer" }, [
        _c("div"),
        _vm._v(" "),
        _c("div", { staticClass: "iconfont" }, [_vm._v("")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "当前" + _vm._s(_vm.downloadLength) + "个文件正在下载中，请稍后"
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }