var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "添加兴趣标注",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            click: _vm.turnon,
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detailBar" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "tagname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.tagname,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "tagname", $$v)
                          },
                          expression: "ruleForm.tagname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "类型1", value: "shanghai" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "类型2", value: "beijing" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "高度", prop: "tagheight" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.tagheight,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "tagheight", $$v)
                          },
                          expression: "ruleForm.tagheight",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "discribe" } },
                    [
                      _c("el-input", {
                        staticClass: "textarea",
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 6, maxRows: 10 },
                        },
                        model: {
                          value: _vm.ruleForm.discribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "discribe", $$v)
                          },
                          expression: "ruleForm.discribe",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addNewTag } },
                [_vm._v("添 加")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary", plain: "" } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }