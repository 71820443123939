var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uavBody" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "topBar" }, [
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("今日飞行架次")]),
            _vm._v(" "),
            _c("span", { staticClass: "countOne" }, [
              _vm._v(_vm._s(_vm.statisticsObj.today_fly_count)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danwei" }, [_vm._v("次")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "growthrate" }, [
            _c("span", { staticClass: "growthNum" }, [
              _vm._v(_vm._s(_vm.statisticsObj.todayFlyCountGrowthRate) + "%"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "growthWord" }, [_vm._v("相较昨日")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "imgBoxOne" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("今日飞行时长")]),
            _vm._v(" "),
            _c("span", { staticClass: "countTwo" }, [
              _vm._v(_vm._s(_vm.statisticsObj.today_fly_time)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiTwo" }, [_vm._v("小时")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "growthrate" }, [
            _c("span", { staticClass: "growthNum" }, [
              _vm._v(_vm._s(_vm.statisticsObj.todayFlyTimeGrowthRate) + "%"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "growthWord" }, [_vm._v("相较昨日")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "imgBoxTwo" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("历史飞行架次")]),
            _vm._v(" "),
            _c("span", { staticClass: "countThr" }, [
              _vm._v(_vm._s(_vm.statisticsObj.flyCountNum)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiThr" }, [_vm._v("次")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "imgBoxThr" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statIng" }, [
          _c("div", { staticClass: "countNum" }, [
            _c("p", { staticClass: "titleNum" }, [_vm._v("历史飞行时长")]),
            _vm._v(" "),
            _c("span", { staticClass: "countFour" }, [
              _vm._v(_vm._s(_vm.statisticsObj.flyTime)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "danweiFour" }, [_vm._v("小时")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "imgBoxFour" }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "midleBar", staticStyle: { position: "relative" } },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tabs",
              staticStyle: { height: "100px" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "飞行面积", name: "work_area_sum" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "飞行里程", name: "mileage_sum" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "飞行架次", name: "uas_serial_id_count" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "飞行时长", name: "work_time_sum" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tabs-radio" },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.timeRadio,
                    callback: function ($$v) {
                      _vm.timeRadio = $$v
                    },
                    expression: "timeRadio",
                  },
                },
                [_vm._v("按年")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.timeRadio,
                    callback: function ($$v) {
                      _vm.timeRadio = $$v
                    },
                    expression: "timeRadio",
                  },
                },
                [_vm._v("按月")]
              ),
              _vm._v(" "),
              _vm.timeRadio === "1"
                ? _c("el-date-picker", {
                    staticStyle: { height: "30px" },
                    attrs: {
                      type: "monthrange",
                      "range-separator": "至",
                      "start-placeholder": "开始月份",
                      "end-placeholder": "结束月份",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions,
                    },
                    on: { change: _vm.monthrangeDataChange },
                    model: {
                      value: _vm.monthlyData,
                      callback: function ($$v) {
                        _vm.monthlyData = $$v
                      },
                      expression: "monthlyData",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "droneTags",
                  style: {
                    "margin-left": _vm.timeRadio === "0" ? "10vw" : "30px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: { droneTagsBgc: _vm.droneTagsIndex === 0 },
                      on: {
                        click: function ($event) {
                          _vm.droneTagsIndex = 0
                        },
                      },
                    },
                    [_vm._v("\n          全部无人机\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: { droneTagsBgc: _vm.droneTagsIndex === 1 },
                      staticStyle: { "border-left": "1px solid #a6aab8" },
                      on: {
                        click: function ($event) {
                          _vm.droneTagsIndex = 1
                        },
                      },
                    },
                    [_vm._v("\n          彩虹无人机\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: { droneTagsBgc: _vm.droneTagsIndex === 2 },
                      staticStyle: { "border-left": "1px solid #a6aab8" },
                      on: {
                        click: function ($event) {
                          _vm.droneTagsIndex = 2
                        },
                      },
                    },
                    [_vm._v("\n          其他无人机\n        ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { id: "myEcahrts" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "botBar" }, [
        _c(
          "div",
          { staticClass: "RtopBar" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { staticClass: "statistics-bottom" }, [
              _c(
                "div",
                { staticClass: "statistics-div statistics-bottom-div" },
                [
                  _c(
                    "div",
                    { staticClass: "drone-type statistics-column-div" },
                    [
                      _c("div", [_vm._v("无人机类型")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: {
                            "selected-btn":
                              _vm.statisticsBottom === "全部无人机",
                          },
                          on: {
                            click: function ($event) {
                              _vm.statisticsBottom = "全部无人机"
                            },
                          },
                        },
                        [_vm._v("\n              全部无人机\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: {
                            "selected-btn":
                              _vm.statisticsBottom === "彩虹无人机",
                          },
                          on: {
                            click: function ($event) {
                              _vm.statisticsBottom = "彩虹无人机"
                            },
                          },
                        },
                        [_vm._v("\n              彩虹无人机\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: {
                            "selected-btn":
                              _vm.statisticsBottom === "其他无人机",
                          },
                          on: {
                            click: function ($event) {
                              _vm.statisticsBottom = "其他无人机"
                            },
                          },
                        },
                        [_vm._v("\n              其他无人机\n            ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flight-time statistics-column-div" },
                    [
                      _c("div", [_vm._v("飞行日期")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flight-time-btn",
                          class: {
                            "flight-period-btn": _vm.flightPeriod === "今天",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.flightPeriodBtn("今天")
                            },
                          },
                        },
                        [_vm._v("\n              今天\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flight-time-btn",
                          class: {
                            "flight-period-btn": _vm.flightPeriod === "本周",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.flightPeriodBtn("本周")
                            },
                          },
                        },
                        [_vm._v("\n              本周\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flight-time-btn",
                          class: {
                            "flight-period-btn": _vm.flightPeriod === "本月",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.flightPeriodBtn("本月")
                            },
                          },
                        },
                        [_vm._v("\n              本月\n            ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flight-time-select" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptionsFlight,
                        },
                        on: { change: _vm.flightTimeChange },
                        model: {
                          value: _vm.flightTime,
                          callback: function ($$v) {
                            _vm.flightTime = $$v
                          },
                          expression: "flightTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "reset" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.bottomGetFlyDataReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "statistics-div statistics-progress" }, [
                _c("div", { staticClass: "progress-column" }, [
                  _c("div", [_vm._v("飞行面积")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "progress-div" },
                    [
                      _c("el-progress", {
                        attrs: {
                          "show-text": false,
                          percentage: _vm.percentageAreaPercentage,
                          color: _vm.areaCustomColor,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.areaPercentage) +
                        "|" +
                        _vm._s(_vm.totalAreaPercentage) +
                        "平方公里\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-column" }, [
                  _c("div", [_vm._v("飞行里程")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "progress-div" },
                    [
                      _c("el-progress", {
                        attrs: {
                          "show-text": false,
                          percentage: _vm.percentageMileagePercentage,
                          color: _vm.mileageCustomColor,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.mileagePercentage) +
                        "|" +
                        _vm._s(_vm.totalMileagePercentage) +
                        "公里\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-column" }, [
                  _c("div", [_vm._v("飞行架次")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "progress-div" },
                    [
                      _c("el-progress", {
                        attrs: {
                          "show-text": false,
                          percentage: _vm.percentageSortiesPercentage,
                          color: _vm.sortiesCustomColor,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.sortiesPercentage) +
                        "|" +
                        _vm._s(_vm.totalSortiesPercentage) +
                        "架次\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progress-column" }, [
                  _c("div", [_vm._v("飞行时长")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "progress-div" },
                    [
                      _c("el-progress", {
                        attrs: {
                          "show-text": false,
                          percentage: _vm.percentageDurationPercentage,
                          color: _vm.durationCustomColor,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.durationPercentage) +
                        "|" +
                        _vm._s(_vm.totalDurationPercentage) +
                        "分\n            "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Rtitle" }, [
      _c("span", { staticClass: "titleWord" }, [_vm._v("飞行累计统计")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }