var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "新建用户",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c(
              "div",
              { staticClass: "formBox" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "formbox",
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.ruleForm,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "mobile", label: "手机号码" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.ruleForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mobile", $$v)
                            },
                            expression: "ruleForm.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "name", label: "账户名称" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.ruleForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "name", $$v)
                            },
                            expression: "ruleForm.name",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "sign" }, [
                          _vm._v(
                            "\n              账户名称请输入真实姓名，方便以后推送任务时更加容易识别。\n            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.addBtn },
                },
                [_vm._v("确 认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }