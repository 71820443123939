var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-push" },
    [
      _c("navTop", { staticClass: "navTop" }),
      _vm._v(" "),
      _c("div", { staticClass: "left-alert" }, [
        _c(
          "div",
          { staticClass: "left-alert-ov iconfont" },
          [
            _c(
              "div",
              { staticClass: "left-alert-top" },
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "project_name",
                    attrs: { title: _vm.projectName },
                    on: { change: _vm.changelog },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [_vm._v(_vm._s(_vm.projectName))]
                ),
                _vm._v(" "),
                _vm.authority
                  ? _c(
                      "div",
                      {
                        staticClass: "cursor alert-top-btn",
                        on: { click: _vm.designate },
                      },
                      [
                        _c("span", [_vm._v("")]),
                        _vm._v("\n          指派\n        "),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.authority
                  ? _c(
                      "div",
                      {
                        staticClass: "cursor alert-top-btn mass",
                        on: {
                          click: function ($event) {
                            return _vm.flockSend(true)
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v("")]),
                        _vm._v("\n          群发\n        "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.projectData.arr, function (item, index) {
              return _c(
                "div",
                { key: "0" + index },
                [
                  _c("div", { staticStyle: { margin: "19px 0 0 49px" } }, [
                    _vm._v(_vm._s(item.block_name)),
                  ]),
                  _vm._v(" "),
                  _vm._l(item.task, function (item2, index2) {
                    return _c("taskItem", {
                      key: index + "0" + index2,
                      staticClass: "taskItem",
                      attrs: {
                        checkeds: item2.checked,
                        index: index2,
                        "index-i": index,
                        "block-id": item.id,
                        "project-obj": item2,
                        block: item.block_name,
                        authority: _vm.authority,
                        nav: _vm.nav,
                      },
                      on: {
                        taskRedoTaskUpdate: _vm.taskRedoTaskUpdate,
                        sonChecked: _vm.sonChecked,
                        instructions: _vm.instructions,
                        taskListTask: _vm.taskListTask,
                        clickConversation: _vm.clickConversation,
                      },
                    })
                  }),
                ],
                2
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-amap",
        {
          ref: "map",
          staticClass: "amap-demo",
          attrs: {
            plugin: _vm.plugin,
            events: _vm.events,
            center: _vm.center,
            zoom: _vm.zoom,
            vid: "amap-vue",
            "amap-manager": _vm.amapManager,
          },
        },
        [
          _vm._l(_vm.polygons, function (polygon, index) {
            return _c("el-amap-polygon", {
              key: index,
              attrs: {
                "stroke-opacity": 0,
                "fill-opacity": 0.6,
                "fill-color": "#367cf3",
                events: _vm.polygonsEvent,
                vid: index,
                path: polygon,
              },
            })
          }),
          _vm._v(" "),
          _c("el-amap-polyline", {
            attrs: {
              "stroke-color": "#ffff00",
              editable: false,
              "stroke-weight": 2,
              path: _vm.polyline,
            },
          }),
          _vm._v(" "),
          _c("el-amap-polyline", {
            attrs: {
              "stroke-color": "#ffff00",
              editable: false,
              "stroke-weight": 1,
              "stroke-style": "dashed",
              path: _vm.polylines,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "video", on: { click: _vm.chat } }, [
            _vm.chatDialog.unread_num_arr[_vm.projectId] > 99
              ? _c("div", { staticClass: "unread" }, [
                  _vm._v("\n        ···\n      "),
                ])
              : _vm.chatDialog.unread_num_arr[_vm.projectId] > 0
              ? _c("div", { staticClass: "unread" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.chatDialog.unread_num_arr[_vm.projectId]) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "el-dialog-wi",
          attrs: {
            title: _vm.designateVisible == false ? "群发" : "指派",
            visible: _vm.centerDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
            close: _vm.abolish,
          },
        },
        [
          _c("div", { staticClass: "el-dialong-body" }, [
            _c("div", { staticClass: "el-dialong-div el-dialong-left" }, [
              _c(
                "div",
                { staticClass: "el-left-checkbox" },
                [
                  _vm._l(_vm.checkedCities, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "iconfont cursor",
                        on: {
                          click: function ($event) {
                            return _vm.clearAway(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              item.unit_name ? item.unit_name : item.name
                            ) +
                            "\n            "
                        ),
                        _c("span", [_vm._v("")]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.designateValue
                    ? _c(
                        "div",
                        {
                          staticClass: "iconfont cursor",
                          on: {
                            click: function ($event) {
                              return _vm.clearAway()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.designateValue.unit_name
                                  ? _vm.designateValue.unit_name
                                  : _vm.designateValue.name
                              ) +
                              "\n            "
                          ),
                          _c("span", [_vm._v("")]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "time-text" }, [_vm._v("设置截至时间")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "el-dialong-time" },
                [
                  _c("el-date-picker", {
                    staticClass: "time",
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.valueDate,
                      callback: function ($$v) {
                        _vm.valueDate = $$v
                      },
                      expression: "valueDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-time-select", {
                    staticClass: "time",
                    attrs: {
                      "picker-options": {
                        step: "00:15",
                        end: "23:45",
                      },
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.valueTime,
                      callback: function ($$v) {
                        _vm.valueTime = $$v
                      },
                      expression: "valueTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "cursor el-dialong-btn" }, [
                _c("div", { on: { click: _vm.selectConfirm } }, [
                  _vm._v(
                    "\n            确定(" +
                      _vm._s(
                        _vm.designateValue != "" ? 1 : _vm.checkedCities.length
                      ) +
                      ")\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cancel", on: { click: _vm.abolish } },
                  [_vm._v("取消")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.underling == false && _vm.designateVisible == false
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入内容",
                        "suffix-icon": "el-icon-search",
                        disabled: _vm.underling !== _vm.pitchOn,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.flockSend(false)
                        },
                      },
                      model: {
                        value: _vm.inputData,
                        callback: function ($$v) {
                          _vm.inputData = $$v
                        },
                        expression: "inputData",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "cheak-all",
                        attrs: { disabled: _vm.underling !== _vm.pitchOn },
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckAllChange(_vm.cities)
                          },
                        },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "el-dialong-group",
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckedCitiesChange(false)
                          },
                        },
                        model: {
                          value: _vm.checkedCities,
                          callback: function ($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities",
                        },
                      },
                      [
                        _vm.cities.length > 0
                          ? _c(
                              "div",
                              { staticClass: "dialog-one-div" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "el-dialong-checkbox",
                                    attrs: {
                                      label: _vm.cities[0],
                                      disabled: _vm.underling !== _vm.pitchOn,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.cities[0].unit_name))]
                                ),
                                _vm._v(" "),
                                _vm.cities[0].unit_code ===
                                _vm.user.user_unit.unit_code
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "el-dialong-chdiv iconfont cursor",
                                        on: {
                                          click: function ($event) {
                                            return _vm.subordinate(0)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              \n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.cities.slice(1), function (city, index) {
                          return _c(
                            "div",
                            { key: city.id, staticClass: "dialog-one-div" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "el-dialong-checkbox",
                                  attrs: {
                                    label: city,
                                    disabled: _vm.underling !== _vm.pitchOn,
                                  },
                                },
                                [_vm._v(_vm._s(city.unit_name))]
                              ),
                              _vm._v(" "),
                              _vm.role === 0 && _vm.nav === 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-dialong-chdiv iconfont cursor",
                                      on: {
                                        click: function ($event) {
                                          return _vm.subordinate(index + 1)
                                        },
                                      },
                                    },
                                    [_vm._v("\n              \n            ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling != false && _vm.designateVisible == false
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入所需搜索用户",
                        "suffix-icon": "el-icon-search",
                        disabled: _vm.underling !== _vm.pitchOn,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.subordinate(_vm.selectNum)
                        },
                      },
                      model: {
                        value: _vm.inputDatas,
                        callback: function ($$v) {
                          _vm.inputDatas = $$v
                        },
                        expression: "inputDatas",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cursor el-dialong-company",
                        on: {
                          click: function ($event) {
                            _vm.getBackLeague(), _vm.estimateAllChange()
                          },
                        },
                      },
                      [
                        _vm.role === 0
                          ? _c("span", { staticClass: "iconfont" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                        _vm._v("\n          联盟下用户\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "cheak-all",
                        attrs: { disabled: _vm.underling !== _vm.pitchOn },
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckAllChange(_vm.underlingArr)
                          },
                        },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "el-dialong-group",
                        on: {
                          change: function ($event) {
                            return _vm.handleCheckedCitiesChange(true)
                          },
                        },
                        model: {
                          value: _vm.checkedCities,
                          callback: function ($$v) {
                            _vm.checkedCities = $$v
                          },
                          expression: "checkedCities",
                        },
                      },
                      _vm._l(_vm.underlingArr, function (city, index) {
                        return _c(
                          "el-checkbox",
                          {
                            key: index,
                            staticClass: "el-dialong-checkbox",
                            attrs: {
                              disabled: _vm.underling !== _vm.pitchOn,
                              label: city,
                            },
                          },
                          [_vm._v(_vm._s(city.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling == false && _vm.designateVisible
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入内容",
                        "suffix-icon": "el-icon-search",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.flockSend(false)
                        },
                      },
                      model: {
                        value: _vm.inputData,
                        callback: function ($$v) {
                          _vm.inputData = $$v
                        },
                        expression: "inputData",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "cheak-all" }, [_vm._v("指派")]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dialog-one-div" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "el-dialong-checkbox",
                            attrs: { label: _vm.cities[0] },
                            model: {
                              value: _vm.designateValue,
                              callback: function ($$v) {
                                _vm.designateValue = $$v
                              },
                              expression: "designateValue",
                            },
                          },
                          [_vm._v(_vm._s(_vm.cities[0].unit_name))]
                        ),
                        _vm._v(" "),
                        _vm.cities[0].unit_code === _vm.user.user_unit.unit_code
                          ? _c(
                              "div",
                              {
                                staticClass: "el-dialong-chdiv iconfont cursor",
                                on: {
                                  click: function ($event) {
                                    return _vm.subordinate(0)
                                  },
                                },
                              },
                              [_vm._v("\n            \n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.cities.slice(1), function (city, index) {
                      return _c(
                        "div",
                        { key: city.id, staticClass: "dialog-one-div" },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "el-dialong-checkbox",
                              attrs: { label: city },
                              model: {
                                value: _vm.designateValue,
                                callback: function ($$v) {
                                  _vm.designateValue = $$v
                                },
                                expression: "designateValue",
                              },
                            },
                            [_vm._v(_vm._s(city.unit_name))]
                          ),
                          _vm._v(" "),
                          _vm.role === 0 && _vm.nav === 2
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "el-dialong-chdiv iconfont cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.subordinate(index + 1)
                                    },
                                  },
                                },
                                [_vm._v("\n            \n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.underling != false && _vm.designateVisible
              ? _c(
                  "div",
                  { staticClass: "el-dialong-div el-dialong-right" },
                  [
                    _c("el-input", {
                      staticClass: "el-dialong-input",
                      attrs: {
                        placeholder: "请输入所需搜索用户",
                        "suffix-icon": "el-icon-search",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.subordinate(_vm.selectNum)
                        },
                      },
                      model: {
                        value: _vm.inputDatas,
                        callback: function ($$v) {
                          _vm.inputDatas = $$v
                        },
                        expression: "inputDatas",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cursor el-dialong-company",
                        on: { click: _vm.getBackLeague },
                      },
                      [
                        _vm.role === 0
                          ? _c("span", { staticClass: "iconfont" }, [
                              _vm._v(""),
                            ])
                          : _vm._e(),
                        _vm._v("\n          联盟下用户\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }),
                    _vm._v(" "),
                    _vm._l(_vm.underlingArr, function (city, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          staticClass: "el-dialong-checkbox",
                          attrs: { label: city },
                          model: {
                            value: _vm.designateValue,
                            callback: function ($$v) {
                              _vm.designateValue = $$v
                            },
                            expression: "designateValue",
                          },
                        },
                        [_vm._v(_vm._s(city.name))]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.directiveReveal
        ? _c(
            "div",
            { staticClass: "directive" },
            _vm._l(_vm.directiveArr, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.directivePush(item)
                    },
                  },
                },
                [
                  _c("img", { attrs: { src: item.url, alt: "" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.parameter).length > 0 && _vm.parameterIndex > 0
        ? _c("div", { staticClass: "task-particulars" }, [
            _c("div", { staticClass: "particulars-top" }, [
              _c(
                "div",
                {
                  class: { bor: _vm.parameterIndex === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(1)
                    },
                  },
                },
                [_vm._v("\n        任务信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cen",
                  class: { bor: _vm.parameterIndex === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(2)
                    },
                  },
                },
                [_vm._v("\n        无人机信息\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { bor: _vm.parameterIndex === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.meaagseCut(3)
                    },
                  },
                },
                [_vm._v("\n        终端信息\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "particulars-body" }, [
              _vm.parameterIndex === 1 && _vm.nav === 1
                ? _c("div", { staticClass: "body-top" }, [
                    _c("div", { staticClass: "div name" }, [
                      _c("div", { staticClass: "task-name" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "model" }, [
                        _c("div", [
                          _vm._v("\n              设备型号:\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.content.plane_type)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "52px" } }, [
                          _vm._v("\n              载荷型号:\n              "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.parameter.content.camera_type)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "div parameter" }, [
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("参数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("最高点")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { color: "#fff", "font-size": "14px" } },
                        [_vm._v("最低点")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.average_ground_resolution
                            ) + " cm"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.high_gsd) + "cm"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            GSD\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.low_gsd) + "cm"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.longitudinal_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.high_longitudinal_overlap
                            ) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            航向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.low_longitudinal_overlap
                            ) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.lateral_overlap) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.high_lateral_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            旁向重叠\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parameter.content.low_lateral_overlap) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_average_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_highest_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            海拔高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.survey_lowest_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            飞行高度\n            "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.content.flight_relative_altitude
                            ) + "m"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("\n            比例尺\n            "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.content.work_scale)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "div message" }, [
                      _c("div", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("信息"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              航线间距:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.flight_interval) +
                                "m"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              预计时间:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.work_time) + "min"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              拍照间距:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.take_pic_interval) +
                                "m"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.parameter.content.work_area
                          ? _c("div", [
                              _vm._v(
                                "\n              预计面积:\n              "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.work_area.slice(0, 3)
                                  ) + "km²"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-div" }, [
                        _c("div", [
                          _vm._v("\n              默认空速:\n              "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.parameter.content.default_air_speed) +
                                "m/s"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              预计里程:\n              "),
                          _vm.parameter.content.total_mileage
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.parameter.content.total_mileage.slice(
                                      0,
                                      5
                                    )
                                  ) + "km"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm.parameterIndex === 1 && _vm.nav === 2
                ? _c("div", { staticClass: "div uav" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("传感器")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project.sensor_type
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("授权空域")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project.auth_area
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("任务性质")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parameter.project_content.project
                                .charge_property
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("影像分辨率")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .average_ground_resolution
                                ) + "cm"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("航向重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .longitudinal_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density === ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("旁向重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .lateral_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density !== ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("激光点密度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .point_cloud_density
                                ) + "p/m²"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.parameter.project_content.project
                      .point_cloud_density !== ""
                      ? _c("div", { staticClass: "uav-div" }, [
                          _c("div", [_vm._v("航带重叠度")]),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.parameter.project_content.project
                                    .lateral_overlap
                                ) + "%"
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm.parameterIndex === 2 && _vm.parameter.uav != null
                ? _c("div", { staticClass: "uav div" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备ID")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.software_code)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备型号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(_vm.parameter.uav.name))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("联盟单位")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.unit_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备状态")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.statusArr[_vm.parameter.uav.status])
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("归属部门")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_dept)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.holder_phone)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("创建人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.uav.creator_phone)),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm.parameterIndex === 3 && _vm.parameter.terminal != null
                ? _c("div", { staticClass: "div uav" }, [
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备ID")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.device_id)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("联盟单位")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.unit_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("设备状态")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.statusArr[_vm.parameter.terminal.status])
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("归属部门")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_dept)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_name)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("持有人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.holder_phone)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uav-div" }, [
                      _c("div", [_vm._v("使用人手机号")]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parameter.terminal.use_mobile)),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.parameter.iframe_url !== ""
              ? _c(
                  "div",
                  {
                    staticClass: "iconfont play",
                    on: {
                      click: function ($event) {
                        return _vm.play(_vm.parameter.iframe_url)
                      },
                    },
                  },
                  [_vm._v("\n      \n    ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("newFmTask", { ref: "newFmTask" }),
      _vm._v(" "),
      _vm.newTaskNum
        ? _c("newTaskPop", { on: { newFmPop: _vm.newFmPop } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "chat-add-dialog",
          attrs: {
            modal: false,
            "lock-scroll": false,
            visible: _vm.chatAddDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.chatAddDialogVisible = $event
            },
          },
        },
        [
          _c("chatAdd", {
            key: _vm.chatAddDialogKey,
            ref: "chatAdd",
            attrs: {
              type: _vm.roomActionType,
              project: _vm.chatDialog.project,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }