<template>
  <airspace :title="'查询空域'" />
</template>

<script>
import { mapState, mapMutations } from "vuex"
import airspace from "@/components/airspace"
export default {
  components: {
    airspace
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
  },
  created() {},
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "air") {
      this.currentUrlStorage(["首页", "空域查询", "空域查询"]);
    }
  },
};
</script>

<style lang="less" scoped>
</style>
