var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "舆情发布",
        visible: _vm.dialogVisible,
        width: "720px",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bodyBar" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formbox",
              attrs: { model: _vm.ruleForm },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "project_id", label: "灾害应急任务名称" },
                },
                [
                  _c("el-select", {
                    staticClass: "fmSelect",
                    attrs: {
                      filterable: "",
                      placeholder: "请选择",
                      disabled: true,
                    },
                    model: {
                      value: _vm.ruleForm.project_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "project_name", $$v)
                      },
                      expression: "ruleForm.project_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "灾害应急任务包络" },
                },
                _vm._l(_vm.projectName, function (item, index) {
                  return _c("el-button", { key: index, attrs: { plain: "" } }, [
                    _vm._v(
                      "\n          " + _vm._s(item.task_name) + "\n        "
                    ),
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "任务下达类型" },
                },
                [
                  _c("span", { staticClass: "taskType" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.sentimentData.project_type == 1
                            ? "航线任务下达"
                            : "通用任务下达"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "summary" }, [
                _c("span", { staticClass: "summarytit" }, [
                  _vm._v("灾害应急任务汇总"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "summaryBar" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "包含总任务数：" + _vm._s(_vm.project_task_count) + "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "已接单任务数：" +
                        _vm._s(_vm.project_task_confirm_count) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "待接单任务数：" +
                        _vm._s(_vm.project_task_unconfirmed_count) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "进行中任务数：" +
                        _vm._s(_vm.project_task_progress) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "已完成任务数：" +
                        _vm._s(_vm.project_task_finished) +
                        "个"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "scene" }, [
                _c("span", { staticClass: "scenetit" }, [
                  _vm._v("灾害应急任务现场"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sceneBar" }),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "是否发起现场连线" },
                },
                [
                  _c("span", { staticClass: "connection" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.sentimentData.spot_connection == 0 ? "否" : "是"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "disaster_level", label: "灾情态势" },
                },
                [
                  _c("span", { staticClass: "situation" }, [
                    _vm._v(_vm._s(_vm.sentimentData.disaster_level)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "disaster_description", label: "灾情评述" },
                },
                [
                  _c("span", { staticClass: "situation" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.sentimentData.disaster_description) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticClass: "snapshot" }, [
                  _c("span", { staticClass: "snapshotit" }, [
                    _vm._v("灾情测区快照"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "imgNav" }, [
                    _c("img", {
                      staticClass: "imgtit",
                      attrs: { src: _vm.sentimentData.area_snapshoot, alt: "" },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticClass: "henduoren" }, [
                  _c("span", { staticClass: "henduorenWord" }, [
                    _vm._v("灾情现场快照"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "imgNav" }, [
                    _vm.sentimentData.snapshoot_1 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_1,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_2 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_2,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_3 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_3,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_4 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_4,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_5 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_5,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_6 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_6,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_7 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_7,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_8 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_8,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sentimentData.snapshoot_9 != ""
                      ? _c("img", {
                          staticClass: "imgtit",
                          attrs: {
                            src: _vm.sentimentData.snapshoot_9,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { staticClass: "divd" }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }