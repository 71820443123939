<template>
  <div>
    <TopBar />
    <div class="mapBar">
      <!-- -->
      <div class="fa-eachar">
        <div class="son-eachar">
          <!-- eachar -->
          <!-- <div class="taskStatistics">
            <div class="titlebar">
              <i class="iconfont task">&#xe656;</i>
              <span class="titword">月推送任务统计</span>
            </div>
            <el-divider />
            <div id="eachartone" />
          </div> -->
          <!-- <div class="warning">
            <div class="titlebar">
              <i class="iconfont task">&#xe655;</i>
              <span class="titword">预警发布</span>
            </div>
            <el-divider />
            <div v-if="token != ''" class="bodyBar">
              <div
                v-for="(item, index) in warningList"
                :key="index"
                class="taskLine"
                @click="jumpWarningDetail(item.id)"
              >
                <span class="taskName">{{ item.area_name }}</span>
                <span class="taskTime">{{ item.created_at }}</span>
              </div>
            </div>
          </div> -->
          <!-- <div class="flightSorties">
            <div class="titlebar">
              <i class="iconfont task">&#xe654;</i>
              <span class="titword">月飞行架次统计</span>
              <div class="titlesign">
                <div class="unitLine" />
                <span class="unitname">飞马机器人</span>
                <div class="userLine" />
                <span class="unitname">大疆创新</span>
                <div class="otherLine" />
                <span class="unitname">其他品牌</span>
              </div>
            </div>
            <el-divider />
            <div id="eachartwo" />
          </div> -->
        </div>
      </div>
      <Monitor ref="monitor" />
      <div id="middleBar" ref="middleBar">
        <!-- 回到顶部 -->
        <div
          v-show="showScroll"
          class="el-backtop"
          style="right: 40px; bottom: 40px"
          title="回到顶部"
          @click="returnTop"
        >
          <i class="el-icon-caret-top" />
        </div>
        <!-- 顶部 -->
        <div class="topTask">
          <div class="taskinfor">
            <div class="topTask-name">应急任务</div>
            <div class="topTask-text">应急任务协同处理查看</div>
            <div class="taskinfor-div">
              <div class="flyNum">
                <div class="leftBar">
                  <img
                    class="iconImg"
                    src="../../../assets/img/homeImg/right.png"
                    alt
                  />
                  <span class="countleft">
                    {{ homeDataList.report_home_today_pushed }}
                  </span>
                  <span class="danwei">个</span>
                  <p class="describe">今日已推送任务</p>
                </div>
                <div class="rightBar">
                  <img
                    class="iconImg"
                    src="../../../assets/img/homeImg/left.png"
                    alt
                  />
                  <span class="countleft">
                    {{ homeDataList.report_home_today_not_pushed }}
                  </span>
                  <span class="danwei">个</span>
                  <p class="describe">今日未推送任务</p>
                </div>
              </div>
              <div class="pushCount">
                <div class="topPush">
                  <span class="pushtask">历史已推送任务</span>
                  <div class="progressBar">
                    <el-progress
                      class="progress"
                      :percentage="historypush"
                      :color="'#3FF1FF'"
                    />
                  </div>
                </div>
                <div class="botPush">
                  <span class="pushtask">历史未推送任务</span>
                  <div class="progressBar">
                    <el-progress
                      class="progress"
                      :percentage="historyNotpush"
                      :color="'#E06551'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="taskmeun">
            <div
              v-for="(item, index) in HomeProjectArr"
              :class="{ taskmeunListClick: HomeProjectArrIndex === index }"
              class="taskmeun-list"
              @click="HomeProjectArrIndex = index"
            >
              <div style="font-size: 13px; color: #3c3d41">
                {{ item.project_name }}
              </div>
              <div style="margin-top: 12px; font-size: 12px; color: #a5a5a5">
                创建人:{{ item.name }}
              </div>
              <div style="margin-top: 4px; font-size: 12px; color: #a5a5a5">
                {{ item.created_at }}
              </div>
            </div>
            <div class="taskmeun-btn" @click="seeMoreTasks">查看更多任务</div>
          </div>
        </div>
        <!-- 中间 -->
        <div class="airsearch">
          <div @click="toAirspace" class="text">
            <div>空域查询</div>
            <div style="font-size: 16px; margin-top: 12px">
              阳光飞行便捷服务站
            </div>
          </div>
          <div class="airsearch-div iconfont">
            <div class="airsearch-div-list">
              <div class="circle">
                <span />
                空域申请查询
              </div>
              <div class="circle-list">
                任务批件
                <span style="color: #f5474a">&#xe6a0;</span>
              </div>
              <div class="circle-list">
                空域批件
                <span style="color: #f5474a">&#xe6a0;</span>
              </div>
              <div class="circle-list">
                长期计划
                <span style="color: #f5474a">&#xe6a0;</span>
              </div>
            </div>
            <div style="transform: translateX(50%)" class="airsearch-div-list">
              <div class="circle">
                <span style="background-color: #20bbef" />
                空域有效期查询
              </div>
              <div class="circle-list">
                空域加载
                <span style="color: #2bbff0">&#xe6a0;</span>
              </div>
              <div class="circle-list">
                填写信息
                <span style="color: #2bbff0">&#xe6a0;</span>
              </div>
              <div class="circle-list">
                确认空域
                <span style="color: #2bbff0">&#xe6a0;</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="homeBot">
          <div class="introduce">
            <div class="introduce-top">数据成果申请</div>
            <div class="introduce-text">高清呈现我们的家园</div>
            <div @click="application" class="introduce-btn">去申请数据</div>
          </div>
          <div
            v-if="HomeApplyDataArr.length > 0"
            style="top: -39px"
            class="homeBot-list"
          >
            <div class="homeBot-list-top">
              <div style="text-align: left; padding-left: 30px">
                数据申请单号: {{ HomeApplyDataArr[0].data_apply_no }}
              </div>
              <div
                style="text-align: right; padding-right: 34px; color: #98a6cd"
              >
                {{ HomeApplyDataArr[0].created_at }}
              </div>
            </div>
            <div class="homeBot-list-body">
              <div style="margin-top: 29px" class="homeBot-list-li">
                <div>数据申请类型</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[0].category }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>数据完整性</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[0].integrality === 0 ? "部分" : "全部" }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>可接受的反馈等待期</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[0].wait_time }}天
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="HomeApplyDataArr.length > 1"
            style="top: 207px"
            class="homeBot-list"
          >
            <div class="homeBot-list-top">
              <div style="text-align: left; padding-left: 30px">
                数据申请单号: {{ HomeApplyDataArr[1].data_apply_no }}
              </div>
              <div
                style="text-align: right; padding-right: 34px; color: #98a6cd"
              >
                {{ HomeApplyDataArr[1].created_at }}
              </div>
            </div>
            <div class="homeBot-list-body">
              <div style="margin-top: 29px" class="homeBot-list-li">
                <div>数据申请类型</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[1].category }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>数据完整性</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[1].integrality === 0 ? "部分" : "全部" }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>可接受的反馈等待期</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[1].wait_time }}天
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="HomeApplyDataArr.length > 2"
            style="top: 452px"
            class="homeBot-list"
          >
            <div class="homeBot-list-top">
              <div style="text-align: left; padding-left: 30px">
                数据申请单号: {{ HomeApplyDataArr[2].data_apply_no }}
              </div>
              <div
                style="text-align: right; padding-right: 34px; color: #98a6cd"
              >
                {{ HomeApplyDataArr[2].created_at }}
              </div>
            </div>
            <div class="homeBot-list-body">
              <div style="margin-top: 29px" class="homeBot-list-li">
                <div>数据申请类型</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[2].category }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>数据完整性</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[2].integrality === 0 ? "部分" : "全部" }}
                </div>
              </div>
              <div class="homeBot-list-li">
                <div>可接受的反馈等待期</div>
                <div style="color: #000000">
                  {{ HomeApplyDataArr[2].wait_time }}天
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homesign">
          <div class="navBare">
            <div class="lefttop">
              <img
                class="portalImg"
                src="../../../assets/img/gatewayImg/banner-logo.png"
                alt
              />
              <span class="portalWord">【公众门户网站】</span>
              <p class="portalline">智慧田长管理平台</p>
            </div>
            <div class="midtop"></div>
            <div class="righttop">
              <div class="titstop">相关链接</div>
              <div class="srcBar">
                <div @click="skip(1)" class="line">
                  <span class="srcline">&nbsp;&nbsp;无人机管家</span>
                  <span class="sing"
                    >-无人机数据获取/处理/显示管理/无人机维护的智能GIS系统。</span
                  >
                </div>
                <div @click="skip(2)" class="line">
                  <span class="srcline">&nbsp;&nbsp;无人机管家（iPad）</span>
                  <span class="sing">{{ urlObj.IpadUrl }}</span>
                </div>
                <div @click="skip(3)" class="line">
                  <span class="srcline">&nbsp;&nbsp;DataManager上传工具</span>
                  <span class="sing">链接</span>
                </div>
                <div @click="skip(4)" class="line">
                  <!-- <span class="srcline">【分布式队伍终端】手机APP</span>
                  <span class="sing">链接</span>
                  <vue-qr :logo-src="logoSrc" class="qr" v-show="urlObj.appUrl !== ''"
                    :size="191"
                    :margin="0"
                    :auto-color="true"
                    :dot-scale="1"
                    :text="urlObj.appUrl" /> -->
                  <el-popover
                    placement="right"
                    trigger="hover"
                    width="120px"
                    id="popover">
                    <vue-qr class="qr"
                      :size="120"
                      :margin="0"
                      :auto-color="true"
                      :dot-scale="1"
                      :text="htmlUrl" />
                    <span slot="reference" @click="toDownload('app')" style="margin-bottom: 20px" class="srcline">【飞享终端】手机APP <span class="sing">链接</span></span>

                  </el-popover>
                </div>
              </div>
              <div class="titstop">相关网站</div>
              <div class="srcBar">
                <div @click="skip(5)" class="line">
                  <span class="srcline">【调度中心端】网站</span>
                  <span class="sing">{{ urlObj.webgxUrl }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="botBar">
        <p class="slide">了解应急任务、成果查看、数据申请等</p>
        <img class="arrow" :src="ScrollImge" alt @click="scrollView" />
      </div>

      <Login ref="login" @loginSuccess="initHomeData" />
      <Forget ref="forget" />
    </div>
  </div>
</template>

<script>
import defaultConstants from "@/utils/default-constants";
import { mapState, mapMutations } from "vuex";
import TopBar from "@/layout/components/portal/topBar";
import Login from "@/components/loginDialog";
import Forget from "@/components/forgetDialog";
// import Monitor from "@/components/monitor";
import Monitor from "@/components/backEndHome/index.vue"

export default {
  inject: ["reload"],
  components: { TopBar, Login, Forget, Monitor },
  data() {
    const self = this;
    return {
      homeDataList: [],
      warningList: [],
      historypush: 0,
      historyNotpush: 0,
      HomeProjectArrIndex: 0,
      HomeProjectArr: [], //  首页最新项目arr
      HomeApplyDataArr: [], //  首页数据申请Arr
      ScrollImge: require("../../../assets/img/gatewayImg/scroll-bottom.gif"),
      showScroll: false,
      switchScroll: true,
      logoSrc:"",
      appSrc:"http://www.baidu.com",
      urlObj: {
        // stewardUrl:
        //   "http://www.feimarobotics.com/zhcn/productSupportDetail?name=uavmanager#anchor-0", //  app的下载链接
        // IpadUrl: "http://www.feimarobotics.com/zhcn/uavmanagerInstall", //  app的下载链接
        // DataManagerUrl: "https://webgx.afuav.com/result/index.html", //  app的下载链接
        // appUrl: "", //  app的下载链接
        // webgxUrl: "https://webgx.afuav.com/#/login", //  app的下载链接
      },
      htmlUrl: ""
    };
  },
  methods: {
    ...mapMutations([
      "userStorage",
      "tokenStorage",
      "loginStorage",
      "registerStorage",
      "forgetStorage",
      "currentUrlStorage",
      "navMessageStorage",
    ]),
    /**
     * 底部跳转相关链接
     */
    skip(key) {
      switch (key) {
        case 1:
          window.open(this.urlObj.stewardUrl);
          break;

        case 2:
          window.open(this.urlObj.IpadUrl);
          break;

        case 3:
          this.urlObj.DataManagerUrl === ""
            ? this.upgradeApp("data", true)
            : window.open(this.urlObj.DataManagerUrl);
          break;

        case 4:
          this.urlObj.appUrl === ""
            ? this.upgradeApp("app", true)
            : window.open(this.urlObj.appUrl);
          break;

        case 5:
          window.open(this.urlObj.webgxUrl);
          break;

        default:
          break;
      }
    },
    /**
     * 获取app的下载链接
     */
    upgradeApp(category, open = false) {
      this.$apis
        .upgrade(
          {
            category: category,
            version_int: "0.0.1",
          },
          this.token
        )
        .then((data) => {
          category === "app"
            ? (this.urlObj.appUrl = data.data.data.file_url)
            : (this.urlObj.DataManagerUrl = data.data.data.file_url);
          if (open) {
            window.open(data.data.data.file_url);
          }
          let ishttps = document.location.protocol
          this.htmlUrl = ishttps+"//"+window.location.host + "/downApp.html"
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 去申请数据
    application() {
      this.$router.push({
        path: "/application",
      });
    },
    // 跳转空域
    toAirspace() {
      this.$router.push({
        path: "/airsearch?nav=air",
      });
      this.navMessageStorage(3);
      this.currentUrlStorage(["首页", "空域查询", "空域查询"]);
    },

    // 查看更多任务
    seeMoreTasks() {
      this.navMessageStorage(0);
      this.currentUrlStorage(["首页", "应急任务", "航线任务"]);
      this.$router.push({
        path: "/getawayTask",
      });
    },

    // 向下滚动展开
    scrollBtn() {
      let el = this.$refs.middleBar;
      window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
      this.upgradeApp("app");
      this.upgradeApp("data");
    },

    // 滚动视图切换
    scrollView() {
      if (this.switchScroll) {
        this.scrollBtn();
        this.ScrollImge = require("@/assets/img/gatewayImg/scroll-top.gif");
      } else {
        this.returnTop();
        this.ScrollImge = require("@/assets/img/gatewayImg/scroll-bottom.gif");
      }
      this.switchScroll = !this.switchScroll;
    },
    // 滚动到顶部
    returnTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    // 获取首页数据
    getHomeData() {
      // this.monthReportPushCount();
      // this.monthReportFlight();
      this.monthReportWarning();
      this.getHomeDataForPortal();
    },
    // 应急任务推送数据
    getHomeDataForPortal() {
      this.$apis
        .homeData({}, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.homeDataList = data.data.data;
            this.historypush = parseInt((
              data.data.data.report_home_history_pushed * 100
            ).toFixed(0));
            this.historyNotpush = parseInt((
              data.data.data.report_home_history_not_pushed * 100
            ).toFixed(0));
            this.taskList = data.data.data.report_home_four_task;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 月推送统计
    monthReportPushCount() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.querySelector("#eachartone"));
      myChart.setOption({
        backgroundColor: "",
        xAxis: [
          {
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisLabel: {
              interval: 0,
              show: true,
            },
          },
          {
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisLabel: {
              interval: 0,
              show: true,
              rotate: 0,
              textStyle: {
                color: "#ffffff",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 11,
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#10899A",
              },
            },
            axisTick: {
              show: false,
            },
            gridIndex: 1,
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: ["推送"],
        },
        yAxis: [
          {
            axisLabel: {
              interval: 0,
              show: true,
              rotate: 0,
              textStyle: {
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 11,
              },
            },
            splitLine: {
              // 网格线
              lineStyle: {
                type: "dotted", // 设置网格线类型 dotted：虚线   solid:实线
                color: ["#3C4353"],
                width: 1,
              },
              show: true, // 隐藏或显示
            },
            axisLine: {
              lineStyle: {
                color: "#69789E",
              },
            },
          },
          {
            type: "value",
            show: true,
          },
          {
            gridIndex: 1,
            show: false,
          },
        ],
        grid: [
          {
            width: "85%",
            left: "10%",
            top: "8%",
          },
          {
            left: "13.3%",
            width: "80%",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 5,
            data: [],
            itemStyle: {
              barBorderRadius: 10,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#0086FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#05C8D7", // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      });
      this.$apis
        .portalTaskPushReport({}, this.token)
        .then((res) => {
          let taskData = JSON.parse(
            res.data.data.report_portal_twelve_month.task_data
          );
          let monthArr = JSON.parse(
            res.data.data.report_portal_twelve_month.month_data
          );

          let dataArr = [];
          let nowMonth = new Date().getMonth() + 1;
          //只匹配今年的月份
          monthArr.forEach((item, index) => {
            let month = item.substr(5, 2);
            if (parseInt(month) <= nowMonth) {
              dataArr.push(taskData[index]);
            }
          });
          // 填充0
          for (let i = 0; i < 12; i++) {
            if (!dataArr[i]) {
              dataArr[i] = 0;
            }
          }
          myChart.setOption({
            series: [
              {
                type: "bar",
                barWidth: 5,
                data: dataArr,
                itemStyle: {
                  barBorderRadius: 10,
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#0086FF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#05C8D7", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 月飞行架次
    monthReportFlight() {
      const unitechart = this.$echarts.init(
        document.querySelector("#eachartwo")
      );
      let feiMaFlyData = [];
      let daJiangFlyData = [];
      let otherFlyData = [];
      let max = 6;
      const option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "17%",
          height: "75%",
          containLabel: true,
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false, // 坐标轴两边留白
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          axisLabel: {
            // 坐标轴刻度标签的相关设置。
            interval: 0, // 设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: "#ffffff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 11,
            },
            formatter: function (params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 8; // 一行显示几个字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            // rotate:50,
          },
          axisTick: {
            // 坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            // 坐标轴轴线相关设置
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线。
            show: true,
            lineStyle: {
              type: "dotted：虚线",
              color: ["#3C4353"],
              // 	opacity:0.1
            },
          },
        },
        yAxis: [
          {
            type: "value",
            min: 0,
            max: max,
            splitNumber: 6,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 11,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dotted：虚线",
                color: ["#3C4353"],
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "飞马机器人",
            type: "line",
            itemStyle: {
              normal: {
                color: "#3A84FF",
                lineStyle: {
                  color: "#3A84FF",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(58,132,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(58,132,255,0.35)",
                    },
                  ]),
                },
              },
            },
            data: feiMaFlyData,
          },
          {
            name: "大疆创新",
            type: "line",
            itemStyle: {
              normal: {
                color: "rgba(255,80,124,1)",
                lineStyle: {
                  color: "rgba(255,80,124,1)",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,80,124,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(255,80,124,0.35)",
                    },
                  ]),
                },
              },
            },
            data: daJiangFlyData,
          },
          {
            name: "其他品牌",
            type: "line",
            itemStyle: {
              normal: {
                color: "#1dd0bd",
                lineStyle: {
                  color: "#1dd0bd",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(29,208,189,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(29,208,189,0.35)",
                    },
                  ]),
                },
              },
            },
            data: otherFlyData,
          },
        ],
      };
      this.$apis.portalFlyData({}, this.token).then((res) => {
        if (res.data.status == 0) {
          let feimaData = JSON.parse(
            res.data.data.report_portal_fly_data.feima_data
          );
          let daJiangData = JSON.parse(
            res.data.data.report_portal_fly_data.dajiang_data
          );
          let monthArr = JSON.parse(
            res.data.data.report_portal_fly_data.month_data
          );

          let nowMonth = new Date().getMonth() + 1;
          //只匹配今年的月份
          monthArr.forEach((item, index) => {
            let month = item.substr(5, 2);
            if (parseInt(month) <= nowMonth) {
              feiMaFlyData.push(feimaData[index]);
              daJiangFlyData.push(daJiangData[index]);
            }
          });
          // 填充0
          for (let i = 0; i < 12; i++) {
            if (!feiMaFlyData[i]) {
              feiMaFlyData[i] = 0;
            }
            if (!daJiangFlyData[i]) {
              daJiangFlyData[i] = 0;
            }
            //取最大值
            max = feiMaFlyData[i] > max ? feiMaFlyData[i] : max;
            max = daJiangFlyData[i] > max ? daJiangFlyData[i] : max;
          }
          option.yAxis[0].max = max;
          unitechart.setOption(option);
        }
      });
    },
    // 获取预警数据
    monthReportWarning() {
      this.warningList = this.$apis
        .portalWarningReport({}, this.token)
        .then((res) => {
          if (res.data.status == 0) {
            this.warningList = res.data.data.report_portal_warning.warning_data;
          }
        });
    },

    // 门户首页最新项目
    portalGetHomeProject() {
      this.$apis
        .portalGetHomeProject({}, this.token)
        .then((data) => {
          this.HomeProjectArr = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 门户首页数据申请
    portalGetHomeApplyData() {
      this.$apis
        .portalGetHomeApplyData({}, this.token)
        .then((data) => {
          var tempData = data.data.data;
          //处理结果格式
          tempData.forEach((element) => {
            let categoryStr = "";
            let jsonArray = JSON.parse(element.category);
            jsonArray.forEach((item) => {
              categoryStr += " " + item;
            });
            element.category = categoryStr;
          });
          this.HomeApplyDataArr = tempData;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 消息中心未读消息
    getMsgCenterUneadNum() {
      this.$apis.noticeUnreadNum({}, this.token).then((res) => {
        let data = res.data.data;
        this.msgCenter.unread_num.data_apply = data.data_apply;
        this.msgCenter.unread_num.warning = data.warning;
        this.msgCenter.unread_num.disaster = data.disaster;
      });
    },
    // 跳转预警发布
    jumpWarningDetail(id) {
      this.$router.push({ path: "/details", query: { id: id } });
    },
    // 初始化页面数据
    initHomeData() {
      this.portalGetHomeProject();
      this.portalGetHomeApplyData();

      this.getHomeData();

      //未读数
      this.getMsgCenterUneadNum();
    },
  },
  created() {
    this.currentUrlStorage(["首页", "首页", "首页"]);
    this.urlObj = {
      stewardUrl: process.env.VUE_APP_URL_STEWARDURL,
      IpadUrl: process.env.VUE_APP_URL_IPADURL,
      DataManagerUrl: "",
      webgxUrl: process.env.VUE_APP_URL_WEBGXURL,
      appUrl: "",
    };
  },
  mounted() {
    // 先获取APP下载链接以便生成二维码
    this.upgradeApp('app',false)
    if (!this.token) {
      this.loginStorage(true);
    } else {
      this.loginStorage(false);
      this.initHomeData();
      // this.$refs.monitor.init();
    }

    this.registerStorage(false);
    this.forgetStorage(false);

    // 实现自适应部分
    this.resizefun = () => {
      this.$echarts.init(document.getElementById("eachartone")).resize();
      this.$echarts.init(document.getElementById("eachartwo")).resize();
    };
    window.addEventListener("resize", this.resizefun);

    //监听滚动条高度对回滚按钮的显隐
    window.addEventListener("scroll", (e) => {
      let top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      if (this.$refs.middleBar && top+100 >= this.$refs.middleBar.offsetTop) {
        this.showScroll = true;
        this.ScrollImge = require("@/assets/img/gatewayImg/scroll-top.gif");
      } else {
        this.showScroll = false;
        this.ScrollImge = require("@/assets/img/gatewayImg/scroll-bottom.gif");
      }
      this.switchScroll = !this.showScroll;
    });
  },
  computed: {
    ...mapState(["user", "token", "loginVisible", "msgCenter"]),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
};
</script>

<style lang="scss" scoped>
@import url("../../../assets/iconfont/iconfont.css");
.homesign {
  margin-top: 36px;
  height: 248px;
  float: left;
  width: 100%;
  background: url("../../../assets/img/gatewayImg/首页-底部.png") center no-repeat;
}
.navBare {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lefttop {
    float: left;
    .portalImg {
      margin-right: 5px;
    }
    .portalWord {
      font-size: 16px;
      color: #0086ff;
    }
    .portalline {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .midtop {
    height: 140px;
    width: 1px;
    background-color: #828a9e;
    float: left;
    margin-left: 50px;
  }
  .righttop {
    width: 500px;
    float: left;
    margin-left: 50px;
    .titstop {
      color: #fff;
      float: left;
    }
    .srcBar {
      float: left;
      .line {
        width: 600px;
        height: 30px;
        cursor: pointer;
        .srcline {
          font-size: 12px;
          color: #0086ff;
        }
        .sing {
          font-size: 12px;
          color: #6a778d;
        }
      }
      // .showQr {
      //   position: relative;
      //   .qr {
      //     position: absolute;
      //     margin: 20px;
      //     z-index: 99;
      //     top: -40px;
      //     // left: 8px;
      //     width: 75px;
      //     height: 75px;
      //     transform: scale(0);
      //     transform-origin: center left;
      //     opacity: 0;
      //     border: 1px solid #fff;
      //     border-radius: 2.5px;
      //     -webkit-transition: all 4s ease-in-out; //兼容谷歌浏览器的css3动画
      //     -o-transition: all .4s ease-in-out;
      //     transition: all .4s ease-in-out;
      //   }
      // }
      // .showQr:hover .qr {
      //     transform: scale(1);
      //     opacity: 1;
      //   }
    }
  }
}
.el-backtop {
  position: fixed;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
}
/deep/.el-divider--horizontal {
  margin: 35px 0 0 0;
}
#eachartone {
  width: 100%;
  height: 100%;
}
#eachartwo {
  width: 100%;
  height: 100%;
}
.mapBar {
  height: calc(100vh - 50px);
  float: left;
  width: 100%;
  // position: relative;
  overflow-y: visible;
  .fa-eachar {
    position: absolute;
    bottom:-25px;
    width: 100%;
    height: 95%;
    overflow-x: auto;
  }
  .son-eachar {
    width: 100%;
    height: 97%;
    min-width: 1300px;
    position: absolute;
  }
}

.taskStatistics {
  height: 230px;
  width: 30%;
  background-color: #050718;
  box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 60px;
  left: 1.5%;
  opacity: 0.95;
  z-index: 999;
}
.warning {
  height: 230px;
  width: 20%;
  background-color: #050718;
  box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 60px;
  left: 32.5%;
  opacity: 0.95;
  z-index: 999;
}
.flightSorties {
  height: 230px;
  width: 45%;
  background-color: #050718;
  box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 60px;
  right: 1.5%;
  opacity: 0.95;
  z-index: 999;
}
.botBar {
  position: absolute;
  bottom: 5px;
  left: 40%;
  width: 20%;
  text-align: center;
  .slide {
    font-size: 13px;
    color: #0090ff;
    margin: 0px 0px 3px 0px;
  }
  .arrow {
    cursor: pointer;
  }
}
.titlebar {
  float: left;
  height: 35px;
  line-height: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.task {
  color: #0086ff;
  margin-left: 18px;
}
.titword {
  font-size: 14px;
  color: #ffffff;
  margin-left: 15px;
}
.bodyBar {
  margin-top: 10px;
  .taskLine {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
    z-index: 1000;
    .taskName {
      font-size: 13px;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .taskTime {
      color: #ffffff;
      font-size: 12px;
    }
  }
  .taskLine:hover {
    height: 40px;
    background: rgba(169, 169, 169, 1);
    opacity: 0.6;
    .taskName {
      color: #0086ff;
    }
    .taskTime {
      color: #0086ff;
    }
  }
}
.titlesign {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.unitLine {
  width: 30px;
  height: 2px;
  background-color: #ff5c5e;
  margin-left: 20px;
}
.userLine {
  width: 30px;
  height: 2px;
  background-color: #0255d8;
  margin-left: 20px;
}
.otherLine {
  width: 30px;
  height: 2px;
  background-color: #1dd0bd;
  margin-left: 20px;
}
.unitname {
  font-size: 14px;
  color: #fff;
  margin-left: 5px;
}
#middleBar {
  width: 100%;
  height: 100%;
  float: left;
  background-color: #eef3fd;
  overflow: auto;
}
.topTask {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.taskinfor {
  width: 852px;
  height: 600px;
  float: left;
  box-sizing: border-box;
  padding-left: 155px;
  background: url("../../../assets/img/gatewayImg/home-top.png") center no-repeat;
  .topTask-name {
    font-size: 28px;
    margin-top: 91px;
    color: #fff;
  }

  .topTask-text {
    margin-top: 12px;
    font-size: 16px;
    color: #fff;
    opacity: 0.6;
  }

  .taskinfor-div {
    width: 400px;
    margin-top: 86px;
  }
}

.flyNum {
  margin-top: 10px;
  .leftBar {
    margin-left: 20px;
    margin-top: 8px;
    width: 120px;
    float: left;
    .countleft {
      color: #00dfff;
      font-size: 18px;
      margin-left: 3px;
    }
    .danwei {
      font-size: 12px;
      color: #00dfff;
    }
    .describe {
      font-size: 14px;
      color: #fff;
    }
  }
  .rightBar {
    float: right;
    width: 120px;
    margin-top: 10px;
    margin-right: 15px;
    .countleft {
      color: #fb4439;
      font-size: 18px;
      margin-left: 3px;
    }
    .danwei {
      font-size: 12px;
      color: #fb4439;
    }
    .describe {
      font-size: 14px;
      color: #fff;
    }
  }
}

.pushCount {
  float: left;
  width: 100%;
  .topPush {
    margin-left: 6%;
    margin-top: 10px;
    color: #69789e;
    font-size: 13px;
    .pushtask {
      float: left;
    }
    .progressBar {
      width: 150px;
      margin-left: 65%;
    }
  }
  .botPush {
    margin-left: 6%;
    color: #69789e;
    font-size: 13px;
    .pushtask {
      float: left;
    }
    .progressBar {
      width: 150px;
      margin-left: 65%;
      margin-top: 30px;
      margin-right: 78px;
    }
  }
}

.taskmeun {
  width: 326px;
  height: 600px;
  margin-left: 21px;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 42px;

  .taskmeun-list {
    width: 100%;
    height: 110px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 25px;
  }

  .taskmeunListClick {
    background-color: #e2e7f1;
  }

  .taskmeun-btn {
    font-size: 14px;
    color: #fff;
    width: 197px;
    height: 46px;
    line-height: 46px;
    background: rgba(0, 134, 255, 1);
    border-radius: 28px;
    box-sizing: border-box;
    padding-left: 42px;
    margin: 22px auto 0;
  }
}
.airsearch {
  margin-top: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 507px;
  background: url("../../../assets/img/gatewayImg/home-mid.png") center no-repeat;
  position: relative;

  .text {
    margin: 119px auto;
    text-align: center;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
  }

  .airsearch-div {
    position: absolute;
    width: 62.5vw;
    height: 27.3vh;
    background: rgba(238, 243, 253, 1);
    box-shadow: 0px 10px 30px 0px rgba(0, 85, 145, 0.25);
    border-radius: 8px;
    top: 265px;
    left: 50%;
    transform: translateX(-50%);

    .airsearch-div-list {
      position: absolute;
      top: 72px;
      left: 50%;
      transform: translateX(-100%);
    }

    .circle {
      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: rgba(245, 71, 74, 1);
        border-radius: 50%;
      }
    }

    .circle-list {
      margin-top: 22px;
      margin-left: 19px;
      font-size: 14px;
      color: rgba(96, 109, 120, 1);
      line-height: 19px;
    }
  }
}
.homeBot {
  margin-top: 162px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 748px;
  background: url("../../../assets/img/gatewayImg/home-bot.png") center no-repeat;
  position: relative;

  .introduce {
    position: absolute;
    top: 92px;
    left: 22.18%;
    div {
      text-align: center;
    }
    .introduce-top {
      color: #0e1929;
      font-size: 28px;
    }
    .introduce-text {
      color: #0e1929;
      font-size: 16px;
      margin-top: 12px;
    }
    .introduce-btn {
      width: 197px;
      height: 46px;
      line-height: 46px;
      background: rgba(0, 134, 255, 1);
      border-radius: 28px;
      color: #fff;
      margin-top: 28px;
      cursor: pointer;
    }
  }

  .homeBot-list {
    position: absolute;
    width: 34.3vw;
    height: 22vh;
    box-shadow: 0px 0px 60px 10px rgba(13, 37, 62, 0.1);
    border-radius: 4px;
    border: 1px solid rgba(211, 212, 213, 1);
    background-color: #fff;
    right: 109px;

    .homeBot-list-top {
      height: 55px;
      border-bottom: 1px solid #d8d8d8;
      line-height: 55px;
      display: flex;
      div {
        width: 50%;
        box-sizing: border-box;
        display: inline-block;
        font-size: 13px;
      }
    }

    .homeBot-list-body {
      font-size: 12px;
      .homeBot-list-li {
        margin-top: 19px;
        margin-left: 30px;
        display: flex;
        div:nth-child(1) {
          width: 8.5vw;
          text-align: left;
          color: #8b8b8b;
        }
      }
    }
  }
}
</style>
