<template>
  <div>
    <div class="topSearch">
      <el-form
        ref="selectRuleForm"
        class="formbox"
        :model="selectRuleForm"
        label-width="80px"
      >
        <el-form-item class="userForm" prop="plane_type">
          <el-select
            v-model="selectRuleForm.plane_type"
            @change="getPlane(selectRuleForm.plane_type)"
            filterable
            placeholder="飞机型号"
            clearable
          >
            <el-option
              v-for="item in planeTypeData"
              :key="item.value"
              :label="item.plane_type"
              :value="item.plane_type"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="province">
          <el-cascader
            filterable
            clearable
            placeholder="选择区域(可搜索)"
            v-model="selectedOptions"
            size="large"
            :options="options"
            @change="changeProblemType"
            ref="myCascader"
          />
        </el-form-item>
        <el-form-item class="userForm">
          <el-select
            v-model="selectRuleForm.camera_type"
            @change="getCamera(selectRuleForm.camera_type)"
            filterable
            placeholder="载荷类型"
            clearable
          >
            <el-option
              v-for="item in cameraTypeData"
              :key="item.value"
              :label="item.camera_type"
              :value="item.camera_type"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="userForm countBar"
          label="GSD范围："
          label-width="85px"
        >
          <el-input-number
            v-model="selectRuleForm.high_gsd_min"
            controls-position="right"
            @change="handlesmall"
          />&nbsp;-
          <el-input-number
            v-model="selectRuleForm.high_gsd_max"
            controls-position="right"
            @change="handlelarge"
          />
        </el-form-item>
        <el-form-item
          class="userForm timer"
          label="时间区间："
          label-width="85px"
        >
          <el-date-picker
            v-model="barchart"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="getBarchart"
          />
        </el-form-item>
        <el-button class="topBtn" @click="searchBtn" type="primary"
          >查询</el-button
        >
      </el-form>
    </div>
    <div class="weatherMap">
      <el-amap
        :plugin="plugin"
        :events="events"
        :zoom="zoom"
        :center="center"
        class="amap-box"
        :vid="'amap-vue'"
      >
        <!-- 图标 -->
        <el-amap-marker
          v-if="markers.length > 0"
          v-for="(item, index) in markers"
          :key="index + '121212121'"
          :position="item.position"
          :visible="item.visible"
          :draggable="item.draggable"
          :events="item.events"
          :vid="index"
          icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADuUlEQVRYR8WXa4hVVRTHf+s441zf4iSp0EOxiHkQSI0GkpPgh2HsQxl0/dCD1MnUwCJGKrxzZ4hqhihDikYNsg956WWEVmDZhITj+KCaHLF8lRCaNsTo2Dg5Z8e65557z73nPs4gcdanfdZee6//Xuu/1t5HCChVcTPRsmk0wmIMdwrMASanlg8YOIXwoxj22ha7++JyOcjWUsqoOm7mimEDhuXAhFL2qflBhB1GaD8alxPF1hQEMPdpUzFuGm3GsB4YG9BxrtmwCJv+6Sd2YrNczbdHXgBVcTPDMuzEsMC76PYbYUkVzLsFZt8AU8c7s39fgdMX4chvsKcPfjmf40rotoUH+uJyLheED0Btm6k119gN3OQaV82CZ5fAfM16ADlwCl7fA31/ZBmflTIae2PS69VmAUie3KbHdV5mQXMDRO8GKcmWbGTGQOIgdHwJ1+z03Fnbos4bifS2mvPINLrcsE+OwBtRqJsd4MhFTHpOwzMJGBhKGQndQ/3Uu5xIA6htMe3G0KxmevLOR6/fuYtLQTz5fiYSInT0tsoGnU8CSJaazVGX7S80wvK66zt57uodPfCyMsuRYWNRrSWaBFDTYrZiWKljJVyiafQ5LwVXORHd4iGmsO3nVlklqQ6n5ZFsMtseC872Qk4PnYGt++CH32H8WHhiITxyD2h1rNyeXjVoW8yQmph5GEioWuv8kzWlzlJ4fsSGTV/D9u/BeMymT4K9zzmKZW9n9YmoVLeYTjE06eTa+2B1vd+BNplfz0OkHCrKIVLmjJPfqXH5GNj4mdOIvDJrKrwZhTtmOtp3uuCtb52xEbZIdcx0C8xXxbuP+5n/zTFo/giGR0YfmYW3wSsPZjqm7qAVseK9FAA4oCn4E5iuKg2Thssr6z6A746Pzrkl8FQ9NC0CHXvlwiVY/Fpac0EBaIuoUNWRGGgoc0V7+4cHYddPMJj3SsmsqJwA7Q8VJvK/IzCvLW1/NRAA1/zKMHzRC51dcG7AD1S75qvL/FH0WuYDkDcFl4bgr8twc2V2GJVkL+2C/sHMtmMsWL0Imu4FyyqeLl8KCpHw/s1w5iJMisBdt8KCOdB1HPafzHYwc4pzar2ig4iPhIXKsPVz+Phw8S0bamHjUgdkUPGVYbFGlOiBjq9A8+aVKePgxaXQUBPUbcbO14hKtWJtp89/6rBf+aCp0IuqcuLonedtxbpNqJeRAgj9OlYQoT5IFEDoTzIFEeqj1OV0qM9yF0SoPyYuiFB/zbwtJrSf09w+93/9nv8HiU3GZrAsXHsAAAAASUVORK5CYII="
        />
      </el-amap>
    </div>
    <el-dialog
      title="数据申请引导"
      :visible.sync="DialogVisible"
      width="784px"
      center
    >
      <div class="imgBar">
        <img
          v-if="this.count == 1"
          src="../../../assets/img/gatewayImg/引导1.png"
          alt
        />
        <img
          v-if="this.count == 2"
          src="../../../assets/img/gatewayImg/引导2.png"
          alt
        />
        <img
          v-if="this.count == 3"
          src="../../../assets/img/gatewayImg/引导3.png"
          alt
        />
        <p v-if="this.count == 1" class="explain">
          在地图中查找您需要数据的区域
        </p>
        <p v-if="this.count == 2" class="explain">单选/多选/框选您需要的数据</p>
        <p v-if="this.count == 3" class="explain">请选择您计划申请的数据</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="this.count > 1"
          class="dataBtn"
          type="primary"
          @click="guideImgPrev"
          >上一页</el-button
        >
        <span class="count">{{ count }}</span>
        <span class="countall">/3</span>
        <el-button
          v-if="this.count < 3"
          class="dataBtn"
          type="primary"
          @click="guideImgNext"
          >下一页</el-button
        >
        <el-button
          v-if="this.count >= 3"
          class="dataBtn"
          type="primary"
          @click="guideImgComplete"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <div class="yindao" @click="showDialog">
      <img src="../../../assets/img/gatewayImg/数据申请-引导.png" alt />
      <span class="yindaoword">数据申请引导</span>
    </div>
    <div
      class="picture"
      :class="{ pictureUp: pictureOn }"
      @click="mapMouseTool"
    >
      <img
        :src="
          pictureOn
            ? '../../../assets/img/gatewayImg/画框搜索Click.png'
            : '../../../assets/img/gatewayImg/数据申请-框选.png'
        "
        alt
      />
      <span class="yindaoword">画框搜索</span>
    </div>
    <div class="dataList">
      <!-- 顶部 -->
      <div class="topNav">
        <span class="countNav"
          >{{ selectCount }}/{{ arrList.length }} 飞行脚印</span
        >
        <div class="sign">
          <span class="signword">最多可多选20个脚印</span>
        </div>
        <el-button @click="showBtn" class="topBtn" type="primary"
          >显示列表</el-button
        >
        <el-button @click="discoverBtn" class="topBtn" plain
          >隐藏列表</el-button
        >
      </div>
      <!-- 主体 -->
      <div v-if="footFlag" class="midNav">
        <el-table
          height="625"
          ref="multipleTable"
          :data="arrList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column align="center" type="selection" />
          <el-table-column
            align="center"
            label="序号"
            type="index"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column align="center" prop="id" label="脚印ID" />
          <el-table-column align="center" prop="plane_type" label="飞机型号" />
          <el-table-column
            class="load"
            align="center"
            prop="camera_type"
            label="载荷类型"
            width="100"
          />
          <el-table-column align="center" prop="work_time" label="飞行时间">
            <template slot-scope="scope">
              <span>{{ scope.row.work_time }}分钟</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="province" label="省" />
          <el-table-column align="center" prop="city" label="市" />
          <el-table-column align="center" prop="high_gsd" label="GSD">
            <template slot-scope="scope">
              <span>{{ scope.row.high_gsd }}cm</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <img
                class="delstyle"
                src="../../../assets/img/数据申请-删除.png"
                alt
                @click="delData(scope.row, scope.$index)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 底部 -->
      <div class="botNav" v-if="footFlag">
        <el-button class="topBtn" @click="emptyBtn" plain>重新框选</el-button>
        <el-button class="topBtn" type="primary" @click="application"
          >数据申请</el-button
        >
      </div>
      <!-- 提交表单 -->
      <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
        <div class="spacial">您当前选定区域数据特点:</div>
        <div class="spacialPlay">
          <p
            class="characteristic"
            :key="index"
            v-for="(item, index) in characteristicArr"
          >
            {{ item }}
          </p>
        </div>
        <div class="tabeltit">
          <span class="title">交易列表</span>
          <span class="choose">已选择脚印:</span>
          <span class="footcount">{{ multipleSelection.length }}</span>
          <span class="choose">个</span>
        </div>
        <!-- 表单表格 -->
        <div class="center-body">
          <el-table
            max-height="170"
            ref="multipleTable"
            :data="multipleSelection"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              :index="indexMethod"
              width="40"
            ></el-table-column>
            <el-table-column align="center" prop="id" label="脚印ID" />
            <el-table-column
              align="center"
              prop="plane_type"
              label="飞机型号"
            />
            <el-table-column
              class="load"
              align="center"
              prop="camera_type"
              label="载荷类型"
              width="90"
            />
            <el-table-column align="center" prop="work_time" label="飞行时间">
              <template slot-scope="scope">
                <span>{{ scope.row.work_time }}分钟</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="province" label="省" />
            <el-table-column align="center" prop="city" label="市" />
            <el-table-column align="center" prop="high_gsd" label="GSD">
              <template slot-scope="scope">
                <span>{{ scope.row.high_gsd }}cm</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  class="block"
                  @click="delBtn(scope.row, scope.$index)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="parameter">
          <el-form
            class="formNav"
            :rules="rules"
            ref="ruleForm"
            :model="ruleForm"
          >
            <el-form-item
              class="seruleform"
              prop="category"
              label="数据申请类型"
              label-width="208px"
            >
              <el-checkbox-group
                v-model="category"
                @change="handleCheckAllChange"
              >
                <el-checkbox
                  class="chooseline"
                  label="原始影像+POS"
                ></el-checkbox>
                <el-checkbox class="chooseline" label="快拼成果"></el-checkbox>
                <el-checkbox class="chooseline" label="DSM/DEM"></el-checkbox>
                <el-checkbox class="chooseline" label="TDOM"></el-checkbox>
                <el-checkbox class="chooseline" label="2.5Dmesh"></el-checkbox>
                <el-checkbox class="chooseline" label="3Dmesh"></el-checkbox>
                <el-checkbox class="chooseline" label="视频流"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              class="seruleform"
              prop="integrality"
              label="数据完整性"
              label-width="150px"
            >
              <el-radio-group
                @change="getLabelId"
                v-model="ruleForm.integrality"
                class="radioBar yesorno"
              >
                <el-radio :label="0">可接受只覆盖部分区域</el-radio>
                <el-radio :label="1">只接受全区域覆盖</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="seruleform"
              prop="wait_time"
              label="可接受的反馈等待期"
              label-width="150px"
              style="margin-top: 13px"
            >
              <el-input
                v-model="ruleForm.wait_time"
                placeholder="请输入"
                style="width: 74px"
              ></el-input
              >&nbsp;天
              <span class="signNav">充裕的等待期更有利于数据需求的达成。</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="footBar">
          <i class="el-icon-warning"
            >鉴于数据成果多样性和存续周期，您当前申请的数据仍需管理员线下核实确认。</i
          >
          <el-button class="postForm" type="primary" @click="submitData"
            >提交</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
let arrStr = [];
let footData = [];
import { mapState, mapMutations } from "vuex";
import { provinceAndCityData } from "element-china-area-data";
export default {
  data() {
    const that = this;
    const self = this;
    return {
      pictureOn: false, //  画框搜索是否选中
      characteristicArr: [
        "时间间隔在一个月以内",
        "数据源不同",
        "由不同联盟单位持有",
      ], //  当前选定区域数据特点数组
      selectedOptions: [],
      options: provinceAndCityData,
      ruleForm: { task_id: "", category: [], integrality: "", wait_time: "" },
      dialogVisible: false,
      planeTypeData: [], // 飞机型号列表
      cameraTypeData: [], // 载荷类型列表
      selectRuleForm: {
        plane_type: "", // 飞机型号
        province: "", // 省
        city: "", // 市
        camera_type: "", // 载荷类型
        high_gsd_min: "", // GSD小
        high_gsd_max: "", // GSD大
        start_date: "", // 开始时间
        end_date: "", // 结束时间
      },
      rules: {
        category: [
          { required: true, message: "请选择数据申请类型", trigger: "blur" },
        ],
        integrality: [
          { required: true, message: "请选择数据完整性", trigger: "blur" },
        ],
        wait_time: [
          {
            required: true,
            message: "请填写可接受的反馈等待期",
            trigger: "blur",
          },
        ],
      },
      dateRang: [new Date(), ""],
      pickerOptions: {
        disabledDate(time) {
          const startyear = that.dateRang[0].getFullYear() - 1;
          const endYear = that.dateRang[0].getFullYear() + 1;

          const endDate =
            endYear +
            "-" +
            (that.dateRang[0].getMonth() + 1) +
            "-" +
            that.dateRang[0].getDate();
          const startDate =
            startyear +
            "-" +
            (that.dateRang[0].getMonth() + 1) +
            "-" +
            that.dateRang[0].getDate();
          const _endDate = new Date(endDate);
          const _startDate = new Date(startDate);
          // 即大于开始时间小于结束时间
          return (
            time.getTime() > _endDate.getTime() ||
            time.getTime() < _startDate.getTime()
          );
        },
        onPick({ minDate, maxDate }) {
          // 重新设置开始时间
          that.dateRang[0] = minDate;
        },
      },
      DialogVisible: false,
      center: [113.93041, 22.53332], //地图中心
      elMap: {}, //  地图对象
      events: {
        //地图事件
        init(e, mapStyle) {
          e.setMapStyle("amap://styles/macaron");
          that.elMap = e;
        },
        click(e) {
          const { lng, lat } = e.lnglat;
          that.lng = lng;
          that.lat = lat;

          // 这里通过高德 SDK 完成。
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          geocoder.getAddress([lng, lat], function (status, result) {
            if (status === "complete" && result.info === "OK") {
              if (result && result.regeocode) {
                that.address = result.regeocode.formattedAddress;
                // that.$nextTick();
              }
            }
          });
        },

        /**
         * 地图缩放触发
         */
        zoomchange() {
          that.zoom = that.elMap.getZoom();
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                }
              });
            },
          },
        },
      ],
      lng: 0,
      lat: 0,
      count: 1,
      localData: [],
      unitData: [],
      loadData: [],
      num: 0,
      cou: 0,
      barchart: [],
      markers: [],
      zoom: 7, //  当前缩放层级
      arrList: [],
      totalFootprints: 0, // 总脚印数
      selectCount: 0, // 选中脚印数
      footFlag: true, // 控制显隐
      multipleSelection: [], // 选中项
      category: [],
      task_id: [],
    };
  },
  methods: {
    ...mapMutations(["visibleStorage"]),
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 获取数据完整性选择
    getLabelId(param) {
      this.ruleForm.integrality = param;
    },
    // 查询按钮
    searchBtn() {
      this.getFlightFootPrints();
    },
    // 获取选择的时间
    getBarchart() {
      this.selectRuleForm.start_date = this.barchart[0] + " " + "00:00:00";
      this.selectRuleForm.end_date = this.barchart[0] + " " + "23:59:59";
    },
    // 获取GSD范围（小）
    handlesmall(val) {
      this.selectRuleForm.high_gsd_min = val;
    },
    // 获取GSD范围（大）
    handlelarge(val) {
      this.selectRuleForm.high_gsd_max = val;
    },
    // 获取选择的载荷类型
    getCamera(val) {
      this.selectRuleForm.camera_type = val;
    },
    // 获取选择的飞机型号
    getPlane(val) {
      this.selectRuleForm.plane_type = val;
    },
    // 获取选择的地区
    changeProblemType(value) {
      this.selectRuleForm.province = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0];
      this.selectRuleForm.city = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1];
    },
    // 飞机型号列表
    getPlaneType() {
      this.$apis.getPlaneType({}, this.token).then((data) => {
        if (data.data.status == 0) {
          this.planeTypeData = data.data.data;
        }
      });
    },
    // 载荷类型列表
    getCameraType() {
      this.$apis.getCameraType({}, this.token).then((data) => {
        if (data.data.status == 0) {
          this.cameraTypeData = data.data.data;
        }
      });
    },
    // 多选取值
    handleCheckAllChange(param) {
      this.ruleForm.category = param;
    },
    // 隐藏列表
    discoverBtn() {
      this.footFlag = false;
    },
    // 显示列表
    showBtn() {
      this.footFlag = true;
    },
    // 重新框选
    emptyBtn() {
      this.$confirm("此操作将清空列表，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.arrList = [];
        })
        .catch(() => {});
    },
    // 删除单挑选中
    delData(param, index) {
      this.$confirm("此操作将删除该项，是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.arrList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 删除表单数据行
    delBtn(param, index) {
      this.$confirm("此操作将删除该项，是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.multipleSelection.splice(index, 1);
        })
        .catch(() => {});
    },
    // 自定义序号
    indexMethod(index) {
      return index + 1;
    },
    // 选中状态
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectCount = val.length;
      this.characteristicArr = [];

      //先进行数据组装
      this.task_id = [];
      val.forEach((element) => {
        this.task_id.push(element.id);
      });
      // 只选了一条数据，不需要比较
      if (val.length < 2) {
        return;
      }

      //将第一条与其他数据进行比较
      let unitId = val[0].confirm_unit_id;
      let planeType = val[0].plane_type;
      let waitTimeMin = Date.parse(val[0].created_at);
      let waitTimeMax = Date.parse(val[1].created_at);

      val.forEach((element, index) => {
        if (index >= 1) {
          if (unitId !== element.confirm_unit_id) {
            this.characteristicArr[0] = "由不同联盟单位持有";
          }
          if (planeType !== element.plane_type) {
            this.characteristicArr[1] = "数据源不同";
          }
          let created_at = Date.parse(element.created_at);
          if (created_at < waitTimeMin) {
            waitTimeMin = created_at;
          } else if (created_at > waitTimeMax) {
            waitTimeMax = created_at;
          }
        }
      });

      if (this.characteristicArr[0] === undefined) {
        this.characteristicArr[0] = "数据源不同";
        this.characteristicArr.pop();
      }
      if (waitTimeMax - waitTimeMin < 30 * 86400000) {
        this.characteristicArr[this.characteristicArr.length] =
          "时间间隔在一个月以内";
      }
    },
    // 数据申请
    application() {
      if (this.multipleSelection.length > 20) {
        this.$confirm("飞行脚印最多可选20个", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      } else if (this.multipleSelection.length == 0) {
        this.$confirm("请选择飞行脚印", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      } else {
        this.dialogVisible = true;
      }
    },
    // 提交申请
    submitData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$apis
            .applyData(
              {
                task_id: this.task_id,
                category: this.category,
                integrality: this.ruleForm.integrality,
                wait_time: this.ruleForm.wait_time,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                this.dialogVisible = false;
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    // 引导图完成
    guideImgComplete() {
      this.DialogVisible = false;
      this.visibleStorage(false);
    },
    // 引导图上一页
    guideImgPrev() {
      if (this.count >= 1) {
        this.count--;
      }
    },
    // 引导图下一页
    guideImgNext() {
      if (this.count < 3) {
        this.count++;
      }
    },
    // 申请引导
    showDialog() {
      this.count = 1;
      this.DialogVisible = true;
      this.visibleStorage(true);
    },
    // 获取飞行脚印
    getFlightFootPrints() {
      this.$apis
        .getFlightFootPrints(
          {
            plane_type: this.selectRuleForm.plane_type,
            province: this.selectRuleForm.province,
            city: this.selectRuleForm.city,
            camera_type: this.selectRuleForm.camera_type,
            high_gsd_min: this.selectRuleForm.high_gsd_min,
            high_gsd_max: this.selectRuleForm.high_gsd_max,
            start_date: this.selectRuleForm.start_date,
            end_date: this.selectRuleForm.end_date,
          },
          this.token
        )
        .then((data) => {
          this.markers = [];
          footData = data.data.data;
          var pointList = [];
          arrStr = [];
          for (let i = 0; i < footData.length; i++) {
            const element = footData[i];
            let ele = element.task_coordinate_points.split(";")[0];
            let arrStr = ele.split(",");
            let obj = {
              position: [Number(arrStr[1]), Number(arrStr[0])],
              events: {
                click: (e) => {
                  this.arrList.push(element);
                },
              },
              visible: true,
              draggable: false,
              template: "<span>1</span>",
            };
            this.markers[i] = obj;
          }
          this.selectRuleForm.plane_type = "";
          this.selectRuleForm.province = "";
          this.selectRuleForm.city = "";
          this.selectRuleForm.camera_type = "";
          this.selectRuleForm.high_gsd_min = "";
          this.selectRuleForm.high_gsd_max = "";
          this.selectRuleForm.start_date = "";
          this.selectRuleForm.end_date = "";
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 鼠标工具 MouseTool
    mapMouseTool() {
      this.pictureOn = true;
      if (this.zoom < 7) {
        this.utils.$notifyError("错误", "缩放等级过大");
        this.pictureOn = false;
        return;
      }
      const mouseTool = new AMap.MouseTool(this.elMap); // 在地图中添加MouseTool插件
      const drawRectangle = mouseTool.rectangle(); // 用鼠标工具画矩形
      AMap.event.addListener(mouseTool, "draw", (e) => {
        // 添加事件
        mouseTool.close(true);
        const mouseToolArr = e.obj.getPath();
        const arr = [];
        this.arrList = [];
        this.multipleSelection = [];
        this.markers.forEach((e, index) => {
          if (
            e.position[0] > mouseToolArr[0].lng &&
            e.position[0] < mouseToolArr[2].lng
          ) {
            if (
              e.position[1] < mouseToolArr[0].lat &&
              e.position[1] > mouseToolArr[2].lat
            ) {
              this.arrList.push(footData[index]);
              this.totalFootprints = this.arrList.length;
            }
          }
        });
        this.pictureOn = false;
      });
    },
  },
  created() {
    this.getFlightFootPrints();
    this.getPlaneType();
    this.getCameraType();
    if (this.centerDialogVisible == true) {
      this.DialogVisible = true;
    } else {
      this.DialogVisible = false;
    }
  },
  computed: {
    ...mapState(["user", "token", "centerDialogVisible"]),
  },
};
</script>

<style lang="less" scoped>
.footBar {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.postForm {
  float: right;
}
.el-icon-warning {
  font-size: 12px;
  color: #007cff;
}
.signNav {
  width: 300px;
  height: 23px;
  font-size: 12px;
  color: #5a87ff;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  line-height: 23px;
  border: 1px solid #5a87ff;
  background-color: rgba(90, 135, 255, 0.22);
}
.formNav {
  width: 100%;
  height: 100%;
}
.seruleform {
  display: flex;
}
.userForm {
  display: flex;
}
.chooseline {
  float: left;
}
.parameter {
  height: 170px;
  width: 100%;
  background-color: #f9f9f9;
  margin-top: 20px;
}
.center-body {
  margin-top: 10px;
  height: 160px;
}
.tabeltit {
  width: 100%;
  margin-top: 20px;
  .title {
    font-size: 13px;
  }
  .choose {
    font-size: 12px;
    color: #b4b4b4;
  }
  .footcount {
    font-size: 12px;
    color: #2c7eff;
  }
}
.spacial {
  font-size: 18px;
  color: #000000;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.spacialPlay {
  width: 100%;
  // height: 60px;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
}
.characteristic {
  width: 126px;
  height: 26px;
  border-radius: 13px;
  line-height: 26px;
  text-align: center;
  background-color: rgba(120, 155, 249, 0.11);
  border: 1px solid #235eff;
  font-size: 12px;
  font-weight: 400;
  color: #2560ff;
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 0 20px 30px 20px;
}
/deep/.el-dialog__header {
  padding: 20px;
}
/deep/.el-dialog {
  width: 756px;
  // height: 570px;
  font-weight: 600;
}
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
.dataList {
  width: 777px;
  height: calc(100vh - 134px);
  position: absolute;
  right: 14px;
  top: 75px;
  box-shadow: 0px 2px 0.5px 0px rgba(153, 153, 153, 0.2);
}
.topNav {
  width: 777px;
  height: 44px;
  background-color: #fff;
  float: left;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  .countNav {
    font-size: 14px;
    color: #2c7eff;
    margin-left: 15px;
  }
  .sign {
    width: 152px;
    height: 23px;
    border: 1px solid #5a87ff;
    text-align: center;
    margin: 0 auto;
    margin-left: 15px;
    background-color: rgba(90, 135, 255, 0.22);
    .signword {
      font-size: 14px;
      color: rgba(90, 135, 255, 1);
      line-height: 23px;
    }
  }
  .topBtn {
    margin-right: 12px;
  }
}
.midNav {
  width: 777px;
  height: calc(100% - 103px);
  background-color: #fff;
  float: left;
  margin-bottom: 6px;
}
.botNav {
  width: 777px;
  height: 47px;
  background-color: #fff;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .topBtn {
    margin-right: 12px;
  }
}
.yindao {
  width: 160px;
  height: 45px;
  background-color: #fff;
  position: absolute;
  left: 21px;
  bottom: 67px;
  box-shadow: 0px 5px 15px 5px rgba(153, 153, 153, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.picture {
  width: 131px;
  height: 45px;
  background-color: #fff;
  position: absolute;
  left: 21px;
  top: 136px;
  box-shadow: 0px 5px 15px 5px rgba(153, 153, 153, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;
  font-size: 15px;
}
.picture:hover {
  border: 1px solid #2c7eff;
}
.pictureUp {
  background-color: #1782fb;
  color: #fff !important;
}
.yindaoword {
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}
.dataBtn {
  width: 85px;
  margin-left: 15px;
}
.NavBiu {
  float: left;
}
.weatherMap {
  height: calc(100vh - 114px);
  float: left;
  width: 100%;
  overflow-y: auto;
}
.count {
  font-size: 33px;
  color: #007cff;
  margin-left: 15px;
}
.countall {
  font-size: 33px;
  color: #999999;
}
.explain {
  text-align: center;
  font-size: 14px;
  color: #939393;
}
.topSearch {
  height: 64px;
  width: 100%;
  float: left;
}
.formbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/deep/.el-form-item {
  margin-bottom: 0px;
  margin-left: 30px;
}
/deep/.el-input-number--medium {
  width: 104px;
}
.countBar {
  width: 320px;
}
.timer {
  width: 450px;
}
.delstyle {
  width: 18px;
  height: 18px;
  line-height: 46px;
  cursor: pointer;
}
</style>
