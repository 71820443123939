<template>
  <div>
    <el-popover placement="right" width="200" trigger="click">
      <i class="el-icon-warning" style="color:#FAAD14;"></i>
      <span class="fmAsk">请问平台内人员绑定还是平台外人员绑定？</span>
      <div style="text-align: center; margin-top: 8px">
        <el-button size="mini" type="primary" @click="inplatform">平台内</el-button>
        <el-button type="primary" @click="sendMsg" size="mini" plain>平台外</el-button>
      </div>
      <div class="addLmReg" slot="reference">
        <div class="addButton">
          <i class="el-icon-plus loginIcon"></i>
          <span class="addregister">添加联盟绑定</span>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      Msg: true
    };
  },
  methods: {
    sendMsg() {
      this.$emit("sendSon", this.Msg);
    },
    inplatform() {
      this.$emit("platForm");
    },
    listManager() {
      this.$apis
        .listManager(
          {},

          this.unit_token
        )
        .then(data => {
         
          this.addNum.length = data.data.data;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  created() {
    this.listManager();
  },
  computed: {
    ...mapState(["unit", "unit_token"])
  }
};
</script>

<style lang="less" scoped>
.fmAsk {
  width: 234px;
  height: 56px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.loginBox {
  display: flex;
  justify-content: center;
  margin-right: 50px;
  .addLmReg {
    width: 218px;
    height: 88px;
    background: rgba(246, 247, 249, 1);
    border-radius: 5px;
    border: 2px dashed rgba(245, 187, 75, 1);
    float: left;
    margin-left: 56px;
    .addButton {
      margin-left: 20px;
      .loginIcon {
        font-size: 32px;
        width: 30px;
        height: 30px;
        float: left;
        line-height: 84px;
        color: rgba(245, 187, 75, 1);
        // margin-left: 35px;
      }
      .addregister {
        font-size: 20px;
        color: rgba(245, 187, 75, 1);
        line-height: 84px;
      }
    }
  }
}
</style>