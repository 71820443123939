<template>
  <div>
    <airspace
      ref="airspace"
      :title="'查询/加载空域'"
      @drawError="showCompleteConfirmBox = false"
      @drawClear="showCompleteConfirmBox = false"
      @drawComplete="drawComplete"
      :ableApply="false"
    >
      <template>
        <div class="goingBtn" v-if="showCompleteConfirmBox">
          <p class="titlesW">正在绘制空域</p>
          <div class="flagword">点击"完成"结束绘制</div>
          <div class="divider"></div>
          <div class="success-btn" @click="catalogueDialog = true">
            完&nbsp;成
          </div>
        </div>
      </template>
    </airspace>
    <el-dialog
      title="加载空域"
      :visible.sync="catalogueDialog"
      width="500px"
      :close-on-click-modal="false"
      :before-close="handleClose"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="bodyBar">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="授权空域名称" prop="air_space_name">
            <el-input
              v-model="ruleForm.air_space_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="起止时间" prop="flight_time">
            <el-date-picker
              v-model="ruleForm.flight_time"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getBarchart"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="授权航高" prop="authorize_altitude">
            <el-input
              v-model="ruleForm.authorize_altitude"
              placeholder="(范围在200m~800m之间)"
            ></el-input>
          </el-form-item>
          <el-form-item label="空管部门名称" prop="traffic_control_department">
            <el-input
              v-model="ruleForm.traffic_control_department"
              placeholder="请输入空管部门名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="空管联系电话" prop="traffic_control_tel">
            <el-input
              v-model="ruleForm.traffic_control_tel"
              placeholder="请输入空管联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="主管部门" prop="manage_department">
            <el-input
              v-model="ruleForm.manage_department"
              placeholder="请输入主管部门"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="manage_tel">
            <el-input
              v-model="ruleForm.manage_tel"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="加载批件" prop="letter">
            <div class="snapshotbar">
              <input
                type="file"
                class="imgupload"
                accept=".doc, .docx, .pdf"
                @change="uploadAir"
              />
              <div class="snapshotBar">
                <img
                  class="uploadImg"
                  src="../../../assets/img/Upload／pic .png"
                  alt
                />
                <p class="describe">
                  {{ airName == "" ? "点击或将文件拖拽到这里上传" : airName }}
                </p>
              </div>
            </div>
            <div class="snapshotbarOne">
              <input
                type="file"
                class="imgupload"
                accept=".doc, .docx, .pdf"
                @change="uploadTask"
              />
              <div class="snapshotBar">
                <img
                  class="uploadImg"
                  src="../../../assets/img/Upload／pic .png"
                  alt
                />
                <p class="describe">
                  {{ taskName == "" ? "点击或将文件拖拽到这里上传" : taskName }}
                </p>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="footBtn"
          :disabled="!ifUpload"
          @click="uploadData"
          type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="catalogueDialog = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
let file = [];
let files = {};
let formData = new FormData();

import { mapState, mapMutations } from "vuex";
import airspace from "@/components/airspace";
export default {
  components: {
    airspace,
  },
  data() {
    const validateAcquaintance = (rule, value, callback) => {
      if (!value) {
        callback(new Error("必须输入航高"));
      }
      value = Number(value);
      if (typeof value === "number" && !isNaN(value)) {
        if (value < 200 || value > 800) {
          callback(new Error("航高在 200 至 800 之间"));
        } else {
          callback();
        }
      } else {
        callback(new Error("航高必须为数字"));
      }
    };
    return {
      airName: "",
      taskName: "",
      AirFlag: false,
      TaskFlag: false,
      catalogueDialog: false,
      showCompleteConfirmBox: false,
      ruleForm: {
        air_space_name: "",
        flight_start_time: "",
        flight_end_time: "",
        authorize_altitude: "",
        survey_area_coordinate_points: "",
        traffic_control_department: "",
        traffic_control_tel: "",
        manage_department: "",
        manage_tel: "",
        flight_time: [],
      },
      rules: {
        air_space_name: [
          { required: true, message: "请输入授权空域名称", trigger: "blur" },
        ],
        flight_time: [
          { required: true, message: "请选择起止时间", trigger: "blur" },
        ],
        authorize_altitude: [
          { validator: validateAcquaintance, trigger: "blur" },
        ],
        traffic_control_department: [
          { required: true, message: "请输入空管部门名称", trigger: "blur" },
        ],
        traffic_control_tel: [
          { required: true, message: "请输入空管联系电话", trigger: "blur" },
        ],
        manage_department: [
          { required: true, message: "请输入主管部门", trigger: "blur" },
        ],
        manage_tel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
      },
      ifUpload: false,
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    //地图组件绘制区域完成
    drawComplete(res) {
      this.showCompleteConfirmBox = true;
      this.ruleForm.survey_area_coordinate_points = res.latitudeAndLongitudeStr;
    },
    // 完成按钮
    goingBtn() {
      this.$refs.query.open();
    },
    // 起止时间处理
    getBarchart() {
      this.ruleForm.flight_start_time =
        this.ruleForm.flight_time[0] + " " + "00:00:00";
      this.ruleForm.flight_end_time =
        this.ruleForm.flight_time[1] + " " + "23:59:59";
    },
    // 上传空域批件
    uploadAir(e) {
      this.AirFlag = true;
      files = e.target.files[0];
      this.airName = files.name;
      this.formDatas(files, 0);
    },
    // 上传任务批件
    uploadTask(e) {
      this.TaskFlag = true;
      files = e.target.files[0];
      this.taskName = files.name;
      this.formDatas(files, 1);
    },
    // 批件数据提交
    formDatas(data, index) {
      let file = data;
     
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = (e) => {
        
        if (index == 1) {
          formData.append("approval_document_2", file, file.name);
          formData.append("air_space_name", this.ruleForm.air_space_name);
          formData.append(
            "authorize_altitude",
            this.ruleForm.authorize_altitude
          );
          formData.append(
            "traffic_control_department",
            this.ruleForm.traffic_control_department
          );
          formData.append(
            "traffic_control_tel",
            this.ruleForm.traffic_control_tel
          );
          formData.append("manage_department", this.ruleForm.manage_department);
          formData.append("manage_tel", this.ruleForm.manage_tel);
          formData.append(
            "survey_area_coordinate_points",
            this.ruleForm.survey_area_coordinate_points
          );
          formData.append("flight_start_time", this.ruleForm.flight_start_time);
          formData.append("flight_end_time", this.ruleForm.flight_end_time);
          this.AirFlag == true
            ? (this.ifUpload = true)
            : (this.ifUpload = false);
        } else {
          formData.append("approval_document_1", file, file.name);
          this.TaskFlag == true
            ? (this.ifUpload = true)
            : (this.ifUpload = false);
        }
      };
    },
    // 提交按钮
    uploadData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$apis
            .saveAuthorize(formData, this.token)
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
                this.catalogueDialog = false;
              }
              loading.close();
            })
            .catch((err) => {
              console.error(err);
              loading.close();
            });
        } else {
        }
      });
    },
    // 监听关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$emit("empty");
          done();
        })
        .catch((_) => {});
    },
  },
  created() {},
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "authorized") {
      this.currentUrlStorage(["首页", "空域查询", "加载授权空域"]);
    }
  },
};
</script>

<style lang="less" scoped>
.navbar {
  width: calc(100vw - 260px);
  height: 100%;
  .amap-box {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.goingBtn {
  width: 260px;
  height: 125px;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
  position: absolute;
  top: 10%;
  left: 20%;
  box-shadow: 8px 8px 30px 8px rgba(184, 183, 183, 0.5);
  display: flex;
  flex-direction: column;
  .divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #dcdfe6;
  }
  .titlesW {
    font-size: 14px;
    color: #3c4353;
  }
  .flagword {
    font-size: 14px;
    color: #828a9e;
    margin-bottom: 20px;
  }
  .success-btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #1890ff;
    cursor: pointer;
  }
}

/deep/.el-divider--horizontal {
  margin-bottom: 0px;
}
/deep/.el-form-item__error {
  left: 150px;
}
/deep/.el-form-item__label {
  width: 150px !important;
}
/deep/.el-input {
  width: 220px;
}
/deep/.el-dialog {
  height: 750px !important;
  overflow-y: auto;
  margin-top: 10vh;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
/deep/.el-range-editor--medium.el-input__inner {
  width: 220px;
}
.hovers {
  float: left;
  margin-top: 70px;
  margin-left: 20px;
  .hoverBar {
    .yellow {
      color: #faad14;
      float: left;
      text-align: center;
      margin-top: 10px;
      margin-left: 8px;
    }
    .sign {
      color: #a6aab8;
      font-size: 12px;
      line-height: 20px;
      margin-left: 30px;
      margin-right: 5px;
    }
    /deep/ .el-popper {
      top: 680px;
      left: 68%;
      padding: 0;
    }
  }
}
.snapshotbarOne {
  float: left;
  line-height: 45px;
  margin-top: 13px;
  margin-left: 150px;
}
.snapshotbar {
  float: left;
  line-height: 45px;
  margin-top: 13px;
}
.imgupload {
  font-size: 0;
  width: 224px;
  height: 130px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}
.snapshotBar {
  width: 224px;
  height: 130px;
  background-color: #f6f7f9;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  .uploadImg {
    margin-left: 89px;
    margin-top: 20px;
  }
  .describe {
    color: #838a9d;
    font-size: 13px;
    text-align: center;
    line-height: 14px;
  }
}
.apply {
  width: 150px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: 2%;
}
.palyBar {
  text-align: center;
}
.imgBar {
  cursor: pointer;
  margin-top: 15px;
}
.wordBar {
  font-size: 14px;
  color: #b4b4b4;
  line-height: 36px;
  margin-bottom: 10px;
}
.bodyBar {
  width: 80%;
  height: 100%;
  margin-left: 5%;
  margin-top: 20px;
}
.el-button--text {
  color: #3c4353;
  font-size: 18px;
}
.el-button--text:hover {
  color: #0094ff;
  border-bottom: 4px solid #0094ff;
}
.rightBar {
  width: 350px;
  height: 93%;
  position: absolute;
  right: 1%;
  top: 2%;
  background-color: #fff;
  .bodybar {
    width: 80%;
    margin-top: 5%;
    margin-left: 10%;
    display: grid;
    .title {
      font-size: 20px;
      color: #a3aaba;
      float: left;
      margin-bottom: 20px;
    }
    .fmNav {
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;
    }
    .uploadBar {
      display: contents;
    }
    .drawair {
      display: contents;
    }
    .careful {
      color: #a6a6a6;
      font-size: 12px;
      text-align: center;
      margin: 12px auto;
      width: 192px;
    }
    .upWord {
      font-size: 14px;
      color: #a6a6a6;
    }
    .divider {
      height: 1px;
      border: 1px dashed #e3e3e3;
      width: 100%;
      margin-top: 60px;
    }
    .results {
      width: 100%;
      margin-top: 80px;
      .resultsTitle {
        color: #b1b1b1;
        font-size: 14px;
      }
      .exhibition {
        width: 100%;
        height: 220px;
        border: 1px solid #dedede;
        margin-top: 10px;
        border-radius: 2px;
        text-align: center;
        padding: 0 18px 0 18px;
        overflow-y: auto;
        .guide {
          font-size: 12px;
          line-height: 36px;
        }
        .information {
          font-size: 12px;
          color: #0094ff;
          text-align: center;
          float: left;
          line-height: 20px;
        }
        .titleword {
          font-size: 12px;
          color: #0094ff;
          text-align: center;
          float: left;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
