var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.dialogVisible,
            width: "330px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "bodys" },
            [
              _c("span", { staticClass: "count" }, [
                _vm._v("共" + _vm._s(_vm.unreadNoticeNum) + "条未读消息"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "seeall",
                  attrs: { type: "text" },
                  on: { click: _vm.watchAll },
                },
                [_vm._v("查看全部")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.noticeList, function (item, index) {
            return _c("div", { key: index }, [
              item.type == 4
                ? _c("div", { staticClass: "content" }, [
                    item.data_apply.status == 1
                      ? _c("div", { staticClass: "content-box" }, [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("有编号为："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("的数据申请待审核"),
                          ]),
                        ])
                      : item.data_apply.status == 2 ||
                        item.data_apply.status == 3
                      ? _c("div", { staticClass: "content-box" }, [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("您的数据申请："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("审核结果为："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dataApplyStatus")(
                                  item.data_apply.status
                                )
                              )
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "content-box" }, [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("编号为："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.data_apply.data_apply_no))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("的数据申请，"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dataApplyStatus")(
                                  item.data_apply.status
                                )
                              )
                            ),
                          ]),
                        ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.type == 5
                ? _c("div", { staticClass: "content" }, [
                    item.warning.status == 0
                      ? _c("div", { staticClass: "content-box" }, [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("有新的预警信息待审核："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.warning.area_name))]
                          ),
                        ])
                      : _c("div", { staticClass: "content-box" }, [
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("您的预警信息："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "contnum",
                              on: {
                                click: function ($event) {
                                  return _vm.turnData(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.warning.area_name))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v("审核结果为："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "contword" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("warningStatus")(item.warning.status)
                              )
                            ),
                          ]),
                        ]),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }