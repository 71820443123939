<template>
  <div class="tools">
    <div class="measurementTool">
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'distance' }"
        @click="selectMeasurementTool('distance')"
      >
        <img src="@/assets/img/planTask/getDistance.png" alt="" width="24" />

        测距
      </div>
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'area' }"
        @click="selectMeasurementTool('area')"
      >
        <img src="@/assets/img/planTask/getArea.png" alt="" width="24" />

        测面
      </div>
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'marker' }"
        @click="selectMeasurementTool('marker')"
      >
        <img src="@/assets/img/planTask/getPoint.png" alt="" width="24" />
        测点
      </div>
    </div>
    <div class="centerShow">
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'center' }"
        @click="shapeInCenter('center')"
      >
        <img src="@/assets/img/planTask/setCenter.png" alt="" width="24" />

        居中显示
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mouseTool: {
      type: Object,
    },
    map: {
      type: Object,
    },
    mapToolManage: {
      type: Object,
    },
  },
  data() {
    return {
      selectText: "",
      ruler2: "",
      areaPath: [],
      areaText: "",
      measurePoint: "",
      distanceText: "",
      disMouseMoveStart: "", //测距时的标记点
      distancePolyline: [], //测距图形
      polygonMarkerNumber: 0, //画多边形时，点击时绘制的点的序号
      showDrawType: false,
    };
  },
  mounted() {

  },
  methods: {
    mouseMove(e) {
      // console.log(e);
      let p1 = this.disMouseMoveStart;
      let p2 = e.lnglat;
      const distance =
        (Math.round(p1.distance(p2)) / 1000).toFixed(1) + "km,双击结束";
      if (this.distanceText === "") {
        this.distanceText = this.mapToolManage.setMapShapeaArea(
          p2,
          distance,
          "#FFFFFF",
          "#DCDCDC",
          "120px",
          "0px"
        );
      } else {
        this.distanceText.setPosition(p2);
        this.distanceText.setText(distance);
      }
    },

    selectMeasurementTool(name) {
      this.mapToolManage.clearPolygon();
      let that = this;
      this.showDrawType = false;
      this.mapToolManage.setShape(name);

      this.mouseTool.close();
      this.ruler2.turnOff();
      this.selectText = name;

      that.map.on("click", this.clickMouseMove);
      that.map.on("dblclick", this.dblclickFunc);
      // this.areaText = "";
      // this.areaPath = [];
      this.delCompute();
      switch (this.selectText) {
        case "distance":
          this.getDistance();
          that.ruler2.turnOn();
          let lnglat = "";
          this.ruler2.on("end", function (e) {
            // e.polyline.setMap(null);
            that.distancePolyline.push(e.polyline);
            that.map.off("mousemove", that.mouseMove);
            console.log(that.distanceText);
            if (that.distanceText !== "") that.distanceText.setMap(null);
            that.distanceText = "";
            // that.mapToolManage.clearTextAndMarker(that.selectText);
            lnglat = "";
          });

          this.ruler2.on("addnode", function (e) {
            that.mapToolManage.setMarker([e.position], "distanceIcon");
            let distance = "0km";
            if (lnglat !== "") {
              distance =
                (Math.round(lnglat.distance(e.position)) / 1000).toFixed(1) +
                "km";
            }
            that.mapToolManage.setMapShapeaArea(
              e.position,
              distance,
              "#FFFFFF",
              "#DCDCDC",
              "71px",
              "20px"
            );
            lnglat = e.position;
          });

          break;
        case "area":
          this.mouseTool.polygon({
            strokeColor: "#7C37B9",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: "#3F67FF",
            fillOpacity: 0.3,
            strokeStyle: "solid",
          });
          break;
        default:
          break;
      }
    },

    getDistance() {
      let icon = {
        icon: new AMap.Icon({
          size: new AMap.Size(0, 0),
          image: require("@/assets/img/planTask/diatanceDot.png"),
          imageSize: new AMap.Size(14, 14),
        }),
        offset: new AMap.Pixel(-7, -7),
      };
      let startMarkerOptions = icon;
      var endMarkerOptions = icon;
      var midMarkerOptions = icon;
      let tmpLineOptions = {
        strokeStyle: "dashed",
        strokeColor: "#FF3000",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var lineOptions = {
        strokeStyle: "solid",
        strokeColor: "#FF3000",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var rulerOptions = {
        startMarkerOptions: startMarkerOptions,
        midMarkerOptions: midMarkerOptions,
        endMarkerOptions: endMarkerOptions,
        lineOptions: lineOptions,
        tmpLineOptions: tmpLineOptions,
        startLabelText: "0公里",
      };
      this.ruler2 = new AMap.RangingTool(this.map, rulerOptions);
      console.log(this.ruler2);
    },
    clickMouseMove(e) {
      this.disMouseMoveStart = e.lnglat;

      if (this.selectText === "distance") {
        if (this.distanceText === "") this.map.on("mousemove", this.mouseMove);
      }
      if (this.selectText === "area") {
        this.areaPath.push(e.lnglat);

        if (this.areaPath.length <= 2) {
          if (this.areaText === "") {
            this.areaText = this.mapToolManage.setMapShapeaArea(
              e.lnglat,
              "至少绘制三个点",
              "#FFFFFF",
              "#DCDCDC",
              "8.2rem",
              "10px"
            );
          } else {
            this.areaText.setPosition(e.lnglat);
            this.areaText.setText("至少绘制三个点");
          }
        } else {
          var area =
            (
              Math.round(AMap.GeometryUtil.ringArea(this.areaPath)) / 1000000
            ).toFixed(1) + "km²,双击结束";
          this.areaText.setPosition(e.lnglat);
          this.areaText.setText(area);
        }
      }
      if (this.selectText === "marker") {
        if (this.measurePoint === "") {
          this.measurePoint = this.mapToolManage.setMarker(
            [e.lnglat],
            "measurePointIcon",
            -7.5,
            -30
          );
        } else {
          this.measurePoint.setPosition(e.lnglat);
        }
        this.measurePoint.setLabel({
          offset: new AMap.Pixel(10, 10), //设置文本标注偏移量
          content:
            '<div class="measurePoint"><div class="lnglat"><img src="/static/img/planTask/lngIcon.png" alt="" width="12"><span class="titleLnglat">经度</span><span>' +
            e.lnglat.lng.toFixed(4) +
            '</span></div><div class="lnglat"><img src="/static/img/planTask/latIcon.png" alt="" width="12"><span class="titleLnglat">纬度</span><span>' +
            e.lnglat.lat.toFixed(4) +
            "</span></div></div>", //设置文本标注内容
          direction: "right", //设置文本标注方位
        });
      }
    },
    dblclickFunc(e) {
      if (this.selectText === "polygon") {
        this.mapToolManage.clearPolygon();
        this.polygonMarkerNumber = 0
      }
      if (this.selectText === "area") {
        let text = this.areaText.getText();
        this.areaText.setText(text.split(",")[0]);
        // this.mapToolManage.clearTextAndMarker(this.selectText);
        if (this.areaPath.length <= 2) {
          this.areaText.setMap(null);
          this.areaText = "";
        }
      }
      if (!this.showDrawType) {
        this.measurePoint = ""
        this.areaPath = [];
        // this.areaText.setMap
        // this.mapToolManage.clearTextAndMarker(this.selectText);
        this.areaText = "";
      }
    },
    // 居中显示
    shapeInCenter() {
      this.map.setFitView();
    },
    delCompute() {
      let that = this;
      if (that.distancePolyline.length > 0) {
        that.mapToolManage.clearTextAndMarker(that.selectText);
        that.distancePolyline.forEach((item) => {
          item.setMap(null);
        });
        that.distancePolyline = [];
      }
      that.mapToolManage.clearTextAndMarker(that.selectText);

      this.mapToolManage.clearCompute();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.amap-marker-label {
  border: 0;
  background-color: transparent;
}
/deep/.measurePoint {
  width: 117px;
  height: 45px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  display: flex;
    flex-direction: column;
    justify-content: space-around;
  .lnglat {
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
        display: flex;
    align-items: center;
    .titleLnglat {
      // display: inline-block;
      // width: 46px;
      margin: 0 5px;

    }
    img {
      margin-left: 10px;
      height: 12px;
    }
  }
  span {
    display: inline-block;
    font-size: 12px;
    // transform: scale(0.5);
    color: #999999;
  }
}
.tools {
  display: flex;
  .measurementTool {
    width: 231px;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: rgba(3, 13, 28, 0.8);
    justify-content: space-around;
    .selectBtn {
      color: #409eff !important;
    }

    div {
      width: 77px;
      height: 100%;
      line-height: 40px;
    }
  }
  %drawToolPubilcStyle {
    text-align: center;
    font-size: 13px;
    color: #d8d8d8;
    &:hover {
      color: #5d626b;
    }
  }
  .canBtn {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .toolBtn {
    @extend %drawToolPubilcStyle;
  }
  .centerShow {
    width: 120px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(3, 13, 28, 0.8);
    margin-left: 12px;
  }
}
</style>
