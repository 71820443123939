var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "topSearch" },
        [
          _c(
            "el-form",
            {
              ref: "selectRuleForm",
              staticClass: "formbox",
              attrs: { model: _vm.selectRuleForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "userForm", attrs: { prop: "plane_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "飞机型号",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getPlane(_vm.selectRuleForm.plane_type)
                        },
                      },
                      model: {
                        value: _vm.selectRuleForm.plane_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectRuleForm, "plane_type", $$v)
                        },
                        expression: "selectRuleForm.plane_type",
                      },
                    },
                    _vm._l(_vm.planeTypeData, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.plane_type,
                          value: item.plane_type,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "userForm", attrs: { prop: "province" } },
                [
                  _c("el-cascader", {
                    ref: "myCascader",
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "选择区域(可搜索)",
                      size: "large",
                      options: _vm.options,
                    },
                    on: { change: _vm.changeProblemType },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function ($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "userForm" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "载荷类型",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getCamera(_vm.selectRuleForm.camera_type)
                        },
                      },
                      model: {
                        value: _vm.selectRuleForm.camera_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectRuleForm, "camera_type", $$v)
                        },
                        expression: "selectRuleForm.camera_type",
                      },
                    },
                    _vm._l(_vm.cameraTypeData, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.camera_type,
                          value: item.camera_type,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm countBar",
                  attrs: { label: "GSD范围：", "label-width": "85px" },
                },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right" },
                    on: { change: _vm.handlesmall },
                    model: {
                      value: _vm.selectRuleForm.high_gsd_min,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectRuleForm, "high_gsd_min", $$v)
                      },
                      expression: "selectRuleForm.high_gsd_min",
                    },
                  }),
                  _vm._v(" -\n        "),
                  _c("el-input-number", {
                    attrs: { "controls-position": "right" },
                    on: { change: _vm.handlelarge },
                    model: {
                      value: _vm.selectRuleForm.high_gsd_max,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectRuleForm, "high_gsd_max", $$v)
                      },
                      expression: "selectRuleForm.high_gsd_max",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm timer",
                  attrs: { label: "时间区间：", "label-width": "85px" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                    },
                    on: { change: _vm.getBarchart },
                    model: {
                      value: _vm.barchart,
                      callback: function ($$v) {
                        _vm.barchart = $$v
                      },
                      expression: "barchart",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "topBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.searchBtn },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "weatherMap" },
        [
          _c(
            "el-amap",
            {
              staticClass: "amap-box",
              attrs: {
                plugin: _vm.plugin,
                events: _vm.events,
                zoom: _vm.zoom,
                center: _vm.center,
                vid: "amap-vue",
              },
            },
            _vm._l(_vm.markers, function (item, index) {
              return _vm.markers.length > 0
                ? _c("el-amap-marker", {
                    key: index + "121212121",
                    attrs: {
                      position: item.position,
                      visible: item.visible,
                      draggable: item.draggable,
                      events: item.events,
                      vid: index,
                      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADuUlEQVRYR8WXa4hVVRTHf+s441zf4iSp0EOxiHkQSI0GkpPgh2HsQxl0/dCD1MnUwCJGKrxzZ4hqhihDikYNsg956WWEVmDZhITj+KCaHLF8lRCaNsTo2Dg5Z8e65557z73nPs4gcdanfdZee6//Xuu/1t5HCChVcTPRsmk0wmIMdwrMASanlg8YOIXwoxj22ha7++JyOcjWUsqoOm7mimEDhuXAhFL2qflBhB1GaD8alxPF1hQEMPdpUzFuGm3GsB4YG9BxrtmwCJv+6Sd2YrNczbdHXgBVcTPDMuzEsMC76PYbYUkVzLsFZt8AU8c7s39fgdMX4chvsKcPfjmf40rotoUH+uJyLheED0Btm6k119gN3OQaV82CZ5fAfM16ADlwCl7fA31/ZBmflTIae2PS69VmAUie3KbHdV5mQXMDRO8GKcmWbGTGQOIgdHwJ1+z03Fnbos4bifS2mvPINLrcsE+OwBtRqJsd4MhFTHpOwzMJGBhKGQndQ/3Uu5xIA6htMe3G0KxmevLOR6/fuYtLQTz5fiYSInT0tsoGnU8CSJaazVGX7S80wvK66zt57uodPfCyMsuRYWNRrSWaBFDTYrZiWKljJVyiafQ5LwVXORHd4iGmsO3nVlklqQ6n5ZFsMtseC872Qk4PnYGt++CH32H8WHhiITxyD2h1rNyeXjVoW8yQmph5GEioWuv8kzWlzlJ4fsSGTV/D9u/BeMymT4K9zzmKZW9n9YmoVLeYTjE06eTa+2B1vd+BNplfz0OkHCrKIVLmjJPfqXH5GNj4mdOIvDJrKrwZhTtmOtp3uuCtb52xEbZIdcx0C8xXxbuP+5n/zTFo/giGR0YfmYW3wSsPZjqm7qAVseK9FAA4oCn4E5iuKg2Thssr6z6A746Pzrkl8FQ9NC0CHXvlwiVY/Fpac0EBaIuoUNWRGGgoc0V7+4cHYddPMJj3SsmsqJwA7Q8VJvK/IzCvLW1/NRAA1/zKMHzRC51dcG7AD1S75qvL/FH0WuYDkDcFl4bgr8twc2V2GJVkL+2C/sHMtmMsWL0Imu4FyyqeLl8KCpHw/s1w5iJMisBdt8KCOdB1HPafzHYwc4pzar2ig4iPhIXKsPVz+Phw8S0bamHjUgdkUPGVYbFGlOiBjq9A8+aVKePgxaXQUBPUbcbO14hKtWJtp89/6rBf+aCp0IuqcuLonedtxbpNqJeRAgj9OlYQoT5IFEDoTzIFEeqj1OV0qM9yF0SoPyYuiFB/zbwtJrSf09w+93/9nv8HiU3GZrAsXHsAAAAASUVORK5CYII=",
                    },
                  })
                : _vm._e()
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据申请引导",
            visible: _vm.DialogVisible,
            width: "784px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.DialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "imgBar" }, [
            this.count == 1
              ? _c("img", {
                  attrs: {
                    src: require("../../../assets/img/gatewayImg/引导1.png"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.count == 2
              ? _c("img", {
                  attrs: {
                    src: require("../../../assets/img/gatewayImg/引导2.png"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.count == 3
              ? _c("img", {
                  attrs: {
                    src: require("../../../assets/img/gatewayImg/引导3.png"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            this.count == 1
              ? _c("p", { staticClass: "explain" }, [
                  _vm._v("\n        在地图中查找您需要数据的区域\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            this.count == 2
              ? _c("p", { staticClass: "explain" }, [
                  _vm._v("单选/多选/框选您需要的数据"),
                ])
              : _vm._e(),
            _vm._v(" "),
            this.count == 3
              ? _c("p", { staticClass: "explain" }, [
                  _vm._v("请选择您计划申请的数据"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              this.count > 1
                ? _c(
                    "el-button",
                    {
                      staticClass: "dataBtn",
                      attrs: { type: "primary" },
                      on: { click: _vm.guideImgPrev },
                    },
                    [_vm._v("上一页")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))]),
              _vm._v(" "),
              _c("span", { staticClass: "countall" }, [_vm._v("/3")]),
              _vm._v(" "),
              this.count < 3
                ? _c(
                    "el-button",
                    {
                      staticClass: "dataBtn",
                      attrs: { type: "primary" },
                      on: { click: _vm.guideImgNext },
                    },
                    [_vm._v("下一页")]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.count >= 3
                ? _c(
                    "el-button",
                    {
                      staticClass: "dataBtn",
                      attrs: { type: "primary" },
                      on: { click: _vm.guideImgComplete },
                    },
                    [_vm._v("完成")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "yindao", on: { click: _vm.showDialog } }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/img/gatewayImg/数据申请-引导.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "yindaoword" }, [_vm._v("数据申请引导")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "picture",
          class: { pictureUp: _vm.pictureOn },
          on: { click: _vm.mapMouseTool },
        },
        [
          _c("img", {
            attrs: {
              src: _vm.pictureOn
                ? "../../../assets/img/gatewayImg/画框搜索Click.png"
                : "../../../assets/img/gatewayImg/数据申请-框选.png",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "yindaoword" }, [_vm._v("画框搜索")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dataList" },
        [
          _c(
            "div",
            { staticClass: "topNav" },
            [
              _c("span", { staticClass: "countNav" }, [
                _vm._v(
                  _vm._s(_vm.selectCount) +
                    "/" +
                    _vm._s(_vm.arrList.length) +
                    " 飞行脚印"
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "topBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.showBtn },
                },
                [_vm._v("显示列表")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "topBtn",
                  attrs: { plain: "" },
                  on: { click: _vm.discoverBtn },
                },
                [_vm._v("隐藏列表")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.footFlag
            ? _c(
                "div",
                { staticClass: "midNav" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: "625",
                        data: _vm.arrList,
                        "tooltip-effect": "dark",
                        "header-cell-style": { background: "#f5f5f5" },
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", type: "selection" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          index: _vm.indexMethod,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "id", label: "脚印ID" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "plane_type",
                          label: "飞机型号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "load",
                        attrs: {
                          align: "center",
                          prop: "camera_type",
                          label: "载荷类型",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "work_time",
                          label: "飞行时间",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.work_time) + "分钟"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3199714591
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "province",
                          label: "省",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "city", label: "市" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "high_gsd",
                          label: "GSD",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.high_gsd) + "cm"),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4263619074
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("img", {
                                    staticClass: "delstyle",
                                    attrs: {
                                      src: require("../../../assets/img/数据申请-删除.png"),
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delData(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1212441656
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.footFlag
            ? _c(
                "div",
                { staticClass: "botNav" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "topBtn",
                      attrs: { plain: "" },
                      on: { click: _vm.emptyBtn },
                    },
                    [_vm._v("重新框选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "topBtn",
                      attrs: { type: "primary" },
                      on: { click: _vm.application },
                    },
                    [_vm._v("数据申请")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "spacial" }, [
                _vm._v("您当前选定区域数据特点:"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "spacialPlay" },
                _vm._l(_vm.characteristicArr, function (item, index) {
                  return _c(
                    "p",
                    { key: index, staticClass: "characteristic" },
                    [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tabeltit" }, [
                _c("span", { staticClass: "title" }, [_vm._v("交易列表")]),
                _vm._v(" "),
                _c("span", { staticClass: "choose" }, [_vm._v("已选择脚印:")]),
                _vm._v(" "),
                _c("span", { staticClass: "footcount" }, [
                  _vm._v(_vm._s(_vm.multipleSelection.length)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "choose" }, [_vm._v("个")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "center-body" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "170",
                        data: _vm.multipleSelection,
                        "tooltip-effect": "dark",
                        "header-cell-style": { background: "#f5f5f5" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          index: _vm.indexMethod,
                          width: "40",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "id", label: "脚印ID" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "plane_type",
                          label: "飞机型号",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "load",
                        attrs: {
                          align: "center",
                          prop: "camera_type",
                          label: "载荷类型",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "work_time",
                          label: "飞行时间",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.work_time) + "分钟"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "province",
                          label: "省",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", prop: "city", label: "市" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "high_gsd",
                          label: "GSD",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.high_gsd) + "cm"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "block",
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delBtn(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "parameter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "formNav",
                      attrs: { rules: _vm.rules, model: _vm.ruleForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "seruleform",
                          attrs: {
                            prop: "category",
                            label: "数据申请类型",
                            "label-width": "208px",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handleCheckAllChange },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            },
                            [
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "原始影像+POS" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "快拼成果" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "DSM/DEM" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "TDOM" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "2.5Dmesh" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "3Dmesh" },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                staticClass: "chooseline",
                                attrs: { label: "视频流" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "seruleform",
                          attrs: {
                            prop: "integrality",
                            label: "数据完整性",
                            "label-width": "150px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "radioBar yesorno",
                              on: { change: _vm.getLabelId },
                              model: {
                                value: _vm.ruleForm.integrality,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "integrality", $$v)
                                },
                                expression: "ruleForm.integrality",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("可接受只覆盖部分区域"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("只接受全区域覆盖"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "seruleform",
                          staticStyle: { "margin-top": "13px" },
                          attrs: {
                            prop: "wait_time",
                            label: "可接受的反馈等待期",
                            "label-width": "150px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "74px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.wait_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "wait_time", $$v)
                              },
                              expression: "ruleForm.wait_time",
                            },
                          }),
                          _vm._v(" 天\n            "),
                          _c("span", { staticClass: "signNav" }, [
                            _vm._v("充裕的等待期更有利于数据需求的达成。"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footBar" },
                [
                  _c("i", { staticClass: "el-icon-warning" }, [
                    _vm._v(
                      "鉴于数据成果多样性和存续周期，您当前申请的数据仍需管理员线下核实确认。"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "postForm",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitData },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sign" }, [
      _c("span", { staticClass: "signword" }, [_vm._v("最多可多选20个脚印")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }