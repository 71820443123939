<template>
  <div class="selectData">
    <span class="text">选择日期</span>
    <span class="text">
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        range-separator=""
        start-placeholder=""
        end-placeholder=""
        :clearable="false"
        @change="changeDate()"
      >
      </el-date-picker>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    dateRangeType: {
      type: String,
    },
  },
  data() {
    return {
      dateRange: "",
      start_datetime: "",
      end_datetime: "",
    };
  },
  methods: {
    changeDate() {
      this.start_datetime = this.dateRange[0] + " " + "00:00:00";
      this.end_datetime = this.dateRange[1] + " " + "23:59:59";
      let category = ""
      if(this.dateRangeType.indexOf("其他") !== -1){
        console.log(1234);
        // this.dateRangeType.splice(this.dateRangeType.indexOf("其他"),1)
        category = "无人机"
      }else{
        category = this.dateRangeType
      }
      // console.log({this.start_datetime,this.end_datetime,this.dateRangeType});
      this.$emit("getDateData", {
        created_at_from: this.start_datetime,
        created_at_to: this.end_datetime,
        category: category,
        page: 1
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectDate {
  position: relative;
  .text {
    font-size: 14px;
    width: 60px;
    color: #1989fa;
    position: absolute;
    right: 0;
    top: 2px;
    cursor: pointer;
    user-select: none;
    // 这里对时间选择器的样式进行修改，透明可点击
    /deep/.el-range-editor.el-input__inner {
      width: 55px;
      height: 20px;
      border: transparent;
      background-color: transparent !important;
    }
    /deep/ .el-date-editor .el-range__icon {
      color: transparent;
    }
    /deep/ .el-range-editor--medium .el-range-input {
      background: transparent !important;
      cursor: pointer;
      width: 0;
    }
  }
}
</style>
