import { PathConfig } from "../index.js";

/*************************  飞行器资源获取 *******************************/

/*
 * 增加一个新飞机类型须知
 * 如果是固定翼加入到planeList,多旋翼rotorList,垂直起降vtolList
 * 如果是dji设备加入到djiDeviceList中
 * 深圳研发D200，V100系列等采用新飞控协议的，加入到newProtocolList
 */
const planeM = {
  //sysMap页面飞机渲染图
  statImage: {
    F1000: "plane_F1000.png",
    F2000: "plane_F2000.png",
    F200: "plane_F200.png",
    F300: "plane_F300.png",
    P300: "plane_P300.png",
    D200: "plane_D200.png",
    D300: "plane_D200.png",
    D1000: "plane_phantom.png",
    D2000: "plane_D2000.png",
    V1000: "plane_V1000.png",
    Phantom4: "plane_phantom.png",
    Inspire2: "plane_inspire_2.png",
    Mavic2: "plane_mavic_2.png",
    V100: "plane_V100.png",
    V200: "plane_V100.png",
    V300: "plane_V100.png",
    E2000: "plane_E2000.png",
    D20: "plane_D20.png",
    unknow: "plane_unknown.png",
  },
  //云播界面飞机图标
  iconImage: {
    F1000: "plane_F1000.png",
    F2000: "plane_F2000.png",
    F200: "plane_F200.png",
    F300: "plane_F300.png",
    P300: "plane_P300.png",
    D20: "plane_D200.png",
    D200: "plane_D200.png",
    D300: "plane_D200.png",
    D500: "plane_D500.png",
    D1000: "plane_D1000.png",
    D2000: "plane_D2000.png",
    E2000: "plane_D2000.png",
    V1000: "plane_V1000.png",
    Phantom4: "plane_D1000.png",
    Inspire2: "plane_Inspire_2.png",
    Mavic2: "plane_mavic_2.png",
    V10: "plane_V10.png",
    V100: "plane_V100.png",
    V200: "plane_V100.png",
    V300: "plane_V100.png",
    unknow: "plane_F1000.png",
  },
  djiDeviceList: ["Phantom4", "Inspire2", "Mavic2", "D1000"],
  planeList: ["F1000", "F2000", "F200", "F300", "P300"],
  rotorList: [
    "D200",
    "D300",
    "D1000",
    "Phantom4",
    "Inspire2",
    "Mavic2",
    "D2000",
    "E2000",
    "D500",
    "V10",
  ],
  vtolList: ["V100", "V200", "V300", "V1000"],
  //D200新协议支持机型
  newProtocolList: [
    "D200",
    "D300",
    "D500",
    "V10",
    "V100",
    "V200",
    "V300",
    "D2000",
    "V1000",
    "E2000",
  ],
  findPlane: function (str) {
    if (str) {
      if (str.indexOf("phantom") >= 0 || str.indexOf("Phantom") >= 0) {
        return "Phantom4";
      }
      
      if (str.indexOf("inspire") >= 0 || str.indexOf("Inspire") >= 0) {
        return "Inspire2";
      }
      
      if (str.indexOf("mavic") >= 0 || str.indexOf("Mavic") >= 0) {
        return "Mavic2";
      }
      const modelsHaveOwnPics = [
        "F1000", "F2000", "F200", "F300",
        "P300",
        "D200", "D300", "D500", "D1000", "D2000", "D20",
        "E2000",
        "V100", "V200", "V300", "V1000", "V10",
      ]
      if(modelsHaveOwnPics.includes(str)) {
        return str
      }
    }

    return "unknow";
  },
  //获取飞机图片
  getStatImageUrl: function (str) {
    var plane = this.findPlane(str);
    return `${PathConfig.planeImgPathMapBase}${this.statImage[plane]}`;
  },
  //获取首页弹窗飞机图片
  getHomeImageUrl: function (str) {
    var plane = this.findPlane(str);
    return "/img/stat/"+this.statImage[plane]
    // return `${PathConfig.planeImgPathMapBase}${this.statImage[plane]}`;
  },
  //获取飞机图标
  getIconImageUrl: function (str) {
    var plane = this.findPlane(str);
    console.log(`${PathConfig.planeImgPathCloudBase}${this.iconImage[plane]}`,"图片");
    return `${PathConfig.planeImgPathCloudBase}${this.iconImage[plane]}`;
  },
  //获取cs类型
  getCsType: function (str) {
    var plane = this.findPlane(str);

    if (this.djiDeviceList.indexOf(plane) >= 0) {
      return "gcj02";
    } else {
      return "wgs84";
    }
  },
  //是否是DJI设备
  isDjiDevice: function (str) {
    var plane = this.findPlane(str);
    return this.djiDeviceList.indexOf(plane) >= 0;
  },
  //是否是旋翼飞机
  isRotorDevice: function (str) {
    var plane = this.findPlane(str);
    return this.rotorList.indexOf(plane) >= 0;
  },
  //是否是固定翼飞机
  isPlaneDevice: function (str) {
    var plane = this.findPlane(str);
    return this.planeList.indexOf(plane) >= 0;
  },
  isNewProtocolDevice: function (str) {
    var plane = this.findPlane(str);
    return this.newProtocolList.indexOf(plane) >= 0;
  },
  //这是个啥玩意
  whatTheHellIsThis: function (str) {
    var plane = this.findPlane(str);

    if (this.rotorList.indexOf(plane) >= 0) {
      //这是个旋翼机
      return "rotor";
    } else if (this.planeList.indexOf(plane) >= 0) {
      //这是个固定翼
      return "plane";
    } else if (this.vtolList.indexOf(plane) >= 0) {
      //这是个倾转机
      return "vtol";
    } else {
      //我也不知道是个啥
      return "unkonw";
    }
  },
  //不同的飞机，电池个数不同
  getBatteryNumOfPlane(planeType) {
    let num = 1;
    switch (planeType){
    case PlaneTypes.D200:
    case PlaneTypes.D300:
      num = 3;
      break;
  
    case PlaneTypes.V100:
    case PlaneTypes.V200:
    case PlaneTypes.V300:
    case PlaneTypes.D500:
      num = 2;
      break;
  
    case PlaneTypes.D20:
      num = 6;
      break;
  
    case PlaneTypes.D2000:
    case PlaneTypes.E2000:
    case PlaneTypes.V1000:
    case PlaneTypes.D1000:
      num = 1;
      break;
  
    case PlaneTypes.V10:
      num = 10;
      break;
  
    default:
      num = 1;
      break;
    }
  
    return num;
  }
};
const PlaneTypes = {
  F1000: "F1000",
  F2000: "F2000",
  F200: "F200",
  F300: "F300",
  P300: "P300",
  D20: "D20",
  D200: "D200",
  D300: "D300",
  D500: "D500",
  D1000: "D1000",
  D2000: "D2000",
  E2000: "E2000",
  Phantom4: "Phantom4",
  Inspire2: "Inspire2",
  Mavic2: "Mavic2",
  V10: "V10",
  V100: "V100",
  V200: "V200",
  V300: "V300",
  V1000: "V1000",
  unknow: "unknow",
}
export { planeM };
