var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "chat-user" }, [
        _c("div", { staticClass: "header" }, [
          _vm.chatAddDialog.type == 1
            ? _c("span", [_vm._v("发起群聊")])
            : _vm.chatAddDialog.type == 2
            ? _c("span", [_vm._v("发起音视频通话")])
            : _vm.chatAddDialog.type == 3
            ? _c("span", [_vm._v("添加成员")])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "select-body" }, [
          this.chatDialog.project.id
            ? _c("div", [
                _c("div", { staticClass: "task-name task-name-no" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.chatDialog.project.name) +
                      "\n        "
                  ),
                ]),
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "task-name", on: { click: _vm.selectTask } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.chatDialog.project.name
                            ? _vm.chatDialog.project.name
                            : "默认"
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "option-box" }, [
            _c(
              "div",
              {
                staticClass: "add-member",
                on: { click: _vm.showSelectUserDialog },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/img/chat/add_member.png") },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "btn-font" }, [_vm._v("添加成员")]),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmUsers.length == 0,
                  expression: "confirmUsers.length == 0",
                },
              ],
              staticClass: "user",
            },
            [_vm._v("暂无人员")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmUsers.length > 0,
                  expression: "confirmUsers.length > 0",
                },
              ],
              staticClass: "user",
            },
            [
              _vm._l(_vm.confirmUsers.slice(0, 4), function (user) {
                return _c(
                  "div",
                  { staticClass: "user-box" },
                  [
                    _c(
                      "el-image",
                      { staticClass: "avatar", attrs: { src: user.photo_url } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("el-image", {
                              staticClass: "avatar",
                              attrs: {
                                src: require("@/assets/img/user-default-head-big.png"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(user.name))]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "user-num" }, [
                _vm._v("共" + _vm._s(_vm.confirmUsers.length) + "人"),
              ]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom" }, [
          _c(
            "div",
            { staticStyle: { width: "112px" } },
            [
              _vm.chatAddDialog.type == 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.addLoading,
                          expression: "addLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.addLoading },
                      on: { click: _vm.createRoom },
                    },
                    [_vm._v("群聊")]
                  )
                : _vm.chatAddDialog.type == 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.addLoading,
                          expression: "addLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.addLoading },
                      on: { click: _vm.createRoom },
                    },
                    [_vm._v("音视频通话")]
                  )
                : _vm.chatAddDialog.type == 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.addLoading,
                          expression: "addLoading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.addLoading },
                      on: { click: _vm.addUser },
                    },
                    [_vm._v("添加成员")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            modal: false,
            width: "80%",
            "lock-scroll": false,
            visible: _vm.selectTaskDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectTaskDialogVisible = $event
            },
          },
        },
        [
          _c("task-select", {
            on: {
              selectTask: _vm.isSelectTask,
              cancelSelect: function ($event) {
                _vm.selectTaskDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "el-dialog-wi",
          attrs: {
            title: "添加成员",
            visible: _vm.selectUserDialogVisible,
            modal: false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectUserDialogVisible = $event
            },
          },
        },
        [
          _c("user-select", {
            key: _vm.chatAddDialog.key,
            on: {
              confirmSelect: _vm.confirmSelect,
              cancelSelect: function ($event) {
                _vm.selectUserDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-top": "10px" } }, [
      _c("div", { staticClass: "hint" }, [
        _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
        _vm._v("\n            最多不能超过30人\n          "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }