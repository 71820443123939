<template>
  <div id="app">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      text-color="#fff"
      router
      @select="handleSelect"
    >
      <el-menu-item
        v-if="navMessage == 0"
        :class="{ active: currentUrl[2] == '航线任务' }"
        index="/getawayTask?nav=feima"
      >
        <span slot="title">航线任务</span>
      </el-menu-item>
      <el-menu-item
        index="/getawayTask?nav=gm"
        v-if="navMessage == 0"
        :class="{ active: currentUrl[2] == '通用任务' }"
      >
        <span slot="title">通用任务</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 3">
        <template class="airTitle" slot="title">空域查询</template>
      </el-menu-item-group>
      <el-menu-item
        index="/airsearch?nav=air"
        v-if="navMessage == 3"
        :class="{ active: currentUrl[2] == '空域查询' }"
      >
        <span slot="title">空域查询</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 3">
        <template class="airTitle" slot="title">空域档案</template>
      </el-menu-item-group>
      <el-menu-item
        index="/authorization?nav=aired"
        v-if="navMessage == 3"
        :class="{ active: currentUrl[2] == '已加载授权空域' }"
      >
        <span slot="title">已加载授权空域</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 7">
        <template class="airTitle" slot="title">个人设置</template>
      </el-menu-item-group>
      <el-menu-item
        index="/personal?nav=pers"
        v-if="navMessage == 7"
        :class="{ active: currentUrl[2] == '个人信息' }"
      >
        <span slot="title">个人信息</span>
      </el-menu-item>
      <el-menu-item
        index="/settings?nav=sett"
        v-if="navMessage == 7"
        :class="{ active: currentUrl[2] == '账户设置' }"
      >
        <span slot="title">账户设置</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 7">
        <template class="airTitle" slot="title">数据申请</template>
      </el-menu-item-group>
      <el-menu-item
        index="/Applied?nav=Appl"
        v-if="navMessage == 7"
        :class="{ active: currentUrl[2] == '已申请数据' }"
      >
        <span slot="title">已申请数据</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 7">
        <template class="airTitle" slot="title">预警发布</template>
      </el-menu-item-group>
      <el-menu-item
        index="/issued?nav=issued"
        v-if="navMessage == 7"
        :class="{ active: currentUrl[2] == '已发布预警' }"
      >
        <span slot="title">已发布预警</span>
      </el-menu-item>
      <el-menu-item-group v-if="navMessage == 7">
        <template class="airTitle" slot="title">空域咨询</template>
      </el-menu-item-group>
      <el-menu-item
        index="/Airspace?nav=Airs"
        v-if="navMessage == 7"
        :class="{ active: currentUrl[2] == '已申请空域' }"
      >
        <span slot="title">已申请空域</span>
      </el-menu-item>
      <el-menu-item
        index="/datamanage?nav=dman"
        v-if="navMessage == 8"
        :class="{ active: currentUrl[2] == '数据申请管理' }"
      >
        <span slot="title">数据申请管理</span>
      </el-menu-item>
      <el-menu-item
        index="/warningmanage?nav=wman"
        v-if="navMessage == 8"
        :class="{ active: currentUrl[2] == '预警发布管理' }"
      >
        <span slot="title">预警发布管理</span>
      </el-menu-item>
      <el-menu-item
        index="/publishTask"
        v-if="navMessage == 9"
        :class="{ active: currentUrl[2] == '发布任务' }"
      >
        <span slot="title">发布任务</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      activeIndex: "/",
    };
  },
  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push({
        path: key,
        params: { data: keyPath },
      });
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单

    },
  },
  computed: {
    ...mapState(["navMessage", "currentUrl", "user", "token"]),
  },
  created() {
    this.setCurrentRoute();
  },
};
</script>

<style lang="less" scoped>
/deep/.el-menu-item-group__title {
  color: #a4aab9;
  margin: 20px 0 0 20px;
  font-size: 16px;
}
#app {
  width: 220px;
  height: calc(100vh - 50px);
  float: left;
}
/deep/.el-menu-item.is-active {
  color: #fff;
}
.active {
  background: rgba(0, 134, 255, 0.3) !important;
  color: #0086ff !important;
}
/deep/.el-menu-item:nth-child(1) {
  margin-top: 2vh;
}
/deep/.el-menu-vertical-demo {
  background-color: #000c17 !important;
}
/deep/.el-menu-item {
  height: 42px;
  line-height: 42px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-menu-item:hover {
  background: rgba(0, 134, 255, 0.3) !important;
  color: #0086ff !important;
}
.el-menu-vertical-demo {
  float: left;
  width: 220px;
  height: calc(100vh - 50px);
}
</style>
