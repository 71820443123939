var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-divider", { staticClass: "fenge" }),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c("div", { staticClass: "leftTit" }, [
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空域名称")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("空域中心点")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("作业高度（真高）"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("航飞作业单位名称"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("委托联盟单位名称"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("规划飞行窗口")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "title" }, [_vm._v("申请函")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "rightTit" }, [
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.air_space_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v("(" + _vm._s(this.airDetails.coordinate_point) + ")"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.flight_altitude) + "m"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.flight_company_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(this.airDetails.authorize_unit_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.start) + "～" + _vm._s(_vm.end)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "details" },
                [
                  _c(
                    "el-link",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.download },
                    },
                    [_vm._v("下载申请函")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider", { staticClass: "fenge" }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeBtn } },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }