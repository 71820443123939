<template>
  <div>
    <div class="today-weather">
      <div class="top">
        <div class="info">
          <div style="font-size: 40px; margin-right: 20px">{{ city }}</div>
          <div>最后更新于：{{ nowWeather.obsTime | timeFormat }}</div>
        </div>
        <div class="weather-info">
          <img
            :src="
              '/static/img/weathercn/color-512/' + nowWeather.icon + '.png'
            "
          />
          <span style="font-size: 110px">{{ nowWeather.temp }}°</span>
          <span style="font-size: 30px; align-self: center">{{
            nowWeather.text
          }}</span>
        </div>
        <div class="aqi">AQI&nbsp;&nbsp;{{cityIndices.category}}</div>
      </div>
      <div class="bottom">
        <div class="item">
          <div class="value">{{ nowWeather.windScale }}级</div>
          <div class="field">{{ nowWeather.windDir }}</div>
        </div>
        <div class="item">
          <div class="value">{{ todayWeather.uvIndex | uvIndexLevel }}</div>
          <div class="field">紫外线</div>
        </div>
        <div class="item">
          <div class="value">{{ nowWeather.humidity }}%</div>
          <div class="field">相对湿度</div>
        </div>
        <div class="item">
          <div class="value">{{ nowWeather.pressure }}hPa</div>
          <div class="field">大气压</div>
        </div>
        <div class="item">
          <div class="value">{{ nowWeather.precip }}mm</div>
          <div class="field">降水量</div>
        </div>
        <div class="item">
          <div class="value">{{ nowWeather.vis }}km</div>
          <div class="field">能见度</div>
        </div>
      </div>
    </div>
    <div class="weather-forecast">
      <span class="title">7日天气预报</span>
      <div class="weather-7d-box">
        <div class="weather-title">
          <div
            class="day"
            v-for="(item, index) in recent7dWeather"
            :key="item.fxDate"
          >
            {{ item.fxDate.substr(5) }}&nbsp;&nbsp;
            <span></span>
            <span>{{ item.fxDate | dateFormat(index) }}</span>
          </div>
        </div>
        <div class="weather-icon icon-top">
          <div class="icon" v-for="item in recent7dWeather" :key="item.fxDate">
            <span class="text">{{ item.textDay }}</span>
            <img
              :src="'/static/img/weathercn/color-64/' + item.iconDay + '.png'"
            />
          </div>
        </div>
        <div id="chart" class="setChart"></div>
        <div class="weather-icon icon-bottom">
          <div class="icon" v-for="item in recent7dWeather" :key="item.fxDate">
            <img
              :src="'/static/img/weathercn/color-64/' + item.iconNight + '.png'"
            />
            <span class="text">{{ item.textNight }}</span>
            <span class="wind"
              >{{ item.windDirDay }}&nbsp;&nbsp;{{ item.windScaleDay }}级</span
            >
          </div>
        </div>
        <div class="bottom-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import echarts from "echarts";
export default {
  data() {
    return {
      city: "",
			nowWeather: {},
      todayWeather: {},
			cityIndices: {},
      recent7dWeather: [],
    };
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    initChart() {
      let chartDom = document.getElementById("chart");
      let myChart = echarts.init(chartDom);
      let tempMaxArr = [];
      let tempMinArr = [];
      this.recent7dWeather.forEach((item) => {
        tempMaxArr.push(item.tempMax);
        tempMinArr.push(item.tempMin);
      });
      let option = {
        xAxis: {
          show: true,
          type: "category",
          nameLocation: "start",
          axisLine: {
            show: false,
            lineStyle: {
              show: true,
              color: "#fff",
            },
          },
          axisTick: {
            show: true,
            inside: true,
            lineStyle: {
              color: "#DDE2EE",
            },
            length: 600,
          },
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          show: false,
          min: 0,
          max: 50,
          scale: true, //自适应
          minInterval: 1, //分割刻度
          type: "value",
          axisLabel: {
            formatter: "{value} °C",
          },
        },
        series: [
          {
            type: "line",
            data: tempMaxArr,
            color: "#FFC616",
            label: {
              show: true,
              color: "#3C4353",
              formatter: "{c}°",
              fontSize: 18,
              position: "top",
            },
          },
          {
            type: "line",
            data: tempMinArr,
            color: "#1FD0BE",
            label: {
              show: true,
              color: "#3C4353",
              formatter: "{c}°",
              fontSize: 18,
              position: "top",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    initCityWeatherByDay() {
      this.$apis
        .getCityWeatherByDay({
          city: this.city,
          day: "7d",
        })
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            this.todayWeather = result.data.data[0];
            this.recent7dWeather = result.data.data;

            this.initChart();
          }
        });
    },
		initCityWeatherByNow() {
			this.$apis
        .getCityWeatherByNow({
          city: this.city,
        })
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            this.nowWeather = result.data.data;
          }
        });
		},
		initCityIndices() {
			this.$apis
        .getCityIndices({
          city: this.city,
					day: '1d',
					type: 10,
        })
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            this.cityIndices = result.data.data[0];
          }
        });
		}
  },
  mounted() {
    this.city = decodeURI(this.$route.query.city);
		this.initCityWeatherByNow();
    this.initCityWeatherByDay();
		this.initCityIndices();
  },
  filters: {
    timeFormat(value) {
      if(value) {
        return value.replace('T',' ').replace('+08:00','')
      }
      return ''
    },
    dateFormat(value, index) {
      let text = "";
      switch (index) {
        case 0:
          text = "今天";
          break;
        case 1:
          text = "明天";
          break;
        case 2:
          text = "后天";
          break;
        default:
          let date = new Date(value);
          let dayTextArr = ["日", "一", "二", "三", "四", "五", "六"];
          text = "星期" + dayTextArr[date.getDay()];
          break;
      }
      return text;
    },
    uvIndexLevel(value) {
      if (0 <= value <= 2) {
        return "弱";
      } else if (3 <= value <= 4) {
        return "较弱";
      } else if (5 <= value <= 6) {
        return "较强";
      } else if (7 <= value <= 9) {
        return "很强";
      } else {
        return "特别强";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.today-weather {
  width: 100%;
  height: 365px;
  background: #242c42;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top {
    width: 1351px;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    .info {
      align-self: flex-end;
      display: flex;
      align-items: flex-end;
      margin-bottom: 39px;
    }
    .weather-info {
      display: flex;
      align-items: flex-end;
      img {
        width: 130px;
        height: 130px;
      }
    }
    .aqi {
      margin-bottom: 44px;
      align-self: flex-end;
      width: 158px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #95b359;
      border-radius: 20px;
    }
  }
  .bottom {
    width: 1351px;
    height: 129px;
    background: #343d53;
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .value {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 21px;
      }
      .field {
        font-size: 14px;
      }
    }
  }
}
.weather-forecast {
  margin: 44px auto;
  width: 1344px;
  height: 829px;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
  padding-top: 54px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 17px;
    margin-bottom: 38px;
    width: 90px;
    height: 18px;
    display: block;
    margin-left: 128px;
  }
}
.weather-7d-box {
  width: 100%;
  height: 606px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  .setChart {
    width: 100%;
    height: 100%;
  }
  .weather-title {
    display: flex;
    width: 1075px;
    position: absolute;
    border-top: 1px solid #dde2ee;
    .day {
      flex: 1;
      height: 50px;
      background: #fafbfc;
      line-height: 50px;
      text-align: center;
    }
  }
  .weather-icon {
    display: flex;
    width: 1075px;
    position: absolute;
    .icon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .text {
      font-size: 18px;
      font-weight: bold;
      color: #3c4353;
    }
    .wind {
      font-size: 14px;
      color: #666666;
      margin-top: 30px;
    }
  }
  .icon-top {
    top: 86px;
  }
  .icon-bottom {
    bottom: 122px;
  }
  .bottom-line {
    width: 1075px;
    height: 1px;
    position: absolute;
    bottom: 58px;
    border-bottom: 1px solid #dde2ee;
  }
}
</style>