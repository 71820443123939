<template>
  <div class="dia_body">
    <div v-if="popType === 'params'" class="params">
      <div style="height: 10px"></div>
      <div style="height: 83px" class="setSnIDPadding device_type">
        <div style="color: #fff">广西航测项目—区块1—任务1</div>
        <div>
          <span>
            <img src="@/assets/img/homeDialog/device_type.png" alt="" />
          </span>
          <span>设备类型</span>
          <span>D2000</span>
          <span>
            <img
              src="@/assets/img/homeDialog/load.png"
              alt=""
              width="23"
              style="position: relative; top: 5px"
            />
          </span>
          <span>荷载</span>
          <span>D-LiDAR150</span>
        </div>
      </div>
      <div style="height: 10px"></div>
      <div class="setSnIDPadding plane_data">
        <table>
          <tr>
            <td class="params_name">比例尺</td>
          </tr>
          <tr>
            <td class="params_title">GSD</td>
            <td class="params_data">2.00cm</td>
            <td class="params_title">海拔高度</td>
            <td class="params_data">160m</td>
          </tr>
          <tr>
            <td class="params_title">航向重叠</td>
            <td class="params_data">80%</td>
            <td class="params_title">飞行高度</td>
            <td class="params_data">160m</td>
          </tr>
          <tr>
            <td class="params_title">旁向重叠</td>
            <td class="params_data">60%</td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="popType === 'drone'">
      <div style="height: 10px"></div>
      <div class="sn_id setSnIDPadding">
        <div
          v-if="!terminal.isSurveyingmapping"
          style="width: 73px; margin-right: 10px"
        >
          <img
            :src="terminal.device_data.device_brands_icon"
            alt=""
            width="73"
          />
        </div>
        <div v-else style="width: 93px; margin-right: 10px">
          <img
            src="@/assets/img/homeDialog/Surveyingmapping.png"
            width="100%"
          />
        </div>
        <div style="width: 135px">
          <ul>
            <li class="snIdTitle">
              <img src="@/assets/img/homeDialog/snId.png" alt="" />
              <div class="text">SN码</div>
            </li>
            <li class="snIdData">{{ terminal.droneInfo.software_code }}</li>
          </ul>
        </div>
        <div
          class="play"
          v-if="monitorType !== 'point' && !terminal.isSurveyingmapping"
        >
          <img
            src="@/assets/img/homeDialog/play.png"
            style="cursor: pointer"
            @click="toDetail"
          />
        </div>
      </div>
      <div class="location setLocationPadding">
        <div>当前位置</div>
        <div
          v-if="terminal.device_data.last_point.city !== undefined"
          style="display: flex"
        >
          <span
            class="cityAndarea"
            :title="
              terminal.device_data.last_point.city +
              '-' +
              terminal.device_data.last_point.area
            "
            >{{
              terminal.device_data.last_point.city +
              "-" +
              terminal.device_data.last_point.area
            }}</span
          >
          <span
            class="setLocation"
            @click="
              goLocation([
                terminal.device_data.last_point.lon,
                terminal.device_data.last_point.lat,
              ])
            "
            >定位</span
          >
        </div>
      </div>
      <div
        class="lnglat setLngLatPadding"
        v-if="terminal.device_data.last_point.lat !== undefined"
      >
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
          <span>纬度</span
          ><span class="lnglatData">{{
            terminal.device_data.last_point.lat | saveLnglat
          }}</span>
        </div>
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
          <span>经度</span
          ><span class="lnglatData">{{
            terminal.device_data.last_point.lon | saveLnglat
          }}</span>
        </div>
      </div>
      <div v-else class="lnglat setLngLatPadding">暂无飞行记录</div>
      <div class="location setLocationPadding">
        <div>上次位置</div>
        <div
          v-if="terminal.device_data.last_two_point.city !== undefined"
          style="display: flex"
        >
          <span
            class="cityAndarea"
            :title="
              terminal.device_data.last_two_point.city +
              '-' +
              terminal.device_data.last_two_point.area
            "
            >{{
              terminal.device_data.last_two_point.city +
              "-" +
              terminal.device_data.last_two_point.area
            }}</span
          >
          <span
            class="setLocation"
            @click="
              goLocation([
                terminal.device_data.last_two_point.lon,
                terminal.device_data.last_two_point.lat,
              ])
            "
            >定位</span
          >
        </div>
      </div>
      <div
        class="lnglat setLngLatPadding"
        v-if="terminal.device_data.last_two_point.lat !== undefined"
      >
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
          <span>纬度</span
          ><span class="lnglatData">{{
            terminal.device_data.last_two_point.lat | saveLnglat
          }}</span>
        </div>
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
          <span>经度</span
          ><span class="lnglatData">{{
            terminal.device_data.last_two_point.lon | saveLnglat
          }}</span>
        </div>
      </div>
      <div v-else class="lnglat setLngLatPadding">暂无飞行记录</div>
      <div class="location setLocationPadding">
        <div>登记位置</div>
        <div
          v-if="terminal.device_data.register_point.city !== undefined"
          style="display: flex"
        >
          <span
            class="cityAndarea"
            :title="
              terminal.device_data.register_point.city +
              '-' +
              terminal.device_data.register_point.area
            "
            >{{
              terminal.device_data.register_point.city +
              "-" +
              terminal.device_data.register_point.area
            }}</span
          >
          <span
            class="setLocation"
            @click="
              goLocation([
                terminal.device_data.register_point.lon,
                terminal.device_data.register_point.lat,
              ])
            "
            >定位</span
          >
        </div>
      </div>
      <div
        v-if="
          terminal.device_data.register_point.lat !== undefined &&
          Number(terminal.device_data.register_point.lat) !== 0
        "
        class="lnglat setLngLatPadding"
      >
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
          <span>纬度</span
          ><span class="lnglatData">{{
            terminal.device_data.register_point.lat | saveLnglat
          }}</span>
        </div>
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
          <span>经度</span
          ><span class="lnglatData">{{
            terminal.device_data.register_point.lon | saveLnglat
          }}</span>
        </div>
      </div>
      <div v-else class="lnglat setLngLatPadding">
        <span
          >暂无登记位置<span v-show="terminal.device_data.is_third === 0" class="toRegisterStyle" @click="toRegister"
            >，去登记</span
          ></span
        >
      </div>
      <div style="height: 10px"></div>
      <deviceData :terminal="listDeviceData" />
    </div>

    <div v-if="popType === 'terminal'">
      <div class="location setLocationPadding">
        <div>当前位置</div>
        <div style="display: flex">
          <span class="cityAndarea" :title="city + '-' + district"
            >{{ city }}-{{ district }}</span
          >
          <span
            class="setLocation"
            @click="
              goLocation([terminal.nowloaction[0], terminal.nowloaction[1]])
            "
            >定位</span
          >
        </div>
      </div>

      <div class="lnglat setLocationPadding">
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
          <span>纬度</span
          ><span class="lnglatData">{{
            terminal.nowloaction[1] | saveLnglat
          }}</span>
        </div>
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
          <span>经度</span
          ><span class="lnglatData">{{
            terminal.nowloaction[0] | saveLnglat
          }}</span>
        </div>
      </div>
      <div class="location setLocationPadding">
        <div>登记位置</div>
        <div
          v-if="terminal.device_data.register_point.city !== undefined"
          style="display: flex"
        >
          <span
            class="cityAndarea"
            :title="
              terminal.device_data.register_point.city +
              '-' +
              terminal.device_data.register_point.area
            "
            >{{
              terminal.device_data.register_point.city +
              "-" +
              terminal.device_data.register_point.area
            }}</span
          >
          <span
            class="setLocation"
            @click="
              goLocation([
                terminal.device_data.register_point.lon,
                terminal.device_data.register_point.lat,
              ])
            "
            >定位</span
          >
        </div>
      </div>
      <div
        v-if="
          terminal.device_data.register_point.lat !== undefined &&
          Number(terminal.device_data.register_point.lat) !== 0
        "
        class="lnglat setLngLatPadding"
      >
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
          <span>纬度</span
          ><span class="lnglatData">{{
            terminal.device_data.register_point.lat | saveLnglat
          }}</span>
        </div>
        <div class="setImg">
          <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
          <span>经度</span
          ><span class="lnglatData">{{
            terminal.device_data.register_point.lon | saveLnglat
          }}</span>
        </div>
      </div>
      <div v-else class="lnglat setLngLatPadding">
        <span
          >暂无登记位置<span v-show="terminal.device_data.is_third === 0" class="toRegisterStyle" @click="toRegister"
            >，去登记</span
          ></span
        >
      </div>
      <div style="height: 10px"></div>
      <deviceData :terminal="listDeviceData" />
    </div>
    <span v-if="singleDialogVisible">
      <dialog-single-monitor
        :terminal="terminal"
        :listDeviceData="listDeviceData"
        @closeDialogSingleMonitor="closeDialogSingleMonitor"
        :nowShowLayer="nowShowLayer"
      />
    </span>
  </div>
</template>

<script>
import singleMonitor from "@/components/monitorPlane/singleMonitor.vue";
import DialogSingleMonitor from "@/components/monitorPlane/dialogSingleMonitor.vue";

import deviceData from "@/components/monitorPlane/deviceData.vue";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      terminal: {},
      popType: "terminal",
      droneInfo: {},
      isDetail: false,
      url: "",
      singleDialogVisible: false, // 单屏监控弹窗
      isShowData: false,
      listDeviceData: {
        status: 1,
        holder_dept: "",
        name: "",
        mobile: "",
        unit_name: "",
        device_brands_name: "",
      }, //设备信息
      flyArea: 0, //飞行面积
      city: "",
      district: "",
      feimaDroneInfo: {
        gps_signal_sup: 0,
        hd_signal_sup: 0,
      },
      droneBattery: ["100%", "100%"],
    };
  },
  components: {
    singleMonitor,
    deviceData,
    DialogSingleMonitor,
  },
  props: {
    terminalData: {
      type: Object,
      data: {},
    },
    trackButtonValue: {
      type: Boolean,
      data: false,
    },
    monitorType: {
      type: String,
    },
    nowShowLayer: {
      type: String,
    },
  },
  beforeMount() {
    this.initData();
  },
  filters: {
    saveLnglat(value) {
      value = Math.round(parseFloat(Number(value)) * 1000000) / 1000000;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".000000";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },
  },
  watch: {
    'terminalData.missionId': {
      handler: "initData",
      immediate: true
      // deep: true,
    },
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "currentUrlStorage",
      "navMessageStorage",
      "userStorage",
      "tokenStorage",
      "loginStorage",
      "visibleStorage",
    ]),
    initData() {
      this.terminal = JSON.parse(JSON.stringify(this.terminalData));
      const { droneInfo, dialogType } = this.terminal;
      this.popType = dialogType || "terminal";
      this.droneInfo = droneInfo;
      this.listDeviceData["msg_data"] = this.terminal.msg_data;
      if (this.terminal.msg_data.holder_user_data) {
        this.listDeviceData.name = this.terminal.msg_data.holder_user_data.name;
        this.listDeviceData.mobile =
          this.terminal.msg_data.holder_user_data.mobile;
        this.listDeviceData.holder_dept =
          this.terminal.msg_data.holder_user_data.holder_dept;
      } else {
        this.listDeviceData.name = this.terminal.msg_data.user_data.name;
        this.listDeviceData.mobile = this.terminal.msg_data.user_data.mobile;
        this.listDeviceData.holder_dept =
          this.terminal.msg_data.user_data.holder_dept;
      }
      this.listDeviceData.unit_name = this.terminal.msg_data.unit_name;
      this.listDeviceData.device_brands_name = this.terminal.device;
      this.listDeviceData.brand = this.terminal.device;
      this.listDeviceData.associated_device_type =
        this.terminal.device_data.associated_device_type;
      this.listDeviceData.associated_device_software_code =
        this.terminal.device_data.associated_device_software_code;

      this.listDeviceData.category_name =
        this.terminal.device_data.category_name;
      this.getPlaneLocation("last_point");
      this.getPlaneLocation("last_two_point");
      this.getPlaneLocation("register_point");

      if (
        this.terminal.nowloaction !== undefined &&
        this.popType === "terminal"
      ) {
        this.findLocation(this.terminal.nowloaction).then((res) => {
          this.city = res.city;
          this.district = res.district;
        });
      }
    },
    /**
     * 去登记跳转
     */
    toRegister() {
      let type = "";
      switch (this.terminal.device_data.category_name) {
        case "终端设备":
          if (this.terminal.device_data.brand_name === "飞享终端") {
            type = "terminal";
          } else if (this.terminal.device_data.brand_name === "乡村绘"){
            type = "countryApp";
          } else {
            type = "monitorModule";
          }
          break;
        case "无人机":
          type = "uav";
          break;
        default:
          type = "mapping";
          break;
      }
      this.navMessageStorage(1);
      // this.currentUrlStorage(["首页","应急装备","无人机"]);
      this.$router.push({
        path: "/deviceManagement",
        query: {
          type: type,
          software_code: this.terminal.device_data.software_code,
        },
      });
    },
    // 解析飞机地址
    getPlaneLocation(name) {
      if (this.terminal.device_data[name].lon !== undefined) {
        // this.terminal.device_data.last_point["city"]
        let lnglat = this.terminal.device_data[name];
        //console.info("god damn shit:"+JSON.stringify(lnglat));
        this.findLocation([lnglat.lon, lnglat.lat]).then((res) => {
          if (res.city !== "") {
            this.$set(this.terminal.device_data[name], "city", res.city);
          } else {
            this.$set(this.terminal.device_data[name], "city", res.province);
          }

          if (res.district === "") {
            this.$set(this.terminal.device_data[name], "area", res.township);
          } else {
            this.$set(this.terminal.device_data[name], "area", res.district);
          }

          this.$set(this.terminal.device_data[name], "province", res.province);
        });
      }
    },
    //逆地址查找地址
    findLocation(lnglat) {
      return new Promise((resolve, reject) => {
        let that = this;
        var locationText;
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder();
          geocoder.getAddress(lnglat, function (status, result) {
            if (status === "complete" && result.info === "OK") {
              // result为对应的地理位置详细信息
              // console.log(result);
              locationText = result.regeocode.addressComponent;
              resolve(locationText);
            }
          });
        });
      });
    },
    // 设置飞行面积
    setFlyArea(data) {
      this.flyArea = data.toFixed(1);
    },
    open(location = false) {
      this.initData();
      // this.dialogVisible = true
    },
    lookParams() {
      this.popType = "params";
    },
    returnDronne() {
      this.popType = "drone";
    },
    closeDialogSingleMonitor() {
      this.singleDialogVisible = false;
    },
    // 单屏监控
    toDetail() {
      this.singleDialogVisible = true;
    },
    // 监控页面查看信息
    lookPlaneData() {
      if (this.terminal.planeType === "common") {
        this.$refs.singleMonitor.openMoreData();
      } else {
        this.isShowData = !this.isShowData;
      }
    },
    closeMonitor() {
      if (this.terminal.planeType === "common" && !this.terminal.isHistory)
        this.$refs.singleMonitor.closeWebsocket();

      if (this.terminal.planeType !== "common") {
        this.$refs.singleMonitor.closeFeimaWebsocket();
      }
    },
    goLocation(lnglat) {
      this.$emit(
        "setLocationInMap",
        lnglat,
        this.popType,
        this.terminal.deviceLayer
      );
    },
    // 设置飞马无人机硬件信息
    setFeimaDroneInfo(data) {
      this.feimaDroneInfo = data;
      // let key;
      // let val;
      // console.log(this.feimaDroneInfo["gps_signal_sup"]);
      // for ( key in data) {
      //   if (key.indexOf("drone_battery_percent") !== -1) {
      //     this.feimaDroneInfo.battery.push(data[key])
      //     // console.log(this.feimaDroneInfo["drone_battery_percent"]);
      //   }
      // }
      // this.feimaDroneInfo.forEach(item=>{
      //   console.log(item);
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: #061220 !important;
  height: 71.3%;
}
/deep/ .el-dialog__header {
  padding: 0;
  height: 48px;
}
/deep/ .el-dialog__body {
  padding: 0;
  height: 100%;
  position: relative;
}
/deep/ .el-dialog__headerbtn {
  margin-top: -4px;
}
// /deep/ .el-dialog__close {
//   position: relative;
//   top: -8px;
// }
.plane_data {
  width: 320px;
  height: 164px;
  background: #001125;
  border-radius: 2px;
  border: 2px solid rgba(10, 110, 247, 0.4);
  position: absolute;
  top: 10px;
  right: 75px;
  padding: 30px 30px 0 33px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .snIdTitle {
    display: flex;
    align-items: center;
  }
  .snIdData {
    // line-height: 24px;
    color: #007bf7;
    margin-left: 4px;
  }
  .setLineHeight {
    line-height: 27px;
  }
  .status {
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #69789e;
    margin-right: 12px;
  }
  .data {
    color: #007bf7;
  }
  .phone {
    color: #ffba00;
    text-decoration: underline;
    margin-left: 11px;
  }
}
.dialog_title {
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  position: relative;
  .area {
    margin-left: 24px;
    margin-right: 25px;
    img {
      position: relative;
      top: 5px;
    }
    .area_data {
      font-family: "DIN";
      font-weight: bold;
      color: #ffba00;
      margin: 0 8px;
      // width: 30px;
      display: inline-block;
    }
  }
  .location {
    img {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
  .plane_information {
    position: absolute;
    right: 75px;
    cursor: pointer;
  }
  .feimaPlaneDatas {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 42px;
    top: 17px;
    color: #848c96;
    .feimaPlaneData {
      position: relative;
      margin-right: 30px;
      img {
        height: 18px;
        margin-right: 3px;
      }
      .feimaPlaneSingal {
        position: absolute;
        top: -11px;
        left: 18px;
        transform: scale(0.6);
      }
      .feimaPlaneHd {
        position: absolute;
        top: -11px;
        left: 27px;
        transform: scale(0.6);
      }
    }
  }
}
.icon {
  margin-left: 20px;
}
.dia_body {
  background: #001125;
  font-size: 14px;
  border-radius: 2px;
  // width: 316px;

  .detail {
    width: 1736px;
    height: 856px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .setSnIDPadding {
    padding-left: 30px;
    padding-right: 19px;
  }
  .setLocationPadding {
    padding-left: 30px;
    padding-right: 31px;
  }
  .setLngLatPadding {
    padding-left: 30px;
    padding-right: 28px;
    .toRegisterStyle {
      color: #ffba00;
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }
  }
  .sn_id {
    // padding: 10px;
    // width: 57px;
    background: #000d1c;
    display: flex;
    align-items: center;
    font-size: 13px;
    // justify-content: space-around;
    height: 82px;
    .snIdTitle {
      display: flex;
      align-items: center;
    }
    .snIdData {
      // line-height: 24px;
      color: #007bf7;
      margin-left: 4px;
    }
    .play {
      // margin-left: 24px;
      margin-top: 9px;
    }
  }
  .location {
    display: flex;
    justify-content: space-between;
    height: 36px;
    line-height: 36px;
    color: #ffffff;
    .setLocation {
      color: #ffba00;
      text-decoration: underline;
      margin-left: 14px;
      cursor: pointer;
    }
    .cityAndarea {
      overflow: hidden; /*超出部分隐藏*/
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
      display: inline-block;
      width: 120px;
    }
  }
  .lnglat {
    height: 42px;
    background: #000d1c;
    font-size: 14px;
    color: #69789e;
    line-height: 42px;
    // padding-right: 20px;
    display: flex;
    justify-content: space-between;
    .setImg {
      display: flex;
      align-items: center;
    }
    .lnglatData {
      margin-left: 5px;
    }
  }
  .information {
    background: #000d1c;
    height: 117px;
    padding-top: 13px;
    .setLineHeight {
      line-height: 27px;
    }
    .status {
      display: flex;
      justify-content: space-between;
    }
    .title {
      color: #69789e;
      margin-right: 12px;
    }
    .data {
      color: #007bf7;
    }
    .phone {
      color: #ffba00;
      text-decoration: underline;
      margin-left: 11px;
    }
  }
  .params {
    // display: flex;
    font-size: 14px;
    tr {
      line-height: 30px;
    }
    .device_type {
      height: 83px;
      background: #000d1c;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .plane_data {
      height: 304px;
      background: #000d1c;
    }
    .params_name {
      color: #fff;
    }
    .params_title {
      color: #69789e;
      width: 66px;
    }
    .params_data {
      color: #007bf7;
      width: 74px;
    }
  }
}
</style>
