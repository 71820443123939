class _Socket {
  constructor(wsURL, msg) {
    this.wsURL = wsURL;
    this.msg = msg;
    this.status = "success";
    this.socketInit = false;
  }
  //初始化并连接socket
  openSocket() {}

  //发送消息
  sendSocket(msg) {}

  //关闭socket
  closeSocket() {}

  //接收消息
  messageSocket() {}

  //连接失败
  errorSocket() {}

  // 数据接收事件
  onMessageSocket(value) {}
  // 连接错误
  onErrorSocket(value) {}
}

/**
 * FmSocket 类对 uniapp 和 web 端的websocket 做了封装处理
 *
 * @example
 *   const fmSocket = new FmSocket({ platform: 'uniapp', url: '//ws....', msg: 'jsonstringdata'})
 *   fmSocket.open()  // 打开 websocket
 *   const data = fmSocket.data // 获取data值
 *   fmSocket.close()  // 关闭 websocket
 *
 * @description
 *   注意， 当FmSocket类连接失败时， 默认做了重连处理。
 */
export default class FmSocket {
  constructor(options) {
    const { platform, url, msg } = options;
    this.platform = platform;
    this.url = url;
    this.message = msg;
    if (this.platform === "uniapp") {
      this._websocket = new UniSocket(this.url, this.message);
    } else {
      this._websocket = new WSocket(this.url, this.message);
    }
  }
  // 打开socket
  open() {
    this._websocket.openSocket();
    // 接收数据
    this._websocket.onMessageSocket = (res) => {
      this.onMessage(res);
    };
    this._websocket.onErrorSocket = (err) => {
      this.onError(err);
    };
  }
  // 关闭socket
  close() {
    this._websocket.closeSocket();
  }
  // 接收数据
  onMessage(data) {}
  // 连接错误
  onError(error) {}
}

class UniSocket extends _Socket {
  constructor(wsURL, msg) {
    super(wsURL, msg);
  }
  //初始化并连接socket
  openSocket() {
    uni.connectSocket({
      url: this.wsURL,
      fail: (e) => {
        console.log("failed...");
        this.status = "fail";
        console.log(e);
        // this.openSocket()
      },
    });
    uni.onSocketOpen((res) => {
      this.status = "open";
      this.sendSocket(this.msg);
      this.messageSocket();
      this.errorSocket();
      this.socketInit = true;
    });
  }
  //发送消息
  sendSocket(msg) {
    console.log("send " + msg);
    uni.sendSocketMessage({
      data: msg,
      success: (res) => {
        this.status = "open";
      },
      fail: (err) => {
        this.status = "fail";
      },
    });
  }
  //关闭socket
  closeSocket() {
    console.log("关闭了websocket");
    this.status = "close";
    uni.closeSocket();
  }
  //接收消息
  messageSocket() {
    uni.onSocketMessage((res) => {
      this.onMessageSocket(res);
      this.status = "open";
    });
  }
  //连接失败
  errorSocket() {
    uni.onSocketError((err) => {
      console.log(err);
      this.status = "fail";
      this.onErrorSocket(err);
    });
  }
}

class WSocket extends _Socket {
  constructor(wsURL, msg) {
    super(wsURL, msg);
    this.openSocket();
  }
  //初始化并连接socket
  openSocket() {
    if (typeof WebSocket === "undefined") {
      console.error("您的浏览器不支持WebSocket");
      return;
    }
    this.websocket = new WebSocket(this.wsURL);

    this.websocket.onopen = event => {
      if (this.websocket.readyState === 1) {
        this.sendSocket(this.msg);
        this.messageSocket();
        this.errorSocket();
        this.socketInit = true;
      }
    };
  }
  //发送消息
  sendSocket(msg) {
    this.websocket.send(msg);
  }
  //关闭socket
  closeSocket() {
    console.log("关闭了websocket");
    this.websocket.close();
  }
  //接收消息
  messageSocket() {
    this.websocket.onmessage = res => {
      this.onMessageSocket(res);
    };
  }
  //连接失败
  errorSocket() {
    this.websocket.onerror = err => {
      console.log(err, "websocket");
      this.onErrorSocket(err);
    };
  }
}
