// 用户统计
<template>
  <div class="userBody">
    <navTop />
    <div class="bodyNav">
      <div class="ImgBar">
        <div class="feimaBar">
          <span class="topTit">当前在线</span>
          <div class="topNum">
            <div class="NumOne">
              <span class="superWord">{{
                userStatisticsObj.online_admin_count
              }}</span>
              <div class="superBar">超级管理员</div>
            </div>
            <div class="NumTwo">
              <span class="unitWord">{{
                userStatisticsObj.online_unit_count
              }}</span>
              <div class="unitBar">联盟管理员</div>
            </div>
            <div class="NumThr">
              <span class="zdWord">{{
                userStatisticsObj.online_user_count
              }}</span>
              <div class="zhongduanBar">联盟终端用户</div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="feimaBot">
            <div class="botTit">用户总数</div>
            <div class="unit">
              <span class="unitname">联盟单位</span>
              <p class="unitCount">{{ userStatisticsObj.unit_count }}</p>
            </div>
            <div class="userNum">
              <span class="username">用户总数</span>
              <p class="userCount">{{ userStatisticsObj.user_count }}</p>
            </div>
          </div>
        </div>
        <el-divider class="line" direction="vertical"></el-divider>
      </div>
      <div class="RtopBar">
        <div class="Rtitle">
          <span class="titleWord">用户类型占比</span>
        </div>
        <el-divider></el-divider>
        <div id="userOption"></div>
      </div>
      <div class="midleBar">
        <div class="midTitBar">
          <span class="midTit">月新增联盟单位/联盟终端用户</span>
          <div class="unitLine"></div>
          <span class="unitname">联盟终端用户</span>
          <div class="userLine"></div>
          <span class="username">联盟单位</span>
        </div>
        <el-divider></el-divider>
        <div id="ecahrtTwo"></div>
      </div>
      <div class="botBar">
        <div class="botTitBar">
          <span class="botTit">终端用户数量前十联盟单位分布表</span>
        </div>
        <el-divider></el-divider>
        <div id="echartThr"></div>
        <div class="footerBox">
          <div class="tableBox">
            <table class="tableData" style="width: 100%">
              <tr class="tabTitle">
                <th
                  class="tableTitle"
                  style="width: 30%"
                  align="center"
                  valign="middle"
                >
                  联盟单位
                </th>
                <th
                  class="tableTitle"
                  style="width: 20%"
                  align="center"
                  valign="middle"
                >
                  联盟代码
                </th>
                <th
                  class="tableTitle"
                  style="width: 10%"
                  align="center"
                  valign="middle"
                >
                  超级管理员
                </th>
                <th
                  class="tableTitle"
                  style="width: 10%"
                  align="center"
                  valign="middle"
                >
                  联盟管理员
                </th>
                <th
                  class="tableTitle"
                  style="width: 10%"
                  align="center"
                  valign="middle"
                >
                  联盟终端用户
                </th>
                <th
                  class="tableTitle"
                  style="width: 10%"
                  align="center"
                  valign="middle"
                >
                  任务群组
                </th>
              </tr>
              <tr
                v-for="(item, index) in this.userStatisticsObj.top_unit_data"
                :key="index"
                class="tabletr"
              >
                <td class="tableFile" align="center" valign="middle">
                  <img class="feimatit" src="../../assets/img/title.png" alt />
                  <span>{{ item.unit_name }}</span>
                </td>
                <td align="center">{{ item.unit_code }}</td>
                <!-- <td align="center">{{ item.unit_id }}</td> -->
                <td align="center">{{ item.admin_count }}</td>
                <td align="center">{{ item.unit_count }}</td>
                <td align="center">{{ item.user_count }}</td>
                <td align="center">{{ item.group_count }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      userStatisticsObj: {
        online_admin_count: 3,
        online_unit_count: 0,
        online_user_count: 1,
        unit_count: 5,
        user_count: 50,
        all_admin_count: 9,
        all_unit_count: 4,
        all_user_count: 37,
        new_data: {
          month_data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          month_unit_data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          month_user_data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        top_unit_data: [
          {
            unit_id: 1,
            unit_code: "A0001",
            unit_name: "默认联盟",
            qty: 37,
            admin_count: 8,
            unit_count: 1,
            user_count: 28,
            group_count: 5,
          },
          {
            unit_id: 2,
            unit_code: "CSLM001",
            unit_name: "测试联盟1",
            qty: 7,
            admin_count: 0,
            unit_count: 2,
            user_count: 5,
            group_count: 4,
          },
          {
            unit_id: 3,
            unit_code: "CSLM002",
            unit_name: "CSLM002",
            qty: 4,
            admin_count: 0,
            unit_count: 1,
            user_count: 3,
            group_count: 1,
          },
          {
            unit_id: 4,
            unit_code: "YADW001",
            unit_name: "演示事业单位",
            qty: 2,
            admin_count: 1,
            unit_count: 0,
            user_count: 1,
            group_count: 0,
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    sector() {
      // 基于准备好的dom，初始化echarts实例
      let sector = this.$echarts.init(document.querySelector("#userOption"));
      const colorList = ["#13C2C2", "#FF625B ", "#4C84FF "];
      let allNum =
        Number(this.userStatisticsObj.all_admin_count) +
        Number(this.userStatisticsObj.all_unit_count) +
        Number(this.userStatisticsObj.all_user_count);
      // 绘制图表
      let option = {
        title: {
          text: "用户总数",
          subtext: allNum + "个",
          textStyle: {
            fontSize: 12,
            color: "#838A9D",
            lineHeight: 5,
          },
          subtextStyle: {
            fontSize: 28,
            color: "#333",
          },
          textAlign: "center",
          left: "21.8%",
          top: "30%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a}{b}  {d}%      {c}个",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: "25%",
          bottom: "35%",
          itemGap: 30,
          selectedMode: false,
          icon: "pin",
          data: ["超级管理员", "联盟管理员", "联盟终端用户"],
          textStyle: {
            fontSize: 14,
            color: "#77899c",
            rich: {
              uname: {
                width: 100,
              },
              unum: {
                color: "#4ed139",
                width: 40,
                align: "right",
              },
            },
          },
          formatter: function (name) {
            let oa = option.series[0].data;
            let num = oa[0].value + oa[1].value + oa[2].value;
            for (let i = 0; i < option.series[0].data.length; i++) {
              if (name == oa[i].name) {
                return (
                  name +
                  "  |   " +
                  ((oa[i].value / num) * 100).toFixed(2) +
                  "%   " +
                  oa[i].value +
                  "个"
                );
              }
            }
          },
        },
        color: colorList,
        series: [
          {
            name: "",
            type: "pie",
            radius: [60, 90],
            center: ["22%", "38%"],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderWidth: 3,
              borderColor: "#fff",
            },
            data: [
              {
                name: "超级管理员",
                value: Number(this.userStatisticsObj.all_admin_count),
              },
              {
                name: "联盟管理员",
                value: Number(this.userStatisticsObj.all_unit_count),
              },
              {
                name: "联盟终端用户",
                value: Number(this.userStatisticsObj.all_user_count),
              },
            ],
          },
        ],
      };
      sector.setOption(option);
    },
    unitNum() {
      let unitechart = this.$echarts.init(document.querySelector("#ecahrtTwo"));
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false, //坐标轴两边留白
          data: this.userStatisticsObj.new_data.month_data,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: "#1B253A",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function (params) {
              let newParamsName = "";
              let paramsNameNumber = params.length;
              let provideNumber = 8; //一行显示几个字
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = "";
                  let start = p * provideNumber;
                  let end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: true,
            lineStyle: {
              color: "#E5E9ED",
              // 	opacity:0.1
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: "联盟单位",
            type: "line",
            itemStyle: {
              normal: {
                color: "#3A84FF",
                lineStyle: {
                  color: "#3A84FF",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(58,132,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(58,132,255,0.35)",
                    },
                  ]),
                },
              },
            },
            data: this.userStatisticsObj.new_data.month_unit_data,
          },
          {
            name: "联盟终端用户",
            type: "line",
            itemStyle: {
              normal: {
                color: "rgba(255,80,124,1)",
                lineStyle: {
                  color: "rgba(255,80,124,1)",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(255,80,124,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(255,80,124,0.35)",
                    },
                  ]),
                },
              },
            },
            data: this.userStatisticsObj.new_data.month_user_data,
          },
        ],
      };
      unitechart.setOption(option);
    },
    userNum() {
      let unitechart = this.$echarts.init(document.querySelector("#echartThr"));
      let data = [];
      let valdata = [];
      let titlename = [];
      let userNum = 0;
      let topUnitDataArr = this.userStatisticsObj.top_unit_data;
      topUnitDataArr.sort(this.utils.$funcArrSort("user_count", false));
      let myColor = [
        "#1089E7",
        "#F57474",
        "#56D0E3",
        "#F8B448",
        "#8B78F6",
        "#3089E7",
        "#F51474",
        "#56F0E3",
        "#F3B448",
        "#8B12F6",
      ];
      topUnitDataArr.length > 10
        ? (topUnitDataArr = topUnitDataArr.slice(0, 10))
        : (myColor = myColor.slice(0, topUnitDataArr.length));
      topUnitDataArr.forEach((e, index) => {
        titlename.push(e.unit_name);
        data.push(e.user_count);
        userNum = userNum + e.user_count;
      });
      data.forEach((e, index) => {
        data[index] = ((e / userNum) * 100).toFixed(2);
      });
      let option = {
        xAxis: {
          type: "value",
        },
        grid: {
          left: "5%",
          right: "2%",
          bottom: "20%",
          containLabel: true,
        },
        yAxis: [
          {
            show: true,
            data: titlename,
            inverse: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#1F1F1F",
              formatter: function (value, index) {
                return [
                  "{lg|" + (index + 1) + "}" + "{title|" + value + "} ",
                ].join("\n");
              },
              rich: {
                lg: {
                  backgroundColor: "#339911",
                  color: "#fff",
                  borderRadius: 15,
                  // padding: 5,
                  align: "center",
                  width: 15,
                  height: 15,
                },
              },
            },
          },
          {
            show: true,
            inverse: true,
            data: valdata,
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "条",
            type: "bar",
            yAxisIndex: 0,
            data: data,
            barWidth: 12,
            itemStyle: {
              normal: {
                barBorderRadius: 12,
                color: function (params) {
                  let num = myColor.length;
                  return myColor[params.dataIndex % num];
                },
              },
            },
            label: {
              normal: {
                show: true,
                position: "inside",
                formatter: "{c}%",
              },
            },
          },
          // {
          //   name: "框",
          //   type: "bar",
          //   yAxisIndex: 1,
          //   barGap: "-100%",
          //   data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100].slice(
          //     0,
          //     this.userStatisticsObj.top_unit_data.length
          //   ),
          //   barWidth: 15,
          //   itemStyle: {
          //     normal: {
          //       color: "none",
          //       borderColor: "#00c1de",
          //       borderWidth: 2,
          //       barBorderRadius: 15,
          //     },
          //   },
          // },
        ],
      };
      unitechart.setOption(option);
    },
  },
  created() {
    this.$apis
      .reportGetUserData({}, this.token)
      .then((data) => {
        let obj = data.data.data;
        obj.new_data.month_data = JSON.parse(obj.new_data.month_data);
        obj.new_data.month_unit_data = JSON.parse(obj.new_data.month_unit_data);
        obj.new_data.month_user_data = JSON.parse(obj.new_data.month_user_data);
        this.userStatisticsObj = obj;
        this.sector();
        this.unitNum();
        this.userNum();
      })
      .catch((err) => {
        console.error(err);
      });
  },
  computed: {
    ...mapState(["navMessage", "token", "user"]),
  },
  mounted() {
    if (this.$route.query.nav == "usersta") {
      this.currentUrlStorage(["首页", "统计分析", "用户统计"]);
    }
    //实现自适应部分
    this.resizefun = () => {
      this.$echarts.init(document.getElementById("userOption")).resize();
      this.$echarts.init(document.getElementById("ecahrtTwo")).resize();
      this.$echarts.init(document.getElementById("echartThr")).resize();
    };
    window.addEventListener("resize", this.resizefun);
  },
  //移除事件监听，避免内存泄漏
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
};
</script>

<style lang="less" scoped>
/deep/.el-divider--vertical {
  margin: 0px;
}
/deep/.el-divider--horizontal {
  margin: 24px 0 0 0;
}
.userBody {
  width: 100%;
  height: 100%;
  padding: 5px 15px 50px 15px;
  background-color: #f0f0f0;
  overflow-y: auto;
  .bodyNav {
    width: 100%;
    height: 100%;
    .ImgBar {
      height: 33vh;
      width: 48%;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      background: url("../../assets/img/User_statistics／bg.png");
      background-size: 100% 100%;
      .bgImg {
        width: 100%;
        height: 100%;
      }
      .feimaBar {
        height: 80%;
        width: 65%;
        margin-left: 10%;
        margin-top: 5%;
        float: left;
        .topTit {
          color: #ffff;
          font-size: 20px;
          width: 100%;
        }
        .topNum {
          width: 83%;
          margin-top: 5%;
          display: flex;
          justify-content: space-between;
          .NumOne {
            .superWord {
              font-size: 30px;
              color: #1862ff;
              margin-left: 25%;
              font-family: DINAlternate-Bold, DINAlternate;
            }
            .superBar {
              width: 112px;
              height: 32px;
              background-color: #1862ff;
              border-radius: 4px;
              font-size: 14px;
              color: #fff;
              line-height: 32px;
              text-align: center;
            }
          }
          .NumTwo {
            .unitWord {
              font-size: 30px;
              color: #fac15a;
              margin-left: 25%;
              font-family: DINAlternate-Bold, DINAlternate;
            }
            .unitBar {
              width: 112px;
              height: 32px;
              background-color: #fac15a;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4353;
              line-height: 32px;
              text-align: center;
            }
          }
          .NumThr {
            .zdWord {
              font-size: 30px;
              color: #1cd0bd;
              margin-left: 25%;
              font-family: DINAlternate-Bold, DINAlternate;
            }
            .zhongduanBar {
              width: 112px;
              height: 32px;
              background-color: #59fa9c;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4353;
              line-height: 32px;
              text-align: center;
            }
          }
        }
        .feimaBot {
          margin-top: 3%;
          .botTit {
            color: #ffff;
            font-size: 20px;
            width: 100%;
          }
          .unit {
            margin-top: 5%;
            float: left;
            width: 20%;
            .unitname {
              font-size: 14px;
              color: #a6aab8;
            }
            .unitCount {
              color: #1182fb;
              font-size: 30px;
              margin: 10px 0 0 0;
            }
          }
          .userNum {
            margin-top: 5%;
            width: 20%;
            float: left;
            .username {
              font-size: 14px;
              color: #a6aab8;
            }
            .userCount {
              color: #1182fb;
              font-size: 30px;
              margin: 10px 0 0 0;
            }
          }
        }
      }
      .line {
        height: 100%;
      }
    }
    .RtopBar {
      float: right;
      width: 50%;
      height: 33vh;
      margin-left: 1vw;
      background-color: #fff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .Rtitle {
        margin-left: 5%;
        margin-top: 3%;
        .titleWord {
          font-size: 20px;
          color: #838a9d;
        }
      }
      #userOption {
        width: 100%;
        height: 100%;
      }
    }
  }
  .midleBar {
    width: 100%;
    height: 50%;
    margin-top: 2vh;
    background-color: #fff;
    float: left;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .midTitBar {
      width: 45%;
      margin-top: 2vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .midTit {
        font-size: 20px;
        color: #838a9d;
      }
      .unitLine {
        width: 30px;
        height: 2px;
        background-color: #ff5c5e;
      }
      .userLine {
        width: 30px;
        height: 2px;
        background-color: #0255d8;
      }
    }
    #ecahrtTwo {
      width: 100%;
      height: 80%;
    }
  }
  .botBar {
    width: 100%;
    height: 50%;
    margin-top: 2vh;
    background-color: #fff;
    float: left;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .botTitBar {
      margin-top: 2vh;
      margin-left: 2vw;
      .botTit {
        font-size: 20px;
        color: #838a9d;
      }
    }
    #echartThr {
      width: 100%;
      height: 90%;
    }
  }
}
.footerBox {
  width: 100%;
  height: 460px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  margin-bottom: 40px;
  .tableBox {
    height: 417px;
    border: 1px solid #dde2ee;
  }
}
.tableData {
  border-collapse: collapse;
  .tabTitle {
    height: 56px;
  }
}
.tableFile {
  height: 48px;
}
.tableTitle {
  height: 30px;
  background-color: #e5e8ed;
}

table tr:nth-child(odd) {
  background-color: #f6f6f6;
}

table tr:nth-child(even) {
  background: #fff;
}
.tabletr {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: rgba(0, 0, 0, 0.65);
}
@media screen and (min-width: 1465px) and (max-width: 1640px) {
  .userBody {
    width: 100%;
    height: 100%;
    padding: 5px 15px 50px 15px;
    background-color: #f0f0f0;
    overflow-y: auto;
    .bodyNav {
      width: 100%;
      height: 100%;
      .ImgBar {
        height: 30vh;
        width: 48%;
        float: left;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        background: url("../../assets/img/User_statistics／bg.png");
        background-size: 100% 100%;
        .bgImg {
          width: 100%;
          height: 100%;
        }
        .feimaBar {
          height: 80%;
          width: 65%;
          margin-left: 8%;
          margin-top: 4%;
          float: left;
          .topTit {
            color: #ffff;
            font-size: 18px;
            width: 100%;
          }
          .topNum {
            width: 83%;
            margin-top: 4%;
            display: flex;
            justify-content: space-between;
            .NumOne {
              .superWord {
                font-size: 28px;
                color: #1862ff;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .superBar {
                width: 100px;
                height: 30px;
                background-color: #1862ff;
                border-radius: 4px;
                font-size: 14px;
                color: #fff;
                line-height: 30px;
                text-align: center;
              }
            }
            .NumTwo {
              .unitWord {
                font-size: 28px;
                color: #fac15a;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .unitBar {
                width: 100px;
                height: 30px;
                background-color: #fac15a;
                border-radius: 4px;
                font-size: 14px;
                color: #3c4353;
                line-height: 30px;
                text-align: center;
              }
            }
            .NumThr {
              .zdWord {
                font-size: 28px;
                color: #1cd0bd;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .zhongduanBar {
                width: 100px;
                height: 30px;
                background-color: #59fa9c;
                border-radius: 4px;
                font-size: 14px;
                color: #3c4353;
                line-height: 30px;
                text-align: center;
              }
            }
          }
          .feimaBot {
            margin-top: 3%;
            .botTit {
              color: #ffff;
              font-size: 18px;
              width: 100%;
            }
            .unit {
              margin-top: 5%;
              float: left;
              width: 20%;
              .unitname {
                font-size: 14px;
                color: #a6aab8;
              }
              .unitCount {
                color: #1182fb;
                font-size: 28px;
                margin: 10px 0 0 0;
              }
            }
            .userNum {
              margin-top: 5%;
              width: 20%;
              float: left;
              .username {
                font-size: 14px;
                color: #a6aab8;
              }
              .userCount {
                color: #1182fb;
                font-size: 28px;
                margin: 10px 0 0 0;
              }
            }
          }
        }
        .line {
          height: 100%;
        }
      }
      .RtopBar {
        float: right;
        width: 50%;
        height: 30vh;
        margin-left: 1vw;
        background-color: #fff;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        .Rtitle {
          margin-left: 5%;
          margin-top: 3%;
          .titleWord {
            font-size: 18px;
            color: #838a9d;
          }
        }
        #userOption {
          width: 100%;
          height: 100%;
        }
      }
    }
    .midleBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .midTitBar {
        width: 45%;
        margin-top: 2vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .midTit {
          font-size: 18px;
          color: #838a9d;
        }
        .unitLine {
          width: 30px;
          height: 2px;
          background-color: #ff5c5e;
        }
        .userLine {
          width: 30px;
          height: 2px;
          background-color: #0255d8;
        }
      }
      #ecahrtTwo {
        width: 100%;
        height: 80%;
      }
    }
    .botBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .botTitBar {
        margin-top: 2vh;
        margin-left: 2vw;
        .botTit {
          font-size: 18px;
          color: #838a9d;
        }
      }
      #echartThr {
        width: 100%;
        height: 90%;
      }
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1465px) {
  .userBody {
    width: 100%;
    height: 100%;
    padding: 5px 15px 50px 15px;
    background-color: #f0f0f0;
    overflow-y: auto;
    .bodyNav {
      width: 100%;
      height: 100%;
      .ImgBar {
        height: 30vh;
        width: 48%;
        float: left;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        background: url("../../assets/img/User_statistics／bg.png");
        background-size: 100% 100%;
        .bgImg {
          width: 100%;
          height: 100%;
        }
        .feimaBar {
          height: 80%;
          width: 65%;
          margin-left: 6%;
          margin-top: 4%;
          float: left;
          .topTit {
            color: #ffff;
            font-size: 16px;
            width: 100%;
          }
          .topNum {
            width: 83%;
            margin-top: 4%;
            display: flex;
            justify-content: space-between;
            .NumOne {
              .superWord {
                font-size: 26px;
                color: #1862ff;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .superBar {
                width: 88px;
                height: 28px;
                background-color: #1862ff;
                border-radius: 4px;
                font-size: 12px;
                color: #fff;
                line-height: 28px;
                text-align: center;
              }
            }
            .NumTwo {
              .unitWord {
                font-size: 26px;
                color: #fac15a;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .unitBar {
                width: 88px;
                height: 28px;
                background-color: #fac15a;
                border-radius: 4px;
                font-size: 12px;
                color: #3c4353;
                line-height: 28px;
                text-align: center;
              }
            }
            .NumThr {
              .zdWord {
                font-size: 26px;
                color: #1cd0bd;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .zhongduanBar {
                width: 88px;
                height: 28px;
                background-color: #59fa9c;
                border-radius: 4px;
                font-size: 12px;
                color: #3c4353;
                line-height: 28px;
                text-align: center;
              }
            }
          }
          .feimaBot {
            margin-top: 3%;
            .botTit {
              color: #ffff;
              font-size: 16px;
              width: 100%;
            }
            .unit {
              margin-top: 5%;
              float: left;
              width: 20%;
              .unitname {
                font-size: 12px;
                color: #a6aab8;
              }
              .unitCount {
                color: #1182fb;
                font-size: 26px;
                margin: 10px 0 0 0;
              }
            }
            .userNum {
              margin-top: 5%;
              width: 20%;
              float: left;
              .username {
                font-size: 12px;
                color: #a6aab8;
              }
              .userCount {
                color: #1182fb;
                font-size: 26px;
                margin: 10px 0 0 0;
              }
            }
          }
        }
        .line {
          height: 100%;
        }
      }
      .RtopBar {
        float: right;
        width: 50%;
        height: 30vh;
        margin-left: 1vw;
        background-color: #fff;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        .Rtitle {
          margin-left: 5%;
          margin-top: 3%;
          .titleWord {
            font-size: 16px;
            color: #838a9d;
          }
        }
        #userOption {
          width: 100%;
          height: 100%;
        }
      }
    }
    .midleBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .midTitBar {
        width: 45%;
        margin-top: 2vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .midTit {
          font-size: 16px;
          color: #838a9d;
        }
        .unitLine {
          width: 30px;
          height: 2px;
          background-color: #ff5c5e;
        }
        .userLine {
          width: 30px;
          height: 2px;
          background-color: #0255d8;
        }
        .unitname {
          font-size: 14px;
        }
        .username {
          font-size: 14px;
        }
      }
      #ecahrtTwo {
        width: 100%;
        height: 80%;
      }
    }
    .botBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .botTitBar {
        margin-top: 2vh;
        margin-left: 2vw;
        .botTit {
          font-size: 16px;
          color: #838a9d;
        }
      }
      #echartThr {
        width: 100%;
        height: 90%;
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  .userBody {
    width: 100%;
    height: 100%;
    padding: 5px 15px 50px 15px;
    background-color: #f0f0f0;
    overflow-y: auto;
    .bodyNav {
      width: 100%;
      height: 100%;
      .ImgBar {
        height: 30vh;
        width: 48%;
        float: left;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        background: url("../../assets/img/User_statistics／bg.png");
        background-size: 100% 100%;
        .bgImg {
          width: 100%;
          height: 100%;
        }
        .feimaBar {
          height: 80%;
          width: 65%;
          margin-left: 5%;
          margin-top: 2%;
          float: left;
          .topTit {
            color: #ffff;
            font-size: 15px;
            width: 100%;
          }
          .topNum {
            width: 83%;
            margin-top: 3%;
            display: flex;
            justify-content: space-between;
            .NumOne {
              .superWord {
                font-size: 24px;
                color: #1862ff;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .superBar {
                width: 78px;
                height: 28px;
                background-color: #1862ff;
                border-radius: 4px;
                font-size: 12px;
                color: #fff;
                line-height: 28px;
                text-align: center;
              }
            }
            .NumTwo {
              .unitWord {
                font-size: 24px;
                color: #fac15a;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .unitBar {
                width: 78px;
                height: 28px;
                background-color: #fac15a;
                border-radius: 4px;
                font-size: 12px;
                color: #3c4353;
                line-height: 28px;
                text-align: center;
              }
            }
            .NumThr {
              .zdWord {
                font-size: 24px;
                color: #1cd0bd;
                margin-left: 25%;
                font-family: DINAlternate-Bold, DINAlternate;
              }
              .zhongduanBar {
                width: 78px;
                height: 28px;
                background-color: #59fa9c;
                border-radius: 4px;
                font-size: 12px;
                color: #3c4353;
                line-height: 28px;
                text-align: center;
              }
            }
          }
          .feimaBot {
            margin-top: 3%;
            .botTit {
              color: #ffff;
              font-size: 14px;
              width: 100%;
            }
            .unit {
              margin-top: 5%;
              float: left;
              width: 20%;
              .unitname {
                font-size: 12px;
                color: #a6aab8;
              }
              .unitCount {
                color: #1182fb;
                font-size: 24px;
                margin: 10px 0 0 0;
              }
            }
            .userNum {
              margin-top: 5%;
              width: 20%;
              float: left;
              .username {
                font-size: 12px;
                color: #a6aab8;
              }
              .userCount {
                color: #1182fb;
                font-size: 24px;
                margin: 10px 0 0 0;
              }
            }
          }
        }
        .line {
          height: 100%;
        }
      }
      .RtopBar {
        float: right;
        width: 50%;
        height: 30vh;
        margin-left: 1vw;
        background-color: #fff;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        .Rtitle {
          margin-left: 5%;
          margin-top: 3%;
          .titleWord {
            font-size: 15px;
            color: #838a9d;
          }
        }
        #userOption {
          width: 100%;
          height: 100%;
        }
      }
    }
    .midleBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .midTitBar {
        width: 45%;
        margin-top: 2vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .midTit {
          font-size: 14px;
          color: #838a9d;
        }
        .unitLine {
          width: 30px;
          height: 2px;
          background-color: #ff5c5e;
        }
        .userLine {
          width: 30px;
          height: 2px;
          background-color: #0255d8;
        }
        .unitname {
          font-size: 12px;
        }
        .username {
          font-size: 12px;
        }
      }
      #ecahrtTwo {
        width: 100%;
        height: 80%;
      }
    }
    .botBar {
      width: 100%;
      height: 50%;
      margin-top: 2vh;
      background-color: #fff;
      float: left;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .botTitBar {
        margin-top: 2vh;
        margin-left: 2vw;
        .botTit {
          font-size: 14px;
          color: #838a9d;
        }
      }
      #echartThr {
        width: 100%;
        height: 90%;
      }
    }
  }
}
</style>